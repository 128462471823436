import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { ConstantsLagoon } from '../../../../../../../../../../common/ConstantsLagoon';
import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import { Address } from '../../../../../../../../../../models/ProviderDetails';
import { useChipStore } from '../../../../../../../../../../store/useChipStore';
import { ChipState } from '../../../../../../../../../../store/useChipStore/chipStore';
import { returnSuppressFlag } from '../../../../../../../../../../utils/featureSuppress';
import { isVirtualVisitOnlyAddress } from '../../../../../../../../../../utils/virtualVisitOnly';
import { StyledMiniCompareCardAddressDiv } from '../../../../../utility/compareDrawer.styled';
import { getDisplayedAddressText, isAddressPresent } from './addressUtility';

type Props = {
  address: Address;
};

export const MiniCompareCardAddress = ({ address }: Props) => {
  const { t } = useTranslation();
  const { chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      chipValue: state.chipValue,
    }))
  );
  const isVirtualCareQuickCareSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_QUICK_CARE
  );
  const shouldShowVirtualVisitAddress = (
    addressLine: string,
    suppressVirtualCareQuickCare: boolean
  ) =>
    suppressVirtualCareQuickCare &&
    isVirtualVisitOnlyAddress(chipValue, addressLine);
  const isAttributePresent = isAddressPresent(address);
  const displayedAddress =
    !shouldShowVirtualVisitAddress(
      address?.line[0],
      isVirtualCareQuickCareSuppressed
    ) && t(getDisplayedAddressText(address, isAttributePresent));
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const MiniCompareCareAddressAttribute = (
    <StyledMiniCompareCardAddressDiv
      cssProps={{ isOverflow }}
      ref={containerRef}
    >
      <Text
        css={{ cursor: 'auto' }}
        data-auto-testid="data-card-address-text"
        data-testid="data-card-address-text"
        size="sm"
      >
        {displayedAddress}
      </Text>
    </StyledMiniCompareCardAddressDiv>
  );

  return (
    <React.Fragment>
      <Flex alignItems="center">
        {!shouldShowVirtualVisitAddress(
          address?.line[0],
          isVirtualCareQuickCareSuppressed
        ) && (
          <IconMaterial
            className="location-icon"
            color="$gray7"
            css={{
              marginBottom: '0.25rem',
            }}
            data-auto-testid="data-card-location-on-icon"
            data-testid="data-card-location-on-icon"
            icon="location_on"
            size="18px"
          />
        )}
        {isOverflow ? (
          <Tooltip
            asChild={false}
            content={displayedAddress}
            css={{
              'abyss-tooltip-trigger': {
                lineHeight: 0.8,
                // '> div': { height: 'auto' },
                '.abyss-text-root': {
                  fontWeight: 400,
                  color: '$gray8',
                  fontFamily: 'var(--abyss-fonts-primary)',
                },
              },
            }}
            position="top"
            positionOffset={8}
            role="tooltip"
          >
            {MiniCompareCareAddressAttribute}
          </Tooltip>
        ) : (
          MiniCompareCareAddressAttribute
        )}
      </Flex>
    </React.Fragment>
  );
};
