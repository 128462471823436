import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Text } from '@abyss/web/ui/Text';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { getGeoLocationFromStorage } from '../../../../../common/PSXHeader/SearchBar/utils';
import { getFeatureFlag } from '../../../../../common/Utils/getFeatureFlag';
import { useLagoon } from '../../../../../hooks/useLagoon';
import { useShareAllResults } from '../../../../../hooks/useShareAllResults';
import { useChipStore } from '../../../../../store/useChipStore';
import { ChipState } from '../../../../../store/useChipStore/chipStore';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
  getRulesPackageKey,
} from '../../../../../utils/user.utils';
import { successToast } from '../../ViewAll/Toast';
import { ShareContainerMobile } from './ShareDrawer.styled';
import {
  convertBase64PdfStringToFile,
  sectionTypeToProviderType,
} from './utils';

type Props = {
  setOpenSection: Function;
  searchParam: string;
  shareTitle: string;
  sectionType: string;
  includeSpecialityRollupCodes: string[];
  setOpenShare: (a: boolean) => void;
};

export const MobilePDFShareSection = ({
  setOpenSection,
  shareTitle,
  searchParam,
  sectionType,
  includeSpecialityRollupCodes,
  setOpenShare,
}: Props) => {
  const { t } = useTranslation();
  const toastMessage = t('TOAST.YOUR_RESULTS_HAVE_BEEN_SHARED');
  const providerType = sectionTypeToProviderType(sectionType);

  const { coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
    }))
  );

  const [pdfFile, setPdfFile] = useState<File>();

  const [, shareAll] = useShareAllResults({});

  const { longitude, latitude } = getGeoLocationFromStorage();
  const currentMember = getCurrentMember();

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const tierBenefits = useLagoon('tier-benefit')();

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );
  const enableTier1FromMemberRPK = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER1_FROM_MEMBER_RPK
  );
  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const memCatergory = currentMember?.membershipCategory || '';
  const membershipCategory = uspToggleFlag ? memCatergory : null;

  useEffect(() => {
    shareAll({
      variables: {
        latitude,
        longitude,
        providerType,
        lob: currentMember?.lineOfBusiness,
        membershipCategory,
        coverages: getCoverageTypes(currentMember),
        planYear: getCurrentPlanYear(),
        planVariationCode: getPlanVariationCode(currentMember, coverageType),
        claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.claimSystemTypeCode,
        eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.eligibilitySystemTypeCode,
        policyID: currentMember?.policyNumber,
        rulesPackageKey: getRulesPackageKey(
          currentMember,
          tierBenefits,
          providerType,
          uspTierOneFlag,
          enableTier1FromMemberRPK
        ),
        reciprocityId: getNetworkIdsForPES(
          currentMember,
          coverageType,
          featureFlags
        ),
        sendEmail: false,
        linkToSearchResultsPage: window.location.href,
        shareTitle,
        searchKeyword: searchParam,
        includeSpecialityRollupCodes,
      },
    }).then((response) => {
      const file = convertBase64PdfStringToFile(
        JSON.parse(response?.data?.shareAllResults?.pdfBase64)?.body,
        shareTitle
      );
      setPdfFile(file);
    });
  }, []);

  const sharePdf = () => {
    if (pdfFile) {
      navigator
        .share({
          files: [pdfFile],
          title: shareTitle,
        })
        .then(() => {
          successToast(toastMessage);
          setOpenSection(false);
          setOpenShare(false);
        });
    }
  };

  return (
    <FloatingSection
      css={{
        'abyss-floating-section-root': {
          borderRadius: '20px 20px 0px 0px',
          boxShadow: '0px -6px 20px rgba(25, 25, 26, 0.16)',
          width: '100%',
          padding: '24px 30px',
          position: 'fixed',
          bottom: '0',
          zIndex: '1',
          overflowY: 'auto',
          backgroundColor: '#FAFCFF',
        },
      }}
      data-auto-testid="mobile-pdf-share-floating-section"
      data-testid="mobile-pdf-share-floating-section"
      position="bottom"
    >
      <ShareContainerMobile>
        <Box
          css={{
            'abyss-box-root': {
              backgroundColor: 'transparent',
              padding: '0px',
            },
          }}
        >
          <Flex
            css={{
              'abyss-flex-root': {
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '$md',
              },
            }}
          >
            {!pdfFile ? (
              <LoadingSpinner
                ariaLoadingLabel="loading pdf"
                isLoading={!pdfFile}
                size="$sm"
              />
            ) : (
              <div />
            )}
            <Flex>
              <Button
                css={{
                  'abyss-button-root': {
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                }}
                variant="tertiary"
              >
                <IconMaterial
                  css={{
                    marginRight: '4px',
                  }}
                  data-auto-testid="button-to-close-mobile-pdf-share-section"
                  data-testid="button-to-close-mobile-pdf-share-section"
                  icon="close"
                  onClick={() => setOpenSection(false)}
                  size="$md"
                />
              </Button>
            </Flex>
            <Button
              align="start"
              css={{
                'abyss-button-root': {
                  width: '343px',
                  padding: '12px 15px',
                  backgroundColor: pdfFile ? '$primary1' : '$gray2',
                  height: '42px',
                  borderRadius: '24px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                },
              }}
              data-auto-testid="mobile-share-pdf-button"
              data-testid="mobile-share-pdf-button"
              onClick={() => sharePdf()}
              variant={pdfFile ? 'solid' : 'ghost'}
            >
              <Text
                css={{
                  'abyss-text-root': {
                    fontSize: '$md',
                    paddingLeft: '5px',
                    fontWeight: '$semibold',
                    lineHeight: '18px',
                    textAlign: 'center',
                    color: pdfFile ? '$primary2' : '$gray5',
                  },
                }}
              >
                {t('Share PDF')}
              </Text>
            </Button>
          </Flex>
        </Box>
      </ShareContainerMobile>
    </FloatingSection>
  );
};
