import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { Badge } from '@abyss/web/ui/Badge';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { useLagoon } from '../../hooks/useLagoon';
import { SnackCardProvider } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { useSnackcardViewAllCount } from '../../hooks/useSnackcardViewAllCount';
import { useSpecialtiesResult } from '../../hooks/useSpecialties';
import { BhProviderIcons } from '../../models/Lagoon';
import { ProviderType } from '../../models/ProviderDetails';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { DetailsStore } from '../../store/useDetailsStore/detailsStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getDistance, getSpecialityTitle } from '../../utils/card.utils';
import { isProviderStartDateInFuture } from '../../utils/date.utils';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import { getBhIcon } from '../../utils/iconography.utils';
import {
  getPremiumCarePhysician,
  getProviderSpecialtyOrOrgTypeCode,
} from '../../utils/providerDetails.utils';
import {
  getNameAttrs,
  parseProviderName,
} from '../../utils/providerNames.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  ProviderTypes,
  ReverseCoverageTypesCodes,
} from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { phoneOnly } from '../ConstantsStyles';
import { TerminationDateIndicatorContainer } from '../DataCard/DataCard.style';
import { isPremiumCareDisplayEligible } from '../DataCard/DataCardUtils';
import { ProviderRating } from '../DataCard/ProviderRating';
import { ImageComponent } from '../ImageComponent';
import { InNetworkBadge } from '../InNetworkBadge/InNetworkBadge';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { NationalGroupProvidersVirtualBiteCard } from '../NationalGroupProvidersVirtualBiteCard';
import { TerminationStatusIndicator } from '../TerminationBadge/TerminationStatusIndicator';
import { convertObjectToUrlParams, getFeatureFlag } from '../Utils';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import {
  CustomAttributesBlock,
  appendCoverageTypes,
  convertProviderTypeToAdobeType,
  formatProviderId,
  getNameForLinkTrack,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { checkTerminationDate } from '../Utils/terminateDateIndicatorUtils';
import { CardInfo } from './CardInfo';
import { RatingContainer, VoiceOnlyText } from './Rating.styles';
import {
  Avatar,
  DistanceDrawerStyles,
  Miles,
  MilesContent,
  MilesText,
  SnackAvatarContainer,
  SnackCardPopoverContainer,
  SnackContainer,
  SnackDescription,
  SnackDetails,
  SnackInfoCard,
  SnackName,
  SnackRole,
  displayVirtualVisitStyle,
} from './SnackCard.styles';
import { SnackCardLoader } from './SnackCardLoader';
import { SnackCardWithExternalLink } from './SnackCardWithExternalLink';

export type SnackCardProps = {
  coverageType?: string;
  providerName?: string;
  professionalDesignation?: string;
  speciality?: string;
  rating?: number;
  distance?: string;
  imgSrc?: string;
  providerId?: string;
  providerType?: string;
  isLoading: boolean;
  isFixes?: boolean;
  locationForAnalytics?: string;
  isVirtualCareOffered?: boolean;
  hasPhysicalAddress?: boolean;
  index?: number;
  isTieredProvider?: boolean;
  organizationType?: string[];
  organizationCode?: string[];
  website?: string;
  headers?: ResponseHeaders;
  snackCardProvider?: SnackCardProvider | any;
  locationId?: string;
  networkEndDate?: string;
  isNetworkAlertIndicator?: boolean;
  isPremiumCareIndicator?: boolean;
  isMNR?: boolean;
  isInNetworkBadge?: boolean;
  networkStartDate?: string;
};
const networkIndicatorSize = (
  isNetworkAlertIndicator,
  displayFutureTerminationDate,
  isInNetworkBadge,
  displayInNetworkBadge,
  displayInNetworkBadgeEnabled,
  isCurrentMemberPreEffective,
  isPremiumCareIndicator,
  isPremiumCareDisplay,
  isPlatinumProviderIdentifierEnabled,
  isPlatinumProvider
) => {
  if (
    isNetworkAlertIndicator &&
    isPremiumCareIndicator &&
    !displayFutureTerminationDate &&
    !isPremiumCareDisplay &&
    !isPlatinumProviderIdentifierEnabled &&
    isPlatinumProvider
  ) {
    return createPaddingStyle('56px');
  }

  if (
    (isNetworkAlertIndicator && !displayFutureTerminationDate) ||
    (isPremiumCareIndicator && !isPremiumCareDisplay) ||
    (displayInNetworkBadgeEnabled &&
      isCurrentMemberPreEffective &&
      isInNetworkBadge &&
      !displayInNetworkBadge)
  ) {
    return createPaddingStyle('30px');
  }

  if (isPlatinumProviderIdentifierEnabled && !isPlatinumProvider) {
    return createPaddingStyle('20px');
  }
  return '';
};

const createPaddingStyle = (padding) => ({
  paddingTop: padding,
  '@screen < $sm': { paddingTop: padding },
});

const infoCardHeight = (
  isNetworkAlertIndicator,
  isInNetworkBadge,
  displayInNetworkBadgeEnabled,
  isPremiumCareIndicator,
  isPlatinumProvider,
  isPlatinumProviderIdentifierEnabled
) => {
  if (isNetworkAlertIndicator && isPremiumCareIndicator && isPlatinumProvider) {
    return {
      height: '260px',
      '@screen < $sm': { height: '245px' },
    };
  }

  if (
    isNetworkAlertIndicator ||
    isPremiumCareIndicator ||
    (isInNetworkBadge && displayInNetworkBadgeEnabled)
  ) {
    return {
      height: '235px',
      '@screen < $sm': { height: '220px' },
    };
  }

  if (isPlatinumProvider && isPlatinumProviderIdentifierEnabled) {
    return {
      height: '222px',
      '@screen < $sm': { height: '200px' },
    };
  }

  return '';
};

export const SnackCard = ({
  coverageType,
  providerName,
  professionalDesignation,
  speciality,
  rating,
  distance,
  imgSrc,
  providerId,
  providerType,
  isLoading,
  isFixes = false,
  locationForAnalytics,
  isVirtualCareOffered,
  hasPhysicalAddress,
  index,
  isTieredProvider,
  organizationType,
  organizationCode,
  website,
  snackCardProvider = {},
  headers = { correlationId: '' },
  locationId,
  networkEndDate,
  isNetworkAlertIndicator,
  isPremiumCareIndicator = false,
  networkStartDate,
  isInNetworkBadge,
}: SnackCardProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const nameAttrs = getNameAttrs(snackCardProvider);
  const displayName = parseProviderName(
    providerName,
    providerType,
    professionalDesignation,
    nameAttrs
  );
  const currentMember = getCurrentMember();
  const analyticsName = getNameForLinkTrack(displayName, providerType);
  const suppressPatientReviews = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const { navigate } = useRouter();
  const [milesdata, setMileseData] = useState(0);
  const { SELECTED_PROVIDER_ID } = Constants.STORAGE_KEYS.SESSION;
  const providerDetailsMilesInfo = 'get-provider-details-miles';
  const mobileScreen = useMediaQuery(phoneOnly);
  const [isOpen, setIsOpen] = useState(false);
  const snackCardModalTestId: string = 'snack-card-miles-info-popover';
  const indexForAnalytics = index !== undefined ? index + 1 : 0;
  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const {
    setCoverageType: setStoreCoverageType,
    coverageType: storeCoverageType,
  } = chipStore;
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );
  const { setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
    }))
  );

  const specialitiesIds = snackCardProvider?.specialities?.map(
    (item) => item?.id
  );

  const coverage = coverageType ?? storeCoverageType;

  const bhProviderIcons: BhProviderIcons[] = useLagoon('bh_provider_icons')();
  const { data: bhSpecialtyCodes = [] } = useSpecialtiesResult({
    coverageType: 'B',
  });

  const bhIcon =
    coverage === 'B'
      ? getBhIcon(specialitiesIds, [], bhProviderIcons, bhSpecialtyCodes)
      : '';
  const navigateToSnackDetails = (
    heading: string,
    showDirection,
    isTiered?
  ) => {
    const typeaheadStoreValues = {
      search: heading,
      sectionType: Constants.SEARCH_TYPES.PROVIDER,
      providerType: ProviderTypes.PRACTITIONER,
      searchType: Constants.SEARCH_TYPES.LOCATION,
    };
    const detailsStoreValues = {
      specialtySearch: speciality,
      providerId,
      showDirection,
      locationId,
      isTieredSnackCard: isTiered,
      organizationCode,
    };
    const pcpStoreValues = { pcpIndicator: true };
    const curCovType = coverageType ?? storeCoverageType;
    setStoreCoverageType(curCovType);
    setTypeaheadSearchStore(typeaheadStoreValues);
    setDetailsStore(detailsStoreValues);
    setPCPSearchState(pcpStoreValues);
    storage.session.set(SELECTED_PROVIDER_ID, providerId);
    const urlParams = convertObjectToUrlParams(chipStore, {
      coverageType: curCovType,
      providerId,
      showDirection,
      locationId,
      isTieredSnackCard: isTiered,
      pcpIndicator: true,
    });
    let path;
    if (providerType === ProviderType.PRACTITIONER) {
      path = `${ConstantsRoutes.PROVIDER_DETAILS.path}${urlParams}`;
    } else if (providerType === ProviderType.MEDICAL_GROUP) {
      path = `${ConstantsRoutes.PROVIDER_GROUP_DETAILS.path}${urlParams}`;
    } else path = `${ConstantsRoutes.FACILITY_DETAILS.path}${urlParams}`;
    navigate(path);
  };
  const gotoDirection = (header) => {
    navigateToSnackDetails(header, true, isTieredProvider);
  };

  useEffect(() => {
    const data = getDistance(distance || '');
    setMileseData(data);
  }, [distance]);

  const { t } = useTranslation();
  const dataTestId = `get-provider-details-name-${providerType}`.replace(
    / /g,
    '-'
  );

  const featureFlags = useLagoon('feature-flags')();
  const virtualCareIndicatorEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VIRTUAL_CARE_INDICATOR_ENABLED
  );
  const displayInNetworkBadgeEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_IN_NETWORK_BADGE_FUTURE_START_DATE
  );

  const { isExpandViewAllEnabled } = useSnackcardViewAllCount();

  const customAttributesBlock: CustomAttributesBlock = {
    correlationId: headers?.correlationId,
    providerId: formatProviderId(
      appendCoverageTypes(snackCardProvider, storeCoverageType)
    ),
    providerType: convertProviderTypeToAdobeType(providerType),
  };

  const handleAdobeLinkTrack = (name: string) => {
    adobeLinkTrackEvent({
      name,
      location: `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`,
      type: 'internal',
      customAttributesBlock,
    });
  };
  useEffect(() => {
    if (isOpen) {
      const linkName: string = snackCardModalTestId;
      const linkLocation: string = `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`;
      const modalName: string = Constants.CONTENT_FILTERS.DISTANCE;
      handleLinkAndModalTrack(
        linkName,
        linkLocation,
        modalName,
        undefined,
        customAttributesBlock
      );
    }
  }, [isOpen]);

  const isVirtualCareQuickCareSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_QUICK_CARE
  );

  const isVirtualCareUnetRailroadSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_RAILROAD
  );

  const isIFPOrganization =
    providerType === ProviderType.ORGANIZATION && currentMember?.isIFPMember;

  const suppressVirtualCare =
    isVirtualCareQuickCareSuppressed ||
    (coverage !== ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
      isVirtualCareUnetRailroadSuppressed);

  const displayVirtualVisit =
    virtualCareIndicatorEnabled &&
    hasPhysicalAddress &&
    isVirtualCareOffered &&
    !suppressVirtualCare &&
    !isIFPOrganization &&
    (providerType === ProviderType.PRACTITIONER || ProviderType.MEDICAL_GROUP);

  const displayVirtualProviderGroup =
    isVirtualCareOffered &&
    !hasPhysicalAddress &&
    providerType === 'Medical Group';

  const directionsIconSize = 18;
  const healthGradeRating = rating?.toFixed(1) || '';
  const isCurrentMemberPreEffective = useCurrentMemberIsPreEffective();
  const suppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );
  const isMultipleBadgeEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MULTIPLE_BADGE_DATA_CARD_VIEW
  );
  const displayTerminationDateEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_FUTURE_TERMINATION_DATE
  );
  const displayFutureTerminationDate = checkTerminationDate(
    displayTerminationDateEnabled,
    networkEndDate
  );
  const displayInNetworkBadge = isProviderStartDateInFuture(networkStartDate);
  const healthGradeRatingCursor = healthGradeRating !== '' ? 'pointer' : '';
  const isPlatinumProviderIdentifierEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_PLATINUM_PROVIDER_IDENTIFIER
  );

  const isPlatinumProvider = snackCardProvider?.isPlatinumProvider;

  const isPremiumCareDisplay: Boolean = isPremiumCareDisplayEligible(
    !suppressPremiumCare,
    getPremiumCarePhysician(snackCardProvider.isPremiumCarePhysician)
  );

  const cardWidth = () => {
    let css = {};
    if (!mobileScreen && isExpandViewAllEnabled) {
      css = { width: '180px' };
    }

    css = {
      ...css,
      ...infoCardHeight(
        isNetworkAlertIndicator,
        isInNetworkBadge,
        displayInNetworkBadgeEnabled,
        isPremiumCareIndicator,
        isPlatinumProviderIdentifierEnabled,
        isPlatinumProvider
      ),
    };

    return css;
  };

  if (displayVirtualProviderGroup) {
    return (
      <NationalGroupProvidersVirtualBiteCard
        css={cardWidth()}
        data-auto-testid="virtual-provider-group-snack-card"
        data-testid="virtual-provider-group-snack-card-tag"
        handleOnClick={() => {
          navigateToSnackDetails(
            Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM,
            false,
            isTieredProvider
          );
        }}
        isFixes={isFixes}
        isLoading={isLoading}
        providerName="oshiHealth"
        speciality={speciality}
      />
    );
  }
  const { surveyCount } = snackCardProvider;
  const { address } = snackCardProvider;
  const isSnackCardWithExternalLink =
    providerType === ProviderType.ORGANIZATION &&
    website &&
    !address?.line?.[0];

  const providerSpeciality = getProviderSpecialtyOrOrgTypeCode(
    providerType,
    organizationType,
    speciality
  );

  const providerSpecialityTitle = getSpecialityTitle(
    providerType,
    coverage,
    providerSpeciality,
    t
  );

  const getLabel = () => `${displayName}${providerSpeciality}`;

  if (isSnackCardWithExternalLink)
    return (
      <SnackCardWithExternalLink
        css={cardWidth()}
        displayName={displayName}
        handleClick={() => {
          adobeLinkTrackEvent({
            name: getNameForLinkTrack(website || '', providerType),
            location: `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`,
            type: 'external',
            destinationUrl: website,
            customAttributesBlock,
          });
          window.open(website, '_blank');
        }}
        isFixes={isFixes}
        providerSpeciality={providerSpeciality}
        providerType={providerType}
      />
    );

  return (
    <React.Fragment>
      {isLoading ? (
        <SnackCardLoader
          css={cardWidth()}
          isLoading={isLoading}
          mobileScreen={mobileScreen}
        />
      ) : (
        <SnackContainer>
          <SnackInfoCard
            alignContent="center"
            alignItems="center"
            css={cardWidth()}
            data-auto-testid={`snack-card-${providerType}`.replace(/ /g, '-')}
            data-testid={`snack-card-${providerType}`.replace(/ /g, '-')}
            direction="column"
            isFixes={isFixes}
          >
            {isMultipleBadgeEnabled && (
              <CardInfo
                cardType="snack-card-internal"
                headers={headers}
                indexForAnalytics={indexForAnalytics}
                landingPage
                locationForAnalytics={`body:${locationForAnalytics}`}
                practitioner={snackCardProvider}
              />
            )}
            <Flex
              data-testid="snack-clickable-container"
              onClick={() => {
                navigateToSnackDetails(
                  Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM,
                  false,
                  isTieredProvider
                );
              }}
            >
              <Flex
                alignContent="center"
                alignItems="center"
                direction="column"
              >
                {displayFutureTerminationDate && (
                  <TerminationDateIndicatorContainer
                    css={{ margin: '0 0 $sm 0' }}
                    data-testid="termination-date-container"
                  >
                    <TerminationStatusIndicator
                      message={t(
                        'DISPLAY_FUTURE_TERMINATION_DATE.NETWORK_ALERT_SNACKCARD'
                      )}
                    />
                  </TerminationDateIndicatorContainer>
                )}
                {displayInNetworkBadge && displayInNetworkBadgeEnabled && (
                  <TerminationDateIndicatorContainer data-testid="in-network-badge-container">
                    <InNetworkBadge
                      message={t(
                        'DISPLAY_IN_NETWORK_BADGE.IN_NETWORK_BADGE_SNACKCARD'
                      )}
                    />
                  </TerminationDateIndicatorContainer>
                )}
                {isPlatinumProviderIdentifierEnabled && isPlatinumProvider && (
                  <TerminationDateIndicatorContainer data-testid="platinum-recognition-badge-container">
                    <Badge
                      css={{
                        '@screen < $sm': {
                          height: '20px',
                        },
                        justifyContent: 'start',
                        alignItems: 'center',
                        marginBottom: '4px',
                        marginLeft: '8px',
                        padding: '2px 8px',
                      }}
                      variant="neutral"
                    >
                      {t('PROVIDER_DETAILS.ABOUT_TAB.PLATINUM_PROVIDER')}
                    </Badge>
                  </TerminationDateIndicatorContainer>
                )}
                <SnackAvatarContainer
                  css={networkIndicatorSize(
                    isNetworkAlertIndicator,
                    displayFutureTerminationDate,
                    isInNetworkBadge,
                    displayInNetworkBadge,
                    displayInNetworkBadgeEnabled,
                    isCurrentMemberPreEffective,
                    isPremiumCareIndicator,
                    isPremiumCareDisplay,
                    isPlatinumProviderIdentifierEnabled,
                    isPlatinumProvider
                  )}
                >
                  <Avatar
                    data-testid={`snack-card-${providerType}-profile`.replace(
                      / /g,
                      '-'
                    )}
                  >
                    {ImageComponent({
                      imgRef,
                      providerType,
                      imgSrc,
                      bhIcon,
                      width: '$iconBrandSize',
                      height: '$iconBrandSize',
                    })}
                  </Avatar>
                  {displayVirtualVisit && (
                    <Flex
                      color="$primary1"
                      css={displayVirtualVisitStyle}
                      style={{
                        top:
                          !isPlatinumProvider &&
                          isPlatinumProviderIdentifierEnabled
                            ? '20px'
                            : '0px',
                      }}
                    >
                      <VoiceOnlyText>
                        {t('DETAILS_PAGE.OFFERS_VIRTUAL_CARE')}
                      </VoiceOnlyText>
                      <IconMaterial
                        color="white"
                        data-auto-testid="videocam-icon"
                        data-testid={`snack-card-${providerType}-virtual-visit-icon`.replace(
                          / /g,
                          '-'
                        )}
                        icon="videocam"
                        size={16}
                        variant="filled"
                      />
                    </Flex>
                  )}
                </SnackAvatarContainer>
                <SnackDescription
                  alignContent="center"
                  alignItems="center"
                  direction="column"
                  justify="space-between"
                >
                  <Tooltip content={displayName}>
                    <SnackName
                      color="$accent1"
                      data-auto-testid={dataTestId}
                      data-testid={dataTestId}
                    >
                      <LinkWithTracking
                        analyticsInfo={{
                          name: analyticsName,
                          location: `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`,
                          customAttributesBlock,
                        }}
                        aria-label={getLabel()}
                        data-auto-testid={`${dataTestId}-link`}
                        data-testid={`${dataTestId}-link`}
                        isStandardAnchor
                      >
                        {displayName}
                      </LinkWithTracking>
                    </SnackName>
                  </Tooltip>
                </SnackDescription>
                <Tooltip content={providerSpecialityTitle}>
                  <SnackRole
                    color="$neutralGray"
                    data-auto-testid="get-provider-details-role"
                    data-testid="get-provider-details-role"
                  >
                    {providerSpecialityTitle}
                  </SnackRole>
                </Tooltip>
              </Flex>
            </Flex>

            <SnackDetails
              alignContent="center"
              alignItems="center"
              direction="column"
            >
              {providerType === ProviderType.PRACTITIONER &&
                !suppressPatientReviews && (
                  <Flex
                    css={{
                      '.abyss-flex-root': {
                        cursor: healthGradeRatingCursor,
                      },
                    }}
                    data-testid="snack-card-clickable-reviews"
                    onClick={() => {
                      healthGradeRating !== '' &&
                        navigateToSnackDetails(
                          Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM,
                          false,
                          isTieredProvider
                        );
                    }}
                  >
                    <RatingContainer>
                      <ProviderRating
                        data-testid="rating-container"
                        healthGradeRating={healthGradeRating}
                        providerId={providerType}
                        sectionTypeDerived={providerType}
                        size={13}
                        surveyCount={surveyCount}
                        underline={false}
                      />
                    </RatingContainer>
                  </Flex>
                )}
              <Miles
                alignContent="center"
                alignItems="center"
                data-auto-testid="get-provider-details-miles"
                data-testid="get-provider-details-miles"
              >
                {mobileScreen && (
                  <Drawer
                    css={DistanceDrawerStyles}
                    data-auto-testid={snackCardModalTestId}
                    data-testid={snackCardModalTestId}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    position="bottom"
                    size="200"
                    title={t('CONTENT_FILTERS.DISTANCE')}
                  >
                    {t('DISTANCE_POPOVER_CONTENT')}
                  </Drawer>
                )}
                <MilesContent
                  data-auto-testid={providerDetailsMilesInfo}
                  data-testid={providerDetailsMilesInfo}
                  onClick={() => {
                    handleAdobeLinkTrack(providerDetailsMilesInfo);
                    gotoDirection(
                      Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM
                    );
                  }}
                >
                  <IconMaterial
                    icon="directions"
                    size={mobileScreen ? 'sm' : directionsIconSize}
                  />
                  <MilesText aria-label={`${milesdata} ${t('miles')}`}>
                    {milesdata} {t('miles')}
                  </MilesText>
                  {mobileScreen && (
                    <Button
                      css={{
                        'abyss-button-root': {
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          padding: '0px',
                        },
                      }}
                      data-auto-testid="snack-card-miles-Info"
                      data-testid="snack-card-miles-Info"
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsOpen(true);
                      }}
                      variant="ghost"
                    >
                      <IconMaterial
                        css={{
                          'abyss-icon': {
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          },
                        }}
                        icon="info"
                        size={16}
                        variant="outlined"
                      />
                    </Button>
                  )}
                </MilesContent>
                {!mobileScreen && (
                  <SnackCardPopoverContainer>
                    <Popover
                      aria-label={t(
                        'ACCESSIBILITY_LABELS.INFORMATION_ABOUT_DISTANCE'
                      )}
                      content={t('DISTANCE_POPOVER_CONTENT')}
                      data-auto-testid={snackCardModalTestId}
                      data-testid={snackCardModalTestId}
                      onOpenChange={() => {
                        setIsOpen(!isOpen);
                      }}
                      open={isOpen}
                      title={
                        <Text size="$sm">{t('CONTENT_FILTERS.DISTANCE')}</Text>
                      }
                      width={220}
                    >
                      <IconMaterial
                        data-auto-testid="snack-card-miles-Info"
                        data-testid="snack-card-miles-Info"
                        icon="info"
                        onClick={() => setIsOpen(true)}
                        size={16}
                        variant="outlined"
                      />
                    </Popover>
                  </SnackCardPopoverContainer>
                )}
              </Miles>
            </SnackDetails>
          </SnackInfoCard>
        </SnackContainer>
      )}
    </React.Fragment>
  );
};
