import { FilterCollectionModel } from '../../common/Constants';

export const MEDICAL_FILTER_DRAWER_ORDER = (
  isCns: boolean
): FilterCollectionModel[] => [
  FilterCollectionModel.TIERED_PROVIDERS,
  FilterCollectionModel.DISTANCE,
  FilterCollectionModel.PATIENT_REVIEWS,
  FilterCollectionModel.PREFERRED_FACILITY,
  FilterCollectionModel.SPECIALTY_CODE,
  FilterCollectionModel.FACILITY_TYPE,
  FilterCollectionModel.BEHAVIORAL_PROGRAM_INDICATOR,
  FilterCollectionModel.AREA_OF_EXPERTISE,
  FilterCollectionModel.UPCOMING_AVAILABILITY,
  FilterCollectionModel.EVENING_AND_WEEKEND,
  FilterCollectionModel.HEALTH_SUPPLIES_OFFERED,
  FilterCollectionModel.ACCESSIBILITY_CODE,
  FilterCollectionModel.ACONAME,
  FilterCollectionModel.CULTURAL_COMPETENCE,
  FilterCollectionModel.HOSPITAL_AFFILIATIONS,
  FilterCollectionModel.ESSENTIAL_COMMUNITY_PROVIDER,
  isCns
    ? FilterCollectionModel.GENDER_CODE
    : FilterCollectionModel.LANGUAGE_CODE,
  FilterCollectionModel.RACE_CODE,
  FilterCollectionModel.ETHNICITY_CODE,
  FilterCollectionModel.MEDICAL,
  isCns
    ? FilterCollectionModel.LANGUAGE_CODE
    : FilterCollectionModel.GENDER_CODE,
  FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
  FilterCollectionModel.BOARD_CERTIFIED_SPECIALITY_INDICATOR,
];

export const MEDICAL_FILTER_CHIP_ORDER = (
  isMnrOrIfp: boolean
): FilterCollectionModel[] => [
  ...(isMnrOrIfp ? [FilterCollectionModel.ACCEPTING_NEW_PATIENTS] : []),
  isMnrOrIfp
    ? FilterCollectionModel.PREMIUM_CARE_PHYSICIAN
    : FilterCollectionModel.DISTANCE,
  isMnrOrIfp
    ? FilterCollectionModel.DISTANCE
    : FilterCollectionModel.PREMIUM_CARE_PHYSICIAN,
  FilterCollectionModel.PATIENT_REVIEWS,
  FilterCollectionModel.MEDICAL,
  FilterCollectionModel.PREFERRED_FACILITY,
  FilterCollectionModel.UPCOMING_AVAILABILITY,
  FilterCollectionModel.FACILITY_TYPE,
  FilterCollectionModel.SPECIALTY_CODE,
  FilterCollectionModel.HEALTH_SUPPLIES_OFFERED,
  FilterCollectionModel.BEHAVIORAL_PROGRAM_INDICATOR,
  FilterCollectionModel.AREA_OF_EXPERTISE,
  FilterCollectionModel.EVENING_AND_WEEKEND,
  FilterCollectionModel.ACCESSIBILITY_CODE,
  FilterCollectionModel.GENDER_CODE,
  FilterCollectionModel.ETHNICITY_CODE,
  FilterCollectionModel.HOSPITAL_AFFILIATIONS,
  ...(isMnrOrIfp ? [] : [FilterCollectionModel.ACCEPTING_NEW_PATIENTS]),
  FilterCollectionModel.TIERED_PROVIDERS,
  FilterCollectionModel.LANGUAGE_CODE,
];

export const BEHAVIORAL_FILTER_DRAWER_ORDER = (
  isCns: boolean
): FilterCollectionModel[] => [
  FilterCollectionModel.PATIENT_REVIEWS,
  FilterCollectionModel.TIERED_PROVIDERS,
  FilterCollectionModel.DISTANCE,
  FilterCollectionModel.VIRTUAL_CARE_OPTIONS,
  FilterCollectionModel.AGE_RANGE,
  FilterCollectionModel.PROGRAM,
  FilterCollectionModel.PROGRAM_CATEGORY,
  FilterCollectionModel.PREFERRED_FACILITY,
  FilterCollectionModel.SPECIALTY_CODE,
  FilterCollectionModel.BEHAVIORAL_PROGRAM_INDICATOR,
  FilterCollectionModel.AREA_OF_EXPERTISE,
  FilterCollectionModel.UPCOMING_AVAILABILITY,
  FilterCollectionModel.HOSPITAL_AFFILIATIONS,
  FilterCollectionModel.EVENING_AND_WEEKEND,
  FilterCollectionModel.ESSENTIAL_COMMUNITY_PROVIDER,
  FilterCollectionModel.HEALTH_SUPPLIES_OFFERED,
  FilterCollectionModel.ACCESSIBILITY_CODE,
  FilterCollectionModel.ACONAME,
  FilterCollectionModel.CULTURAL_COMPETENCE,
  isCns
    ? FilterCollectionModel.GENDER_CODE
    : FilterCollectionModel.LANGUAGE_CODE,
  FilterCollectionModel.RACE_CODE,
  FilterCollectionModel.ETHNICITY_CODE,
  FilterCollectionModel.MEDICAL,
  isCns
    ? FilterCollectionModel.LANGUAGE_CODE
    : FilterCollectionModel.GENDER_CODE,
  FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
];

export const BEHAVIORAL_FILTER_CHIP_ORDER = [
  FilterCollectionModel.DISTANCE,
  FilterCollectionModel.VIRTUAL_CARE_OPTIONS,
  FilterCollectionModel.AGE_RANGE,
  FilterCollectionModel.PROGRAM,
  FilterCollectionModel.VIRTUAL_CARE,
  FilterCollectionModel.PATIENT_REVIEWS,
  FilterCollectionModel.MEDICAL,
  FilterCollectionModel.UPCOMING_AVAILABILITY,
  FilterCollectionModel.PREFERRED_FACILITY,
  FilterCollectionModel.SPECIALTY_CODE,
  FilterCollectionModel.HEALTH_SUPPLIES_OFFERED,
  FilterCollectionModel.BEHAVIORAL_PROGRAM_INDICATOR,
  FilterCollectionModel.AREA_OF_EXPERTISE,
  FilterCollectionModel.EVENING_AND_WEEKEND,
  FilterCollectionModel.ACCESSIBILITY_CODE,
  FilterCollectionModel.GENDER_CODE,
  FilterCollectionModel.ETHNICITY_CODE,
  FilterCollectionModel.HOSPITAL_AFFILIATIONS,
  FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
  FilterCollectionModel.LANGUAGE_CODE,
];

export const DENTAL_FILTER_DRAWER_ORDER = [
  FilterCollectionModel.DISTANCE,
  FilterCollectionModel.TIERED_PROVIDERS,
  FilterCollectionModel.PATIENT_REVIEWS,
  FilterCollectionModel.SPECIALTY_CODE,
  FilterCollectionModel.SERVICES_OFFERED,
  FilterCollectionModel.EVENING_AND_WEEKEND,
  FilterCollectionModel.ACCESSIBILITY_CODE,
  FilterCollectionModel.GENDER_CODE,
  FilterCollectionModel.LANGUAGE_CODE,
  FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
  FilterCollectionModel.ACCOMMODATE_OFFERED,
];

export const DENTAL_FILTER_CHIP_ORDER = [
  FilterCollectionModel.DISTANCE,
  FilterCollectionModel.PATIENT_REVIEWS,
  FilterCollectionModel.SERVICES_OFFERED,
  FilterCollectionModel.LANGUAGE_CODE,
];

export const VISION_FILTER_DRAWER_ORDER = [
  FilterCollectionModel.DISTANCE,
  FilterCollectionModel.PREFERRED_FACILITY,
  FilterCollectionModel.TIERED_PROVIDERS,
  FilterCollectionModel.SPECIALTY_CODE,
  FilterCollectionModel.FACILITY_TYPE,
  FilterCollectionModel.PATIENT_REVIEWS,
  FilterCollectionModel.SERVICES_OFFERED,
  FilterCollectionModel.EVENING_AND_WEEKEND,
  FilterCollectionModel.ACCESSIBILITY_CODE,
  FilterCollectionModel.GENDER_CODE,
  FilterCollectionModel.LANGUAGE_CODE,
  FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
];

export const VISION_FILTER_CHIP_ORDER = [
  FilterCollectionModel.DISTANCE,
  FilterCollectionModel.PATIENT_REVIEWS,
  FilterCollectionModel.FACILITY_TYPE,
  FilterCollectionModel.PREFERRED_FACILITY,
  FilterCollectionModel.SERVICES_OFFERED,
  FilterCollectionModel.LANGUAGE_CODE,
];
