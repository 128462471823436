import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { IconBrand } from '@abyss/web/ui/IconBrand';

export const CrisisSupportBox = styled(Box, {
  dynamic: ({ cssProps: { displayCarousel } }) => ({
    marginBottom: displayCarousel ? '' : '16px',
  }),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '8px',
  position: 'relative',
  flex: '1 0 0',
  padding: '0',
  alignSelf: 'stretch',
});

export const CrisisSupportIcon = styled(IconBrand, {
  zIndex: '1',
  position: 'absolute',
  bottom: '0px',
  height: '100% !important',
  right: '5px',
  '@screen < $sm': {
    bottom: '0px',
    height: '100% !important',
    right: '-5px',
  },
});

export const crisisSupportCardContentStyle = {
  color: '#323333',
  fontSize: '$md',
  fontWeight: '400',
  fontStyle: '$primary',
  textAlign: 'left',
  lineHeight: '20px',
  width: '100%',
  '@screen < $sm': {
    fontSize: '$sm',
    lineHeight: '18px',
  },
};

export const crisisSupportCardStyle = {
  width: '384px',
  height: '100%',
  minWidth: '144px',
  padding: '0px',
  '@screen < $sm': {
    width: '311px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
};

export const crisisSupportCardContainerStyle = {
  'abyss-flex-root': {
    display: 'flex',
    maxWidth: '284px',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '100%',
    justifyContent: 'space-evenly',
    padding: '16px',
    '@screen < $sm': {
      width: '80%',
      maxWidth: '209px',
    },
  },
};

export const CrisisSupportCardCta = styled('div', {
  fontSize: '$md',
  lineHeight: '20px',
  display: 'block',
  marginTop: '10px',
  fontWeight: '$bold',
  color: '$info1',
  '& > span': {
    color: '$gray8',
    fontWeight: 400,
  },
  '@screen < $sm': {
    fontSize: '$sm',
    lineHeight: '18px',
  },
});
