import { config } from '@abyss/web/tools/config';

import SAVE_RECOMMENDATION_PREFERENCES from './SaveRecommendationPreferences.graphql';
import { useQuery } from '@abyss/web/hooks/useQuery';

export const useSaveRecommendationPreferences = (options) => {
 const [queryResults, queryAction] = useQuery(SAVE_RECOMMENDATION_PREFERENCES, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'saveRecommendationPreferencesStatus',
    onCompleted: (result) => {
      if(options?.handleSuccess) {
        options?.handleSuccess();
      }
      return result;
    },
    onError: (error) => {
      if(options?.handleError) {
        options?.handleError();
      }
      throw error;
    }
  });

   return [queryResults, queryAction];
}
