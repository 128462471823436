import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../common/Constants';
import {
  convertObjectToUrlParams,
  getFeatureFlag,
} from '../../../../common/Utils';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { useAnalyticsStore } from '../../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import { usePCPStore } from '../../../../store/usePCPStore';
import { PCPSearchStore } from '../../../../store/usePCPStore/pcpStore';
import { useSearchStore } from '../../../../store/useSearchStore';
import { SearchState } from '../../../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import { handleSearchBarSuggestionClickOrEnter } from '../../../../utils/typeahead.utils';
import { getLoggedInMember } from '../../../../utils/user.utils';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import {
  CustomAttributesBlock,
  convertTypeaheadProviderIdAndType,
  getTypeAheadCategory,
} from '../../../Utils/adobeTrackUtils/adobeTrackUtils';
import { getLinkNameForAnalytics } from '../utils';
import { HighlightedSuggestion } from './HighlightedSuggestion';
import {
  Divider,
  DropdownContainer,
  SuggestionListContainer,
  SuggestionListItem,
  SuggestionSectionItem,
  isPrimaryActiveSuggestion,
  keywordSearchDropdownIconColor,
} from './KeywordSearchDropdown.styled';
import { Skeleton } from './Skeleton';
import { SuggessionList } from './SuggessionList';
import { UserTextSearch } from './UserTextSearch';

type Props = {
  activeSuggestion: number;
  headers: ResponseHeaders;
  isKeywordSearchLoading: boolean;
  typeAheadSuggestions?: any;
  keywordSearchTerm: string;
  isLoading: boolean;
  setIsFocusedOnKeywordSearchInput: (val: boolean) => void;
  enter: boolean;
  dentalCoverage: string;
  medicalCoverage: string;
  memberDDP: string;
  visionCoverage: string;
  autoCompleteCallCompleted: boolean;
  getCoverageType: Array<string>;
  combinedRollupCodes: string;
  aggregatedAoeCodes?: string[];
  bhProgramFacilityAgg: string;
  hideDisabledTypeahead: boolean;
  blurKeywordSearchInput: () => void;
  checkForEmptyLocation: () => void;
  enableUESSuggestionMatch: boolean;
  handleGeneralSearchClick?: () => void;
  isLLMGeneralSearchEnabled?: boolean;
};

export const KeywordSearchDropdown = ({
  activeSuggestion,
  headers,
  isKeywordSearchLoading,
  typeAheadSuggestions,
  keywordSearchTerm,
  isLoading,
  setIsFocusedOnKeywordSearchInput,
  enter,
  visionCoverage,
  dentalCoverage,
  medicalCoverage,
  memberDDP,
  getCoverageType,
  autoCompleteCallCompleted,
  hideDisabledTypeahead,
  blurKeywordSearchInput,
  checkForEmptyLocation,
  handleGeneralSearchClick,
  isLLMGeneralSearchEnabled = false,
}: Props) => {
  const { t } = useTranslation();
  const commonSearchesLagoon: () => any = useLagoon('common-searches');
  const commonSearchesData = commonSearchesLagoon();
  const { navigate } = useRouter();
  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const { onlineRetailers, setSearchStore } = useSearchStore(
    useShallow((state: SearchState) => ({
      setSearchStore: state.setSearchStore,
      onlineRetailers: state.searchStore.onlineRetailers,
    }))
  );

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setCoverageType, setChipValue } = chipStore;

  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const { setPCPSearchState, choosePCP, dependentSeqNbr } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
      choosePCP: state.pcpSearchState.choosePCP,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const noResultsFound = t('No Results Found');
  const specialCharSearch = t('Special char search');
  const shortsearch = t('Short search');
  const specialCharCheck = !/[A-Za-z0-9]/.test(keywordSearchTerm);

  const featureFlags = useLagoon('feature-flags')();
  const dentalSearchLagoonFlag = getFeatureFlag(
    featureFlags,
    'TYPEAHEAD_DENTAL_SEARCH_STARTS_HERE_ENABLED'
  );

  const visionSearchLagoonFlag = getFeatureFlag(
    featureFlags,
    'TYPEAHEAD_VISION_SEARCH_STARTS_HERE_ENABLED'
  );
  const selectedMember = getLoggedInMember();
  const isPopulationAllowed =
    selectedMember?.population?.toLowerCase() === 'unet';

  const [enableRetriveTypeAheadDataFlag, enableSearchResultsV2] =
    useFeatureFlag([
      ConstantsLagoon.FEATURE_FLAGS.ENABLE_RETRIVE_TYPEAHEAD_DATA,
      ConstantsLagoon.FEATURE_FLAGS.ENABLE_SEARCH_RESULTS_V2,
    ]);

  const enableRetriveTypeAheadData =
    enableRetriveTypeAheadDataFlag && enableSearchResultsV2;

  const displayDentalSearchStartsHere =
    dentalCoverage && dentalSearchLagoonFlag;
  const displayVisionSearchStartsHere =
    visionCoverage && visionSearchLagoonFlag;

  const sortItems = (a, b) => {
    if (
      !a?.coverageType?.some((coverageCode: string) =>
        getCoverageType.includes(coverageCode)
      )
    ) {
      return 1;
    }
    if (
      !b?.coverageType?.some((coverageCode: string) =>
        getCoverageType.includes(coverageCode)
      )
    ) {
      return -1;
    }

    return 0;
  };

  useEffect(() => {
    setSearchStore({ onlineRetailers });

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.IS_PROVIDER_CARD_SELECTED,
      false
    );
  }, []);

  if (isKeywordSearchLoading) {
    const text = t('SEARCH_TIME_NOTIFICATION');
    return <Skeleton text={text} />;
  }

  const searchInputOptionLocation = 'search-input-option-list';

  function adobeLinkTrack(value, index, typeAheadProviderLinks = false) {
    const customAttributesBlock =
      value?.searchType === 'Speciality' || typeAheadProviderLinks
        ? undefined
        : ({
            correlationId: headers?.correlationId,
            ...convertTypeaheadProviderIdAndType(value),
          } as CustomAttributesBlock);

    adobeLinkTrackEvent({
      name: getLinkNameForAnalytics(value),
      location: `body:${searchInputOptionLocation}:${getTypeAheadCategory(
        value?.providerType
      )}`,
      type: 'internal',
      searchBlock: {
        linkPosition: index + 1 || '1',
      },
      customAttributesBlock,
    });
  }

  const onChipClicked = (value, coverageType) => {
    adobeLinkTrackEvent({
      name: value,
      location: `body:${searchInputOptionLocation}`,
    });
    const chipStoreValues = {
      chipValue: value,
      coverageType,
    };
    setChipValue(value);
    setCoverageType(coverageType);
    const urlParams = convertObjectToUrlParams(chipStore, {
      ...chipStoreValues,
    });
    navigate(`/${urlParams}`, { replace: true });
  };

  const specialityPreferenceTable = useLagoon(
    Constants.LAGOON_TABLE.SPECIALITY_PREFERENCE
  )().map((item) => ({
    rollupCode: item.rollupCode,
    specialityCode: item.specialityCode,
  }));

  const onSuggestionClick = (value, index) => {
    if (value) {
      setIsFocusedOnKeywordSearchInput(false);
      adobeLinkTrack(value, index);
      handleSearchBarSuggestionClickOrEnter({
        enableRetriveTypeAheadData,
        specialityPreferenceTable,
        value,
        searchTerm: keywordSearchTerm,
        getCoverageType,
        dependentSeqNbr,
        choosePCP,
        chipStore,
        setTypeaheadSearchStore,
        setAnalyticsState,
        setPCPSearchState,
        setChipValue,
        setCoverageType,
        setDetailsStore,
        navigate,
      });
      checkForEmptyLocation();
    }
  };

  const setMessage = () => {
    let message: string;
    if (specialCharCheck && keywordSearchTerm !== '') {
      message = specialCharSearch;
    } else if (keywordSearchTerm?.length <= 1) {
      message = shortsearch;
    } else {
      message = noResultsFound;
    }
    return message;
  };

  const onSuggestionClickWithStartIndex = (commonSearchesStartIndex) => {
    onSuggestionClick(
      commonSearchesData[activeSuggestion - commonSearchesStartIndex],
      activeSuggestion - commonSearchesStartIndex
    );
  };

  const OnChipClickedUsingCategory = (chipCategory) => {
    if (activeSuggestion === 0) {
      if (Constants.CHIPS_CATEGORIES.DENTAL === chipCategory)
        onChipClicked(Constants.CHIPS_CATEGORIES.DENTAL, 'D');
      else if (Constants.CHIPS_CATEGORIES.VISION === chipCategory)
        onChipClicked(Constants.CHIPS_CATEGORIES.VISION, 'V');
    } else if (activeSuggestion > 0) {
      onSuggestionClickWithStartIndex(1);
    }
  };

  if (
    enter &&
    keywordSearchTerm?.trim().length < Constants.MIN_KEYWORD_SEARCHTERM_LENGTH
  ) {
    blurKeywordSearchInput();
    setIsFocusedOnKeywordSearchInput(false);
    if (displayDentalSearchStartsHere && displayVisionSearchStartsHere) {
      if (activeSuggestion === 0) {
        onChipClicked(Constants.CHIPS_CATEGORIES.DENTAL, 'D');
      } else if (activeSuggestion === 1) {
        onChipClicked(Constants.CHIPS_CATEGORIES.VISION, 'V');
      } else if (activeSuggestion > 1) {
        // When both dental and vision search starts here are displayed, common searches index starts from 2.
        onSuggestionClickWithStartIndex(2);
      }
    } else if (displayDentalSearchStartsHere) {
      // When both only dental search starts here is displayed, common searches index starts from 1.
      OnChipClickedUsingCategory(Constants.CHIPS_CATEGORIES.DENTAL);
    } else if (displayVisionSearchStartsHere) {
      // When both only vision search starts here is displayed, common searches index starts from 1.
      OnChipClickedUsingCategory(Constants.CHIPS_CATEGORIES.VISION);
    } else {
      // When both dental and vision are not displayed, common searches index starts from 0.
      onSuggestionClickWithStartIndex(0);
    }
  }

  const numberOfOtherCoverageOptionsForNoKeywordInput =
    (displayDentalSearchStartsHere ? 1 : 0) +
    (displayVisionSearchStartsHere ? 1 : 0);

  return (
    <React.Fragment>
      {(keywordSearchTerm?.trim().length === 0 ||
        keywordSearchTerm?.trim().length === 1) &&
      !enter ? (
        <React.Fragment>
          <Divider />
          {displayDentalSearchStartsHere || displayVisionSearchStartsHere ? (
            <Divider />
          ) : null}
          <SuggestionListContainer
            data-auto-testid="suggestion-list-container"
            data-testid="suggestion-list-container"
          >
            <DropdownContainer
              aria-label="common searches"
              data-testid="primary-dropdown-container"
              role="listbox"
            >
              {displayVisionSearchStartsHere ? (
                <SuggestionSectionItem
                  aria-posinset={displayDentalSearchStartsHere ? 1 : 0}
                  className={`${
                    activeSuggestion === (displayDentalSearchStartsHere ? 1 : 0)
                      ? 'active'
                      : ''
                  }`}
                  id={
                    activeSuggestion === (displayDentalSearchStartsHere ? 1 : 0)
                      ? 'primary-search-bar-result-suggestion'
                      : ''
                  }
                  role="option"
                >
                  <SuggestionListItem
                    className="suggestion-link-span"
                    css={{
                      '&.abyss-flex-root':
                        activeSuggestion ===
                        (displayDentalSearchStartsHere ? 1 : 0)
                          ? isPrimaryActiveSuggestion
                          : '',
                    }}
                    data-testid="search-input-box-vision-common-suggestion"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      setIsFocusedOnKeywordSearchInput(false);
                      onChipClicked(Constants.CHIPS_CATEGORIES.VISION, 'V');
                    }}
                    style={{ whiteSpace: 'pre' }}
                    tabIndex={-1}
                  >
                    {t('VISION_SEARCH')}
                    <IconMaterial icon="chevron_right" />
                  </SuggestionListItem>
                </SuggestionSectionItem>
              ) : null}
              {displayDentalSearchStartsHere ? (
                <SuggestionSectionItem
                  aria-posinset={0}
                  className={`${activeSuggestion === 0 ? 'active' : ''}`}
                  id={
                    activeSuggestion === 0
                      ? 'primary-search-bar-result-suggestion'
                      : ''
                  }
                  role="option"
                >
                  <SuggestionListItem
                    className="suggestion-link-span"
                    css={{
                      '&.abyss-flex-root':
                        activeSuggestion === 0 ? isPrimaryActiveSuggestion : '',
                    }}
                    data-testid="search-input-box-dental-common-suggestion"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      setIsFocusedOnKeywordSearchInput(false);
                      onChipClicked(Constants.CHIPS_CATEGORIES.DENTAL, 'D');
                    }}
                    style={{ whiteSpace: 'pre' }}
                    tabIndex={-1}
                  >
                    {t('DENTAL_SEARCH')}
                    <IconMaterial icon="chevron_right" />
                  </SuggestionListItem>
                </SuggestionSectionItem>
              ) : null}
              {medicalCoverage
                ? commonSearchesData
                    .filter(({ coverageType }) =>
                      getCoverageType.includes(coverageType)
                    )
                    .map((value, index) => (
                      <SuggestionSectionItem
                        aria-posinset={
                          index + numberOfOtherCoverageOptionsForNoKeywordInput
                        }
                        className={`${
                          activeSuggestion ===
                          index + numberOfOtherCoverageOptionsForNoKeywordInput
                            ? 'active'
                            : ''
                        } ${index === 0 ? 'first-child' : ''}`}
                        id={
                          activeSuggestion ===
                          index + numberOfOtherCoverageOptionsForNoKeywordInput
                            ? 'primary-search-bar-result-suggestion'
                            : ''
                        }
                        role="option"
                      >
                        {index === 0 && (
                          <Text
                            data-testid="heading-common-search"
                            fontWeight={700}
                            size="$sm"
                          >
                            {t('COMMON_SEARCH')}
                          </Text>
                        )}
                        <SuggestionListItem
                          className="suggestion-link-span"
                          css={{
                            '&.abyss-flex-root':
                              activeSuggestion ===
                              index +
                                numberOfOtherCoverageOptionsForNoKeywordInput
                                ? isPrimaryActiveSuggestion
                                : '',
                          }}
                          data-testid="search-input-box-common-suggestion-link"
                          key={value.id}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            ev.preventDefault();
                            onSuggestionClick(value, index);
                          }}
                          style={{ whiteSpace: 'pre' }}
                          tabIndex={-1}
                        >
                          <span className="icon-span">
                            <IconMaterial
                              color={keywordSearchDropdownIconColor}
                              icon="search"
                            />
                          </span>
                          <HighlightedSuggestion
                            keyword={value?.translatedPsxKeyword}
                            text={value?.translatedPsxKeyword}
                          />
                        </SuggestionListItem>
                      </SuggestionSectionItem>
                    ))
                : null}
            </DropdownContainer>
          </SuggestionListContainer>
        </React.Fragment>
      ) : typeAheadSuggestions?.length && !enter ? (
        <SuggessionList
          activeSuggestion={activeSuggestion}
          getCoverageType={getCoverageType}
          handleGeneralSearchClick={handleGeneralSearchClick}
          hideDisabledTypeahead={hideDisabledTypeahead}
          isLLMGeneralSearchEnabled={isLLMGeneralSearchEnabled}
          isPopulationAllowed={isPopulationAllowed}
          keywordSearchTerm={keywordSearchTerm}
          memberDDP={memberDDP}
          onSuggestionClick={onSuggestionClick}
          sortItems={sortItems}
          typeAheadSuggestions={typeAheadSuggestions}
        />
      ) : (
        (autoCompleteCallCompleted || specialCharCheck) &&
        !isLoading &&
        !enter && (
          <SuggestionListContainer
            data-auto-testid="suggestion-list-container"
            data-testid="suggestion-list-containter"
          >
            <DropdownContainer
              data-testid="primary-dropdown-container"
              role="listbox"
            >
              <SuggestionSectionItem
                className={`first-child ${activeSuggestion === 0}`}
              >
                {isLLMGeneralSearchEnabled && isPopulationAllowed ? (
                  <UserTextSearch
                    data-testid="user-text-search-common-suggestion"
                    keywordSearchTerm={keywordSearchTerm}
                    onClick={handleGeneralSearchClick}
                  />
                ) : (
                  <span
                    className="no-results"
                    data-testid="suggestion-list-no-results"
                    role="alert"
                  >
                    {setMessage()}
                  </span>
                )}
              </SuggestionSectionItem>
            </DropdownContainer>
          </SuggestionListContainer>
        )
      )}
    </React.Fragment>
  );
};
