import { styled } from '@abyss/web/tools/styled';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import {
  HeadingStyle,
  SubTitleStyle,
  TitleStyle,
  textStyle,
  titleTextStyle,
} from './SearchResultsDynamicTitle.styled';

const TitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'left',
  paddingBottom: '16px',
  flexWrap: 'wrap',
  flexDirection: 'column',
  '@screen < $md': {
    padding: '0 $md 12px',
  },
});
type SearchResultsDynamicTitlePropType = {
  choosePCP: Boolean;
  offset: Number;
  searchTitle: String;
  chipCount: number;
  onSpecialtyClick: (value: boolean, title: string) => void;
  isMixedSuggestions?: boolean;
  isNameSearchEnabled?: boolean;
  isSpecialtySearchEnabled?: boolean;
  specialtySearch: boolean;
  hasOnlyPseudoRollupCodes: boolean;
  specialtyResults?: { chipEnabled: boolean; chipCategory: string }[];
};
export const SearchResultsDynamicTitle = ({
  choosePCP,
  offset,
  searchTitle,
  chipCount,
  onSpecialtyClick,
  isMixedSuggestions,
  specialtySearch,
  isNameSearchEnabled,
  isSpecialtySearchEnabled,
  hasOnlyPseudoRollupCodes,
  specialtyResults = [],
}: SearchResultsDynamicTitlePropType) => {
  const { t } = useTranslation();
  const [autoCompleteParallelSearchFlow] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.AUTOCOMPLETE_PARALLEL_SEARCHFLOW,
  ]);

  let chipCountMessage = '';
  if (chipCount === 1) {
    chipCountMessage = `${chipCount} ${t('category')}`;
  } else {
    chipCountMessage = `${chipCount} ${t('categories')}`;
  }
  const data = useLagoon('ui-messaging')();
  const searchSpecialityResultTitle = find(data, {
    key: ConstantsLagoon.MIXED_SEARCH_RESULT_PAGE.SEARCH_SPECIALITY_RESULTS,
  });
  const searchNameResultTitle = find(data, {
    key: ConstantsLagoon.MIXED_SEARCH_RESULT_PAGE.SEARCH_NAME_RESULTS,
  });
  const searchSpecialityResultSubTitle = find(data, {
    key: ConstantsLagoon.MIXED_SEARCH_RESULT_PAGE
      .SEARCH_SPECIALITY_RESULTS_SUBTITLE,
  });
  const searchNameResultSubTitle = find(data, {
    key: ConstantsLagoon.MIXED_SEARCH_RESULT_PAGE.SEARCH_NAME_RESULTS_SUBTITLE,
  });
  const searchResultTitle = find(data, {
    key: ConstantsLagoon.MIXED_SEARCH_RESULT_PAGE.SEARCH_RESULT_TITLE,
  });
  const searchInsteadForHeading = find(data, {
    key: ConstantsLagoon.MIXED_SEARCH_RESULT_PAGE.SEARCH_INSTEAD_FOR_HEADING,
  });
  const searchPreferredFacilityResultSubTitle = find(data, {
    key: ConstantsLagoon.MIXED_SEARCH_RESULT_PAGE
      .SEARCH_PREFERRED_FACILITY_RESULTS_SUBTITLE,
  });

  let title = `${searchResultTitle.message.replace(
    '{searchTitle}',
    searchTitle
  )} ${t(chipCountMessage)}`;
  let subTitle;
  if (isMixedSuggestions && specialtySearch) {
    title = `${searchSpecialityResultTitle.message.replace(
      '{searchTitle}',
      searchTitle
    )} ${t(chipCountMessage)}`;
    subTitle = searchSpecialityResultSubTitle.message.replace(
      '{searchTitle}',
      searchTitle
    );
  }

  if (isMixedSuggestions && !specialtySearch) {
    title = `${searchNameResultTitle.message.replace(
      '{searchTitle}',
      searchTitle
    )} ${t(chipCountMessage)}`;
    subTitle = searchNameResultSubTitle.message.replace(
      '{searchTitle}',
      searchTitle
    );
  }
  if (isMixedSuggestions && !specialtySearch && hasOnlyPseudoRollupCodes) {
    title = `${searchNameResultTitle.message.replace(
      '{searchTitle}',
      searchTitle
    )} ${t(chipCountMessage)}`;
    subTitle = searchPreferredFacilityResultSubTitle.message.replace(
      '{searchTitle}',
      searchTitle
    );
  }
  const dataTestId: string = hasOnlyPseudoRollupCodes
    ? 'results-heading-link-for-preferred-facility'
    : 'results-heading-for-specialty';

  const isCategoryLinkEnabled = specialtyResults?.some(
    (item) => item?.chipEnabled
  );

  return (
    <Fragment>
      {!choosePCP && searchTitle && (
        <Fragment>
          <TitleContainer>
            <Heading
              css={titleTextStyle}
              data-testid="result-title"
              display="h4"
              offset={0}
            >
              <Markdown>{title}</Markdown>
            </Heading>
            {isMixedSuggestions &&
            specialtySearch &&
            (autoCompleteParallelSearchFlow === false ||
              isNameSearchEnabled) ? (
              <Text css={textStyle}>
                <TitleStyle>
                  <HeadingStyle>{searchInsteadForHeading.message}</HeadingStyle>
                  <Text
                    css={{
                      'abyss-heading-root': {
                        '@screen < $md': {
                          fontSize: '$mediumText !important',
                          lineHeight: '$lg !important',
                        },
                      },
                    }}
                    data-auto-testid="results-heading-for-provider-name"
                    data-testid="results-heading-for-provider-name"
                    display="h4"
                    offset={1}
                    onClick={() => onSpecialtyClick(false, 'name search')}
                    textAlign="left"
                  >
                    <SubTitleStyle>
                      {subTitle}
                      <IconMaterial icon="chevron_right" size="24px" />
                    </SubTitleStyle>
                  </Text>
                </TitleStyle>
              </Text>
            ) : null}
            {isMixedSuggestions &&
            !specialtySearch &&
            isCategoryLinkEnabled &&
            (autoCompleteParallelSearchFlow === false ||
              isSpecialtySearchEnabled) ? (
              <Text css={textStyle}>
                <TitleStyle>
                  <HeadingStyle>{searchInsteadForHeading.message}</HeadingStyle>
                  <Text
                    css={{
                      'abyss-heading-root': {
                        '@screen < $md': {
                          fontSize: '$mediumText !important',
                          lineHeight: '$lg !important',
                        },
                      },
                    }}
                    data-auto-testid={dataTestId}
                    data-testid={dataTestId}
                    display="h4"
                    offset={1}
                    onClick={() => onSpecialtyClick(true, 'specialty search')}
                    textAlign="left"
                  >
                    <SubTitleStyle>
                      {subTitle}
                      <IconMaterial icon="chevron_right" size="24px" />
                    </SubTitleStyle>
                  </Text>
                </TitleStyle>
              </Text>
            ) : null}
          </TitleContainer>
        </Fragment>
      )}
      {choosePCP && (
        <TitleContainer>
          <Heading
            css={{
              'abyss-heading-root': {
                '@screen >= $md': {
                  paddingBottom: '16px !important',
                  paddingTop: '8px !important',
                },
                '@screen < $sm': {
                  fontSize: '$mediumText !important',
                  lineHeight: '$lg !important',
                },
              },
            }}
            data-testid="provider-results-for-choose-pcp"
            display="h4"
            offset={offset}
            textAlign="left"
          >
            {t('Results for')} {t('primary care doctor')}
          </Heading>
        </TitleContainer>
      )}
    </Fragment>
  );
};
