import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Markdown } from '@abyss/web/ui/Markdown';

export const BannerStyled = styled(Flex, {
  backgroundColor: '$info2',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  borderTop: '1px solid $info1',
  borderBottom: '1px solid $info1',
  padding: '16px ',
  '@screen < $md': {
    display: 'inline-block',
    '& div': {
      display: 'inline',
    },
  },
  '@screen < $sm': {
    padding: '8px 16px',
  },
});

export const IconContainer = styled('div', {
  height: '18px',
  width: '18px',
  '@screen < $sm': {
    position: 'relative',
    top: '4px',
  },
});

export const StyledMarkdown = styled(Markdown, {
  marginLeft: '4px',
  fontSize: '$md',
  lineHeight: '20px',
  color: '$gray8',
  flex: 1,
  '& span': {
    marginLeft: '14px',
  },
  '@screen < $sm': {
    fontSize: '$sm',
    lineHeight: '18px',

    '& span': {
      display: 'block',
      marginLeft: 0,
    },
  },
});
