import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Text } from '@abyss/web/ui/Text';

export const StyledDrawer = styled(Drawer, {
  zIndex: '999 !important',
  '.abyss-modal-header-container': {
    width: 'fit-content',
  },
  '.abyss-modal-content-container': {
    borderRadius: 0,
    '@screen < $sm': {
      top: '25px',
      height: 'calc(100vh - 26px)',
      width: '100%',
      borderRadius: '20px 20px 0px 0px',
      color: '$primary1',
      border: '1px solid $primary1',
    },
  },
  '.abyss-modal-close-button': {
    '@screen < $sm': {
      top: '12px',
      right: '12px',
    },
  },
  '.abyss-modal-body': {
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const SmartChoiceCard = styled('div', {
  marginTop: 0,
  borderWidth: '0.5px',
  borderColor: '#CBCCCD',
  borderStyle: 'solid',
  width: '100%',
  borderRadius: '8px',
  padding: '16px',
  backgroundColor: 'white',
  '@screen < $lg': {
    width: '100%',
    alignItems: 'flex-start',
  },
  '.desc-text': {
    color: '$gray7',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: '$primary',
    lineHeight: '18px',
    '@screen < $md': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
    },
  },
  '.desc-text-l1': {
    color: '$gray7',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: '$primary',
    lineHeight: '20px',
    '@screen < $md': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  },
  '.abyss-card-root': {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    paddingTop: '$md',
    paddingBottom: '$md',
    display: 'flex',
    '@screen < $md': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
});

export const BreakDownContainer = styled('div', {
  borderWidth: '0.5px',
  borderColor: '#CBCCCD',
  borderStyle: 'solid',
  width: '100%',
  borderRadius: '8px',
  padding: '16px 32px',
  '@screen < $md': {
    alignItems: 'flex-start',
    padding: '16px',
  },
});

export const RightContainer = styled('div', {
  marginLeft: '8px',
  display: 'flex',
  flexDirection: 'column',
  '.smart-choice-text': {
    fontFamily: '$primary',
    lineHeight: '32px',
    color: '$gray8 !important',
    fontWeight: 700,
  },
});

export const BreakDownValue = styled(Text, {
  fontSize: '14px !important',
  fontWeight: 700,
  paddingTop: '2px',
  paddingBottom: '2px',
  paddingLeft: '8px',
  paddingRight: '8px',
  borderRadius: '4px',
  fontFamily: '$primary',
  lineHeight: '18px',
});

export const PercentageBox = styled('div', {
  width: '92px',
  height: '68px',
  backgroundColor: '#007000',
  borderRadius: '8px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  '@screen < $md': {
    width: '76px',
    height: '60px',
  },
});

export const PercentageText = styled(Text, {
  color: '$white',
  fontSize: '28px !important',
  fontWeight: 700,
  fontFamily: '$primary',
  lineHeight: '36px !important',
  '@screen < $md': {
    fontSize: '22px !important',
    lineHeight: '28px !important',
  },
});

export const BreakDownValueContainer = styled('div', {
  marginTop: '4px',
  marginBottom: '12px',
});

export const BreakDownTitle = styled(Text, {
  color: '$gray8',
  fontSize: '20px',
  fontWeight: 700,
  fontFamily: '$primary',
  lineHeight: '18px',
});

export const smartChoiceLinkStyle = (isMobileScreen) => ({
  fontWeight: '600',
  '& .abyss-insert-element-after': {
    alignItems: 'flex-end',
    '& svg': { fill: '$error1' },
    marginLeft: isMobileScreen ? '-20px' : '-8px',
  },
  '&:hover::before': { display: 'none' },
});
