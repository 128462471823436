import { styled } from '@abyss/web/tools/styled';
import { Link } from '@abyss/web/ui/Link';

export const StyledLinkViewMore = styled(Link, {
  fontFamily: '$primary',
  fontSize: '14px !important',
  fontWeight: '400 !important',
  lineHeight: '20px !important',
  '.abyss-link-root:hover': {
    textDecoration: 'none',
  },
});
