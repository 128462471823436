import { styled } from '@abyss/web/tools/styled';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ItemListContainer = styled('div', {
  overflowY: 'auto',
});

const LocationSuggestionListItem = styled('li', {
  cursor: 'default',
  display: 'flex',
  alignItems: 'center',
  paddingTop: '$sm',
  paddingBottom: '$sm',
  fontSize: '$sm',
  fontFamily: '$primary',
  fontWeight: 'bold',
  scrollMargin: '$sm',
  color: '$interactive1',
  '&:hover, &:focus, &.active, &.selected': {
    backgroundColor: '$gray3',
  },
  variants: {
    active: {
      true: {
        backgroundColor: '$gray3',
      },
    },
  },
});

type Feature = {
  id?: string;
};

interface Props {
  activeIndex: number;
  gqlSug: Feature[];
  items: string[];
  onClick: (item: Feature) => void;
}

export const ItemList: React.FC<Props> = ({
  activeIndex,
  gqlSug,
  items,
  onClick = null,
}) => {
  const { t } = useTranslation();

  return (
    <ItemListContainer>
      <Text
        css={{ 'abyss-text-root': { lineHeight: '20px' } }}
        fontWeight="$bold"
        size="14.22px"
      >
        {t('LOCATION_SEARCH.DID_YOU_MEAN')}
      </Text>
      <ul
        aria-expanded={items?.length > 0}
        aria-live="polite"
        id="search-results"
        role="listbox"
      >
        <span
          aria-live="polite"
          style={{ position: 'absolute', left: '-9999px' }}
        >
          {items?.length}
          {t('LOCATION_SEARCH.SUGGESTIONS_AVAILABLE')}
        </span>
        {items?.map((item, index) => (
          <React.Fragment key={item}>
            <LocationSuggestionListItem
              active={index === activeIndex}
              aria-posinset={index + 1}
              aria-selected={index === activeIndex}
              aria-setsize={items?.length}
              data-auto-testid={`${index}-${item}`}
              data-testid={`${index}-${item}`}
              key={gqlSug[index]?.id}
              onClick={() => {
                if (onClick) {
                  onClick(gqlSug[index]);
                }
              }}
              role="option"
              tabIndex={index === activeIndex ? 0 : -1}
            >
              {item}
            </LocationSuggestionListItem>
          </React.Fragment>
        ))}
      </ul>
    </ItemListContainer>
  );
};
