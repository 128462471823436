import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useToast } from '@abyss/web/hooks/useToast';
import { styled } from '@abyss/web/tools/styled';
import { Alert } from '@abyss/web/ui/Alert';
import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { find } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { emailRegex } from '../../frontends/ProviderSearch/routes/MapView/ShareDrawer/utils';
import { useLagoon } from '../../hooks/useLagoon';
import { useReportInaccurateProvider } from '../../hooks/useReportInaccurateProvider';
import { shortenZipCode } from '../../utils/providerDetails.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { adobeImpressionTrackEvent } from '../AdobeTagging/adobeImpressionTrackEvent';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { mobileOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import {
  OTHER_LOCATION_DETAILS,
  OTHER_PROVIDER_DETAILS,
  TELL_US_MORE_MAX_LENGTH,
} from './constants';
import { FeedbackCheckboxGroup } from './FeedbackChecboxGroup';
import {
  DrawerSubtitle,
  EmailInputStyle,
  FeedbackFormContainer,
  StyledDrawer,
  StyledTextInputArea,
} from './index.style';
import { useFeedbackFormOptions } from './useFeedbackFormOptions';

const FeedbackFormBanner = styled(Alert, {
  '& .abyss-alert-icon': {
    fontSize: '20px !important',
    marginTop: '2px',
  },
  '& .abyss-alert-container': {
    marginBottom: '24px',
  },
  '& .abyss-alert-children-container, & .abyss-alert-title': {
    color: '$gray8',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
  },
  '& .abyss-alert-title': { fontWeight: 700 },
});

export const SendFeedbackFormDrawer = ({
  isOpen,
  setIsOpen,
  type,
  providerData,
  coverageType,
  enableNewFeedbackFormDesign,
  selectedIndex,
  providerLocations,
}) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { infoOptions, locationInformationOptions } = useFeedbackFormOptions();
  const currentMember = getCurrentMember();
  const mobileScreen = useMediaQuery(mobileOnly);

  const addressFormatting = (location) => {
    const address = `${location?.address?.line} ${location?.address?.city}, ${
      location?.address?.state
    } ${shortenZipCode(location?.address?.postalCode)} (${Number(
      location?.distance
    ).toFixed(1)} ${t('PROVIDER_DETAILS.MILES')})`;

    return address;
  };

  const allLocations = providerLocations?.map((location, index) => ({
    value: index,
    label: addressFormatting(location),
  }));

  const [location, setLocation] = React.useState(selectedIndex ?? 0);

  const lagoonData = useLagoon('ui-messaging')();
  const feedbackFormBanner = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.NEW_DESIGN_FEEDBACK_FORM_BANNER,
  });

  const properties: any = {
    buttonStyles: {
      backgroundColor: '$primary2',
      border: '1px solid $gray4',
      padding: '$sm $lg $sm $md',
      height: '2.625em',
      fontWeight: '$semibold',
      borderRadius: '24px',
      color: '$gray8',
    },
    drawerSize: 492,
    drawerposition: 'right',
  };

  const form = useForm({
    defaultValues: {
      notifyMe: false,
      providerInformation: [],
      locationInformation: [],
    },
  });

  const handleSuccess = () => {
    const successMessage = form.getValues('notifyMe')
      ? t('FEEDBACK_FORM.SUCCESS_MESSAGE_WITH_EMAIL')
      : t('FEEDBACK_FORM.SUCCESS_MESSAGE_WITHOUT_EMAIL');
    setIsOpen(false);
    form.reset();
    adobeImpressionTrackEvent({
      type: 'Feedback',
      message: successMessage,
    });
    toast.show({
      id: 'feedback-form-success-toast',
      variant: 'success',
      message: successMessage,
      autoClose: 5000,
    });
  };

  const handleError = () => {
    const errorMessage = t('FEEDBACK_FORM.ERROR_MESSAGE');
    setIsOpen(false);
    form.reset();
    adobeImpressionTrackEvent({
      type: 'Feedback',
      message: errorMessage,
    });
    toast.show({
      id: 'feedback-form-error-toast',
      variant: 'error',
      message: errorMessage,
      autoClose: 5000,
    });
  };

  const [, reportInaccurateProvider] = useReportInaccurateProvider({
    handleSuccess,
    handleError,
  });

  const handleCloseDrawer = () => {
    setIsOpen(false);
    form.reset();
  };

  const isSubmitButtonDisabled = () => {
    const allValues = form.getValues() || {};

    const {
      freeForm,
      providerInformation,
      locationInformation,
      otherProviderDetails,
      otherLocationDetails,
    } = allValues;

    const checkBoxesValues = [...providerInformation, ...locationInformation];

    if (!(checkBoxesValues.length !== 0 || freeForm)) {
      return true;
    }

    if (checkBoxesValues.length !== 0) {
      if (
        providerInformation?.includes(OTHER_PROVIDER_DETAILS) &&
        !otherProviderDetails
      ) {
        return true;
      }
      if (
        locationInformation?.includes(OTHER_LOCATION_DETAILS) &&
        !otherLocationDetails
      ) {
        return true;
      }
    }

    return false;
  };

  const onSubmit = (formData) => {
    const editsCheckboxValues = [
      ...infoOptions[type]?.options,
      ...locationInformationOptions?.options,
    ].reduce((acc, item) => {
      if (
        item.value !== OTHER_PROVIDER_DETAILS &&
        item.value !== OTHER_LOCATION_DETAILS
      ) {
        acc[item.value] = [
          ...formData.providerInformation,
          ...formData.locationInformation,
        ].includes(item.value);
      }
      return acc;
    }, {});

    const payload = {
      providerData,
      edits: {
        ...editsCheckboxValues,
        ...(formData?.providerInformation?.includes(OTHER_PROVIDER_DETAILS) && {
          otherProviderDetails: formData?.otherProviderDetails,
        }),
        ...(formData?.locationInformation?.includes(OTHER_LOCATION_DETAILS) && {
          otherLocationDetails: formData?.otherLocationDetails,
        }),
        ...(formData?.freeForm && { freeForm: formData.freeForm }),
      },
      submitter: {
        ...(formData?.notifyMe && { submitterEmail: formData?.submitterEmail }),
        submitterRole: 'member',
      },
      lob: currentMember?.lineOfBusiness,
      coverageType,
    };

    if (enableNewFeedbackFormDesign) {
      const { line, city, state, postalCode } =
        providerLocations[location]?.address;
      payload.providerData.location = `${line}, ${city}, ${state} ${postalCode}`;
    }
    reportInaccurateProvider({ variables: payload });
  };

  const customRenderer = React.useCallback(
    (value) => {
      const {
        address: { line, city, state, postalCode },
        distance,
      } = providerLocations[value];
      return (
        <Layout.Group
          css={{
            display: 'block',
            fontWeight: 400,
            color: '$gray7',
          }}
        >
          <span>{line}</span>
          <span>{`${city}, ${state} ${shortenZipCode(postalCode)} (${Number(
            distance
          ).toFixed(1)} ${t('PROVIDER_DETAILS.MILES')})`}</span>
        </Layout.Group>
      );
    },
    [providerLocations]
  );

  return (
    <React.Fragment>
      <StyledDrawer
        css={{
          '& .abyss-modal-content-container': {
            '& ul.abyss-select-input-option-list': { maxHeight: '288px' },
            '& li.abyss-select-input-option': {
              padding: '12px',
              fontSize: '16px',
              fontWeight: 400,
            },
            '& .abyss-select-input-left-element-wrapper': { zIndex: -1 },
          },
        }}
        data-auto-testid="send-feedback-drawer"
        data-testid="send-feedback-drawer"
        isOpen={isOpen}
        onClose={handleCloseDrawer}
        position={properties.position}
        size={properties.drawerSize}
        title={
          <Heading
            color="$primary"
            data-testid="send-feedback-heading"
            offset={5}
          >
            {t('FEEDBACK_FORM.SEND_FEEDBACK')}
          </Heading>
        }
      >
        <ContentWrapper style={{ paddingTop: '12px' }}>
          <FeedbackFormContainer
            css={
              enableNewFeedbackFormDesign
                ? { padding: mobileScreen ? 0 : '0 16px' }
                : {}
            }
          >
            {enableNewFeedbackFormDesign && (
              <FeedbackFormBanner
                data-testid="feedback-form-banner"
                title={feedbackFormBanner?.title}
                variant="info"
              >
                {feedbackFormBanner?.message}
              </FeedbackFormBanner>
            )}
            <DrawerSubtitle data-testid="send-feedback-sub-heading">
              {t('FEEDBACK_FORM.SUBTITLE')}
            </DrawerSubtitle>
            <FormProvider
              data-auto-testid="feedback-form"
              data-testid="feedback-form"
              onSubmit={onSubmit}
              state={form}
            >
              {enableNewFeedbackFormDesign && (
                <Flex
                  css={{
                    marginTop: '24px',
                    '& button.abyss-select-input-input': {
                      height: '86px !important',
                      fontSize: '0px !important',
                      background: 'transparent',
                    },
                    '& .abyss-select-input-left-element': { padding: '12px' },
                  }}
                >
                  <SelectInput
                    customRender={(option) => customRenderer(option?.value)}
                    data-testid="provider-selected-location"
                    inputLeftElement={customRenderer(location)}
                    label={t('FEEDBACK_FORM.SELECTED_LOCATION')}
                    onChange={setLocation}
                    options={allLocations}
                    value={location}
                  />
                </Flex>
              )}
              <FeedbackCheckboxGroup
                dataTestId="provider-information"
                model="providerInformation"
                options={infoOptions[type]?.options}
                selectedValues={form.getValues('providerInformation')}
                textAreaProperties={{
                  name: OTHER_PROVIDER_DETAILS,
                  label: t('FEEDBACK_FORM.TELL_US_MORE'),
                  placeholder: t('FEEDBACK_FORM.OTHER_INFORMATION_PLACEHOLDER'),
                  maxLength: TELL_US_MORE_MAX_LENGTH,
                }}
                title={infoOptions[type]?.title}
              />
              <FeedbackCheckboxGroup
                dataTestId="location-information"
                model="locationInformation"
                options={locationInformationOptions?.options}
                selectedValues={form.getValues('locationInformation')}
                textAreaProperties={{
                  name: OTHER_LOCATION_DETAILS,
                  label: t('FEEDBACK_FORM.TELL_US_MORE'),
                  placeholder: t('FEEDBACK_FORM.OTHER_INFORMATION_PLACEHOLDER'),
                  maxLength: TELL_US_MORE_MAX_LENGTH,
                }}
                title={locationInformationOptions?.title}
              />

              <Flex css={{ marginTop: '18px' }}>
                <StyledTextInputArea
                  data-testid="tell-us-more-feedback-form"
                  displayMaxLength
                  label={t('FEEDBACK_FORM.TELL_US_MORE')}
                  maxLength={TELL_US_MORE_MAX_LENGTH}
                  model="freeForm"
                  placeholder={
                    t('FEEDBACK_FORM.TELL_US_MORE_PLACEHOLDER') || ''
                  }
                  rows={3}
                  validators={{
                    maxLength: {
                      value: TELL_US_MORE_MAX_LENGTH,
                      message: t(
                        'FEEDBACK_FORM.TELL_US_MORE_VALIDATION_MESSAGE'
                      ),
                    },
                  }}
                />
              </Flex>

              <Flex css={{ margin: '20px 0' }}>
                <Checkbox
                  css={{ 'abyss-checkbox-label': { color: '$gray6' } }}
                  data-testid="feedback-form-notify-me-checkbox"
                  label={t(`FEEDBACK_FORM.${type}_NOTIFY_ME_TEXT`) || ''}
                  model="notifyMe"
                />
              </Flex>

              {form.getValues('notifyMe') && (
                <TextInput
                  css={EmailInputStyle}
                  data-auto-testid="email-address-for-feedback-input"
                  data-testid="email-address-for-feedback-input"
                  label={t('FEEDBACK_FORM.EMAIL_ADDRESS')}
                  leftAddOn={
                    <IconMaterial
                      color={'$gray6'}
                      icon="email"
                      size={24}
                      variant="outlined"
                    />
                  }
                  model="submitterEmail"
                  placeholder={t('FEEDBACK_FORM.EMAIL_ADDRESS') || ''}
                  validators={{
                    required: true,
                    pattern: {
                      value: emailRegex,
                      message: t('FEEDBACK_FORM.ENTER_EMAIL'),
                    },
                  }}
                />
              )}

              <Flex css={{ margin: '24px 0 80px 0', gap: '10px' }}>
                <Button
                  data-testid="send-feedback-form-submit-button"
                  disabled={isSubmitButtonDisabled()}
                  type="submit"
                >
                  {t('FEEDBACK_FORM.SEND_FEEDBACK')}
                </Button>
                <Button
                  data-testid="feedback-form-drawer-cancel-button"
                  onClick={handleCloseDrawer}
                  variant="outline"
                >
                  {t('FEEDBACK_FORM.CANCEL')}
                </Button>
              </Flex>
            </FormProvider>
          </FeedbackFormContainer>
        </ContentWrapper>
      </StyledDrawer>
    </React.Fragment>
  );
};
