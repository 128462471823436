import { createTheme } from '@abyss/web/tools/theme';
import { OverlayProvider } from '@abyss/web/ui/OverlayProvider';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import React, { useContext } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { ReverseCoverageTypesCodes } from '../../common/Constants';
import { PortalName } from '../../common/Portal';
import { DetailsWidgetView } from '../../common/Widget/ProviderDetails';
import {
  PortalContext,
  PortalContextProvider,
} from '../../context/PortalContext';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { Configuration } from '../ProviderSearch/context/Configuration';
import { DateTimeConfig } from '../ProviderSearch/context/DateTimeConfig';
import { Internationalization } from '../ProviderSearch/context/Internationalization';
import { Obapi } from '../ProviderSearch/context/Obapi';
import { PlanSelector } from '../ProviderSearch/context/PlanSelector';
import { getTheme } from '../themeOverrides/theme.utils';

export const ProviderDetailsWidget = (args) => {
  const {
    'claim-system-type-code': claimSystemTypeCode,
    'coverage-type': coverageType,
    'ddp-code': ddpCode,
    'eligibility-system-type-code': eligibilitySystemTypeCode,
    latitude,
    lob,
    'location-id': locationId,
    longitude,
    'out-of-pocket-cost': outOfPocketCost,
    'plan-variation-code': planVariationCode,
    'plan-year': planYear,
    'policy-id': policyId,
    'provider-id': providerId,
    'provider-type': providerType,
    'reciprocity-id': reciprocityId,
    'total-cost': totalCost,
    'membership-category': membershipCategory,
    population,
    'portal-data': portalData,
  } = args || {};

  const parsedPortalData = JSON.parse(portalData || '{}');
  const { setPortalData } = useContext(PortalContext);
  const isPortalWidget = parsedPortalData?.portalName;

  // Will remove this console log after testing
  console.log('Testing PortalName', isPortalWidget);
  if (isPortalWidget === PortalName.UHC) {
    document.cookie = `bgParcelsCookie=bgParcelsCookie; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }

  const themeOverride = getTheme(
    parsedPortalData?.portalConfig?.theme,
    parsedPortalData?.portalIdentifier,
    parsedPortalData?.portalConfig?.themeOverride
  );

  const theme = createTheme(
    parsedPortalData?.portalConfig?.theme || 'uhc',
    themeOverride
  );

  const setIsWidget = useStore(StoreKeys.SET_IS_WIDGET);
  const setUserDdpCode = useStore(StoreKeys.SET_USER_DDP_CODE);
  const setMemberState = useStore(StoreKeys.SET_OBAPI_MEMBERS);

  const { setStoreCoverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      setStoreCoverageType: state.setCoverageType,
    }))
  );

  setIsWidget(true);
  setPortalData(parsedPortalData);
  setStoreCoverageType(coverageType);
  setUserDdpCode(ddpCode);
  if (isPortalWidget) {
    const portalMemberInfo = parsedPortalData?.portalMemberInfo?.memberInfo;
    if (portalMemberInfo?.length) {
      setMemberState(portalMemberInfo);
    } else {
      const widgetMemberInfo = [
        {
          eligibility: [
            {
              memberHealthCoverage: {
                coverageType: [{}],
              },
            },
          ],
          lineOfBusiness: lob,
          policyNumber: policyId,
          population,
        },
      ];
      setMemberState(widgetMemberInfo);
    }
  }

  const adjustedReciprocityId =
    coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
    reciprocityId === '0'
      ? 'Employer%20Guest'
      : reciprocityId;

  const ProviderDetailsDrawerWidget = () => (
    <Internationalization>
      <OverlayProvider>
        <ThemeProvider theme={theme}>
          {providerId ? (
            <DetailsWidgetView
              claimSystemTypeCode={claimSystemTypeCode}
              coverageType={coverageType}
              ddpUserCode={ddpCode}
              eligibilitySystemTypeCode={eligibilitySystemTypeCode}
              latitude={latitude}
              lob={lob}
              locationId={locationId}
              longitude={longitude}
              membershipCategory={membershipCategory}
              outOfPocketCost={outOfPocketCost}
              planVariationCode={planVariationCode}
              planYear={planYear}
              policyId={policyId}
              population={population}
              providerId={providerId}
              providerType={providerType || 'Practitioner'}
              reciprocityId={adjustedReciprocityId}
              totalCost={totalCost}
            />
          ) : (
            <div>No Provider ID</div>
          )}
        </ThemeProvider>
      </OverlayProvider>
    </Internationalization>
  );

  if (isPortalWidget) {
    return (
      <PortalContextProvider portalDataArgs={parsedPortalData}>
        <Configuration>
          <DateTimeConfig>
            <ProviderDetailsDrawerWidget />
          </DateTimeConfig>
        </Configuration>
      </PortalContextProvider>
    );
  }
  return (
    <Configuration>
      <PlanSelector>
        <DateTimeConfig>
          <Obapi>
            <ProviderDetailsDrawerWidget />
          </Obapi>
        </DateTimeConfig>
      </PlanSelector>
    </Configuration>
  );
};
