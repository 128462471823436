import { useCallback, useEffect, useRef, useState } from 'react';

import { Constants } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { useCategoriesCount } from '../../../../hooks/useCategoriesCount';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { ChipCategory, GetCategoryCountProps } from './categoryCountTypes';
import {
  getPayloadWithRecriprocityId,
  groupSpecialtyRollupCodes,
} from './searchUtils';
import {
  compareAndReturn,
  getModifiedPayloadForCategoriesCount,
} from './utils';

export type UseParallelSearchResults = {
  isNameResultsLoaded: boolean;
  isSpecialityResultsLoaded: boolean;
  nameResults: ChipCategory[];
  specialityResults: ChipCategory[];
  corelationIds: string;
};

export const useParallelSearchResultsCategoriesCount: (
  customParams: any,
  onCategoryCountLoaded?: (data: any) => void
) => [
  results: UseParallelSearchResults,
  getCategoryCountResults: (params: GetCategoryCountProps) => any
] = (customParams: any, onCategoryCountLoaded?: (data: any) => void) => {
  const isNameLoaded = useRef(false);
  const isSpecialityLoaded = useRef(false);
  const shouldLoadName = useRef(false);
  const shouldLoadSpeciality = useRef(false);

  const nameResultsRef = useRef([]);
  const specialtyRef = useRef([]);

  const [enableSearchResultsV2] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SEARCH_RESULTS_V2,
  ]);

  const [enableAoeMedical] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_AOE_MEDICAL,
  ]);

  const [nameCategoryCount, getNameResults] = useCategoriesCount({
    onCompleted: (result: any) => {
      const nameResults = shouldLoadName.current
        ? result?.data?.getChipCategoriesCount || []
        : [];
      nameResultsRef.current = nameResults;
      isNameLoaded.current = true;
      if (isSpecialityLoaded.current) {
        const specialityResults = specialtyRef.current;
        onCategoryCountLoaded?.({ nameResults, specialityResults });
      }
    },
  });
  const [specialityCategoryCount, getSpecialityCategoryCount] =
    useCategoriesCount({
      onCompleted: (result: any) => {
        const specialityResults = shouldLoadSpeciality.current
          ? result?.data?.getChipCategoriesCount || []
          : [];
        specialtyRef.current = specialityResults;

        isSpecialityLoaded.current = true;

        if (isNameLoaded.current) {
          const nameResults = nameResultsRef.current;
          onCategoryCountLoaded?.({ nameResults, specialityResults });
        }
      },
    });

  const [results, setResults] = useState({
    isNameResultsLoaded: false,
    isSpecialityResultsLoaded: false,
    nameResults: [],
    specialityResults: [],
    corelationIds: '',
  });

  const {
    data: nameChipCategoryData,
    corelationId: nameCategoryCountCoRelationId,
  } = nameCategoryCount;

  const {
    data: specialityChipCategoryData,
    corelationId: specialityCategoryCountCoRelationId,
  } = specialityCategoryCount;

  const getCategoryCountResults = useCallback(
    (passedParams: GetCategoryCountProps) => {
      const {
        currentMember,
        onlineRetailers,
        featureFlags,
        pseudoVerticalsDetails,
        pseudoVerticals,
        includeSpecialityRollupCodes,
        searchType,
        uesTranslation,
        coverageTypes,
        keyword,
        search,
        searchTerm,
        isMixedSuggestions,
        isUserTextSearch,
        behavioralFacilityProgram,
        pseudoRollupCode,
        preferredFacilities,
        ...restParams
      } = passedParams;
      isNameLoaded.current = false;
      isSpecialityLoaded.current = false;
      const payloadWithRecriprocityId = getPayloadWithRecriprocityId({
        currentMember,
        onlineRetailers,
        featureFlags,
        pseudoVerticalsDetails,
        pseudoVerticals,
      });

      const getNameSearchResults = () => {
        const namesPayload = getModifiedPayloadForCategoriesCount(
          payloadWithRecriprocityId,
          undefined,
          uesTranslation,
          search,
          keyword,
          pseudoVerticalsDetails?.isPseudoRollupSearch,
          isUserTextSearch,
          coverageTypes,
          false,
          [],
          false,
          '',
          enableAoeMedical
        );

        getNameResults({
          ...customParams,
          payloadwithName: namesPayload,
          searchTerm: compareAndReturn(search, searchTerm),
          ...restParams,
        });
      };

      const getSpecialityCategoryCountResults = () => {
        const codeByGroup = groupSpecialtyRollupCodes(
          includeSpecialityRollupCodes,
          searchType,
          true
        );

        const {
          isConditionSearch,
          typeaheadAoeCodes = [],
          enableBHAoeTypeahead,
        } = enableSearchResultsV2 ? passedParams : customParams || {};

        const specialityPayload = getModifiedPayloadForCategoriesCount(
          payloadWithRecriprocityId,
          codeByGroup,
          uesTranslation,
          !isUserTextSearch ? search : '',
          keyword,
          pseudoVerticalsDetails?.isPseudoRollupSearch,
          isUserTextSearch,
          coverageTypes,
          isConditionSearch,
          typeaheadAoeCodes,
          enableBHAoeTypeahead,
          behavioralFacilityProgram,
          enableAoeMedical
        );

        getSpecialityCategoryCount({
          ...customParams,
          payloadwithName: specialityPayload,
          searchTerm: compareAndReturn(search, searchTerm),
          isUserTextSearch,
          behavioralFacilityProgram,
          ...(pseudoRollupCode ? { pseudoRollupCode } : {}),
          ...(preferredFacilities ? { preferredFacilities } : {}),
          ...restParams,
          ...(enableSearchResultsV2
            ? { search, includeSpecialityRollupCodes, searchType, keyword }
            : {}),
        });
      };

      const makeSpecialitySearch =
        searchType === Constants.SEARCH_TYPES.SPECIALTY ||
        pseudoVerticalsDetails?.isPseudoRollupSearch ||
        includeSpecialityRollupCodes?.length > 0 ||
        customParams.isConditionSearch ||
        customParams.typeaheadAoeCodes?.length > 0 ||
        customParams.gender?.length > 0 ||
        customParams.virtualCare ||
        customParams.acceptsEap;
      const makeNameSearch =
        (isMixedSuggestions || searchType === Constants.SEARCH_TYPES.NAME) &&
        !!search;

      isNameLoaded.current = !makeNameSearch;
      shouldLoadName.current = makeNameSearch;
      if (makeNameSearch) {
        getNameSearchResults();
      }

      isSpecialityLoaded.current = !makeSpecialitySearch;
      shouldLoadSpeciality.current = makeSpecialitySearch;
      if (makeSpecialitySearch) {
        getSpecialityCategoryCountResults();
      }
    },
    [getNameResults, getSpecialityCategoryCount]
  );

  useEffect(() => {
    setResults({
      isNameResultsLoaded: isNameLoaded.current,
      isSpecialityResultsLoaded: isSpecialityLoaded.current,
      nameResults: shouldLoadName.current
        ? nameChipCategoryData?.getChipCategoriesCount || []
        : [],
      specialityResults: shouldLoadSpeciality.current
        ? specialityChipCategoryData?.getChipCategoriesCount || []
        : [],
      corelationIds: `${
        shouldLoadName.current ? nameCategoryCountCoRelationId : ''
      }-${
        shouldLoadSpeciality.current ? specialityCategoryCountCoRelationId : ''
      }`,
    });
  }, [
    JSON.stringify(nameChipCategoryData?.getChipCategoriesCount),
    JSON.stringify(specialityChipCategoryData?.getChipCategoriesCount),
    specialityCategoryCountCoRelationId,
    nameCategoryCountCoRelationId,
    shouldLoadName.current,
    shouldLoadSpeciality.current,
    isNameLoaded.current,
    isSpecialityLoaded.current,
  ]);

  return [results, getCategoryCountResults];
};
