/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { DataCardContainer } from '../../../../common/DataCard/DataCardContainer';
import { OutsideCountyAlert } from '../../../../common/DataCard/OutsideCountyAlert';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { BottomDataCardStyle } from '../../../../common/ResultSection/ResultSection.style';
import { isOutOfCounty } from '../../../../common/SnackCardContainer/utils';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { Provider } from '../../../../models/Provider';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { PopupStyle } from './MapView.styled';

type Props = {
  headers: ResponseHeaders;
  practitioner?: Provider;
  locationForAnalytics?: string;
  sectionType?: string;
  pageNumber?: number;
  selectedFilters?: string;
  selectLocation?: (locationId, locationLng, locationLat) => void;
  setPopupContent: (content: any) => void;
  isMNR?: boolean;
};
export const BottomDataCard = ({
  headers,
  practitioner,
  locationForAnalytics,
  pageNumber,
  sectionType,
  selectedFilters,
  setPopupContent,
  selectLocation,
  isMNR,
}: Props) => {
  const [initialY, setInitialY] = React.useState(null);
  const [currentY, setCurrentY] = React.useState(null);
  const [offset, setOffset] = React.useState(0);
  const [countySearchAlertFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_COUNTY_SEARCH_ALERT,
  ]);
  const { isCountySearch } = useContext(CountySearchContext);
  const { name, stateCode } = getGeoLocationFromStorage();

  const handleDirectionClick = (provider) => {
    if (selectLocation)
      selectLocation(
        provider.locationId,
        provider.longitude,
        provider.latitude
      );
  };
  const handleTouchStart = (e) => {
    setInitialY(e.touches[0].clientY);
    setCurrentY(e.touches[0].clientY);
  };
  const handleTouchMove = (e) => {
    if (initialY === null) {
      return;
    }
    setCurrentY(e.touches[0].clientY);
    setOffset(e.touches[0].clientY - initialY);
  };
  const handleTouchEnd = () => {
    if (currentY - initialY > 20) {
      setInitialY(null);
      setCurrentY(null);
      setOffset(0);
      setPopupContent(null);
    }
  };

  const displayOutOfCntyIndicator = (provider) => {
    const isOutOfCnty = isOutOfCounty(
      isCountySearch,
      name,
      stateCode,
      provider
    );
    return (
      countySearchAlertFlag &&
      isOutOfCnty && (
        <OutsideCountyAlert
          county={name?.split(',')[0]}
          isOutOfCounty={isOutOfCnty}
        />
      )
    );
  };

  const showPopup = () =>
    practitioner?.address?.line?.[0] ? (
      <React.Fragment>
        <DataCardContainer
          css={BottomDataCardStyle}
          handleDirectionClick={() => handleDirectionClick(practitioner)}
          headers={headers}
          isMNR={isMNR}
          locationForAnalytics={locationForAnalytics}
          pageNumber={pageNumber}
          practitioner={practitioner || {}}
          sectionType={sectionType}
          selectedFilters={selectedFilters}
        />
        {displayOutOfCntyIndicator(practitioner)}
      </React.Fragment>
    ) : null;
  return (
    <div
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
      style={{ transform: `translateY(${offset}px)` }}
    >
      <PopupStyle>{showPopup()}</PopupStyle>
    </div>
  );
};
