import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Text } from '@abyss/web/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LocationLabel } from '../../../common/PSXHeader/LocationInput/LocationInput.styled';
import { DateTime } from '../../../utils/DateTime';
import { GuestLocationInput } from './GuestLocationInput';

export interface GuestLocation {
  label: string;
  latitude: string;
  longitude: string;
  zipcode: string;
}

export const GuestLocationPicker = () => {
  const [location, setLocation] = useState<GuestLocation>();

  return (
    <PageBody>
      <Heading textAlign="center">Where are you looking for care?</Heading>

      <Flex style={{ marginTop: '21px', justifyContent: 'center' }}>
        <GuestLocationSearch location={location} setLocation={setLocation} />
      </Flex>
    </PageBody>
  );
};

type GuestLocationInputProps = {
  location?: GuestLocation;
  setLocation: (a: GuestLocation) => void;
};

export const LocationInputContainer = styled(Flex, {
  marginRight: '24px',
  flexWrap: 'nowrap',
  height: '71px',
  backgroundColor: '#FFFFFF',
  borderRight: 'solid',
  borderLeft: 'solid',
  borderTop: 'solid',
  borderBottom: 'solid',
  borderColor: '#CBCCCD',
  borderRadius: '50px',
  paddingRight: '20px',
  paddingLeft: '20px',

  justifyContent: 'space-between',
  minWidth: '320px',
  width: '66%',
  '@screen < $md': {
    marginRight: '16px',
  },
  '.abyss-search-input-root': {
    height: '38px',
  },
  '.abyss-search-input-input': {
    height: '38px',
  },
});

const buildPortalData = (lineOfBusiness: string): any => {
  const currentYear =
    DateTime.getCurrentDateTimeWithCentralOffset().getFullYear();
  const coverageEffStartDate = `${currentYear}-01-01`;
  const coverageEffEndDate = `${currentYear}-12-31`;
  return {
    lineOfBusiness,
    policyNumber: '0001',
    reciprocityId: '52',
    planVariationCode: '8888',
    coverageTypeCode: 'M',
    coverageEffStartDate,
    coverageEffEndDate,
  };
};

const GuestLocationSearch = ({
  location,
  setLocation,
}: GuestLocationInputProps) => {
  const { t } = useTranslation();
  const { navigate, getSearchParams } = useRouter();
  const [searchParams] = getSearchParams();
  const isPlanSelectorFlow = searchParams?.get('planSelector') === 'true';
  const lob = searchParams?.get('lob');

  return (
    <LocationInputContainer>
      <div>
        <Text css={LocationLabel}>{'Location'}</Text>
        <GuestLocationInput location={location} setLocation={setLocation} />
      </div>
      <div style={{ alignSelf: 'center' }}>
        <Button
          data-auto-testid="submit-button"
          data-testid="submit-button"
          disabled={location?.label?.length === 0}
          size="$md"
          type="submit"
          onClick={() => {
            isPlanSelectorFlow
              ? navigate(`/select-plan?lob=${encodeURIComponent(lob)}`)
              : navigate(`/browse`, {
                  state: buildPortalData(searchParams.get('lob')),
                });
          }}
        >
          {t('Search')}
        </Button>
      </div>
    </LocationInputContainer>
  );
};
