/* eslint-disable no-nested-ternary */
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { Badge } from '@abyss/web/ui/Badge';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Pagination } from '@abyss/web/ui/Pagination';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { getLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCoverageType } from '../../../hooks/useCoverageType';
import { useCurrentMemberIsPreEffective } from '../../../hooks/useCurrentMemberIsPreEffective';
import { useLagoon } from '../../../hooks/useLagoon';
import { usePhysicianDirectory } from '../../../hooks/usePhysicianDirectory';
import { useSpecialtiesResult } from '../../../hooks/useSpecialties';
import { FacilityLocationsResponse } from '../../../models/FacilityDetails';
import {
  ProviderLocation,
  ProviderType,
} from '../../../models/ProviderDetails';
import { Specialty } from '../../../models/TranslationContent';
import { useChipStore } from '../../../store/useChipStore';
import { ChipState } from '../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../store/useDetailsStore';
import { DetailsStore } from '../../../store/useDetailsStore/detailsStore';
import { getSelectedLocationIndex } from '../../../utils/providerDetails.utils';
import { getMemberHealthCoverageType } from '../../../utils/providerSearch.utils';
import { removeAccents } from '../../../utils/string.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageEffDateRange,
  getCoverageTypes,
  getCurrentMember,
  getMemberPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
  getRulesPackageKey,
} from '../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  CoverageTypesCodes,
  PRACTITIONER_NAME_FOR_ANALYTICS,
  PhysicianDirectorySortOrder,
  ReverseCoverageTypesCodes,
} from '../../Constants';
import { FeatureFlags } from '../../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { ConstantsRoutes } from '../../ConstantsRoutes';
import { phoneOnly, tabletOnly } from '../../ConstantsStyles';
import { getGeoLocationFromStorage } from '../../PSXHeader/SearchBar/utils';
import { convertObjectToUrlParams, interleave } from '../../Utils';
import { convertProviderTypeToAdobeType } from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { getFeatureFlag } from '../../Utils/getFeatureFlag';
import { DirectoryLink } from './DirectoryLink/DirectoryLink';
import { DirectoryListMobile } from './DirectoryListMobile/DirectoryListMobile';
import { DirectorySearchInput } from './DirectorySearchInput';
import { DirectorySearchTypeInput } from './DirectorySearchTypeInput';
import {
  PhysicianDirectoryInterface,
  getCopyContent,
  getCopyContentForNullSearch,
  renderDesktopItems,
  renderTabletItems,
} from './helper';
import NameSearchText from './NameSearchText/NameSearchText';
import {
  ContentTextContainer,
  DirectoryTextContainer,
  SearchContainer,
  SectionContainer,
  StyledDivider,
  layoutGroupStyles,
  letterBadgeStyles,
} from './PhysicianDirectory.styled';
import SpecialitySearch from './SpecialitySearch/SpecialitySearch';

interface FirstLetterToPhysicianMapping {
  [letter: string]: PhysicianDirectoryInterface[];
}

const pageSize = 50;

type Props = {
  providerId: string;
  providerType: string;
  facilityLocations?: FacilityLocationsResponse[];
  providerGroupLocations?: ProviderLocation[];
};
interface FirstLetterToSpecialtyMapping {
  [letter: string]: Specialty[];
}

export const matchSearchTextWithPhysician = (
  searchText,
  physicianList,
  navigate,
  isMobile,
  tablet,
  currentPage,
  setDetailsStore,
  chipStore
) => {
  const onClick = (physician) => {
    const detailsStoreValues = {
      providerId: physician.providerId,
    };
    setDetailsStore(detailsStoreValues);
    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      physician.providerId
    );
    adobeLinkTrackEvent({
      name: `${PRACTITIONER_NAME_FOR_ANALYTICS}`,
      location: `body:${Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY}:page ${currentPage}`,
      type: 'internal',
      destinationUrl: '',
    });

    const urlParams = convertObjectToUrlParams(chipStore, detailsStoreValues);
    navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${urlParams}`);
  };

  const search = searchText.trim();
  const columnsCount = isMobile ? 1 : tablet ? 2 : 3;
  const rows = Math.ceil(physicianList.length / columnsCount);

  const physicanColumnsList = tablet
    ? renderTabletItems(physicianList, rows)
    : !isMobile
    ? renderDesktopItems(physicianList, rows)
    : [];

  switch (true) {
    case isMobile:
      return physicianList?.map((physician) => (
        <DirectoryListMobile
          isTablet={false}
          linkOnClick={onClick}
          physician={physician}
          search={search}
        />
      ));
    case tablet:
      return (
        <Grid
          css={{ 'abyss-grid': { margin: 0, gap: '$lg' } }}
          style={{ display: 'flex', flexFlow: 'row' }}
        >
          {physicanColumnsList.map((physicians, index) => (
            <Grid
              css={{
                'abyss-grid': {
                  margin: 0,
                  flexDirection: 'column',
                  width: '50%',
                },
              }}
              key={`physicians-columns-${index}`}
            >
              <React.Fragment>
                {physicians?.map((physician) => (
                  <DirectoryListMobile
                    isTablet
                    linkOnClick={onClick}
                    physician={physician}
                    search={search}
                  />
                ))}
              </React.Fragment>
            </Grid>
          ))}
        </Grid>
      );
    default:
      return physicanColumnsList.map((physicians) => (
        <Grid
          css={{ 'abyss-grid': { margin: 0 } }}
          data-auto-testid="desktop-grid"
          data-testid="desktop-grid"
          key={`desktop-grid-${physicians[0]?.providerId}`}
        >
          {physicians?.map((physician) => (
            <Grid.Col
              css={{
                'abyss-grid-col': {
                  padding: '0',
                },
              }}
              span={{ xs: 12, md: 6, lg: 4 }}
            >
              <DirectoryLink
                linkOnClick={onClick}
                physician={physician}
                search={search}
              />
            </Grid.Col>
          ))}
        </Grid>
      ));
  }
};

export const getStartingLetterSections = (
  physicianDirectoryMapping,
  providerNameToSearch,
  navigate,
  mobileScreen,
  tablet,
  currentPage,
  setDetailsStore,
  chipStore
) =>
  interleave(
    Object.keys(physicianDirectoryMapping)
      .filter((letter) => physicianDirectoryMapping[letter].length > 0)
      .map((letter) => (
        <Layout.Group
          alignItems="left"
          css={layoutGroupStyles}
          key={letter}
          style={{ width: '100%' }}
        >
          <div style={{ width: '100%' }}>
            <Badge
              css={letterBadgeStyles}
              data-testid="physician-directory-badge"
              rounded
              variant="neutral"
            >
              {letter}
            </Badge>
            {matchSearchTextWithPhysician(
              providerNameToSearch,
              physicianDirectoryMapping[letter],
              navigate,
              mobileScreen,
              tablet,
              currentPage,
              setDetailsStore,
              chipStore
            )}
          </div>
        </Layout.Group>
      )),
    <StyledDivider color="$gray3" />
  );

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PhysicianDirectory = ({
  providerId,
  providerType,
  facilityLocations,
  providerGroupLocations,
}: Props) => {
  const coverageTypeData = useCoverageType();
  const { t } = useTranslation();
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(providerType),
      indicator: '',
    },
  });
  const { navigate } = useRouter();

  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { coverageType } = chipStore;
  const [searchType, setSearchType] = useState(t('Name') || 'Name');
  const { data: specialties = [] } = useSpecialtiesResult({
    coverageType,
  });

  const letterMapping: FirstLetterToSpecialtyMapping = {};

  Constants.BROWSE_AZ.forEach((letter) => {
    letterMapping[letter.label] = [];
  });
  specialties
    .sort((a, b) => a.name.localeCompare(b.name))
    .forEach((speciality) => {
      if (speciality?.name) {
        const startingLetter = removeAccents(speciality?.name.substring(0, 1));
        letterMapping[startingLetter].push(speciality);
      }
    });
  const mobileScreen = useMediaQuery(phoneOnly);
  const tablet = useMediaQuery(tabletOnly);
  const [providerNameToSearch, setProviderNameToSearch] = useState('');
  const [specialityToSearch, setSpecialityToSearch] = useState('');
  const [sortOrder, setSortOrder] = useState<PhysicianDirectorySortOrder>(
    PhysicianDirectorySortOrder.ASCENDING
  );
  const [physicians, getPhysicians] = usePhysicianDirectory({});
  const { longitude, latitude } = getGeoLocationFromStorage();
  const currentMember = getCurrentMember();
  const [cancelPrevCalls, setCancelPrevCalls] = useState(true);
  const physicianDirectoryMapping: FirstLetterToPhysicianMapping = {};

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const showPhysicianSpecialities = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.FACILITIES
    ].PHYSICIAN_DIRECTORY_SPECIALITIES
  );

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );
  const enableTier1FromMemberRPK = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER1_FROM_MEMBER_RPK
  );
  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const uiMessagingLagoonRows = useLagoon('ui-messaging')();
  const tierBenefits = useLagoon('tier-benefit')();

  const directoryContent = find(uiMessagingLagoonRows, {
    key: ConstantsLagoon.CONTENT_FILTERS.PHYSICIAN_DIRECTORY_DIRECTORY_CONTENT,
  });

  const [physicianList, setPhysicianList] = useState(
    physicians?.data?.physicianDirectory?.physicianDirectoryList
  );

  const [totalPhysicanCount, setTotalPhysicanCount] = useState();

  const { state, gotoPage, previousPage, nextPage, ...paginationProps } =
    usePagination({
      pages: Math.ceil((totalPhysicanCount || 0) / pageSize),
    });
  const { currentPage, pageCount } = state;
  const { firstPage } = paginationProps;

  const pdAllLinkTrack = (name: String) => {
    adobeLinkTrackEvent({
      name,
      location: `body:${Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY} pagination control:page ${currentPage}`,
      type: 'internal',
      destinationUrl: '',
    });
  };

  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const numFacilityLocations = facilityLocations?.length;
  const locations = numFacilityLocations
    ? facilityLocations
    : providerGroupLocations;
  const index = getSelectedLocationIndex(locations, selectedId);
  const selectedLocation = locations?.[index].locationId;

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  const nextPageFunc = () => {
    pdAllLinkTrack('next page');
    nextPage();
  };
  const gotoPageFunc = (page) => {
    pdAllLinkTrack(`page ${page + 1}`);
    gotoPage(page);
  };
  const previousPageFunc = () => {
    pdAllLinkTrack('previous page');
    previousPage();
  };

  const isFacility = providerType === ProviderType.ORGANIZATION;
  const memCatergory = currentMember?.membershipCategory || '';
  const membershipCategory = uspToggleFlag ? memCatergory : null;

  const locale = getLanguage().code;
  const isPreEffective = useCurrentMemberIsPreEffective();
  const currentMemberEffectiveDate = getCoverageEffDateRange(
    currentMember,
    coverageType
  ).startDate;

  useEffect(() => {
    let medicalGroupAffiliationProviderId;
    let hospitalaffiliationproviderId;
    if (coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']) {
      if (providerId?.includes('GRP')) {
        medicalGroupAffiliationProviderId = providerId;
      }
      if (providerId?.includes('FAC')) {
        hospitalaffiliationproviderId = providerId;
      }
    } else {
      medicalGroupAffiliationProviderId =
        providerType !== ProviderType.ORGANIZATION ? providerId : null;
      hospitalaffiliationproviderId =
        providerType === ProviderType.ORGANIZATION ? providerId : null;
    }

    const medicalCoverage = getMemberHealthCoverageType(
      currentMember?.eligibility,
      CoverageTypesCodes.MEDICAL
    );

    setCancelPrevCalls(false);
    getPhysicians({
      variables: {
        latitude,
        longitude,
        lob: currentMember?.lineOfBusiness,
        coverages: getCoverageTypes(currentMember),
        planYear: getMemberPlanYear(isPreEffective, currentMemberEffectiveDate),
        memberEffectiveDate: isPreEffective ? currentMemberEffectiveDate : null,
        planVariationCode: getPlanVariationCode(currentMember, coverageType),
        population: currentMember?.population,
        claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.claimSystemTypeCode,
        eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.eligibilitySystemTypeCode,
        policyID: currentMember?.policyNumber,
        providerName: providerNameToSearch,
        providerType,
        reciprocityId: getNetworkIdsForPES(
          currentMember,
          coverageType,
          featureFlags
        ),
        snpType:
          coverageType == ReverseCoverageTypesCodes.MEDICAL
            ? medicalCoverage?.snpType
            : '',
        rulesPackageKey: getRulesPackageKey(
          currentMember,
          tierBenefits,
          providerType,
          uspTierOneFlag,
          enableTier1FromMemberRPK
        ),
        membershipCategory,
        medicalGroupAffiliationProviderId,
        hospitalaffiliationproviderId,
        sortOrder,
        pageNumber: currentPage,
        pageSize,
        coverageType: coverageType || coverageTypeData,
        includeSpecialtyRollUpCodes: '',
        locale,
      },
    });
  }, [
    providerNameToSearch,
    currentPage,
    sortOrder,
    providerType,
    selectedLocation,
  ]);

  useEffect(() => {
    setCancelPrevCalls(false);
  }, [providerNameToSearch, currentPage, sortOrder]);

  useEffect(() => {
    if (!cancelPrevCalls) {
      setPhysicianList(
        physicians?.data?.physicianDirectory?.physicianDirectoryList
      );
      setTotalPhysicanCount(physicians?.data?.physicianDirectory?.totalCount);
    }

    return () => {
      if (
        physicians?.data !== null &&
        physicians.providerNameToSearch === providerNameToSearch
      )
        setCancelPrevCalls(!cancelPrevCalls);
      else setCancelPrevCalls(false);
    };
  }, [physicians]);

  if (sortOrder === PhysicianDirectorySortOrder.ASCENDING) {
    Constants.BROWSE_AZ.forEach((letter) => {
      physicianDirectoryMapping[letter.label] = [];
    });
  } else {
    Constants.BROWSE_AZ.filter((item) => {
      item.id !== 'all' && item.id === '#';
    });
    const sortedAlphabets = orderBy(Constants.BROWSE_AZ, 'label', 'desc');

    uniqBy(sortedAlphabets, 'label').forEach((letter) => {
      physicianDirectoryMapping[letter.label] = [];
    });
  }

  physicianList
    ?.filter((physician) => physician.providerName)
    ?.forEach((physician) => {
      const name = `${physician.providerName}, ${physician.primaryDegrees.join(
        ','
      )}`;
      const startingLetter = name?.substring(0, 1).toUpperCase();
      physicianDirectoryMapping[startingLetter]?.push({
        providerName: name.replace(' ', ', '),
        providerId: physician.providerId,
        specialities: showPhysicianSpecialities
          ? physician.specialities
              ?.map((specality) => specality?.value)
              .join(', ')
              ?.toLowerCase()
          : '',
      });
    });

  if (
    (physicianList?.length === 0 || !physicianList) &&
    providerNameToSearch.length === 0 &&
    physicians.isLoading === false
  )
    return (
      <SectionContainer>
        <Text data-testid="no-directory-available-message">
          {getCopyContentForNullSearch(isFacility, uiMessagingLagoonRows)}
        </Text>
      </SectionContainer>
    );

  return (
    <React.Fragment>
      {showPhysicianSpecialities && (
        <SectionContainer>
          <ContentTextContainer>
            {getCopyContent(isFacility, uiMessagingLagoonRows)}
          </ContentTextContainer>
        </SectionContainer>
      )}
      <SectionContainer>
        <SearchContainer>
          {!showPhysicianSpecialities && (
            <DirectorySearchInput
              gotoPage={gotoPage}
              setProviderNameToSearch={setProviderNameToSearch}
            />
          )}
          {showPhysicianSpecialities && (
            <DirectorySearchTypeInput
              firstPage={firstPage}
              searchType={searchType}
              setProviderNameToSearch={setProviderNameToSearch}
              setSearchType={(type: string) => {
                setSpecialityToSearch('');
                setSearchType(type);
              }}
              setSpecialityToSearch={setSpecialityToSearch}
              specialityToSearch={specialityToSearch}
            />
          )}
        </SearchContainer>
      </SectionContainer>
      {!specialityToSearch ? (
        <SectionContainer>
          {physicians.isLoading ? (
            <LoadingSpinner
              ariaLoadingLabel="loading results"
              data-testid="physician-directory-providers-result-loader"
              isLoading={physicians.isLoading}
              size="$sm"
            />
          ) : (
            <React.Fragment>
              <NameSearchText
                physicianList={physicianList}
                providerNameToSearch={providerNameToSearch}
                setSortOrder={setSortOrder}
                showPhysicianSpecialities={showPhysicianSpecialities}
                sortOrder={sortOrder}
                totalPhysicanCount={totalPhysicanCount}
              />
              {getStartingLetterSections(
                physicianDirectoryMapping,
                providerNameToSearch,
                navigate,
                mobileScreen,
                tablet,
                currentPage,
                setDetailsStore,
                chipStore
              )}
            </React.Fragment>
          )}
          {pageCount > 1 && (
            <Pagination
              {...paginationProps}
              css={{
                'abyss-pagination-root': {
                  marginTop: '$sm',
                  '@screen < $sm': {
                    marginTop: '$xs',
                  },
                },
                'abyss-pagination-page-button': {
                  minWidth: '20px',
                },
                'abyss-pagination-arrow': {
                  paddingLeft: '4px',
                  paddingRight: '4px',
                },
              }}
              customNextLabel={t('PAGINATION.NEXT')}
              customPreviousLabel={t('PAGINATION.PREVIOUS')}
              data-auto-testid="physician-directory-pagination"
              data-testid="physician-directory-pagination"
              gotoPage={gotoPageFunc}
              nextPage={nextPageFunc}
              pageSize={pageSize}
              previousPage={previousPageFunc}
            />
          )}
        </SectionContainer>
      ) : (
        <SectionContainer>
          <div style={{ width: '100%' }}>
            <SpecialitySearch
              allSpecialities={specialties}
              providerId={providerId}
              providerType={providerType}
              resetSpeciality={() => setSpecialityToSearch('')}
              specialityToSearch={specialityToSearch}
            />
          </div>
        </SectionContainer>
      )}
      {showPhysicianSpecialities && (
        <SectionContainer>
          <DirectoryTextContainer>
            {directoryContent.message}
          </DirectoryTextContainer>
        </SectionContainer>
      )}
    </React.Fragment>
  );
};
