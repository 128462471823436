import { Card } from '@abyss/web/ui/Card';
import { Markdown } from '@abyss/web/ui/Markdown';
import React from 'react';

import {
  DisclaimerContainer,
  StyledVisionDisclaimer,
} from '../VisionCare/VisionDisclaimer.styled';

type Props = {
  VirtualVisitDisclaimerMessage: string;
  page?: Boolean;
};
export const VirtualVisitDisclaimer = ({
  VirtualVisitDisclaimerMessage,
  page = true,
}: Props) => (
  <StyledVisionDisclaimer data-testid="vision-disclaimer">
    <DisclaimerContainer page={page}>
      <Card>
        <Card.Section>
          <Markdown>{VirtualVisitDisclaimerMessage ?? ''}</Markdown>
        </Card.Section>
      </Card>
    </DisclaimerContainer>
  </StyledVisionDisclaimer>
);
