export const gehaThemeOverride = {
  theme: {
    colors: {
      memeberInitialsBgColor: '$interactive1',
      memeberInitialsTextColor: 'white',
      bgHeaderColor: '#FBFCFE',
      searchIconColor: '#323333',
      cardBorder: '#E5E5E5',
      searchBorderColor: '#E5E5E5',
    },
    fontSizes: {
      extraSmallText: '12.64px',
      smallText: '14.24px',
      mediumText: '20.25px',
      largeText: '25.63px',
      dateInfoText: '12.64px',
      providerDetailsDrawerBHFooterTimeStampText: '$md',
      providerDetailsDrawerBHFooterDateText: '$md',
    },
    fontWeights: {
      memeberInitialsFW: '500',
      providerDetailsDrawerHeaderInNetworkFW: '$medium',
      providerDetailsDrawerBHFooterDateFW: '$medium',
    },
    lineHeights: {
      lg: '24px',
      xl: '32px',
      md: '20px',
      sm: '16px',
    },
    space: {
      wrapperPadding: '24px 0',
      headerWrapperPadding: '24px 0',
      tabButtonsPadding: '5px 0 5px 0',
      recentSearchBottomGap: '8px',
      recentActivityTopPadding: '24px',
      popoverContainerTop: '3px',
    },
    borderWidths: {
      recentActivityBorder: '1px',
    },
    sizes: {
      iconBrandSize: '72px',
      dataCardForMapIconSize: '49px',
    },
  },
};
