import { config } from '@abyss/web/tools/config';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import React, { useContext } from 'react';

import { PortalContext } from '../context/PortalContext';
import { ProviderTypes } from './Constants';

export type Props = {
  imgRef: React.RefObject<HTMLImageElement>;
  providerType?: string;
  imgSrc?: string;
  bhIcon?: string;
  width?: string;
  height?: string;
};

export const ImageComponent = ({
  imgRef,
  providerType,
  imgSrc,
  bhIcon,
  width = '84px',
  height = '84px',
}: Props) => {
  let dataTestId: string;
  const { portalData } = useContext(PortalContext);
  const portalConfigCheck = portalData?.portalConfig?.theme === 'optum';
  const networkIcon = portalConfigCheck ? 'network_circle' : 'network';

  if (imgSrc && imgSrc.includes('default')) {
    dataTestId = 'default-image';
    if (bhIcon) {
      if (
        providerType?.toLowerCase() === ProviderTypes.ORGANIZATION.toLowerCase()
      ) {
        return <IconBrand css={{ width, height }} icon="clinic" />;
      } else if (
        providerType?.toLowerCase() ===
          ProviderTypes.PROVIDER_GROUPS.toLowerCase() ||
        providerType?.toLowerCase() ===
          ProviderTypes.MEDICALGROUP.toLowerCase() ||
        providerType?.toLowerCase() === ProviderTypes.GROUP.toLowerCase() ||
        providerType?.toLowerCase() ===
          ProviderTypes.MEDICAL_GROUP.toLowerCase()
      ) {
        return <IconBrand css={{ width, height }} icon={networkIcon} />;
      } else {
        return <IconBrand css={{ width, height }} icon={bhIcon} />;
      }
    }
  } else {
    dataTestId = 'provider-image';
  }
  const handleImageError = () => {
    if (imgRef.current) {
      // eslint-disable-next-line no-param-reassign
      imgRef.current.src = `${config(
        'CDN_BASE_URL'
      )}/cdn/assets/images/default_${providerType}.jpg`;
    }
  };
  return (
    <img
      alt=""
      data-testid={dataTestId}
      onError={handleImageError}
      ref={imgRef}
      src={imgSrc}
    />
  );
};
