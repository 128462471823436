import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Card } from '@abyss/web/ui/Card';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { h2StylingForResponsive } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { CrisisSupport } from '../CrisisSupport/CrisisSupport';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { PSXIcon } from '../PSXIcon';
import { getFeatureFlag } from '../Utils';

const CardContainer = styled(Card, {
  border: 'none',
  boxShadow: 'none',
  marginTop: '$sm',
  '@screen < $md': {
    marginTop: '$xs',
  },
  '.box-container': {
    padding: '0px',
  },
  'abyss-card-section': { padding: '0px' },
  paddingBottom: '0',
  borderRadius: '0px',
});
const ContactLink = styled('div', {
  alignItems: 'center',
  backgroundColor: '$primary2',
  border: '1px solid $primary1',
  borderRadius: '50px',
  cursor: 'pointer',
  display: 'inline-flex',
  fontSize: '16px',
  fontWeight: '$bold',
  height: '42px',
  justifyContent: 'center',
  padding: '0px 20px',
  position: 'relative',
  '@screen < $md': {
    fontWeight: '$semibold',
    size: '$sm',
  },
  a: { color: '$primary1' },
});

type Props = {
  activeChip: string;
};

export const SuicideAndCrisisCard = ({ activeChip }: Props) => {
  const { t } = useTranslation();
  const suicideAndCrisisInfos = useLagoon('suicide-and-crisis-lifeline')();

  const {
    suicideAndCrisisLifelineHeader,
    suicideAndCrisisLifelineContent,
    suicideAndCrisisLifelineCta,
  } = suicideAndCrisisInfos.find(
    (suicideAndCrisisInfo: { activeTabs: string }) =>
      suicideAndCrisisInfo.activeTabs.includes(activeChip)
  );

  function onClickCall(url, phone) {
    adobeLinkTrackEvent({
      name: `call ${phone}`,
      location: 'body:suicide and crisis lifeline',
      type: 'phone',
      destinationUrl: url,
    });
  }
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();

  const isCrisisSectionEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CRISIS_SUPPORT_SECTION
  );

  if (isCrisisSectionEnabled) {
    return (
      suicideAndCrisisInfos?.length !== 0 && (
        <CrisisSupport data={suicideAndCrisisInfos} />
      )
    );
  }

  return (
    <ContainerForAllSectionsStyled css={{ marginTop: '8px' }}>
      <ContentWrapper>
        <CardContainer>
          <Grid
            css={{
              margin: '0px',
              gap: 0,
              '@screen < $md': {
                gap: '10px',
              },
            }}
          >
            <Grid.Col
              css={{
                'abyss-grid-col': {
                  padding: '0px',
                  svg: {
                    maxWidth: '100%',
                    width: '165px',
                    height: '111px',
                    '@screen < $md': {
                      height: '72px',
                      width: '112px',
                    },
                  },
                },
              }}
            >
              <PSXIcon
                data-auto-testid="suicide-and-crisis-card-phone-in-talk"
                data-testid="suicide-and-crisis-card-phone-in-talk"
                icon="suicide_and_crisis"
                size={165}
              />
            </Grid.Col>
            <Grid.Col
              css={{
                'abyss-grid-col': {
                  textAlign: 'left',
                  padding: '0px',
                  flex: 1,
                },
              }}
              span={{ xs: 8 }}
            >
              <Box className="box-container" color="$white">
                <Heading
                  css={h2StylingForResponsive}
                  data-auto-testid="suicide-and-crisis-card-heading"
                  data-testid="suicide-and-crisis-card-heading"
                  display="h4"
                  offset={1}
                >
                  {suicideAndCrisisLifelineHeader}
                </Heading>
                <Text
                  color="$gray7"
                  css={{
                    display: 'inline-flex',
                    lineHeight: '24px',
                    fontWeight: '500',
                    color: '$neutralGray',
                    '@screen < $md': {
                      fontSize: '$md',
                      lineHeight: '20px',
                    },
                  }}
                  data-auto-testid="suicide-and-crisis-card-description"
                  data-testid="suicide-and-crisis-card-description"
                  size="$lg"
                >
                  {suicideAndCrisisLifelineContent}
                </Text>
                <Box
                  color="$white"
                  css={{
                    'abyss-box-root': {
                      width: 'auto',
                      height: 'auto',
                      padding: '$lg 0 $sm',
                      '@screen < $md': {
                        padding: '$md 0 0px',
                      },
                    },
                  }}
                >
                  <ContactLink
                    data-auto-testid="suicide-and-crisis-card-call"
                    data-testid="suicide-and-crisis-card-call"
                  >
                    <a
                      data-auto-testid="suicide-and-crisis-call-link"
                      data-testid="suicide-and-crisis-call-link"
                      href={`tel:${suicideAndCrisisLifelineCta}`}
                      onClick={() =>
                        onClickCall(
                          `tel:${suicideAndCrisisLifelineCta}`,
                          suicideAndCrisisLifelineCta
                        )
                      }
                    >
                      {`${t(
                        'TALK_TO_NURSE_CARD.CALL'
                      )} ${suicideAndCrisisLifelineCta}`}
                    </a>
                  </ContactLink>
                </Box>
              </Box>
            </Grid.Col>
          </Grid>
        </CardContainer>
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
