import { styled } from '@abyss/web/tools/styled';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';

export const StyledDrawer = styled(Drawer, {
  zIndex: '999 !important',
  '.abyss-modal-header-container': {
    width: 'fit-content',
  },
  '.abyss-modal-content-container': {
    borderRadius: 0,
    '@screen < $xl': {
      '& .abyss-modal-body > div': {
        marginLeft: '0px',
        marginRight: '0px',
      },
    },
    '@screen < $sm': {
      top: '25px',
      height: 'calc(100vh - 26px)',
      width: '100%',
      borderRadius: '20px 20px 0px 0px',
      color: '$primary1',
      border: '1px solid $primary1',
      '& .abyss-modal-body > div': {
        marginLeft: '16px',
        marginRight: '16px',
      },
    },
  },
  '.abyss-modal-close-button': {
    '@screen < $sm': {
      top: '12px',
      right: '12px',
    },
  },
  '.abyss-modal-body': {
    scrollbarWidth: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

const headingStyle = {
  fontFamily: '$primary !important',
  fontWeight: '700 !important',
  fontSize: '20.25px !important',
  lineHeight: '24px !important',
  letterSpacing: '0px',
};

export const HeadingText = styled(Heading, {
  ...headingStyle,
  marginTop: '16px',
});

export const HeadingTextOnBoarding = styled(Heading, {
  ...headingStyle,
  marginLeft: '4px',
});

export const DescriptionTextOnboarding = styled(Text, {
  margin: '4px 0px 16px 30px',
  fontSize: '18px',
  fontWeight: '400',
  lineHeight: '24px',
});

export const FlexContainer = styled(Flex, {
  marginTop: '16px',
  alignItems: 'center',
});

export const ToggleSwitchField = styled(ToggleSwitch, {
  padding: '4px 16px 4px 16px',
  '.abyss-toggle-switch-label': { marginLeft: '8px' },
});

export const AccordionContainer = styled(Accordion, {
  paddingLeft: '16px',
  paddingRight: '16px',
  fontFamily: '$primary',
  backgroundColor: 'transparent',
  '& .abyss-accordion-trigger': {
    color: '$gray8',
    padding: '0px',
    cursor: 'pointer',
    border: 'none',
    '&:hover': { backgroundColor: '$gray1' },
    '& .abyss-accordion-header': {
      fontWeight: '500',
      fontSize: '18px',
    },
    '& .abyss-accordion-header-subtext': {
      fontSize: '16px',
      fontWeight: '400',
      color: '$gray8',
    },
  },
  '& .abyss-radio-group-container': { marginTop: '16px' },
  '& .abyss-checkbox-group-container': {
    marginTop: '16px',
    maxHeight: '300px',
    overflow: 'auto',
  },
  '& .abyss-accordion-content-text': { padding: '0px 0px 10px 4px' },
});
