import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useLagoon } from '../../../hooks/useLagoon';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { useChipStore } from '../../../store/useChipStore';
import { ChipState } from '../../../store/useChipStore/chipStore';
import { usePCPStore } from '../../../store/usePCPStore';
import { PCPSearchStore } from '../../../store/usePCPStore/pcpStore';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { handleLinkAndModalTrack } from '../../Utils/adobeTrackUtils';
import { getFeatureFlag } from '../../Utils/getFeatureFlag';
import {
  StyledChoosePCPAlertClose,
  StyledChoosePCPAlertGroup,
  StyledChoosePCPAlertIcon,
} from './ChoosePCPAlert.styled';
import { ChoosePCPAlertModal } from './ChoosePCPAlertModal';

type Props = {
  isMapView?: boolean;
};

export const ChoosePCPAlert = ({ isMapView = false }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const setUIState = useStore(StoreKeys.SET_UI_STATE);
  const uiState = useStore(StoreKeys.UI_STATE);

  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${478}px)`);

  const { choosePCP } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      choosePCP: state.pcpSearchState.choosePCP,
    }))
  );
  const { chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
    }))
  );

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const showPcpBanner: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_PCP_ALERT_BANNER
  );

  const buttonYesdataTestId = 'alert-button-yes';
  const buttonNodataTestId = 'alert-button-no';
  const buttonClosedataTestId = 'close-alert-icon';

  const handleCloseAlert = (linkName) => {
    adobeLinkTrackEvent({ name: linkName, location: 'modal:choose pcp alert' });
    setUIState({
      ...uiState,
      hasDismissedLookingForPCP: true,
    });
  };

  const handleOpenModal = (linkName) => {
    handleLinkAndModalTrack(
      linkName,
      'modal:choose pcp alert',
      'pcp member selection'
    );
    setIsModalOpen(true);
  };
  const showAlert =
    !uiState.hasDismissedLookingForPCP &&
    !choosePCP &&
    showPcpBanner &&
    chipValue === Constants.CHIPS_CATEGORIES.PRIMARY_CARE;

  if (!showAlert) {
    return null;
  }

  let height;
  let alignItems;
  let textMargin;
  if (isMobile) {
    height = 90;
    alignItems = 'top';
    textMargin = '0 8px 8px 0';
  } else {
    height = 64;
    alignItems = 'center';
    textMargin = '0 8px 0 0';
  }

  let desktopMargin;
  let mobileMargin;
  if (isMapView) {
    desktopMargin = '$md 0 $sm 0';
    mobileMargin = '0 0 $md 0';
  } else {
    desktopMargin = '0 0 $lg 0';
    mobileMargin = '0 0 $md 0';
  }

  const BoxStyles = {
    borderRadius: '4px',
    border: '1px solid $accent1',
    margin: desktopMargin,
    '@media (max-width: 1268px)': {
      width: '98%',
    },
    '@screen < $xl': {
      width: '94%',
    },
    '@screen < $sm': {
      margin: mobileMargin,
    },
  };

  return (
    <Box
      color="$info2"
      css={BoxStyles}
      data-testid="looking-for-pcp-alert"
      height={height}
    >
      <StyledChoosePCPAlertGroup
        alignItems={alignItems}
        aria-label={t('PRIMARY_CARE_PROVIDER.LOOKING_FOR_PCP')}
        role="group"
      >
        <Layout.Group alignItems={alignItems}>
          <StyledChoosePCPAlertIcon icon="info" size="20" />
          <Flex alignItems="center">
            <Text
              css={{ lineHeight: '18px', margin: textMargin }}
              fontWeight="bold"
              size="14"
            >
              {t('PRIMARY_CARE_PROVIDER.LOOKING_FOR_PCP')}
            </Text>
            <Layout.Group space="8">
              <Button
                data-testid={buttonYesdataTestId}
                onClick={() => handleOpenModal(buttonYesdataTestId)}
                size="$sm"
              >
                {t('Yes')}
              </Button>
              <Button
                data-testid={buttonNodataTestId}
                onClick={() => handleCloseAlert(buttonNodataTestId)}
                size="$sm"
                variant="outline"
              >
                {t('No')}
              </Button>
            </Layout.Group>
          </Flex>
        </Layout.Group>
        <Button
          aria-label="Close message"
          data-testid={buttonClosedataTestId}
          onClick={() => handleCloseAlert(buttonClosedataTestId)}
          rounded
          size="$sm"
          variant="ghost"
        >
          <StyledChoosePCPAlertClose color="$black" icon="close" />
        </Button>
      </StyledChoosePCPAlertGroup>
      <ChoosePCPAlertModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </Box>
  );
};
