import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { getLoggedInMember } from '../../utils/user.utils';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import {
  defaultToZero,
  distanceParser,
  formattedValue,
  getAcoBenefitPlan,
  getFlagValue,
  getLLMStatus,
  getLanguageFormatted,
  useCustomPolicy,
} from '../PSXHeader/SmartChoice/utils';
import { getFeatureFlag } from '../Utils';
import { StyledDrawer } from './SmartChoice.styled';

export const properties: any = {
  drawerSize: 492,
  drawerposition: 'right',
};

export interface Props {
  isOpen: boolean;
  handleCloseDrawer: () => void;
  recommendationDetails: any;
  isTieredPlan: boolean;
  preferencesData: any;
}

export const SmartChoiceDetails = ({
  isOpen,
  handleCloseDrawer,
  recommendationDetails,
  isTieredPlan,
  preferencesData,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const enableLLMMedicalSpecialists: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LLM_MEDICAL_SPECIALISTS
  );

  const {
    quality,
    benefits,
    convenience,
    personalFit,
    verification,
    randomization,
  } = recommendationDetails;

  const qualityScore = quality?.scoreDetails?.value;
  const benefitsScore = benefits?.scoreDetails?.value;
  const convenienceScore = convenience?.scoreDetails?.value;
  const personalFitScore = personalFit?.scoreDetails?.value;
  const verificationScore = verification?.scoreDetails?.value;
  const randomizationScore = randomization?.scoreDetails?.value;
  const qualityPercentage = quality?.scoreDetails?.percentage;
  const benefitsPercentage = benefits?.scoreDetails?.percentage;
  const conveniencePercentage = convenience?.scoreDetails?.percentage;
  const personalFitPercentage = personalFit?.scoreDetails?.percentage;
  const verificationPercentage = verification?.scoreDetails?.percentage;
  const randomizationPercentage = randomization?.scoreDetails?.percentage;
  const { credentialed, premiumDesignation } = quality;
  const {
    costEfficiencyRating,
    costIndicator,
    providerTierDesignation,
    acoParticipationStatus,
  } = benefits;
  const {
    appointmentAvailability,
    eveningAppointments,
    weekendAppointments,
    acceptingNewPatients,
    virtualAppointments,
    onlineAppointmentScheduling,
    memberPreferredRadius,
  } = convenience;
  const {
    consumerRatingMidPointStatus,
    boardCertification,
    consumerRating,
    consumerRatingSurveyVolume,
    gender,
    languageSpoken,
  } = personalFit;
  const randomizationKey = preferencesData.randomizationKey;
  const { officeLocationScore } = verification;
  const distance = convenience?.distance != null ? convenience?.distance : '';
  const formattedDist = distanceParser(distance);
  const tier = getFlagValue(providerTierDesignation);
  const affiliation = getFlagValue(acoParticipationStatus);
  const preferredProviderStatus = getFlagValue(
    benefits?.preferredProviderStatus
  );
  const selectedMember = getLoggedInMember();
  const allConfigs = useLagoon('config')();
  const acoBenefitPlanCollection = allConfigs?.find(
    (row) => row.key === 'ACO_BENEFIT_COLLECTION'
  )?.value;

  const { isCustomPreferredPolicy } = useCustomPolicy(
    selectedMember,
    useLagoon('CUSTOM_PREFERRED_POLICY')
  );

  const isAcoBenefitPlan = getAcoBenefitPlan(
    selectedMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
      (coverage) => coverage.typeCode?.coverageTypeCode === 'M'
    )?.productCode ?? '',
    selectedMember?.eligibility
      .flatMap((coverage) => coverage?.memberHealthCoverage?.coverageType)
      .map((coverageType) => coverageType?.marketType ?? ''),
    selectedMember?.membershipCategory ?? '',
    selectedMember?.population ?? '',
    acoBenefitPlanCollection
  );

  const renderOverallRecommendationScore = (
    t: any,
    qualityScore: any,
    benefitsScore: any,
    convenienceScore: any,
    personalFitScore: any,
    verificationScore: any,
    randomizationScore: any
  ) => {
    const qualityScoreValue = defaultToZero(qualityScore);
    const benefitsScoreValue = defaultToZero(benefitsScore);
    const convenienceScoreValue = defaultToZero(convenienceScore);
    const personalFitScoreValue = defaultToZero(personalFitScore);
    const verificationScoreValue = defaultToZero(verificationScore);
    const randomizationScoreValue = defaultToZero(randomizationScore);
    const totalScore =
      parseFloat(qualityScoreValue) +
      parseFloat(benefitsScoreValue) +
      parseFloat(convenienceScoreValue) +
      parseFloat(personalFitScoreValue) +
      parseFloat(verificationScoreValue) +
      parseFloat(randomizationScoreValue);

    return (
      <Flex alignItems="flex-end" justify="space-between">
        <Text css={{ fontSize: '18px', fontWeight: '$bold' }}>
          {t('SMART_CHOICE.AUDIT_SCREEN.Overall Score')}:
        </Text>
        {totalScore !== null ? (
          <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>
            {totalScore}
          </Text>
        ) : (
          <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>
            {t('N/A')}
          </Text>
        )}
      </Flex>
    );
  };

  const showScore = (
    title: string,
    qualityScore: string,
    qualityPercentage: string
  ) => (
    <Flex
      css={{ marginTop: '15px', marginBottom: '15px' }}
      justify="space-between"
    >
      <Text
        css={{ fontSize: '18px', fontWeight: '$bold' }}
        data-testid="smart-choice-sub-heading"
      >
        {title}
      </Text>
      <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>
        {t('SMART_CHOICE.AUDIT_SCREEN.Score_percentage', {
          qualityScore,
          qualityPercentage,
        })}
      </Text>
    </Flex>
  );

  const showMeasures = (val: string, key: string | null | undefined) => (
    <Flex justify="space-between">
      <Text css={{ fontSize: '15px' }} data-testid="smart-choice-sub-heading">
        {val}
      </Text>
      <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>
        {key !== '' ? formattedValue(key) : t('N/A')}
      </Text>
    </Flex>
  );

  const showValue = (fieldVal: string) =>
    fieldVal !== '' ? (
      <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>{fieldVal}</Text>
    ) : (
      <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>{t('N/A')}</Text>
    );

  return (
    <React.Fragment>
      <StyledDrawer
        data-auto-testid="onboarding-smart-choice-drawer"
        data-testid="onboarding-smart-choice-drawer"
        isOpen={isOpen}
        onClose={handleCloseDrawer}
        position={properties.position}
        size={properties.drawerSize}
        title={
          <Heading
            color="$primary"
            css={{ fontSize: '32.45px !important' }}
            data-testid="onboarding-smart-choice-heading"
            offset={5}
            textAlign="center"
          >
            {t('SMART_CHOICE.AUDIT_SCREEN.Smart Choice Details')}
          </Heading>
        }
      >
        <ContentWrapper style={{ padding: mobileScreen ? '0px' : '16px' }}>
          {renderOverallRecommendationScore(
            t,
            qualityScore,
            benefitsScore,
            convenienceScore,
            personalFitScore,
            verificationScore,
            randomizationScore
          )}
          {/* Qualtiy Scores */}
          {showScore(
            t('SMART_CHOICE.AUDIT_SCREEN.Quality'),
            qualityScore,
            qualityPercentage
          )}
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Provider Credentialing')}:`,
            credentialed
          )}
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.UHPD')}:`,
            premiumDesignation
          )}
          <Flex justify="space-between">
            <Text>{`${t('SMART_CHOICE.AUDIT_SCREEN.LLM Score')}:`}</Text>
            {enableLLMMedicalSpecialists ? (
              <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>
                {getLLMStatus(quality?.llmStatus, t)}
              </Text>
            ) : (
              <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>
                {t('N/A')}
              </Text>
            )}
          </Flex>

          {/* Benfits Score */}
          {showScore(
            t('SMART_CHOICE.AUDIT_SCREEN.Benefits'),
            benefitsScore,
            benefitsPercentage
          )}
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Network Status')}:`,
            'Y'
          )}
          <Flex justify="space-between">
            <Text
              css={{ fontSize: '15px' }}
              data-testid="smart-choice-sub-heading"
            >
              {`${t('SMART_CHOICE.AUDIT_SCREEN.Cost Efficiency')}:`}
            </Text>
            <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>
              {costEfficiencyRating !== '' ? costEfficiencyRating : t('N/A')}
            </Text>
          </Flex>
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Tier')}:`,
            isTieredPlan === true ? tier : ''
          )}
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.ACO affiliation')}:`,
            isAcoBenefitPlan ? affiliation : ''
          )}
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Green Diamond')}:`,
            isCustomPreferredPolicy ? preferredProviderStatus : ''
          )}
          <Flex justify="space-between">
            <Text css={{ fontSize: '15px' }}>{`${t(
              'SMART_CHOICE.AUDIT_SCREEN.Cost Indicator'
            )}:`}</Text>
            {showValue(costIndicator)}
          </Flex>

          {/* Convenience Score */}
          {showScore('Convenience', convenienceScore, conveniencePercentage)}
          <Flex justify="space-between">
            <Text css={{ fontSize: '15px' }}>{`${t(
              'SMART_CHOICE.AUDIT_SCREEN.Appointment Availability Window'
            )}:`}</Text>
            {showValue(appointmentAvailability)}
          </Flex>
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Evening Availability')}:`,
            eveningAppointments
          )}
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Weekend Availability')}:`,
            weekendAppointments
          )}
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Accepting New Patients')}:`,
            acceptingNewPatients
          )}
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Online Appointment scheduling')}:`,
            onlineAppointmentScheduling
          )}
          <Flex justify="space-between">
            <Text css={{ fontSize: '15px' }}>{`${t(
              'SMART_CHOICE.AUDIT_SCREEN.Distance'
            )}:`}</Text>
            {distance !== '' ? (
              <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>
                {formattedDist}
              </Text>
            ) : (
              <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>
                {t('N/A')}
              </Text>
            )}
          </Flex>
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Virtual Appointments')}:`,
            virtualAppointments
          )}
          {showMeasures(
            `${t(
              'SMART_CHOICE.AUDIT_SCREEN.Member Preferred Radius Indicator'
            )}:`,
            memberPreferredRadius
          )}
          {/* Personal Fit Score */}
          {showScore(
            t('SMART_CHOICE.AUDIT_SCREEN.Personal Fit'),
            personalFitScore,
            personalFitPercentage
          )}
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Language Spoken - Practitioner')}:`,
            languageSpoken
          )}
          <Flex justify="space-between">
            <Text>{`${t(
              'SMART_CHOICE.AUDIT_SCREEN.Preferred Languages'
            )}:`}</Text>
            {preferencesData?.languagesSpoken !== '' ? (
              <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>
                {getLanguageFormatted(preferencesData?.languagesSpoken)}
              </Text>
            ) : (
              <Text css={{ fontSize: '15px', fontWeight: '$bold' }}>
                {t('N/A')}
              </Text>
            )}
          </Flex>
          <Flex justify="space-between">
            <Text css={{ fontSize: '15px' }}>{`${t(
              'SMART_CHOICE.AUDIT_SCREEN.Gender'
            )}:`}</Text>
            {showValue(gender)}
          </Flex>
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Board Certified')}:`,
            boardCertification
          )}
          <Flex justify="space-between">
            <Text css={{ fontSize: '15px' }}>{`${t(
              'SMART_CHOICE.AUDIT_SCREEN.Consumer Rating'
            )}:`}</Text>
            {showValue(consumerRating)}
          </Flex>
          <Flex justify="space-between">
            <Text css={{ fontSize: '15px' }}>{`${t(
              'SMART_CHOICE.AUDIT_SCREEN.Consumer Rating Survey Volume'
            )}:`}</Text>
            {showValue(consumerRatingSurveyVolume)}
          </Flex>
          {showMeasures(
            `${t('SMART_CHOICE.AUDIT_SCREEN.Mid Point Rating Indicator')}:`,
            consumerRatingMidPointStatus
          )}

          {/* Updated Info Score */}
          {showScore(
            t('SMART_CHOICE.AUDIT_SCREEN.Updated Information'),
            verificationScore,
            verificationPercentage
          )}
          <Flex justify="space-between">
            <Text css={{ fontSize: '15px' }}>{`${t(
              'SMART_CHOICE.AUDIT_SCREEN.Address Confidence Indicator'
            )}:`}</Text>
            {showValue(officeLocationScore)}
          </Flex>

          {/* Randomization Score */}
          {showScore(
            t('SMART_CHOICE.AUDIT_SCREEN.Randomization'),
            randomizationScore,
            randomizationPercentage
          )}
          <Flex justify="space-between">
            <Text css={{ fontSize: '15px' }}>{`${t(
              'SMART_CHOICE.AUDIT_SCREEN.Randomization Key'
            )}:`}</Text>
            {showValue(randomizationKey)}
          </Flex>
          <Flex css={{ marginTop: '30px' }}>
            <Text css={{ fontSize: '15px' }}>
              {t('SMART_CHOICE.AUDIT_SCREEN.Not_A_Match')}
            </Text>
          </Flex>
        </ContentWrapper>
      </StyledDrawer>
    </React.Fragment>
  );
};
