import { useState, useEffect } from 'react';
import { useFeatureFlag } from '../useFeatureFlag';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getCurrentMember, isTier1Plan } from '../../utils/user.utils';
import { StoreKeys } from '../useStore/state';
import { useStore } from '../../hooks/useStore/useStore';

export const useTier1PlanIdentifier = (isTiredPlanVal: boolean | undefined, rpk: string = '') => {
  const [rulesPackageKey, setRulesPackageKey] = useState('');
  const [enableTier1FromMemberRPK] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER1_FROM_MEMBER_RPK,
  ]);
  const currentMember = getCurrentMember();
  const rpkFromMemberInfo = currentMember?.rulesPackageKey;
  const setIsTier1Plan = useStore(StoreKeys.SET_TIER1_PLAN_FLAG);

  useEffect(() => {
    if (rpk || rpkFromMemberInfo) {
      setRulesPackageKey(rpk || rpkFromMemberInfo);
    }
  }, [rpk, rpkFromMemberInfo]);

  useEffect(() => {
    const tier1Status =
      isTier1Plan(
        enableTier1FromMemberRPK,
        rpkFromMemberInfo,
        isTiredPlanVal
      );
    setIsTier1Plan(tier1Status);
  }, [
    isTiredPlanVal,
    rpkFromMemberInfo,
    enableTier1FromMemberRPK,
  ]);
  return { rulesPackageKey };
};