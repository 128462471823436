import { storage } from '@abyss/web/tools/storage';
import { Layout } from '@abyss/web/ui/Layout';
import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { AdditionalResources } from '../../../../common/AdditionalResources/AdditionalResources';
import { CardWrapperDetailsPage } from '../../../../common/CardWrapper';
import {
  CareCategories,
  Constants,
  LOB,
  MemberCoverageTypeToUICoverageTypeConversion,
  ReverseCoverageTypesCodes,
  ReverseDentalCodes,
  ReverseVisionCodes,
} from '../../../../common/Constants';
import { FeatureFlags } from '../../../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { CustomMessageNotification } from '../../../../common/CustomMessageConfig/CustomMessageNotification';
import { DisclaimerContent } from '../../../../common/DisclaimerContent';
import { getLagoonFlag } from '../../../../common/LagoonContent/LagoonContent';
import { SectionSkeleton } from '../../../../common/LazyLoaderSkeleton';
import { RecentSearchSkeleton } from '../../../../common/LazyLoaderSkeleton/RecentSearchSkeleton';
import { LoadingSpinnerComp } from '../../../../common/Loader';
import { OnlineSpecialtyGroup } from '../../../../common/OnlineSpecialtyGroups/OnlineSpecialtyGroups';
import { PSXHeader } from '../../../../common/PSXHeader';
import {
  CoverageType,
  MemberInfo,
} from '../../../../common/PSXHeader/ObapiDemo/memberProfile';
import { SuicideAndCrisisCard } from '../../../../common/SuicideAndCrisisCard';
import { getFeatureFlag } from '../../../../common/Utils';
import { WhereToGetHelp } from '../../../../common/WhereToGetHelp/WhereToGetHelp';
import { PortalContext } from '../../../../context/PortalContext';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { ErrorBoundary } from '../../../../errors/ErrorBoundary';
import { useAdobeProvidersImpressionBlock } from '../../../../hooks/adobeHook/useAdobeProvidersImpressionBlock';
import { useClearStore } from '../../../../hooks/useClearStore';
import { useCurrentMemberIsPreEffective } from '../../../../hooks/useCurrentMemberIsPreEffective';
import { useInitializeStore } from '../../../../hooks/useInitializeStore';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import {
  getPortalDefaultSearchParams,
  isPolicyIdSuppressedForMarketPlace,
  isValidEnumValue,
} from '../../../../utils/common';
import { returnSuppressFlag } from '../../../../utils/featureSuppress';
import { getLagoonSuicideAndCrisisCardTabs } from '../../../../utils/suicideAndCrisis.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getMemberHasDesignatedNetwork,
  getNetworkIdForDentalVision,
} from '../../../../utils/user.utils';
import { VisionCareProviders } from '../VisionCareProviders';
import { AllLandingPageSectionsContainer } from './HealthGradesContainer.styled';
import * as LazyComponents from './lazyComponents';

const Loader = () => <SectionSkeleton />;

const primaryCareApiCalls = {
  numberOfApiCalls: 2,
  skipCostIndicator: true,
};

export const PSXHome = () => {
  useInitializeStore(ConstantsRoutes.LANDING_PAGE.key);
  const { LOGGED_IN_MEMBER_INDEX, ACTIVE_MEMBER_INDEX } =
    Constants.STORAGE_KEYS.SESSION;
  const activeMemberIndex =
    Number(storage.session.get(ACTIVE_MEMBER_INDEX)) ?? 0;
  const storedMembers = useStore(StoreKeys.OBAPI_MEMBERS);
  const [memberProfile, setMemberProfile] =
    useState<MemberInfo[]>(storedMembers);
  const loggedInMemberIndex = storage.session.get(LOGGED_IN_MEMBER_INDEX);
  const loggedInMemberInfo = memberProfile[loggedInMemberIndex];
  const cnsKeyValue = 'cnsKeyValue';
  sessionStorage.removeItem(cnsKeyValue);
  const currentMember = getCurrentMember();
  const { isKelseySeyboldPlan = false } = currentMember || {};
  const { portalData } = useContext(PortalContext);

  const { clearAllStores } = useClearStore();
  const [hasMedicalCoverage, setHasMedicalCoverage] = useState(true);
  const [
    suppressPrimaryCareProviderForCnS,
    setSuppressPrimaryCareProviderForCnS,
  ] = useState(false);
  const chipCatagories: CareCategories[] = Object.values(CareCategories);
  const [isLoading, setIsLoading] = useState(false);
  const [customMessage, setCustomMessage] = useState('');
  const isVirtualCareSuppressedForEmpire = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_EMPIRE
  );
  const isTrackRequired = false;

  const featureFlags = useLagoon('feature-flags')();
  const enableCns = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CNS
  );

  const enableNewReferralsIndicator = getFeatureFlag(
    featureFlags,
    'ENABLE_NEW_REFERRALS_INDICATOR'
  );

  const displayNewReferral =
    enableNewReferralsIndicator && loggedInMemberInfo?.isIFPMember;

  const dentalNetworkId = getNetworkIdForDentalVision(
    currentMember,
    ReverseDentalCodes
  );
  const visionNetworkId = getNetworkIdForDentalVision(
    currentMember,
    ReverseVisionCodes
  );
  const memberCoverages = getCoverageTypes(currentMember);
  const convertedMemberCoverages = memberCoverages?.map(
    (cov) => MemberCoverageTypeToUICoverageTypeConversion[cov]
  );
  const { value, setCoverageType, setChipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      value: state.chipValue,
      setChipValue: state.setChipValue,
      setCoverageType: state.setCoverageType,
    }))
  );

  const ariaControls = (activeChip) => {
    const tabList = document.querySelector(
      '[data-testid="tabs-category-list-abyss-tabs-list"]'
    )?.children;
    if (tabList != null) {
      for (const element of tabList) {
        if (element.getAttribute('label')?.trim() === activeChip.trim()) {
          return element.getAttribute('aria-controls') || undefined;
        }
      }
    }
    return undefined;
  };

  // If Portal Config has default chip and coverage type values, set them as default values.
  const { chipValue: portalChipValue, coverageType: portalCoverageType } =
    getPortalDefaultSearchParams();
  const isValidChipValue =
    portalChipValue && isValidEnumValue(CareCategories, portalChipValue);
  const isValidCoverageTypeValue =
    portalCoverageType &&
    isValidEnumValue(ReverseCoverageTypesCodes, portalCoverageType);
  if (isValidChipValue && isValidCoverageTypeValue) {
    setChipValue(portalChipValue);
    setCoverageType(portalCoverageType);
  }

  const [onProvidersLoad, onError, reSetValue] =
    useAdobeProvidersImpressionBlock({
      ...primaryCareApiCalls,
      numberOfApiCalls: enableCns && memberProfile[0]?.isCnSMember ? 3 : 2,
      isTrackRequired,
    });

  const firstRender = useRef(true);

  const showVirtualCareForBH = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH
    ].VIRTUAL_CARE
  );

  useEffect(() => {
    let timer;
    if (firstRender.current) {
      firstRender.current = false;
      setIsLoading(false);
    } else {
      setIsLoading(true);
      timer = setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [activeMemberIndex]);

  useEffect(() => {
    document.title = `${value} | ${Constants.SITE_NAME}`;

    if (value === Constants.CHIPS_CATEGORIES.PRIMARY_CARE) {
      reSetValue('');
    }
  }, [value]);

  const [virtualCareCardShow, setVirtualCareCardShow] =
    useState<boolean>(false);

  const [memberId, setMemberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );
  const [, setBreadcrumbUrls] = useSessionStorage<BreadcrumbSessionStorage>(
    Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
    {}
  );

  const { clearSearchFilters } = useContext(SearchFilterContext);
  const featureSuppression = useLagoon(
    Constants.LAGOON_TABLE.FEATURE_SUPPRESSION
  )();

  const marketPlaceSuppress = featureSuppression.find(
    (item) => item.DNT_feature === 'Market Place'
  );

  const galileoFlag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY.GALILEO
  );
  const notificationsFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.NOTIFICATIONS
  );
  const thingsToKnowFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.THINGS_TO_KNOW_DECEMBER_RLS
  );

  const showCustomMessageNotification = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.CUSTOM_MESSAGE_NOTIFICATION
  );

  const dentalIntergratedViewEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DENTAL_INTEGRATED_VIEW
  );

  const lagoonData: [{ key: string; isActive: boolean }] =
    useLagoon('vision')();

  const talkToNurseVisionPageFLag = getLagoonFlag(
    lagoonData,
    ConstantsLagoon.VISION.VISION_PAGE_TALK_TO_NURSE_TOGGLE
  );

  const enableMarketPlace = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MARKET_PLACE
  );

  const suicideAndCrisisInfos = useLagoon('suicide-and-crisis-lifeline')();
  const lagoonSuicideAndCrisisCardTabs = getLagoonSuicideAndCrisisCardTabs(
    suicideAndCrisisInfos
  );
  const suppressVirtualCareQuickCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_QUICK_CARE
  );

  const enableIFP = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_IFP
  );

  const isPreEffective = useCurrentMemberIsPreEffective();
  const activeMemberLOB = memberProfile?.[0]?.lineOfBusiness;

  const enableBrowseForCare = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BROWSE_FOR_CARE
  );

  const enableCommonProviderSearches = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_COMMON_PROVIDER_SEARCHES
  );

  useEffect(() => {
    setBreadcrumbUrls({});
    setMemberId(memberId);
    clearSearchFilters();
    clearAllStores();
  }, []);

  useEffect(() => {
    let currentActiveMemberIndex = activeMemberIndex;
    if (activeMemberLOB === LOB.MNR || activeMemberLOB === LOB.CNS) {
      currentActiveMemberIndex = 0;
      storage.session.set(ACTIVE_MEMBER_INDEX, '0');
    }
    let showVirtualCareCard: boolean = false;
    if (memberProfile[0]?.eligibility[0]?.memberHealthCoverage?.coverageType) {
      const coverageType =
        memberProfile[0]?.eligibility[0]?.memberHealthCoverage.coverageType.find(
          (x: CoverageType) =>
            x.typeCode &&
            x.typeCode.desc === Constants.COVERAGE_TYPECODE_TO_CHIP.M
        );

      setSuppressPrimaryCareProviderForCnS(
        memberProfile[0]?.isCnSMember && coverageType?.situsState === 'IN'
      );

      if (coverageType && coverageType?.designatedNetwork) {
        showVirtualCareCard = getMemberHasDesignatedNetwork(
          coverageType,
          'DVCN'
        );
      }
    }

    showVirtualCareCard = memberProfile[0]?.isCnSMember
      ? !memberProfile[0].isCnSDsnpMember &&
        memberProfile[0].isTelemedicineEligible
      : showVirtualCareCard && !isVirtualCareSuppressedForEmpire;

    setVirtualCareCardShow(showVirtualCareCard);

    let showMedical = false;

    if (memberProfile[currentActiveMemberIndex]?.eligibility) {
      showMedical = memberProfile[currentActiveMemberIndex].eligibility.some(
        (record) =>
          record?.memberHealthCoverage?.coverageType?.some(
            (coverageType) =>
              coverageType.typeCode &&
              coverageType.typeCode.desc ===
                Constants.COVERAGE_TYPECODE_TO_CHIP.M
          )
      );
    }

    setHasMedicalCoverage(showMedical);
  }, [
    memberProfile,
    activeMemberIndex,
    isVirtualCareSuppressedForEmpire,
    activeMemberLOB,
  ]);

  const handleMemberIdChange = (id) => {
    setMemberId(id);
  };

  const handleMemberDataChange = (data: MemberInfo[]) => {
    setMemberProfile(data);
  };

  const setCustomMessageFn = (customMessageValue) => {
    setCustomMessage(customMessageValue);
  };

  // checks if tabs are active in lagoon
  const isActiveTab = (
    currentChip: string,
    chipCategory: string,
    lagoonActiveChips: string[]
  ): boolean =>
    currentChip === chipCategory &&
    lagoonActiveChips?.includes(chipCategory.replace(/_/g, ' '));

  const isOnlineSpecialtyGroupActive = () =>
    (virtualCareCardShow ||
      portalData?.portalConfig?.showMarketPlace == true) &&
    !isPreEffective &&
    !suppressVirtualCareQuickCare &&
    enableMarketPlace &&
    showVirtualCareForBH &&
    !isPolicyIdSuppressedForMarketPlace(marketPlaceSuppress, currentMember);

  return (
    <React.Fragment>
      <ErrorBoundary>
        <PSXHeader
          customMessage={customMessage}
          dataTestId="search-home-on-submit"
          onMemberDataChange={handleMemberDataChange}
          onMemberIdChange={handleMemberIdChange}
        />
        {showCustomMessageNotification &&
          value === Constants.CHIPS_CATEGORIES.ALL && (
            <Suspense fallback={<Loader />}>
              <CustomMessageNotification
                setCustomMessageFn={setCustomMessageFn}
              />
            </Suspense>
          )}
        <CardWrapperDetailsPage id={ariaControls(value)} role="tabpanel">
          {notificationsFlag ? (
            <LazyComponents.Notifications value={value} />
          ) : null}
          <Layout.Stack grow space={0}>
            {value !== Constants.CHIPS_CATEGORIES.PRIMARY_CARE && (
              <Suspense fallback={<RecentSearchSkeleton />}>
                <AllLandingPageSectionsContainer>
                  <LazyComponents.RecentActivityAndVisit activeChip={value} />
                </AllLandingPageSectionsContainer>
              </Suspense>
            )}
            {value === Constants.CHIPS_CATEGORIES.ALL &&
              hasMedicalCoverage &&
              (isLoading ? (
                <LoadingSpinnerComp isLoading={isLoading} />
              ) : (
                <ErrorBoundary>
                  <AllLandingPageSectionsContainer>
                    <LazyComponents.CareOptions
                      virtualCareCardShowFlag={virtualCareCardShow}
                    />
                    {enableBrowseForCare && (
                      <LazyComponents.CommonlySearchedCard
                        selectedChipValue={value}
                      />
                    )}
                    {enableCommonProviderSearches && (
                      <LazyComponents.CommonProviderSearches
                        dentalNetworkId={dentalNetworkId}
                        lob={activeMemberLOB}
                        memberCoverageTypes={convertedMemberCoverages}
                        visionNetworkId={visionNetworkId}
                      />
                    )}
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={false}
                    />
                    <AdditionalResources />
                  </AllLandingPageSectionsContainer>
                </ErrorBoundary>
              ))}
            {value === Constants.CHIPS_CATEGORIES.VIRTUAL_VISIT && (
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                  <AllLandingPageSectionsContainer>
                    <LazyComponents.VirtualVisit
                      data-auto-testid="search-home-primary-card"
                      data-testid="search-home-primary-card"
                    />
                  </AllLandingPageSectionsContainer>
                </Suspense>
              </ErrorBoundary>
            )}
            {value === Constants.CHIPS_CATEGORIES.PRIMARY_CARE && (
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                  <AllLandingPageSectionsContainer>
                    <LazyComponents.PrimaryCareProvider
                      data-auto-testid="search-home-primary-card"
                      data-testid="search-home-primary-card"
                    />
                    <LazyComponents.RecentActivityAndVisit activeChip={value} />
                    <LazyComponents.TopPrimaryCareProviderCards
                      data-auto-testid="home-top-primary-card"
                      data-testid="home-top-primary-card"
                      onCompleted={onProvidersLoad}
                      onError={onError}
                    />
                    <LazyComponents.TopPrimaryCareMedicalGroupsCards
                      data-auto-testid="home-top-primary-care-medical-groups"
                      data-testid="home-top-primary-care-medical-groups"
                      onCompleted={onProvidersLoad}
                      onError={onError}
                      suppressPrimaryCareProviderForCnS={
                        suppressPrimaryCareProviderForCnS
                      }
                    />
                    {enableCns && memberProfile[0]?.isCnSMember && (
                      <LazyComponents.FQHCandRHCCards
                        data-auto-testid="fqhc-rhc-cards"
                        data-testid="fqhc-rhc-cards"
                        onCompleted={onProvidersLoad}
                        onError={onError}
                      />
                    )}
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={virtualCareCardShow}
                    />
                  </AllLandingPageSectionsContainer>
                </Suspense>
              </ErrorBoundary>
            )}

            {value === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS && (
              <ErrorBoundary>
                {/* Active Referrals Cards are not part of V1 release */}

                {/* <ActiveReferralsCard
                  data-auto-testid="active-referals-card"
                  data-testid="active-referals-card"
                  referralsCount="2"
                  to="#"
                /> */}
                <Suspense fallback={<Loader />}>
                  <AllLandingPageSectionsContainer>
                    {displayNewReferral &&
                      !loggedInMemberInfo?.isPreEffective &&
                      loggedInMemberInfo?.isReferralRequired &&
                      !isKelseySeyboldPlan && (
                        <LazyComponents.ReferralCard
                          data-auto-testid="search-home-referral-card"
                          data-testid="search-home-referral-card"
                        />
                      )}
                    <LazyComponents.BrowseMedicalSpecialists
                      data-auto-testid="search-home-browse-common-specialties-card"
                      data-testid="search-home-browse-common-specialties-card"
                    />
                    {!displayNewReferral &&
                      !!loggedInMemberInfo?.isGatedUser &&
                      !loggedInMemberInfo?.isIFPMember &&
                      !isKelseySeyboldPlan && (
                        <LazyComponents.ReferralCard
                          data-auto-testid="search-home-referral-card"
                          data-testid="search-home-referral-card"
                        />
                      )}
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={virtualCareCardShow}
                    />
                  </AllLandingPageSectionsContainer>
                </Suspense>
              </ErrorBoundary>
            )}
            {value === Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH && (
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                  <AllLandingPageSectionsContainer>
                    {enableIFP && <LazyComponents.BHTierOneMessage />}
                    <LazyComponents.BrowseBehavioralHealthProviders />
                    {isOnlineSpecialtyGroupActive() && <OnlineSpecialtyGroup />}
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={virtualCareCardShow}
                    />
                  </AllLandingPageSectionsContainer>
                </Suspense>
              </ErrorBoundary>
            )}
            {value === Constants.CHIPS_CATEGORIES.HEALTH_SUPPLIES && (
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                  <AllLandingPageSectionsContainer>
                    <LazyComponents.BrowseHealthSupplies
                      data-auto-testid="browse-health-supplies-facility-section"
                      data-testid="browse-health-supplies-facility-section"
                    />
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={virtualCareCardShow}
                    />
                  </AllLandingPageSectionsContainer>
                </Suspense>
              </ErrorBoundary>
            )}
            {value === Constants.CHIPS_CATEGORIES.FACILITIES && (
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                  <AllLandingPageSectionsContainer>
                    <LazyComponents.BrowseLabAndServices />
                    {thingsToKnowFlag && <LazyComponents.ThingsToKnowCard />}
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={virtualCareCardShow}
                    />
                  </AllLandingPageSectionsContainer>
                </Suspense>
              </ErrorBoundary>
            )}
          </Layout.Stack>
          {value !== Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS &&
            value !== Constants.CHIPS_CATEGORIES.HEALTH_SUPPLIES &&
            value !== Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH &&
            value !== Constants.CHIPS_CATEGORIES.FACILITIES && (
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                  {galileoFlag && (
                    <LazyComponents.GalileoCard
                      data-auto-testid="search-home-galileo-card"
                      data-testid="search-home-galileo-card"
                    />
                  )}
                </Suspense>
              </ErrorBoundary>
            )}
          {value === Constants.CHIPS_CATEGORIES.VISION && (
            <Suspense fallback={<Loader />}>
              <AllLandingPageSectionsContainer>
                <VisionCareProviders />
                <WhereToGetHelp selectedChipValue={value} />
                <LazyComponents.ChipsGeneralContent
                  data-auto-testid="chips-general-content"
                  data-testid="chips-general-content"
                  selectedChipValue={value}
                  virtualPCPAssignedFlag={virtualCareCardShow}
                />
              </AllLandingPageSectionsContainer>
            </Suspense>
          )}
          {value === Constants.CHIPS_CATEGORIES.DENTAL && (
            <Suspense fallback={<Loader />}>
              <AllLandingPageSectionsContainer>
                {dentalIntergratedViewEnabled ? (
                  <React.Fragment>
                    <LazyComponents.BrowseDentalProviders />
                    <WhereToGetHelp selectedChipValue={value} />
                  </React.Fragment>
                ) : (
                  <LazyComponents.CommonlySearchedCard
                    selectedChipValue={value}
                  />
                )}
                <LazyComponents.ChipsGeneralContent
                  data-auto-testid="chips-general-content"
                  data-testid="chips-general-content"
                  selectedChipValue={value}
                  virtualPCPAssignedFlag={virtualCareCardShow}
                />
              </AllLandingPageSectionsContainer>
            </Suspense>
          )}
          {chipCatagories.map(
            (chipCategory) =>
              isActiveTab(
                value,
                chipCategory,
                lagoonSuicideAndCrisisCardTabs
              ) && (
                <Suspense fallback={<Loader />}>
                  <SuicideAndCrisisCard
                    activeChip={value}
                    data-auto-testid="suicide-and-crisis-card"
                    data-testid="suicide-and-crisis-card"
                  />
                </Suspense>
              )
          )}
          {value === Constants.CHIPS_CATEGORIES.VISION &&
            (talkToNurseVisionPageFLag ? (
              <LazyComponents.TalkToNurseCard
                data-auto-testid="talk-to-nurse-card-vision"
                data-testid="talk-to-nurse-card-vision"
              />
            ) : null)}
          <DisclaimerContent selectedChipValue={value} />
        </CardWrapperDetailsPage>
      </ErrorBoundary>
    </React.Fragment>
  );
};
