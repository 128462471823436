import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { useTranslation } from 'react-i18next';

import {
  cardHeaderContainerStyle,
  cardHeaderMyBenefits,
  crisisSupportCarouselScreen,
} from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import {
  carouselStyle,
  slideContainerStyle,
} from '../MyBenefits/MyBenefits.style';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { CrisisSupportCard } from './CrisisSupportCard';

export type CrisisSupportProps = {
  'data-testid'?: string;
  data: any[];
};

export const CrisisSupport = ({ data }) => {
  const { t } = useTranslation();
  const displayCarousel = useMediaQuery(crisisSupportCarouselScreen);
  const slides = data?.map((item, i) => (
    <Slide key={`crisis-support-${i}`}>
      <Slide.Container css={slideContainerStyle}>
        <CrisisSupportCard data={item} index={i} />
      </Slide.Container>
    </Slide>
  ));

  return (
    <ContainerForAllSectionsStyled>
      <ContentWrapper>
        <Flex
          alignItems="flex-end"
          css={cardHeaderContainerStyle}
          justify="start"
        >
          <Heading
            css={cardHeaderMyBenefits}
            data-auto-testid={'crisis-support-heading'}
            data-testid={'crisis-support-heading'}
            display="h4"
            offset={1}
          >
            {t('Crisis Support')}
          </Heading>
        </Flex>

        <Carousel
          css={
            (displayCarousel || data.length > 3) && data.length !== 1
              ? carouselStyle
              : {
                  ...carouselStyle,
                  'abyss-carousel-minimal-controls': {
                    display: 'none',
                  },
                }
          }
          key={`crisis-support-data-${data ? data.length : 0}`}
          minimal
          noLoop
          slides={slides}
          slidesPerView={1}
        />
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
