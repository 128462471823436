import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { adobeStandardSearchEvent } from '../../common/AdobeTagging/adobeStandardSearchEvent';
import {
  convertProviderTypeToAdobeType,
  getSearchLocation,
} from '../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useAdobePageTrackEvent } from '../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCoverageType } from '../../hooks/useCoverageType';
import { useGeoLocationStorage } from '../../hooks/useGeoLocationStorage';
import { useLagoon } from '../../hooks/useLagoon';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { CommonlySearchedCard } from '../CommonlySearched';
import {
  Constants,
  ReverseCoverageTypesCodes,
  SUGGESTED_PROVIDER,
} from '../Constants';
import { SuggestedProviders } from '../SuggestedProviders/SuggestedProviders';
import { WhereToGetHelp } from '../WhereToGetHelp/WhereToGetHelp';
import { NoResult } from './NoResult';
import {
  CommonlySearchedStyled,
  NullPageContainerStyled,
  TopRecommendedStyled,
  WhereToGetHelpContainer,
} from './NullResultsPage.styles';
import { TopRecommendedProvider } from './TopRecommendedProvider';

export type NullResultsPageProps = {
  searchTitle: string;
  specialtyCode: string;
  emptyResults: boolean;
  specialtySearch?: boolean;
  onCommonSearchChipClicked: () => void;
};

export const NullResultsPage = ({
  searchTitle,
  specialtyCode,
  emptyResults,
  specialtySearch,
  onCommonSearchChipClicked,
}: NullResultsPageProps) => {
  const coverageType = useCoverageType();
  const { searchMethod, search, searchTerm, providerType } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      searchMethod: state.typeaheadSearchStore.searchMethod,
      search: state.typeaheadSearchStore.search,
      searchTerm: state.typeaheadSearchStore.searchTerm,
      providerType: state.typeaheadSearchStore.providerType,
    }))
  );
  const { originLinkNameForAnalytics } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      originLinkNameForAnalytics:
        state.analyticsState.originLinkNameForAnalytics,
    }))
  );
  const storedLocation = useGeoLocationStorage();
  const lagoonData = useLagoon('null-search-page-common')();
  const IsCommonNullPage = searchMethod === 'typed';

  const requiredCoveragetype = IsCommonNullPage ? 'COMMON' : coverageType;

  const filteredLagoonData = lagoonData?.find(
    ({ coverageType: lagoonCoverageType }) =>
      lagoonCoverageType?.split(',').includes(requiredCoveragetype)
  );
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'null results',
    sitesectionLevel1: 'search results',
    sitesectionLevel2: 'category',
  });

  useEffect(() => {
    adobePageTrackEvent();

    const method = searchMethod ?? 'guided';
    const term = method === 'guided' ? '' : searchTerm || search;
    adobeStandardSearchEvent({
      term,
      type: convertProviderTypeToAdobeType(providerType),
      linkName: originLinkNameForAnalytics,
      method,
      numberOfResults: 0,
      customAttributesBlock: {
        providerType: '',
        searchLocation: getSearchLocation(storedLocation),
      },
    });
  }, []);

  const { ALL, BEHAVIORAL_HEALTH, VISION, DENTAL } = Constants.CHIPS_CATEGORIES;
  const getSelectedChipValue = () => {
    if (coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'])
      return BEHAVIORAL_HEALTH;
    if (coverageType === ReverseCoverageTypesCodes.VISION) return VISION;
    if (coverageType === ReverseCoverageTypesCodes.DENTAL) return DENTAL;

    return ALL;
  };

  return (
    <NullPageContainerStyled>
      <NoResult
        data-auto-testid="null-page-abyss-box-root"
        data-testid="null-page-abyss-box-root"
        lagoonData={filteredLagoonData}
        searchTitle={searchTitle}
        specialtySearch={specialtySearch}
      />
      {specialtyCode === SUGGESTED_PROVIDER ? <SuggestedProviders /> : null}
      {specialtyCode === SUGGESTED_PROVIDER && emptyResults ? (
        <WhereToGetHelpContainer>
          <SuggestedProviders />
        </WhereToGetHelpContainer>
      ) : null}
      {!IsCommonNullPage ? (
        <TopRecommendedStyled>
          <TopRecommendedProvider
            coverageType={coverageType}
            heading={filteredLagoonData?.topProviderHeading}
          />
        </TopRecommendedStyled>
      ) : null}
      <CommonlySearchedStyled>
        <CommonlySearchedCard
          onCommonSearchChipClicked={onCommonSearchChipClicked}
          selectedChipValue={getSelectedChipValue()}
        />
      </CommonlySearchedStyled>
      {coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] ? (
        <WhereToGetHelpContainer>
          <WhereToGetHelp selectedChipValue="BEHAVIORAL HEALTH" />
        </WhereToGetHelpContainer>
      ) : null}
    </NullPageContainerStyled>
  );
};
