import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import { CardWrapperDetailsPage } from '../../../../common/CardWrapper';
import {
  AdobeEventMethods,
  Constants,
  ProviderTypes,
  ReverseCoverageTypesCodes,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DetailsPageHeader } from '../../../../common/DetailsPageHeader/DetailsPageHeader';
import { LoadingSpinnerComp } from '../../../../common/Loader';
import { ProviderDetailTabs } from '../../../../common/ProviderDetailTabs/ProviderDetailTabs';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { SendFeedback } from '../../../../common/SendFeedback/SendFeedback';
import { getFeatureFlag } from '../../../../common/Utils';
import {
  getSearchLocation,
  internalConvertProviderTypeToAdobeType,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useInitializeStore } from '../../../../hooks/useInitializeStore';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useProviderDetails } from '../../../../hooks/useProviderDetails';
import { ProviderType } from '../../../../models/ProviderDetails';
import { useAnalyticsStore } from '../../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import {
  getNetworkStatus,
  getSelectedLocationIndex,
} from '../../../../utils/providerDetails.utils';
import {
  getCoverageTypes,
  getCurrentMember,
} from '../../../../utils/user.utils';
import { FacilityBanner } from './FacilityBanner';
import { toShowEmpirePolicyHospitalBanner } from './FacilityDetailsUtil';

export const FacilityDetails = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const allConfigs = useLagoon('config')();
  const featureInclusion = useLagoon('feature-inclusion')();
  const { coverageType, chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      chipValue: state.chipValue,
    }))
  );
  const LOCATIONS_TAB_INDEX = 0;
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();

  const enableSendFeedback = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SUGGEST_AN_EDIT_FORM
  );
  const enableNewFeedbackFormDesign = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_NEW_FEEDBACK_FORM_DESIGN
  );
  const { searchTerm, searchFromTypeAhead } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      searchTerm: state.typeaheadSearchStore.searchTerm,
      searchFromTypeAhead: state.typeaheadSearchStore.searchFromTypeAhead,
    }))
  );

  const {
    avatar = 'clinic',
    showDirection,
    providerId: storeProviderId,
    locationId: storeLocationId,
  } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      avatar: state.detailsState.avatar,
      showDirection: state.detailsState.showDirection,
      providerId: state.detailsState.providerId,
      locationId: state.detailsState.locationId,
    }))
  );

  const { linkName } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
    }))
  );

  const { providerId, locationId } = useInitializeStore(
    ConstantsRoutes.FACILITY_DETAILS.key
  );

  const { data, isLoading } = useProviderDetails({
    providerId,
    providerType: ProviderType.ORGANIZATION,
    locationId,
  });
  const storedLocation = getGeoLocationFromStorage();

  const [navigateToDirections, setNavigateToDirections] = useState(
    Boolean(showDirection)
  );
  const [currentTabTile, setCurrentTabTitle] = useState<string>(
    Constants.PROVIDER_DETAILS.LOCATIONS
  );
  const [isMapView, setIsMapView] = useState<boolean>(false);
  const [, setDirectionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );
  useEffect(() => {
    if (
      data?.facilityDetails?.providerDetails?.providerName &&
      searchFromTypeAhead
    ) {
      adobeStandardSearchEvent({
        linkName,
        term: searchTerm,
        numberOfResults: 1,
        method: AdobeEventMethods.Predictive,
        customAttributesBlock: {
          searchLocation: getSearchLocation(storedLocation),
          providerType: internalConvertProviderTypeToAdobeType(
            ProviderTypes.FACILITY
          ),
        },
      });
    }
  }, [
    data?.facilityDetails?.providerDetails?.providerName,
    searchFromTypeAhead,
  ]);

  useEffect(() => {
    if (showDirection) {
      setDirectionsTriggeredFrom('');
      setNavigateToDirections(true);
    }
  }, [showDirection]);

  const facilityDetails = data?.facilityDetails?.providerDetails || {};
  const providerLocations = facilityDetails?.providerLocations || [];
  const { organizationCode } = facilityDetails;
  const currentMember = getCurrentMember();
  const { policyNumber } = currentMember;
  const hasMedicalCoverage = getCoverageTypes(currentMember)?.includes(
    ReverseCoverageTypesCodes.MEDICAL
  );
  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const selectedLocationIndex = getSelectedLocationIndex(
    providerLocations,
    selectedId
  );
  const selectedLocation = providerLocations?.[selectedLocationIndex];

  const networkStatus = getNetworkStatus(selectedLocation?.network?.status);

  const [activeTab, setActiveTab] = useState(LOCATIONS_TAB_INDEX);

  useEffect(() => {
    if (navigateToDirections) {
      setActiveTab(LOCATIONS_TAB_INDEX);
    }
  }, [navigateToDirections]);

  const setHandleTabChange = (index) => {
    if (index !== LOCATIONS_TAB_INDEX) {
      setNavigateToDirections(false);
    }
    adobeLinkTrackEvent({
      name: Object.values(Constants.FACILITY_DETAILS.TABS_LIST)[index],
      location: `body:details tabs`,
      type: 'tab',
    });
    setActiveTab(index);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingSpinnerComp isLoading={isLoading} />
      ) : (
        <React.Fragment>
          {currentTabTile === Constants.FACILITY_DETAILS.LOCATIONS &&
          toShowEmpirePolicyHospitalBanner({
            policyNumber,
            hasMedicalCoverage,
            allConfigs,
            featureInclusion,
            organizationCode,
          }) ? (
            <FacilityBanner />
          ) : null}
          {!(mobileScreen && isMapView) ? (
            <CardWrapperDetailsPage backgroundColor="#FBFCFE">
              <DetailsPageHeader
                avatar={avatar}
                coverageType={coverageType}
                enableNewFeedbackFormDesign={enableNewFeedbackFormDesign}
                enableSendFeedback={enableSendFeedback}
                feedbackFormType={
                  Constants.FEEDBACK_FORM_TYPES.FACILITY_DETAILS
                }
                formProviderType={facilityDetails?.providerType}
                handleTabChange={setHandleTabChange}
                imageLocation={facilityDetails?.imageLocation}
                inNetwork={networkStatus}
                npi={facilityDetails?.npi}
                primarySpeciality={facilityDetails?.organizationType}
                professionalDesignation={undefined}
                providerId={providerId}
                providerLocations={providerLocations}
                providerName={facilityDetails?.providerName}
                providerType={ProviderType.ORGANIZATION}
                setNavigateToDirections={setNavigateToDirections}
              />
            </CardWrapperDetailsPage>
          ) : null}
          <CardWrapperDetailsPage>
            <ProviderDetailTabs
              active={activeTab}
              chipValue={chipValue}
              coverageType={coverageType}
              handleTabChange={(activeTab, title) => {
                adobeLinkTrackEvent({
                  name: title.toLowerCase(),
                  location: `body:details tabs`,
                  type: 'tab',
                });
                setCurrentTabTitle(title);
                setHandleTabChange(activeTab);
              }}
              isMapView={isMapView}
              navigateToDirections={navigateToDirections}
              providerDetails={facilityDetails}
              setIsMapView={setIsMapView}
              setNavigateToDirections={setNavigateToDirections}
            />
          </CardWrapperDetailsPage>
        </React.Fragment>
      )}
      {enableSendFeedback && !enableNewFeedbackFormDesign && (
        <SendFeedback
          coverageType={coverageType}
          data={{
            name: facilityDetails?.providerName,
            address: selectedLocation?.address,
            npi: facilityDetails?.npi,
            mpin: facilityDetails?.providerId,
            providerType: facilityDetails?.providerType,
          }}
          type={Constants.FEEDBACK_FORM_TYPES.FACILITY_DETAILS}
        />
      )}
    </React.Fragment>
  );
};
