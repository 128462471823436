import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import React from 'react';

import { CriteriaTextWrapper } from '../../SmartChoiceBreakDown.styles';

interface NotAMatchSearchIconAndTextProps {
  text: string;
  StartIcon?: React.ReactNode;
  EndIcon?: React.ReactNode;
}
export const NotAMatchSearchIconAndText = ({
  text,
  StartIcon,
  EndIcon,
}: NotAMatchSearchIconAndTextProps) => (
  <CriteriaTextWrapper data-testid="not-a-match-text-with-icon">
    <IconSymbol color="#4B4D4F" icon="search_off" />
    {StartIcon && <React.Fragment>{StartIcon}</React.Fragment>}
    {text && (
      <CriteriaTextWrapper>
        {text}
        {EndIcon && <React.Fragment>{EndIcon}</React.Fragment>}
      </CriteriaTextWrapper>
    )}
  </CriteriaTextWrapper>
);
