import { ProviderSummaryTypes, ReverseCoverageTypesCodes } from "../common/Constants";

export const getDistance = (dist: string) => {
  let numMiles;
  if (dist !== '') {
    numMiles = parseFloat(dist).toFixed(1);
  }
  return numMiles || 0;
};

export const getSpecialityTitle = (providerType: string | undefined, coverageType: string, providerSpeciality: string, t?: (key: string) => string): string => {
  if (providerType === ProviderSummaryTypes.ORGANIZATION && coverageType === ReverseCoverageTypesCodes["BEHAVIORAL HEALTH"]) {
    return t ? t('BEHAVIORAL_TREATMENT_CENTER') : '';
  }
  return providerSpeciality || '';
};