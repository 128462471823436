import { useCollapse } from '@abyss/web/hooks/useCollapse';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../../../common/Utils';
import { useLagoon } from '../../../../../hooks/useLagoon';
import {
  ChooseLocationHeading,
  ChooseLocationHeadingWrapper,
  CollapseIcon,
  CollapseIconContainer,
  DemoUpdatePCPErrorModalContainer,
} from './ChoosePCPLocation.styled';
import { UpdateErrorModalSimulation } from './UpdateErrorModalSimulation';

type Props = {
  formattedProviderName: string;
  hasMultipleLocations: boolean;
  isMobileMapView: boolean;
  handleShowErrorModalSimulationSubmit: (
    errorCode: string,
    errorMessage?: string,
    populationType?: string
  ) => void;
  handleShowErrorModalSimulationReset: () => void;
};

export const ChoosePCPLocationHeading = ({
  formattedProviderName,
  hasMultipleLocations,
  isMobileMapView,
  handleShowErrorModalSimulationSubmit,
  handleShowErrorModalSimulationReset,
}: Props) => {
  const { t } = useTranslation();
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const isDemoFeatureEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DEMO_FLAG
  );

  const {
    collapseProps,
    buttonProps,
    isOpen: isOpenCollapse,
  } = useCollapse({ defaultIsOpen: false });
  const { 'aria-expanded': ariaExpanded, ...onClickButtonProp } = buttonProps;

  return !isMobileMapView ? (
    <ChooseLocationHeadingWrapper
      css={{
        position: 'sticky',
        top: '0',
        zIndex: '999',
        backgroundColor: 'white',
      }}
      data-auto-testid="choose-location-heading"
      data-testid="choose-location-heading"
    >
      {hasMultipleLocations ? (
        <React.Fragment>
          <ChooseLocationHeading display="h3" offset={0}>
            {t('CHOOSE_PCP_FLOW.MULTIPLE_LOCATIONS_HEADING')}
          </ChooseLocationHeading>
          <Text color="#4B4D4F" fontWeight="$medium" size={18}>
            {formattedProviderName}{' '}
            {t('CHOOSE_PCP_FLOW.MULTIPLE_LOCATIONS_SUBHEADING')}
          </Text>
        </React.Fragment>
      ) : (
        <ChooseLocationHeading display="h3" offset={0}>
          {t('CHOOSE_PCP_FLOW.VERIFY_LOCATION')}
        </ChooseLocationHeading>
      )}
      {isDemoFeatureEnabled ? (
        <div>
          <CollapseIconContainer>
            <CollapseIcon
              data-testid={
                isOpenCollapse ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
              }
              {...onClickButtonProp}
              color="$primary1"
              icon={
                isOpenCollapse ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
              }
            />
          </CollapseIconContainer>
          {isOpenCollapse ? (
            <div {...collapseProps}>
              <DemoUpdatePCPErrorModalContainer>
                <UpdateErrorModalSimulation
                  handleOnReset={handleShowErrorModalSimulationReset}
                  handleOnSubmit={handleShowErrorModalSimulationSubmit}
                />
              </DemoUpdatePCPErrorModalContainer>
            </div>
          ) : null}
        </div>
      ) : null}
    </ChooseLocationHeadingWrapper>
  ) : null;
};
