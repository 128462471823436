import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { ProviderType } from '../../models/ProviderDetails';
import { Constants } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import {
  ProviderRatingStar,
  ProviderRatingText,
  ProviderRatingsText,
} from './DetailsPageHeader.styled';

type Props = {
  rating?: string;
  providerType?: ProviderType;
  surveyCount?: number;
  color?: string;
  hideNumber?: boolean;
};

export const ProviderDetailsRating = ({
  hideNumber,
  rating,
  providerType,
  surveyCount,
  color = '$primary1',
}: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery<boolean>(phoneOnly) || isWidget;
  const { t } = useTranslation();
  const surveyRating = surveyCount ? ` (${surveyCount})` : '';

  const ratingWithCount = rating
    ? `${rating}${surveyRating}`
    : t(Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA);

  const ratingText = rating
    ? rating
    : t(Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA);

  const RatingsText = () => (
    <React.Fragment>
      {Constants.RATE_LIMITING.map((param) => (
        <ProviderRatingStar
          aria-hidden="true"
          className="star-icon"
          color="$secondary2"
          icon={StartIcons(param)}
          key={`rating${param}`}
          size={ratingSize}
        />
      ))}
      <ProviderRatingsText
        aria-hidden="true"
        className="text"
        color={color}
        data-auto-testid="provider-details-rating"
        data-testid="provider-details-rating"
        size={ratingSize}
      >
        {providerType == ProviderType.PRACTITIONER ? ratingText : null}
      </ProviderRatingsText>
    </React.Fragment>
  );
  const RatingText = () => (
    <React.Fragment>
      <ProviderRatingStar
        aria-hidden="true"
        className="star-icon"
        color="$secondary2"
        icon={StartIcons(1)}
        key={`rating${1}`}
        size={ratingSize}
      />
      <ProviderRatingText
        aria-hidden="true"
        className="text"
        color={color}
        data-auto-testid="provider-details-rating"
        data-testid="provider-details-rating"
        size={ratingSize}
      >
        {providerType == ProviderType.PRACTITIONER ? ratingWithCount : null}
      </ProviderRatingText>
    </React.Fragment>
  );

  const ratingSize = mobileScreen ? '$sm' : '$md';

  const StartIcons = (param) =>
    param < parseInt(rating || '0', 10) ? 'star' : 'star_border';

  if (mobileScreen) {
    return (
      <Flex
        css={{
          flexWrap: 'nowrap',
        }}
        data-testid="provider-deatials-header-rating-container"
        direction="row"
        justify="flex-end"
      >
        {hideNumber ? <RatingText /> : <RatingsText />}
      </Flex>
    );
  }

  return <Flex>{hideNumber ? <RatingText /> : <RatingsText />}</Flex>;
};
