/* eslint-disable no-nested-ternary */

import { TextInput } from '@abyss/web/ui/TextInput';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {
  Constants,
  NetworkStatus,
  ProviderTypes,
} from '../../../../../common/Constants';
import { getDistance } from '../../../../../common/Utils/dataCardUtils';
import { isDistanceAttributeEmpty } from '../CompareDrawer/Components/CompareCard/CompareExpandedCard/Attributes/Location/Distance';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../CompareDrawer/utility/compareDrawerConstants';
import {
  getDegreesList,
  getSchoolsList,
} from '../CompareDrawer/utility/getEducationText';
import { getHoursOfOperaion } from '../CompareDrawer/utility/getHoursOfOperation';
import { EmailInputStyles } from './ShareDrawerContents/ShareDrawerContents.styled';

export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\"[^\"]+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const sectionTypeToProviderType = (sectionType) => {
  if (
    sectionType?.toLowerCase() ===
    Constants.RESULT_SECTION.PROVIDER.toLowerCase()
  ) {
    return ProviderTypes.PRACTITIONER;
  }
  if (
    sectionType?.toLowerCase() ===
    Constants.RESULT_SECTION.FACILITY.toLowerCase()
  ) {
    return ProviderTypes.ORGANIZATION;
  }
  if (
    sectionType?.toLowerCase() ===
    Constants.RESULT_SECTION.PROVIDER_GROUPS.toLowerCase()
  ) {
    return ProviderTypes.MEDICAL_GROUP;
  }

  return sectionType;
};

export const emailInput = (onChange, value, label, errorMessage, onBlur) => (
  <TextInput
    css={EmailInputStyles}
    data-auto-testid="email-id"
    data-testid="email-id"
    label={label}
    model={label}
    onBlur={() => {
      onBlur(label);
    }}
    onChange={(e) => {
      onChange(e.target.value);
    }}
    validators={{
      required: label === 'Email Address 1',
      pattern: {
        value: emailRegex,
        message: errorMessage,
      },
    }}
  />
);

export const convertBase64PdfStringToFile = (pdfBase64String, shareTitle) => {
  // decode base64 string, remove space for IE compatibility
  const binary = Buffer.from(
    pdfBase64String.replace(/\s/g, ''),
    'base64'
  ).toString('binary');
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  // create the file object with content-type "application/pdf"
  return new File([view], `${shareTitle}.pdf`, { type: 'application/pdf' });
};

export const getProviderGender = (gender: string | undefined): string =>
  gender
    ? gender.charAt(0).toUpperCase().concat(gender.slice(1))
    : `${NULL_ATTRIBUTE_STATE.GENDER}`;

export const getProviderSpecialty = (speciality) =>
  speciality || `${NULL_ATTRIBUTE_STATE.SPECIALTY}`;

export const getProviderLanguages = (languagesSpoken: string[]) => {
  const formattedLanguage =
    languagesSpoken?.length > 0 && languagesSpoken.filter(Boolean).join(', ');
  return formattedLanguage || `${NULL_ATTRIBUTE_STATE.LANGUAGES_SPOKEN}`;
};

export const getNetworkStatus = (networkStatus: string) =>
  networkStatus ? NetworkStatus.INN : NetworkStatus.OON;

export const getStatusText = (
  status: boolean | undefined | null,
  trueText: string,
  falseText: string,
  nullText: string
) => {
  if (isNil(status)) {
    return nullText;
  }
  return status ? t(trueText) : t(falseText);
};

export const hideFeedbackContainer = (hidden: boolean) => {
  if (document.getElementById('QSIFeedbackButton-target-container'))
    (
      document.getElementById(
        'QSIFeedbackButton-target-container'
      ) as HTMLElement
    ).hidden = hidden;
};

export const adobeShareDrawerLinkLocation = 'share drawer';
export const adobeShareProviders = 'share providers';
export const getEmailBodyTextHelper = ({
  shareTitle,
  isOriginCompareProviders,
  shareTitleForCompare,
  selectedProviderList,
  getIsChecked,
}) => {
  let emailBodyText = `${shareTitle}\n`;
  if (isOriginCompareProviders) {
    emailBodyText = `${shareTitleForCompare}\n`;
  }
  selectedProviderList.forEach((provider) => {
    const phone: string = isEmpty(provider.phones?.phone[0])
      ? NULL_ATTRIBUTE_STATE.PHONE_NUMBER
      : provider.phones?.phone[0];
    const networkStatus: string = getNetworkStatus(provider.networkStatus);
    const gender: string = getProviderGender(provider?.gender);
    const formattedAddress: string = `${provider?.address?.line?.[0]}, ${provider?.address?.city}, ${provider?.address?.state}`;
    const address: string = isEmpty(provider?.address?.line?.[0])
      ? NULL_ATTRIBUTE_STATE.ADDRESS
      : formattedAddress;

    const distance: string = isDistanceAttributeEmpty(provider.distance)
      ? NULL_ATTRIBUTE_STATE.DISTANCE
      : `${getDistance(provider.distance)} miles away`;

    const tierOneProvider: string = getStatusText(
      provider.isTieredProvider,
      ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.TRUE,
      ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.FALSE,
      NULL_ATTRIBUTE_STATE.TIER_ONE_PROVIDER
    );
    const educationList = getSchoolsList(provider.educationInfo);
    const degreeList = getDegreesList(provider.educationInfo);
    const speciality: string = getProviderSpecialty(provider.speciality);
    const languages = getProviderLanguages(provider?.languagesSpoken);
    const acceptingPatient: string = getStatusText(
      provider.acceptingNewPatients,
      ATTRIBUTE_STATUS_TEXT.ACCEPTING_NEW_PATIENTS.TRUE,
      ATTRIBUTE_STATUS_TEXT.ACCEPTING_NEW_PATIENTS.FALSE,
      NULL_ATTRIBUTE_STATE.ACCEPTING_NEW_PATIENTS
    );
    const virtualCare: string = getStatusText(
      provider.virtualCareOffered,
      ATTRIBUTE_STATUS_TEXT.VIRTUAL_CARE_OFFERED.TRUE,
      ATTRIBUTE_STATUS_TEXT.VIRTUAL_CARE_OFFERED.FALSE,
      NULL_ATTRIBUTE_STATE.VIRTUAL_CARE_OFFERED
    );

    const { daysWithTime, possibleHours } = getHoursOfOperaion(
      provider.hoursOfOperation
    );
    const hoursOfOperation = isEmpty(provider.hoursOfOperation)
      ? NULL_ATTRIBUTE_STATE.HOURS_OF_OPERATION
      : `${daysWithTime} : ${possibleHours}`;

    const premiumCareProvider = getStatusText(
      provider.premiumCareProvider,
      ATTRIBUTE_STATUS_TEXT.PREMIUM_CARE_PROVIDER.TRUE,
      ATTRIBUTE_STATUS_TEXT.PREMIUM_CARE_PROVIDER.FALSE,
      NULL_ATTRIBUTE_STATE.PREMIUM_CARE_PROVIDER
    );

    const hasPCPServiceAvailability = getStatusText(
      provider.hasPCPServiceAvailability,
      ATTRIBUTE_STATUS_TEXT.PCP_SERVICES_AVAILABILE.TRUE,
      ATTRIBUTE_STATUS_TEXT.PCP_SERVICES_AVAILABILE.FALSE,
      NULL_ATTRIBUTE_STATE.PCP_SERVICES_AVAILABILE
    );

    emailBodyText += `\n${
      provider.providerName
    }\n${phone}\n${networkStatus}${getIsChecked(
      'specialties',
      `\n${speciality}`
    )}${getIsChecked('reviews', `\n${'Rating - 4'}`)}${getIsChecked(
      'distance',
      `\n${address}\n${distance}`
    )}${getIsChecked('tierOneProvider', `\n${tierOneProvider}`)}${getIsChecked(
      'education',
      `\n${educationList}`
    )}${getIsChecked('education', `\n${degreeList}`)}${getIsChecked(
      'gender',
      `\n${gender}`
    )}${getIsChecked(
      'acceptingNewPatients',
      `\n${acceptingPatient}`
    )}${getIsChecked('languagesSpoken', `\n${languages}`)}${getIsChecked(
      'virtualCareOffered',
      `\n${virtualCare}`
    )}${getIsChecked(
      'hoursOfOperation',
      `\n${hoursOfOperation}`
    )}${getIsChecked(
      'premiumCareProvider',
      `\n${premiumCareProvider}`
    )}${getIsChecked(
      'hasPCPServiceAvailability',
      `\n${hasPCPServiceAvailability}`
    )}
      `;
  });

  emailBodyText += `\n[${window.location.href}]`;
  return emailBodyText;
};

export const getEmailBodyHTMLHelper = ({
  isOriginCompareProviders,
  selectedProviderList,
  shareTitle,
  shareTitleForCompare,
  getIsChecked,
}) => {
  let emailBodyHtml = `<div><div><b>
  ${isOriginCompareProviders ? shareTitleForCompare : shareTitle}</b>
  </div>`;
  selectedProviderList.forEach((provider) => {
    const phone: string = isEmpty(provider.phones?.phone[0])
      ? NULL_ATTRIBUTE_STATE.PHONE_NUMBER
      : provider.phones?.phone[0];
    const gender: string = getProviderGender(provider.gender);
    const address: string = `${provider?.address?.line?.[0]}, ${provider?.address?.city}, ${provider?.address?.state}`;
    const speciality: string = getProviderSpecialty(provider.speciality);
    const languages = getProviderLanguages(provider?.languagesSpoken);
    const acceptingPatient: string = getStatusText(
      provider.acceptingNewPatients,
      'Accepting new patients',
      'Not accepting new patients',
      NULL_ATTRIBUTE_STATE.ACCEPTING_NEW_PATIENTS
    );
    const virtualCare: string = getStatusText(
      provider.virtualCareOffered,
      'Virtual care offered',
      'Virtual care not offered',
      NULL_ATTRIBUTE_STATE.VIRTUAL_CARE_OFFERED
    );

    const networkStatus: string = getNetworkStatus(provider.networkStatus);

    emailBodyHtml += `<div style="margin-top:15px"><b>${
      provider.providerName
    }</b></div>
    <div>${phone}</div>
    <div>${networkStatus}</div>
    ${getIsChecked('specialties', `<div>${speciality}</div>`)}
    ${getIsChecked('reviews', `<div>${'Rating - 4'}</div>`)}
    ${getIsChecked(
      'distance',
      `<div>${address}\n${getDistance(provider.distance)} ${'miles away'}</div>`
    )}
    ${getIsChecked('gender', `<div>${gender}</div>`)}
    ${getIsChecked('languagesSpoken', `<div>${languages}</div>`)}
    ${getIsChecked('acceptingNewPatients', ` <div>${acceptingPatient}</div>`)}
    ${getIsChecked('virtualCareOffered', `<div>${virtualCare}</div>`)}
    ${
      // Cost to be included near future
      getIsChecked('hoursOfOperation', `<div>${'TBD'}</div>`)
    }
<div style="margin-top:10px"></div>
</div>`;
  });
  emailBodyHtml += `<div>[${window.location.href}]</div>`;
  return emailBodyHtml;
};
