import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';

export const BreakdownContainer = styled('div', {
  padding: '2px',
  fontFamily: '$primary !important',
  '@screen < $sm': {
    padding: '0px 0px 20px 0px',
  },
});

export const StyledDrawer = styled(Drawer, {
  zIndex: '999 !important',
  '.abyss-modal-header-container': {
    width: 'fit-content',
  },
  '.abyss-modal-content-container': {
    borderRadius: 0,
    '@screen < $sm': {
      top: '25px',
      height: 'calc(100vh - 26px)',
      width: '100%',
      borderRadius: '20px 20px 0px 0px',
      color: '$primary1',
      border: '1px solid $primary1',
    },
  },
  '.abyss-modal-close-button': {
    '@screen < $sm': {
      top: '12px',
      right: '12px',
    },
  },
  '.abyss-modal-body': {
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const DrawerContentWithBackgroundgray = styled(Flex, {
  backgroundColor: '#F3F3F3',
  padding: '8px 16px 8px 16px',
});
export const CriteriaTitle = styled(Text, {
  fontFamily: '$primary',
  fontWeight: '500',
  fontSize: '14px !important',
  lineHeight: '18px !important',
  letterSpacing: '0',
  marginBottom: '0px !important',
  color: '#000000 !important',
});
export const CriteriaDescription = styled(Text, {
  fontFamily: '$primary',
  fontWeight: 500,
  fontSize: '11px !important',
  lineHeight: '14px !important',
  letterSpacing: '0',
  marginBottom: '0px !important',
  color: '#4B4D4F !important',
});
export const DescriptionContainer = styled('div', {
  marginTop: '24px',
  padding: '0px 16px 0px 16px',
});
export const StyledDescription = styled(Text, {
  fontFamily: '$primary !important',
  fontWeight: '400 !important',
  fontSize: '16px !important',
  lineHeight: '20px !important',
  letterSpacing: '0%',
  color: '#323334 !important',
});

export const CriteriaPointsContainer = styled(Flex, {
  margin: '24px 0 0 0',
  padding: '8px 16px 8px 16px',
  gap: '8px',
});

export const CriteriaTextWrapper = styled('span', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '10px',
});
export const ContainerWithTopandBottonBorder = styled(Flex, {
  borderBottom: '0.5px solid #CBCCCD',
  borderTop: '0.5px solid #CBCCCD',
  padding: '8px 16px 8px 16px',
  gap: '8px',
});

export const FAQContainer = styled('div', {
  padding: '8px 16px 8px 16px',
  marginTop: '24px',
});

export const FAQLink = styled(Link, {
  fontSize: '14px !important',
  fontWeight: '500 !important',
  lineHeight: '18px !important',
  letterSpacing: '0 !important',
  color: '#196ECF !important',
  verticalAlign: 'middle !important',
  '&::before': {
    borderBottom: 'none !important',
  },
});

export const MatchStyledText = styled(Text, {
  marginBottom: '0px !important',
  fontFamily: '$primary !important',
  fontWeight: '400 !important',
  fontSize: '14px !important',
  lineHeight: '20px !important',
  letterSpacing: '0',
  color: '#323334 !important',
  display: 'flex !important',
});

export const CriteriaSubTitle = styled(Text, {
  fontFamily: '$primary !important',
  fontWeight: '700 !important',
  fontSize: '16px !important',
  lineHeight: '20px !important',
  letterSpacing: '0',
  color: '#323334 !important',
  marginBottom: '0px !important',
  verticalAlign: 'middle',
  marginTop: '20px',
});

export const StyledFlexWithGapAndNoWrap = styled(Flex, {
  gap: '10px',
  flexWrap: 'nowrap',
});
