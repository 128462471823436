import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerMenuButton } from '../../../DrawerMenuButton';
import { PrintButton } from '../../../PrintButton';
import { ShareButton } from '../../../ShareButton';
import { adobeCompareDrawerLinkLocation } from '../../utility/compareDrawerConstants';
import { EditCompareButton } from '../EditCompare/EditCompareButton';

export const CompareDrawerHeaderStyle = styled('div', {
  variants: {
    view: {
      mobile: {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      desktop: {
        paddingLeft: '30px',
        paddingRight: '30px',
      },
    },
  },
});
type Props = {
  handleOnClickCarret: any;
  handleFocusIn: any;
  handleTabOutOfSelection: any;
  isExpandedCardOpen: boolean;
  caretRef: React.RefObject<HTMLButtonElement>;
  selectedCount: number;
  total: number;
  handleClickShare: any;
  modal: any;
  isEditCompareOpen: boolean;
  setIsEditCompareOpen: any;
  setNameOfButtonClickedLast: any;
  deselectAllButtonRef: React.RefObject<HTMLButtonElement>;
};

export const CompareDrawerHeader = ({
  handleOnClickCarret,
  handleFocusIn,
  handleTabOutOfSelection,
  isExpandedCardOpen,
  caretRef,
  selectedCount,
  total,
  handleClickShare,
  modal,
  isEditCompareOpen,
  setIsEditCompareOpen,
  setNameOfButtonClickedLast,
  deselectAllButtonRef,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isEditCompareOpen) {
      deselectAllButtonRef?.current?.focus();
    } else {
      caretRef?.current?.focus();
    }
  }, [isEditCompareOpen]);

  useEffect(() => {
    if (isExpandedCardOpen) {
      caretRef?.current?.focus();
    }
  }, [isExpandedCardOpen]);

  return (
    <CompareDrawerHeaderStyle view="desktop">
      <Box
        css={{
          'abyss-box-root': {
            backgroundColor: 'transparent',
            padding: '0px',
          },
        }}
      >
        <Flex
          alignItems="flex-start"
          css={{
            'abyss-flex-root': {
              marginTop: '26px',
            },
          }}
          justify="space-between"
        >
          {/* <Layout.Stack css={{ paddingTop: '4px' }}> */}
          <Layout.Group
            data-auto-testid="selection-show-mini-cards"
            data-testid="selection-show-mini-cards"
            onFocus={() => handleFocusIn()}
            onKeyDown={(e) => handleTabOutOfSelection(e)}
          >
            <Heading color="$gray8" display="h6" offset={1}>
              Compare Providers
            </Heading>
            <Text color="$gray7">
              ({selectedCount} {t('of')} {total} {t('SELECTED')})
            </Text>
            <Button
              after={
                <IconMaterial
                  data-auto-testid="compare-providers-icon-button-to-expand-and-close-compare-drawer"
                  data-testid="compare-providers-icon-button-to-expand-and-close-compare-drawer"
                  icon={
                    isExpandedCardOpen
                      ? 'keyboard_arrow_up'
                      : 'keyboard_arrow_down'
                  }
                  size="$md"
                />
              }
              aria-expanded={Boolean(isExpandedCardOpen)}
              css={{ fontSize: 14, fontWeight: 600 }}
              data-auto-testid="compare-providers-button-to-expand-and-close-compare-drawer"
              data-testid="compare-providers-button-to-expand-and-close-compare-drawer"
              onClick={handleOnClickCarret}
              ref={caretRef}
              size="$sm"
              tabIndex={0}
              variant="tertiary"
            >
              {isExpandedCardOpen ? 'Hide details' : 'Show details'}
            </Button>
          </Layout.Group>
          {/* </Layout.Stack> */}
          <Layout.Group alignItems="top">
            {isExpandedCardOpen && (
              <div>
                <PrintButton
                  locationForAnalytics={`modal:${adobeCompareDrawerLinkLocation}`}
                  view="desktop"
                />
              </div>
            )}

            {isExpandedCardOpen && (
              <div>
                <ShareButton
                  data-auto-testid="compare-drawer-share-button"
                  data-testid="compare-drawer-share-button"
                  onClick={() => handleClickShare()}
                  view="none"
                />
              </div>
            )}
            <div style={{ margin: '0px 4px 4px 4px' }}>
              <DrawerMenuButton
                ariaLabel="Close"
                data-auto-testid="mapview-compare-drawer-menu"
                data-testid="mapview-compare-drawer-menu"
                icon="close"
                locationForAnalytics="modal:compare drawer"
                onClick={() => modal?.open()}
                view="desktop"
              />
            </div>
          </Layout.Group>
        </Flex>
        {isExpandedCardOpen && (
          <EditCompareButton
            caretRef={caretRef}
            isEditCompareOpen={isEditCompareOpen}
            setIsEditCompareOpen={setIsEditCompareOpen}
            setNameOfButtonClickedLast={setNameOfButtonClickedLast}
          />
        )}
      </Box>
    </CompareDrawerHeaderStyle>
  );
};
