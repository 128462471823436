import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import React from 'react';

import {
  MatchStyledText,
  StyledFlexWithGapAndNoWrap,
} from '../../SmartChoiceBreakDown.styles';
import { StyledLinkViewMore } from '../ConvenienceBreakDown.styles';

interface MatchTextWithViewMoreProps {
  text: string;
}
export const MatchTextWithViewMore = ({ text }: MatchTextWithViewMoreProps) => (
  <StyledFlexWithGapAndNoWrap alignItems="flex-start" direction="row">
    <IconSymbol color="#007000" icon="done" />
    <StyledFlexWithGapAndNoWrap alignItems="flex-start" direction="column">
      <MatchStyledText>{text}</MatchStyledText>
      <StyledLinkViewMore
        data-testid="locations-link"
        href="/locations"
      >{`Locations > `}</StyledLinkViewMore>
    </StyledFlexWithGapAndNoWrap>
  </StyledFlexWithGapAndNoWrap>
);
