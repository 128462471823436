import { ReactNode } from 'react';

import { CssProps } from './CssProps';

export enum CardHeaderOffset {
  Header = 3,
  SubHeader = 5,
}

export type HeaderProps = {
  'data-testid'?: string;
  color?: string;
  children: ReactNode;
  css?: CssProps;
  offset?: CardHeaderOffset;
  id?: string;
};
