import { config } from '@abyss/web/tools/config';
import { storage } from '@abyss/web/tools/storage';

import { SessionCookie, emptyCookie } from '../models/SessionCookie';

export const SESSION_COOKIE: string = 'FH_MU';

export const parseSessionCookie = (cookie: string): SessionCookie => {
  const cookieData = cookie?.split('!');

  if (cookieData?.length === 3) {
    return {
      sessionId: cookieData[0],
      lastAccessedTime: parseInt(cookieData[1], 10),
      sessionDuration: parseInt(cookieData[2], 10),
    };
  }

  return emptyCookie;
};

export const isSessionValid = (cookie: SessionCookie): boolean => {
  const { lastAccessedTime, sessionDuration } = cookie;

  if (lastAccessedTime && sessionDuration) {
    const accessTimeMillis = lastAccessedTime * 1000;
    const durationMillis = sessionDuration * 1000;

    if (new Date().getTime() < accessTimeMillis + durationMillis) {
      return true;
    }
  }
  return false;
};

export const loginNotRequired = (): boolean => {
  const hostname = window?.location?.hostname?.toLowerCase();
  return hostname === 'localhost' || hostname?.startsWith('findcare');
};

export const stageEnv = (): boolean => {
  const hostname = window?.location?.hostname?.toLowerCase();
  return  hostname?.startsWith('findcare-stage');
};

export const logout = (setSessionId) => {
  setSessionId('');
  window.location.assign(config('UHG_LOGOUT_URL'));
};

export const validateSession = (sessionId, setSessionId) => {
  const sessionCookie = storage.cookie.get(SESSION_COOKIE);

  if (sessionCookie) {
    const cookie = parseSessionCookie(sessionCookie);

    if (isSessionValid(cookie)) {
      if (!sessionId) {
        setSessionId(cookie.sessionId);
      }
    } else {
      logout(setSessionId);
    }
  } else if (loginNotRequired()) {
    if (sessionId) {
      logout(setSessionId);
    }
  } else {
    logout(setSessionId);
  }
};
