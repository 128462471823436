import { Flex } from '@abyss/web/ui/Flex';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import { find } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../../../hooks/useLagoon';
import { getYesNoNAText } from '../../../../utils/providerDetails.utils';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { ViewMoreButton } from '../ProviderLocation.style';
import {
  SpecialtyOfferedByFacilityModal,
  specialtiesOffered,
  specialtiesOfferedTitle,
} from './SpecialtyOfferedByFacility.styled';

export const SpecialtiesOfferedByFacility = ({
  notAvailableOrUnknownText,
  facilityProviderSpecialties,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const handleViewMore = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);
  const uiMessaging = useLagoon('ui-messaging')();
  const specialtiesOfferedByFacilitiesModal = find(uiMessaging, {
    key: ConstantsLagoon.FACILITY_LOCATIONS.SPECIALTIES_OFFERED_BY_FACILITY,
  });
  const getSpecialties = (facilities) => {
    const allowedKeys = [
      'hasAnesthesiology',
      'hasEmergencyMedicine',
      'hasPathology',
      'hasRadiology',
      'hasNeonatology',
      'hasHospitalist',
      'hasIntensivist',
    ]; // Define the required keys

    return Object.entries(facilities)
      .filter(([key]) => allowedKeys.includes(key))
      .map(([key, value]) => ({
        key: key
          .replace(/has/, '')
          .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
          .toLowerCase(),
        hasSpecialty: value,
      }))
      .sort((a, b) => a.key.localeCompare(b.key));
  };
  const specialties = getSpecialties(facilityProviderSpecialties);

  return (
    <div>
      {specialties.slice(0, 3).map((specialty, index) => (
        <Flex
          css={{ gap: '4px' }}
          data-testid={`sub-heading-speciality-${specialty.key.replace(
            /_/g,
            '-'
          )}`}
          key={index}
        >
          <Text css={specialtiesOfferedTitle}>
            {t(`PROVIDER_DETAILS.LOCATIONS_TAB.${specialty.key.toUpperCase()}`)}{' '}
            -{' '}
          </Text>
          <Text css={specialtiesOffered}>
            {getYesNoNAText(t, specialty.hasSpecialty as boolean) ||
              notAvailableOrUnknownText}
          </Text>
        </Flex>
      ))}
      {specialties.length > 3 && (
        <ViewMoreButton
          data-testid="view-more-specialty-offered"
          onClick={handleViewMore}
        >
          <Text color="$info1" size="$md">
            {`+${specialties.length - 3}  ${t('More')}`}
          </Text>
        </ViewMoreButton>
      )}
      <Modal
        css={SpecialtyOfferedByFacilityModal}
        data-testid="specialty-offered-modal"
        isOpen={showModal}
        onClose={handleCloseModal}
        title={specialtiesOfferedByFacilitiesModal.message}
      >
        <Modal.Section>
          {specialties.map((specialty, index) => (
            <Flex
              css={{ gap: '4px' }}
              data-testid={`sub-heading-speciality-${specialty.key.replace(
                /_/g,
                '-'
              )}`}
              key={index}
            >
              <Text css={specialtiesOfferedTitle}>
                {t(
                  `PROVIDER_DETAILS.LOCATIONS_TAB.${specialty.key.toUpperCase()}`
                )}{' '}
                -{' '}
              </Text>
              <Text css={specialtiesOffered}>
                {getYesNoNAText(t, specialty.hasSpecialty as boolean) ||
                  notAvailableOrUnknownText}
              </Text>
            </Flex>
          ))}
        </Modal.Section>
      </Modal>
    </div>
  );
};
