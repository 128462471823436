import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Tabs } from '@abyss/web/ui/Tabs';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { PortalContext } from '../../context/PortalContext';
import { useAdobePageTrackEvent } from '../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCoverageType } from '../../hooks/useCoverageType';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { updateStoreValueWithParamValue } from '../../utils/store.utils';
import {
  getCurrentMember,
  isTypeCodeMatchingCoverageType,
} from '../../utils/user.utils';
import { adobeImpressionTrackEvent } from '../AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { CategoryChipsDrawer } from '../ChipsCategory/CategoryChipsDrawer';
import {
  CareCategoriesText,
  ChipMenuIcon,
  MenuList,
  chipSelectorDrawerMobile,
} from '../ChipsCategory/ChipsCategory.styles';
import {
  Constants,
  CoverageTypesCodes,
  ReverseCoverageTypesCodes,
} from '../Constants';
import { notMobile } from '../ConstantsStyles';
import {
  TabsContainer,
  browseFlexStyle,
  hamburgerButton,
  tabFlexStyle,
  tabsCategory,
  tabsLayoutStyles,
} from '../TabsCategory/TabsCategory.styles';
import { convertObjectToUrlParams } from '../Utils';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';

interface ChipObject {
  label: string;
  value: string;
  id: string;
  enabled: boolean;
}
type Props = {
  chips: ChipObject[];
  customMessage?: string;
  isMixedResultsPage?: boolean;
};

export const TabsCategory = ({
  chips,
  customMessage,
  isMixedResultsPage = false,
}: Props) => {
  const desktopScreen = useMediaQuery(notMobile);
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const [searchParams, setSearchParams] = useSearchParams();
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);
  const { portalData } = useContext(PortalContext);
  const locationTabIndex = 0;
  const [active, setActive] = useState(locationTabIndex);
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const {
    chipValue: contextValue,
    setCoverageType: setStoreCoverageType,
    setChipValue: setStoreChipValue,
  } = chipStore;
  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const [headerChipEnabled, setHeaderChipEnabled] = useState(true);
  const {
    searchType = '',
    includeSpecialityRollupCodes = '',
    pseudoVerticals,
    providerType = '',
    search,
    isMixedSuggestions,
  } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      searchType: state.typeaheadSearchStore.searchType,
      includeSpecialityRollupCodes:
        state.typeaheadSearchStore.includeSpecialityRollupCodes,
      pseudoVerticals: state.typeaheadSearchStore.pseudoVerticals,
      suggestion: state.typeaheadSearchStore.suggestion,
      search: state.typeaheadSearchStore.search,
      providerType: state.typeaheadSearchStore.providerType,
      isMixedSuggestions: state.typeaheadSearchStore.isMixedSuggestions,
    }))
  );
  const [careCategory, setCareCategory] = useState(contextValue);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuListRef = useRef<HTMLElement>();
  const allTabs = containerRef.current?.querySelectorAll('.abyss-tabs-tab');
  const [displayHamburgerMenuIcon, setDisplayHamburgerMenuIcon] =
    useState(false);
  const loggedInMemberIndex = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.LOGGED_IN_MEMBER_INDEX
  );
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: `${contextValue} overview`,
  });
  const currentMember = getCurrentMember();
  const coverageType = useCoverageType();

  const chipValueFromParms = searchParams.get('chipValue');
  const coverageTypeFromParms = searchParams.get('coverageType');

  const hamburgerIconRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!isOpen && hamburgerIconRef.current) {
      hamburgerIconRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    updateStoreValueWithParamValue(
      contextValue,
      setStoreChipValue,
      chipValueFromParms
    );
    updateStoreValueWithParamValue(
      coverageType,
      setStoreCoverageType,
      coverageTypeFromParms
    );
  }, [chipValueFromParms, coverageTypeFromParms]);

  useEffect(() => {
    if (portalData?.portalConfig) {
      setHeaderChipEnabled(!!portalData?.portalConfig?.headerChipsEnabled);
    }
  }, [portalData]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (containerRef.current != null) {
        setWindowSize(containerRef.current.offsetWidth);
      }
    };
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    const updateScrollCategory = () => {
      const scrollCategoryTimeout = setTimeout(() => {
        clearTimeout(scrollCategoryTimeout);
      }, 800);
    };
    updateScrollCategory();
    window.addEventListener('resize', updateScrollCategory);
    return () => {
      debounce(
        () => window.removeEventListener('resize', handleWindowResize),
        400
      );
    };
  }, [windowSize]);

  useEffect(() => {
    if (shouldScroll) {
      containerRef?.current?.scrollIntoView({ block: 'center' });
    }
    if (isOpen) {
      menuListRef?.current?.focus();
      handleLinkAndModalTrack(
        Constants.CHIPS_CATEGORIES.TYPES_OF_CARE,
        'sub nav',
        Constants.CHIPS_CATEGORIES.TYPES_OF_CARE
      );
    }

    if (
      contextValue === 'Dental' ||
      contextValue === 'Vision' ||
      contextValue === 'Health Supplies' ||
      contextValue === 'Facilities' ||
      contextValue === 'Behavioral Health'
    ) {
      containerRef?.current?.scrollIntoView({ block: 'center' });

      setCareCategory(contextValue);
    }
  }, [contextValue, isOpen, menuListRef]);

  useEffect(() => {
    const tabsListContainer = document.querySelector(
      "[data-testid='tabs-category-list-abyss-tabs-list']"
    ) as HTMLElement;

    setDisplayHamburgerMenuIcon(
      tabsListContainer?.offsetWidth < tabsListContainer?.scrollWidth
    );
  }, [windowSize]);

  useEffect(() => {
    if (
      loggedInMemberIndex != null &&
      !isMixedResultsPage &&
      contextValue === Constants.CHIPS_CATEGORIES.ALL // Remaining chips page tracks will be based on provider data. Implemented in useAdobeProvidersImpressionBlock hook.
    ) {
      adobePageTrackEvent();
    }
  }, [contextValue, loggedInMemberIndex]);

  useEffect(() => {
    if (customMessage && Constants.CHIPS_CATEGORIES.ALL) {
      adobeImpressionTrackEvent({
        type: 'alert',
        message: customMessage,
      });
    }
  }, [customMessage]);

  useEffect(() => {
    const index = chips.findIndex((item) => item.value === contextValue);
    setActive(index);
  }, [contextValue]);

  useEffect(() => {
    const isTabAvailable =
      !!currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
        (coverage) =>
          isTypeCodeMatchingCoverageType(
            coverageType,
            coverage.typeCode?.coverageTypeCode
          )
      );

    if (!isTabAvailable) {
      handleTabChange(0);
    }
  }, [coverageType, currentMember]);

  const setHasBreadCrumbClickedFlag = useStore(
    StoreKeys.SET_BREADCRUMB_CLICKED_FLAG
  );

  const onChipClicked = (value, index, isResponsiveView?: boolean) => {
    setHasBreadCrumbClickedFlag(true);
    adobeLinkTrackEvent({
      name: isResponsiveView ? 'change button' : value,
      location: isResponsiveView
        ? `modal:${Constants.CHIPS_CATEGORIES.TYPES_OF_CARE}`
        : 'sub-nav',
      type: 'internal',
    });
    setActive(index);

    if (value === Constants.CHIPS_CATEGORIES.ALL) {
      const selectedCoverageType =
        find(Constants.MOCK_CARE_CATEGORIES, {
          value,
        })?.coverageType || CoverageTypesCodes.MEDICAL;

      setStoreChipValue(value);
      setStoreCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
      setCareCategory(value);

      if (!isMixedResultsPage) {
        const chipStoreValues = {
          chipValue: value,
        };
        setSearchParams(chipStoreValues, { replace: false });
      }
    } else {
      const selectedCoverageType =
        find(Constants.MOCK_CARE_CATEGORIES, {
          value,
        })?.coverageType || CoverageTypesCodes.MEDICAL;

      setStoreChipValue(value);
      setStoreCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
      setCareCategory(value);
      setShouldScroll(true);
      setAnalyticsState({ originLinkNameForAnalytics: value });

      const chipStoreValues = {
        chipValue: value,
        coverageType: ReverseCoverageTypesCodes[selectedCoverageType],
      };

      if (!isMixedResultsPage) {
        setSearchParams(chipStoreValues, { replace: false });
      }

      if (isMixedResultsPage) {
        const urlParams = convertObjectToUrlParams(chipStore, {
          ...chipStoreValues,
          search,
          searchType,
          providerType,
          includeSpecialityRollupCodes,
          pseudoVerticals,
          isMixedSuggestions,
        });

        navigate(`/results/${urlParams}`);
      }
    }
    allTabs?.[index].scrollIntoView();
  };

  const activeTabs: string[] = [];

  const handleTabChange = (index) => {
    setActive(index);
    onChipClicked(activeTabs[index], index);
    allTabs?.[index].scrollIntoView();
  };

  const renderChipsTabs = chips.map((item: ChipObject) => {
    activeTabs.push(item.value);
    return (
      <Tabs.Tab
        aria-label={t(item.label)}
        className={contextValue === item.value ? 'active' : ''}
        data-auto-testid={`tabs-category-${item.id}-chip`}
        data-testid={`tabs-category-${item.id}-chip`}
        disabled={!item.enabled}
        key={t(item.value)}
        label={t(item.label)}
        tabbableContent={false}
        text={t(item.label)}
        value={item.value}
        variant="outline"
      />
    );
  });
  return (
    <React.Fragment>
      {!desktopScreen && isOpen && (
        <Drawer
          css={chipSelectorDrawerMobile}
          isOpen={isOpen}
          onClose={() => {
            adobeLinkTrackEvent({
              name: 'close',
              location: `modal:${Constants.CHIPS_CATEGORIES.TYPES_OF_CARE}`,
            });
            setIsOpen(false);
            if (hamburgerIconRef?.current) {
              hamburgerIconRef?.current.focus();
            }
          }}
          position="bottom"
          title={t(Constants.CHIPS_CATEGORIES.TYPES_OF_CARE)}
        >
          <CategoryChipsDrawer
            careCategory={careCategory}
            chips={chips}
            onChipClicked={onChipClicked}
            setCareCategory={setCareCategory}
            setIsOpen={setIsOpen}
          />
        </Drawer>
      )}
      {desktopScreen && isOpen && displayHamburgerMenuIcon && (
        <MenuList
          data-auto-testid="category-menu-list"
          data-testid="category-menu-list"
          id="category-menu-list"
          ref={menuListRef}
          tabIndex="0"
        >
          <Flex justify="space-between">
            <CareCategoriesText>
              {t(Constants.CHIPS_CATEGORIES.TYPES_OF_CARE)}
            </CareCategoriesText>
            <Button
              css={{
                paddingTop: '6px',
                paddingBottom: '36px',
                paddingLeft: '8px',
                paddingRight: '0px',
              }}
              data-auto-testid="chips-menu-drawer-close"
              data-testid="chips-menu-drawer-close"
              onClick={() => {
                setIsOpen(false);
              }}
              variant="tertiary"
            >
              <IconMaterial icon="close" />
            </Button>
          </Flex>
          <CategoryChipsDrawer
            careCategory={careCategory}
            chips={chips}
            onChipClicked={onChipClicked}
            setCareCategory={setCareCategory}
            setIsOpen={setIsOpen}
          />
        </MenuList>
      )}
      {headerChipEnabled && (
        <>
          <Layout.Stack css={tabsLayoutStyles} grow space={0}>
            <Flex css={browseFlexStyle} direction="row">
              <Heading
                css={tabsCategory}
                data-testid="results-page-heading"
                display="h4"
                id="browse-care-categories"
                offset={1}
              >
                {isMixedResultsPage
                  ? t('WE_FOUND_RESULTS_IN_THESE_CATEGORIES')
                  : t('BROWSE_CARE_CATEGORY')}
              </Heading>
            </Flex>
            <Flex css={tabFlexStyle} direction="row">
              {displayHamburgerMenuIcon && (
                <ChipMenuIcon>
                  <Button
                    aria-label={t('CARE_OPTIONS')}
                    css={hamburgerButton}
                    data-auto-testid="hamburger-icon-chips-header-container"
                    data-testid="hamburger-icon-chips-header-container"
                    onKeyDown={(e) => {
                      if (e.key === ' ' || e.key === 'Enter') {
                        e.preventDefault();
                        setIsOpen(true);
                      }
                    }}
                    ref={hamburgerIconRef}
                    variant="ghost"
                  >
                    <IconMaterial
                      color="$primary1"
                      data-auto-testid="bulleted-icon-chips-list"
                      data-testid="bulleted-icon-chips-list"
                      icon="format_list_bulleted"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      size="24px"
                    />
                  </Button>
                </ChipMenuIcon>
              )}
              <TabsContainer
                className="hide-scrollbar"
                data-auto-testid="tab-container"
                data-testid="tab-container"
                ref={containerRef}
              >
                <Tabs
                  active={active}
                  aria-labelledby="browse-care-categories"
                  data-auto-testid="tabs-category-list"
                  data-testid="tabs-category-list"
                  grow={false}
                  key="chip-tabs-list"
                  onTabChange={handleTabChange}
                  role="group"
                  value={careCategory}
                  variant="line"
                >
                  {renderChipsTabs}
                </Tabs>
              </TabsContainer>
            </Flex>
          </Layout.Stack>
        </>
      )}
    </React.Fragment>
  );
};
