import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants, PopulationType } from '../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../../common/ConstantsStyles';
import { PSXIcon } from '../../../../../common/PSXIcon';
import { getFeatureFlag } from '../../../../../common/Utils';
import { useLagoon } from '../../../../../hooks/useLagoon';
import { useChipStore } from '../../../../../store/useChipStore';
import { ChipState } from '../../../../../store/useChipStore/chipStore';
import { usePCPStore } from '../../../../../store/usePCPStore';
import { PCPSearchStore } from '../../../../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../../store/useTypeaheadStore/typeaheadStore';
import {
  doChoosePCPStoreUpdate,
  getErrorMessageKeyUsingPCPErrorMapping,
  getErrorModalContentKey,
} from '../../../../../utils/primaryCare.utils';
import {
  ChatAndCallContainer,
  ErrorButtonContainer,
  ErrorContentContainer,
  ErrorHeaderContainer,
  ReturnToSearchResultsButton,
  StyledLocationItems,
  StyledLowerContentFlex,
  StyledLowerModalSectionUSP,
  StyledTopModalSectionUSP,
  StyledUpdateErrorModal,
  TopTitleStyle,
} from './ChoosePCPLocation.styled';

type Props = {
  handleClose(): void;
  isOpen: boolean;
  errorCode?: string;
  primaryPhoneNumber?: string;
  populationType: PopulationType;
  resultsErrorMessage?: string;
  dependentSeqNbr?: string;
};

export const UpdatePCPErrorModal = ({
  handleClose,
  isOpen,
  errorCode,
  primaryPhoneNumber,
  populationType,
  resultsErrorMessage,
  dependentSeqNbr,
}: Props) => {
  const lagoonErrorMessages = useLagoon(
    Constants.LAGOON_TABLE.UPDATE_PCP_ERROR_MODAL
  )();
  const pcpErrorCodeMap = useLagoon(
    Constants.LAGOON_TABLE.PCP_ERROR_CODE_MAP
  )();
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const enableLagoonPcpErrorMap = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LAGOON_PCP_ERROR_MAP
  );

  const showCallChatSection = !getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_ERROR_PAGE_CALL_CHAT
  );

  const key = enableLagoonPcpErrorMap
    ? getErrorMessageKeyUsingPCPErrorMapping(
        populationType,
        errorCode,
        resultsErrorMessage,
        pcpErrorCodeMap
      )
    : getErrorModalContentKey(populationType, errorCode, resultsErrorMessage);
  const errorMessage = find(lagoonErrorMessages, { key });

  const mobileScreen = useMediaQuery(phoneOnly);
  const { navigate } = useRouter();
  const { t } = useTranslation();
  const lowerCasePopulationType = populationType?.toLowerCase();
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
      clearTypeaheadSearchStore: state.clearTypeaheadSearchStore,
    }))
  );

  const { setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
      clearPCPSearchState: state.clearPCPSearchState,
    }))
  );

  const handleBackToSearch = (e) => {
    e.preventDefault();
    adobeLinkTrackEvent({
      name: 'return to search results',
      location: `modal:${lowerCasePopulationType} error modal`,
      type: 'internal',
    });

    const urlParams = doChoosePCPStoreUpdate(
      dependentSeqNbr,
      chipStore,
      setTypeaheadSearchStore,
      setPCPSearchState
    );
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
  };

  function onClick(url, phone) {
    adobeLinkTrackEvent({
      name: `call ${phone}`,
      location: `body: ${lowerCasePopulationType} error modal`,
      type: 'phone',
      destinationUrl: url,
    });
  }

  const alignVal = mobileScreen ? 'left' : 'center';

  return (
    <StyledUpdateErrorModal
      data-testid={`${lowerCasePopulationType}-error-modal-container`}
      isOpen={isOpen}
      onClose={handleClose}
      size={mobileScreen ? 320 : 624}
    >
      <TopTitleStyle />

      <Layout.Stack
        alignLayout="center"
        // @ts-ignore
        css={{
          paddingTop: '16px',
          '@screen < $sm': {
            paddingTop: 0,
          },
        }}
        space={0}
        width="100%"
      >
        <StyledTopModalSectionUSP data-testid="error-message-container">
          <IconBrand icon="alert" size={84} variant="twotone" />
          <StyledLocationItems
            alignItems="center"
            alignLayout={alignVal}
            space={0}
          >
            <ErrorHeaderContainer>{errorMessage?.header}</ErrorHeaderContainer>
            <ErrorContentContainer>
              {errorMessage?.errorMessage}
            </ErrorContentContainer>
          </StyledLocationItems>
          <ErrorButtonContainer>
            <ReturnToSearchResultsButton
              data-auto-testid={`return-to-search-restart-${lowerCasePopulationType}`}
              data-testid="restart-search-link"
              onClick={handleBackToSearch}
              size="$md"
            >
              {t('CHOOSE_PCP_FLOW.ERROR_MODAL.RETURN_TO_SEARCH')}
            </ReturnToSearchResultsButton>
          </ErrorButtonContainer>
        </StyledTopModalSectionUSP>
        {showCallChatSection && (
          <React.Fragment>
            <StyledLowerModalSectionUSP>
              <Text color="$primary1" fontWeight="bold" size="md">
                {t('CHOOSE_PCP_FLOW.ERROR_MODAL.MORE_HELP')}
              </Text>
              <StyledLowerContentFlex>
                <div id={Constants.LIVE_PERSON_EMBEDDED_CHAT_DIV_ID} />
                <Link
                  before={<PSXIcon icon="call_us" size={18} />}
                  color="#0C55B8"
                  data-testid={`${lowerCasePopulationType}-error-modal-call-us`}
                  fontWeight="bold"
                  href={`tel:${primaryPhoneNumber}`}
                  onClick={() =>
                    onClick(`tel:${primaryPhoneNumber}`, primaryPhoneNumber)
                  }
                  size="$sm"
                  style={ChatAndCallContainer}
                >
                  {t('CHOOSE_PCP_FLOW.ERROR_MODAL.CALL_US')}
                </Link>
              </StyledLowerContentFlex>
            </StyledLowerModalSectionUSP>
          </React.Fragment>
        )}
      </Layout.Stack>
    </StyledUpdateErrorModal>
  );
};
