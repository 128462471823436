import { merge } from 'lodash';
import { gehaThemeOverride } from './gehaThemeOverride';
import { uhcThemeOverride } from './uhcThemeOverride';
import { optumThemeOverride } from './optumThemeOverride';

export function getTheme(
  portalTheme: string,
  portalIdentifier: string | undefined,
  themeOverride: object | undefined | null
) : object {
  if (!themeOverride) {
    if (portalTheme === 'optum') {
      return optumThemeOverride;
    } else {
      return uhcThemeOverride;
    }
  } else {
    if (portalIdentifier === 'geha') {
      return merge({}, themeOverride, gehaThemeOverride);
    } else {
      return merge({}, themeOverride, uhcThemeOverride);
    }
  }
}