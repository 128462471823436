import { find } from 'lodash';
import React from 'react';

import { Benefits } from '../../../../../api/src/models/ProviderRecommendationDetails';
import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { PSXIcon } from '../../../common/PSXIcon';
import { useLagoon } from '../../../hooks/useLagoon';
import { MatchText } from '../MatchText';
import { NotAMatchText } from '../NotAMatchText';
import {
  BreakdownContainer,
  CriteriaPointsContainer,
  CriteriaTitle,
  DescriptionContainer,
  DrawerContentWithBackgroundgray,
  FAQContainer,
  FAQLink,
  StyledDescription,
} from '../SmartChoiceBreakDown.styles';
import { MatchTextWithTierLocations } from './Components/MatchTextWithTierLocations';
import { NotMatchTextWithTierLocations } from './Components/NotMatchTextWithTierLocations';

interface BenifitsBreakDownProps {
  benefits: Benefits;
  acoName: string;
  selectedLocatoionCity: string;
}

export const BenifitsBreakDown = ({
  benefits,
  acoName,
  selectedLocatoionCity,
}: BenifitsBreakDownProps) => {
  const [matchCount, setMatchCount] = React.useState<number>(1);
  const [notMatchCount, setNotMatchCount] = React.useState<number>(0);
  const lagoonData = useLagoon('ui-messaging')();
  const incrementMatchCounts = (isMatch: boolean) => {
    if (isMatch) {
      setMatchCount(matchCount + 1);
    } else {
      setNotMatchCount(notMatchCount + 1);
    }
  };

  React.useEffect(() => {
    calculateMatchCounts();
  }, [benefits]);

  const calculateMatchCounts = () => {
    if (benefits?.preferredProviderStatus) {
      benefits?.preferredProviderStatus === 'Y'
        ? incrementMatchCounts(true)
        : incrementMatchCounts(false);
    }
    if (benefits?.providerTierDesignation) {
      benefits?.providerTierDesignation === 'Y'
        ? incrementMatchCounts(true)
        : incrementMatchCounts(false);
    }
    if (benefits?.acoParticipationStatus) {
      benefits?.acoParticipationStatus === 'Y'
        ? incrementMatchCounts(true)
        : incrementMatchCounts(false);
    }
    if (benefits?.costIndicator) {
      switch (benefits?.costIndicator) {
        case '$':
        case '$$':
          incrementMatchCounts(true);
          break;
        case '$$$':
          incrementMatchCounts(false);
          break;
        default:
          break;
      }
    }

    if (benefits?.costEfficiencyRating) {
      switch (benefits.costEfficiencyRating) {
        case 'A':
        case 'B':
        case 'C':
        case 'D':
          setMatchCount(matchCount + 1);
          break;
        case 'E':
          setMatchCount(matchCount + 1);
          break;
        case 'F':
        case 'G':
          setNotMatchCount(notMatchCount + 1);
          break;
        default:
          break;
      }
    }
  };
  const replacePlaceholders = (
    text: string | undefined,
    replacements: Record<string, string>
  ): string =>
    (text || '').replace(/\{\{(.*?)\}\}/g, (_, key) => {
      const keys = key.split('.');
      let value: any = replacements;
      for (const k of keys) {
        value = value?.[k];
        if (value === undefined) break;
      }
      return typeof value === 'string' ? value : '';
    });

  const good_match = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_GOOD_MATCH_CONTENT,
  });
  const description = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_DESCRIPTION,
  });
  const in_network_provider = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_IN_NETWORK,
  });
  const preferred_provider = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_PREFERRED_PROVIDER,
  });
  const provider_tier = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_PROVIDER_TIER,
  });
  const preferred_tier1_and_loactions = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_PROVIDER_TIER1_WITH_LOCATION,
  });
  const not_prefered_tier1_and_loactions = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_PROVIDER_ISNOT_TIER1_WITH_LOCATION,
  });
  const provider_participated_aco = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_PROVIDER_PARTICIPATES_ACO_NAME,
  });
  const provider_participates_aco_name_and_location = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_PROVIDER_PRATICIPATES_ACO_NAME_AND_LOCATION,
  });
  const provider_does_not_participate_aco = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_DOES_NOT_PARTICIPATES_ELIGIBLE_AT_ACO_NAME,
  });
  const provider_does_not_participate_with_location = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_PROVIDER_NOT_PARTICIPATES_ACO_NAME_AND_LOCATION,
  });
  const below_average_cost_indicator = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_BELOW_AVARAGE_COST_FOR_OFFICE_VISIT,
  });
  const average_cost_indicator = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_AVERAGE_COST_FOR_OFFICE_VISIT,
  });
  const above_average_cost_indicator = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_ABOVE_AVERAGE_COST_FOR_OFFICE_VISIT,
  });
  const below_average_cost_of_care = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_BELOW_AVARAGE_TOTAL_COST_OF_CARE,
  });
  const average_cost_of_care = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_AVERAGE_TOTAL_COST_OF_CARE,
  });
  const above_average_cosst_of_care = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_ABOVE_AVERAGE_TOTAL_COST_OF_CARE,
  });
  const FAQs = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BREAKDOWN_FAQ_TEXT,
  });
  return (
    <BreakdownContainer data-testid="benefits-breakdown">
      <DrawerContentWithBackgroundgray direction="column">
        <CriteriaTitle data-testid="good-match-title">
          {replacePlaceholders(good_match?.message || '', {
            matchCount: `${matchCount}`,
            totalMatchNonMatchCount: `${matchCount + notMatchCount}`,
          })}
        </CriteriaTitle>
      </DrawerContentWithBackgroundgray>
      <DescriptionContainer data-testid="benefits-breakdown-description">
        <StyledDescription>{description?.message}</StyledDescription>
      </DescriptionContainer>
      <CriteriaPointsContainer
        data-testid="benefits-breakdown-criteria"
        direction="column"
      >
        <MatchText text={in_network_provider?.message} />
        {benefits?.preferredProviderStatus === 'Y' && (
          <MatchText
            StartIcon={<PSXIcon icon={'green_diamond'} size={24} />}
            text={preferred_provider?.message}
          />
        )}
        {benefits?.providerTierDesignation === 'Y' && (
          <React.Fragment>
            <MatchText
              StartIcon={<PSXIcon icon="Group 204976975" size={24} />}
              text={provider_tier?.message}
            />
            <MatchTextWithTierLocations
              StartIcon={<PSXIcon icon="Group 204976975" size={24} />}
              text={replacePlaceholders(
                preferred_tier1_and_loactions?.message,
                {
                  selectedLocatoionCity: `${selectedLocatoionCity}`,
                }
              )}
            />
          </React.Fragment>
        )}
        {benefits?.providerTierDesignation === 'N' && (
          <React.Fragment>
            <NotAMatchText text={provider_tier?.message} />

            <NotMatchTextWithTierLocations
              text={replacePlaceholders(
                not_prefered_tier1_and_loactions?.message,
                {
                  selectedLocatoionCity: `${selectedLocatoionCity}`,
                }
              )}
            />
          </React.Fragment>
        )}
        {benefits?.acoParticipationStatus === 'Y' && (
          <React.Fragment>
            <MatchText
              text={replacePlaceholders(provider_participated_aco?.message, {
                acoName: `${acoName}`,
              })}
            />
            <MatchTextWithTierLocations
              StartIcon={<PSXIcon icon="Group 204976975" size={24} />}
              text={replacePlaceholders(
                provider_participates_aco_name_and_location?.message,
                {
                  selectedLocatoionCity: `${selectedLocatoionCity}`,
                  acoName: `${acoName}`,
                }
              )}
            />
          </React.Fragment>
        )}
        {benefits?.acoParticipationStatus === 'N' && (
          <React.Fragment>
            <NotAMatchText text={provider_does_not_participate_aco?.message} />
            <NotMatchTextWithTierLocations
              text={replacePlaceholders(
                provider_does_not_participate_with_location?.message,
                {
                  selectedLocatoionCity: `${selectedLocatoionCity}`,
                }
              )}
            />
          </React.Fragment>
        )}
        {benefits?.costIndicator === '$' && (
          <MatchText text={below_average_cost_indicator?.message} />
        )}
        {benefits?.costIndicator === '$$' && (
          <MatchText text={average_cost_indicator?.message} />
        )}
        {benefits?.costIndicator === '$$$' && (
          <NotAMatchText text={above_average_cost_indicator?.message} />
        )}
        {benefits?.costEfficiencyRating &&
          (() => {
            switch (benefits.costEfficiencyRating) {
              case 'A':
              case 'B':
              case 'C':
              case 'D':
                return <MatchText text={below_average_cost_of_care?.message} />;
              case 'E':
                return <MatchText text={average_cost_of_care?.message} />;
              case 'F':
              case 'G':
                return (
                  <NotAMatchText text={above_average_cosst_of_care?.message} />
                );
              default:
                return null;
            }
          })()}
      </CriteriaPointsContainer>

      <FAQContainer>
        <FAQLink data-testid="faq-link" href="https://www.uhc.com/">
          {FAQs?.message}
        </FAQLink>
      </FAQContainer>
    </BreakdownContainer>
  );
};
