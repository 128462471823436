import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Card } from '@abyss/web/ui/Card';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { ProgressBar } from '@abyss/web/ui/ProgressBar';
import { Text } from '@abyss/web/ui/Text';
import { ProviderDetails } from '#/models/ProviderDetails';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { mobileOnly } from '../../../../common/ConstantsStyles';
import { SmartChoiceBreakDownL1 } from '../../../../common/SmartChoice/SmartChoiceBreakDownL1';
import { BreakdownDrawer } from '../../../../common/SmartChoiceBreakDownDrawer/BreakdownDrawer';
import { getFeatureFlag } from '../../../../common/Utils';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  RecommendationCriteriaMatch,
  RecommendationScoreMatch,
  breakDownKeys,
  getChipTextColor,
  getChipbackgroundColor,
  getMatchDescription,
} from '../../../../utils/smartChoice.utils';
import { ContentWrapper } from '../../../ContentWrapper';
import {
  CardContainer,
  CardSubContainer,
  ContentContainer,
  ContentTitle,
  ContentValue,
  CriteriaContentContainer,
  PercentageBox,
  PercentageText,
  RightContainer,
  ViewMoreText,
} from './SmartChoice.style';

type Props = {
  recommendationDetails: object;
  providerDetails: {
    providerName: string;
    primaryDegrees: string[];
    specialties: string[];
  };
};

export const SmartChoice = ({
  recommendationDetails,
  locationKey,
  providerDetails,
}: Props) => {
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;

  const ENABLE_SMART_CHOICE_UPDATED_PROVIDER_INFO: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SMART_CHOICE_UPDATED_PROVIDER_INFO
  );

  const [smarChoiceBreakDownL1, setSmarChoiceBreakDownL1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [drawerType, setDrawerType] = useState('');

  const toggleDrawer = (str) => {
    setIsOpen(!isOpen);
    setDrawerType(str);
  };
  const onBreakDownClick = (str) => {
    setSmarChoiceBreakDownL1(false);
    setIsOpen(!isOpen);
    setDrawerType(str);
  };

  const renderBreakDownCard = (key: string): JSX.Element => {
    let match =
      recommendationDetails?.[key]?.scoreDetails?.match ??
      RecommendationScoreMatch.Low;

    if (key === 'quality') {
      match =
        recommendationDetails?.[key]?.scoreDetails?.match ??
        RecommendationCriteriaMatch.None;
    }
    return (
      <Card
        css={{
          marginTop: '16px !important',
          padding: '0px !important',
          marginRight: '8px',
          cursor: 'pointer',
        }}
        data-testid="smart-choice-card"
        key={key}
        onClick={() => toggleDrawer(key)}
      >
        <ContentContainer>
          <ContentTitle color="$gray8">{t(breakDownKeys[key])}:</ContentTitle>
          <CriteriaContentContainer>
            <ContentValue
              css={{
                backgroundColor: getChipbackgroundColor(match, key),
                color: getChipTextColor(match, key),
              }}
            >
              {t(getMatchDescription(match, key))}
            </ContentValue>
          </CriteriaContentContainer>
          <ProgressBar
            barColor="$gray3"
            css={{ borderWidth: '0px' }}
            height={'8px'}
            percentage={
              recommendationDetails?.[key]?.scoreDetails?.percentage ?? 0
            }
          />
        </ContentContainer>
      </Card>
    );
  };

  const renderBreakDownData = (): JSX.Element[] => {
    const breakDownData: JSX.Element[] = [];
    Object.keys(breakDownKeys)
      .filter((item) => {
        if (
          item === 'verification' &&
          !ENABLE_SMART_CHOICE_UPDATED_PROVIDER_INFO
        ) {
          return false;
        }
        return true;
      })
      ?.map((key) => breakDownData.push(renderBreakDownCard(key)));
    return breakDownData;
  };

  const recommendationScore = (
    recommendationDetails as { totalRecommendationScore?: number }
  )?.totalRecommendationScore;

  return (
    <>
      <CardContainer>
        <ContentWrapper isWidget={false}>
          <CardSubContainer>
            <Flex
              alignItems={mobileScreen ? 'center' : 'baseline'}
              css={{
                flexWrap: 'nowrap',
              }}
            >
              <PercentageBox>
                <PercentageText color="$white">
                  {recommendationScore}%
                </PercentageText>
              </PercentageBox>

              <RightContainer>
                <Flex
                  alignItems="center"
                  css={{ marginBottom: mobileScreen ? '0px' : '16px' }}
                  justify="space-between"
                >
                  <Flex
                    alignItems={mobileScreen ? 'flex-start' : 'center'}
                    css={{ flexDirection: mobileScreen ? 'column' : 'row' }}
                  >
                    <Text
                      className="smart-choice-text"
                      css={{
                        'abyss-text-root': {
                          fontSize: mobileScreen ? '20px' : '25px',
                        },
                      }}
                    >
                      {t('SMART_CHOICE.SMART_CHOICE')}
                    </Text>

                    <Text className="desc-text" color="$gray7">
                      <span style={{ fontWeight: mobileScreen ? 500 : 700 }}>
                        9
                      </span>{' '}
                      {mobileScreen
                        ? t('SMART_CHOICE.PREFERENCES_APPLIED')
                        : t('SMART_CHOICE.PERSONAL_PREFERENCES_APPLIED')}
                    </Text>
                  </Flex>
                  <Flex
                    css={{ cursor: 'pointer' }}
                    data-testid="smart-choice-view-more-btn"
                    onClick={() => setSmarChoiceBreakDownL1(true)}
                  >
                    {!mobileScreen && (
                      <ViewMoreText>{t('SMART_CHOICE.VIEW_MORE')}</ViewMoreText>
                    )}
                    <IconMaterial color="black" icon="chevron_right" />
                  </Flex>
                </Flex>

                {!mobileScreen && <Divider height={0.5} />}
              </RightContainer>
            </Flex>
            <Flex>{!mobileScreen && renderBreakDownData()}</Flex>
          </CardSubContainer>
        </ContentWrapper>
      </CardContainer>
      {smarChoiceBreakDownL1 && (
        <SmartChoiceBreakDownL1
          handleCloseDrawer={() => setSmarChoiceBreakDownL1(false)}
          isOpen={smarChoiceBreakDownL1}
          onBreakDownClick={onBreakDownClick}
          providerDetails={providerDetails}
          recommendationDetails={recommendationDetails}
        />
      )}
      <BreakdownDrawer
        drawerType={drawerType}
        isOpen={isOpen}
        locationKey={locationKey}
        providerDetails={providerDetails as ProviderDetails}
        recommendationDetails={recommendationDetails}
        setIsOpen={setIsOpen}
      />
    </>
  );
};
