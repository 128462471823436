import { Router } from '@abyss/web/ui/Router';
import React from 'react';

import { ChooseSearchExperience } from './ChooseSearchExperience';
import { DeeplinkContainer } from './deeplink/DeeplinkContainer';
import { GuestLineOfBusinessPicker } from './GuestLineOfBusinessPicker';
import { GuestPlanPicker } from './GuestPlanPicker';
import { GuestProviderNameSearchContainer } from './providerNameSearch/GuestProviderNameSearchContainer';
import { ProviderSearchForPortalContainer } from './ProviderSearchForPortalContainer';
import { GuestLocationPicker } from './selectLocation/GuestLocationPicker';

export const GuestRoutes = () => {
  return (
    <Router.Routes>
      <Router.Route>
        <Router.Route element={<ChooseSearchExperience />} path="/" />
        <Router.Route
          element={<GuestLineOfBusinessPicker />}
          path="/select-lob"
        />
        <Router.Route
          element={<GuestLocationPicker />}
          path="/select-location"
        />
        <Router.Route element={<GuestPlanPicker />} path="/select-plan" />
        <Router.Route
          element={<GuestProviderNameSearchContainer />}
          path="/provider-search-by-name"
        />
        <Router.Route element={<DeeplinkContainer />} path="/deeplink" />
        <Router.Route
          element={<ProviderSearchForPortalContainer />}
          path="/browse"
        />
      </Router.Route>
    </Router.Routes>
  );
};
