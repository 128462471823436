export const smartChoiceFlex = (
  isMobileScreen,
  isMemberSelectedHeader,
  isMixedResultsPage,
  memberChangeHeader,
  showChoosePCPHeader
) => ({
  right: isMobileScreen
    ? isMemberSelectedHeader && isMixedResultsPage
      ? '0px'
      : isMemberSelectedHeader || showChoosePCPHeader
      ? '50px'
      : '30px'
    : memberChangeHeader
    ? '0px'
    : '60px',
  bottom: isMobileScreen
    ? isMemberSelectedHeader
      ? '30px'
      : '0px'
    : memberChangeHeader
    ? isMemberSelectedHeader
      ? '4px'
      : '16px'
    : '18px',
  top: isMobileScreen ? (isMemberSelectedHeader ? 'auto' : '18px') : 'auto',
});

export const smartChoiceLinkStyle = (isMobileScreen) => ({
  fontWeight: '600',
  '& .abyss-insert-element-after': {
    alignItems: 'flex-end',
    '& svg': { fill: '$error1' },
    marginLeft: isMobileScreen ? '-20px' : '-8px',
  },
  '&:hover::before': { display: 'none' },
});
