import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import React from 'react';

import { CriteriaTextWrapper } from '../../SmartChoiceBreakDown.styles';
import { StyledNotAMatchText } from '../QualityBreakDown.styles';

interface NotAMatchPremiumCarePhysicianTextProps {
  text: string;
  StartIcon?: React.ReactNode;
  EndIcon?: React.ReactNode;
}
export const NotAMatchPremiumCarePhysicianText = ({
  text,
  StartIcon,
  EndIcon,
}: NotAMatchPremiumCarePhysicianTextProps) => (
  <CriteriaTextWrapper data-testid="not-a-match-text">
    <IconSymbol color="#990000" icon="do_not_disturb" />{' '}
    {StartIcon && <React.Fragment>{StartIcon}</React.Fragment>}
    {text && (
      <CriteriaTextWrapper>
        <StyledNotAMatchText>{text}</StyledNotAMatchText>
        {EndIcon && <React.Fragment>{EndIcon}</React.Fragment>}
      </CriteriaTextWrapper>
    )}
  </CriteriaTextWrapper>
);
