import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import React from 'react';

import {
  MatchStyledText,
  StyledFlexWithGapAndNoWrap,
} from '../../SmartChoiceBreakDown.styles';
import { StyledLinkLocations } from '../BenfitsBreakDown.styles';

interface NotMatchTextWithTierLocationsProps {
  text: string;
  EndIcon?: React.ReactNode;
}
export const NotMatchTextWithTierLocations = ({
  text,
}: NotMatchTextWithTierLocationsProps) => (
  <StyledFlexWithGapAndNoWrap
    alignItems="flex-start"
    data-testid="not-match-tier1-with-location"
    direction="row"
  >
    <StyledFlexWithGapAndNoWrap>
      <IconSymbol color="#990000" icon="do_not_disturb" />
    </StyledFlexWithGapAndNoWrap>

    <StyledFlexWithGapAndNoWrap alignItems="flex-start" direction="column">
      <MatchStyledText>{text}</MatchStyledText>
      <StyledLinkLocations
        data-testid="locations-link"
        href="/locations"
      >{`Locations > `}</StyledLinkLocations>
    </StyledFlexWithGapAndNoWrap>
  </StyledFlexWithGapAndNoWrap>
);
