import { useCallback } from 'react';

import {
  Constants,
  LOB,
  ProviderType,
  ProviderTypes,
} from '../../../../common/Constants';
import { useProviderResults } from '../../../../hooks/useProviderSearch';
import { useTier1PlanIdentifier } from '../../../../hooks/useTier1PlanIdentifier';
import { getProviderSearchParams } from '../../../../utils/providerSearch.utils';
import { getCurrentMember } from '../../../../utils/user.utils';
import { checkError, formatData, getDataForMixedResults } from './searchUtils';

type GetProviderSearchResultsProps = {
  setShowFacilities: (x: boolean) => any;
  setShowProviderGroups: (x: boolean) => any;
  setShowProviders: (x: boolean) => any;
  providerSearchParams: any;
  choosePCP?: boolean;
  cleanUpProviderCalls: boolean;
  pseudoVerticalsDetails: any;
  selectedCategory: any;
  selectedCategoryPayload: any;
  specialtySearch: boolean;
  searchType: string;
  providerQueryTypes: any[];
  providerType?: ProviderType | 'All';
};

export const useSearchResultsProviderResults = (
  customParams: any,
  onProviderResultsLoaded?: (providersResult: any) => void,
  onOrgResultsLoaded?: (organizationResult: any) => void,
  onMedGrpResultsLoaded?: (medGrpResult: any) => void
) => {
  const [providersResult, getProvidersResult] = useProviderResults(
    customParams,
    {
      onCompleted: (providersResult) => {
        onProviderResultsLoaded?.(providersResult);
      },
    }
  );
  const [organizationResult, getOrgResult] = useProviderResults(customParams, {
    onCompleted: (organizationResult) => {
      onOrgResultsLoaded?.(organizationResult);
    },
  });
  const [medGrpResult, getMedGroupResult] = useProviderResults(customParams, {
    onCompleted: (medGrpResult) => {
      onMedGrpResultsLoaded?.(medGrpResult);
    },
  });
  const currentMember = getCurrentMember();

  const {
    data: practitionerData,
    isLoading: providerIsLoading,
    error: providerHasError,
  } = providersResult;

  const {
    data: orgdata,
    isLoading: orgIsLoading,
    error: orgHasError,
  } = organizationResult;

  const {
    data: medGrpData,
    isLoading: medGrpIsLoading,
    error: medGrpHasError,
  } = medGrpResult;

  const data: any = formatData(practitionerData, orgdata, medGrpData);

  const isTiredPlanVal = data?.isTiredPlan || undefined;

  const error: boolean =
    !medGrpIsLoading &&
    !orgHasError &&
    !providerHasError &&
    checkError(providerHasError, orgHasError, medGrpHasError);

  useTier1PlanIdentifier(isTiredPlanVal);

  const getProviderSearchResults = useCallback(
    ({
      providerSearchParams,
      setShowFacilities,
      setShowProviderGroups,
      setShowProviders,
      choosePCP = false,
      cleanUpProviderCalls,
      pseudoVerticalsDetails,
      selectedCategory,
      selectedCategoryPayload,
      specialtySearch,
      searchType,
      providerQueryTypes,
      providerType = 'All',
    }: GetProviderSearchResultsProps) => {
      const canFetchData = selectedCategory || choosePCP;
      if (!canFetchData) {
        return;
      }

      const callProvidersResult = (params, code) => {
        const hasProviderTypeInProviderQueryTypes =
          providerQueryTypes?.length > 0
            ? providerQueryTypes.filter(
                (item) =>
                  ProviderTypes[item.providerType] === params.providerType
              ).length > 0
            : true;
        const enableProviderSearch =
          hasProviderTypeInProviderQueryTypes &&
          ['All', ProviderType.PRACTITIONER].includes(providerType);

        const enableProviderGroupSearch =
          ['All', ProviderType.MEDICAL_GROUP].includes(providerType) &&
          hasProviderTypeInProviderQueryTypes &&
          (!choosePCP || currentMember.lineOfBusiness === LOB.ENI);
        const enableFacilitySearch =
          ['All', ProviderType.ORGANIZATION].includes(providerType) &&
          hasProviderTypeInProviderQueryTypes &&
          !choosePCP;

        const providerTypeMapping = {
          P: enableProviderSearch
            ? () => {
                getProvidersResult(params);
                setShowProviders(true);
              }
            : () => {
                setShowProviders(false);
              },
          M: enableProviderGroupSearch
            ? () => {
                getMedGroupResult(params);
                setShowProviderGroups(true);
              }
            : () => {
                setShowProviderGroups(false);
              },
          O: enableFacilitySearch
            ? () => {
                getOrgResult(params);
                setShowFacilities(true);
              }
            : () => {
                setShowFacilities(false);
              },
        };

        providerTypeMapping[code]();
      };

      const { practitionerParams, orgParams, medGrpParams } =
        getProviderSearchParams(
          providerSearchParams,
          selectedCategoryPayload,
          specialtySearch ? Constants.SEARCH_TYPES.SPECIALTY : searchType,
          choosePCP,
          cleanUpProviderCalls,
          providerQueryTypes
        );

      getDataForMixedResults({
        callProvidersResult,
        setShowFacilities,
        setShowProviderGroups,
        setShowProviders,
        medGrpParams,
        orgParams,
        practitionerParams,
        choosePCP,
        cleanUpProviderCalls,
        pseudoVerticalsDetails,
        searchType: providerSearchParams.searchType,
        selectedCategory,
        memberLob: currentMember.lineOfBusiness,
        selectedCategoryPayload,
      });
    },
    [getProvidersResult, getOrgResult, getMedGroupResult]
  );

  return {
    data,
    error,
    medGrpIsLoading,
    medGrpResult,
    providerIsLoading,
    medGrpData,
    providersResult,
    providerHasError,
    orgIsLoading,
    organizationResult,
    getProviderSearchResults,
  };
};
