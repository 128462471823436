import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import React from 'react';

import {
  CriteriaTextWrapper,
  MatchStyledText,
} from './SmartChoiceBreakDown.styles';

interface MatchTextProps {
  text: string;
  StartIcon?: React.ReactNode;
  EndIcon?: React.ReactNode;
}
export const MatchText = ({ text, StartIcon, EndIcon }: MatchTextProps) => (
  <CriteriaTextWrapper data-testid="match-text">
    <div>
      <IconSymbol color="#007000" icon="done" />
    </div>
    {StartIcon && <React.Fragment>{StartIcon}</React.Fragment>}

    {text && (
      <CriteriaTextWrapper>
        <MatchStyledText>{text}</MatchStyledText>

        {EndIcon && <React.Fragment>{EndIcon}</React.Fragment>}
      </CriteriaTextWrapper>
    )}
  </CriteriaTextWrapper>
);
