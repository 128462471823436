import { config } from '@abyss/web/tools/config';
import { storage } from '@abyss/web/tools/storage';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { Constants, PopulationType } from '../../common/Constants';
import { getCurrentMember } from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import BH_EAP_QUERY from './BhEap.graphql';

export const useBhEapDetailsSearch = (options) => {
  const [bhEapData, setBhEapData] = useState({});
  const [queryResult, queryAction] = useCustomQuery(BH_EAP_QUERY, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'bhEap',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setBhEapData(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: bhEapData,
  };

  return [parsedResult, queryAction];
};

export const useBhEap = () => {
  const currentMember = getCurrentMember();
  const { USE_PRE_PROD } = Constants.STORAGE_KEYS.SESSION;
  const [enablePreProd] = useState(storage.session.get(USE_PRE_PROD, false));

  const [bhEapData, getbhEapData] = useBhEapDetailsSearch({});

  const [memberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );
  useEffect(() => {
    storage.session.set(USE_PRE_PROD, enablePreProd);
  }, [enablePreProd]);

  const isPopulationInEligibleForBhEAP = (population) => [
    PopulationType.COSMOS,
    PopulationType.UM,
    PopulationType.SIERRA,
  ].includes(population);

  useEffect(() => {
    if (
      !currentMember ||
      !memberId ||
      isPopulationInEligibleForBhEAP(currentMember?.population)
    )
      return;
    const {
      demographics: { name, dateOfBirth },
      policyNumber,
    } = currentMember;
    getbhEapData({
      variables: {
        firstName: name.firstName,
        lastName: name.lastName,
        dob: dateOfBirth,
        groupNumber: policyNumber,
        memberId: memberId.toString(),
        usePreProd: enablePreProd || false,
      },
    });
  }, [memberId]);
  return bhEapData?.data?.bhEap;
};
