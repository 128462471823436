import { useCollapse } from '@abyss/web/hooks/useCollapse';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LOB, ProviderTypes } from '../../../common/Constants';
import { PortalContext } from '../../../context/PortalContext';
import { setLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import {
  LANGUAGES,
  LanguageObject,
} from '../../../frontends/ProviderSearch/context/Internationalization/types';
import { useLagoon } from '../../../hooks/useLagoon';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { getFeatureFlag } from '../../Utils/getFeatureFlag';
import { ObapiDemo } from '../ObapiDemo';
import { MemberInfo } from '../ObapiDemo/memberProfile';
import {
  CollapseIcon,
  CollapseIconContainer,
  DemoProviderSearchContainer,
  StyledSelectInput,
} from './DemoHeader.styled';

type Props = {
  form: any;
  lob: string;
  providerType: string;
  searchType: string;
  onMemberDataChange?: (a: MemberInfo[]) => void;
  onMemberIdChange?: (a: number) => void;
  setLob: (a: LOB) => void;
  setMemberProfiles: (a: MemberInfo[]) => void;
  setProviderType: (a: string) => void;
  setSearchType: (a: string) => void;
};

export const DemoHeader = ({
  form = {},
  lob = '',
  providerType = '',
  searchType = '',
  onMemberDataChange = () => {},
  onMemberIdChange = () => {},
  setLob = () => {},
  setMemberProfiles = () => {},
  setProviderType = () => {},
  setSearchType = () => {},
}: Props) => {
  const { t } = useTranslation();
  const { portalData } = useContext(PortalContext);
  const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGES.en.title);
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const demoFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DEMO_FLAG
  );
  const enableMnrCosmos = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MNR_COSMOS
  );
  const enableCns = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CNS
  );
  const enableIFP = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_IFP
  );
  const {
    collapseProps,
    buttonProps,
    isOpen: isOpenCollapse,
  } = useCollapse({ defaultIsOpen: false });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { 'aria-expanded': ariaExpanded, ...onClickButtonProp } = buttonProps;

  const setMNRPreEffectiveValues = () => {
    form.setValue('firstName', 'FNZIWLLECFMB');
    form.setValue('lastName', 'LNZQZZPRGM');
    form.setValue('dob', '10-15-1955');
    form.setValue('memberId', '992509665');
    form.setValue('groupNumber', '71590');
    form.setValue(
      'id',
      'Ro3J1yl0g5yJA3h_op5pmUAiDVMZc3Ts9mJlR7DLbpEBYKymFm79YxPMYlk8EcS8'
    );
    form.setValue('userName', '168adf307-7017-47f8-a130-dd0727e0e452');
    form.setValue('portalSource', 'aarp');
    form.setValue('bigNJsonString', '');
  };

  const setENIValues = (portalData) => {
    form.setValue('firstName', 'FNUUYDQFZCCW');
    form.setValue('lastName', 'LNLNUBNRSM');
    form.setValue('dob', '02-02-1979');
    form.setValue('memberId', '00990062383');
    form.setValue('groupNumber', '0168504');
    form.setValue('id', '');
    form.setValue('userName', '');
    form.setValue('portalSource', '');
    form.setValue('bigNJsonString', '');
    if (portalData?.portalName === 'umr') {
      form.setValue('delsysCode', '52');
    }
  };

  if (!demoFlag) {
    return null;
  }

  const addLangParamToCurrentUrl = (lang: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set('lang', lang);
    window.history.pushState({}, '', url.toString());
    window.location.reload();
  };

  const regularLobOptions = [
    { value: LOB.ENI, label: LOB.ENI },
    { value: LOB.MNR, label: LOB.MNR },
    { value: LOB.CNS, label: LOB.CNS },
    { value: LOB.IFP, label: LOB.IFP },
  ];

  const umrLobOptions = [{ value: LOB.ENI, label: LOB.ENI }];

  const lobOptions =
    portalData.portalName === 'umr' ? umrLobOptions : regularLobOptions;

  const handleLangaugeChange = (e) => {
    if (portalData?.portalConfig?.language) {
      const selectedValue = e;
      const chosenLanguage =
        LANGUAGES.en.locale === selectedValue ? LANGUAGES.en : LANGUAGES.es;
      addLangParamToCurrentUrl(chosenLanguage.locale);
    } else {
      const selectedValue = e;
      const chosenLanguage =
        LANGUAGES.en.locale === selectedValue ? LANGUAGES.en : LANGUAGES.es;
      setLanguage(chosenLanguage)
        .then((lang: LanguageObject) => {
          setSelectedLanguage(lang.title);
          window.location.reload();
        })
        .catch((lang: LanguageObject) => {
          throw new Error(`${lang} is already the selected language`);
        });
      setSelectedLanguage(chosenLanguage.title);
    }
  };

  return (
    <React.Fragment>
      <CollapseIconContainer>
        <CollapseIcon
          data-testid={
            isOpenCollapse ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
          }
          {...onClickButtonProp}
          color="$primary1"
          icon={isOpenCollapse ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
        />
      </CollapseIconContainer>
      {isOpenCollapse ? (
        <div {...collapseProps}>
          <DemoProviderSearchContainer>
            {LANGUAGES ? (
              <StyledSelectInput
                data-auto-testid="pick-one-language"
                data-testid="pick-one-language"
                label="Language"
                onChange={handleLangaugeChange}
                options={[
                  { value: LANGUAGES.en.locale, label: LANGUAGES.en.title },
                  { value: LANGUAGES.es.locale, label: LANGUAGES.es.title },
                ]}
                placeholder="Select Language"
                value={selectedLanguage}
              />
            ) : null}
            {enableMnrCosmos || enableCns || enableIFP ? (
              <StyledSelectInput
                data-auto-testid="pick-one-lob"
                data-testid="pick-one-lob"
                label="LOB"
                onChange={(e) => {
                  form?.setValue('lob', e);

                  if (e === LOB.MNR) {
                    setMNRPreEffectiveValues();
                  }

                  if (e === LOB.ENI) {
                    setENIValues(portalData);
                  }

                  setLob(e);
                }}
                options={lobOptions}
                placeholder="Select LOB"
                value={lob}
              />
            ) : null}
            <ObapiDemo
              setMemberId={onMemberIdChange}
              setMemberProfiles={(memberProfile) => {
                setMemberProfiles(memberProfile);
                onMemberDataChange(memberProfile);
              }}
            />
            <StyledSelectInput
              data-auto-testid="pick-one-search-type"
              data-testid="pick-one-search-type"
              isClearable
              isSearchable
              label="Search Type"
              onChange={(e) => {
                form?.setValue('searchType', e);
                setSearchType(e);
              }}
              onClear={() => setSearchType('')}
              options={[
                { value: 'name', label: 'Name' },
                { value: 'speciality', label: 'Speciality' },
                { value: 'location', label: 'Location' },
              ]}
              placeholder={t('Pick one')}
              value={searchType}
            />
            <StyledSelectInput
              data-auto-testid="pick-one-provider-type"
              data-testid="pick-one-provider-type"
              isClearable
              isSearchable
              label="Provider Type"
              onChange={(e) => {
                form?.setValue('providerType', e);
                setProviderType(e);
              }}
              onClear={() => setProviderType('')}
              options={[
                {
                  value: ProviderTypes.PRACTITIONER,
                  label: 'Practitioner',
                },
                {
                  value: ProviderTypes.ORGANIZATION,
                  label: 'Facility',
                },
              ]}
              placeholder={t('Pick one')}
              value={providerType}
            />
          </DemoProviderSearchContainer>
        </div>
      ) : null}
    </React.Fragment>
  );
};
