import { usePrint } from '@abyss/web/hooks/usePrint';
import React, { useEffect } from 'react';

import {
  NUMBER_OF_PDF_PRACTITIONER_RESULTS,
  SHOULD_GET_HG_DATA,
} from '../../../../../common/Constants';
import { useFilterProviderResults } from '../../../../../hooks/useProviderSearch/useProviderSearch';
import { ProviderSearchFilters } from '../../../../../models/ProviderSearch';
import { PrintView } from './PrintView';

export type FilterProviderResultsPrintViewProps = {
  filters: ProviderSearchFilters;
  coverageType: string;
  initialPage: boolean;
};

export const FilterProviderResultsPrintView = ({
  filters,
  coverageType,
  initialPage,
}: FilterProviderResultsPrintViewProps) => {
  const { results: pdfResults = [] } = useFilterProviderResults({
    pageNumber: 1,
    pageSize: NUMBER_OF_PDF_PRACTITIONER_RESULTS,
    selectedFilters: filters,
    coverageType,
    shouldGetHGData: SHOULD_GET_HG_DATA,
    initialPage,
  });
  const print = usePrint();

  useEffect(() => {
    if (pdfResults?.length > 0) {
      print;
      print.printPage(() => {}, {
        openNewWindow: false,
      });
    }
  }, [pdfResults]);

  return <PrintView selectedProviderList={pdfResults} />;
};
