import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { SaveAndCancelButton } from '../../frontends/ProviderSearch/routes/ChoosePCP/ChoosePCPLocation/ChoosePCPLocation.styled';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useLagoon } from '../../hooks/useLagoon';
import { ProviderLocation } from '../../models/ProviderDetails';
import { getDistance } from '../../utils/card.utils';
import { isProviderStartDateInFuture } from '../../utils/date.utils';
import { getFormattedAddress } from '../../utils/providerDetails.utils';
import { capitalizeFirstLetters } from '../../utils/providerNames.utils';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { InNetworkBadgeWithDate } from '../InNetworkBadge/InNetworkBadge';
import { TerminationBadge } from '../TerminationBadge/TerminationBadge';
import { getFeatureFlag } from '../Utils';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import {
  AcceptingNewPatientsBadge,
  LocationBadgeRow,
  LocationCardGroup,
  LocationCardWrapper,
  LocationDetailsStack,
  MilesButton,
} from './SelectLocationCard.styled';

export type Props = {
  className?: string;
  handleDirectionClick: () => void;
  highlightRefs: MutableRefObject<any>;
  isMobileMapView?: boolean;
  location: ProviderLocation;
  selected?: boolean;
  selectLocation(): void;
  setIsMobileMapView: (isMobileMapView: boolean) => void;
  showBadgeRow?: boolean;
  showRadioSelect?: boolean;
};

export const SelectLocationCard = ({
  location,
  isMobileMapView = false,
  showBadgeRow = false,
  showRadioSelect = false,
  selected = false,
  highlightRefs,
  handleDirectionClick,
  selectLocation,
  className = '',
  setIsMobileMapView,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();
  const {
    address,
    distance,
    acceptingNewPatients,
    providerGroupsAffiliations,
  } = location;
  const [includeProviderGroupName] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.INCLUDE_PROVIDER_GROUP_NAME,
  ]);
  const featureFlags = useLagoon('feature-flags')();
  const displayInNetworkBadgeEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_IN_NETWORK_BADGE_FUTURE_START_DATE
  );

  const displayInNetworkBadge = isProviderStartDateInFuture(
    location?.network?.start
  );

  const addressString = getFormattedAddress(address, includeProviderGroupName);
  const addressFontSizeMobile = mobileScreen ? 'md' : 'lg';

  const choosePcpLocationTestId: string = 'choose-pcp-location';
  const selectLocationCardTestId: string = 'pcp-select-location-card';

  const handleSelectLocation = () => {
    const linkName: string = selectLocationCardTestId;
    const linkLocation: string = `body:${choosePcpLocationTestId}`;
    const modalName: string = Constants.MAP_VIEW_NAVIGATION_MODAL;
    const linkType: string = 'radio';
    handleLinkAndModalTrack(linkName, linkLocation, modalName, linkType);
    selectLocation();
  };

  const wrapperSelectedStyle = {
    border: '3px solid $interactive2',
    borderRadius: '12px',
  };

  return (
    <div onClick={handleSelectLocation}>
      <LocationCardGroup>
        <LocationDetailsStack
          alignItems="left"
          className="location-details"
          grow
        >
          <LocationCardWrapper
            aria-pressed={selected ? 'true' : 'false'}
            className={`location-card-wrap ${className}`}
            css={selected ? wrapperSelectedStyle : ''}
            data-auto-testid={selectLocationCardTestId}
            data-testid={selectLocationCardTestId}
            style={{
              width: '100%',
              margin: '6px',
            }}
          >
            <TerminationBadge
              endDate={location?.network?.end}
              message={t('PCP_TERMINATION.MAY_LEAVE_THE_NETWORK_ON')}
            />
            {displayInNetworkBadge && displayInNetworkBadgeEnabled && (
              <InNetworkBadgeWithDate
                message={t('DISPLAY_IN_NETWORK_BADGE.IN_NETWORK_BADGE_PCP')}
                startDate={location?.network?.start}
              />
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {includeProviderGroupName && providerGroupsAffiliations?.[0] && (
                <Text color="#4B4D4F" fontWeight={'$bold'}>
                  {capitalizeFirstLetters(providerGroupsAffiliations[0]?.value)}
                </Text>
              )}
              {showRadioSelect ? (
                <div
                  style={{
                    flex: 1,
                    justifyContent: 'flex-end',
                    textAlign: 'right',
                  }}
                >
                  {selected ? (
                    <IconMaterial
                      data-auto-testid="icon-location-selected"
                      data-testid="icon-location-selected"
                      icon="check_circle"
                      size={20}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ) : null}
            </div>
            <Text
              color="#4B4D4F"
              fontWeight="$medium"
              size={isMobileMapView ? 14.22 : addressFontSizeMobile}
            >
              {addressString}
            </Text>
          </LocationCardWrapper>
          {showBadgeRow ? (
            <LocationBadgeRow
              data-auto-testid="select-location-badge-row"
              data-testid="select-location-badge-row"
            >
              <MilesButton
                data-auto-testid="select-location-miles-button"
                data-testid="select-location-miles-button"
                onClick={(event) => {
                  event.stopPropagation();
                  handleDirectionClick();
                }}
                ref={(el: HTMLButtonElement) => {
                  if (highlightRefs) {
                    // eslint-disable-next-line no-param-reassign
                    highlightRefs.current[location.locationId] = el;
                  }
                }}
              >
                <IconMaterial icon="directions" size="$sm" />
                <Text color="$interactive1" fontWeight="$bold" size="$md">
                  {getDistance(distance)} {t('miles')}
                </Text>
              </MilesButton>

              {acceptingNewPatients ? (
                <div style={{ paddingLeft: '10px' }}>
                  <AcceptingNewPatientsBadge
                    icon={
                      <IconMaterial color="$success1" icon="check" size="sm" />
                    }
                    variant="none"
                  >
                    <Text color="$gray8" fontWeight="$medium" size="$md">
                      {t('Accepting new patients')}
                    </Text>
                  </AcceptingNewPatientsBadge>
                </div>
              ) : null}
            </LocationBadgeRow>
          ) : null}
          {isMobileMapView ? (
            <SaveAndCancelButton
              after={<IconMaterial color="$white" icon="chevron_right" />}
              data-auto-testid={choosePcpLocationTestId}
              data-testid={choosePcpLocationTestId}
              isDisabled={!selected}
              onClick={() => setIsMobileMapView(false)}
            >
              {t('CHOOSE_PCP_FLOW.SAVE_LOCATION')}
            </SaveAndCancelButton>
          ) : null}
        </LocationDetailsStack>
      </LocationCardGroup>
    </div>
  );
};
