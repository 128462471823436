import { Alert } from '@abyss/web/ui/Alert';
import { I18nProvider } from '@abyss/web/ui/I18nProvider/I18nProvider';
import find from 'lodash/find';
import React, { useContext, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { adobeModalCloseTrackEvent } from '../../../../common/AdobeTagging/adobeModalCloseTrackEvent';
import { Constants, FilterCollectionModel } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../../common/Utils';
import { getFilterValue } from '../../../../common/Utils/filters.utils';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useSearchStore } from '../../../../store/useSearchStore';
import { SearchState } from '../../../../store/useSearchStore/searchStore';
import { getLagoonConfigValue } from '../../../../utils/providerSearch.utils';

export const ExpandedSearchRadiusBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const uiMessaging = useLagoon(Constants.LAGOON_TABLE.UI_MESSAGING)();
  const { searchRadius } = useSearchStore(
    useShallow((state: SearchState) => ({
      searchRadius: state.searchStore.searchRadius,
    }))
  );

  const { searchFilters } = useContext(SearchFilterContext);
  const distanceFilterValue = getFilterValue(
    searchFilters,
    FilterCollectionModel.DISTANCE
  ) as number;

  const allConfigs = useLagoon('config');
  const defaultSearchRadius = getLagoonConfigValue(
    allConfigs,
    'DEFAULT_SEARCH_RADIUS'
  );
  const providerCountPreference = getLagoonConfigValue(
    allConfigs,
    'MIN_PROVIDER_COUNT_OTHER'
  );
  const expandedSearchRadiusDescription = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.EXPANDED_SEARCH_RADIUS_DESCRIPTION,
  });

  const isEnabledExpandedSearchRadiusBanner = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_EXPANDED_SEARCH_RADIUS_BANNER
  );

  const showExpandedSearchRadiusBanner =
    distanceFilterValue &&
    isEnabledExpandedSearchRadiusBanner &&
    (Number(searchRadius) || 0) > (Number(defaultSearchRadius) || 0);

  return (
    showExpandedSearchRadiusBanner === true && (
      <I18nProvider translations={{ close: 'Close message' }}>
        <Alert
          css={{
            marginBottom: '$md',
            '@media (max-width: 1268px)': {
              width: '98%',
            },
            '@screen < $xl': {
              width: '94%',
            },
          }}
          data-testid="expanded-search-radius-banner"
          isVisible={isVisible}
          onClose={() => {
            adobeModalCloseTrackEvent('close click');
            setIsVisible(false);
          }}
          title={expandedSearchRadiusDescription?.title}
          variant="info"
        >
          {expandedSearchRadiusDescription?.message
            ?.replace('{{count}}', providerCountPreference)
            ?.replace('{{miles}}', searchRadius)}
        </Alert>
      </I18nProvider>
    )
  );
};
