import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import update from 'lodash/update';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setGeoLocationToStorage } from '../../../common/PSXHeader/SearchBar/utils';
import { useLocation } from '../../../hooks/useLocation';
import { refactorPlaceName } from '../../../utils/locationSearch.util';
import {
  FindUserLocationContainer,
  StyledLocationError,
  StyledLocationHeader,
  StyledLocationItems,
  StyledLocationSearch,
} from '../../ProviderSearch/context/SearchLocation/LocationInput.styled';
import { GuestLocation } from './GuestLocationPicker';

type GuestLocationSearchProps = {
  setLocation: (a: GuestLocation) => void;
  location?: GuestLocation;
};

export const GuestLocationInput = ({
  location,
  setLocation,
}: GuestLocationSearchProps) => {
  const { t } = useTranslation();
  const [isOnFocus, setIsOnFocus] = useState(false);

  const [inputVal, setInputVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [gqlSuggestions, setgqlSuggestions] = useState<object[]>([
    {
      section: (
        <div id="instructional-text">
          {'To find care near you, enter a street address or 5 digit zip code.'}
        </div>
      ),
      items: [],
    },
  ]);

  const [, getLocation] = useLocation({
    onCompleted: (result: {
      data: {
        location: {
          features: [{ place_name: string; id: string; zipCode?: string }];
        };
      };
    }) => {
      const resultSuggestions = cloneDeep(result?.data?.location?.features);
      resultSuggestions?.map((loc) =>
        update(loc, 'place_name', () => refactorPlaceName(loc.place_name))
      );
      const findSuggestion =
        resultSuggestions?.length > 0 &&
        resultSuggestions.concat({
          place_name: 'find',
          id: 'find',
        });

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !isEmpty(resultSuggestions)
        ? setgqlSuggestions([
            {
              section:
                'To find care near you, enter a street address or 5 digit zip code.',
              items: findSuggestion,
            },
          ])
        : setgqlSuggestions([
            {
              section:
                'To find care near you, enter a street address or 5 digit zip code.',
              items: [],
            },
            {
              section: t('LOCATION_SEARCH.ERR_DEC_RELEASE'),
              items: [],
            },
          ]);
    },
  });

  const handleLocationChange = async (loc: string) => {
    await getLocation({
      variables: {
        address: encodeURIComponent(loc),
        countySearchEnabled: true,
      },
    });
  };

  const debounceApiFilteringSearch = useCallback(
    debounce(async (inputValue: string) => {
      if (inputValue?.trim().length) {
        await handleLocationChange(inputValue);
      }
      setIsLoading(false);
    }, 300),
    [inputVal]
  );

  const handleClear = () => {
    setgqlSuggestions([
      {
        section:
          'To find care near you, enter a street address or 5 digit zip code.',
        items: [],
      },
    ]);
    setInputVal('');
  };

  const handleLocationInputChange = (inputValue) => {
    if (inputValue?.trim().length) {
      setIsLoading(true);
      setInputVal(inputValue);
    } else {
      handleClear();
    }
  };

  const handleSearch = (newLocation) => {
    if (newLocation.place_name === 'find') {
      // findUserLocation();
    } else {
      setLocation({
        latitude: newLocation.center[0],
        longitude: newLocation.center[1],
        label: newLocation.place_name,
        zipcode: newLocation.zipCode,
      });
      {
        const {
          id: locationId,
          center = [],
          place_name: placeName,
          stateCode: targetStateCode,
          zipCode: targetZipCode,
        } = newLocation;
        const [selectedLong, selectedLat] = center;
        setGeoLocationToStorage({
          id: locationId,
          name: placeName,
          longitude: selectedLong,
          latitude: selectedLat,
          stateCode: targetStateCode,
          zipCode: targetZipCode,
        });
      }
    }
  };

  return (
    <StyledLocationSearch
      apiFiltering={debounceApiFilteringSearch}
      css={{
        '&.abyss-search-input-root': {
          border: '0px',
        },
      }}
      customRender={(suggestion) =>
        // eslint-disable-next-line no-nested-ternary
        suggestion.section ? (
          suggestion.section !== t('LOCATION_SEARCH.ERR_DEC_RELEASE') &&
          suggestion.section !== t('LOCATION_SEARCH.ERROR') ? (
            <StyledLocationHeader>
              {suggestion.section}
              {!inputVal.includes('Search') && suggestion.items?.length > 0
                ? t(`LOCATION_SEARCH.DID_YOU_MEAN`)
                : ''}
            </StyledLocationHeader>
          ) : (
            <StyledLocationError alignItems="top">
              <IconMaterial color="$error1" icon="error" size="$sm" />
              <Text color="$error1" size="14.22px">
                {suggestion.section}
              </Text>
            </StyledLocationError>
          )
        ) : suggestion.place_name !== 'find' ? (
          <StyledLocationItems grow space={0}>
            <Text color="$accent1" fontWeight="$bold" size="$sm">
              {suggestion.place_name}
            </Text>
          </StyledLocationItems>
        ) : (
          <StyledLocationItems>
            <FindUserLocationContainer
              data-auto-testid="find-user-location-clickable-element"
              data-testid="find-user-location-clickable-element"
              onClick={() => {
                handleClear();
                findUserLocation();
              }}
            >
              <Layout.Group space={4}>
                <IconMaterial
                  css={{ marginRight: '10px' }}
                  icon="my_location"
                />
                <Text color="$interactive1" fontWeight="$bold" size="sm">
                  {t('Find my location')}
                </Text>
              </Layout.Group>
            </FindUserLocationContainer>
          </StyledLocationItems>
        )
      }
      hideLabel
      inputLeftElement={
        <IconMaterial color="$gray8" icon="location_on" size={24} />
      }
      isLoading={isLoading}
      keys={['value', 'location']}
      label={t('Location')}
      onClear={handleClear}
      onFocus={() => setIsOnFocus(true)}
      onInputChange={handleLocationInputChange}
      onSearch={handleSearch}
      openOnFocus
      options={gqlSuggestions}
      placeholder={
        location?.label || t('LOCATION_SEARCH.PLACEHOLDER_DECEMBER_RELEASE')
      }
      rounded
      value={location?.label}
    />
  );
};
