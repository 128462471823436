import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { ProviderTypes } from '../../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../../../common/ConstantsStyles';
import { LinkWithTracking } from '../../../../../../common/LinkWithTracking/LinkWithTracking';
import { getGeoLocationFromStorage } from '../../../../../../common/PSXHeader/SearchBar/utils';
import { getCurrentDateWithDashFormatting } from '../../../../../../common/Utils/datesUtils';
import { getFeatureFlag } from '../../../../../../common/Utils/getFeatureFlag';
import { useLagoon } from '../../../../../../hooks/useLagoon';
import { useSendEmail } from '../../../../../../hooks/useSendEmail';
import { useShareAllResults } from '../../../../../../hooks/useShareAllResults/useShareAllResults';
import { EducationInformation } from '../../../../../../models/Provider';
import { useChipStore } from '../../../../../../store/useChipStore';
import { ChipState } from '../../../../../../store/useChipStore/chipStore';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
  getRulesPackageKey,
} from '../../../../../../utils/user.utils';
import { CompareCheckboxInfo } from '../../CompareDrawer/utility/compareDrawerConstants';
import {
  adobeShareProviders,
  getEmailBodyHTMLHelper,
  getEmailBodyTextHelper,
} from '../utils';
import { ShareDrawerButtons } from './ShareDrawerButtons';
import {
  CopyLabelLink,
  InputBoxCss,
  MessageLabel,
  MessageLabelLink,
  ShareContainerInner,
  StyledDiv,
} from './ShareDrawerContents.styled';
import { ShareDrawerEmailInputs } from './ShareDrawerEmailInputs';

type Props = {
  compareCheckboxes: CompareCheckboxInfo[];
  openShareContents: boolean;
  setOpenShareContents: (a: boolean) => void;
  showSuccessAlertCallback: () => void;
  isShareAllResults: boolean;
  providerType: string;
  searchKeyword: string;
  specialtyCode: string;
  selectedProviderList: {
    providerName: string;
    providerId: string;
    speciality: string;
    networkStatus: string;
    languagesSpoken: string[];
    acceptingNewPatients: boolean;
    gender: string;
    virtualCareOffered: boolean;
    distance: string;
    isTieredProvider: boolean;
    educationInfo: EducationInformation;
    hoursOfOperation: {};
    premiumCareProvider: boolean;
    hasPCPServiceAvailability: boolean;
    address: {
      line: string[];
      city: string;
      district: string;
      state: string;
      postalCode: string;
    };
    phones: {
      phone: string[];
    };
  }[];
  isOriginCompareProviders: boolean;
};

export const ShareContentsInnerDrawer = ({
  compareCheckboxes,
  openShareContents,
  setOpenShareContents,
  showSuccessAlertCallback,
  isShareAllResults,
  providerType,
  searchKeyword,
  specialtyCode,
  selectedProviderList,
  isOriginCompareProviders,
}: Props) => {
  const { t } = useTranslation();
  const form = useForm();
  const mobile = useMediaQuery(phoneOnly);
  const [emailOne, setEmailOne] = useState('');
  const [emailTwo, setEmailTwo] = useState('');
  const [emailThree, setEmailThree] = useState('');
  const [emailFour, setEmailFour] = useState('');
  const [emailFive, setEmailFive] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const { coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
    }))
  );

  const tierBenefits = useLagoon('tier-benefit')();
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const enableTier1FromMemberRPK = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER1_FROM_MEMBER_RPK
  );

  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const formatEmails = () => {
    const emails = [emailOne];
    if (emailTwo) emails.push(emailTwo);
    if (emailThree) emails.push(emailThree);
    if (emailFour) emails.push(emailFour);
    if (emailFive) emails.push(emailFive);
    return emails;
  };

  const { longitude, latitude } = getGeoLocationFromStorage();

  const currentMember = getCurrentMember();
  const [, sendEmail] = useSendEmail({});
  const [, shareAll] = useShareAllResults({});

  const subjectText: string = (() => {
    switch (providerType?.toLowerCase()) {
      case ProviderTypes.PRACTITIONER.toLowerCase():
        return t('SHARE_PROVIDERS.PROVIDER');
      case ProviderTypes.ORGANIZATION.toLowerCase():
        return t('SHARE_PROVIDERS.FACILITY');
      default:
        return t('SHARE_PROVIDERS.PROVIDER_GROUP');
    }
  })();

  const subjectTextForCompare = () => t('SHARE_PROVIDERS.COMPARE_PROVIDERS');
  const shareTitle: string = `${subjectText} ${searchKeyword} ${t(
    'SHARE_PROVIDERS.SEARCH_ON'
  )} ${getCurrentDateWithDashFormatting()}`;

  const shareTitleForCompare = `${subjectTextForCompare()} ${getCurrentDateWithDashFormatting()}`;

  const getIsChecked = (editCheckItems: string, providerInformation: string) =>
    compareCheckboxes.find((item) => item.name === editCheckItems)?.isChecked
      ? `${providerInformation}`
      : '';

  const getEmailBodyText = () =>
    getEmailBodyTextHelper({
      shareTitle,
      isOriginCompareProviders,
      shareTitleForCompare,
      selectedProviderList,
      getIsChecked,
    });

  const getEmailBodyHTML = () =>
    getEmailBodyHTMLHelper({
      isOriginCompareProviders,
      selectedProviderList,
      shareTitle,
      shareTitleForCompare,
      getIsChecked,
    });

  const handleSendEmailDesktop = async () => {
    const memCatergory = currentMember?.membershipCategory || '';
    const membershipCategory = uspToggleFlag ? memCatergory : null;
    if (isShareAllResults) {
      await shareAll({
        variables: {
          latitude,
          longitude,
          providerType,
          lob: currentMember?.lineOfBusiness,
          membershipCategory,
          coverages: getCoverageTypes(currentMember),
          planYear: getCurrentPlanYear(),
          planVariationCode: getPlanVariationCode(currentMember, coverageType),
          claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
            currentMember,
            coverageType
          )?.claimSystemTypeCode,
          eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
            currentMember,
            coverageType
          )?.eligibilitySystemTypeCode,
          policyID: currentMember?.policyNumber,
          rulesPackageKey: getRulesPackageKey(
            currentMember,
            tierBenefits,
            providerType,
            uspTierOneFlag,
            enableTier1FromMemberRPK
          ),
          reciprocityId: getNetworkIdsForPES(
            currentMember,
            coverageType,
            featureFlags
          ),
          sendEmail: true,
          linkToSearchResultsPage: window.location.href,
          shareTitle,
          emailAddresses: formatEmails(),
          searchKeyword,
          includeSpecialityRollupCodes: [specialtyCode],
        },
      });
    } else {
      await sendEmail({
        variables: {
          recipientEmails: formatEmails(),
          subject: shareTitle,
          text: '',
          htmlText: getEmailBodyHTML(),
        },
      });
    }
  };

  const onSubmit = () => {
    showSuccessAlertCallback();
  };

  return (
    <React.Fragment>
      <FormProvider
        data-auto-testid="share-email-form"
        data-testid="share-email-form"
        onSubmit={onSubmit}
        state={form}
      >
        <ShareContainerInner>
          <Box
            css={{
              'abyss-box-root': {
                backgroundColor: 'transparent',
                padding: mobile ? '5px 0px 5px 0px' : '0px',
              },
            }}
          >
            <Flex alignItems="left" justify="space-between">
              <Heading
                color="$gray9"
                data-auto-testid="recipient-heading"
                data-testid="recipient-heading"
                offset={6}
              >
                {t('Recipient (add up to 5)')}
              </Heading>
              <IconMaterial
                data-auto-testid="button-share-inner-drawer-close"
                data-testid="button-share-inner-drawer-close"
                icon="close"
                onClick={() => {
                  adobeLinkTrackEvent({
                    name: 'close',
                    location: `modal:${adobeShareProviders}`,
                    type: 'internal',
                  });
                  setOpenShareContents(!openShareContents);
                }}
                size="$md"
              />
            </Flex>
          </Box>
        </ShareContainerInner>

        {mobile ? (
          <Layout.Stack
            css={{ marginTop: '12px', width: '100%' }}
            grow
            justifyContent="start"
          >
            <StyledDiv>
              <ShareDrawerEmailInputs
                emailFive={emailFive}
                emailFour={emailFour}
                emailOne={emailOne}
                emailThree={emailThree}
                emailTwo={emailTwo}
                setEmailFive={setEmailFive}
                setEmailFour={setEmailFour}
                setEmailOne={setEmailOne}
                setEmailThree={setEmailThree}
                setEmailTwo={setEmailTwo}
              />
              <ShareDrawerButtons
                handleSendEmailDesktop={handleSendEmailDesktop}
                openShareContents={openShareContents}
                setOpenShareContents={setOpenShareContents}
              />
            </StyledDiv>
          </Layout.Stack>
        ) : (
          <Layout.Group
            alignItems="start"
            css={{ marginTop: '12px', width: '100%' }}
            grow
          >
            <StyledDiv>
              <ShareDrawerEmailInputs
                emailFive={emailFive}
                emailFour={emailFour}
                emailOne={emailOne}
                emailThree={emailThree}
                emailTwo={emailTwo}
                setEmailFive={setEmailFive}
                setEmailFour={setEmailFour}
                setEmailOne={setEmailOne}
                setEmailThree={setEmailThree}
                setEmailTwo={setEmailTwo}
              />
              <ShareDrawerButtons
                handleSendEmailDesktop={handleSendEmailDesktop}
                openShareContents={openShareContents}
                setOpenShareContents={setOpenShareContents}
              />
            </StyledDiv>
            <StyledDiv>
              <TextInputArea
                css={InputBoxCss}
                data-auto-testid="message-text"
                data-testid="message-text"
                disableAutoSize
                label={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginBottom: '10px',
                    }}
                  >
                    <Text
                      css={MessageLabel}
                      data-auto-testid="message-heading"
                      data-testid="message-heading"
                    >
                      {t('SHARE_INNER_DRAWER_TEXT.MESSAGE')}
                    </Text>
                    {isCopied ? (
                      <Flex direction="row">
                        <IconMaterial
                          color="$success1"
                          icon="check_circle"
                          size="15px"
                        />
                        <Text css={CopyLabelLink}>
                          {t('SHARE_INNER_DRAWER_TEXT.COPIED')}
                        </Text>
                      </Flex>
                    ) : (
                      <LinkWithTracking
                        analyticsInfo={{
                          name: 'copy text',
                          location: `modal:${adobeShareProviders}`,
                          type: 'internal',
                        }}
                        before={
                          <IconMaterial icon="content_copy" size="18px" />
                        }
                        css={MessageLabelLink}
                        data-auto-testid="share-inner-element-text"
                        data-testid="share-inner-element-text"
                        onClick={() => {
                          copy(getEmailBodyHTML(), { format: 'text/html' });
                          setIsCopied(true);
                        }}
                      >
                        {t('SHARE_INNER_DRAWER_TEXT.COPY_TEXT')}
                      </LinkWithTracking>
                    )}
                  </div>
                }
                onChange={() => {}}
                value={getEmailBodyText()}
              />
            </StyledDiv>
          </Layout.Group>
        )}
      </FormProvider>
    </React.Fragment>
  );
};
