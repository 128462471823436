import {
  Coverage,
  CoverageEffDateRange,
  Demographics,
  Eligibility,
  MemberCoverage,
  MemberInfo,
  Name,
  PortalMemberInfo,
  TypeCode,
} from './PortalMemberInfo';

const name: Name = {
  firstName: 'Fnzmrnxbkffy',
  lastName: 'Lnydyxachk',
};

const demographics: Demographics = {
  name,
  dateOfBirth: '1978-04-25',
  dependentSeqNbr: '00',
  address: [
    {
      addressType: 'PERMANENT',
      address1: '1111 NEW STREET',
      city: 'CYPRESS',
      state: 'CA',
      zipCode: '90630',
    },
  ],
};

const coverageEffDateRange: CoverageEffDateRange = {
  startDate: '2022-11-01',
  endDate: '2099-12-31',
};

const typeCode: TypeCode = {
  desc: 'MEDICAL',
  coverageTypeCode: 'M',
};

const coverageType: Coverage = {
  coverageEffDateRange,
  planVariationCode: '0001',
  typeCode,
  reciprocityId: '52',
};

const behavioralCoverageType: Coverage = {
  coverageEffDateRange,
  planVariationCode: '0001',
  typeCode: {
    desc: 'BEHAVIORAL HEALTH',
    coverageTypeCode: 'B',
  },
  reciprocityId: '',
};

const memberHealthCoverage: MemberCoverage = {
  coverageType: [coverageType, behavioralCoverageType],
};

const eligibility: Eligibility = {
  memberHealthCoverage,
};

const authMemberInfo: MemberInfo = {
  population: '',
  demographics,
  eligibility: [eligibility],
  lineOfBusiness: 'E&I',
  policyNumber: '0915181',
  reciprocityId: '52',
  viewPcp: true,
  editPcp: true,
};

export const MockAuthPortalMemberInfo: PortalMemberInfo = {
  memberInfo: [authMemberInfo],
};
