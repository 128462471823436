import { useForm } from '@abyss/web/hooks/useForm';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { TextInput } from '@abyss/web/ui/TextInput';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../../../hooks/useLagoon';
import { adobeSearchFilterEvent } from '../../../AdobeTagging/adobeSearchFilterEvent';
import { Constants } from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import {
  ErrorTextContainer,
  SelectInputButton,
  providerSearchContainer,
  searchInputStyle,
  selectInputContainerStyle,
} from './DirectorySearchTypeInput.styles';

type Props = {
  setProviderNameToSearch: (a: string) => void;
  setSpecialityToSearch: (a: string) => void;
  setSearchType: (a: string) => void;
  specialityToSearch: String;
  searchType: string;
  firstPage: () => void;
};

export const DirectorySearchTypeInput = ({
  setSpecialityToSearch,
  setProviderNameToSearch,
  specialityToSearch,
  searchType,
  setSearchType,
  firstPage,
}: Props) => {
  const { t } = useTranslation();
  const form = useForm();

  const [isSearchLinkEnabled, setIsSearchLinkEnabled] = useState(false);
  const [isErrorEnabled, setIsErrorEnabled] = useState(false);
  const data = useLagoon('ui-messaging')();
  const searchInputLabel = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.PHYSICIAN_DIRECTORY_SEARCH_INPUT_LABEL,
  });
  const searchInputError = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.PHYSICIAN_DIRECTORY_SEARCH_ERROR,
  });
  const searchInputByNameLabel = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS
      .PHYSICIAN_DIRECTORY_SEARCH_INPUT_BY_NAME_LABEL,
  });
  const searchInputErrorBySpecialtyLabel = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS
      .PHYSICIAN_DIRECTORY_SEARCH_INPUT_BY_SPECIALITY_LABEL,
  });
  const searchInputOptions = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS
      .PHYSICIAN_DIRECTORY_SEARCH_INPUT_OPTIONS,
  });
  const searchOptions = searchInputOptions?.message || '';

  useEffect(() => {
    if (specialityToSearch === '') {
      form.reset();
      setIsSearchLinkEnabled(false);
    }
  }, [specialityToSearch]);

  const onSearchInputChange = (e) => {
    const { value } = e.target;
    form?.setValue('physician-directory-search', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (value && value.length >= 2) {
      setIsSearchLinkEnabled(true);
      setIsErrorEnabled(false);
    } else {
      setIsSearchLinkEnabled(false);
      if (value.length === 0) {
        onSearch();
      }
    }
    findValidationErrorMessage(value);
  };

  const findValidationErrorMessage = (input) => {
    if (input === '' || input.length === 0) {
      setIsErrorEnabled(false);
    }
    if (input && input.length < 2) {
      setIsErrorEnabled(true);
    }
    if (!Constants.INPUT_MODELS.NUMBERS_REGEX_PATTERN.test(input)) {
      setIsErrorEnabled(true);
      setIsSearchLinkEnabled(false);
    }
    if (
      !Constants.INPUT_MODELS.PHYSICIAN_DIRECTORY_SEARCH_REGEX_PATTERN.test(
        input
      )
    ) {
      setIsErrorEnabled(true);
      setIsSearchLinkEnabled(false);
    }
    return undefined;
  };

  const onSearch = () => {
    const inputValue = form.getValues('physician-directory-search');
    if (!findValidationErrorMessage(inputValue)) {
      if (searchType?.trim() === t('Name')) {
        if (inputValue.length !== 1) firstPage();
        setProviderNameToSearch(inputValue);
        adobeSearchFilterEvent({
          type: 'provider',
          term: inputValue,
        });
      } else if (searchType?.trim() === t('Speciality')) {
        setSpecialityToSearch(inputValue);
        adobeSearchFilterEvent({
          type: 'speciality',
          term: inputValue,
        });
      }
    }
  };

  useEffect(() => {
    form?.setValue('physician-directory-search', '', {
      shouldValidate: true,
      shouldDirty: true,
    });
    setProviderNameToSearch('');
    if (specialityToSearch.length > 0) {
      firstPage();
    }
  }, [searchType, specialityToSearch]);

  return (
    <React.Fragment>
      <Flex css={providerSearchContainer}>
        <Flex css={selectInputContainerStyle}>
          <SelectInputButton
            data-testid="provider-search-type"
            label={searchInputLabel?.message || t('Search providers by')}
            onChange={setSearchType}
            onClear={() => setSearchType('')}
            options={[
              {
                value: searchOptions.split(',')[0],
                label: searchOptions.split(',')[0],
              },
              {
                value: searchOptions.split(',')[1],
                label: searchOptions.split(',')[1],
              },
            ]}
            value={t(searchType)}
          />
        </Flex>
        <FormProvider state={form}>
          <TextInput
            css={searchInputStyle}
            data-auto-testid="physician-directory-search"
            data-testid="physician-directory-search"
            formatter={(value) => value.replace(/[<>'&/"]/g, '')}
            inputLeftElement={<IconMaterial color="$black" icon="search" />}
            inputRightElement={
              isSearchLinkEnabled && (
                <Button
                  data-auto-testid="physician-directory-search-link"
                  data-testid="physician-directory-search-link"
                  onClick={() => {
                    onSearch();
                  }}
                  variant="tertiary"
                >
                  {t('Search')}
                </Button>
              )
            }
            label={
              searchType === t('Name')
                ? searchInputByNameLabel?.message ||
                  t('FACILITY_DETAILS.SEARCH_INPUT_BY_NAME_LABEL')
                : searchInputErrorBySpecialtyLabel?.message ||
                  t('FACILITY_DETAILS.SEARCH_INPUT_BY_SPECIALITY_LABEL')
            }
            model="physician-directory-search"
            onChange={onSearchInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && isSearchLinkEnabled) onSearch();
            }}
            // onKeyDown={() => onSearch()}
            placeholder={
              searchType === t('Name')
                ? searchOptions.split(',')[0]
                : searchOptions.split(',')[1]
            }
          />
          {isErrorEnabled && (
            <Flex
              alignItems="top"
              css={{ marginTop: '8px' }}
              data-testid="physician-directory-search-invalid-suggestion"
            >
              <ErrorTextContainer color="$error1" role="alert">
                {searchInputError?.message || ''}
              </ErrorTextContainer>
            </Flex>
          )}
        </FormProvider>
      </Flex>
    </React.Fragment>
  );
};
