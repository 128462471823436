import { config } from '@abyss/web/tools/config';
import { RouterProvider } from '@abyss/web/ui/RouterProvider';
import { useEffect } from 'react';

import { Configuration } from '../ProviderSearch/context/Configuration';
import { createRouter } from '../ProviderSearchForPortal/createRouter';
import { GuestRoutes } from './GuestRoutes';

const router = createRouter(GuestRoutes, '/guest');

// TODO: call createGuestSession endpoint to get cookie needed to authorize GQL calls

const callGuestSessionUrl = async () => {
  const createGuestSessionUrl = `${config(
    'GUEST_BASE_URL'
  )}/api/create-guest-session`;
  await fetch(createGuestSessionUrl);
};

export const GuestExperience = () => {
  useEffect(() => {
    callGuestSessionUrl();
  }, []);

  return (
    <Configuration>
      <RouterProvider router={router} />
    </Configuration>
  );
};
