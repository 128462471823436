import {
  Constants,
  CoverageTypesCodes,
  PopulationType,
  UpdatePcpErrorResponse,
} from '../common/Constants';
import { convertObjectToUrlParams } from '../common/Utils';
import {
  isCnsMember,
  isMnrMember,
} from '../common/Utils/memberUtils/memberUtils';
import { getMemberHealthCoverageType } from './providerSearch.utils';

export const getPCPGetParams = (
  enablePreProd: boolean,
  loggedInMember,
  memberId: string
) => {
  const {
    demographics,
    eligibility,
    familyId,
    isGatedUser,
    isNonGatedUSPUser,
    lineOfBusiness,
    membershipCategory,
    policyNumber,
    population,
    reciprocityId,
  } = loggedInMember;

  const { name, dateOfBirth, dependentSeqNbr } = demographics;

  const medicalCoverage =
    getMemberHealthCoverageType(eligibility, CoverageTypesCodes.MEDICAL) || {};
  const {
    claimSystemTypeCode,
    coverageID,
    eligibilitySystemTypeCode,
    encryptedUserNameWithPlanId,
    marketType,
    planVariationCode,
  } = medicalCoverage;

  const coverageId = coverageID?.find(
    (cov) => cov.sourceSysCode === 'SUBSCRIBER ID'
  );

  return {
    firstName: name.firstName,
    lastName: name.lastName,
    groupNumber: policyNumber,
    memberId,
    claimSystemTypeCode,
    dateOfBirth,
    dependentSeqNbr,
    eligibilitySystemTypeCode,
    enablePreProd,
    familyId,
    id: encryptedUserNameWithPlanId,
    isGatedUser,
    isNonGatedUSPUser,
    lob: lineOfBusiness,
    membershipCategory,
    planVariationCode,
    population,
    portalSource: marketType,
    reciprocityId,
    userName: coverageId?.id,
  };
};

export const getPCPUpdateParams = (
  dependentSeqNbr: string | undefined,
  loggedInMember,
  memberByDependentSeqNbr,
  memberId: string,
  pcpId: string,
  providerType: string,
  providerName: string
) => {
  const { demographics: loggedInDemographics, population } = loggedInMember;
  const { dependentSeqNbr: loggedInDepSeqNbr } = loggedInDemographics;
  const {
    demographics,
    eligibility,
    familyId,
    isGatedUser,
    isNonGatedUSPUser,
    lineOfBusiness,
    policyNumber,
  } = memberByDependentSeqNbr;
  const { dateOfBirth, name } = demographics;
  const { firstName, lastName } = name;

  const medicalCoverage =
    getMemberHealthCoverageType(eligibility, CoverageTypesCodes.MEDICAL) || {};
  const { coverageID, encryptedUserNameWithPlanId, marketType } =
    medicalCoverage;

  const coverageId = coverageID?.find(
    (cov) => cov.sourceSysCode === 'SUBSCRIBER ID'
  );

  return {
    firstName,
    lastName,
    dateOfBirth,
    groupNumber: policyNumber,
    memberId,
    dependentSeqNbr,
    familyId,
    pcpId,
    pcpName: providerName,
    submittedByDependentSequenceNumber: loggedInDepSeqNbr,
    providerType,
    isGatedUser,
    isNonGatedUSPUser,
    id: encryptedUserNameWithPlanId,
    lob: lineOfBusiness,
    portalSource: marketType,
    userName: coverageId?.id,
    population,
  };
};

// Returns the lagoon key value to pull error modal content from the Update PCP Error Modal table
export const getErrorModalContentKey = (
  populationType: PopulationType,
  errorCode: string = '',
  errorMessage: string = ''
): string => {
  const message = errorMessage?.toLowerCase();
  if (populationType === PopulationType.USP) {
    switch (errorCode) {
      case '50127':
        return '3';
      default:
        return '2';
    }
  }

  if (
    (errorCode === '000' &&
      message === UpdatePcpErrorResponse.noPrimaryCareRecords) ||
    (errorCode === '500352' &&
      message === UpdatePcpErrorResponse.noPcpNetworkFound)
  ) {
    return '1';
  }

  switch (errorCode) {
    case '40423':
    case '20003':
      return '1';
    default:
      return '2';
  }
};

export type UpdatePCPErrorContent = {
  dntPopulation?: string;
  dntObapiErrorCode?: string;
  dntObapiErrorResponse?: string;
  dntUpdatePcpErrorModalTableKey?: string;
};

export const getErrorMessageKeyUsingPCPErrorMapping = (
  population: PopulationType,
  errorCode: string = '',
  errorMessage: string = '',
  errorTableContent?: UpdatePCPErrorContent[]
) => {
  if (errorTableContent == null || errorTableContent.length === 0) {
    return getErrorModalContentKey(population, errorCode, errorMessage);
  }

  // Filter error content by population and if there's a match, return the error message key
  const errorContentMatchesByPopulation = errorTableContent.filter(
    (item) => item.dntPopulation?.toLowerCase() === population.toLowerCase()
  );
  if (errorContentMatchesByPopulation != null && errorContentMatchesByPopulation.length > 0) {
    const errorMessageKey = getErrorMessageKey(
      errorCode,
      errorMessage,
      errorContentMatchesByPopulation
    );
    if (errorMessageKey) {
      return errorMessageKey;
    }
  }

  // If there were no error matches from the population filter, filter by dntPopulation column not defined/empty and return an error message key
  const errorContentWithUndefinedDntPopulation = errorTableContent.filter(
    (item) =>
      item.dntPopulation === '' ||
      item.dntPopulation === undefined ||
      item.dntPopulation === null
  );

  return (
    getErrorMessageKey(
      errorCode,
      errorMessage,
      errorContentWithUndefinedDntPopulation
    ) ?? '2'
  );
};

const getErrorMessageKey = (
  errorCode: string,
  errorResponse: string,
  errorTableContent: UpdatePCPErrorContent[]
) => {
  const matchesByErrorCode = errorTableContent.filter(
    (item) => item.dntObapiErrorCode === errorCode
  );

  const matchByErrorResponse = matchesByErrorCode.find(
    (item) =>
      item.dntObapiErrorResponse?.toLowerCase() === errorResponse.toLowerCase()
  );
  if (matchByErrorResponse) {
    return matchByErrorResponse.dntUpdatePcpErrorModalTableKey;
  }

  const emptyErrorResponseMatch = matchesByErrorCode.find(
    (item) => item.dntObapiErrorResponse === '' || item.dntObapiErrorResponse == null
  );
  if (emptyErrorResponseMatch) {
    return emptyErrorResponseMatch.dntUpdatePcpErrorModalTableKey;
  }

  const defaultErrorCodeMatch = errorTableContent.find(
    (item) => item.dntObapiErrorCode?.toLowerCase() === 'default'
  );
  return defaultErrorCodeMatch?.dntUpdatePcpErrorModalTableKey ?? null;
};

export const getShowEditPCP = (editPcp, lockInIndicator) =>
  editPcp && !lockInIndicator;

export const getIsFuturePCPCardSeparate = (lob) =>
  isMnrMember(lob) || isCnsMember(lob);

export const showFuturePCPText = (lob, futurePCP, isPreEffective) =>
  !!futurePCP && !isPreEffective && !getIsFuturePCPCardSeparate(lob);

export const showFuturePCPCard = (lob, futurePCP, isPreEffective) =>
  !!futurePCP && !isPreEffective && getIsFuturePCPCardSeparate(lob);

export const doChoosePCPStoreUpdate = (
  dependentSeqNbr,
  chipStore,
  setTypeaheadSearchStore,
  setPCPSearchState
) => {
  const typeaheadStoreValues = {
    search: Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS,
    searchType: Constants.SEARCH_TYPES.SPECIALTY,
    providerType: '',
  };
  setTypeaheadSearchStore({
    ...typeaheadStoreValues,
    resultType: Constants.RESULT_SECTION.ALL,
    acceptingNewPatients: true,
  });
  const pcpStoreValues = {
    choosePCP: true,
    dependentSeqNbr,
    pcpIndicator: true,
  };
  setPCPSearchState(pcpStoreValues);

  return convertObjectToUrlParams(chipStore, {
    ...typeaheadStoreValues,
    ...pcpStoreValues,
  });
};
