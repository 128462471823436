import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';

import { LOB, ReverseCoverageTypesCodes } from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { phoneOnly } from '../../common/ConstantsStyles';
import {
  isCnsMember,
  isMnrCosmosMember,
} from '../../common/Utils/memberUtils/memberUtils';
import { suppressFiltersByFeatureAndPolicy } from '../../common/Utils/suppressFiltersByFeatureAndPolicy';
import {
  returnInclusionFlag,
  returnSuppressFlag,
} from '../../utils/featureSuppress';
import {
  portalShouldShowOnlineScheduling,
  portalShouldShowVirtualVisits,
} from '../../utils/portalConfig.utils';
import { showECP } from '../../utils/providerDetails.utils';
import {
  getCurrentMember,
  getMemberCoverageTypeSitusState,
} from '../../utils/user.utils';
import { useCoverageType } from '../useCoverageType';
import { useCurrentMemberIsPreEffective } from '../useCurrentMemberIsPreEffective';
import { useFeatureFlag } from '../useFeatureFlag';
import { getFiltersArray } from './providerFilters.utils';

const BEHAVIORAL_HEALTH = ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];
const { MEDICAL } = ReverseCoverageTypesCodes;

export const useProviderFilters = () => {
  const { t } = useTranslation();
  const currentMember = getCurrentMember();
  const { lineOfBusiness, population } = currentMember;
  const situsState = getMemberCoverageTypeSitusState(currentMember);
  const isPreEffective = useCurrentMemberIsPreEffective();
  const isMnr = isMnrCosmosMember(lineOfBusiness, population);
  const isIfp = currentMember.isIFPMember;
  const isIFPTieredPlan = currentMember.isIFPTieredPlan;
  const isCns = isCnsMember(lineOfBusiness);
  const coverageType = useCoverageType();
  const isMobile = useMediaQuery(phoneOnly);

  const [
    isTiered2PlanFilterEnable,
    isAccommodateFilterEnabled,
    isACOFilterDisabled,
    isCulturalCompetencyFlag,
    isDistanceSlider,
    isPreferredFacilityDisabled,
    isVirtualCareFilterEnabled,
    upcomingAvailability,
    upcomingAvailabilityForMedical,
    upcomingAvailabilityForBh,
    isBehavioralFacilityFilterEnabled,
    isBoardCertifiedFilterEnabled,
    isPremiumCareChipFilterEnable,
    enableBhVirtualCareFilterV2,
  ] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIERED2_PLAN_FILTER,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_ACCOMMODATE_FILTER,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_ACO_FILTER,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CULTURAL_COMPETENCY_CODES_FILTER,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_DISTANCE_FILTER_SLIDER,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_PREFERRED_FACILITY_FILTER,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_VIRTUAL_CARE_BH_FILTER,
    ConstantsLagoon.FEATURE_FLAGS.UPCOMING_AVAILABILITY,
    ConstantsLagoon.FEATURE_FLAGS.UPCOMING_AVAILABILITY_FOR_MEDICAL,
    ConstantsLagoon.FEATURE_FLAGS.UPCOMING_AVAILABILITY_FOR_BH,
    ConstantsLagoon.FEATURE_FLAGS.BEHAVIORAL_FACILITY_PROGRAMS_FILTER,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BOARD_CERTIFIED_PROVIDERS,
    ConstantsLagoon.FEATURE_FLAGS.SHOW_PREMIUM_CARE_CHIP_FILTER,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_VIRTUAL_CARE_FILTER_V2,
  ]);

  const suppressVirtualCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_EMPIRE
  );

  const suppressDocAsap = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.SUPPRESS_DOC_ASAP
  );

  const isVirtualCareQuickCareSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_QUICK_CARE
  );

  const isVirtualCareUnetRailroadSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_RAILROAD
  );

  const isSuppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const suppressVirtualCareRailRoad =
    isVirtualCareQuickCareSuppressed ||
    (coverageType !== ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
      isVirtualCareUnetRailroadSuppressed);

  const includeDocAsap =
    lineOfBusiness === LOB.CNS &&
    returnInclusionFlag(
      ConstantsLagoon.FEATURE_INCLUSION_FLAGS.INCLUDE_DOC_ASAP
    );

  const upComingAvailabilityValidCoverages: string[] = [];
  if (upcomingAvailabilityForMedical && !upcomingAvailability) {
    upComingAvailabilityValidCoverages.push(MEDICAL);
  }
  if (upcomingAvailabilityForBh) {
    upComingAvailabilityValidCoverages.push(BEHAVIORAL_HEALTH);
  }

  const toShowDistanceSlider = !isMobile && isDistanceSlider;
  const showOnlineScheduling = portalShouldShowOnlineScheduling();
  const portalShowVirtualVisits = portalShouldShowVirtualVisits();
  const ecpEnabled = showECP(lineOfBusiness, situsState);
  const showVirtualCareV2 =
    coverageType == ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
    enableBhVirtualCareFilterV2;

  const virtualCareFilterDisabled =
    !portalShowVirtualVisits ||
    !isVirtualCareFilterEnabled ||
    suppressVirtualCare ||
    suppressVirtualCareRailRoad ||
    showVirtualCareV2;

  const upcomingAvailabilityFilterDisabled =
    !showOnlineScheduling ||
    isPreEffective ||
    upComingAvailabilityValidCoverages.indexOf(coverageType as any) === -1 ||
    (lineOfBusiness === LOB.CNS ? !includeDocAsap : suppressDocAsap);

  const tieredProvidersFilterDisabled = !(
    isTiered2PlanFilterEnable &&
    isIFPTieredPlan &&
    isIfp
  );
  const premiumCarePhysicianFilterDisabled = !(
    isPremiumCareChipFilterEnable && !isSuppressPremiumCare
  );
  const accommodateOfferedFilterDisabled = !(
    isAccommodateFilterEnabled && isCns
  );

  const filters = getFiltersArray(
    t,
    isMnr,
    isIfp,
    isCns,
    isACOFilterDisabled,
    isCulturalCompetencyFlag,
    toShowDistanceSlider,
    virtualCareFilterDisabled,
    isPreferredFacilityDisabled,
    ecpEnabled,
    upcomingAvailabilityFilterDisabled,
    isBehavioralFacilityFilterEnabled,
    isBoardCertifiedFilterEnabled,
    tieredProvidersFilterDisabled,
    premiumCarePhysicianFilterDisabled,
    accommodateOfferedFilterDisabled,
    showVirtualCareV2
  );

  suppressFiltersByFeatureAndPolicy(filters);

  return filters;
};
