import 'react-router-dom';

import { storage } from '@abyss/web/tools/storage';
import { createTheme } from '@abyss/web/tools/theme';
import { OverlayProvider } from '@abyss/web/ui/OverlayProvider';
import { PrintProvider } from '@abyss/web/ui/PrintProvider';
import { RouterProvider } from '@abyss/web/ui/RouterProvider';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { ToastProvider } from '@abyss/web/ui/Toast';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';

import { Constants } from '../../common/Constants';
import { ChipCategoryContextProvider } from '../../context/ChipCategoryContext';
import { CountySearchContextProvider } from '../../context/CountySearchContext';
import {
  PortalContext,
  PortalContextProvider,
} from '../../context/PortalContext';
import { SearchFilterContextProvider } from '../../context/SearchFilterContext';
import { ErrorBoundary } from '../../errors/ErrorBoundary';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { registerEvents } from '../../tools/events';
import {
  setBhReciprocityId,
  setGuestMemberInfo,
} from '../../utils/ohbs/ohbs.utils';
import {
  ConditionalObapiWrapper,
  ConditionalPlanSelectorWrapper,
} from '../../utils/portal.utils';
import { loginNotRequired, validateSession } from '../../utils/session.utils';
import { Analytics } from '../ProviderSearch/context/Analytics';
import { Configuration } from '../ProviderSearch/context/Configuration';
import { DateTimeConfig } from '../ProviderSearch/context/DateTimeConfig';
import { EducationalContentModal } from '../ProviderSearch/context/EducationalContentModal/EducationalContentModal';
import { Internationalization } from '../ProviderSearch/context/Internationalization';
import { LanguageNotSupportedModal } from '../ProviderSearch/context/LanguageNotSupportedModal/LanguageNotSupportedModal';
import { LivePerson } from '../ProviderSearch/context/LivePerson';
import { Obapi } from '../ProviderSearch/context/Obapi/Obapi';
import { PlanSelector } from '../ProviderSearch/context/PlanSelector';
import { SearchLocation } from '../ProviderSearch/context/SearchLocation';
import { TimeoutModal } from '../ProviderSearch/context/TimeoutModal';
import { Routes } from '../ProviderSearch/routes';
import { getTheme } from '../themeOverrides/theme.utils';
import { createRouter } from './createRouter';

export const App = () => {
  const sessionId = useStore(StoreKeys.SESSION_ID);
  const setSessionId = useStore(StoreKeys.SET_SESSION_ID);
  const setMemberState = useStore(StoreKeys.SET_OBAPI_MEMBERS);

  const location = useLocation();
  const isStandAloneExp = loginNotRequired();
  const { portalData } = useContext(PortalContext);

  const isGuestUser = !!portalData.portalConfig.isGuestUser;
  if (!portalData?.portalConfig?.useOBAPI) {
    if (isGuestUser) {
      storage.session.set(Constants.STORAGE_KEYS.SESSION.IS_GUEST, true);
      setGuestMemberInfo(portalData.portalMemberInfo.memberInfo);
    } else {
      storage.session.set(Constants.STORAGE_KEYS.SESSION.IS_GUEST, false);
      setMemberState(portalData.portalMemberInfo?.memberInfo);
    }
  }

  const handleValidateSession = () => {
    if (portalData?.portalConfig?.enableSession) {
      validateSession(sessionId, setSessionId);
    }
  };

  const [, setMemberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );
  const memberIdFromPortal = Number(portalData.portalEligibilityData?.memberId);

  useEffect(() => {
    if (memberIdFromPortal) {
      setMemberId(memberIdFromPortal);
    }
  }, [memberIdFromPortal]);

  useEffect(() => {
    // Validate on window focus
    if (portalData?.portalConfig?.enableSession) {
      window.addEventListener('focus', handleValidateSession);
      return () => {
        window.removeEventListener('focus', handleValidateSession);
      };
    }
    return () => {};
  }, []);

  useEffect(() => {
    // Validate on location change
    handleValidateSession();
  }, [location]);

  const { IS_PSX_VIEWED } = Constants.STORAGE_KEYS.LOCAL;
  useEffect(() => {
    handleValidateSession();
    storage.local.set(IS_PSX_VIEWED, true);
  }, []);

  const themeOverride = getTheme(
    portalData?.portalConfig?.theme,
    portalData?.portalIdentifier,
    portalData?.portalConfig?.themeOverride
  );

  return (
    <ErrorBoundary>
      {/* check if usePlanSelector is true to wrap with plan selector comp */}
      <ConditionalPlanSelectorWrapper
        isUsePlanSelector={portalData.portalConfig?.usePlanSelector}
        wrapper={(children) => <PlanSelector>{children}</PlanSelector>}
      >
        <DateTimeConfig>
          {/* check if useOBAPI is true to wrap with obapi comp */}
          <ConditionalObapiWrapper
            isUseOBAPI={portalData.portalConfig?.useOBAPI}
            wrapper={(children) => <Obapi>{children}</Obapi>}
          >
            <Internationalization>
              <Analytics>
                <PrintProvider>
                  <OverlayProvider>
                    <ThemeProvider
                      // Set includeBaseCss to false when loaded as an Abyss parcel to prevent CSS from bleeding into the parent portal
                      includeBaseCss={
                        portalData.portalConfig?.includeBaseCss || false
                      }
                      theme={createTheme(
                        portalData.portalConfig?.theme || 'uhc',
                        themeOverride
                      )}
                    >
                      {!isStandAloneExp &&
                        portalData?.portalConfig?.enableSession && (
                          <TimeoutModal />
                        )}
                      <ToastProvider
                        containerWidth="26rem"
                        position="top-right"
                      />
                      {!isGuestUser && <EducationalContentModal />}
                      <LanguageNotSupportedModal />
                      <LivePerson />
                      <ChipCategoryContextProvider>
                        <SearchFilterContextProvider>
                          <CountySearchContextProvider>
                            <SearchLocation>
                              <Routes />
                            </SearchLocation>
                          </CountySearchContextProvider>
                        </SearchFilterContextProvider>
                      </ChipCategoryContextProvider>
                    </ThemeProvider>
                  </OverlayProvider>
                </PrintProvider>
              </Analytics>
            </Internationalization>
          </ConditionalObapiWrapper>
        </DateTimeConfig>
      </ConditionalPlanSelectorWrapper>
    </ErrorBoundary>
  );
};

function convertValueToString(obj: any, propertyName: string): void {
  if (obj && propertyName && obj.hasOwnProperty(propertyName)) {
    const value = obj[propertyName];
    obj[propertyName] = typeof value === 'number' ? value.toString() : value;
  }
}

export const ProviderSearchForPortal = (args) => {
  const portalData = JSON.parse(args['portal-data']);

  // Convert members policyNumber and reciprocityId to string if they are numbers
  if (Array.isArray(portalData?.portalMemberInfo?.memberInfo)) {
    portalData.portalMemberInfo.memberInfo.forEach((member: any) => {
      convertValueToString(member, 'policyNumber');
      convertValueToString(member, 'reciprocityId');
      convertValueToString(
        member?.eligibility?.[0]?.memberHealthCoverage?.coverageType[0],
        'reciprocityId'
      );
    });
  }

  setBhReciprocityId(portalData.portalMemberInfo?.memberInfo);
  const { setPortalData } = useContext(PortalContext);
  useEffect(() => {
    const isAuthenticatedUser = portalData.portalConfig.isGuestUser;
    if (isAuthenticatedUser) {
      setPortalData(portalData);
    }

    // defaults to true if not specified
    const loadAdobeScript =
      portalData.portalConfig.loadAdobeScript === undefined ||
      portalData.portalConfig.loadAdobeScript;
    registerEvents(undefined, loadAdobeScript);
  }, []);
  const basepath = portalData.portalConfig.basePath;
  const router = createRouter(App, basepath);
  return (
    <ErrorBoundary>
      <PortalContextProvider portalDataArgs={portalData}>
        <Internationalization>
          <Configuration>
            <RouterProvider router={router} />
          </Configuration>
        </Internationalization>
      </PortalContextProvider>
    </ErrorBoundary>
  );
};
