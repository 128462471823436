import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';

import { LocateMe } from './LocateMe';
import { MapControlContainer } from './MapDisplay.styled';

type Props = {
  map: any;
  controlChildren?: React.ReactNode;
  mobileRouteView?: boolean;
  enableMapEnhancements?: boolean;
  isLocationsTab?: boolean;
};

export const MapControlMobile = ({
  map,
  controlChildren,
  mobileRouteView = false,
  enableMapEnhancements = false,
  isLocationsTab = false,
}: Props) => (
  <MapControlContainer
    alignItems="end"
    css={{
      top: mobileRouteView ? (isLocationsTab ? '260px' : '16px') : '$md',
      right: !enableMapEnhancements ? '$md' : 'auto',
      left: enableMapEnhancements ? (isLocationsTab ? '60%' : '$md') : 'auto',
    }}
    direction={enableMapEnhancements ? 'row' : 'column'}
  >
    {controlChildren}
    <Flex
      css={{
        '@screen < $md': {
          marginLeft: enableMapEnhancements
            ? 'calc((100vw - 10px)/2) '
            : 'auto',
        },
        '@media (max-width: 720px)': {
          marginLeft: enableMapEnhancements
            ? 'calc((100vw - 100px)/2) '
            : 'auto',
        },
        '@media (max-width: 545px)': {
          marginLeft: enableMapEnhancements
            ? 'calc((100vw - 180px)/2) '
            : 'auto',
        },
        '@media (max-width: 464px)': {
          marginLeft: enableMapEnhancements
            ? 'calc((100vw - 200px)/2) '
            : 'auto',
        },
        '@media (max-width: 430px)': {
          marginLeft: enableMapEnhancements
            ? isLocationsTab
              ? ''
              : '50px'
            : 'auto',
        },
      }}
    >
      <LocateMe map={map} />
    </Flex>
  </MapControlContainer>
);
