import { storage } from '@abyss/web/tools/storage';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { CareCategories, Constants } from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import {
  convertTypeaheadProviderIdAndType,
  getTypeAheadCategory,
} from '../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { PortalContext } from '../../context/PortalContext';
import { isMissingParameters } from '../../errors/helpers';
import {
  getRecentSearchVariables,
  getSearchResultsPageParams,
} from '../../frontends/ProviderSearch/routes/SearchResults/SearchResults.UtilsV2';
import {
  UseParallelSearchResults,
  useParallelSearchResultsCategoriesCount,
} from '../../frontends/ProviderSearch/routes/SearchResults/useParallelSearchResultsCategoriesCount';
import { useSearchResultsProviderResults } from '../../frontends/ProviderSearch/routes/SearchResults/useSearchResultsProviderResults';
import { isShowProviderGroups } from '../../frontends/ProviderSearch/routes/SearchResults/utils';
import { useFacilitiesResult } from '../../hooks/useFacilities';
import { useSpecialtiesResult } from '../../hooks/useSpecialties';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { usePCPStore } from '../../store/usePCPStore';
import { useSearchStore } from '../../store/useSearchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { LagoonData } from '../../utils/common';
import {
  getLagoonConfigValue,
} from '../../utils/providerSearch.utils';
import { getResolvedSource } from '../../utils/typeahead.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getDependentInfo,
  getPlanVariationCode,
  isPlanVariationCodeRequired,
} from '../../utils/user.utils';
import { useAutoCompleteResults } from '../useAutoCompleteQuery';
import { useCategoryLLMFlag } from '../useCategoryLLMFlag';
import { useCoverageType } from '../useCoverageType';
import { useFeatureFlag } from '../useFeatureFlag';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import {
  usePlanYear,
  useSaveRecentSearches,
} from '../useRecentSearches/useRecentSearches';
import { useTypeaheadPseudoRollupTable } from '../useTypeaheadPseudoRollup';
import {
  getConvertedCoverageArray,
  getDataForProviders,
  onCategoryCountLoaded,
  retreiveTypeAheadData,
  retriveTypeAheadDataFromAutoComplete,
} from './utils';

const VALID_SEARCH_TYPES = Object.values(Constants.SEARCH_SOURCE_TYPES);

interface Props {
  onCompleted?: (result: any) => void;
}
export const useTypeAheadData = ({ onCompleted }: Props) => {
  const { t } = useTranslation();
  const { portalData } = useContext(PortalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const coverageTypeFromStore = useCoverageType();
  const storedLocation = useGeoLocationStorage();
  const { data: facilities = [] } = useFacilitiesResult({});
  const { data: specialties = [] } = useSpecialtiesResult({});
  const [, saveRecentSearch] = useSaveRecentSearches({});
  const [planYear] = usePlanYear();
  const pseudoRollupCodeTable: LagoonData[] = useTypeaheadPseudoRollupTable();

  const [globalSeachParams] = useSessionStorage<{
    includeSpecialityRollupCodes: string;
  }>(Constants.STORAGE_KEYS.SESSION.PSX_GLOBAL_SEARCH, {
    includeSpecialityRollupCodes: '',
  });

  const [
    enableRetriveTypeAheadDataFlag,
    enableSearchResultsV2,
    enableUESSuggestionMatch,
    enableNavigateToCorrectCategory,
    cleanUpProviderCalls,
    enableBHAoeTypeahead,
    enableAoeMedical,
    disableMedicalGroupForPcpFF,
  ] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_RETRIVE_TYPEAHEAD_DATA,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SEARCH_RESULTS_V2,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_UES_SUGGESTION_MATCH,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_NAVIGATE_TO_CORRECT_CATEGORY,
    ConstantsLagoon.FEATURE_FLAGS.CLEANUP_PROVIDER_CALLS,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_AOE_TYPEAHEAD,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_AOE_MEDICAL,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_MEDICAL_GROUP_FOR_PCP,
  ]);

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const typeaheadPreferenceCodes = useLagoon('typeahead-preference')();
  const invokeLlmModelFlag = useCategoryLLMFlag();
  const allConfigs = useLagoon('config');
  const deepLinkMapping = useLagoon(Constants.LAGOON_TABLE.DEEPLINK_MAPPING)();
  const specialityPreferenceTable = useLagoon(
    Constants.LAGOON_TABLE.SPECIALITY_PREFERENCE
  )().map((item) => ({
    rollupCode: item.rollupCode,
    specialityCode: item.specialityCode,
  }));

  const [isLoading, setIsLoading] = useState(true);
  const [emptyResults, setEmptyResults] = useState(false);
  const [showProviders, setShowProviders] = useState(true);
  const [showProviderGroups, setShowProviderGroups] = useState(true);
  const [showFacilities, setShowFacilities] = useState(true);

  const isTypeAheadDataLoaded = useRef(false);
  const isCategoryCountLoaded = useRef(false);
  const isEmptyResults = useRef(false);
  const typeAheadDataRef = useRef<any>({});
  const categoryCountResultsRef = useRef<any>({});
  const previousDataRef = useRef(null);
  const dataRef = useRef(null);

  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: any) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const { setPCPSearchState, dependentSeqNbr } = usePCPStore(
    useShallow((state: any) => ({
      setPCPSearchState: state.setPCPSearchState,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: any) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const { onlineRetailers, setSearchStore } = useSearchStore(
    useShallow((state: any) => ({
      onlineRetailers: state.searchStore.onlineRetailers,
      setSearchStore: state.setSearchStore,
    }))
  );

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { chipValue, setCoverageType, setChipValue } = chipStore;

  const { typeaheadSearchStore, setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: any) => ({
      typeaheadSearchStore: state.typeaheadSearchStore,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );

  const searchParamsObject = Object.fromEntries(searchParams.entries());
  const {
    searchType = '',
    search = '',
    source,
    sectionType,
    searchTerm: searchTermFromParams = '',
    coverageType: coverageTypeFromParams,
    choosePCP: choosePCPFromParams,
    chipValue: chipValueFromParams,
  } = searchParamsObject;

  const choosePCP = choosePCPFromParams === 'true';
  const searchTerm = searchTermFromParams || search;
  const coverageType = coverageTypeFromParams || coverageTypeFromStore;

  const isSpecialtySearch = searchType === Constants.SEARCH_TYPES.SPECIALTY;
  const searchLinkName = source?.split(':')?.[1];
  const resolvedSource = source?.split(':')?.[0];
  const isGlobalSearch =
    resolvedSource === Constants.SEARCH_SOURCE_TYPES.GLOBAL_SEARCH;

  const { data: specialtyCodes = [] } = useSpecialtiesResult({
    coverageType,
  });
  const { longitude, latitude } = storedLocation;
  const lagoonTableName = searchLinkName || resolvedSource;
  const commonSearchesLagoon = useLagoon(
    lagoonTableName || Constants.LAGOON_TABLE.COMMON_SEARCHES
  )();

  const enableRetriveTypeAheadData =
    enableRetriveTypeAheadDataFlag && enableSearchResultsV2;

  const searchRadius = getLagoonConfigValue(
    allConfigs,
    'DEFAULT_SEARCH_RADIUS'
  );
  const autoIncrementRadius = getLagoonConfigValue(
    allConfigs,
    'AUTO_INCREMENT_SEARCH_RADIUS'
  );

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();
  const dependentInfo = getDependentInfo(dependentSeqNbr);
  const memberCoverageTypes = getCoverageTypes(currentMember);
  const memberCoverages = getCoverageTypes(currentMember);
  const getCoverageType = getConvertedCoverageArray(memberCoverages);

  const planVariationCode = getPlanVariationCode(currentMember, coverageType);
  const policyID = currentMember?.policyNumber;

  const baseParams = {
    pseudoRollupCodeTable,
    deepLinkMapping,
    choosePCP,
    specialtyCodes,
    isGlobalSearch,
    globalSeachParams,
    setTypeaheadSearchStore,
    typeaheadSearchStore,
    currentMember,
    onlineRetailers,
    featureFlags,
    portalData,
    isSpecialtySearch,
    dependentSeqNbr,
    coverageType,
    searchRadius,
    autoIncrementRadius,
    providerType: '',
    dependentInfo,
    invokeLlmModelFlag,
    t,
  };

  const setShowProviderGroupsWithAdditionalCheck = (value: boolean) => {
    const resolvedValue = isShowProviderGroups(
      value,
      currentMember?.lineOfBusiness,
      choosePCP,
      disableMedicalGroupForPcpFF
    );
    setShowProviderGroups(resolvedValue);
  };

  const uniqueSearch = `${search}-${getResolvedSource(
    source
  )}-${latitude}-${longitude}`;

  // Set typeAhead data,categoryCount data loaded and empty results to false
  // and chip category to all, when new unquie search is triggered.
  useEffect(() => {
    if (search) {
      isEmptyResults.current = false;
      isTypeAheadDataLoaded.current = false;
      isCategoryCountLoaded.current = false;
      setChipValue(CareCategories.ALL);
    }
  }, [uniqueSearch]);

  // This is to trigger onCompleted callback for Map View Page
  // view useViewAllData hook to use the provider Search Results
  // for the initial map view Page.
  const onSearchResultsLoaded = (searchResult: any) => {
    const {
      otherParams: {
        preferredFacilities,
        virtualCare,
        acceptingNewPatients,
        specialtyCodeFromQuery,
        preSelectEapFilter,
        specialtyCode,
        genderCode,
        virtualCareCode,
        essentialCommunityProviderCode,
        invokeLlmModel,
      },
    } = getSearchResultsPageParams({
      ...baseParams,
      typeAheadData: typeAheadDataRef.current,
      chipValue,
    });

    onCompleted?.({
      ...searchResult,
      baseParams,
      parallelCategoryCountResults,
      typeAheadData: typeAheadDataRef.current,
      isEmptyResults: isEmptyResults.current,
      isTypeAheadDataLoaded: isTypeAheadDataLoaded.current,
      isCategoryCountLoaded: isCategoryCountLoaded.current,
      categoryCountResults: categoryCountResultsRef.current,
      acceptingNewPatients,
      preSelectEapFilter,
      virtualCare,
      specialtyCodeFromQuery,
      specialtyCode,
      preferredFacilities,
      genderCode,
      virtualCareCode,
      essentialCommunityProviderCode,
      invokeLlmModel,
    });
  };

  const onProviderResultsLoaded = (providersResult: any) => {
    const {
      totalCountPractitioners: totalCount,
      practitionerSearchRadius: searchRadius,
      isTieredPlan,
      providers,
      headers,
    } = providersResult;
    onSearchResultsLoaded({
      providersResult: {
        totalCount,
        searchRadius,
        isTieredPlan,
        providers,
        headers,
      },
    });
  };
  const onOrgResultsLoaded = (organizationResult: any) => {
    const {
      totalCountOrganizations: totalCount,
      organizationSearchRadius: searchRadius,
      isTieredPlan,
      facilities: providers,
      headers,
    } = organizationResult;
    onSearchResultsLoaded({
      organizationResult: {
        totalCount,
        searchRadius,
        isTieredPlan,
        providers,
        headers,
      },
    });
  };
  const onMedGrpResultsLoaded = (medGrpResult: any) => {
    const {
      totalCountMedicalGroup: totalCount,
      medicalGroupSearchRadius: searchRadius,
      isTieredPlan,
      medicalGroup: providers,
      headers,
    } = medGrpResult;
    onSearchResultsLoaded({
      medGrpResult: {
        totalCount,
        searchRadius,
        isTieredPlan,
        providers,
        headers,
      },
    });
  };

  const {
    data: combinedSearchResults,
    error: combinedSearchResultsError,
    providerIsLoading,
    providersResult,
    medGrpResult,
    organizationResult,
    orgIsLoading,
    medGrpIsLoading,
    providerHasError,
    getProviderSearchResults,
  } = useSearchResultsProviderResults(
    {},
    onProviderResultsLoaded,
    onOrgResultsLoaded,
    onMedGrpResultsLoaded
  );

  const getProvidersearchData = (
    isSpecialtySearch: boolean,
    chipToUse: string
  ) => {
    getDataForProviders({
      isSpecialtySearch,
      chipToUse,
      baseParams,
      typeAheadDataRef,
      choosePCP,
      sectionType,
      categoryCountResultsRef,
      setTypeaheadSearchStore,
      memberCoverageTypes,
      featureFlags,
      currentMember,
      coverageType,
      onlineRetailers,
      enableBHAoeTypeahead,
      enableAoeMedical,
      cleanUpProviderCalls,
      setShowFacilities,
      setShowProviderGroups: setShowProviderGroupsWithAdditionalCheck,
      setShowProviders,
      getProviderSearchResults,
    });
  };

  useEffect(() => {
    const {
      totalCountPractitioners,
      totalCountOrganizations,
      totalCountMedicalGroup,
    } = combinedSearchResults;
    const isResultsLoaded =
      (!showProviders || totalCountPractitioners !== undefined) &&
      (!showFacilities || totalCountOrganizations !== undefined) &&
      (!showProviderGroups || totalCountMedicalGroup !== undefined);
    if (isResultsLoaded) {
      const hasResults = !!(
        (showProviders && totalCountPractitioners) ||
        (showFacilities && totalCountOrganizations) ||
        (showProviderGroups && totalCountMedicalGroup)
      );
      setEmptyResults(!hasResults);
      isEmptyResults.current = !hasResults;
      setIsLoading(false);
    }
  }, [
    JSON.stringify(combinedSearchResults),
    showProviders,
    showFacilities,
    showProviderGroups,
  ]);

  // This is to trigger Provider Search Results when dependencies change and conditions are met
  useEffect(() => {
    const isSpecialtySearch = searchType === Constants.SEARCH_TYPES.SPECIALTY;
    if (
      isTypeAheadDataLoaded.current &&
      isCategoryCountLoaded.current &&
      chipValue !== CareCategories.ALL &&
      !choosePCP
    ) {
      getProvidersearchData(isSpecialtySearch, chipValue);
    }
  }, [chipValue, choosePCP, searchType]);

  // This is to trigger Provider Search Results for choosePCP flow
  useEffect(() => {
    if (choosePCP) {
      getProvidersearchData(
        searchType == Constants.SEARCH_TYPES.SPECIALTY,
        CareCategories.PRIMARY_CARE
      );
    }
  }, [choosePCP, latitude, longitude]);

  const onCatagoryCountQueryCompleted = (
    categoryCountResults: UseParallelSearchResults
  ) => {
    isCategoryCountLoaded.current = true;
    categoryCountResultsRef.current = categoryCountResults;
    const { chipToSelect, specialtySearch } = onCategoryCountLoaded({
      categoryCountResults,
      typeAheadData: typeAheadDataRef.current,
      chipValue,
      searchParamsObject,
      isSpecialtySearch,
      enableNavigateToCorrectCategory,
      typeaheadPreferenceCodes,
      dataRef,
      previousDataRef,
      isEmptyResults,
      setIsLoading,
      setChipValue,
      setCoverageType,
      setSearchParams,
    });

    const isSuperUser = storage.session.get(
      Constants.STORAGE_KEYS.SESSION.SUPER_USER
    );
    const isGuestUser = storage.session.get(
      Constants.STORAGE_KEYS.SESSION.IS_GUEST
    );

    if (!isSuperUser && !isGuestUser) {
      const { nameResults, specialityResults } = categoryCountResults;
      const categoriesCountResults = specialtySearch
        ? specialityResults
        : nameResults;
      const variables = getRecentSearchVariables({
        baseParams,
        typeAheadData: typeAheadDataRef.current,
        categoriesCountResults,
        planYear,
        chipValue: chipToSelect || chipValue,
        storedLocation,
      });
      saveRecentSearch({ variables });
    }
  };

  const [parallelCategoryCountResults, getParallelCategoryCountResults] =
    useParallelSearchResultsCategoriesCount({}, onCatagoryCountQueryCompleted);

  const onTypeAheadDataLoaded = (typeAheadData: any) => {
    if (typeAheadData?.searchType) {
      setTypeaheadSearchStore({ searchType });
      typeAheadData.searchType = searchType;
    }
    isTypeAheadDataLoaded.current = true;
    typeAheadDataRef.current = typeAheadData;
    // If choosePCP is true, then we don't need to make category count request.
    if (choosePCP) {
      return;
    }
    const { categoryCountRequestParams } = getSearchResultsPageParams({
      ...baseParams,
      typeAheadData,
      chipValue,
    });
    const planVariationCodeRequired = isPlanVariationCodeRequired(
      currentMember?.lineOfBusiness,
      currentMember?.population,
      portalData?.portalName
    );

    const requiredParams = {
      policyID,
      latitude,
      longitude,
      coverageType,
      ...(planVariationCodeRequired ? { planVariationCode } : {}),
    };

    if (isMissingParameters('Categories Count', requiredParams)) {
      isEmptyResults.current = true;
      setIsLoading(false);
      return;
    }
    // trigger category count query once typeahead data is loaded
    getParallelCategoryCountResults(categoryCountRequestParams);
  };

  const [, GetAutoCompleteResults] = useAutoCompleteResults({
    onCompleted: (data) => {
      // This retrieves typeahead data from AutoCompleteResults and calls the onTypeAheadDataLoaded function
      retriveTypeAheadDataFromAutoComplete({
        ...data,
        search,
        searchLinkName,
        searchType,
        enableRetriveTypeAheadData,
        enableUESSuggestionMatch,
        specialityPreferenceTable,
        getCoverageType,
        dependentSeqNbr,
        choosePCP,
        chipStore,
        setTypeaheadSearchStore,
        setAnalyticsState,
        setPCPSearchState,
        setDetailsStore,
        currentMember,
        typeaheadSearchStore,
        setChipValue,
        setCoverageType,
        convertTypeaheadProviderIdAndType,
        getTypeAheadCategory,
        onTypeAheadDataLoaded,
      });
    },
  });

  useEffect(() => {
    // If sectionType is in params and no source, then it belongs to flows
    // that are not migrated yet, so we need to set the chip value and call onCompleted
    // to use the store data.
    if (sectionType && !source) {
      setChipValue(chipValueFromParams);
      onCompleted?.({});
      // If source is not valid or FF is turned OFF
      // we don't want toretrieve typeahead data,
      // then we use the store data.
    } else if (
      !VALID_SEARCH_TYPES.includes(resolvedSource) ||
      !enableRetriveTypeAheadData
    ) {
      onTypeAheadDataLoaded(typeaheadSearchStore);
    } else {
      // This retrieves typeahead data based on search source and call onTypeAheadDataLoaded function
      retreiveTypeAheadData({
        enableRetriveTypeAheadData,
        resolvedSource,
        source,
        search,
        commonSearchesLagoon,
        specialityPreferenceTable,
        searchTerm,
        getCoverageType,
        dependentSeqNbr,
        choosePCP,
        chipStore,
        setTypeaheadSearchStore,
        setAnalyticsState,
        setPCPSearchState,
        setDetailsStore,
        setSearchStore,
        facilities,
        specialties,
        GetAutoCompleteResults,
        typeaheadSearchStore,
        onTypeAheadDataLoaded,
        setChipValue,
        setCoverageType,
      });
    }
  }, [uniqueSearch, facilities.length, specialties.length]);

  const {
    otherParams: {
      genderCode,
      virtualCareCode,
      essentialCommunityProviderCode,
      invokeLlmModel,
      preferredFacilities,
      virtualCare,
      includeSpecialityRollupCodes,
      acceptingNewPatients,
      specialtyCodeFromQuery,
      preSelectEapFilter,
      userZip,
      category,
      pesKeyword,
      linkName,
      combinedIncludeSpecialityRollupCodes,
      searchMethod,
      medicalSpeciality,
      isMixedSuggestions,
    },
  } = getSearchResultsPageParams({
    ...baseParams,
    typeAheadData: typeAheadDataRef.current,
    chipValue,
  });

  return {
    isLoading,
    search,
    virtualCare,
    includeSpecialityRollupCodes,
    acceptingNewPatients,
    specialtyCodeFromQuery,
    preSelectEapFilter,
    userZip,
    category,
    pesKeyword,
    linkName,
    combinedIncludeSpecialityRollupCodes,
    searchMethod,
    searchTerm,
    medicalSpeciality,
    isMixedSuggestions,
    preferredFacilities,
    parallelCategoryCountResults,
    isEmptyResults: isEmptyResults.current,
    emptyResults,
    categoryCountResults: categoryCountResultsRef.current,
    genderCode,
    virtualCareCode,
    essentialCommunityProviderCode,
    invokeLlmModel,
    combinedSearchResults,
    combinedSearchResultsError,
    providerIsLoading,
    providersResult,
    medGrpResult,
    organizationResult,
    orgIsLoading,
    medGrpIsLoading,
    providerHasError,
    showFacilities,
    showProviderGroups,
    showProviders,
  };
};
