import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';

export const PageContainer = styled('div', {
  padding: '0px',
  backgroundColor: '$mapViewContainerBgColor',
  borderTop: '1px solid rgb(229, 229, 230)',
  '@screen < $sm': { borderTop: 'none' },
});

export const AlertBannerWrapper = styled('div', {
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '0 0 1px',
  '@media (max-width: 1248px)': {
    margin: '0 0 0 24px',
    maxWidth: 'none',
    width: '100%',
  },
});

export const MapViewWrapper = styled('div', {
  maxWidth: '1440px',
  marginLeft: 'calc((100vw - 1200px)/2) ',
  marginRight: '24px',
  padding: '24px 0',

  '@media (max-width: 1248px)': {
    marginLeft: '24px',
    marginRight: '24px',
  },
  '@screen < $md': {
    marginLeft: '16px',
    marginRight: '16px',
    padding: '16px 0',
  },
  '@screen < $sm': {
    padding: '0',
    margin: '0',
  },
});

export const MapViewWrapperFullWidth = styled('div', {
  maxWidth: '100%', // '80.89%',
  marginLeft: '96px',
  padding: '24px 0',

  '@media (max-width: 1920px)': {
    marginLeft: '48px',
  },
  '@media (max-width: 1440px)': {
    marginLeft: '24px',
  },
});

export const EnhancePaginationMobileStyles = {
  '.abyss-c-PJLV-ihNPJpp-css': {
    '&:last-child': {
      position: 'sticky',
      bottom: '0',
      zIndex: '999',
      backgroundColor: 'white',
    },
  },
};

export const HeaderContainer = styled('div', {
  dynamic: ({
    cssProps: { urlCheck, enableMapViewEnhancements, enableMapFilterSortCTA },
  }) => ({
    position:
      enableMapViewEnhancements && (enableMapFilterSortCTA ? '' : 'sticky'),
    top:
      enableMapViewEnhancements &&
      (urlCheck ? (enableMapFilterSortCTA ? '0px' : '40px') : '0px'),
    zIndex: enableMapViewEnhancements && (enableMapFilterSortCTA ? '' : '999'),
  }),
});

export const FilterMapFullWidth = styled('div', {
  dynamic: ({ cssProps: { urlCheck } }) => ({
    maxWidth: '100%', // '80.89%',
    paddingLeft: '96px',
    paddingTop: '8px',
    borderBottom: '1px solid rgb(229, 229, 230)',
    position: 'sticky',
    top: urlCheck ? '40px' : '0',
    zIndex: '4',
    backgroundColor: 'white',

    '@media (max-width: 1920px)': {
      paddingLeft: '48px',
    },
    '@media (max-width: 1440px)': {
      paddingLeft: '24px',
    },
  }),
});

export const MobileFilterContainer = styled(Flex, {
  dynamic: ({ cssProps: { urlCheck, enableMapFilterSortCTA } }) => ({
    padding: enableMapFilterSortCTA && '16px 0 12px 0',
    position: enableMapFilterSortCTA && 'sticky',
    top: enableMapFilterSortCTA && urlCheck ? '40px' : '0',
    zIndex: enableMapFilterSortCTA && '999',
    backgroundColor: enableMapFilterSortCTA && 'white',
  }),
});

export const MobileResultContainer = styled(Flex, {
  dynamic: ({ cssProps: { choosePCP, urlCheck, enableMapFilterSortCTA } }) => ({
    display: 'grid',
    margin: '0 0 6px 0 ',
    padding: '16px 0 0 24px',
    position: 'sticky',
    top: enableMapFilterSortCTA
      ? urlCheck
        ? '126px'
        : '86px'
      : choosePCP
      ? urlCheck
        ? '224px'
        : '184px'
      : urlCheck
      ? '152px'
      : '112px',
    zIndex: '2',
    backgroundColor: 'white',
  }),
});
