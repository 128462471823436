import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../../common/Constants';
import { makeAllSlidesAriaHidden } from '../../../../../common/Utils/a11yUtils/a11yUtils';
import { CustomAttributesBlock } from '../../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { getCurrentDateWithDashFormatting } from '../../../../../common/Utils/datesUtils';
import { getFeatureFlag } from '../../../../../common/Utils/getFeatureFlag';
import { useLagoon } from '../../../../../hooks/useLagoon';
import { CompareProvider } from '../../../../../models/Provider';
import { ResponseHeaders } from '../../../../../models/ResponseHeaders';
import { useChipStore } from '../../../../../store/useChipStore';
import { ChipState } from '../../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../../store/useDetailsStore/detailsStore';
import { DrawerMenuButton } from '../DrawerMenuButton';
import { PrintCompareDrawer } from '../PrintMapView/PrintCompareDrawer';
import { compareEmptyCarouselStyle } from './CompareDrawer.style';
import { CompareButton } from './Components/Buttons/CompareButton';
import { DrawerMenu } from './Components/Buttons/DrawerMenu';
import { CompareCard } from './Components/CompareCard/CompareCard';
import { ConfirmationModal } from './Components/ConfirmationModal';
import { EditCompareButton } from './Components/EditCompare/EditCompareButton';
import { EditCompareDrawerMobile } from './Components/EditCompare/EditCompareDrawerMobile';
import { MockFalseProvider } from './MockProviders/MockFalseProvider';
import { MockNullProvider } from './MockProviders/MockNullProvider';
import { MockTrueProvider } from './MockProviders/MockTrueProvider';
import {
  CompareDrawerHeader,
  compareEmptyCarouselExpandedStyle,
  placeHolderTextMobileStyle,
} from './utility/compareDrawer.styled';
import {
  CompareCheckboxInfo,
  adobeCompareDrawerLinkLocation,
} from './utility/compareDrawerConstants';
import { openTabOrNavigateOnClick } from './utility/handleDetailsOnClick';

type Props = {
  items: CompareCheckboxInfo[];
  setItems: React.Dispatch<React.SetStateAction<CompareCheckboxInfo[]>>;
  selectedCount: number;
  total: number;
  removeItem: (item: string) => void;
  selectedProviders: any[];
  setSelectedProviders: (a: CompareProvider[]) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setOpenCompare: (a: boolean) => void;
  headers: ResponseHeaders;
};

export const CompareDrawerMobile = ({
  headers,
  items,
  setItems,
  selectedCount,
  total,
  selectedProviders,
  setSelectedProviders,
  setSelectedCheckbox,
  removeItem,
  setOpenCompare,
}: Props) => {
  const { t } = useTranslation();
  const [isExpandedCardOpen, setIsExpandedCardOpen] = useState<boolean>(false);
  const [isCompareViewOpen, setIsCompareViewOpen] = useState<boolean>(false);
  const { navigate } = useRouter();

  const { chipValue, coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      chipValue: state.chipValue,
    }))
  );

  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );
  const modal = useOverlay('confirmation-modal');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [cardHeight, setCardHeight] = useState(0);

  const [nameOfButtonClickedLast, setNameOfButtonClickedLast] =
    useState<string>('');

  const itemsForCard = [
    ...items,
    {
      name: 'inNetwork',
      label: 'In-network',
      isChecked: true,
    },
  ] as CompareCheckboxInfo[];

  const handleDetailsOnClickFn = (
    providerId,
    options,
    customAttributesBlock: CustomAttributesBlock
  ) => {
    openTabOrNavigateOnClick(
      providerId,
      navigate,
      options,
      { chipValue, coverageType },
      setDetailsStore,
      customAttributesBlock
    );
  };

  const shareTitle = `${t(
    'SHARE_PROVIDERS.COMPARE_PROVIDERS'
  )} ${getCurrentDateWithDashFormatting()}`;

  const [changedTempItems, setChangedTempItems] = useState<
    CompareCheckboxInfo[]
  >([]);
  const [isEditCompareOpen, setIsEditCompareOpen] = useState<boolean>(false);

  // QA Testing temporary code (for null provider & Tier 1 Provider)
  // TODO - Remove before October Release

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const insertMockProviders = getFeatureFlag(
    featureFlags,
    'INSERT_NULL_PROVIDER'
  );

  useEffect(() => {
    if (insertMockProviders) {
      selectedProviders.splice(0, 1, MockTrueProvider);
      selectedProviders.splice(1, 1, MockFalseProvider);
      selectedProviders.splice(2, 1, MockNullProvider);
    }
  });
  // End of QA Testing temporary code

  useEffect(() => {
    if (nameOfButtonClickedLast === 'cancel') {
      setItems(JSON.parse(JSON.stringify(items)));
      setIsEditCompareOpen(false);
      setNameOfButtonClickedLast('');
    }

    if (nameOfButtonClickedLast === 'edit') {
      setItems(JSON.parse(JSON.stringify(items)));
      setIsEditCompareOpen(true);
      setNameOfButtonClickedLast('');
    }
  }, [nameOfButtonClickedLast, changedTempItems]);

  const carouselStyles = {
    'abyss-carousel-root': {
      width: '100%',
    },
    'abyss-carousel-swiper-container': {
      display: 'flex',
      willChange: 'transform',
    },
    'abyss-carousel-slide-container': {
      position: 'relative',
      width: isExpandedCardOpen && isCompareViewOpen ? '202px' : '162px',
    },
    'abyss-carousel-minimal-controls': {
      marginTop: '12px',
      marginBottom: '12px',
      padding: '0px',
      height: '20px',
      'button.abyss-carousel-minimal-pagination-container': {
        padding: ' 0 4px 0 4px',
        display: 'block',
        width: 'auto',
      },
    },
    'abyss-carousel-minimal-nav-icon-right': {
      width: '20px',
      height: '20px',
    },
    'abyss-carousel-minimal-nav-container-right': {
      width: '20px',
      height: '20px',
      padding: '0px',
      top: '-3px',
    },
    'abyss-carousel-minimal-nav-container-left': {
      width: '20px',
      height: '20px',
      padding: '0px',
      top: '-3px',
    },

    'abyss-carousel-minimal-nav-icon-left': {
      width: '20px',
      height: '20px',
    },
    'abyss-carousel-minimal-pagination-container': {
      padding: '0 8px 0 8px',
    },
  };

  const carouselSelectedProviderSlides = Array.from(Array(5).keys()).map(
    (i) => (
      <Slide
        css={{
          'abyss-slide-container': {
            backgroundColor: '$white',
          },
        }}
      >
        <Slide.Container
          css={{
            'abyss-slide-content-wrapper': {
              padding: '0',
              visibility: 'visible',
            },
          }}
        >
          <Card
            css={{
              'abyss-card-root': {
                border: 'none',
                boxShadow: 'none',
              },
            }}
          >
            <Card.Section css={{ padding: '10px' }}>
              {selectedProviders[i] ? (
                <CompareCard
                  compareCheckboxes={items}
                  handleDetailsOnClick={handleDetailsOnClickFn}
                  headers={headers}
                  index={i}
                  isCompareViewOpen={isCompareViewOpen}
                  isExpandedCardOpen={isExpandedCardOpen}
                  removeItem={removeItem}
                  selectedProvider={selectedProviders[i]}
                  setCardHeight={setCardHeight}
                />
              ) : (
                <Box
                  css={
                    isExpandedCardOpen && isCompareViewOpen
                      ? compareEmptyCarouselExpandedStyle
                      : compareEmptyCarouselStyle
                  }
                  height={
                    isExpandedCardOpen && isCompareViewOpen
                      ? `${cardHeight - 34}px`
                      : '136px'
                  }
                  index={i}
                  key={`compare-card-skeleton${i}`}
                >
                  <Text css={placeHolderTextMobileStyle}>
                    {t('COMPARE_DRAWER_TEXT.CARD')}
                  </Text>
                </Box>
              )}
            </Card.Section>
          </Card>
        </Slide.Container>
      </Slide>
    )
  );

  return (
    <React.Fragment>
      <CompareDrawerHeader
        data-auto-testid="compare-container-mobile"
        data-testid="compare-container-mobile"
        view="mobile"
      >
        <Box
          css={{
            'abyss-box-root': {
              backgroundColor: 'transparent',
              padding: '0px',
            },
          }}
        >
          <Flex
            alignItems="flex-start"
            css={{
              'abyss-flex-root': {
                marginTop: '16px',
                paddingLeft: '4px',
              },
            }}
            justify="space-between"
          >
            <Layout.Stack
              alignItems="left"
              css={{ paddingTop: '8px' }}
              space={4}
            >
              <Flex>
                <Heading
                  color="$gray8"
                  css={{ paddingRight: '0.25rem' }}
                  display="h6"
                  offset={1}
                >
                  Compare Providers
                </Heading>
                <Text color="$gray7">
                  ({selectedCount} {t('of')} {total} {t('SELECTED')})
                </Text>
              </Flex>
              <Button
                after={
                  <IconMaterial
                    icon={
                      isExpandedCardOpen
                        ? 'keyboard_arrow_up'
                        : 'keyboard_arrow_down'
                    }
                    size="$md"
                  />
                }
                css={{
                  '&.abyss-button-root': {
                    padding: 0,
                    height: 'auto',
                  },
                }}
                data-auto-testid="icon-button-to-collapse-providers-mobile"
                data-testid="icon-button-to-collapse-providers-mobile"
                onClick={() => {
                  adobeLinkTrackEvent({
                    name: 'compare details',
                    location: `modal:${adobeCompareDrawerLinkLocation}`,
                    type: isExpandedCardOpen
                      ? 'accordion close'
                      : 'accordion open',
                  });
                  setIsExpandedCardOpen(!isExpandedCardOpen);
                  if (!isExpandedCardOpen) {
                    makeAllSlidesAriaHidden();
                  }
                }}
                variant="tertiary"
              >
                {isEditCompareOpen ? 'Hide details' : 'Show details'}
              </Button>
              {isExpandedCardOpen && (
                <EditCompareButton
                  isEditCompareOpen={isEditCompareOpen}
                  setIsEditCompareOpen={setIsEditCompareOpen}
                  setNameOfButtonClickedLast={setNameOfButtonClickedLast}
                />
              )}
            </Layout.Stack>
            <DrawerMenu
              isMenuOpen={isMenuOpen}
              items={items}
              locationForAnalytics="modal:more actions"
              selectedProviders={selectedProviders}
              setIsMenuOpen={setIsMenuOpen}
              shareTitle={shareTitle}
            />
            <Layout.Group alignItems="top">
              <DrawerMenuButton
                ariaLabel="More Actions"
                data-auto-testid="mapview-compare-drawer-menu"
                data-testid="mapview-compare-drawer-menu"
                icon="more_vert"
                locationForAnalytics={`modal:${adobeCompareDrawerLinkLocation}`}
                onClick={setIsMenuOpen}
                view="mobile"
              />
              <DrawerMenuButton
                ariaLabel="Close"
                data-auto-testid="mapview-compare-drawer-menu"
                data-testid="mapview-compare-drawer-menu"
                icon="close"
                locationForAnalytics={`modal:${adobeCompareDrawerLinkLocation}`}
                onClick={() => modal?.open()}
                view="mobile"
              />
            </Layout.Group>
          </Flex>
        </Box>
      </CompareDrawerHeader>
      {isExpandedCardOpen && (
        <Carousel
          css={carouselStyles}
          minimal
          nextSlideOnClick={() => makeAllSlidesAriaHidden()}
          noLoop
          prevSlideOnClick={() => makeAllSlidesAriaHidden()}
          slideIndexOnClick={() => makeAllSlidesAriaHidden()}
          slides={carouselSelectedProviderSlides}
          slidesPerView={1}
        />
      )}
      <Flex justify="space-evenly">
        <CompareButton
          isCompareViewOpen={isCompareViewOpen}
          isExpandedCardOpen={isExpandedCardOpen}
          selectedCount={selectedCount}
          setIsCompareViewOpen={setIsCompareViewOpen}
          setIsExpandedCardOpen={setIsExpandedCardOpen}
        />
      </Flex>
      <ConfirmationModal
        modal={modal}
        modalName="confirmation-modal"
        setItems={setItems}
        setOpenCompare={setOpenCompare}
        setSelectedCheckbox={setSelectedCheckbox}
        setSelectedProviders={setSelectedProviders}
      />
      <EditCompareDrawerMobile
        isEditCompareOpen={isEditCompareOpen}
        items={items}
        nameOfButtonClickedLast={nameOfButtonClickedLast}
        setChangedTempItems={setChangedTempItems}
        setNameOfButtonClickedLast={setNameOfButtonClickedLast}
        setOpenEdit={setIsEditCompareOpen}
      />
      {selectedProviders && (
        <PrintCompareDrawer
          compareCheckboxes={itemsForCard}
          selectedProviders={selectedProviders}
        />
      )}
    </React.Fragment>
  );
};
