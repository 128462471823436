/* eslint-disable no-nested-ternary */
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useSearchStore } from '../../../../store/useSearchStore';
import { SearchState } from '../../../../store/useSearchStore/searchStore';
import { getOnlineRetailers } from '../../../../utils/user.utils';
import { TypeaheadBHType, TypeaheadProviderTypes } from '../../../Constants';
import { PSXIcon } from '../../../PSXIcon';
import { DisplayCoverageTypes } from './DisplayCoverageTypes';
import {
  Divider,
  DropdownContainer,
  ProviderIcon,
  SuggestionListContainer,
  SuggestionListItem,
  SuggestionSectionItem,
  isPrimaryActiveSuggestion,
} from './KeywordSearchDropdown.styled';
import { SuggestionItem } from './SuggestionItem';
import { UserTextSearch } from './UserTextSearch';

type Props = {
  typeAheadSuggestions;
  onSuggestionClick;
  activeSuggestion;
  getCoverageType;
  keywordSearchTerm;
  memberDDP;
  sortItems;
  hideDisabledTypeahead;
  handleGeneralSearchClick;
  isLLMGeneralSearchEnabled;
  isPopulationAllowed;
};
export const SuggessionList = ({
  typeAheadSuggestions,
  onSuggestionClick,
  activeSuggestion,
  getCoverageType,
  keywordSearchTerm,
  memberDDP,
  sortItems,
  hideDisabledTypeahead,
  isLLMGeneralSearchEnabled,
  handleGeneralSearchClick,
  isPopulationAllowed,
}: Props) => {
  const { t } = useTranslation();

  const formatSpecialty = (specialtiesList = []) => {
    const distinctSpecialty = uniqBy(specialtiesList, 'category');
    const list = distinctSpecialty.slice(0, 2);
    let displaySpecialty = '';
    list.map((item: any, index: number) => {
      displaySpecialty += `${item.category}${
        index !== list.length - 1 ? ', ' : ''
      }`;
      return displaySpecialty;
    });
    if (distinctSpecialty.length > 2) {
      displaySpecialty += ` (${distinctSpecialty.length - 2} ${t('More')})`;
    }

    return displaySpecialty;
  };
  const { setSearchStore } = useSearchStore(
    useShallow((state: SearchState) => ({
      onlineRetailers: state.searchStore.onlineRetailers,
      setSearchStore: state.setSearchStore,
    }))
  );

  return (
    <React.Fragment>
      <Divider />
      <SuggestionListContainer
        data-auto-testid="suggestion-list-container"
        data-testid="suggestion-list-containter"
      >
        <DropdownContainer
          data-testid="primary-dropdown-container"
          role="listbox"
        >
          {isLLMGeneralSearchEnabled &&
            !typeAheadSuggestions?.some(
              (item) =>
                item?.suggestion &&
                item?.suggestion?.toLowerCase() ===
                  keywordSearchTerm?.toLowerCase()
            ) &&
            !typeAheadSuggestions?.some(
              (item) =>
                item?.section &&
                item?.items?.find(
                  (value) =>
                    value?.displayName?.toLowerCase() ===
                    keywordSearchTerm?.toLowerCase()
                )
            ) &&
            isPopulationAllowed && (
              <UserTextSearch
                data-testid="user-text-search-common-suggestion"
                key={keywordSearchTerm}
                keywordSearchTerm={keywordSearchTerm}
                onClick={handleGeneralSearchClick}
              />
            )}
          {typeAheadSuggestions?.length > 0 &&
            typeAheadSuggestions?.sort(sortItems).map((value, index) => {
              if (value?.suggestion) {
                return (
                  <SuggestionItem
                    activeSuggestion={activeSuggestion}
                    data-testid="search-input-box-typeahead-suggestion"
                    getCoverageType={getCoverageType}
                    hideDisabledTypeahead={hideDisabledTypeahead}
                    index={index}
                    key={value.id}
                    keywordSearchTerm={keywordSearchTerm}
                    memberDDP={memberDDP}
                    onClick={() => {
                      onSuggestionClick(value, index);
                      setSearchStore({
                        onlineRetailers: getOnlineRetailers(
                          value.isOnlineProviders
                        ),
                      });
                    }}
                    value={value}
                  />
                );
              }
              if (value?.section) {
                return value?.items?.sort(sortItems).map((item, ix) => {
                  const speciality = formatSpecialty(item.speciality);
                  return (
                    <SuggestionSectionItem
                      aria-posinset={ix + typeAheadSuggestions.length - 1}
                      className={`${
                        activeSuggestion ===
                        ix + typeAheadSuggestions.length - 1
                          ? 'active'
                          : ''
                      } ${ix === 0 ? 'first-child' : ''}`}
                      id={
                        activeSuggestion ===
                        ix + typeAheadSuggestions.length - 1
                          ? 'primary-search-bar-result-suggestion'
                          : ''
                      }
                      key={`${value.section}-${speciality}-${item.displayName}-${item.displayAddress}`}
                      role="option"
                    >
                      {ix === 0 && (
                        <>
                          {typeAheadSuggestions?.length > 1 ? <Divider /> : ''}
                          <Text
                            color="$gray8"
                            data-testid="heading-typeahead-suggested-searches"
                            fontWeight={700}
                            size="14.22px"
                          >
                            {value.section}
                          </Text>
                        </>
                      )}
                      <SuggestionListItem
                        className="suggestion-link-span"
                        color="$interactive1"
                        css={
                          item?.coverageType.some((coverageCode) =>
                            getCoverageType.includes(coverageCode)
                          )
                            ? {
                                '&.abyss-flex-root':
                                  activeSuggestion ===
                                  ix + typeAheadSuggestions.length - 1
                                    ? isPrimaryActiveSuggestion
                                    : '',
                              }
                            : { pointerEvents: 'none' }
                        }
                        data-testid="search-input-box-typeahead-suggestion-section"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          ev.preventDefault();
                          onSuggestionClick(item, ix);
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <div>
                            {item?.providerType ===
                              TypeaheadProviderTypes.PROVIDER_GROUP ||
                            (item?.providerType ===
                              TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
                              item?.providerId?.startsWith(
                                TypeaheadBHType.GRP
                              )) ? (
                              <IconMaterial
                                data-testid="providergroup-icon"
                                icon="people"
                                style={ProviderIcon}
                              />
                            ) : item?.providerType ===
                              TypeaheadProviderTypes.PRACTITIONER ? (
                              <IconMaterial
                                data-testid="provider-icon"
                                icon="person"
                                style={ProviderIcon}
                              />
                            ) : item?.providerType ===
                                TypeaheadProviderTypes.FACILITY ||
                              item?.providerType ===
                                TypeaheadProviderTypes.MEDICAL_HEALTH_SUPPLIES ||
                              (item?.providerType ===
                                TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
                                item?.providerId?.startsWith(
                                  TypeaheadBHType.FAC
                                )) ? (
                              <PSXIcon
                                data-testid="facility-icon"
                                icon="hospital"
                                style={ProviderIcon}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <Text color="$gray7" fontWeight={500} size="14.22px">
                            <Text
                              color={
                                !item?.coverageType.some((coverageCode) =>
                                  getCoverageType.includes(coverageCode)
                                )
                                  ? '$gray6'
                                  : '$info1'
                              }
                              fontWeight={700}
                            >
                              {t(item?.displayName)}
                            </Text>
                            <Text
                              style={{
                                marginLeft: '8px',
                                marginRight: '8px',
                              }}
                            >
                              {speciality}
                            </Text>
                            <Text style={{ display: 'inline-block' }}>
                              <DisplayCoverageTypes
                                getCoverageType={getCoverageType}
                                hideDisabledTypeahead={false}
                                item={item}
                                memberDDP={memberDDP}
                              />
                            </Text>
                          </Text>
                        </div>
                      </SuggestionListItem>
                    </SuggestionSectionItem>
                  );
                });
              }
              return null;
            })}
        </DropdownContainer>
      </SuggestionListContainer>
    </React.Fragment>
  );
};
