import { config } from '@abyss/web/tools/config';

import GET_RECOMMENDATION_PREFERENCE from './GetRecommendationPreferencesStatus.graphql';
import { useQuery } from '@abyss/web/hooks/useQuery';

export const useGetRecommendationPreferencesStatus = (options) => {
 const [queryResults, queryAction] = useQuery(GET_RECOMMENDATION_PREFERENCE, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    requestPolicy: 'no-cache',
    accessor: 'recommendationPreferencesStatus',
    onCompleted: (result) => {
      if(options?.handleSuccess) {
        options?.handleSuccess();
      }
      return result;
    },
    onError: (error) => {
      if(options?.handleError) {
        options?.handleError();
      }
      throw error;
    }
  });

   return [queryResults, queryAction];
}
