import { ReactNode } from 'react';

import { PopulationType, ReverseCoverageTypesCodes, SortOptionValues } from '../Constants';

export type Radio = {
  label: string | ReactNode;
  value: string;
};

type filteredOptionsProps = {
  options: Radio[];
  suppressPatientReviews?: boolean | undefined;
  suppressPremiumCare?: boolean | undefined;
  population?: string;
  isMnr?: boolean;
  isCns?: boolean;
  isIfp?: boolean;
};

const getSuppressedSortOptions = (
  suppressPatientReviews: boolean | undefined,
  suppressPremiumCare: boolean | undefined,
  population: string = '',
  isMnr: boolean = false,
  isCns: boolean = false,
  isIfp: boolean = false
) => {
  const suppressedOptions: SortOptionValues[] = [];
  if (suppressPatientReviews) {
    suppressedOptions.push(SortOptionValues.HIGHEST_RATING);
  }
  if (suppressPremiumCare && (population === PopulationType.COSMOS || isCns)) {
    suppressedOptions.push(SortOptionValues.UHPD_PROV);
  }
  if (isMnr || isCns || isIfp) {
    suppressedOptions.push(SortOptionValues.ACO_PROV);
  }
  if (isCns) {
    suppressedOptions.push(SortOptionValues.TIER_PROV);
  }
  return suppressedOptions;
};

export const filteredOptions = (filOptions: filteredOptionsProps) => {
  const {
    options,
    suppressPatientReviews,
    suppressPremiumCare,
    population,
    isMnr,
    isCns,
    isIfp,
  } = filOptions;
  const suppressedOptions: SortOptionValues[] = getSuppressedSortOptions(
    suppressPatientReviews,
    suppressPremiumCare,
    population,
    isMnr,
    isCns,
    isIfp
  );
  return options.filter(
    (option) => !suppressedOptions.includes(option.value as SortOptionValues)
  );
};

export const getBestMatchOptionKey = (coverageType: string): string => coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
    ? 'BH_PREFERRED_PROVIDERS'
    : 'PREFERRED_PROVIDERS'

export const getRecommendedProviderOptionKey = (coverageType: string): string => coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
    ? 'BH_PREFERRED_PROVIDERS'
    : 'RECOMMENDED_PROVIDERS'