import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ToggleExpandProps {
  isExpanded: boolean;
  handleToggleExpand: () => void;
}

const ToggleExpand: React.FC<ToggleExpandProps> = ({
  isExpanded,
  handleToggleExpand,
}) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="toggle-expand"
      onClick={handleToggleExpand}
      style={{
        cursor: 'pointer',
        color: '#196ECF',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '18px',
        display: 'flex',
        height: '21px',
        alignItems: 'center',
        gap: '4px',
      }}
    >
      {isExpanded ? (
        <>
          {t('CONTENT_FILTERS.TOGGLE_EXPAND_SHOW_LESS')} (#){' '}
          <IconMaterial icon="expand_less" />
        </>
      ) : (
        <>
          {t('CONTENT_FILTERS.TOGGLE_EXPAND_SHOW_MORE')} (#){' '}
          <IconMaterial icon="expand_more" />
        </>
      )}
    </div>
  );
};

export default ToggleExpand;
