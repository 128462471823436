import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Heading } from '@abyss/web/ui/Heading';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PortalUrl } from '../../../common/Portal';
import { getLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { isExternalUrl } from '../../../utils/common';
import { getPortalConfigUrls } from '../../../utils/portalConfig.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { phoneOnly } from '../../ConstantsStyles';

export const ContentCardsContainer = styled('div', {
  dynamic: ({ cssProps: { backgroundColor } }) => ({
    backgroundColor,
  }),
  height: '100%',
  padding: '$md 32px',
  paddingLeft: 0,

  borderRadius: '8px',
  '@screen < $sm': {
    padding: '$md 24px',
    paddingLeft: 0,
    minHeight: '174px',
  },
});

export const CardButton = {
  'abyss-button-root': {
    '@screen < $sm': {
      fontWeight: '$semibold',
    },
  },
};

const srOnly = { display: 'none' };

type Props = {
  cardTitle?: string;
  cardContent: string;
  buttonLabel: string;
  dntHelpKey: any;
  url?: string;
  cardIcon: React.ReactNode;
  cardBGColor: string;
  testId?: string;
  accessibilityText?: string;
};

const getUrl = (url?: string) => {
  if (isExternalUrl(url)) {
    return url;
  }
  return url ? `${window.location.origin}${url}` : null;
};

export const buildUrlForCard = (
  url: string | undefined,
  labelName: string,
  isExternal: boolean = false
) => {
  // If URL is provided, return URL appended with the current origin
  const langCode = getLanguage().code;
  url = url?.replace('lang_code', langCode).trim();
  if (url) {
    if (url.startsWith('tel:') || url.startsWith('mailto:')) {
      return {
        url,
        isExternal,
      };
    }

    return {
      url: `${window.location.origin}${url}`,
      isExternal,
    };
  }

  // If URL is not provided, get the URL from the portal config
  const portalUrls: PortalUrl[] = getPortalConfigUrls();
  if (portalUrls?.length > 0) {
    const portalUrl = portalUrls.find((item) =>
      item?.name?.includes(labelName)
    );
    url = portalUrl?.urlPath;
  }

  // Check if the URL is an external
  const isExternalNewUrl = isExternalUrl(url);

  // If the portalUrl is found return the URL
  const newURL = getUrl(url);

  return {
    url: newURL ?? '',
    isExternal: isExternalNewUrl,
  };
};

export const WhereToGetHelpContainer = ({
  cardTitle = '',
  cardContent,
  buttonLabel,
  dntHelpKey,
  url,
  cardIcon,
  cardBGColor,
  accessibilityText = '',
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();

  let currentUrl = {
    url,
    isExternal: isExternalUrl(url),
  };

  const analyticsInfo = {
    name: buttonLabel,
    location: 'body:where to get help',
    destinationUrl: url,
  };
  const handleLinkTrackEvent = () => {
    adobeLinkTrackEvent({
      ...analyticsInfo,
    });

    if (currentUrl.isExternal) window.open(currentUrl.url, '_blank');
    else window.location.href = currentUrl.url as any;
  };
  const datatestid = cardTitle.replace(/[^a-zA-Z ]/g, '').replace(/\s+/g, '-');

  if (!currentUrl.isExternal) {
    currentUrl = buildUrlForCard(url, dntHelpKey);
  }

  return (
    <ContentCardsContainer cssProps={{ backgroundColor: cardBGColor }}>
      <Layout.Group
        alignItems="top"
        css={{
          '&, > div': {
            height: '100%',
          },
        }}
        data-auto-testid="where-to-get-help-card"
        data-testid="where-to-get-help-card"
      >
        {cardIcon ? (
          <Layout.Stack>
            <IconBrand icon={cardIcon} size="60px" variant="twotone" />
          </Layout.Stack>
        ) : null}
        <Layout.Stack
          alignItems="left"
          css={{
            'div:nth-child(2)': {
              flexGrow: 1,
              paddingBottom: '8px',
            },
            ...(!cardIcon && {
              paddingLeft: '32px',
              '@screen < $sm': { paddingLeft: '24px' },
            }),
          }}
        >
          <Heading
            data-auto-testid={`${datatestid}-heading`}
            data-testid={`${datatestid}-heading`}
            display="h6"
            offset={2}
          >
            {t(cardTitle)}
          </Heading>
          <Text
            color="$gray8"
            data-auto-testid={`${datatestid}-content`}
            data-testid={`${datatestid}-content`}
            size={mobileScreen ? '$sm' : '$md'}
          >
            {cardContent}
          </Text>
          <Button
            after={
              currentUrl.isExternal ? <IconMaterial icon="open_in_new" /> : null
            }
            aria-label={`${buttonLabel} ${accessibilityText}`.toLowerCase()}
            css={CardButton}
            data-auto-testid={`${datatestid}-button`}
            data-testid={`${datatestid}-button`}
            name="get_started"
            onClick={handleLinkTrackEvent}
          >
            <Text color="$white" size={mobileScreen ? '$sm' : '$md'}>
              {buttonLabel}
            </Text>
            <span className="sr-only" style={srOnly}>
              {accessibilityText.toLowerCase()}
            </span>
          </Button>
        </Layout.Stack>
      </Layout.Group>
    </ContentCardsContainer>
  );
};
