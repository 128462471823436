import { Tabs } from '@abyss/web/ui/Tabs';
import { useTranslation } from 'react-i18next';

import { ProviderType } from '../../../models/ProviderDetails';
import { widgetPageLoadEvent } from '../../../utils/widgetEvents.utils';
import { FacilityTabs, ProviderTabs } from '../../Constants';
import { translatedTabTitle } from '../../Utils/translatedTabTitle.util';
import { WidgetTabs } from './DetailsWidgetHeader.styled';

type Props = {
  providerType: ProviderType;
  setActiveTab(tab: ProviderTabs | FacilityTabs): void;
};

export const DetailsWidgetTabs = ({ providerType, setActiveTab }: Props) => {
  const { t } = useTranslation();

  let tabs: (ProviderTabs | FacilityTabs)[] = [];

  switch (providerType) {
    case ProviderType.ORGANIZATION:
      tabs = [ProviderTabs.LOCATIONS, ProviderTabs.QUALITY];
      break;
    case ProviderType.MEDICAL_GROUP:
      tabs = [
        ProviderTabs.ABOUT,
        ProviderTabs.LOCATIONS,
        FacilityTabs.PHYSICIAN_DIRECTORY,
      ];
      break;
    default:
      tabs = [ProviderTabs.ABOUT, ProviderTabs.LOCATIONS, ProviderTabs.QUALITY];
  }

  const handleTabClick = (index) => {
    const tab = tabs[index];
    widgetPageLoadEvent({ pageName: tab });
    setActiveTab(tab);
  };

  return (
    <WidgetTabs
      onTabChange={handleTabClick}
      title={t('PROVIDER_DETAILS.TAB_TITLE')}
      variant="line"
    >
      {tabs.map((tab) => (
        <Tabs.Tab
          data-testid={`widget-tab-${tab}`}
          key={`widget-tab-${tab}`}
          label={t(
            translatedTabTitle(
              providerType === ProviderType.PRACTITIONER
                ? 'PROVIDER_DETAILS'
                : 'FACILITY_DETAILS',
              tab
            )
          )}
          value={tab}
        />
      ))}
    </WidgetTabs>
  );
};
