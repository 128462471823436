import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { t } from 'i18next';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';

import { PersonalFit } from '../../../../../api/src/models/ProviderRecommendationDetails';
import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { useLagoon } from '../../../hooks/useLagoon';
import { MatchText } from '../MatchText';
import { NotAMatchText } from '../NotAMatchText';
import {
  BreakdownContainer,
  ContainerWithTopandBottonBorder,
  CriteriaPointsContainer,
  CriteriaSubTitle,
  CriteriaTitle,
  DescriptionContainer,
  DrawerContentWithBackgroundgray,
  FAQContainer,
  FAQLink,
  StyledDescription,
} from '../SmartChoiceBreakDown.styles';
import { NotAMatchSearchIconAndText } from './Components/NotAMatchSearchIconAndText';
import {
  HealthGradeDescriptionContainer,
  StyledIcon,
  StyledLinkHealthGrade,
  StyledText,
} from './PersonalFitBreakDown.styles';

interface PersonalFitBreakDownProps {
  personalFit: PersonalFit;
  providerSpeaks: string;
  providerGender: string;
  providerBoardCertifications: string;
}

export const PersonalFitBreakDown = ({
  personalFit,
  providerSpeaks,
  providerGender,
  providerBoardCertifications,
}: PersonalFitBreakDownProps) => {
  const [matchCount, setMatchCount] = useState(0);
  const [notMatchCount, setNotMatchCount] = useState(0);
  const [consumerRating, setConsumerRating] = useState('');
  const [consumerRatingSurveyVolume, setConsumerRatingSurveyVolume] =
    useState('');
  const getMatchCounts = (personalFit: PersonalFit) => {
    let matchCount = 0;
    let notMatchCount = 0;
    if (personalFit?.languageSpoken) {
      personalFit?.languageSpoken === 'Y' ? matchCount++ : notMatchCount++;
    } else {
      notMatchCount++;
    }
    if (personalFit?.gender) {
      personalFit?.gender === 'Y' ? matchCount++ : notMatchCount++;
    } else {
      notMatchCount++;
    }
    if (personalFit?.boardCertification) {
      personalFit?.boardCertification === 'Y' ? matchCount++ : notMatchCount++;
    }
    if (personalFit?.consumerRatingMidPointStatus) {
      personalFit?.consumerRatingMidPointStatus === 'Y'
        ? matchCount++
        : notMatchCount++;
    } else {
      notMatchCount++;
    }
    return { matchCount, notMatchCount };
  };
  const replacePlaceholders = (
    text: string | undefined,
    replacements: Record<string, string>
  ): string =>
    (text || '').replace(/\{\{(.*?)\}\}/g, (_, key) => {
      const keys = key.split('.');
      let value: any = replacements;
      for (const k of keys) {
        value = value?.[k];
        if (value === undefined) break;
      }
      return typeof value === 'string' ? value : '';
    });
  const lagoonData = useLagoon('ui-messaging')();
  const good_match = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_GOOD_MATCH_CONTENT,
  });
  const description = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_DESCRIPTION,
  });
  const compatibility_title = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_COMPATABILITY_TITLE,
  });
  const speaks = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_SPEAKS,
  });
  const does_not_speak = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_DOES_NOT_SPEAKS_LANGUAGE,
  });
  const spoken_languages_unknown = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_LANGUAGE_UNKNOWN,
  });
  const provider_identifies_as = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_PROVIDER_IDENTIFIES_AS,
  });
  const provider_gender_unknown = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_GENDER_UNKNOWN,
  });
  const provider_board_certified = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_BOARD_CERTIFIED_IN,
  });
  const not_certified = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_NOT_BORD_CERTIFIED,
  });
  const paitient_experiance = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_PAITIENT_EXPERIENCE_TITLE,
  });
  const consumer_rating_and_stars = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_CONSIMER_RATING_STARS_ARE_PROVIDED_BY_HEALTHGRADES,
  });
  const rating_unavailable = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_CONSUMER_RATING_INFO_UNAVAILABLE,
  });
  const healthgrade_description = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_HEALTHGRADE_DESCRIPTION_AT_END,
  });
  const FAQs = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BREAKDOWN_FAQ_TEXT,
  });

  useEffect(() => {
    const { matchCount, notMatchCount } = getMatchCounts(personalFit);
    setMatchCount(matchCount);
    setNotMatchCount(notMatchCount);
    if (personalFit?.consumerRating) {
      setConsumerRating(personalFit?.consumerRating);
    }
    if (personalFit.consumerRatingSurveyVolume) {
      setConsumerRatingSurveyVolume(personalFit?.consumerRatingSurveyVolume);
    }
  }, [personalFit]);
  return (
    <BreakdownContainer data-testid="personal-fit-breakdown">
      <DrawerContentWithBackgroundgray direction="column">
        <CriteriaTitle data-testid="good-match-match-notmatch-count">
          {replacePlaceholders(good_match?.message || '', {
            matchCount: `${matchCount}`,
            totalMatchNonMatchCount: `${matchCount + notMatchCount}`,
          })}
        </CriteriaTitle>
      </DrawerContentWithBackgroundgray>
      <DescriptionContainer>
        <StyledDescription data-testid="personal-fit-description">
          {description?.message}
        </StyledDescription>
      </DescriptionContainer>
      <CriteriaPointsContainer data-testid="criteria-points" direction="column">
        <CriteriaSubTitle data-testid="compatability-title">
          {compatibility_title?.message}
        </CriteriaSubTitle>
        {personalFit?.languageSpoken === 'Y' && (
          <MatchText
            text={replacePlaceholders(speaks?.message, {
              providerSpeaks,
            })}
          />
        )}
        {personalFit?.languageSpoken === 'N' && (
          <NotAMatchText
            text={replacePlaceholders(does_not_speak?.message, {
              providerSpeaks,
            })}
          />
        )}
        {personalFit?.languageSpoken === '' && (
          <NotAMatchSearchIconAndText
            text={spoken_languages_unknown?.message}
          />
        )}
        {personalFit?.gender === 'Y' && (
          <MatchText
            text={replacePlaceholders(provider_identifies_as?.message, {
              providerGender,
            })}
          />
        )}
        {personalFit?.gender === 'N' && (
          <NotAMatchText
            text={replacePlaceholders(provider_identifies_as?.message, {
              providerGender,
            })}
          />
        )}
        {personalFit?.gender === '' && (
          <NotAMatchSearchIconAndText text={provider_gender_unknown} />
        )}
        {personalFit?.boardCertification === 'Y' && (
          <MatchText
            text={replacePlaceholders(provider_board_certified?.message, {
              providerBoardCertifications,
            })}
          />
        )}
        {personalFit?.boardCertification === 'N' && (
          <NotAMatchText text={not_certified?.message} />
        )}
        <CriteriaSubTitle data-testid="paitient-experiance">
          {paitient_experiance?.message}
        </CriteriaSubTitle>
        {personalFit?.consumerRatingMidPointStatus === 'Y' && (
          <MatchText
            text={replacePlaceholders(consumer_rating_and_stars?.message, {
              consumerRating,
              consumerRatingSurveyVolume,
            })}
          />
        )}
        {personalFit?.consumerRatingMidPointStatus === 'N' && (
          <NotAMatchText
            EndIcon={
              <IconSymbol color="#196ECF" icon="info" variant="outlined" />
            }
            text={replacePlaceholders(consumer_rating_and_stars?.message, {
              consumerRating,
              consumerRatingSurveyVolume,
            })}
          />
        )}
        {personalFit?.consumerRatingMidPointStatus === '' && (
          <NotAMatchSearchIconAndText text={rating_unavailable?.message} />
        )}
      </CriteriaPointsContainer>
      <ContainerWithTopandBottonBorder
        data-testid="healthgrades-container"
        direction="column"
      >
        <StyledIcon
          icon="healthgrades"
          size={132}
          title={t('HEALTH_GRADES_LOGO_TITLE')}
        />
        <HealthGradeDescriptionContainer data-testid="healthgrades-description">
          <StyledText>{healthgrade_description?.message}</StyledText>
          <StyledLinkHealthGrade
            data-testid="healthgrades-link"
            href="/"
            openNewWindow
          >
            {''}
          </StyledLinkHealthGrade>
        </HealthGradeDescriptionContainer>
      </ContainerWithTopandBottonBorder>
      <FAQContainer>
        <FAQLink
          data-testid="faq-link"
          href="https://www.uhc.com/premium-care-physician"
        >
          {FAQs?.message}
        </FAQLink>
      </FAQContainer>
    </BreakdownContainer>
  );
};
