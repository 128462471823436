import { useCallback } from 'react';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { useCategoriesCount } from '../../../../hooks/useCategoriesCount';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { GetCategoryCountProps } from './categoryCountTypes';
import {
  getPayloadWithRecriprocityId,
  groupSpecialtyRollupCodes,
} from './searchUtils';
import {
  compareAndReturn,
  getModifiedPayloadForCategoriesCount,
} from './utils';

export const useSearchResultsCategoriesCount = (customParams: any) => {
  const [categoryCount, getResults] = useCategoriesCount();

  const [enableAoeMedical] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_AOE_MEDICAL,
  ]);

  const getCategoryCountResults = useCallback(
    ({
      currentMember,
      onlineRetailers,
      featureFlags,
      pseudoVerticalsDetails,
      pseudoVerticals,
      includeSpecialityRollupCodes,
      searchType,
      specialtySearch,
      uesTranslation,
      coverageTypes,
      keyword,
      search,
      searchTerm,
      isUserTextSearch,
    }: GetCategoryCountProps) => {
      const payloadWithRecriprocityId = getPayloadWithRecriprocityId({
        currentMember,
        onlineRetailers,
        featureFlags,
        pseudoVerticalsDetails,
        pseudoVerticals,
      });
      const codeByGroup = groupSpecialtyRollupCodes(
        includeSpecialityRollupCodes,
        searchType,
        specialtySearch
      );
      const payload = getModifiedPayloadForCategoriesCount(
        payloadWithRecriprocityId,
        codeByGroup,
        uesTranslation,
        search,
        keyword,
        pseudoVerticalsDetails?.isPseudoRollupSearch,
        isUserTextSearch,
        coverageTypes,
        enableAoeMedical
      );

      getResults({
        ...customParams,
        payloadwithName: payload,
        searchTerm: compareAndReturn(searchTerm, search),
        isUserTextSearch,
      });
    },
    [getResults]
  );

  return [categoryCount, getCategoryCountResults];
};
