import translationsEnJson from '../../../public/locale/en/translation.json';
import { ProviderSearchFilters } from '../../models/ProviderSearch';
import { FilterCollectionModel, ReverseCoverageTypesCodes } from '../Constants';

export const getDistanceFilterOption = (
  value: number,
  previousValue?: number
) => [
  {
    label: `${value} ${translationsEnJson.miles}`,
    value,
    dnt_label: `${value} ${translationsEnJson.miles}`,
    c_value: value,
    hide: !value,
    previousValue,
  },
];

export const getFilterValue = (
  filterData: ProviderSearchFilters,
  model: FilterCollectionModel
) => {
  const filterValue = Array.isArray(filterData?.[model])
    ? filterData?.[model].length > 0
      ? filterData?.[model]?.[0]?.value
      : false
    : filterData?.[model]?.value;
  return filterValue;
};

export const resolveSelectedFilters = (
  coverageType: string,
  filterData: ProviderSearchFilters,
  selectedFilters: ProviderSearchFilters,
  defaultSearchRadius = 5
): ProviderSearchFilters => {
  // Update distance filter value based on virtual care filter value
  // only for Behavioral Health coverage type
  if (coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']) {
    const currentVirtualCareFilterValue = getFilterValue(
      filterData,
      FilterCollectionModel.VIRTUAL_CARE
    );
    const previousVirtualCareFilterValue = getFilterValue(
      selectedFilters,
      FilterCollectionModel.VIRTUAL_CARE
    );
    const distanceFilterValue = getFilterValue(
      selectedFilters,
      FilterCollectionModel.DISTANCE
    ) as number;

    // remove distance filter value when virtual care filter value is changed to true
    if (
      currentVirtualCareFilterValue === true &&
      previousVirtualCareFilterValue !== true &&
      distanceFilterValue
    ) {
      const updatedFilterData = {
        ...filterData,
        [FilterCollectionModel.DISTANCE]: getDistanceFilterOption(
          null,
          distanceFilterValue
        ),
      };
      return updatedFilterData;
      // set distance filter value from previous, when virtual care filter value is changed to false
    } else if (
      currentVirtualCareFilterValue === false &&
      previousVirtualCareFilterValue !== false &&
      !distanceFilterValue
    ) {
      const updatedFilterData = {
        ...filterData,
        [FilterCollectionModel.DISTANCE]: getDistanceFilterOption(
          (filterData?.[FilterCollectionModel.DISTANCE]?.[0]
            ?.previousValue as number) ||
            filterData?.[FilterCollectionModel.AUTO_SEARCH_RADIUS] ||
            defaultSearchRadius
        ),
      };
      return updatedFilterData;
    } else {
      return filterData;
    }
  } else {
    return filterData;
  }
};

export const getPreviousDistanceOrDefaultOption = (
  coverageType: String,
  model: FilterCollectionModel,
  selectedFilters: ProviderSearchFilters,
  defaultSearchRadius: number
) => {
  // If the coverage type is Behavioral Health and a previous distance value is available,
  // set the distance filter to the previous value when the virtual care filter is cleared
  const distanceFilterValue = getFilterValue(
    selectedFilters,
    FilterCollectionModel.DISTANCE
  ) as number;
  if (
    coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
    model === FilterCollectionModel.VIRTUAL_CARE &&
    !distanceFilterValue
  ) {
    return {
      [FilterCollectionModel.DISTANCE]: getDistanceFilterOption(
        (selectedFilters?.[FilterCollectionModel.DISTANCE]?.[0]
          ?.previousValue as number) ||
          selectedFilters?.[FilterCollectionModel.AUTO_SEARCH_RADIUS] ||
          defaultSearchRadius
      ),
    };
  }
  return {};
};

const resolvedFilters = (selectedFilters) => {
  const resolvedFilters = { ...selectedFilters };
  if (resolvedFilters?.AutoSearchRadius) {
    delete resolvedFilters.AutoSearchRadius;
  }
  Object.keys(resolvedFilters).forEach((key) => {
    if (
      resolvedFilters[key] === null ||
      resolvedFilters[key] === '' ||
      (Array.isArray(resolvedFilters[key]) &&
        resolvedFilters[key].length === 0) ||
      Object.keys(resolvedFilters[key]).length === 0
    ) {
      delete resolvedFilters[key];
    }
  });
  return resolvedFilters;
};

export const areFiltersEqual = (filtersA, filtersB) => {
  const resolvedFilterA = resolvedFilters(filtersA);
  const resolvedFilterB = resolvedFilters(filtersB);
  return JSON.stringify(resolvedFilterA) === JSON.stringify(resolvedFilterB);
};
