import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import find from 'lodash/find';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useLagoon } from '../../hooks/useLagoon';
import { SnackCardProvider } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { CustomPreferredPolicy } from '../../models/Lagoon';
import { FacilityDDPCode, Provider } from '../../models/Provider';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import {
  displayPreferredFacilityOnCards,
  getPremiumCarePhysician,
  getSuppressFacilityFlags,
  isShowPreferredFacilityBadge,
} from '../../utils/providerDetails.utils';
import { removeLeadingZeroes } from '../../utils/string.utils';
import {
  getCurrentMember,
  getIsCustomPreferredPolicy,
  getIsOxfordAndUSP,
  getLoggedInMember,
  getNetworkIdsForPES,
} from '../../utils/user.utils';
import { Constants, PremiumCareConstants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import {
  displayPremiumCareIdentifier,
  displayTier1Identifier,
  displayTier2Identifier,
  initializeCardVariantsPopupState,
  isPremiumCareDisplayEligible,
} from '../DataCard/DataCardUtils';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { PSXIcon } from '../PSXIcon';
import { getFeatureFlag } from '../Utils';
import {
  convertProviderTypeToAdobeType,
  formatProviderId,
  handleAdobeSearchResultClickAndModalEvent,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { getTierProviderEligibility } from '../Utils/memberUtils/memberUtils';
import { truncateLargeStrings } from '../Utils/truncateLargeStrings';
import {
  GreenDiamond,
  PreferredProviderMobile,
  PreferredProviderWeb,
} from './PreferredProvider.styles';
import {
  PremiumCareDrawerLinkStyle,
  PremiumCareDrawerStyles,
  PremiumCarePopoverLinkStyle,
  PremiumCareSnackCardPopoverStyles,
  PremiumInfoBox,
  PremiumPopoverText,
} from './PremiumCare.styles';
import { TierCardText } from './SnackCard.styles';
import { TierOne } from './TierOne';
import { TierTwo } from './TierTwo';

export enum CardInfoVariants {
  PREMIUM_CARE = 'premium-care',
  PREFERRED_FACILITY = 'preferred-facility',
  PREFERRED_PROVIDER = 'preferred-provider',
}

export enum CardTypeVariants {
  SNACK_CARD_CONTAINER = 'snack-card-container',
  SNACK_CARD_CAROUSEL = 'snack-card-carousel',
  SNACK_CARD_INTERNAL = 'snack-card-internal',
  DATA_CARD = 'data-card',
  MAP_DATA_CARD_INTERNAL = 'map-data-card-internal',
}

const providerPreferredContent = (currentMember, customPreferredPolicies) =>
  find(customPreferredPolicies, {
    policyId: removeLeadingZeroes(currentMember?.policyNumber),
    reciprocityId: currentMember?.reciprocityId,
  })?.popoverContent ?? Constants.PREFERRED_PROVIDER_BENEFITS.CONTENT;

export const providerPreferredHeading = (
  currentMember,
  customPreferredPolicies
) =>
  find(customPreferredPolicies, {
    policyId: removeLeadingZeroes(currentMember?.policyNumber),
    reciprocityId: currentMember?.reciprocityId,
  })?.badgeLabel ?? Constants.PREFERRED_PROVIDER_BENEFITS.HEADING;

export const providerPreferredIcon = (currentMember, customPreferredPolicies) =>
  find(customPreferredPolicies, {
    policyId: removeLeadingZeroes(currentMember?.policyNumber),
    reciprocityId: currentMember?.reciprocityId,
  })?.icon ?? Constants.PREFERRED_PROVIDER_BENEFITS.GREEN_DIAMOND;

type Props = {
  cardType: string;
  headers?: ResponseHeaders;
  indexForAnalytics?: number;
  rulesPackageKey?: string;
  practitioner?: Provider | SnackCardProvider;
  locationForAnalytics?: string;
  selectedFilters?: string;
  searchTerm?: string;
  landingPage?: boolean;
  popupContainer?: boolean;
  onClosePopup?: () => void;
};

export const CardInfo = ({
  cardType,
  selectedFilters,
  indexForAnalytics,
  locationForAnalytics,
  headers = { correlationId: '' },
  practitioner = {},
  searchTerm,
  landingPage = false,
  popupContainer = false,
  onClosePopup,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);

  const { coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
      coverageType: state.coverageType,
    }))
  );

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState<
    Record<CardInfoVariants, boolean>
  >(
    initializeCardVariantsPopupState(CardInfoVariants) as Record<
      CardInfoVariants,
      boolean
    >
  );
  const [isOpen, setIsOpen] = useState<Record<CardInfoVariants, boolean>>(
    initializeCardVariantsPopupState(CardInfoVariants) as Record<
      CardInfoVariants,
      boolean
    >
  );

  const featureFlags = useLagoon('feature-flags')();
  const uiMessaging = useLagoon('ui-messaging')();
  const customPreferredPoliciesData: CustomPreferredPolicy[] = useLagoon(
    'custom-preferred-policies'
  )();

  const customPreferredPolicies = customPreferredPoliciesData?.map(
    (policy) => ({ ...policy, policyId: removeLeadingZeroes(policy.policyId) })
  );

  const customPreferredPoliciesWithLabel = customPreferredPoliciesData?.filter(
    (policy) => policy?.badgeLabel && policy?.icon
  );

  const memberHasRPK = useStore(StoreKeys.TIER1_PLAN_FLAG);
  const ddpCodeUser = getLoggedInMember()?.ddpCode;
  const ddpCode = practitioner?.ddpCode as FacilityDDPCode;

  const {
    providerType,
    isTieredProvider,
    isTier2Provider,
    isPremiumCarePhysician,
    isPreferredLab = false,
    isFreeStandingFacility = false,
    isOxPreferredLab,
  } = practitioner;

  const preferredFacilityFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PREFERRED_FACILITY_IDENTIFICATION
  );
  const currentMember = getCurrentMember();
  const isTier1ProviderEligible = displayTier1Identifier(isTieredProvider);

  const isDisplayTier1Identifier = getTierProviderEligibility(
    currentMember,
    isTier1ProviderEligible
  );

  const suppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const reciprocityId = getNetworkIdsForPES(
    currentMember,
    coverageType,
    featureFlags
  );

  const isDisplayTier2Identifier =
    currentMember?.isIFPTieredPlan && displayTier2Identifier(isTier2Provider);

  const isCustomPreferredPolicy: boolean = getIsCustomPreferredPolicy(
    customPreferredPoliciesWithLabel,
    currentMember?.policyNumber,
    reciprocityId
  );
  const isMultipleBadgeCardViewEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MULTIPLE_BADGE_DATA_CARD_VIEW
  );
  const showMultipleBadgeOnDataCard: boolean =
    isMultipleBadgeCardViewEnabled && cardType === CardTypeVariants.DATA_CARD;
  const showMultipleBadgeOnSnackCard: boolean =
    isMultipleBadgeCardViewEnabled &&
    cardType === CardTypeVariants.SNACK_CARD_INTERNAL;

  let showPremiumCare = displayPremiumCareIdentifier(
    !suppressPremiumCare,
    getPremiumCarePhysician(isPremiumCarePhysician),
    isDisplayTier1Identifier,
    isDisplayTier2Identifier,
    memberHasRPK,
    isCustomPreferredPolicy
  );

  if (
    isMultipleBadgeCardViewEnabled &&
    cardType != CardTypeVariants.MAP_DATA_CARD_INTERNAL
  ) {
    showPremiumCare =
      cardType === CardTypeVariants.DATA_CARD ||
      cardType === CardTypeVariants.SNACK_CARD_INTERNAL
        ? isPremiumCareDisplayEligible(
            !suppressPremiumCare,
            getPremiumCarePhysician(isPremiumCarePhysician)
          )
        : false;
  }

  const { isOXMember } = getIsOxfordAndUSP(
    currentMember,
    coverageType,
    featureFlags
  );

  const oxfordPlnFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN
  );

  const suppressFacilityFlags = getSuppressFacilityFlags();
  const showBenefitsBadge = isShowPreferredFacilityBadge(
    suppressFacilityFlags,
    preferredFacilityFlag,
    ddpCode,
    ddpCodeUser,
    isPreferredLab,
    isOxPreferredLab,
    isOXMember,
    isFreeStandingFacility,
    oxfordPlnFlag
  );

  const showGreenDiamondTag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.GREEN_DIAMOND_IDENTIFICATION
  );

  const isGreenDiamondIndicator = practitioner?.isGreenDiamondProvider;
  const variants: CardInfoVariants[] = [];

  const addCardInfoVariant = (
    condition: boolean,
    variantType: CardInfoVariants
  ) => {
    if (condition) variants.push(variantType);
  };

  if (showMultipleBadgeOnSnackCard) {
    addCardInfoVariant(showPremiumCare, CardInfoVariants.PREMIUM_CARE);
  } else {
    addCardInfoVariant(
      !!isGreenDiamondIndicator && !!showGreenDiamondTag,
      CardInfoVariants.PREFERRED_PROVIDER
    );
    addCardInfoVariant(
      showMultipleBadgeOnDataCard
        ? showPremiumCare
        : !isGreenDiamondIndicator && showPremiumCare,
      CardInfoVariants.PREMIUM_CARE
    );
    addCardInfoVariant(
      !isGreenDiamondIndicator &&
        !!displayPreferredFacilityOnCards(
          showPremiumCare,
          showBenefitsBadge,
          isDisplayTier1Identifier,
          isDisplayTier2Identifier
        ),
      CardInfoVariants.PREFERRED_FACILITY
    );
  }

  const locale = getLanguage().code;
  const premiumCareData = PremiumCareConstants[locale];
  const linkUrl = premiumCareData?.popoverURL ?? '';
  const premiumContent = premiumCareData?.popoverContent;

  const preferredFacilityContent = find(uiMessaging, {
    key: ConstantsLagoon.CONTENT_FILTERS.PREFERRED_FACILITY_CONTENT,
  })?.message;

  const preferredProviderContent = providerPreferredContent(
    currentMember,
    customPreferredPolicies
  );

  const preferredProviderHeading = providerPreferredHeading(
    currentMember,
    customPreferredPolicies
  );

  const preferredProviderIcon = providerPreferredIcon(
    currentMember,
    customPreferredPolicies
  );

  useEffect(() => {
    Object.keys(isMobileDrawerOpen).forEach((key) => {
      if (isMobileDrawerOpen[key]) {
        handleAdobeSearchResultClickAndModalEvent({
          filters: selectedFilters,
          linkName: `provider-${key}-drawer`,
          linkPosition: indexForAnalytics,
          linkLocation: locationForAnalytics,
          modalName:
            key === CardInfoVariants.PREMIUM_CARE
              ? Constants.PREMIUM_CARE_PAGE_LOCATION
              : Constants.PREFERRED_FACILITY_PAGE_LOCATION,
          customAttributesBlock: {
            correlationId: headers?.correlationId,
            providerId: formatProviderId(practitioner),
            providerType: convertProviderTypeToAdobeType(providerType),
          },
          term: searchTerm ?? '',
        });
      }
    });
  }, [isMobileDrawerOpen]);

  useEffect(() => {
    Object.keys(isOpen).forEach((key) => {
      if (isOpen[key]) {
        handleAdobeSearchResultClickAndModalEvent({
          filters: selectedFilters,
          linkName: `provider-${key}-popover`,
          linkPosition: indexForAnalytics,
          linkLocation: locationForAnalytics,
          modalName:
            key === CardInfoVariants.PREMIUM_CARE
              ? Constants.PREMIUM_CARE_PAGE_LOCATION
              : Constants.PREFERRED_FACILITY_PAGE_LOCATION,
          customAttributesBlock: {
            correlationId: headers?.correlationId,
            providerId: formatProviderId(practitioner),
            providerType: convertProviderTypeToAdobeType(providerType),
          },
          term: searchTerm ?? '',
        });
      }
    });
  }, [isOpen]);

  const ICON_MAP = {
    [CardInfoVariants.PREMIUM_CARE]: (
      <>
        <IconMaterial color="#002677" icon="favorite" size={16} />
        <IconMaterial color="#002677" icon="favorite" size={16} />
      </>
    ),
    [CardInfoVariants.PREFERRED_FACILITY]: null,
    [CardInfoVariants.PREFERRED_PROVIDER]: null,
  };

  const LINK_MAP = {
    [CardInfoVariants.PREMIUM_CARE]: (
      <LinkWithTracking
        analyticsInfo={{
          name: `${CardInfoVariants.PREMIUM_CARE}-benefits-link`,
          location: `modal:${Constants.PREMIUM_CARE_PHYSICIANS.TAG.toLowerCase()}`,
          type: 'external',
          destinationUrl: linkUrl,
        }}
        css={PremiumCarePopoverLinkStyle}
        data-auto-testid={`${CardInfoVariants.PREMIUM_CARE}-benefits-link`}
        data-testid={`${CardInfoVariants.PREMIUM_CARE}-benefits-link`}
        href={linkUrl}
      >
        {t('PREMIUM_CARE_BENEFITS.PREMIUM_CARE_LINK')}
      </LinkWithTracking>
    ),
    [CardInfoVariants.PREFERRED_FACILITY]: null,
    [CardInfoVariants.PREFERRED_PROVIDER]: null,
  };

  const TAG_MAP = {
    [CardInfoVariants.PREMIUM_CARE]: showMultipleBadgeOnDataCard
      ? t('PREMIUM_CARE_BENEFITS.PREMIUM_CARE_PHYSICIAN_TAG')
      : t('PREMIUM_CARE_BENEFITS.PREMIUM_CARE_TAG'),
    [CardInfoVariants.PREFERRED_FACILITY]: t('PREFERRED_FACILITY_BENEFITS.TAG'),
    [CardInfoVariants.PREFERRED_PROVIDER]: t(preferredProviderHeading),
  };

  const POPOVER_CONTENT_MAP = {
    [CardInfoVariants.PREMIUM_CARE]:
      premiumContent ?? t('PREMIUM_CARE_BENEFITS.PREMIUM_CARE_CONTENT'),
    [CardInfoVariants.PREFERRED_FACILITY]:
      preferredFacilityContent ?? t('PREFERRED_FACILITY_BENEFITS.CONTENT'),
    [CardInfoVariants.PREFERRED_PROVIDER]:
      preferredProviderContent ?? t('PREFERRED_PROVIDER_BENEFITS.CONTENT'),
  };

  const POPOVER_HEADING_MAP = {
    [CardInfoVariants.PREMIUM_CARE]: t(
      'PREMIUM_CARE_BENEFITS.PREMIUM_CARE_HEADING'
    ),
    [CardInfoVariants.PREFERRED_FACILITY]: t(
      'PREFERRED_FACILITY_BENEFITS.HEADING'
    ),
    [CardInfoVariants.PREFERRED_PROVIDER]: t(preferredProviderHeading),
  };

  const POPOVER_WIDTH_MAP = {
    [CardInfoVariants.PREMIUM_CARE]: 363,
    [CardInfoVariants.PREFERRED_FACILITY]: 220,
  };

  const webPreferredIndicator = (variant, cardType) => {
    const truncateLength = popupContainer
      ? 15.5
      : cardType === CardTypeVariants.SNACK_CARD_CONTAINER ||
        cardType === CardTypeVariants.SNACK_CARD_CAROUSEL
      ? 22.5
      : 52.5;

    const textTruncated = TAG_MAP?.[variant]?.length >= truncateLength;

    return !showMultipleBadgeOnSnackCard &&
      variant === CardInfoVariants.PREFERRED_PROVIDER ? (
      <PreferredProviderWeb
        data-auto-testid={`${variant}-popover`}
        data-testid={`${variant}-popover`}
        onClick={() => setIsOpen({ ...isOpen, [variant]: true })}
      >
        <GreenDiamond>
          <PSXIcon icon={preferredProviderIcon} size={16} />
        </GreenDiamond>
        {textTruncated ? (
          <div style={{ paddingTop: '5px' }}>
            <Tooltip
              content={TAG_MAP?.[variant]}
              position="top"
              positionOffset={8}
            >
              <TierCardText
                style={{
                  marginTop: '5px',
                  marginRight: '4px',
                  fontSize: '12px',
                }}
              >
                {truncateLargeStrings(TAG_MAP?.[variant], truncateLength)}
              </TierCardText>
            </Tooltip>
          </div>
        ) : (
          <TierCardText
            style={{ marginTop: '5px', marginRight: '4px', fontSize: '12px' }}
          >
            {truncateLargeStrings(TAG_MAP?.[variant], truncateLength)}
          </TierCardText>
        )}
      </PreferredProviderWeb>
    ) : (
      <PremiumInfoBox
        data-auto-testid={`${variant}-popover`}
        data-testid={`${variant}-popover`}
        onClick={() => setIsOpen({ ...isOpen, [variant]: true })}
      >
        {ICON_MAP[variant]}
        <TierCardText>{TAG_MAP?.[variant]}</TierCardText>
      </PremiumInfoBox>
    );
  };

  const mobilePreferredIndicator = (variant, cardType) => {
    const truncateLength =
      cardType === CardTypeVariants.SNACK_CARD_CONTAINER ||
      cardType === CardTypeVariants.SNACK_CARD_CAROUSEL
        ? 23
        : 52.5;

    return !showMultipleBadgeOnSnackCard &&
      variant === CardInfoVariants.PREFERRED_PROVIDER ? (
      <PreferredProviderMobile
        cssProps={{ landingPage }}
        data-auto-testid={`${variant}-popover`}
        data-testid={`${variant}-popover`}
        onClick={() =>
          setIsMobileDrawerOpen({ ...isMobileDrawerOpen, [variant]: true })
        }
      >
        <GreenDiamond>
          <PSXIcon icon={preferredProviderIcon} size={12} />
        </GreenDiamond>
        <TierCardText
          style={{ marginTop: '5px', marginRight: '4px', fontSize: '11px' }}
        >
          {truncateLargeStrings(TAG_MAP?.[variant], truncateLength)}
        </TierCardText>
      </PreferredProviderMobile>
    ) : (
      <PremiumInfoBox
        cssProps={{ landingPage }}
        data-auto-testid={`${variant}-popover`}
        data-testid={`${variant}-popover`}
        onClick={() =>
          setIsMobileDrawerOpen({ ...isMobileDrawerOpen, [variant]: true })
        }
      >
        {ICON_MAP[variant]}
        <TierCardText>{TAG_MAP?.[variant]}</TierCardText>
      </PremiumInfoBox>
    );
  };

  if (
    isGreenDiamondIndicator &&
    showGreenDiamondTag &&
    !preferredProviderIcon &&
    !preferredProviderHeading
  ) {
    return null;
  }

  return (
    <Flex>
      {!showMultipleBadgeOnSnackCard && isDisplayTier1Identifier && (
        <TierOne
          data-auto-testid={`tier-one-${cardType}-tag`}
          data-testid={`tier-one-${cardType}-tag`}
          isTierOne={isDisplayTier1Identifier}
        />
      )}
      {!showMultipleBadgeOnSnackCard &&
        !isGreenDiamondIndicator &&
        !isDisplayTier1Identifier &&
        isDisplayTier2Identifier && (
          <TierTwo
            data-auto-testid={`tier-two-${cardType}-tag`}
            data-testid={`tier-two-${cardType}-tag`}
            isTierTwo={isDisplayTier2Identifier}
          />
        )}
      {variants &&
        variants.map((variant, index) => (
          <div
            data-auto-testid={`${variant}-${cardType}-tag`}
            data-testid={`${variant}-${cardType}-tag`}
            key={index}
          >
            <Drawer
              css={PremiumCareDrawerStyles}
              data-auto-testid={`provider-${variant}-drawer`}
              data-testid={`provider-${variant}-drawer`}
              isOpen={isMobileDrawerOpen[variant]}
              onClose={() => {
                setIsMobileDrawerOpen({
                  ...isMobileDrawerOpen,
                  [variant]: false,
                });
                if (onClosePopup) onClosePopup();
              }}
              position="bottom"
              size="200"
              title={POPOVER_HEADING_MAP?.[variant]}
            >
              {POPOVER_CONTENT_MAP?.[variant]}
              {variant === CardInfoVariants.PREMIUM_CARE ? (
                <LinkWithTracking
                  analyticsInfo={{
                    name: `${variant}-benefits-link`,
                    location: `modal:${Constants.PREMIUM_CARE_PHYSICIANS.TAG.toLowerCase()}`,
                    type: 'external',
                    destinationUrl: linkUrl,
                  }}
                  css={PremiumCareDrawerLinkStyle}
                  data-auto-testid={`${variant}-benefits-link`}
                  data-testid={`${variant}-benefits-link`}
                  href={linkUrl}
                >
                  {t('PREMIUM_CARE_BENEFITS.PREMIUM_CARE_LINK')}
                </LinkWithTracking>
              ) : null}
            </Drawer>
            {mobileScreen ? (
              mobilePreferredIndicator(variant, cardType)
            ) : (
              <PremiumCareSnackCardPopoverStyles
                content={
                  <div>
                    <PremiumPopoverText>
                      {POPOVER_CONTENT_MAP?.[variant]}
                    </PremiumPopoverText>
                    {LINK_MAP[variant]}
                  </div>
                }
                cssProps={{ landingPage }}
                data-auto-testid={`provider-${variant}-popover`}
                data-testid={`provider-${variant}-popover`}
                onOpenChange={() =>
                  setIsOpen({ ...isOpen, [variant]: !isOpen[variant] })
                }
                open={isOpen[variant]}
                title={POPOVER_HEADING_MAP?.[variant]}
                width={POPOVER_WIDTH_MAP?.[variant]}
              >
                {webPreferredIndicator(variant, cardType)}
              </PremiumCareSnackCardPopoverStyles>
            )}
          </div>
        ))}
    </Flex>
  );
};
