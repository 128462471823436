import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Box } from '@abyss/web/ui/Box';
import { Card } from '@abyss/web/ui/Card';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import mapboxgl from 'mapbox-gl';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../../common/Constants';
import {
  compareCarouselScreen,
  expandedCarouselScreen,
} from '../../../../../common/ConstantsStyles';
import {
  slideContainerStyle,
  slideStyle,
} from '../../../../../common/PrimaryCareProvider/DependentsPCP/DependentPCP.styled';
import { makeAllSlidesAriaHidden } from '../../../../../common/Utils/a11yUtils/a11yUtils';
import { CustomAttributesBlock } from '../../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { getFeatureFlag } from '../../../../../common/Utils/getFeatureFlag';
import { useLagoon } from '../../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../../hooks/useStore/state';
import { useStore } from '../../../../../hooks/useStore/useStore';
import { CompareProvider } from '../../../../../models/Provider';
import { ResponseHeaders } from '../../../../../models/ResponseHeaders';
import { useChipStore } from '../../../../../store/useChipStore';
import { ChipState } from '../../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../../store/useDetailsStore/detailsStore';
import { useTypeaheadStore } from '../../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../../store/useTypeaheadStore/typeaheadStore';
import { successToast } from '../../ViewAll/Toast';
import { PrintCompareDrawer } from '../PrintMapView/PrintCompareDrawer';
import { displayFloatingSection } from '../ShareDrawer/ShareDrawer';
import {
  CompareDrawerContainer,
  compareEmptyCarouselStyle,
  getCarouselStyle,
  placeHolderTextStyle,
} from './CompareDrawer.style';
import { CompareButton } from './Components/Buttons/CompareButton';
import { CompareCard } from './Components/CompareCard/CompareCard';
import { CompareDrawerHeader } from './Components/CompareDrawerHeader/CompareDrawerHeader';
import { ConfirmationModal } from './Components/ConfirmationModal';
import { EditCompareDrawer } from './Components/EditCompare/EditCompareDrawer';
import { MockFalseProvider } from './MockProviders/MockFalseProvider';
import { MockNullProvider } from './MockProviders/MockNullProvider';
import { MockTrueProvider } from './MockProviders/MockTrueProvider';
import {
  CompareCheckboxInfo,
  adobeCompareDrawerLinkLocation,
} from './utility/compareDrawerConstants';
import { openTabOrNavigateOnClick } from './utility/handleDetailsOnClick';

type Props = {
  items: CompareCheckboxInfo[];
  setItems: React.Dispatch<React.SetStateAction<CompareCheckboxInfo[]>>;
  selectedCount: number;
  total: number;
  removeItem: (item: string) => void;
  selectedProviders: any[];
  setSelectedProviders: (a: CompareProvider[]) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setOpenCompare: (a: boolean) => void;
  map: React.MutableRefObject<mapboxgl.Map>;
  headers: ResponseHeaders;
};

export const CompareDrawer = ({
  headers,
  items,
  setItems,
  selectedCount,
  total,
  selectedProviders,
  setSelectedProviders,
  setSelectedCheckbox,
  removeItem,
  setOpenCompare,
}: Props) => {
  const { t } = useTranslation();
  const caretRef = React.useRef<HTMLButtonElement>(null);
  const deselectAllButtonRef = React.useRef<HTMLButtonElement>(null);

  const modal = useOverlay('confirmation-modal');
  const [isExpandedCardOpen, setIsExpandedCardOpen] = useState<boolean>(false);
  const [isCompareViewOpen, setIsCompareViewOpen] = useState<boolean>(false);
  const [isEditCompareOpen, setIsEditCompareOpen] = useState<boolean>(false);
  const { navigate } = useRouter();
  const [isShare, setIsShare] = useState(false);
  const [isCloseShareContents, setIsCloseShareContents] = useState(true);
  const { chipValue, coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      chipValue: state.chipValue,
    }))
  );
  const { search, sectionType, specialtyCode } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      search: state.typeaheadSearchStore.search,
      sectionType: state.typeaheadSearchStore.sectionType,
      specialtyCode: state.typeaheadSearchStore.specialtyCode,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );
  const [nameOfButtonClickedLast, setNameOfButtonClickedLast] =
    useState<string>('');

  const [cardHeight, setCardHeight] = useState(0);
  const [cardMaxHeight, setCardMaxHeight] = useState(560);
  const [changedTempItems, setChangedTempItems] = useState<
    CompareCheckboxInfo[]
  >([]);

  const toastMessage = t('TOAST.YOUR_RESULTS_HAVE_BEEN_SHARED');
  const closeAllDrawersAndOpenSuccessAlert = () => {
    setIsShare(false);
    // after integration, check for success here
    successToast(toastMessage);
  };
  const handleDetailsOnClickCb = (
    providerId,
    options,
    customAttributesBlock: CustomAttributesBlock
  ) => {
    openTabOrNavigateOnClick(
      providerId,
      navigate,
      options,
      { chipValue, coverageType },
      setDetailsStore,
      customAttributesBlock
    );
  };

  const handleClickShare = () => {
    setIsShare(true);
    setIsCloseShareContents(true);
  };

  const shareAllResults = false;
  const isOriginCompareProviders = true;

  // QA Testing code to manually insert mock providers
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const insertMockProviders = getFeatureFlag(
    featureFlags,
    'INSERT_NULL_PROVIDER'
  );

  useEffect(() => {
    if (insertMockProviders) {
      selectedProviders.splice(0, 1, MockTrueProvider);
      selectedProviders.splice(1, 1, MockFalseProvider);
      selectedProviders.splice(2, 1, MockNullProvider);
    }
  });
  // End of QA Testing code

  useEffect(() => {
    const updateMaxHeight = () => {
      const windowHeight = window.innerHeight;
      if (windowHeight < 716) {
        setCardMaxHeight(windowHeight * 0.5);
      } else {
        setCardMaxHeight(560);
      }
    };
    updateMaxHeight();
    window.addEventListener('resize', updateMaxHeight);
    return () => window.removeEventListener('resize', updateMaxHeight);
  }, []);

  useEffect(() => {
    if (nameOfButtonClickedLast === 'cancel') {
      setItems(JSON.parse(JSON.stringify(items)));
      setIsEditCompareOpen(false);
      setNameOfButtonClickedLast('');
    }

    if (nameOfButtonClickedLast === 'edit') {
      setItems(JSON.parse(JSON.stringify(items)));
      setIsEditCompareOpen(true);
      setNameOfButtonClickedLast('');
    }
    if (nameOfButtonClickedLast === 'reset') {
      setItems(JSON.parse(JSON.stringify(items)));
      setNameOfButtonClickedLast('');
    }
    if (nameOfButtonClickedLast === 'apply') {
      items.forEach((checkbox) => {
        const tempCheckbox = checkbox;
        const changedCheckBox = changedTempItems.find(
          (e) => e.name === checkbox.name
        );
        tempCheckbox.isChecked = changedCheckBox?.isChecked;
        return tempCheckbox;
      });
      if (changedTempItems?.length > 0) {
        setItems(JSON.parse(JSON.stringify(changedTempItems)));
      }
      setNameOfButtonClickedLast('');
      setIsEditCompareOpen(false);
    }
  }, [nameOfButtonClickedLast, changedTempItems]);

  const handleOnClickCarret = () => {
    adobeLinkTrackEvent({
      name: 'compare details',
      location: `modal:${adobeCompareDrawerLinkLocation}`,
      type: isExpandedCardOpen ? 'accordion close' : 'accordion open',
    });

    if (isExpandedCardOpen && isEditCompareOpen) {
      setNameOfButtonClickedLast('cancel');
      setIsEditCompareOpen(false);
    }
    setIsExpandedCardOpen(!isExpandedCardOpen);
  };
  makeAllSlidesAriaHidden();
  const itemsForCard = [
    ...items,
    {
      name: 'inNetwork',
      label: 'In-network',
      isChecked: true,
    },
  ] as CompareCheckboxInfo[];
  const expandedCardsScreen = useMediaQuery(expandedCarouselScreen);
  const notExpandedCardsScreen = useMediaQuery(compareCarouselScreen);

  const screenForCarouselDisplay =
    isExpandedCardOpen && isCompareViewOpen
      ? expandedCardsScreen
      : notExpandedCardsScreen;

  const carouselSelectedProviderSlides = Array.from(Array(5).keys()).map(
    (i) => (
      <Slide css={slideStyle}>
        <Slide.Container css={slideContainerStyle}>
          <Card
            css={{
              'abyss-card-root': {
                border: 'none',
                boxShadow: 'none',
              },
            }}
          >
            <Card.Section css={{ padding: '10px' }}>
              {selectedProviders[i] ? (
                <CompareCard
                  compareCheckboxes={itemsForCard}
                  handleDetailsOnClick={handleDetailsOnClickCb}
                  headers={headers}
                  index={i}
                  isCompareViewOpen={isCompareViewOpen}
                  isExpandedCardOpen={isExpandedCardOpen}
                  removeItem={removeItem}
                  selectedProvider={selectedProviders[i]}
                  setCardHeight={setCardHeight}
                />
              ) : (
                <Box
                  css={compareEmptyCarouselStyle}
                  height={
                    isExpandedCardOpen && isCompareViewOpen
                      ? `${cardHeight - 34}px`
                      : '96px'
                  }
                  index={i}
                  key={`compare-card-skeleton${i}`}
                >
                  <Text css={placeHolderTextStyle}>
                    {t('COMPARE_DRAWER_TEXT.CARD')}
                  </Text>
                </Box>
              )}
            </Card.Section>
          </Card>
        </Slide.Container>
      </Slide>
    )
  );

  // begin: a11y keyboard navigation
  useEffect(() => {
    if (selectedCount > 1) {
      caretRef?.current?.focus();
    }
  }, [selectedCount]);

  const { compareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const { indexOflastProviderSelected } = compareProvidersFlow;
  const setUIstate = useStore(StoreKeys.SET_UI_STATE);
  const uiState = useStore(StoreKeys.UI_STATE);

  const handleTabOutOfSelection = (e) => {
    if (e.shiftKey && e.key === 'Tab') {
      setUIstate({
        ...uiState,
        compareProvidersFlow: {
          indexOflastProviderSelected,
          shouldFocusLastIndex: true,
        },
      });
    }
  };

  const handleFocusIn = () => {
    setUIstate({
      ...uiState,
      compareProvidersFlow: {
        indexOflastProviderSelected,
        shouldFocusLastIndex: false,
      },
    });
  };
  // end: a11y keyboard navigation

  return (
    <React.Fragment>
      <CompareDrawerHeader
        caretRef={caretRef}
        deselectAllButtonRef={deselectAllButtonRef}
        handleClickShare={handleClickShare}
        handleFocusIn={handleFocusIn}
        handleOnClickCarret={handleOnClickCarret}
        handleTabOutOfSelection={handleTabOutOfSelection}
        isEditCompareOpen={isEditCompareOpen}
        isExpandedCardOpen={isExpandedCardOpen}
        modal={modal}
        selectedCount={selectedCount}
        setIsEditCompareOpen={setIsEditCompareOpen}
        setNameOfButtonClickedLast={setNameOfButtonClickedLast}
        total={total}
      />
      {isExpandedCardOpen && (
        <React.Fragment>
          {screenForCarouselDisplay ? (
            <CompareDrawerContainer
              css={{
                maxHeight: cardMaxHeight,
              }}
            >
              <Carousel
                css={getCarouselStyle({
                  isExpandedCardOpen,
                  isCompareViewOpen,
                })}
                data-auto-testid="my-dependent-pcp-carousel"
                data-testid="my-dependent-pcp-carousel"
                maxHeight={cardMaxHeight}
                minimal
                nextSlideOnClick={() => makeAllSlidesAriaHidden()}
                noLoop
                prevSlideOnClick={() => makeAllSlidesAriaHidden()}
                slideIndexOnClick={() => makeAllSlidesAriaHidden()}
                slides={carouselSelectedProviderSlides}
                slidesPerView={1}
              />
            </CompareDrawerContainer>
          ) : (
            <Layout.Group
              alignItems="top"
              css={{
                'abyss-layout-group': {
                  marginTop: '$lg',
                  marginLeft: '17px',
                  marginRight: '0px',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    '-webkit-appearance': 'none',
                    width: '7px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '4px',
                    backgroundColor: 'rgba(0,0,0,.5)',
                    boxShadow: '0 0 1px rgba(255,255,255,.5)',
                  },
                },
              }}
              data-auto-testid="compare-container"
              data-testid="compare-container"
              space={12}
            >
              {selectedProviders?.length > 0 &&
                selectedProviders.slice(0, 5).map((selectedProvider) => (
                  <div
                    key={selectedProvider}
                    style={{ maxHeight: cardMaxHeight }}
                  >
                    <CompareCard
                      compareCheckboxes={itemsForCard}
                      handleDetailsOnClick={handleDetailsOnClickCb}
                      headers={headers}
                      index={selectedProviders.indexOf(selectedProvider)}
                      isCompareViewOpen={isCompareViewOpen}
                      isExpandedCardOpen={isExpandedCardOpen}
                      removeItem={removeItem}
                      selectedProvider={selectedProvider}
                      setCardHeight={setCardHeight}
                    />
                  </div>
                ))}
              {Array.from({
                length: Math.max(0, 5 - selectedProviders.length),
              }).map((place, index) => (
                // it is okay to use index as key since there is nothing to sort on rerender
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} style={{ maxHeight: cardMaxHeight }}>
                  <Box
                    css={compareEmptyCarouselStyle}
                    height={
                      isExpandedCardOpen && isCompareViewOpen
                        ? `${cardHeight - 34}px`
                        : '96px'
                    }
                    index={index}
                    key={place}
                  >
                    <Text css={placeHolderTextStyle}>
                      {t('COMPARE_DRAWER_TEXT.CARD')}
                    </Text>
                  </Box>
                </div>
              ))}
            </Layout.Group>
          )}
        </React.Fragment>
      )}

      <CompareButton
        isCompareViewOpen={isCompareViewOpen}
        isExpandedCardOpen={isExpandedCardOpen}
        selectedCount={selectedCount}
        setIsCompareViewOpen={setIsCompareViewOpen}
        setIsExpandedCardOpen={setIsExpandedCardOpen}
      />
      <ConfirmationModal
        modal={modal}
        modalName="confirmation-modal"
        setItems={setItems}
        setOpenCompare={setOpenCompare}
        setSelectedCheckbox={setSelectedCheckbox}
        setSelectedProviders={setSelectedProviders}
      />
      <EditCompareDrawer
        caretRef={caretRef}
        deselectAllButtonRef={deselectAllButtonRef}
        isEditCompareOpen={isEditCompareOpen}
        items={items}
        nameOfButtonClickedLast={nameOfButtonClickedLast}
        setChangedTempItems={setChangedTempItems}
        setNameOfButtonClickedLast={setNameOfButtonClickedLast}
        setOpenEdit={setIsEditCompareOpen}
      />
      <PrintCompareDrawer
        compareCheckboxes={itemsForCard}
        selectedProviders={selectedProviders}
      />

      {isShare &&
        selectedProviders?.length > 0 &&
        displayFloatingSection(
          items,
          isCloseShareContents,
          setIsCloseShareContents,
          closeAllDrawersAndOpenSuccessAlert,
          shareAllResults,
          sectionType,
          search,
          specialtyCode,
          selectedProviders,
          isOriginCompareProviders
        )}
    </React.Fragment>
  );
};
