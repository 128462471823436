import { prependSpaceIfString } from "../../../../../../utils/string.utils";
import { CostIndicators } from "../../../../../../common/Constants";
import { useTranslation } from "react-i18next";

export const itemToShow = (name, costOption, showTierOnePlan, showTier2Plan) =>
  name !== 'inNetwork' &&
  (name === 'cost' ? costOption : true) &&
  (name === 'tierOneAndTwoProvider' ? showTier2Plan : true) &&
  (name === 'tierOneProvider' ? !showTier2Plan && showTierOnePlan : true);

export const getCheckboxLabel = (label) => {
  const checkboxLabel = label || '';
  return checkboxLabel;
};

export const isTieredOrVisionOrDental = (
  isTierOne: boolean,
  isDental: boolean,
  isVision: boolean
): boolean => isTierOne || isDental || isVision;

export const getInitialCheckedCount = (
  costOption,
  showTierOnePlan,
  showTier2Plan,
  items
): number =>
  items?.filter(
    (e) =>
      itemToShow(e.name, costOption, showTierOnePlan, showTier2Plan) &&
      e.isChecked === true
  ).length;

export const useUpdatedCostIndicatorLabel = ({
  costIndicator,
  practitioner,
}) => {
  const { t } = useTranslation();

  let label;
  let id;

  const hasName = practitioner?.firstName || practitioner?.middleName || practitioner?.lastName;

  switch (costIndicator) {
    case CostIndicators.$:
      label = t('COST_INDICATOR.BELOW_AVERAGE_LABEL');
      id = 'below-average-indicator';
      break;
    case CostIndicators.$$:
      label = t('COST_INDICATOR.AVERAGE_LABEL');
      id = 'average-indicator';
      break;
    case CostIndicators.$$$:
      label = t('COST_INDICATOR.ABOVE_AVERAGE_LABEL');
      id = 'above-average-indicator';
      break;
    default:
      label = '';
      id = '';
      break;
  }

  if (label && hasName) {
        label = `${label.replace(/[.]$/g, '')} ${t('FOR')}${prependSpaceIfString(
          practitioner?.firstName,
          practitioner?.middleName,
          practitioner?.lastName
        )}`;
      }

  return { label, id };
};

export default useUpdatedCostIndicatorLabel;