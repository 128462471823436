export const getFeatureFlag = <T extends { key: string; active: boolean }>(
  featureFlagsParam: T[] = [],
  flagKey: string = ''
) => {
  if (!flagKey) {
    return false;
  }
  return featureFlagsParam?.find?.((e) => e.key === flagKey)?.active ?? false;
};

interface IFeatureFlag {
  key: string;
  active: boolean;
}

export const getFeatureFlags = (featureFlags:IFeatureFlag[], flagKeys: string[]) =>
  flagKeys.map((flagItem) => getFeatureFlag(featureFlags, flagItem));

export const checkIsPreEffective = (
  featureFlagVal: boolean,
  isPreEfective: boolean
) => featureFlagVal && !isPreEfective;
