import { styled } from '@abyss/web/tools/styled';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';

enum SearchExperience {
  PLAN_TYPE = 'PLAN_TYPE',
  SPECIFIC_PLAN = 'SPECIFIC_PLAN',
  PROVIDER_NAME = 'PROVIDER_NAME',
}

const SearchExperienceCopy = {
  [SearchExperience.PLAN_TYPE]: {
    title: 'general plan type',
    description:
      'Search by Medicare, Employer and Individuals, or other plan type and location to see a general list of providers.',
    icon: 'medicare_supplement',
    cta: 'insurance plan type',
    href: '/select-lob',
  },
  [SearchExperience.SPECIFIC_PLAN]: {
    title: 'specific plan',
    description:
      'Choose an insurance plan to see which network providers are available in your area.',
    icon: 'u_card',
    cta: 'insurance plan',
    href: '/select-lob?planSelector=true',
  },
  [SearchExperience.PROVIDER_NAME]: {
    title: 'provider',
    description: 'Find a network provider, provider group, or facility.',
    icon: 'stethoscope',
    cta: 'provider',
    href: '/provider-search-by-name',
  },
};

const SearchTypeStyledCard = styled(Card, {
  '&.abyss-card-root': {
    width: '288px',
    borderRadius: '8px',
    padding: '0px',
    marginTop: '0px !important',
  },
});

export const ChooseSearchExperience = () => {
  const SearchTypeCard = (exp: SearchExperience) => (
    <SearchTypeStyledCard>
      <Card.Section
        css={{
          'abyss-card-section': {
            padding: '0px',
          },
        }}
      >
        <Layout.Stack
          css={{
            'abyss-layout-stack': {
              marginLeft: '8px',
              marginRight: '8px',
              height: '253px',
              alignItems: 'start',
            },
          }}
        >
          <IconBrand
            icon={SearchExperienceCopy[exp]?.icon}
            size="48px"
            css={{ paddingTop: '16px' }}
          />
          <Heading offset={1}>
            I would like to search by {SearchExperienceCopy[exp]?.title}
          </Heading>
          <Text size={'sm'}>{SearchExperienceCopy[exp]?.description}</Text>
        </Layout.Stack>
      </Card.Section>
      <Card.Section
        css={{
          'abyss-card-section': {
            padding: '0px',
          },
        }}
      >
        <Layout.Group
          css={{
            'abyss-layout-group': {
              marginLeft: '4px',
              height: '52px',
            },
          }}
        >
          <Link size={'sm'} href={SearchExperienceCopy[exp]?.href}>
            {`Explore by ${SearchExperienceCopy[exp].cta} >`}
          </Link>
        </Layout.Group>
      </Card.Section>
    </SearchTypeStyledCard>
  );

  return (
    <PageBody>
      <Heading textAlign="center">How would you like to search?</Heading>

      <Flex
        direction="row"
        justify="center"
        alignItems="baseline"
        style={{ gap: '16px', marginTop: '21px' }}
      >
        {SearchTypeCard(SearchExperience.PLAN_TYPE)}
        {SearchTypeCard(SearchExperience.SPECIFIC_PLAN)}
        {SearchTypeCard(SearchExperience.PROVIDER_NAME)}
      </Flex>
    </PageBody>
  );
};
