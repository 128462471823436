import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import find from 'lodash/find';
import pick from 'lodash/pick';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { adobeImpressionTrackEvent } from '../../../../common/AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeSearchModifiedEvent } from '../../../../common/AdobeTagging/adobeSearchModifiedEvent';
import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import {
  CareCategories,
  Constants,
  LOB,
  ReverseCoverageTypesCodes,
  errorCodeRouteMap,
} from '../../../../common/Constants';
import {
  FeatureFlags,
  SECTION_TERM,
} from '../../../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DisclaimersContainer } from '../../../../common/DisclaimersContainer/DisclaimersContainer';
import { SectionSkeleton } from '../../../../common/LazyLoaderSkeleton/SectionSkeleton';
import { NullResultsPage } from '../../../../common/NullResultsPage';
import { PSXHeader } from '../../../../common/PSXHeader';
import {
  CoverageType,
  MemberInfo,
} from '../../../../common/PSXHeader/ObapiDemo/memberProfile';
import { StillNeedHelp } from '../../../../common/StillNeedHelp';
import { convertObjectToUrlParams } from '../../../../common/Utils';
import {
  convertCoverageType,
  getIndicatorsForImpressions,
  getSearchLocation,
  getTier1Indicator,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import { useInitializeStore } from '../../../../hooks/useInitializeStore';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useSpecialtiesResult } from '../../../../hooks/useSpecialties';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { useTypeAheadData } from '../../../../hooks/useTypeAheadData';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import { useAnalyticsStore } from '../../../../store/useAnalyticsStore';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useErrorStore } from '../../../../store/useErrorStore';
import { usePCPStore } from '../../../../store/usePCPStore';
import { returnSuppressFlag } from '../../../../utils/featureSuppress';
import { getSuppressFacilityFlags } from '../../../../utils/providerDetails.utils';
import {
  getCurrentMember,
  getDependentInfo,
  getLoggedInMember,
  getMemberHasDesignatedNetwork,
  isOHCNSMember,
} from '../../../../utils/user.utils';
import { NullPageContainerStyled } from '../NullSpecialtyPage/NullSpecialtyPage.styles';
import { SearchedResultSectionData } from './SearchedResultSectionData';
import {
  getCorrectChipCategory,
  getSearchType,
  getSelectedCoverageType,
  getSpecialtyCode,
} from './SearchResults.UtilsV2';
import {
  getNoOfResultsForAnalytics,
  getProviderType,
  getProviderTypesFromDisplayedResults,
} from './searchUtils';
import {
  checkForPseudoRollupCodes,
  combineProvidersAcrossSections,
  compareAndReturn,
  getAllProvidersData,
  getFirstEnabledChip,
} from './utils';

export const SearchResultsV2 = () => {
  // TODO: Check if this required.
  // initialize store values from url searchParams for search results page
  const { providerType = '' } = useInitializeStore(
    ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
  );
  const { ACTIVE_MEMBER_INDEX } = Constants.STORAGE_KEYS.SESSION;
  const activeMemberIndex =
    Number(storage.session.get(ACTIVE_MEMBER_INDEX)) ?? 0;
  const storedMembers = useStore(StoreKeys.OBAPI_MEMBERS);
  const [memberProfile] = useState<MemberInfo[]>(storedMembers);
  const [virtualCareCardShow, setVirtualCareCardShow] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const storedLocation = useGeoLocationStorage();
  const mobileScreen = useMediaQuery(phoneOnly);
  const { isCountySearch } = useContext(CountySearchContext);
  const { clearSearchFilters } = useContext(SearchFilterContext);

  const coverageTypeFromStore = useCoverageType();
  const searchParamsObject = Object.fromEntries(searchParams.entries());
  const {
    searchType = '',
    search = '',
    coverageType: coverageTypeFromParams,
  } = searchParamsObject;
  const coverageType = coverageTypeFromParams || coverageTypeFromStore;
  // Todo: Need to fix specialityCodes search and check if this is still in use
  const { data: specialtyCodes = [] } = useSpecialtiesResult({
    coverageType,
  });

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const uiMessaging = useLagoon('ui-messaging')();
  const typeaheadPreferenceCodes = useLagoon('typeahead-preference')();
  const suppressFacilityFlags = getSuppressFacilityFlags();
  const suppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );
  const [eapCodeFlag, showTierProviderTag, enableNavigateToCorrectCategory] =
    useFeatureFlag([
      ConstantsLagoon.FEATURE_FLAGS.EAP_CODE,
      FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
        Constants.CHIPS_CATEGORIES.PRIMARY_CARE
      ][SECTION_TERM.TIER_ONE],
      ConstantsLagoon.FEATURE_FLAGS.ENABLE_NAVIGATE_TO_CORRECT_CATEGORY,
    ]);
  const isVirtualCareSuppressedForEmpire = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_EMPIRE
  );

  const memberHasRPK = useStore(StoreKeys.TIER1_PLAN_FLAG);
  const [breadcrumbUrls, setBreadcrumbUrls] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const isSpecialtySearch = searchType === Constants.SEARCH_TYPES.SPECIALTY;

  const isSearchModified = useRef(false);
  const [isPageTracked, setPageTracked] = useState<boolean>(false);

  const { dependentSeqNbr, choosePCP } = usePCPStore(
    useShallow((state: any) => ({
      choosePCP: state.pcpSearchState.choosePCP,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const { originLinkNameForAnalytics = '', setAnalyticsState } =
    useAnalyticsStore(
      useShallow((state: any) => ({
        originLinkNameForAnalytics:
          state.analyticsState.originLinkNameForAnalytics,
        setAnalyticsState: state.setAnalyticsState,
      }))
    );

  const { setErrorStore } = useErrorStore(
    useShallow((state: any) => ({
      setErrorStore: state.setErrorStore,
    }))
  );

  const { chipValue, setChipValue, setCoverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
      setChipValue: state.setChipValue,
      setCoverageType: state.setCoverageType,
    }))
  );

  const OHCNSpolicyIds = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.STATE_SPECIFIC_DISCLAIMER,
  })?.policyIDs;
  const isOHCNS = isOHCNSMember(OHCNSpolicyIds);

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();

  const ddpCodeUser = getLoggedInMember()?.ddpCode;

  const {
    isLoading,
    search: searchTitle,
    virtualCare,
    includeSpecialityRollupCodes,
    acceptingNewPatients,
    specialtyCodeFromQuery,
    preSelectEapFilter,
    userZip,
    category: searchCategory,
    pesKeyword,
    linkName,
    combinedIncludeSpecialityRollupCodes,
    searchMethod,
    searchTerm,
    medicalSpeciality,
    isMixedSuggestions,
    preferredFacilities,
    parallelCategoryCountResults,
    isEmptyResults,
    categoryCountResults,
    genderCode,
    virtualCareCode,
    essentialCommunityProviderCode,
    invokeLlmModel,
    combinedSearchResults,
    combinedSearchResultsError,
    providerIsLoading,
    providersResult,
    medGrpResult,
    organizationResult,
    orgIsLoading,
    medGrpIsLoading,
    providerHasError,
    showFacilities,
    showProviderGroups,
    showProviders,
  } = useTypeAheadData({});

  const specialtyCode: string = getSpecialtyCode(
    pesKeyword,
    searchCategory,
    specialtyCodes
  );

  const hasOnlyPseudoRollupCodes = checkForPseudoRollupCodes(
    includeSpecialityRollupCodes
  );

  useEffect(() => {
    clearSearchFilters();
  }, []);

  useEffect(() => {
    if (choosePCP) {
      adobePageTrackEvent();
      setPageTracked(true);
    }
  }, [choosePCP]);

  useEffect(() => {
    setPageTracked(false);
  }, [chipValue]);

  const allProviders = getAllProvidersData(combinedSearchResults, chipValue);
  const combineProviders = combineProvidersAcrossSections(
    combinedSearchResults,
    false
  );

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'overview',
    sitesectionLevel1: 'search results',
    sitesectionLevel2: 'category',
    providerBlock: {
      type: convertCoverageType(coverageType),
    },
    impressionBlock: {
      type: 'provider',
      indicator:
        combineProviders?.length > 0
          ? getIndicatorsForImpressions(
              combineProviders,
              featureFlags,
              false,
              {
                suppressPremiumCare,
                ddpCodeUser,
                suppressFacilityFlags,
                memberHasRPK,
                isCountySearch,
              },
              currentMember
            )
          : '',
      providerTier: getTier1Indicator(showTierProviderTag, allProviders),
    },
  });

  useEffect(() => {
    const totalNumberOfResults = getNoOfResultsForAnalytics(
      combinedSearchResults
    );
    if (!isLoading && chipValue && !choosePCP && totalNumberOfResults > 0) {
      const adobeSearchMethod = compareAndReturn(searchMethod, 'guided');
      const adobeSearchTerm =
        adobeSearchMethod === 'guided'
          ? ''
          : compareAndReturn(searchTerm, search);
      const adobeEventBody = {
        term: adobeSearchTerm,
        type: getProviderType(providerType),
        linkName: originLinkNameForAnalytics,
        method: adobeSearchMethod,
        filters: chipValue?.toLowerCase(),
        numberOfResults: getNoOfResultsForAnalytics(combinedSearchResults),
        customAttributesBlock: {
          providerType: getProviderTypesFromDisplayedResults(
            combinedSearchResults
          ),
          searchLocation: getSearchLocation(storedLocation),
        },
      };

      adobePageTrackEvent();
      setPageTracked(true);

      if (!isSearchModified.current) {
        adobeStandardSearchEvent(adobeEventBody);
        isSearchModified.current = true;
      } else {
        adobeSearchModifiedEvent(adobeEventBody);
      }

      if (isMixedSuggestions && isSpecialtySearch) {
        adobeImpressionTrackEvent({
          type: 'search modify',
          message:
            'search instead for providers that include typed term in their name',
          searchTerm: adobeSearchTerm,
          method: adobeSearchMethod,
        });
      }
      if (isMixedSuggestions && !isSpecialtySearch) {
        adobeImpressionTrackEvent({
          type: 'search modify',
          message: 'search instead for providers that can treat typed term',
          searchTerm: adobeSearchTerm,
          method: adobeSearchMethod,
        });
      }
    }
  }, [
    providerIsLoading,
    medGrpIsLoading,
    orgIsLoading,
    isSpecialtySearch,
    chipValue,
  ]);

  const noResultUrlParams = convertObjectToUrlParams({} as ChipState, {
    chipValue,
    includeSpecialityRollupCodes,
    linkName,
    medicalSpeciality,
    originLinkNameForAnalytics,
    search,
    searchType,
    specialtyCode,
  });

  const showMedicalSpecialityNSRPage =
    medicalSpeciality &&
    chipValue === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS;

  useEffect(() => {
    if (!isLoading && isEmptyResults && showMedicalSpecialityNSRPage) {
      navigate(
        `${ConstantsRoutes.NULL_SPECIALTY_RESULTS.path}/${noResultUrlParams}`
      );
    }
    if (combinedSearchResultsError) {
      const errorStoreValues = {
        uiSection: linkName || originLinkNameForAnalytics,
      };
      setErrorStore(errorStoreValues);
      const urlParams = convertObjectToUrlParams({} as ChipState, {
        ...errorStoreValues,
      });
      navigate(
        `${
          errorCodeRouteMap[providerHasError?.status] ||
          errorCodeRouteMap['4xx']
        }${urlParams}`
      );
    }
  }, [
    isLoading,
    combinedSearchResultsError,
    showMedicalSpecialityNSRPage,
    noResultUrlParams,
  ]);

  useEffect(() => {
    if (chipValue !== CareCategories.ALL) {
      document.title = chipValue
        ? `${t(`${chipValue}-results-for`)} ${searchTitle} | ${
            Constants.SITE_NAME
          }`
        : Constants.SITE_NAME;
    }
  }, [chipValue, searchTitle]);
  const activeMemberLOB = memberProfile?.[0]?.lineOfBusiness;

  useEffect(() => {
    if (activeMemberLOB === LOB.MNR || activeMemberLOB === LOB.CNS) {
      storage.session.set(ACTIVE_MEMBER_INDEX, '0');
    }
    let showVirtualCareCard: boolean = false;
    if (memberProfile[0]?.eligibility[0]?.memberHealthCoverage?.coverageType) {
      const coverageType =
        memberProfile[0]?.eligibility[0]?.memberHealthCoverage.coverageType.find(
          (x: CoverageType) =>
            x.typeCode &&
            x.typeCode.desc === Constants.COVERAGE_TYPECODE_TO_CHIP.M
        );

      if (coverageType && coverageType?.designatedNetwork) {
        showVirtualCareCard = getMemberHasDesignatedNetwork(
          coverageType,
          'DVCN'
        );
      }
    }

    showVirtualCareCard = memberProfile[0]?.isCnSMember
      ? !memberProfile[0].isCnSDsnpMember &&
        memberProfile[0].isTelemedicineEligible
      : showVirtualCareCard && !isVirtualCareSuppressedForEmpire;

    setVirtualCareCardShow(showVirtualCareCard);
  }, [
    memberProfile,
    activeMemberIndex,
    isVirtualCareSuppressedForEmpire,
    activeMemberLOB,
  ]);

  useEffect(() => {
    const { pathname, search } = location;
    breadcrumbUrls[
      ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
    ] = `${pathname}${search}`;
    const updatedBreadCrumbUrls = pick(
      breadcrumbUrls,
      ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
    );
    setBreadcrumbUrls(updatedBreadCrumbUrls);
  }, [searchParams]);

  const { specialityResults, nameResults } = categoryCountResults;
  const enabledChipForNameSearch = getFirstEnabledChip(nameResults);
  const enabledChipForSpecialitySearch = getFirstEnabledChip(specialityResults);

  const onSpecialtyResultClicked = (
    isSpecialtySearch: boolean,
    title: string = ''
  ) => {
    const { specialityResults } = categoryCountResults;
    const navigateToChipCategory = enableNavigateToCorrectCategory
      ? getCorrectChipCategory(
          combinedIncludeSpecialityRollupCodes,
          typeaheadPreferenceCodes,
          specialityResults
        )
      : '';

    const firstEnabledChip = isSpecialtySearch
      ? enabledChipForSpecialitySearch
      : enabledChipForNameSearch;
    const chipToSelect =
      isSpecialtySearch && navigateToChipCategory
        ? navigateToChipCategory
        : firstEnabledChip;

    const selectedCoverageType = getSelectedCoverageType(chipToSelect);
    setCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
    setChipValue(chipToSelect);
    setSearchParams(
      {
        ...searchParamsObject,
        chipValue: chipToSelect,
        coverageType: ReverseCoverageTypesCodes[selectedCoverageType],
        searchType: getSearchType(isSpecialtySearch),
      },
      { replace: true }
    );

    adobeLinkTrackEvent({
      name: title,
      location: 'body:result heading',
    });

    setAnalyticsState({ originLinkNameForAnalytics: title });
  };

  const getChipsToShowOnHeader = () => {
    if (enabledChipForNameSearch && !isSpecialtySearch) {
      return nameResults || [];
    }
    return specialityResults || [];
  };

  const breadcrumbs = [
    {
      title: t('BC Results'),
      href: '',
    },
  ];

  return (
    <React.Fragment>
      {!choosePCP ? (
        <PSXHeader
          breadcrumbs={breadcrumbs}
          categoryCount={getChipsToShowOnHeader()}
          dataTestId="search-results-search-form"
          isMixedResultsPage
          showChips={!isEmptyResults}
          showChoosePCPHeader={false}
          showMemberSelection={!mobileScreen}
          showSearchInputBackButton={mobileScreen}
        />
      ) : (
        <PSXHeader
          breadcrumbs={breadcrumbs}
          dataTestId="search-results-search-form"
          showChips={false}
          showChoosePCPHeader
          showMemberSelection={false}
        />
      )}
      {isLoading ||
      (!choosePCP && !isEmptyResults && chipValue === CareCategories.ALL) ? (
        <SectionSkeleton />
      ) : isEmptyResults && !showMedicalSpecialityNSRPage ? (
        <NullResultsPage
          emptyResults={isEmptyResults}
          onCommonSearchChipClicked={() => {}}
          searchTitle={searchTitle}
          specialtyCode={specialtyCode}
          specialtySearch={isSpecialtySearch}
        />
      ) : (
        <SearchedResultSectionData
          acceptingNewPatients={acceptingNewPatients}
          categoryCount={getChipsToShowOnHeader()}
          choosePCP={choosePCP || false}
          contextValue={chipValue}
          data={combinedSearchResults}
          dependentSeqNbr={dependentSeqNbr || ''}
          eapCodeFlag={eapCodeFlag}
          essentialCommunityProviderCode={essentialCommunityProviderCode}
          facilitiesHeaders={organizationResult.headers}
          genderCode={genderCode}
          hasOnlyPseudoRollupCodes={hasOnlyPseudoRollupCodes}
          invokeLlmModel={invokeLlmModel}
          isMixedResultsPage
          isMixedSuggestions={isMixedSuggestions}
          isNameSearchEnabled={enabledChipForNameSearch !== ''}
          isPageTracked={isPageTracked}
          isSpecialtySearchEnabled={
            hasOnlyPseudoRollupCodes || enabledChipForSpecialitySearch !== ''
          }
          medGrpIsLoading={medGrpIsLoading}
          medicalGroupHeaders={medGrpResult.headers}
          onSpecialtyResultClicked={onSpecialtyResultClicked}
          orgIsLoading={orgIsLoading}
          preSelectEapFilter={preSelectEapFilter}
          preferredFacility={preferredFacilities}
          providerHeaders={providersResult.headers}
          providerIsLoading={providerIsLoading}
          resultType={chipValue}
          searchMethod={searchMethod}
          searchTerm={searchTerm}
          searchTitle={searchTitle}
          searchType={getSearchType(isSpecialtySearch)}
          showChips={!isEmptyResults}
          showFacilities={showFacilities}
          showProviderGroups={showProviderGroups}
          showProviders={showProviders}
          specialtyCode={specialtyCode}
          specialtyCodeFromQuery={specialtyCodeFromQuery}
          specialtyResults={parallelCategoryCountResults?.specialityResults}
          specialtySearch={isSpecialtySearch}
          userZip={userZip}
          virtualCare={virtualCare}
          virtualCareCardShowFlag={virtualCareCardShow}
          virtualCareCode={virtualCareCode}
        />
      )}
      <NullPageContainerStyled hidden={!isEmptyResults}>
        <StillNeedHelp />
        {isOHCNS && <DisclaimersContainer />}
      </NullPageContainerStyled>
    </React.Fragment>
  );
};
