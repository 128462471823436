import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

import { benefitCardHeadingStyle } from '../ConstantsStyles';
import {
  CrisisSupportBox,
  CrisisSupportCardCta,
  CrisisSupportIcon,
  crisisSupportCardContainerStyle,
  crisisSupportCardContentStyle,
  crisisSupportCardStyle,
} from './CrisisSupport.style';

export type CrisisSupportProps = {
  data: any[];
  index: number;
};

const cardsBackgrounds = {
  0: '#E5F8FB',
  1: '#EFF9EF',
  2: '#EEF8FB',
};

export const CrisisSupportCard = ({ data, index }) => (
  <CrisisSupportBox
    aria-hidden="false"
    css={{
      ...crisisSupportCardStyle,
      background: cardsBackgrounds[index % 3],
    }}
  >
    <Flex css={crisisSupportCardContainerStyle}>
      <Heading
        css={benefitCardHeadingStyle}
        data-testid="crisis-support-card-heading"
        display="h6"
        offset={2}
      >
        {data?.suicideAndCrisisLifelineHeader}
      </Heading>
      <Text css={crisisSupportCardContentStyle}>
        {data?.suicideAndCrisisLifelineContent}
      </Text>

      <CrisisSupportCardCta
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: data?.suicideAndCrisisLifelineCta,
        }}
      />
    </Flex>

    {data?.icon ? (
      <CrisisSupportIcon icon={data?.icon} size="102" variant="twotone" />
    ) : null}
  </CrisisSupportBox>
);
