import { useForm } from '@abyss/web/hooks/useForm';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Text } from '@abyss/web/ui/Text';
import { TextInput } from '@abyss/web/ui/TextInput';

import { PopulationType } from '../../../../../common/Constants';

export const UpdateErrorModalSimulation = ({
  handleOnSubmit,
  handleOnReset,
}) => {
  const form = useForm();
  const populationTypeOptions = Object.entries(PopulationType).map(
    ([key, value]) => ({
      value: key,
      label: value,
    })
  );

  const onSubmit = () => {
    handleOnSubmit(
      form.getValues('errorCodeInput'),
      form.getValues('errorMessageInput'),
      form.getValues('populationType')
    );
  };

  return (
    <div>
      <FormProvider
        data-testid="update-pcp-error-testing-form"
        onSubmit={onSubmit}
        state={form}
      >
        <Text fontWeight="$bold" size="$lg">
          Simulate Update PCP Error Modal Scenarios
        </Text>
        <Layout.Space />
        <Text size="$sm">
          Directions: To simulate different error modal scenarios, add an error
          code, and if desired error message and population. Then click Save in
          this form and click Save location button to see the error modal. To
          reset the form or to end the error simulation after you've previously
          saved error scenario content, click Reset.
        </Text>
        <Layout.Space />
        <TextInput
          data-testid="error-code-input"
          isClearable
          label="Error Code"
          model="errorCodeInput"
          validators={{ required: true }}
        />
        <TextInput
          data-testid="error-message-input"
          isClearable
          label="Error Message"
          model="errorMessageInput"
        />
        <Layout.Space />
        <SelectInput
          data-auto-testid="pick-one-population-type"
          data-testid="pick-one-population-type"
          label="Population Type"
          model="populationType"
          options={populationTypeOptions}
          placeholder="Select Population Type"
        />
        <Layout.Space />
        <Button style={{ marginRight: '10px' }} type="submit">
          Save
        </Button>
        <Button data-testid="reset-button" onClick={handleOnReset} type="reset">
          Reset
        </Button>
      </FormProvider>
    </div>
  );
};
