import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import { HcbsService } from '#/models/ProviderDetails';
import { find } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { useLagoon } from '../../../../hooks/useLagoon';
import { capitalizeFirstLetters } from '../../../../utils/providerNames.utils';
import { InfoProviderContent } from '../InfoProviderContent';
import {
  HcbsSubHeadingListStyles,
  HcbsSubHeadingText,
} from '../ProviderLocationsDetails.style';

type Props = {
  hcbsServiceData: HcbsService;
  notAvailableOrUnknownText: string;
};

export const HcbsServiceInfo = ({
  hcbsServiceData,
  notAvailableOrUnknownText,
}: Props) => {
  const { t } = useTranslation();
  const data = useLagoon('ui-messaging')();
  const hcbsContent = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.HCBS_TOOLTIP_INFO_ICON,
  });

  return (
    <InfoProviderContent
      icon="home_work"
      iconType="symbol"
      infoTitle={t(
        'PROVIDER_LOCATIONS_DETAILS.HOME_AND_COMMUNITY_BASED_SERVICES.HEADING'
      )}
      isHcbs
      popoverContent={hcbsContent?.message}
      popoverTitle={hcbsContent?.title}
      showPopover
      variant="filled"
    >
      <Flex
        css={{
          gap: '8px',
        }}
        direction="column"
      >
        <Flex
          css={{ gap: '4px' }}
          data-testid="sub-heading-hcbs-services"
          direction="column"
        >
          <HcbsSubHeadingText>
            {t(
              'PROVIDER_LOCATIONS_DETAILS.HOME_AND_COMMUNITY_BASED_SERVICES.SUB_HEADING_SERVICES'
            )}
          </HcbsSubHeadingText>
          {hcbsServiceData.serviceCategory?.length > 0 ? (
            <Text>
              <HcbsSubHeadingListStyles>
                {hcbsServiceData.serviceCategory?.map((services) => (
                  <li
                    data-testid="hcbs-service-category"
                    key={services}
                    style={{ paddingBottom: '4px' }}
                  >
                    {capitalizeFirstLetters(services)}
                  </li>
                ))}
              </HcbsSubHeadingListStyles>
            </Text>
          ) : (
            <Text>
              <HcbsSubHeadingListStyles>
                <li>
                  <Text> {notAvailableOrUnknownText}</Text>
                </li>
              </HcbsSubHeadingListStyles>
            </Text>
          )}
        </Flex>
        <Flex
          css={{ gap: '4px' }}
          data-testid="sub-heading-hcbs-waivers"
          direction="column"
        >
          <HcbsSubHeadingText>
            {t(
              'PROVIDER_LOCATIONS_DETAILS.HOME_AND_COMMUNITY_BASED_SERVICES.SUB_HEADING_WAIVERS'
            )}
          </HcbsSubHeadingText>
          {hcbsServiceData.waivers?.length > 0 ? (
            <Text>
              <HcbsSubHeadingListStyles>
                {hcbsServiceData.waivers?.map((waiver) => (
                  <li
                    data-testid="hcbs-waiver"
                    key={waiver}
                    style={{ paddingBottom: '4px' }}
                  >
                    {capitalizeFirstLetters(waiver)}
                  </li>
                ))}
              </HcbsSubHeadingListStyles>
            </Text>
          ) : (
            <Text>
              <HcbsSubHeadingListStyles>
                <li>
                  <Text> {notAvailableOrUnknownText}</Text>
                </li>
              </HcbsSubHeadingListStyles>
            </Text>
          )}
        </Flex>
        <Flex
          css={{ gap: '4px' }}
          data-testid="sub-heading-hcbs-counties-serviced"
          direction="column"
        >
          <HcbsSubHeadingText>
            {t(
              'PROVIDER_LOCATIONS_DETAILS.HOME_AND_COMMUNITY_BASED_SERVICES.SUB_HEADING_COUNTIES_SERVICED'
            )}
          </HcbsSubHeadingText>
          {hcbsServiceData.countyServices?.length > 0 ? (
            <Text>
              <HcbsSubHeadingListStyles>
                {hcbsServiceData.countyServices?.map((county) => (
                  <li
                    data-testid="hcbs-county-services"
                    key={county}
                    style={{ paddingBottom: '4px' }}
                  >
                    {capitalizeFirstLetters(county)}
                  </li>
                ))}
              </HcbsSubHeadingListStyles>
            </Text>
          ) : (
            <Text>
              <HcbsSubHeadingListStyles>
                <li>
                  <Text> {notAvailableOrUnknownText}</Text>
                </li>
              </HcbsSubHeadingListStyles>
            </Text>
          )}
        </Flex>
      </Flex>
    </InfoProviderContent>
  );
};
