import { Label } from '@abyss/web/ui/Label';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Modal } from '@abyss/web/ui/Modal';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ModalWithTracking } from '../ModalWithTracking/ModalWithTracking';
import { getBestMatchOptionKey } from './SortByUtil';
import { useCoverageType } from '../../hooks/useCoverageType';

type Props = {
  dataForAnalytics: DataForAnalytics;
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
};
export type CustomAttributesBlock = {
  correlationId: string;
  providerId: string;
  providerType: string;
};
interface DataForAnalytics {
  linkName: string;
  linkLocation: string;
  modalName: string;
  linkType?: string;
  customAttributesBlock?: CustomAttributesBlock;
}
export const InfoModal = ({ dataForAnalytics, isOpen, setIsOpen }: Props) => {
  const data = useLagoon('ui-messaging')();
  const modalContent = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.INFO_MODAL_CONTENT,
  });

  const { t } = useTranslation();
  const coverageType = useCoverageType();

  return (
    <ModalWithTracking
      analyticsInfo={{
        ...dataForAnalytics,
      }}
      css={{
        'abyss-modal-header-title': {
          color: '$gray7',
        },
      }}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={<Label size="$xl">{t(`SORT_OPTIONS.${getBestMatchOptionKey(coverageType)}`)}</Label>}
      trackingType={Constants.ADOBE_TRACKING.MODAL_WITH_LINK_TRACKING}
    >
      <Modal.Section
        css={{
          'abyss-modal-section-root': {
            paddingBottom: '32px',
          },
        }}
      >
        <Markdown>{modalContent?.message ?? ''}</Markdown>
      </Modal.Section>
    </ModalWithTracking>
  );
};
