"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUESSpecialityTranslationsEN = getUESSpecialityTranslationsEN;
exports.getNewPESRollUpSpecialityTranslationsEN = getNewPESRollUpSpecialityTranslationsEN;
exports.getUesAoeTranslationsEN = getUesAoeTranslationsEN;
exports.getModuleOrDefault = getModuleOrDefault;
exports.getCurrentLocale = getCurrentLocale;
exports.getDynamicContentLocale = getDynamicContentLocale;
// Added for the native app, Metro doesn't support dynamic requires
function getUESSpecialityTranslationsEN() {
    return require("../data/en/uesSpecialityTranslations.json");
}
function getNewPESRollUpSpecialityTranslationsEN() {
    return require("../data/en/newPesRollUpSpecialityTranslations.json");
}
function getUesAoeTranslationsEN() {
    return require("../data/en/uesAoeTranslation.json");
}
function getModuleOrDefault(localeCode, fileName) {
    try {
        return require("../data/".concat(localeCode, "/").concat(fileName, ".json"));
    }
    catch (error) {
        if ((error === null || error === void 0 ? void 0 : error.code) === "MODULE_NOT_FOUND") {
            return require("../data/en/".concat(fileName, ".json"));
        }
        throw error;
    }
}
/**
 * Get the current two-character locale (e.g. 'en' or 'es')
 */
function getCurrentLocale(locale) {
    if (locale === void 0) { locale = "en"; }
    return locale.slice(0, 2);
}
/**
 * Get the current locale with fallback to 'en'.
 * @description
 * Since we currently only have english and spanish files for dynamic content,
 * we should default to english so content will still appear for users with other locales set
 */
function getDynamicContentLocale(locale) {
    var localeCode = getCurrentLocale(locale);
    if (["en", "es"].includes(localeCode)) {
        return localeCode;
    }
    return "en";
}
function getDynamicContentPaths(locale) {
    var localeCode = getDynamicContentLocale(locale);
    return {
        accessibility: getModuleOrDefault(localeCode, "accessibilityTranslations"),
        age: getModuleOrDefault(localeCode, "ageTranslations"),
        aoe: getModuleOrDefault(localeCode, "aoeTranslations"),
        behaviouralPrograms: getModuleOrDefault(localeCode, "behaviouralProgramsTranslations"),
        culturalCompetency: getModuleOrDefault(localeCode, "culturalCompetencyTranslations"),
        culturalCompetencyCategories: getModuleOrDefault(localeCode, "culturalCompetencyCategoriesTranslations"),
        carePathCategory: getModuleOrDefault(localeCode, "carePathCategoryTranslations"),
        degree: getModuleOrDefault(localeCode, "degreeTranslations"),
        ethnicityCode: getModuleOrDefault(localeCode, "ethnicityTranslations"),
        hcbsWaivers: getModuleOrDefault(localeCode, "hcbsWaiversTranslations"),
        raceCode: getModuleOrDefault(localeCode, "raceTranslations"),
        behavioralFacilityPrograms: getModuleOrDefault(localeCode, "behavioralFacilityProgramsTranslations"),
        platinumPrograms: getModuleOrDefault(localeCode, "platinumProgramsTranslations"),
        facilityServiceCode: getModuleOrDefault(localeCode, "facilityServiceCodeTranslations"),
        facilitySubCategoryCode: getModuleOrDefault(localeCode, "facilitySubCategoryCodeTranslations"),
        gender: getModuleOrDefault(localeCode, "genderTranslations"),
        language: getModuleOrDefault(localeCode, "languageTranslations"),
        pesSpecialty: getModuleOrDefault(localeCode, "pesSpecialityTranslations"),
        pesFacility: getModuleOrDefault(localeCode, "pesFacilityTranslations"),
        preferredFacility: getModuleOrDefault(localeCode, "preferredFacilityTranslations"),
        qualityRating: getModuleOrDefault(localeCode, "qualityRatingTranslations"),
        leapFrogRatings: getModuleOrDefault(localeCode, "leapFrogRatingCategories"),
        sourceSystemSpecialty: getModuleOrDefault(localeCode, "sourceSystemSpecialityTranslations"),
        servicesOffered: getModuleOrDefault(localeCode, "servicesOfferedTranslations"),
        accommodateOffered: getModuleOrDefault(localeCode, "accommodateOfferedTranslations"),
        methodsOfService: getModuleOrDefault(localeCode, "methodsOfServiceTranslations"),
        uesSpecialty: getModuleOrDefault(localeCode, "uesSpecialityTranslations"),
        newPesRollUpSpecialty: getModuleOrDefault(localeCode, "newPesRollUpSpecialityTranslations"),
        virtualCare: getModuleOrDefault(localeCode, "virtualCareTranslations"),
        virtualCareOptions: getModuleOrDefault(localeCode, "virtualCareOptionsTranslations"),
        ddp: getModuleOrDefault(localeCode, "ddpTranslations"),
        carePathSteps: getModuleOrDefault(localeCode, "costExperienceCarePathsTranslations"),
        docAsapAvailability: getModuleOrDefault(localeCode, "docAsapAvailabilityTranslations"),
        servicesProvided: getModuleOrDefault(localeCode, "servicesProvidedTranslations"),
        uesAoe: getModuleOrDefault(localeCode, "uesAoeTranslation"),
    };
}
exports.default = getDynamicContentPaths;
