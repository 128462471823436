export const uhcThemeOverride = {
  theme: {
    colors: {
      lightGrey: '#D0D0D0',
      lightBlue: '#D9F6FA',
      cardBorder: '#E5E5E6',
      neutralGray: '#4B4D4F',
      neutralGray2: '#F2F2F2',
      neutralGray5: '#6E7072',
      descriptionBlack: '#19191A',
      neutralGray7: '#323334',
      neutralGray4: '#CBCCCD',
      starBorder: '#D2800F',
      bgHeaderColor: '#FBFCFE',
      icColor: '#323334',
      tcolor: '#196ECF',
      bgProviderDetailsColor: '$bgHeaderColor',
      tabsContainerBackgroundColor: '#FAFAFA',
      tabButtonBackgroundColor: '#FAFAFA',
      tabSelectionTextColor: '#323334',
      whereToGetHelpCardBg1: '$info2',
      whereToGetHelpCardBg2: '#FCF6F1',
      whereToGetHelpCardBg3: '$tint2',
      tabNotSelectedTextColor: '#196ECF',
      snackCardAvatarBackgroundColor: '#EEF8FB',
      milesTextColor: '$accent1',
      headerCardWrapperBorderColor: '$cardBorder',
      virtualVisitIconColor: '$primary1',
      searchBorderColor: '$lightGrey',
      recentVisitCardSliderBg: '$white',
      memeberInitialsBgColor: '#EEF8FB',
      memeberInitialsTextColor: '$primary1',
      providerDetailsDrawerHeaderBgColor: '$primary1',
      providerDetailsDrawerHeaderLoadingBgColor: '$primary1',
      providerDetailsDrawerHeaderTextColor: '$primary2',
      providerDetailsDrawerHeaderHighlightColor: '$primary2',
      providerDetailsDrawerHeaderInNetworkStatusIconColor: '$primary2',
      providerDetailsDrawerHeaderInNetworkStatusLabelColor: '$primary2',
      providerDetailsDrawerLocationsDropdownOptionColor: '$primary1',
      searchIconColor: '#196ECF',
    },
    fontSizes: {
      extraSmallText: '12.64px',
      smallText: '14.24px',
      mediumText: '20.25px',
      largeText: '25.63px',
      dateInfoText: '12.64px',
      providerDetailsDrawerBHFooterTimeStampText: '$md',
      providerDetailsDrawerBHFooterDateText: '$md',
    },
    fontWeights: {
      memeberInitialsFW: '500',
      providerDetailsDrawerHeaderInNetworkFW: '$medium',
      providerDetailsDrawerBHFooterDateFW: '$medium',
    },
    lineHeights: {
      lg: '24px',
      xl: '32px',
      md: '20px',
      sm: '16px',
    },
    space: {
      wrapperPadding: '24px 0',
      headerWrapperPadding: '24px 0',
      tabButtonsPadding: '5px 0 5px 0',
      recentSearchBottomGap: '8px',
      recentActivityTopPadding: '24px',
      popoverContainerTop: '3px',
    },
    borderWidths: {
      recentActivityBorder: '1px',
    },
    sizes: {
      iconBrandSize: '72px',
      dataCardForMapIconSize: '49px',
    },
  },
};
