import { getModuleOrDefault } from '@optum-fpc-psx-mobile-apps/mapping-files-translation';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  CareCategories,
  Constants,
  EAP_OPTION_LABEL,
  FACILITIES_M1,
  FilterCollectionModel,
  PAGE_SIZE,
  ProviderType,
  SectionType,
} from '../../common/Constants';
import { ConstantsRoutes } from '../../common/ConstantsRoutes';
import { areFiltersEqual } from '../../common/Utils/filters.utils';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { getConditionAoeCodes } from '../../frontends/ProviderSearch/routes/SearchResults/SearchResults.UtilsV2';
import {
  compareAndReturn,
  getAoeCodesForEAP,
} from '../../frontends/ProviderSearch/routes/SearchResults/utils';
import { Option, ProviderSearchFilters } from '../../models/ProviderSearch';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { usePCPStore } from '../../store/usePCPStore';
import { useSearchStore } from '../../store/useSearchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import {
  getIncludeSpecialityRollupCodesFilteredByCategory,
  getOrgTypeCodes,
  getProviderTypeBySectionType,
} from '../../utils/typeahead.utils';
import { useLagoon } from '../useLagoon';
import { useFilterProviderResults } from '../useProviderSearch';
import { useTypeAheadData } from '../useTypeAheadData';

const getInitialPageResults = (
  sectionType: SectionType,
  providersResult: any,
  medGrpResult: any,
  organizationResult: any
) => {
  if (sectionType === SectionType.PROVIDER) {
    return providersResult;
  }
  if (sectionType === SectionType.PROVIDER_GROUP) {
    return medGrpResult;
  }
  return organizationResult;
};

interface ProviderSearchResult {
  isTieredPlan: boolean;
  results: any[];
  searchRadius: number;
  totalResultsCount: number;
  isLoading: boolean;
  headers: { correlationId: string };
  selectedFilters: ProviderSearchFilters;
}

export const useViewAllData = ({
  selectedFilters,
  pageNumber,
  breadcrumbUrls,
  pageSize,
  filters,
  coverageType,
  shouldGetHGData,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { setDefaultSearchFilters } = useContext(SearchFilterContext);
  const deepLinkMapping = useLagoon(Constants.LAGOON_TABLE.DEEPLINK_MAPPING)();
  const aoeTranslations = getModuleOrDefault('en', 'aoeTranslations');
  const uesTranslation = getModuleOrDefault('en', 'uesSpecialityTranslations');
  const searchParamsObject = Object.fromEntries(searchParams.entries());
  const { searchType, chipValue, sectionType, choosePCP, source } =
    searchParamsObject;
  const providerType = getProviderTypeBySectionType(sectionType as SectionType);
  const viewAllDataTestId = `results-section-${sectionType}-view-all`.replace(
    / /g,
    '-'
  );

  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: any) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const { setPCPSearchState, dependentSeqNbr } = usePCPStore(
    useShallow((state: any) => ({
      setPCPSearchState: state.setPCPSearchState,
      choosePCP: state.pcpSearchState.choosePCP,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const { setSearchStore } = useSearchStore(
    useShallow((state: any) => ({
      setSearchStore: state.setSearchStore,
    }))
  );
  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: any) => ({
      typeaheadSearchStore: state.typeaheadSearchStore,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );

  const [initialPageResults, setInitialPageResults] =
    useState<ProviderSearchResult>({
      results: [],
      totalResultsCount: 0,
      isLoading: sectionType && !source ? false : true,
      isTieredPlan: false,
      searchRadius: 5,
      headers: { correlationId: '' },
      selectedFilters: {},
    });

  const onCompleted = (data: any) => {
    const {
      providersResult,
      medGrpResult,
      organizationResult,
      typeAheadData,
      genderCode,
      virtualCareCode,
      essentialCommunityProviderCode,
      acceptingNewPatients,
      preSelectEapFilter,
      virtualCare,
      specialtyCodeFromQuery,
      specialtyCode,
      preferredFacilities: preferredFacility,
      invokeLlmModel,
    } = data;

    if (sectionType && !source) {
      const initialPageResults = {
        results: [],
        totalResultsCount: 0,
        isLoading: false,
        isTieredPlan: false,
        searchRadius: 0,
        headers: { correlationId: '' },
        selectedFilters: {},
      };

      setInitialPageResults(initialPageResults);
      return;
    }

    const results = getInitialPageResults(
      sectionType as SectionType,
      providersResult,
      medGrpResult,
      organizationResult
    );

    const { totalCount, searchRadius } = results;
    const { combinedIncludeSpecialityRollupCodes, condition } = typeAheadData;
    const { areaOfExpertise } = getConditionAoeCodes(
      condition,
      deepLinkMapping,
      aoeTranslations
    );

    const {
      label = '',
      includeOrgTypeCodes = [],
      coverages = [],
      uesType = '',
    } = Constants.CARE_CATEGORIES_NAME_PAYLOAD.find(
      (category) => category.label === chipValue
    ) || {};
    const codeByGroup = combinedIncludeSpecialityRollupCodes?.split(',') || [];
    const categoryCoverageType = coverages?.[0]; // can we use coverageType ?

    const includeSpecialityRollupCodesFilteredByCategory: string[] =
      getIncludeSpecialityRollupCodesFilteredByCategory(
        uesTranslation,
        codeByGroup,
        uesType
      );

    const orgTypeCodes = getOrgTypeCodes(
      label,
      includeOrgTypeCodes,
      FACILITIES_M1,
      searchType
    );

    const eapOption = areaOfExpertise?.filter(
      (option: Option) => option.label === EAP_OPTION_LABEL
    );
    const eapOptionValue = getAoeCodesForEAP().toString();
    if (
      preSelectEapFilter &&
      eapOption.length < 1 &&
      providerType !== ProviderType.ORGANIZATION
    ) {
      areaOfExpertise.push({
        label: t(EAP_OPTION_LABEL),
        value: eapOptionValue,
        dnt_label: EAP_OPTION_LABEL,
      });
    }

    const defaultFilters: FilterCollectionModel[] = [
      FilterCollectionModel.AUTO_SEARCH_RADIUS,
      FilterCollectionModel.DISTANCE,
      ...(acceptingNewPatients
        ? [FilterCollectionModel.ACCEPTING_NEW_PATIENTS]
        : []),
      ...(virtualCare ? [FilterCollectionModel.VIRTUAL_CARE] : []),
      ...(preferredFacility?.length > 0
        ? [FilterCollectionModel.PREFERRED_FACILITY]
        : []),
      ...(areaOfExpertise?.length > 0
        ? [FilterCollectionModel.AREA_OF_EXPERTISE]
        : []),
      ...(specialtyCodeFromQuery?.length > 0
        ? [FilterCollectionModel.SPECIALTY_CODE]
        : []),
      ...(genderCode?.length > 0 ? [FilterCollectionModel.GENDER_CODE] : []),
      ...(virtualCareCode ? [FilterCollectionModel.VIRTUAL_CARE] : []),
      ...(essentialCommunityProviderCode
        ? [FilterCollectionModel.ESSENTIAL_COMMUNITY_PROVIDER]
        : []),
    ];

    const selectedFilters = setDefaultSearchFilters(defaultFilters, {
      autoSearchRadius: searchRadius,
      preferredFacility,
      AreaOfExpertise: areaOfExpertise,
      SpecialtyCode: specialtyCodeFromQuery,
      GenderCode: genderCode,
      VirtualCare: virtualCareCode,
      EssentialCommunityProvider: essentialCommunityProviderCode,
    });
    setAnalyticsState({ linkName: viewAllDataTestId });
    setSearchStore({
      totalCount,
      includeOrgTypeCodes: orgTypeCodes?.includeOrgTypeCodes,
      excludeOrgTypeCodes: orgTypeCodes?.excludeOrgTypeCodes,
      searchRadius,
    });
    setPCPSearchState({
      pcpIndicator: compareAndReturn(
        choosePCP,
        chipValue === CareCategories.PRIMARY_CARE
      ),
      dependentSeqNbr,
    });

    // TODO check if all thes are required
    setTypeaheadSearchStore({
      specialtyCode,
      sectionType,
      providerType,
      includeSpecialityRollupCodes:
        searchType === 'name'
          ? ''
          : includeSpecialityRollupCodesFilteredByCategory.join(','),
      acceptingNewPatients,
      ...(categoryCoverageType && {
        coverageType: categoryCoverageType,
      }),
      invokeLlmModel,
      preSelectEapFilter,
      isTypeAheadDataLoaded: true,
    });

    const initialPageResults = {
      results: results.providers,
      totalResultsCount: results.totalCount,
      isLoading: false,
      isTieredPlan: results.isTieredPlan,
      searchRadius: results.searchRadius,
      headers: results.headers,
      selectedFilters,
    };

    setInitialPageResults(initialPageResults);
  };

  useTypeAheadData({ onCompleted });

  const initialPage = !!(
    initialPageResults.isLoading ||
    (initialPageResults.results.length &&
      areFiltersEqual(selectedFilters, initialPageResults.selectedFilters) &&
      pageNumber === 1 &&
      breadcrumbUrls?.[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key] &&
      pageSize === PAGE_SIZE)
  );

  const filteredResults = useFilterProviderResults({
    pageNumber,
    pageSize,
    selectedFilters: filters,
    coverageType,
    shouldGetHGData,
    initialPage,
  });
  const {
    results = [],
    totalResultsCount,
    isLoading = false,
    isTieredPlan,
    headers,
  } = initialPage ? initialPageResults : filteredResults;

  return {
    results,
    totalResultsCount,
    isLoading,
    isTieredPlan,
    headers,
    initialPage,
    sectionType,
  };
};
