import { styled } from '@abyss/web/tools/styled';
import { Text } from '@abyss/web/ui/Text';

export const CardContainer = styled('div', {
  backgroundColor: '#FAFCFF',
});

export const ContentContainer = styled('div', {
  borderWidth: '0.5px',
  borderColor: '#CBCCCD',
  borderStyle: 'solid',
  width: '196px',
  borderRadius: '8px',
  padding: '16px',
  '@screen < $md': {
    alignItems: 'flex-start',
  },
});

export const PercentageBox = styled('div', {
  width: '98px',
  height: '76px',
  backgroundColor: '#007000',
  borderRadius: '16px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  '@screen < $md': {
    width: '91px',
  },
});

export const PercentageText = styled(Text, {
  color: '$white',
  fontSize: '36px !important',
  fontWeight: 700,
  fontFamily: '$primary',
  lineHeight: '48px !important',
  '@screen < $md': {
    fontWeight: 600,
    fontSize: '30px !important',
  },
});

export const CardSubContainer = styled('div', {
  marginTop: 0,
  borderWidth: '0.5px',
  borderColor: '#CBCCCD',
  borderStyle: 'solid',
  width: '850px',
  borderRadius: '8px',
  padding: '16px',
  backgroundColor: 'white',
  '@screen < $lg': {
    width: '100%',
    alignItems: 'flex-start',
  },
  '.desc-text': {
    color: '$gray7',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: '$primary',
    lineHeight: '18px',
    '@screen < $md': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
    },
  },
  '.abyss-card-root': {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    paddingTop: '$md',
    paddingBottom: '$md',
    display: 'flex',
    '@screen < $md': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
});

export const CriteriaContentContainer = styled('div', {
  marginTop: '4px',
  marginBottom: '16px',
});

export const ContentTitle = styled(Text, {
  color: '$gray8',
  fontSize: '16px',
  fontWeight: 700,
  fontFamily: '$primary',
  lineHeight: '18px',
});

export const ContentValue = styled(Text, {
  fontSize: '14px !important',
  fontWeight: 700,
  paddingTop: '2px',
  paddingBottom: '2px',
  paddingLeft: '8px',
  paddingRight: '8px',
  borderRadius: '4px',
  fontFamily: '$primary',
  lineHeight: '18px',
});

export const ViewMoreText = styled(Text, {
  color: '$gray8',
  fontSize: '14px !important',
  fontWeight: 590,
  fontFamily: '$primary',
});

export const RightContainer = styled('div', {
  marginLeft: '24px',
  width: '85%',
  '.smart-choice-text': {
    fontFamily: '$primary',
    lineHeight: '32px',
    color: '$gray8 !important',
    marginRight: '16px',
    fontWeight: 700,
  },
});
