import { find } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Convenience } from '../../../../../api/src/models/ProviderRecommendationDetails';
import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { useLagoon } from '../../../hooks/useLagoon';
import { MatchText } from '../MatchText';
import { NotAMatchText } from '../NotAMatchText';
import {
  BreakdownContainer,
  CriteriaPointsContainer,
  CriteriaSubTitle,
  CriteriaTitle,
  DescriptionContainer,
  DrawerContentWithBackgroundgray,
  FAQContainer,
  FAQLink,
  StyledDescription,
} from '../SmartChoiceBreakDown.styles';
import { MatchTextWithAvailability } from './Components/MatchTextWithAvailability';
import { MatchTextWithViewMore } from './Components/MatchTextWithViewMore';

interface ConvenienceBreakDownProps {
  convenience: Convenience;
}
export const ConvenienceBreakDown = ({
  convenience,
}: ConvenienceBreakDownProps) => {
  const [matchCount, setMatchCount] = useState(0);
  const [notMatchCount, setNotMatchCount] = useState(0);
  const lagoonData = useLagoon('ui-messaging')();
  const good_match = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_GOOD_MATCH_CONTENT,
  });
  const description = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_DESCRIPTION,
  });
  const availability_title = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_AVALIABILITY_TITLE,
  });
  const accept_new_patients = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_ACCEPCTING_NEW_PATIENTS,
  });
  const appointment_availability_within = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_APPOINTMENTS_ARE_AVAILABILE_WITH_IN,
  });
  const evening_appointments_available = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_EVENING_APPONTMENTS_AVAILABILE,
  });
  const evening_appointments_unavailable = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_EVENING_APPONTMENTS_UNAVAILABILE,
  });
  const weekend_appointments_available = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_WEEKEND_APPONTMENTS_AVAILABILE,
  });
  const weekend_appointments_unavailable = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_WEEKEND_APPONTMENTS_UNAVAILABILE,
  });
  const online_appointment_scheduling_available = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_ONLINE_APOINTMENT_SCHEDULE_AVAILABLE_THOUGHT_MYUHC_COM,
  });
  const online_appointment_scheduling_unavailable = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_ONLINE_APOINTMENT_SCHEDULE_UNAVAILABLE_THOUGHT_MYUHC_COM,
  });
  const within_no_of_miles = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_WITH_NUMBER_OF_MILES_YOUR_LOCATION,
  });
  const virtual_appointments_available = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_VIRTUAL_APOINTMENTS_AVAILABLE,
  });
  const virtual_appointments_unavailable = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_VIRTUAL_APOINTMENTS_UNAVAILABLE,
  });
  const FAQs = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BREAKDOWN_FAQ_TEXT,
  });

  useEffect(() => {
    const { matchCount, notMatchCount } = getMatchAndNotMatchCount(convenience);
    setMatchCount(matchCount);
    setNotMatchCount(notMatchCount);
  }, [convenience]);

  const getMatchAndNotMatchCount = (convenience: Convenience) => {
    let matchCount = 0;
    let notMatchCount = 0;
    if (convenience?.acceptingNewPatients) {
      convenience?.acceptingNewPatients === 'Y'
        ? matchCount++
        : notMatchCount++;
    }
    if (convenience?.appointmentAvailability) {
      convenience?.appointmentAvailability !== ''
        ? matchCount++
        : notMatchCount++;
    } else {
      notMatchCount++;
    }
    if (convenience?.eveningAppointments) {
      convenience?.eveningAppointments === 'Y' ? matchCount++ : notMatchCount++;
    }
    if (convenience?.weekendAppointments) {
      convenience?.weekendAppointments === 'Y' ? matchCount++ : notMatchCount++;
    }
    if (convenience?.onlineAppointmentScheduling) {
      convenience?.onlineAppointmentScheduling === 'Y'
        ? matchCount++
        : notMatchCount++;
    }
    if (convenience?.memberPreferredRadius) {
      convenience?.memberPreferredRadius === 'Y'
        ? matchCount++
        : notMatchCount++;
    }
    if (convenience?.virtualAppointments) {
      convenience?.virtualAppointments === 'Y' ? matchCount++ : notMatchCount++;
    }

    return { matchCount, notMatchCount };
  };

  const replacePlaceholders = (
    text: string | undefined,
    replacements: Record<string, string>
  ): string =>
    (text || '').replace(/\{\{(.*?)\}\}/g, (_, key) => {
      const keys = key.split('.');
      let value: any = replacements;
      for (const k of keys) {
        value = value?.[k];
        if (value === undefined) break;
      }
      return typeof value === 'string' ? value : '';
    });

  return (
    <BreakdownContainer data-testid="convenience-breakdown">
      <DrawerContentWithBackgroundgray direction="column">
        <CriteriaTitle data-testid="convenience-breakdown-title">
          {replacePlaceholders(good_match?.message, {
            matchCount: `${matchCount}`,
            totalMatchNonMatchCount: `${matchCount + notMatchCount}`,
          })}
        </CriteriaTitle>
      </DrawerContentWithBackgroundgray>
      <DescriptionContainer data-testid="convenience-breakdown-description">
        <StyledDescription data-testid="convenience-breakdown">
          {description?.message}
        </StyledDescription>
      </DescriptionContainer>
      <CriteriaPointsContainer
        data-testid="convenience-breakdown-points"
        direction="column"
      >
        <CriteriaSubTitle data-testid="convenince-availability">
          {availability_title?.message}
        </CriteriaSubTitle>
        {convenience?.acceptingNewPatients === 'Y' && (
          <MatchText text={accept_new_patients?.message} />
        )}
        {convenience?.acceptingNewPatients === 'N' && (
          <NotAMatchText text={accept_new_patients?.message} />
        )}
        {convenience?.appointmentAvailability !== '' && (
          <MatchTextWithAvailability
            availability={convenience?.appointmentAvailability || ''}
            text={appointment_availability_within?.message}
          />
        )}
        {convenience?.appointmentAvailability === '' && (
          <MatchTextWithAvailability
            availability={convenience?.appointmentAvailability}
            text={appointment_availability_within?.message}
          />
        )}
        {convenience?.eveningAppointments === 'Y' && (
          <MatchText text={evening_appointments_available?.message} />
        )}
        {convenience?.eveningAppointments === 'N' && (
          <NotAMatchText text={evening_appointments_unavailable?.message} />
        )}
        {convenience?.weekendAppointments === 'Y' && (
          <MatchText text={weekend_appointments_available?.message} />
        )}
        {convenience?.weekendAppointments === 'N' && (
          <NotAMatchText text={weekend_appointments_unavailable?.message} />
        )}
        {convenience?.onlineAppointmentScheduling === 'Y' && (
          <MatchTextWithViewMore
            text={online_appointment_scheduling_available?.message}
          />
        )}
        {convenience?.onlineAppointmentScheduling === 'N' && (
          <NotAMatchText
            text={online_appointment_scheduling_unavailable?.message}
          />
        )}
        <CriteriaSubTitle>Access to care</CriteriaSubTitle>
        {convenience?.memberPreferredRadius === 'Y' && (
          <MatchText
            text={replacePlaceholders(within_no_of_miles?.message, {
              distance: convenience?.distance || '',
            })}
          />
        )}
        {convenience?.memberPreferredRadius === 'N' && (
          <NotAMatchText
            text={replacePlaceholders(within_no_of_miles?.message, {
              distance: convenience?.distance || '',
            })}
          />
        )}
        {convenience?.virtualAppointments === 'Y' && (
          <MatchText text={virtual_appointments_available?.message} />
        )}
        {convenience?.virtualAppointments === 'N' && (
          <NotAMatchText text={virtual_appointments_unavailable?.message} />
        )}
      </CriteriaPointsContainer>
      <FAQContainer>
        <FAQLink data-testid="faq-link-convenience" href="https://www.uhc.com/">
          {FAQs?.message}
        </FAQLink>
      </FAQContainer>
    </BreakdownContainer>
  );
};
