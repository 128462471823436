import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';

export const StyledDrawer = styled(Drawer, {
  zIndex: '999 !important',
  '.abyss-modal-header-container': {
    width: 'fit-content',
  },
  '.abyss-modal-content-container': {
    borderRadius: 0,
    '@screen < $sm': {
      top: '25px',
      height: 'calc(100vh - 26px)',
      width: '100%',
      borderRadius: '20px 20px 0px 0px',
      color: '$primary1',
      border: '1px solid $primary1',
    },
  },
  '.abyss-modal-close-button': {
    '@screen < $sm': {
      top: '12px',
      right: '12px',
    },
  },
  '.abyss-modal-body': {
    scrollbarWidth: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
});
