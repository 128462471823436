import { createContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import translationsEnJson from '../../public/locale/en/translation.json';
import {
  Constants,
  DEFAULT_SEARCH_RADIUS,
  FilterCollectionModel,
  FilterOptionValues,
} from '../common/Constants';
import {
  getBestMatchOptionKey,
  getRecommendedProviderOptionKey,
} from '../common/ContentFilters/SortByUtil';
import { useCoverageType } from '../hooks/useCoverageType';
import { useSmartChoice } from '../hooks/useSmartChoice';
import {
  FiltersDefaultValues,
  ProviderSearchFilters,
} from '../models/ProviderSearch';

interface SearchFiltersContextType {
  searchFilters: ProviderSearchFilters;
  defaultSearchFilters: ProviderSearchFilters;
  setSearchFilters: (selectedFilters: ProviderSearchFilters) => void;
  setDefaultSearchFilters: (
    defaultFilters: FilterCollectionModel[],
    defaultValues?: FiltersDefaultValues
  ) => ProviderSearchFilters;
  clearSearchFilters: () => void;
  isSmartChoice: boolean;
}

type ProviderDefaultValue = {
  autoSearchRadius?: number;
};

export const SearchFilterContext = createContext<SearchFiltersContextType>({
  searchFilters: {},
  defaultSearchFilters: {},
  setSearchFilters: () => {},
  setDefaultSearchFilters: () => ({}),
  clearSearchFilters: () => {},
  isSmartChoice: false,
});

const getFilterDefaultValues = (
  translate,
  requiredFilters: FilterCollectionModel[] = [],
  defaultValues: FiltersDefaultValues = {},
  coverageType: string,
  isSmartChoice: boolean
): ProviderSearchFilters => {
  const {
    autoSearchRadius = DEFAULT_SEARCH_RADIUS,
    preferredFacility,
    SpecialtyCode,
    AreaOfExpertise,
    GenderCode,
  } = defaultValues;
  const BEST_MATCH_SORT_LABEL_KEY = getBestMatchOptionKey(coverageType);
  const SMART_CHOICE_SORT_LABEL_KEY =
    getRecommendedProviderOptionKey(coverageType);

  const sortByArr = isSmartChoice
    ? {
        label: translate(`SORT_OPTIONS.${SMART_CHOICE_SORT_LABEL_KEY}`),
        value: Constants.RECOMMENDED_PROVIDERS_SEARCH_SORT_BY,
        hide: true,
        dnt_label: translationsEnJson.SORT_OPTIONS[SMART_CHOICE_SORT_LABEL_KEY],
      }
    : {
        label: translate(`SORT_OPTIONS.${BEST_MATCH_SORT_LABEL_KEY}`),
        value: Constants.PROVIDER_SEARCH_SORT_BY,
        hide: true,
        dnt_label: translationsEnJson.SORT_OPTIONS[BEST_MATCH_SORT_LABEL_KEY],
      };
  const filtersDefaultValues: ProviderSearchFilters = {
    [FilterCollectionModel.SORT_BY]: sortByArr,
    [FilterCollectionModel.ACCEPTING_NEW_PATIENTS]: [
      {
        label: translate('FILTER_OPTIONS.AcceptingNewPatients.Y'),
        value: FilterOptionValues.AcceptingNewPatients.Yes,
        dnt_label: translationsEnJson.FILTER_OPTIONS.AcceptingNewPatients.Y,
      },
    ],
    [FilterCollectionModel.VIRTUAL_CARE]: {
      label: translate('FILTER_OPTIONS.VirtualCare.Y'),
      value: FilterOptionValues.VirtualCare.Yes,
      dnt_label: translationsEnJson.FILTER_OPTIONS.VirtualCare.Y,
    },
    [FilterCollectionModel.PREFERRED_FACILITY]: preferredFacility?.map(
      (value) => ({
        label: translationsEnJson.FILTER_OPTIONS.PreferredFacility[value],
        value,
        dnt_label: translationsEnJson.FILTER_OPTIONS.PreferredFacility[value],
      })
    ),
    [FilterCollectionModel.SPECIALTY_CODE]: SpecialtyCode?.map((value) => ({
      label: value.label,
      value: value.value,
      dnt_label: value.dnt_label,
    })),

    [FilterCollectionModel.AREA_OF_EXPERTISE]: AreaOfExpertise?.map(
      (value) => ({
        label: value.label,
        value: value.value,
        dnt_label: value.dnt_label,
      })
    ),
    [FilterCollectionModel.GENDER_CODE]: GenderCode?.map((value) => ({
      label: value.label,
      value: value.value,
      dnt_label: value.dnt_label,
    })),
    [FilterCollectionModel.DISTANCE]: [
      {
        label: `${autoSearchRadius} ${translationsEnJson.miles}`,
        value: autoSearchRadius,
        dnt_label: `${autoSearchRadius} ${translationsEnJson.miles}`,
        c_value: autoSearchRadius,
        hide: !autoSearchRadius,
      },
    ],
    [FilterCollectionModel.AUTO_SEARCH_RADIUS]: autoSearchRadius,
    [FilterCollectionModel.ESSENTIAL_COMMUNITY_PROVIDER]: [
      {
        label: translate('FILTER_OPTIONS.EssentialCommunityProvider.Y'),
        value: FilterOptionValues.EssentialCommunityProvider.Yes,
        dnt_label:
          translationsEnJson.FILTER_OPTIONS.EssentialCommunityProvider.Y,
      },
    ],
  };
  const filterValues = requiredFilters.reduce((acc, key) => {
    if (filtersDefaultValues[key]) {
      acc[key] = filtersDefaultValues[key];
    }
    return acc;
  }, {});
  return filterValues;
};

export const SearchFilterContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const coverageType = useCoverageType();
  const { t } = useTranslation();
  const isSmartChoice = useSmartChoice();

  const [defaultValue, setDefaultvalue] =
    useSessionStorage<ProviderDefaultValue>(
      Constants.STORAGE_KEYS.SESSION.DEFAULT_SEARCH_FILTERS,
      {}
    );

  const defaultFiltersState: ProviderSearchFilters = useMemo(
    () =>
      getFilterDefaultValues(
        t,
        [
          FilterCollectionModel.SORT_BY,
          FilterCollectionModel.AUTO_SEARCH_RADIUS,
          FilterCollectionModel.DISTANCE,
        ],
        defaultValue,
        coverageType,
        isSmartChoice
      ),
    [coverageType, JSON.stringify(defaultValue), isSmartChoice]
  );

  const [storedSearchFilters, setStoredSearchFilters] =
    useSessionStorage<ProviderSearchFilters>(
      Constants.STORAGE_KEYS.SESSION.SELECTED_SEARCH_FILTERS,
      defaultFiltersState
    );

  const [searchFilters, setSearchFilters] =
    useState<ProviderSearchFilters>(storedSearchFilters);

  useEffect(() => {
    setStoredSearchFilters(searchFilters);
  }, [JSON.stringify(searchFilters)]);

  const onSearchFiltersChange = (selectedFilters: ProviderSearchFilters) => {
    setSearchFilters(selectedFilters);
  };

  useEffect(() => {
    const updatedFilters = getFilterDefaultValues(
      t,
      [
        FilterCollectionModel.SORT_BY,
        FilterCollectionModel.AUTO_SEARCH_RADIUS,
        FilterCollectionModel.DISTANCE,
      ],
      defaultValue,
      coverageType,
      isSmartChoice
    );
    const mergedFilters = {
      ...searchFilters,
      ...updatedFilters,
    };

    setSearchFilters(mergedFilters);
  }, [isSmartChoice]);

  const onDefaultFiltersChange = (
    defaultFilters: FilterCollectionModel[] = [],
    defaultValues: FiltersDefaultValues = {}
  ) => {
    const filterValues = getFilterDefaultValues(
      t,
      defaultFilters,
      defaultValues,
      coverageType,
      isSmartChoice
    );

    setDefaultvalue(defaultValues);
    const updatedFilters = {
      ...searchFilters,
      ...defaultFiltersState,
      ...filterValues,
    };
    setSearchFilters(updatedFilters);
    return updatedFilters;
  };

  const onClearSearchFilters = () => {
    setSearchFilters(defaultFiltersState);
  };
  return (
    <SearchFilterContext.Provider
      value={{
        searchFilters,
        defaultSearchFilters: defaultFiltersState,
        setSearchFilters: onSearchFiltersChange,
        setDefaultSearchFilters: onDefaultFiltersChange,
        clearSearchFilters: onClearSearchFilters,
        isSmartChoice,
      }}
    >
      {children}
    </SearchFilterContext.Provider>
  );
};
