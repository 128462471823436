import { PageBody } from '@abyss/web/ui/PageBody';
import { Router } from '@abyss/web/ui/Router';
import { FAQPage } from 'parcels/src/frontends/StandaloneFAQPage/FAQPage';
import { Suspense, useEffect } from 'react';

import { AllPatientReviews } from '../../../common/AllPatientReviews/AllPatientReviews';
import { CardWrapper } from '../../../common/CardWrapper';
import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { Page404 } from '../../../errors/Page404';
import { Page408 } from '../../../errors/Page408';
import { Page4XX } from '../../../errors/Page4XX';
import { Page500 } from '../../../errors/Page500';
import { useCoverageType } from '../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../hooks/useLagoon';
import { useManageBrowserHistory } from '../../../hooks/useManageBrowserHistory';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { getCurrentMember, getPolicyNumber } from '../../../utils/user.utils';
import { ChoosePCPLocation, ChoosePCPSuccess } from './ChoosePCP';
import { FacilityDetails } from './FacilityDetails/FacilityDetails';
import { Footer } from './Footer';
import { GroupDetails } from './GroupDetails';
import { NullResultPageForSpecialty } from './NullSpecialtyPage';
import { ProviderDetailsView } from './ProviderDetailsView';
import { PSXHome } from './PSXHome';
import { SearchResults, SearchResultsV2 } from './SearchResults';
import { ViewAll } from './ViewAll';
import { VirtualProviderGroupDetails } from './VirtualProviderGroupDetails';

export const Routes = () => {
  useManageBrowserHistory();
  const suppressionLagoonData = useLagoon('feature-supression')();
  const includeLagoonData = useLagoon('feature-inclusion')();
  const setUIFeatureSuppressionState = useStore(
    StoreKeys.SET_UI_FEATURE_SUPPRESSION
  );
  const setUIFeatureInclusionState = useStore(
    StoreKeys.SET_UI_FEATURE_INCLUSION
  );
  const coverageType = useCoverageType();
  const currentMember = getCurrentMember();

  const memberPolicy = getPolicyNumber(currentMember, coverageType);

  useEffect(() => {
    setUIFeatureSuppressionState({ suppressionLagoonData, memberPolicy });
  }, [suppressionLagoonData, memberPolicy]);

  useEffect(() => {
    setUIFeatureInclusionState({ includeLagoonData, memberPolicy });
  }, [includeLagoonData, memberPolicy]);

  const [enableSearchResultsV2] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SEARCH_RESULTS_V2,
  ]);

  return (
    <PageBody
      css={{
        'abyss-page-body-container': {
          backgroundColor: '$pageBodyBgColor',
          maxWidth: '100%',
          padding: 0,
        },
      }}
      id="main-content"
      tabIndex={-1}
    >
      <Suspense fallback={<div>LOADING...</div>}>
        <Router.Routes>
          <Router.Route element={<PSXHome />} exact path="/" />
          <Router.Route element={<PSXHome />} exact path="/:token" />
          <Router.Route
            element={
              enableSearchResultsV2 ? <SearchResultsV2 /> : <SearchResults />
            }
            path="/results"
          />
          <Router.Route
            element={
              enableSearchResultsV2 ? <SearchResultsV2 /> : <SearchResults />
            }
            path="/results/:token"
          />
          <Router.Route
            element={<NullResultPageForSpecialty />}
            path="/no-specialty-results"
          />
          <Router.Route
            element={<NullResultPageForSpecialty />}
            path="/no-specialty-results/:token"
          />
          {/* search results page with list view */}
          <Router.Route element={<ViewAll />} path="/results/viewall" />
          <Router.Route element={<ViewAll />} path="/results/viewall/:token" />
          {/* search results page with map view */}
          <Router.Route element={<ViewAll />} path="/results/mapview" />
          <Router.Route element={<ViewAll />} path="/results/mapview/:token" />
          <Router.Route
            element={<ProviderDetailsView />}
            path="/provider-details"
          />
          <Router.Route
            element={<ProviderDetailsView />}
            path="/provider-details/:token"
          />
          <Router.Route
            element={<AllPatientReviews />}
            path="/provider-details/reviews"
          />
          <Router.Route
            element={<AllPatientReviews />}
            path="/provider-details/reviews/:token"
          />
          <Router.Route
            element={<FacilityDetails />}
            path="/facility-details"
          />
          <Router.Route
            element={<FacilityDetails />}
            path="/facility-details/:token"
          />
          <Router.Route element={<GroupDetails />} path="/group-details" />
          <Router.Route
            element={<GroupDetails />}
            path="/group-details/:token"
          />
          <Router.Route
            element={<VirtualProviderGroupDetails />}
            path="/virtual-group-details"
          />
          <Router.Route
            element={<VirtualProviderGroupDetails />}
            path="/virtual-group-details/:token"
          />
          <Router.Route
            element={<ChoosePCPLocation />}
            path="/choose-pcp/locations"
          />
          <Router.Route
            element={<ChoosePCPLocation />}
            path="/choose-pcp/locations/:token"
          />
          <Router.Route
            element={<ChoosePCPSuccess />}
            path="/choose-pcp/success"
          />
          <Router.Route
            element={<ChoosePCPSuccess />}
            path="/choose-pcp/success/:token"
          />
          <Router.Route element={<Page500 />} path="/500" />
          <Router.Route element={<Page408 />} path="/408" />
          <Router.Route element={<Page404 />} path="/404" />
          <Router.Route element={<Page4XX />} path="/4xx" />
          <Router.Route element={<Page500 />} path="/500/:token" />
          <Router.Route element={<Page408 />} path="/408/:token" />
          <Router.Route element={<Page404 />} path="/404/:token" />
          <Router.Route element={<Page4XX />} path="/4xx/:token" />
          <Router.Route element={<FAQPage />} path="/faq" />
        </Router.Routes>
      </Suspense>
      <CardWrapper backgroundColor="rgb(244, 246, 250)">
        <Footer />
      </CardWrapper>
    </PageBody>
  );
};
