import { MemberInfo } from './PSXHeader/ObapiDemo/memberProfile';

const START_DATE = '2022-11-01';
const END_DATE = '9999-12-31';
const ADDRESS_LINE1 = '4040 N 62ND AVE';
const TYPE_CODE_DESC = 'EHB DENTAL';

export const MOCK_MEMBERS_INFO_3: MemberInfo[] = [
  {
    isPcpRequired: false,
    demographics: {
      dateOfBirth: '04/01/1986',
      name: {
        firstName: 'Malcolm',
        lastName: 'John',
      },
      gender: 'MALE',
      dependentSeqNbr: '00',
      relationshipTypeCode: {
        code: 'SUBSCRIBER',
      },
      address: [
        {
          addressType: 'PERMANENT',
          address1: '20 W 34th St',
          city: 'New York',
          state: 'NY',
          zipCode: '10001',
        },
      ],
      primaryCare: {
        hasPCP: true,
        isCoverageActive: true,
        pcpName: 'Joseph Aurilio',
        providerID: '000308678',
        pcpID: '000308678 001',
      },
    },
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'PHARMACY',
                coverageTypeCode: 'RX',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              designatedNetwork: [],
              virtualPCPAssignedInd: 'N',
              typeCode: {
                desc: 'MEDICAL',
                coverageTypeCode: 'M',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'DENTAL',
                coverageTypeCode: 'D',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'BEHAVIORAL HEALTH',
                coverageTypeCode: 'B',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'VISION',
                coverageTypeCode: 'V',
              },
            },
          ],
        },
      },
    ],
    reciprocityId: '52',
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    demographics: {
      dateOfBirth: '12/06/2008',
      name: {
        firstName: 'Michelangelo',
        lastName: 'John',
      },
      gender: 'MALE',
      dependentSeqNbr: '00',
      relationshipTypeCode: {
        code: 'DEPENDENT',
      },
      address: [
        {
          addressType: 'PERMANENT',
          address1: '21 W 35th St',
          city: 'New York',
          state: 'NY',
          zipCode: '10002',
        },
      ],
      primaryCare: {
        hasPCP: true,
        isCoverageActive: true,
        pcpName: 'David Abraham',
        providerID: '006504354',
        pcpID: '006504354 003',
      },
    },
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'PHARMACY',
                coverageTypeCode: 'RX',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'MEDICAL',
                coverageTypeCode: 'M',
              },
            },
          ],
        },
      },
    ],
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    demographics: {
      dateOfBirth: '03/16/2004',
      name: {
        firstName: 'Mike',
        lastName: 'Lee',
      },
      dependentSeqNbr: '00',
      relationshipTypeCode: {
        code: 'DEPENDENT',
      },
      gender: 'MALE',
      address: [
        {
          addressType: 'PERMANENT',
          address1: '22 W 36th St',
          city: 'New York',
          state: 'NY',
          zipCode: '10003',
        },
      ],
      primaryCare: {
        hasPCP: true,
        isCoverageActive: true,
        pcpName: 'Adelakun Elizabeth',
        providerID: '006668623',
        pcpID: '006668623 005',
      },
    },
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'PHARMACY',
                coverageTypeCode: 'RX',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'MEDICAL',
                coverageTypeCode: 'M',
              },
            },
            {
              groupNumber: '1661955',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'VISION',
                coverageTypeCode: 'V',
              },
            },
          ],
        },
      },
    ],
  },
];

export const MOCK_MEMBERS_INFO: MemberInfo[] = [
  {
    demographics: {
      dateOfBirth: '1978-04-25',
      dependentSeqNbr: '00',
      address: [
        {
          addressType: 'PERMANENT',
          address1: '1111 NEW STREET',
          city: 'CYPRESS',
          state: 'CA',
          zipCode: '90630',
          zip4: null,
        },
      ],
      gender: 'MALE',
      relationshipTypeCode: {
        code: 'SUBSCRIBER',
      },
      name: {
        firstName: 'Fnzmrnxbkffy',
        lastName: 'Lnydyxachk',
      },
    },
    basePlan: 'MEDICAL',
    sourceCode: 'CS',
    policyNumber: '0915181',
    isPcpRequired: false,
    isGatedUser: false,
    isEAPEligible: false,
    isIFPTieredPlan: false,
    isUrgentCareEligible: true,
    eligibility: [
      {
        policyKey: '00_MEDICAL_0915181_2024-12-31',
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '0915181',
              marketType: '50',
              policyNumber: '0915181',
              planVariationCode: '0001',
              reportingCode: '0001',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'BEHAVIORAL HEALTH',
                coverageTypeCode: 'B',
              },
              designatedNetwork: null,
              virtualPCPAssignedInd: null,
              coverageID: [
                {
                  sourceSysCode: 'MEMBER ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'ALTERNATE ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'SUBSCRIBER ID',
                  id: '00990005439',
                },
              ],
              situsState: 'CA',
              obligorID: '01',
              claimSystemTypeCode: null,
              eligibilitySystemTypeCode: '01',
              sharedArrangementCode: '00',
              productCode: 'Q',
            },
            {
              groupNumber: '0915181',
              marketType: null,
              policyNumber: '0915181',
              planVariationCode: '0001',
              reportingCode: '0001',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'DENTAL',
                coverageTypeCode: 'D',
              },
              designatedNetwork: null,
              virtualPCPAssignedInd: null,
              coverageID: [
                {
                  sourceSysCode: 'MEMBER ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'ALTERNATE ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'SUBSCRIBER ID',
                  id: '00990005439',
                },
              ],
              situsState: 'CA',
              obligorID: '01',
              claimSystemTypeCode: '05',
              eligibilitySystemTypeCode: '01',
              sharedArrangementCode: '00',
              productCode: null,
            },
            {
              groupNumber: '0915181',
              marketType: null,
              policyNumber: '0915181',
              planVariationCode: '0001',
              reportingCode: '0001',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'VISION',
                coverageTypeCode: 'V',
              },
              designatedNetwork: null,
              virtualPCPAssignedInd: null,
              coverageID: [
                {
                  sourceSysCode: 'MEMBER ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'ALTERNATE ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'SUBSCRIBER ID',
                  id: '00990005439',
                },
              ],
              situsState: 'CA',
              obligorID: '01',
              claimSystemTypeCode: '12',
              eligibilitySystemTypeCode: '01',
              sharedArrangementCode: '00',
              productCode: 'U',
            },
            {
              groupNumber: '0915181',
              marketType: '50',
              policyNumber: '0915181',
              planVariationCode: '0001',
              reportingCode: '0001',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'MEDICAL',
                coverageTypeCode: 'M',
              },
              designatedNetwork: ['DVCN'],
              virtualPCPAssignedInd: null,
              coverageID: [
                {
                  sourceSysCode: 'MEMBER ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'ALTERNATE ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'SUBSCRIBER ID',
                  id: '00990005439',
                },
              ],
              situsState: 'CA',
              obligorID: '01',
              claimSystemTypeCode: '01',
              eligibilitySystemTypeCode: '01',
              encryptedUserNameWithPlanId:
                '17ddfdYk7HTOdh92sEK5H50tmHvJvWA99E4BntpwPPepFsDGtjlLqaVdvMyQ7Tpe',
              sharedArrangementCode: '00',
              productCode: 'Q',
            },
          ],
        },
      },
    ],

    planDetail: {
      dependentSeqNbr: '00',
      planKey: '00_0915181_2024-12-31',
      eligibility: {
        benefitSet: [
          {
            coverageTypeCode: {
              code: 'M',
              desc: 'MEDICAL',
            },
            benefitPeriod: {
              startDate: START_DATE,
              stopDate: END_DATE,
            },
            planNameType: {
              brandingLongName: 'Choice Plus',
            },
            planBenefitSet: [
              {
                coverageLevel: '1',
                tciTableNumberId: {
                  id: '022884',
                },
              },
            ],
            serviceLevelBenefitSet: {
              serviceBenefit: [
                {
                  service: {
                    serviceCodeQualifier: '01',
                    hipaaServiceTypeCode: {
                      code: '01',
                    },
                  },
                  level: [
                    {
                      networkStatusCode: {
                        code: 'TIER-1',
                      },
                      benefitStatusCode: {
                        code: '1',
                      },
                    },
                    {
                      networkStatusCode: {
                        code: 'IN-NETWORK',
                      },
                      benefitStatusCode: {
                        code: '1',
                      },
                    },
                    {
                      networkStatusCode: {
                        code: 'OUT-NETWORK',
                      },
                      benefitStatusCode: {
                        code: '1',
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    planSummary: [
      {
        productId: 'LAWW',
        productDetail: [
          {
            providerNetwork: [
              'UBH_GENERAL',
              'LEGACYEMP',
              'BCBANETWORK',
              'MEDICARE',
            ],
            externalAccountNumber: '0915181',
            cbmsAccountNumber: '0',
            siteId: '0',
          },
        ],
      },
    ],
    reciprocityId: '52',
    networks: [
      {
        type: 'DENTAL',
        value: ['PPO000000001'],
      },
      {
        type: 'VISION',
        value: ['1'],
      },
    ],
    lineOfBusiness: 'E&I',
    planKey: '00_0915181_2024-12-31',
    viewPcp: true,
    editPcp: true,
    viewDependentPcp: true,
    editDependentPcp: true,
    familyId: '0022042120',
    rallyId: '',
    id: '0',
  },
  {
    basePlan: 'MEDICAL',
    sourceCode: 'CS',
    policyNumber: '0915181',
    isPcpRequired: false,
    isGatedUser: false,
    isEAPEligible: false,
    isUrgentCareEligible: true,
    eligibility: [
      {
        policyKey: '01_MEDICAL_0915181_2024-12-31',
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '0915181',
              marketType: '50',
              policyNumber: '0915181',
              planVariationCode: '0001',
              reportingCode: '0001',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'BEHAVIORAL HEALTH',
                coverageTypeCode: 'B',
              },
              designatedNetwork: null,
              virtualPCPAssignedInd: null,
              coverageID: [
                {
                  sourceSysCode: 'MEMBER ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'ALTERNATE ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'SUBSCRIBER ID',
                  id: '00990005439',
                },
              ],
              situsState: 'CA',
              obligorID: '01',
              claimSystemTypeCode: null,
              eligibilitySystemTypeCode: '01',
              sharedArrangementCode: '00',
              productCode: 'Q',
            },
            {
              groupNumber: '0915181',
              marketType: null,
              policyNumber: '0915181',
              planVariationCode: '0001',
              reportingCode: '0001',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'DENTAL',
                coverageTypeCode: 'D',
              },
              designatedNetwork: null,
              virtualPCPAssignedInd: null,
              coverageID: [
                {
                  sourceSysCode: 'MEMBER ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'ALTERNATE ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'SUBSCRIBER ID',
                  id: '00990005439',
                },
              ],
              situsState: 'CA',
              obligorID: '01',
              claimSystemTypeCode: '05',
              eligibilitySystemTypeCode: '01',
              sharedArrangementCode: '00',
              productCode: null,
            },
            {
              groupNumber: '0915181',
              marketType: null,
              policyNumber: '0915181',
              planVariationCode: '0001',
              reportingCode: '0001',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'VISION',
                coverageTypeCode: 'V',
              },
              designatedNetwork: null,
              virtualPCPAssignedInd: null,
              coverageID: [
                {
                  sourceSysCode: 'MEMBER ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'ALTERNATE ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'SUBSCRIBER ID',
                  id: '00990005439',
                },
              ],
              situsState: 'CA',
              obligorID: '01',
              claimSystemTypeCode: '12',
              eligibilitySystemTypeCode: '01',
              sharedArrangementCode: '00',
              productCode: 'U',
            },
            {
              groupNumber: '0915181',
              marketType: '50',
              policyNumber: '0915181',
              planVariationCode: '0001',
              reportingCode: '0001',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'MEDICAL',
                coverageTypeCode: 'M',
              },
              designatedNetwork: ['DVCN'],
              virtualPCPAssignedInd: null,
              coverageID: [
                {
                  sourceSysCode: 'MEMBER ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'ALTERNATE ID',
                  id: '00990005439',
                },
                {
                  sourceSysCode: 'SUBSCRIBER ID',
                  id: '00990005439',
                },
              ],
              situsState: 'CA',
              obligorID: '01',
              claimSystemTypeCode: '01',
              eligibilitySystemTypeCode: '01',
              sharedArrangementCode: '00',
              productCode: 'Q',
            },
          ],
        },
      },
    ],
    demographics: {
      dateOfBirth: '1984-04-25',
      dependentSeqNbr: '01',
      address: [
        {
          addressType: 'PERMANENT',
          address1: '1111 NEW STREET',
          city: 'CYPRESS',
          state: 'CA',
          zipCode: '90630',
          zip4: null,
        },
      ],
      gender: 'FEMALE',
      relationshipTypeCode: {
        code: 'SPOUSE',
      },
      name: {
        firstName: 'Fnxssrzugrzf',
        lastName: 'Lnydyxachk',
      },
    },
    planDetail: null,
    planSummary: null,
    reciprocityId: '52',
    networks: [
      {
        type: 'DENTAL',
        value: ['PPO000000001'],
      },
      {
        type: 'VISION',
        value: ['1'],
      },
    ],
    lineOfBusiness: 'E&I',
    planKey: '01_0915181_2024-12-31',
    viewPcp: true,
    editPcp: true,
    viewDependentPcp: false,
    editDependentPcp: false,
    familyId: '0022042120',
    rallyId: '',
  },
];

export const MOCK_MEMBERS_INFO_2: MemberInfo[] = [
  {
    isPcpRequired: false,
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [],
        },
      },
    ],
    demographics: {
      dateOfBirth: '1979-02-21',
      dependentSeqNbr: '01',
      gender: 'FEMALE',
      address: [
        {
          addressType: 'PERMANENT',
          address1: ADDRESS_LINE1,
          city: 'PHOENIX',
          state: 'AZ',
          zipCode: '85033',
        },
      ],
      relationshipTypeCode: {
        code: 'SUBSCRIBER',
      },
      name: {
        firstName: 'Teresa',
        lastName: 'Zapien Hinojos',
      },
    },
    reciprocityId: '52',
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: 'EXSG',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: TYPE_CODE_DESC,
                coverageTypeCode: 'DS',
              },
            },
          ],
        },
      },
    ],
    demographics: {
      dateOfBirth: '2005-02-07',
      dependentSeqNbr: '04',
      address: [
        {
          addressType: 'PERMANENT',
          address1: ADDRESS_LINE1,
          city: 'PHOENIX',
          state: 'AZ',
          zipCode: '85033',
        },
      ],
      gender: 'FEMALE',
      relationshipTypeCode: {
        code: 'DEPENDENT',
      },
      name: {
        firstName: 'Jimena',
        lastName: 'Lazcano Zapien',
      },
      primaryCare: {
        hasPCP: true,
        isCoverageActive: true,
        pcpName: 'up stuff',
        providerID: '678787',
        pcpID: '428722',
      },
    },
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: 'EXSG',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: TYPE_CODE_DESC,
                coverageTypeCode: 'DS',
              },
            },
          ],
        },
      },
    ],
    demographics: {
      dateOfBirth: '2009-10-31',
      dependentSeqNbr: '03',
      address: [
        {
          addressType: 'PERMANENT',
          address1: ADDRESS_LINE1,
          city: 'PHOENIX',
          state: 'AZ',
          zipCode: '85033',
        },
      ],
      gender: 'FEMALE',
      relationshipTypeCode: {
        code: 'DEPENDENT',
      },
      name: {
        firstName: 'Rodrigo',
        lastName: 'Garcia Zapien',
      },
    },
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [],
        },
      },
    ],
    demographics: {
      dateOfBirth: '1979-05-07',
      dependentSeqNbr: '02',
      gender: 'FEMALE',
      address: [
        {
          addressType: 'PERMANENT',
          address1: ADDRESS_LINE1,
          city: 'PHOENIX',
          state: 'AZ',
          zipCode: '85033',
        },
      ],
      relationshipTypeCode: {
        code: 'SPOUSE',
      },
      name: {
        firstName: 'Miguel',
        lastName: 'Garcia',
      },
    },
  },
  {
    reciprocityId: '52',
    isPcpRequired: false,
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: 'EXSG',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: TYPE_CODE_DESC,
                coverageTypeCode: 'DS',
              },
            },
          ],
        },
      },
    ],
    demographics: {
      dateOfBirth: '2008-04-26',
      dependentSeqNbr: '05',
      address: [
        {
          addressType: 'PERMANENT',
          address1: ADDRESS_LINE1,
          city: 'PHOENIX',
          state: 'AZ',
          zipCode: '85033',
        },
      ],
      gender: 'FEMALE',
      relationshipTypeCode: {
        code: 'DEPENDENT',
      },
      name: {
        firstName: 'Adolfo',
        lastName: 'Garcia Zapien',
      },
    },
  },
];

export const MOCK_LAWW_MEMBERS_INFO: MemberInfo[] = [
  {
    demographics: {
      dateOfBirth: '1978-04-25',
      dependentSeqNbr: '00',
      address: [
        {
          addressType: 'PERMANENT',
          address1: '1111 NEW STREET',
          city: 'CYPRESS',
          state: 'CA',
          zipCode: '90630',
          zip4: null,
        },
      ],
      gender: 'MALE',
      relationshipTypeCode: {
        code: 'SUBSCRIBER',
      },
      name: {
        firstName: 'Fnzmrnxbkffy',
        lastName: 'Lnydyxachk',
      },
    },
    eligibility: [
      {
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: '0915181',
              planVariationCode: '0001',
              policyNumber: '0915181',
              coverageEffDateRange: {
                startDate: START_DATE,
                endDate: END_DATE,
              },
              typeCode: {
                desc: 'BEHAVIORAL HEALTH',
                coverageTypeCode: 'B',
              },
            },
          ],
        },
      },
    ],
    demographics: {
      dateOfBirth: '1984-04-25',
      dependentSeqNbr: '01',
      address: [
        {
          addressType: 'PERMANENT',
          address1: '1111 NEW STREET',
          city: 'CYPRESS',
          state: 'CA',
          zipCode: '90630',
          zip4: null,
        },
      ],
      gender: 'FEMALE',
      relationshipTypeCode: {
        code: 'SPOUSE',
      },
      name: {
        firstName: 'Fnxssrzugrzf',
        lastName: 'Lnydyxachk',
      },
    },
    planDetail: null,
    planSummary: null,
    reciprocityId: '52',
    networks: [
      {
        type: 'DENTAL',
        value: [],
      },
      {
        type: 'VISION',
        value: [],
      },
    ],
    lineOfBusiness: 'E&I',
    planKey: '01_0915181_2024-12-31',
    viewPcp: true,
    editPcp: true,
    viewDependentPcp: false,
    editDependentPcp: false,
    familyId: '0022042120',
    rallyId: '',
  },
];

export const MOCK_STAGE_DEFAULT_MEMBER_INFO: MemberInfo[] = [
  {
    basePlan: "MEDICAL",
    sourceCode: "CS",
    membershipCategory: null,
    policyNumber: "0905610",
    population: "UNET",
    isPcpRequired: false,
    isReferralRequired: false,
    isIFPMember: false,
    isCnSMember: false,
    isCnSDsnpMember: false,
    isKelseySeyboldPlan: false,
    isGatedUser: false,
    isEAPEligible: false,
    isNonGatedUSPUser: false,
    isUrgentCareEligible: false,
    isPreEffective: false,
    isTelemedicineEligible: false,
    ddpCode: "",
    eligibility: [
      {
        policyKey: "00_MEDICAL_0905610_9999-12-31",
        memberHealthCoverage: {
          coverageType: [
            {
              groupNumber: "0905610",
              marketType: "50",
              policyNumber: "0905610",
              planVariationCode: "0001",
              reportingCode: "0001",
              coverageEffDateRange: {
                startDate: "2024-01-01",
                endDate: "9999-12-31"
              },
              typeCode: {
                desc: "DENTAL",
                coverageTypeCode: "D",
                reciprocityId: null
              },
              designatedNetwork: null,
              virtualPCPAssignedInd: null,
              coverageID: [
                {
                  sourceSysCode: "MEMBER ID",
                  id: "00990085919"
                },
                {
                  sourceSysCode: "ALTERNATE ID",
                  id: "00990085919"
                },
                {
                  sourceSysCode: "SUBSCRIBER ID",
                  id: "00990085919"
                },
                {
                  sourceSysCode: "ENTERPRISE ID",
                  id: "5233188919"
                }
              ],
              situsState: "CA",
              obligorID: "01",
              claimSystemTypeCode: "05",
              eligibilitySystemTypeCode: "01",
              sharedArrangementCode: "00",
              productCode: null,
              productID: null,
              pcpEligibilityIndicator: "N",
              pcpReferralsIndicator: "N",
              marketNumber: null,
              fundingArrangement: "F",
              fipsCountyCode: null,
              planCodeValue: null,
              contractNumber: "H2001",
              pbpNumber: null,
              segmentId: null,
              snpType: null,
              medicalDualStatusCode: null,
              encryptedUserNameWithPlanId: null
            },
            {
              groupNumber: "0905610",
              marketType: "50",
              policyNumber: "0905610",
              planVariationCode: "0001",
              reportingCode: "0001",
              coverageEffDateRange: {
                startDate: "2024-01-01",
                endDate: "9999-12-31"
              },
              typeCode: {
                desc: "VISION",
                coverageTypeCode: "V",
                reciprocityId: null
              },
              designatedNetwork: null,
              virtualPCPAssignedInd: null,
              coverageID: [
                {
                  sourceSysCode: "MEMBER ID",
                  id: "00990085919"
                },
                {
                  sourceSysCode: "ALTERNATE ID",
                  id: "00990085919"
                },
                {
                  sourceSysCode: "SUBSCRIBER ID",
                  id: "00990085919"
                },
                {
                  sourceSysCode: "ENTERPRISE ID",
                  id: "5233188919"
                }
              ],
              situsState: "CA",
              obligorID: "01",
              claimSystemTypeCode: "12",
              eligibilitySystemTypeCode: "01",
              sharedArrangementCode: "00",
              productCode: "Q",
              productID: null,
              pcpEligibilityIndicator: "N",
              pcpReferralsIndicator: "N",
              marketNumber: null,
              fundingArrangement: "F",
              fipsCountyCode: null,
              planCodeValue: null,
              contractNumber: "H2001",
              pbpNumber: null,
              segmentId: null,
              snpType: null,
              medicalDualStatusCode: null,
              encryptedUserNameWithPlanId: null
            },
            {
              groupNumber: "0905610",
              marketType: "50",
              policyNumber: "0905610",
              planVariationCode: "0001",
              reportingCode: "0001",
              coverageEffDateRange: {
                startDate: "2024-01-01",
                endDate: "9999-12-31"
              },
              typeCode: {
                desc: "MEDICAL",
                coverageTypeCode: "M",
                reciprocityId: null
              },
              designatedNetwork: [
                "DVCN"
              ],
              virtualPCPAssignedInd: null,
              coverageID: [
                {
                  sourceSysCode: "MEMBER ID",
                  id: "00990085919"
                },
                {
                  sourceSysCode: "ALTERNATE ID",
                  id: "00990085919"
                },
                {
                  sourceSysCode: "SUBSCRIBER ID",
                  id: "00990085919"
                },
                {
                  sourceSysCode: "ENTERPRISE ID",
                  id: "5233188919"
                }
              ],
              situsState: "CA",
              obligorID: "01",
              claimSystemTypeCode: "01",
              eligibilitySystemTypeCode: "01",
              sharedArrangementCode: "00",
              productCode: "Q",
              productID: null,
              pcpEligibilityIndicator: "N",
              pcpReferralsIndicator: "N",
              marketNumber: null,
              fundingArrangement: "F",
              fipsCountyCode: null,
              planCodeValue: null,
              contractNumber: "H2001",
              pbpNumber: null,
              segmentId: null,
              snpType: null,
              medicalDualStatusCode: null,
              encryptedUserNameWithPlanId: null
            }
          ]
        }
      }
    ],
  demographics: {
      dateOfBirth: "1979-05-02",
      dependentSeqNbr: "00",
      address: [
        {
          addressType: "PERMANENT",
          address1: "5183 SOUTH TTYIVL BLVD",
          city: "FGASTULZQGQMMCV",
          state: "CA",
          zipCode: "90630",
          zip4: null
        }
      ],
      gender: "MALE",
      relationshipTypeCode: {
        code: "SUBSCRIBER"
      },
      name: {
        firstName: "Fnmqxvtbmkla",
        lastName: "Lnasfjnfnt"
      }
    },
    planDetail: {
      dependentSeqNbr: "00",
      planKey: null,
      eligibility: {
        benefitSet: null
      }
    },
    planSummary: null,
    rulesPackageKey: "",
    reciprocityId: "52",
    isIFPTieredPlan: false,
    bhReciprocityId: null,
    networks: [
      {
        type: "DENTAL",
        value: []
      },
      {
        type: "VISION",
        value: [
          "1"
        ]
      }
    ],
    lineOfBusiness: "E&I",
    planKey: "00_0905610_9999-12-31",
    viewPcp: true,
    editPcp: true,
    viewDependentPcp: true,
    editDependentPcp: true,
    familyId: "0023207369",
    rallyId: "8418e94d-39a2-4b0d-b1e8-296a4b1c1af4"
  }
]