export const removeAccents = (str) =>
  str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const removeLeadingZeroes = (str?: string): string =>
  str?.replace(/^0+/, '') || '';

export const trimSpaces = (str?: string): string => str?.trim() || '';

export const toCapitalCase = (str: string): string =>
  str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

export const trimBrackets = (
  str?: string,
  bracketType: '(' | '[' | '{' = '('
): string => {
  const text = str?.trim() || '';

  let regex = /^\(|\)$/g;
  if (bracketType === '[') regex = /^\[|\]$/g;
  else if (bracketType === '{') regex = /^\{|\}$/g;
  return text.replace(regex, '');
};

export const prependSpaceIfString = (...args: (string | undefined)[]) =>
  args.reduce((acc, str) => {
    if (str) acc += ' ' + str;
    return acc;
  }, '');
