import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import React from 'react';

import {
  CriteriaTextWrapper,
  MatchStyledText,
  StyledFlexWithGapAndNoWrap,
} from './SmartChoiceBreakDown.styles';

interface NotAMatchTextProps {
  text: string;
  StartIcon?: React.ReactNode;
  EndIcon?: React.ReactNode;
}
export const NotAMatchText = ({
  text,
  StartIcon,
  EndIcon,
}: NotAMatchTextProps) => (
  <CriteriaTextWrapper data-testid="not-a-match-text">
    <StyledFlexWithGapAndNoWrap direction="row">
      <IconSymbol color="#990000" icon="do_not_disturb" />{' '}
      {StartIcon && <React.Fragment>{StartIcon}</React.Fragment>}
    </StyledFlexWithGapAndNoWrap>
    {text && (
      <CriteriaTextWrapper>
        <MatchStyledText>{text}</MatchStyledText>
        {EndIcon && <React.Fragment>{EndIcon}</React.Fragment>}
      </CriteriaTextWrapper>
    )}
  </CriteriaTextWrapper>
);
