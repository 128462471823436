import { storage } from '@abyss/web/tools/storage';
import { getModuleOrDefault } from '@optum-fpc-psx-mobile-apps/mapping-files-translation';
import { MutableRefObject } from 'react';

import {
  CareCategories,
  Constants,
  MemberCoverageTypeToUICoverageTypeConversion,
  ReverseCoverageTypesCodes,
  SectionType,
  TypeaheadChipCategoriesMapping,
} from '../../common/Constants';
import { handleSearchBarSearchButtonClickOrEnter } from '../../common/PSXHeader/SearchBar/utils';
import { mappingRollUpCodeToCategory } from '../../common/RecentActivity/helper';
import { ChipCategory } from '../../frontends/ProviderSearch/routes/SearchResults/categoryCountTypes';
import {
  getCorrectChipCategory,
  getSearchResultsPageParams,
  getSearchType,
  getSelectedCoverageType,
} from '../../frontends/ProviderSearch/routes/SearchResults/SearchResults.UtilsV2';
import {
  getProviderSearchPayload,
  getProviderSearchPayloadWithRecriprocityId,
} from '../../frontends/ProviderSearch/routes/SearchResults/searchUtils';
import { UseParallelSearchResults } from '../../frontends/ProviderSearch/routes/SearchResults/useParallelSearchResultsCategoriesCount';
import { getFirstEnabledChip } from '../../frontends/ProviderSearch/routes/SearchResults/utils';
import { TypeaheadSearchState } from '../../store/useTypeaheadStore/typeaheadStore';
import {
  BrowseAllVariant,
  getProviderTypeBySectionType,
  handleOnCommonlySearchedClick,
  handleOnSpecialtyOrFacilityClick,
  handleSearchBarSpecialtySuggestionClickOrEnter,
} from '../../utils/typeahead.utils';
import { getOnlineRetailers } from '../../utils/user.utils';

export const getEnabledChipCategories = (specialityResults: ChipCategory[]) =>
  specialityResults.reduce<string[]>((acc, item) => {
    if (item.chipEnabled) {
      acc.push(TypeaheadChipCategoriesMapping[item.chipCategory]);
    }
    return acc;
  }, []);

export const getChipToSelect = ({
  specialtySearch,
  enabledChipForSpecialitySearch,
  enabledChipForNameSearch,
  navigateToChipCategory,
  chipValueFromParams,
  availableCategoryChipsForSpecialtySearch,
  availableCategoryChipsForNameSearch,
}) => {
  const isNameSearch = enabledChipForNameSearch && !specialtySearch;
  if (isNameSearch) {
    if (
      chipValueFromParams &&
      availableCategoryChipsForNameSearch.includes(chipValueFromParams)
    ) {
      return chipValueFromParams;
    }
    return enabledChipForNameSearch;
  } else {
    if (
      chipValueFromParams &&
      availableCategoryChipsForSpecialtySearch.includes(chipValueFromParams)
    ) {
      return chipValueFromParams;
    }
    if (
      navigateToChipCategory &&
      availableCategoryChipsForSpecialtySearch.includes(navigateToChipCategory)
    ) {
      return navigateToChipCategory;
    }
    return enabledChipForSpecialitySearch;
  }
};

export interface OnCategoryCountLoadedParams {
  categoryCountResults: UseParallelSearchResults;
  typeAheadData: TypeaheadSearchState;
  chipValue: string;
  searchParamsObject: any;
  isSpecialtySearch: boolean;
  enableNavigateToCorrectCategory: boolean;
  typeaheadPreferenceCodes: string[];
  dataRef: MutableRefObject<any>;
  previousDataRef: MutableRefObject<any>;
  isEmptyResults: MutableRefObject<boolean>;
  setIsLoading: (value: boolean) => void;
  setChipValue: (value: string) => void;
  setCoverageType: (value: string) => void;
  setSearchParams: (params: any, options?: any) => void;
}

// This is to load the initial Provider Search Results after Category Count is loaded
export const onCategoryCountLoaded = ({
  categoryCountResults,
  typeAheadData,
  chipValue,
  searchParamsObject,
  isSpecialtySearch,
  enableNavigateToCorrectCategory,
  typeaheadPreferenceCodes,
  dataRef,
  previousDataRef,
  isEmptyResults,
  setIsLoading,
  setChipValue,
  setCoverageType,
  setSearchParams,
}: OnCategoryCountLoadedParams) => {
  const { chipValue: chipValueFromParams } = searchParamsObject;
  const { combinedIncludeSpecialityRollupCodes = '' } = typeAheadData;
  const { nameResults, specialityResults } = categoryCountResults;
  const enabledChipForNameSearch = getFirstEnabledChip(nameResults);
  const enabledChipForSpecialitySearch = getFirstEnabledChip(specialityResults);
  const hasOnlySpecialitySearchEnabled =
    !enabledChipForNameSearch && !!enabledChipForSpecialitySearch;

  const availableCategoryChipsForSpecialtySearch =
    getEnabledChipCategories(specialityResults);
  const availableCategoryChipsForNameSearch =
    getEnabledChipCategories(nameResults);

  const navigateToChipCategory = enableNavigateToCorrectCategory
    ? getCorrectChipCategory(
        combinedIncludeSpecialityRollupCodes,
        typeaheadPreferenceCodes,
        specialityResults
      )
    : '';

  const specialtySearch = hasOnlySpecialitySearchEnabled || isSpecialtySearch;
  const chipToSelect = getChipToSelect({
    specialtySearch,
    enabledChipForSpecialitySearch,
    enabledChipForNameSearch,
    navigateToChipCategory,
    chipValueFromParams,
    availableCategoryChipsForSpecialtySearch,
    availableCategoryChipsForNameSearch,
  });

  // If chipToSelect is defined, then we have results for that category
  if (chipToSelect) {
    const selectedCoverageType = getSelectedCoverageType(chipToSelect);
    setChipValue(chipToSelect);
    setCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
    setSearchParams(
      {
        ...searchParamsObject,
        chipValue: chipToSelect,
        coverageType: ReverseCoverageTypesCodes[selectedCoverageType],
        searchType: getSearchType(specialtySearch),
      },
      { replace: true }
    );
    // We need this setTimeout for below reasons.
    // Say that user is in NSR page, and trigers a new search, Apollo does not trigger onCompleted
    // when the new results is the same as the previous results (prior to NSR page).
    // So, we are setting the isLoading to false after 1 second if the data is the same as the previous data
    setTimeout(() => {
      if (dataRef?.current === previousDataRef.current) {
        setIsLoading?.(false);
      }
      previousDataRef.current = null;
    }, 1000);
  } else {
    // chipToSelect is not defined, so we don't have results for any category
    // So, we need to show the empty results page
    isEmptyResults.current = true;
    previousDataRef.current = dataRef?.current || null;
    setChipValue(chipValueFromParams);
    const { coverageType } = typeAheadData;
    if (coverageType && chipValue === CareCategories.ALL) {
      setCoverageType(coverageType);
      setSearchParams(
        {
          ...searchParamsObject,
          coverageType,
        },
        { replace: true }
      );
    }
    setIsLoading?.(false);
  }
  return { chipToSelect, specialtySearch };
};

export const getDataForProviders = ({
  isSpecialtySearch,
  chipToUse,
  baseParams,
  typeAheadDataRef,
  choosePCP,
  sectionType,
  categoryCountResultsRef,
  setTypeaheadSearchStore,
  getProviderSearchResults,
  memberCoverageTypes,
  featureFlags,
  currentMember,
  coverageType,
  onlineRetailers,
  enableBHAoeTypeahead,
  enableAoeMedical,
  setShowFacilities,
  setShowProviderGroups,
  setShowProviders,
  cleanUpProviderCalls,
}) => {
  const uesTranslation = getModuleOrDefault('en', 'uesSpecialityTranslations');
  const {
    providerSearchParams,
    otherParams: { coverageTypes },
  } = getSearchResultsPageParams({
    ...baseParams,
    typeAheadData: typeAheadDataRef.current,
    chipValue: chipToUse,
  });

  const {
    search,
    includeSpecialityRollupCodes,
    pseudoVerticalsDetails,
    isUserTextSearch,
    typeaheadAoeCodes,
    keyword,
    bhProgramFacilityAgg,
  } = providerSearchParams;

  if (!(choosePCP || search)) {
    return;
  }
  const searchTypeToUse = getSearchType(isSpecialtySearch);
  const payloadWithRecriprocityId = getProviderSearchPayloadWithRecriprocityId(
    memberCoverageTypes,
    featureFlags,
    currentMember,
    coverageType,
    choosePCP,
    onlineRetailers
  );
  const payload = getProviderSearchPayload(
    payloadWithRecriprocityId,
    isSpecialtySearch,
    searchTypeToUse,
    isUserTextSearch,
    typeaheadAoeCodes,
    enableBHAoeTypeahead,
    includeSpecialityRollupCodes,
    pseudoVerticalsDetails?.isPseudoRollupSearch,
    uesTranslation,
    search,
    keyword,
    coverageTypes,
    bhProgramFacilityAgg,
    enableAoeMedical
  );

  const selectedCategory = Constants.CARE_CATEGORIES_TYPEAHEAD.find(
    (category) => category.label === chipToUse
  );
  const selectedCategoryPayload = payload.find(
    (item) => item.chipCategory === selectedCategory?.id
  );

  const { specialityResults } = categoryCountResultsRef.current;
  const providerQueryTypes: any =
    specialityResults?.filter(
      (item) => item.chipCategory === selectedCategory?.id
    )[0]?.providerTypes || [];
  setTypeaheadSearchStore({ providerQueryTypes });
  getProviderSearchResults({
    setShowFacilities,
    setShowProviderGroups,
    setShowProviders,
    providerSearchParams: {
      ...providerSearchParams,
      searchType: searchTypeToUse,
    },
    choosePCP,
    cleanUpProviderCalls,
    pseudoVerticalsDetails,
    selectedCategory,
    selectedCategoryPayload,
    specialtySearch: isSpecialtySearch,
    searchType: searchTypeToUse,
    providerQueryTypes,
    ...(sectionType
      ? {
          providerType: getProviderTypeBySectionType(
            sectionType as SectionType
          ),
        }
      : {}),
  });
};

export const retreiveTypeAheadData = ({
  enableRetriveTypeAheadData,
  resolvedSource,
  source,
  search,
  commonSearchesLagoon,
  specialityPreferenceTable,
  searchTerm,
  getCoverageType,
  dependentSeqNbr,
  choosePCP,
  chipStore,
  setTypeaheadSearchStore,
  setAnalyticsState,
  setPCPSearchState,
  setChipValue,
  setCoverageType,
  setDetailsStore,
  setSearchStore,
  facilities,
  specialties,
  GetAutoCompleteResults,
  onTypeAheadDataLoaded,
  typeaheadSearchStore,
}) => {
  try {
    const isGlobalSearch =
      resolvedSource === Constants.SEARCH_SOURCE_TYPES.GLOBAL_SEARCH;
    const isCommonSearch =
      resolvedSource === Constants.SEARCH_SOURCE_TYPES.COMMON_SEARCHES;
    const isBrowseForCareSearch =
      resolvedSource === Constants.SEARCH_SOURCE_TYPES.BROWSE_FOR_CARE;
    const isSpecialtyDrawerSearch =
      resolvedSource === Constants.SEARCH_SOURCE_TYPES.SPECIALTY_DRAWER;
    const isFacilitiesDrawerSearch =
      resolvedSource === Constants.SEARCH_SOURCE_TYPES.FACILITIES_DRAWER;
    const isRecentsearches =
      resolvedSource === Constants.SEARCH_SOURCE_TYPES.RECENT_SEARCHES;
    const isAutoCompleteSearch =
      resolvedSource === Constants.SEARCH_SOURCE_TYPES.AUTO_COMPLETE;
    const isDeeplinkSearch =
      resolvedSource === Constants.SEARCH_SOURCE_TYPES.DEEPLINK;

    if (isRecentsearches) {
      const recentSearchesKey = `${search}:${source}`;
      const typeaheadValues =
        storage.session.get('recent-searches')?.[recentSearchesKey];
      if (typeaheadValues) {
        // if we have the data in session storage use them or else use auto complete query
        onTypeAheadDataLoaded(typeaheadValues);
      } else {
        GetAutoCompleteResults({
          query: encodeURIComponent(searchTerm.toString().trim()),
        });
      }
    } else if (isCommonSearch) {
      const value = commonSearchesLagoon.find(
        ({ psxKeyword }) => search === psxKeyword
      );
      const typeaheadValues = handleSearchBarSpecialtySuggestionClickOrEnter({
        enableRetriveTypeAheadData,
        specialityPreferenceTable,
        value,
        searchTerm,
        commonSearch: true,
        getCoverageType,
        dependentSeqNbr,
        choosePCP,
        chipStore,
        setTypeaheadSearchStore,
        setAnalyticsState,
        setPCPSearchState,
        setChipValue,
        setCoverageType,
        setDetailsStore,
        navigate: undefined,
      });
      onTypeAheadDataLoaded(typeaheadValues);
    } else if (isBrowseForCareSearch) {
      const value = commonSearchesLagoon.find(
        ({ psxKeyword }) => search === psxKeyword
      );
      const typeaheadValues = handleOnCommonlySearchedClick({
        item: value,
        source,
        navigate: undefined,
        chipStore,
        setAnalyticsState,
        setPCPSearchState,
        setTypeaheadSearchStore,
        setSearchStore,
        getOnlineRetailers,
        mappingRollUpCodeToCategory,
        enableRetriveTypeAheadData,
      });
      onTypeAheadDataLoaded(typeaheadValues);
    } else if (isAutoCompleteSearch || isGlobalSearch || isDeeplinkSearch) {
      GetAutoCompleteResults({
        query: encodeURIComponent(searchTerm.toString().trim()),
      });
    } else if (isFacilitiesDrawerSearch || isSpecialtyDrawerSearch) {
      const records = isFacilitiesDrawerSearch ? facilities : specialties;
      if (!records.length) {
        return;
      }
      const item = records.find(({ name }) => name === search);
      const typeaheadValues = handleOnSpecialtyOrFacilityClick({
        item,
        navigate: undefined,
        setTypeaheadSearchStore,
        setAnalyticsState,
        chipState: chipStore,
        setDetailsStore,
        variant: isFacilitiesDrawerSearch
          ? BrowseAllVariant.Facility
          : BrowseAllVariant.Specialty,
        enableRetriveTypeAheadData,
      });
      onTypeAheadDataLoaded(typeaheadValues);
    }
  } catch {
    onTypeAheadDataLoaded(typeaheadSearchStore);
  }
};

export const retriveTypeAheadDataFromAutoComplete = ({
  suggestions,
  typeAheadSuggestions,
  combinedRollupCodes,
  aggregatedAoeCodes,
  bhProgramFacilityAgg,
  search,
  searchLinkName,
  searchTypeFromParams,
  searchType,
  enableRetriveTypeAheadData,
  enableUESSuggestionMatch,
  specialityPreferenceTable,
  getCoverageType,
  dependentSeqNbr,
  choosePCP,
  chipStore,
  setTypeaheadSearchStore,
  setAnalyticsState,
  setPCPSearchState,
  setChipValue,
  setCoverageType,
  setDetailsStore,
  currentMember,
  convertTypeaheadProviderIdAndType,
  getTypeAheadCategory,
  onTypeAheadDataLoaded,
  typeaheadSearchStore,
}) => {
  try {
    const value = suggestions?.find(
      ({ suggestion }) =>
        suggestion.toLowerCase() === search.toString().toLowerCase()
    );

    const isSearchButtonClickOrKeyboardEnter =
      ['search button', 'search enter', 'general search'].includes(
        searchLinkName
      ) || searchType === Constants.SEARCH_TYPES.NAME;
    const isSuggestionClickOrEnter = !isSearchButtonClickOrKeyboardEnter;

    if (isSuggestionClickOrEnter) {
      if (!value) {
        onTypeAheadDataLoaded(typeaheadSearchStore);
        return;
      }

      const typeaheadValues = handleSearchBarSpecialtySuggestionClickOrEnter({
        enableRetriveTypeAheadData,
        specialityPreferenceTable,
        value,
        searchTerm: search,
        commonSearch: false,
        getCoverageType,
        dependentSeqNbr,
        choosePCP,
        chipStore,
        setTypeaheadSearchStore,
        setAnalyticsState,
        setPCPSearchState,
        setChipValue,
        setCoverageType,
        setDetailsStore,
        navigate: undefined,
      });
      onTypeAheadDataLoaded(typeaheadValues);
    } else if (isSearchButtonClickOrKeyboardEnter) {
      const analyticsLinkName = searchLinkName || 'search button';
      const typeaheadLinkName =
        searchLinkName === 'general search'
          ? 'typeahead general search'
          : 'typeahead search';

      const typeaheadValues = handleSearchBarSearchButtonClickOrEnter(
        {
          isLLMGeneralSearchEnabled: searchLinkName === 'general search',
          enableRetriveTypeAheadData,
          enableUESSuggestionMatch,
          typeAheadSuggestions: typeAheadSuggestions || [],
          navigate: undefined,
          headers: undefined,
          analyticsLinkName,
          typeaheadLinkName,
          currentMember,
          userSearchTerm: search,
          combinedRollupCodes,
          aggregatedAoeCodes,
          bhProgramFacilityAgg,
          setIsFocusedOnKeywordSearchInput: () => {},
          convertTypeaheadProviderIdAndType,
          getTypeAheadCategory,
          store: {
            chipStore,
            setTypeaheadSearchStore,
            setAnalyticsState,
            setPCPSearchState,
            setChipValue,
            setCoverageType,
            setDetailsStore,
          },
        },
        specialityPreferenceTable
      );

      if (typeaheadValues.isMixedSuggestions && searchTypeFromParams) {
        typeaheadValues.searchType = searchTypeFromParams;
      }
      onTypeAheadDataLoaded(typeaheadValues);
    }
  } catch {
    onTypeAheadDataLoaded(typeaheadSearchStore);
  }
};

export const getConvertedCoverageArray = (memberCoverages) => {
  const convertedMemberCoverages = memberCoverages?.map(
    (cov) => MemberCoverageTypeToUICoverageTypeConversion[cov]
  );
  const dentalCoverage = convertedMemberCoverages?.find((cov) => cov === 'D');
  const visionCoverage = convertedMemberCoverages?.find((cov) => cov === 'V');
  const behavioralCoverage = convertedMemberCoverages?.find(
    (cov) => cov === 'B'
  );
  const medicalCoverage = convertedMemberCoverages?.find((cov) => cov === 'M');
  const getCoverageType = [
    dentalCoverage,
    visionCoverage,
    medicalCoverage,
    behavioralCoverage,
  ];
  return getCoverageType;
};
