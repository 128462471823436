/* eslint-disable no-nested-ternary */
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeviceLocation } from '../../../../hooks/useDeviceLocation';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../Constants';
import {
  DropdownContentContainer,
  DropdownPositionContainer,
  hiddenTextStyle,
} from './LocationDropdown.styled';
import { LocationDropdownSkeleton } from './LocationDropdownSkeleton';
import { LocationSuggestionStack } from './LocationSuggestionStack';

type Props = {
  onClose: () => void;
  activeSuggestion: number;
  locationInputVal: string;
  locationSuggestions?: object[];
  isLocationLoading: boolean;
  setSelectedLocation: (any) => void;
  setLocationInputVal: (val: string) => void;
  id?: string;
};

export const LocationDropdown = ({
  onClose,
  activeSuggestion,
  locationSuggestions = [],
  isLocationLoading,
  setSelectedLocation,
  locationInputVal,
  setLocationInputVal,
  id,
}: Props) => {
  const { t } = useTranslation();
  const locationForAnalytics: string = 'change search location';

  const handleLocationItemClick = (
    location: { place_name: string },
    index: number
  ) => {
    if (index === locationSuggestions.length - 1) {
      getDeviceLocation();
      adobeLinkTrackEvent({
        name: Constants.LOCATION_SEARCH.FIND_MY_LOCATION,
        location: `body:${locationForAnalytics}`,
        type: 'internal',
      });
    } else {
      adobeLinkTrackEvent({
        name: 'suggested location',
        location: `body:${locationForAnalytics}`,
        type: 'internal',
      });
      setSelectedLocation(location);
      setLocationInputVal(location?.place_name);
    }
    onClose();
  };
  const [, setDeviceLocation] = useState<string>('');
  const { getDeviceLocation } = useDeviceLocation(setDeviceLocation);

  return (
    <DropdownPositionContainer data-testid="location-dropdown-container">
      <DropdownContentContainer>
        <Layout.Stack
          data-testid="location-suggestions-dropdown-container"
          grow
          space={15}
        >
          <Text color="$gray7">
            {t('LOCATION_SEARCH.HEADER_DECEMBER_RELEASE')}
            <span
              id={id}
              style={{
                display: 'none',
              }}
            >
              {t('LOCATION_SEARCH.HEADER_DECEMBER_RELEASE')}
            </span>
          </Text>
          <Layout.Stack alignItems="left" space={12}>
            {isLocationLoading ? (
              <LocationDropdownSkeleton testId="location-dropdown-container-skeleton" />
            ) : locationSuggestions?.length === 1 &&
              locationInputVal.trim().length > 0 ? (
              <Layout.Group
                alignItems="top"
                data-testid="location-invalid-suggestion"
              >
                <IconMaterial color="$error1" icon="error" />
                <Text color="$error1" role="alert">
                  <Text css={hiddenTextStyle}>Error: </Text>
                  {t('LOCATION_SEARCH.ERROR')}
                </Text>
              </Layout.Group>
            ) : locationInputVal.trim().length > 0 ? (
              <LocationSuggestionStack
                activeSuggestion={activeSuggestion}
                onSuggestionClick={handleLocationItemClick}
                suggestions={locationSuggestions}
              />
            ) : null}
          </Layout.Stack>
        </Layout.Stack>
      </DropdownContentContainer>
    </DropdownPositionContainer>
  );
};
