import { IconMaterial } from '@abyss/web/ui/IconMaterial';

import {
  BannerStyled,
  IconContainer,
  StyledMarkdown,
} from './CustomDisclaimerBanner.style';

interface Props {
  data: {
    message: string;
    icon?: string;
  }[];
}
export const CustomDisclaimerBanner = ({ data }: Props) => {
  if (data?.length === 0) {
    return null;
  }
  return (
    <>
      {data?.map((item, index) => (
        <BannerStyled data-testid="custom-disclaimer-banner" key={index}>
          <IconContainer>
            {item?.icon && (
              <IconMaterial color="info1" icon={item?.icon} size="$sm" />
            )}
          </IconContainer>
          <StyledMarkdown>{item?.message}</StyledMarkdown>
        </BannerStyled>
      ))}
    </>
  );
};
