import { storage } from '@abyss/web/tools/storage';

import {
  Constants,
  NULL_RESULTS,
  ProviderType,
  ReverseCoverageTypesCodes,
  SectionType,
  TypeaheadBHType,
  TypeaheadProviderTypes,
} from '../common/Constants';
import { ConstantsRoutes } from '../common/ConstantsRoutes';
import {
  getLinkNameForAnalytics,
  getReqCoverageType,
  getReqCoverageTypes,
  getSearchMethodForAnalytics,
  handleSearchSelected,
  setStore,
} from '../common/PSXHeader/SearchBar/utils';
import { convertObjectToUrlParams } from '../common/Utils';

const resolveAutoCompleteValue = (args) => {
  const { value, searchTerm, getCoverageType, dependentSeqNbr, choosePCP } =
    args;
  const isCommonSearch = !!value?.psxKeyword;
  return {
    ...value,
    searchTerm,
    resultType: value ? Constants.RESULT_SECTION.ALL : NULL_RESULTS,
    linkName: getLinkNameForAnalytics(value),
    typeaheadLinkName: `${getLinkNameForAnalytics(value)}-${value?.vertical}`,
    searchMethod: getSearchMethodForAnalytics(value),
    fromViewAll: false,
    includeSpecialityRollupCodes:
      value?.vertical?.code || value?.vertical || value?.pesKeyword,
    searchType:
      value?.vertical ||
      value?.pseudoVerticals ||
      value?.aoeCodes?.length > 0 ||
      isCommonSearch ||
      value?.bhProgramFacility
        ? Constants.SEARCH_TYPES.SPECIALTY
        : Constants.SEARCH_TYPES.NAME,
    coverageType: getReqCoverageType(value?.coverageType, getCoverageType),
    coverageTypes: getReqCoverageTypes(value?.coverageType, getCoverageType),
    dependentSeqNbr,
    choosePCP,
    bhProgramFacilityAgg: value?.bhProgramFacility,
  };
};

export const handleSearchBarSuggestionClickOrEnter = (args: any) => {
  const value = resolveAutoCompleteValue(args);
  const {
    chipStore,
    setTypeaheadSearchStore,
    setAnalyticsState,
    setPCPSearchState,
    setChipValue,
    setCoverageType,
    setDetailsStore,
    navigate,
  } = args;

  const store = {
    chipStore,
    setTypeaheadSearchStore,
    setAnalyticsState,
    setPCPSearchState,
    setChipValue,
    setCoverageType,
    setDetailsStore,
  };

  const updateValue = {
    ...value,
    choosePCP: false,
    dependentSeqNbr: value?.dependentSeqNbr,
    pseudoVerticals: value?.pseudoVerticals,
    isMixedSuggestions: value?.isMixedSuggestions || false,
    isUserTextSearch: value?.isUserTextSearch || false,
    searchFromTypeAhead: true,
  };

  // navigate to details page when below conditions are met
  // else navigate to search results page
  if (value?.providerType === TypeaheadProviderTypes.PRACTITIONER) {
    const updateProviderValue = {
      ...value,
      choosePCP: value?.choosePCP,
      searchByProvider: true,
      searchFromTypeAhead: true,
      coverageType: value?.coverageType,
      dependentSeqNbr: value?.dependentSeqNbr,
      searchBySpeciality: false,
    };
    setStore(store, updateProviderValue);
    const urlParams = convertObjectToUrlParams(store.chipStore, {
      ...updateProviderValue,
    });
    navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${urlParams}`);
  } else if (
    value?.providerType === TypeaheadProviderTypes.FACILITY ||
    value?.providerType === TypeaheadProviderTypes.MEDICAL_HEALTH_SUPPLIES ||
    value?.providerType === TypeaheadProviderTypes.VISION ||
    value?.providerType === TypeaheadProviderTypes.DENTAL ||
    (value?.providerType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
      value?.providerId?.startsWith(TypeaheadBHType.FAC))
  ) {
    setStore(store, {
      ...updateValue,
      searchBySpeciality: false,
    });
    const urlParams = convertObjectToUrlParams(store.chipStore, {
      ...updateValue,
      searchBySpeciality: false,
    });
    navigate(`${ConstantsRoutes.FACILITY_DETAILS.path}${urlParams}`);
  } else if (
    value?.providerType === TypeaheadProviderTypes.PROVIDER_GROUP ||
    (value?.providerType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
      value?.providerId?.startsWith(TypeaheadBHType.GRP))
  ) {
    setStore(store, {
      ...updateValue,
      choosePCP: value?.choosePCP,
      searchBySpeciality: false,
    });
    const urlParams = convertObjectToUrlParams(store.chipStore, {
      ...updateValue,
      choosePCP: value?.choosePCP,
      searchBySpeciality: false,
    });
    navigate(`${ConstantsRoutes.PROVIDER_GROUP_DETAILS.path}${urlParams}`);
  } else {
    // navigate to search results page when above conditions are not met
    handleSearchBarSpecialtySuggestionClickOrEnter(args);
  }
};

export const handleSearchBarSpecialtySuggestionClickOrEnter = (args: any) => {
  const {
    enableRetriveTypeAheadData,
    specialityPreferenceTable,
    chipStore,
    setTypeaheadSearchStore,
    setAnalyticsState,
    setPCPSearchState,
    setChipValue,
    setCoverageType,
    setDetailsStore,
    navigate,
  } = args;
  const value = resolveAutoCompleteValue(args);
  const store = {
    chipStore,
    setTypeaheadSearchStore,
    setAnalyticsState,
    setPCPSearchState,
    setChipValue,
    setCoverageType,
    setDetailsStore,
  };
  const typeaheadValues = handleSearchSelected(
    enableRetriveTypeAheadData,
    specialityPreferenceTable,
    value,
    store,
    navigate
  );
  return typeaheadValues;
};

export const excludeDisabledResults = (
  memberCoverageType,
  suggestionResults
) => {
  const filteredResults: Object[] = [];
  suggestionResults.forEach((result) => {
    if (memberCoverageType.some((type) => result.coverageType.includes(type))) {
      filteredResults.push(result);
    }
  });

  return filteredResults;
};

export enum BrowseAllVariant {
  Specialty = 'specialty',
  Facility = 'facility',
}

export const handleOnSpecialtyOrFacilityClick = ({
  item,
  navigate,
  setTypeaheadSearchStore,
  setAnalyticsState,
  chipState,
  setDetailsStore,
  variant,
  enableRetriveTypeAheadData,
}) => {
  if (variant === BrowseAllVariant.Specialty) {
    if (item?.providerId) {
      const detailsStoreValues = {
        providerId: item.providerId,
      };
      setDetailsStore(detailsStoreValues);
      storage.session.set(
        Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
        item.providerId
      );
      const urlParams = convertObjectToUrlParams(chipState, detailsStoreValues);
      navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${urlParams}`);
      return null;
    } else if (item?.codes) {
      const providerGroupRollUpCodes: string[] = item.codes
        .map((codes) => {
          const firstRollUpCode = `52-526-050${codes.slice(2)}`;
          const secondRollUpCode = `52-526-033${codes.slice(2)}`;

          return [firstRollUpCode, secondRollUpCode];
        })
        .flat()
        .join(',');

      const rollUpCodes = `${providerGroupRollUpCodes},${item.codes.join(',')}`;

      setAnalyticsState({
        linkName: item.name,
        originLinkNameForAnalytics: item.name,
      });
      const typeaheadStoreValues = {
        search: item.name,
        includeSpecialityRollupCodes: rollUpCodes || '',
        combinedIncludeSpecialityRollupCodes: rollUpCodes || '',
        providerType: '',
        searchType: Constants.SEARCH_TYPES.SPECIALTY,
        specialtyCode: item.codes.join(',') || '',
        sectionType: Constants.RESULT_SECTION.PROVIDER_CAPITAL,
        drawerName: Constants.DRAWER_NAME.BROWSE_ALL_SPECIALTIES,
        medicalSpeciality: true,
      };
      setTypeaheadSearchStore(typeaheadStoreValues);
      if (!enableRetriveTypeAheadData) {
        const urlParams = convertObjectToUrlParams(
          chipState,
          typeaheadStoreValues
        );
        navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
        // we don't need to return anything as we are navigating to different page.
        return null;
        // enableRetriveTypeAheadData is turned on, navigate is provided for direct user search flow.
        // We need to pass only the independent variables to the url and navite to the search results page.
      } else if (navigate) {
        const urlParams = convertObjectToUrlParams(chipState, {
          search: item.name,
          searchType: Constants.SEARCH_TYPES.SPECIALTY,
          source: Constants.SEARCH_SOURCE_TYPES.SPECIALTY_DRAWER,
        });
        navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
        // we don't need to return anything as we are navigating to different page.
        return null;
      } else {
        // navigate is not provided for broswer back button flow where
        // navigation is not required and it returns the store values.
        return typeaheadStoreValues;
      }
    }
  } else if (variant === BrowseAllVariant.Facility) {
    if (item.codes) {
      const rollUpCodes = item.codes.join(',');

      setAnalyticsState({
        linkName: item.name,
        originLinkNameForAnalytics: item.name,
      });
      const typeaheadStoreValues = {
        search: item.name,
        includeSpecialityRollupCodes: rollUpCodes || '',
        combinedIncludeSpecialityRollupCodes: rollUpCodes || '',
        providerType: '',
        searchType: Constants.SEARCH_TYPES.SPECIALTY,
        facilityCode: item.codes.join(',') || '',
        sectionType: Constants.RESULT_SECTION.PROVIDER_CAPITAL,
        drawerName: Constants.DRAWER_NAME.BROWSE_ALL_FACILITIES,
      };
      setTypeaheadSearchStore(typeaheadStoreValues);

      if (!enableRetriveTypeAheadData) {
        const urlParams = convertObjectToUrlParams(
          chipState,
          typeaheadStoreValues
        );
        navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
        // we don't need to return anything as we are navigating to different page.
        return null;
        // enableRetriveTypeAheadData is turned on, navigate is provided for direct user search flow.
        // We need to pass only the independent variables to the url and navite to the search results page.
      } else if (navigate) {
        const urlParams = convertObjectToUrlParams(chipState, {
          search: item.name,
          searchType: Constants.SEARCH_TYPES.SPECIALTY,
          source: Constants.SEARCH_SOURCE_TYPES.FACILITIES_DRAWER,
        });
        navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
        // we don't need to return anything as we are navigating to different page.
        return null;
      } else {
        // navigate is not provided for broswer back button flow where
        // navigation is not required and it returns the store values.
        return typeaheadStoreValues;
      }
    }
  }
  return null;
};

export const handleOnCommonlySearchedClick = ({
  item,
  source,
  navigate,
  chipStore,
  setAnalyticsState,
  setPCPSearchState,
  setTypeaheadSearchStore,
  setSearchStore,
  getOnlineRetailers,
  mappingRollUpCodeToCategory,
  enableRetriveTypeAheadData,
}) => {
  setAnalyticsState({
    originLinkNameForAnalytics: item?.psxKeyword,
  });
  const pcpStoreValues = {
    choosePCP: false,
  };
  setPCPSearchState(pcpStoreValues);
  const typeaheadStoreValues = {
    search: item?.psxKeyword,
    heading: '',
    searchType: item?.searchType?.toLowerCase(),
    pesKeyword: item?.pesKeyword,
    providerType: item?.providerType ?? '',
    includeSpecialityRollupCodes: item?.pesKeyword,
    combinedIncludeSpecialityRollupCodes: item?.pesKeyword,
  };
  setTypeaheadSearchStore({
    ...typeaheadStoreValues,
    category: item?.category,
    resultType: Constants.RESULT_SECTION.ALL,
    keyword: item?.psxKeyword || item?.searchTerm,
    isUserTextSearch: false,
  });
  const searchStoreValues = {
    onlineRetailers: getOnlineRetailers(item?.isOnlineProviders),
  };
  setSearchStore(searchStoreValues);
  if (!enableRetriveTypeAheadData) {
    const urlParams = convertObjectToUrlParams(chipStore, {
      ...pcpStoreValues,
      ...typeaheadStoreValues,
      choosePCP: false,
      chipValue: mappingRollUpCodeToCategory(item?.pesKeyword),
      originLinkNameForAnalytics: item?.psxKeyword,
      resultType: Constants.RESULT_SECTION.ALL,
      coverageType: item?.coverageType,
      keyword: item?.psxKeyword || item?.searchTerm,
      onlineRetailers: getOnlineRetailers(item?.isOnlineProviders),
      includeSpecialityRollupCodes: item?.pesKeyword,
    });
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
    return null;
  } else if (navigate) {
    const urlParams = convertObjectToUrlParams(chipStore, {
      search: item.psxKeyword,
      searchType: item?.searchType?.toLowerCase(),
      source: `browse-for-care:${source}`,
    });
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
    // we don't need to return anything as we are navigating to different page.
    return null;
  } else {
    // navigate is not provided for broswer back button flow where
    // navigation is not required and it returns the store values.
    return typeaheadStoreValues;
  }
};

export const getResolvedSource = (source: string) => {
  const sourceData = source?.split(':');
  const searchLinkName = sourceData?.[1];
  const allSuggestionsArray = [
    'search button',
    'search enter',
    'general search',
  ];
  if (allSuggestionsArray.includes(searchLinkName)) {
    return `${sourceData?.[0]}:all suggestions`;
  } else {
    return source;
  }
};

export const getIncludeSpecialityRollupCodesFilteredByCategory = (
  uesTranslation,
  codeByGroup,
  uesType
) => {
  const includeSpecialityRollupCodesFilteredByCategory: string[] = [];
  codeByGroup.forEach((value: string) => {
    const foundSpecialty = uesTranslation?.[value?.trim()];
    const isBHFacilityFound = foundSpecialty?.find(
      (specialty) =>
        specialty.type === TypeaheadProviderTypes.FACILITY &&
        specialty.coverageType ===
          ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
    );
    if (isBHFacilityFound) {
      if (uesType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH) {
        includeSpecialityRollupCodesFilteredByCategory.push(value);
      }
    }
    foundSpecialty?.forEach((specialty) => {
      if (uesType === specialty?.type) {
        includeSpecialityRollupCodesFilteredByCategory.push(value);
      }
    });
  });
  return includeSpecialityRollupCodesFilteredByCategory;
};

export const getOrgTypeCodes = (
  label,
  includeOrgTypeCodes,
  excludeOrgTypeCodes,
  searchType
): { includeOrgTypeCodes: string; excludeOrgTypeCodes: string } => {
  if (
    searchType === 'name' &&
    label === Constants.CHIPS_CATEGORIES.HEALTH_SUPPLIES
  ) {
    return {
      includeOrgTypeCodes: JSON.stringify(includeOrgTypeCodes),
      excludeOrgTypeCodes: '',
    };
  } else if (
    searchType === 'name' &&
    label === Constants.CHIPS_CATEGORIES.FACILITIES
  ) {
    return {
      includeOrgTypeCodes: '',
      excludeOrgTypeCodes: JSON.stringify(excludeOrgTypeCodes),
    };
  } else {
    return { includeOrgTypeCodes: '', excludeOrgTypeCodes: '' };
  }
};

export const getProviderTypeBySectionType = (sectionType: SectionType) => {
  const providerTypeBySection = {
    [SectionType.PROVIDER]: ProviderType.PRACTITIONER,
    [SectionType.PROVIDER_GROUP]: ProviderType.MEDICAL_GROUP,
    [SectionType.FACILITY]: ProviderType.ORGANIZATION,
  };
  return providerTypeBySection[sectionType];
};
