import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Text } from '@abyss/web/ui/Text';

import { GuestSearchBar } from './GuestSearchBar';

export const GuestProviderNameSearchContainer = () => {
  return (
    <PageBody>
      <Heading textAlign="center">Who are you looking for?</Heading>

      <Flex style={{ justifyContent: 'center' }}>
        <Text>
          Search by provider, provider group, or facility and location.
        </Text>

        <Flex style={{ marginTop: '21px', width: '75%' }}>
          <GuestSearchBar />
        </Flex>
      </Flex>
    </PageBody>
  );
};
