import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { config } from '@abyss/web/tools/config';
import { Card } from '@abyss/web/ui/Card';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
/* eslint-disable no-nested-ternary */
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';

/* eslint-disable @optum-fpc-psx-mobile-apps/no-missing-testid */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useLagoon } from '../../hooks/useLagoon';
import { useSnackcardViewAllCount } from '../../hooks/useSnackcardViewAllCount';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { BottomWrapper } from '../DataCard/DataCard.style';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { SnackCardViewAllLink } from '../SnackCardContainer/SnackCardViewAllLink';
import { makeAllSlidesAriaHidden } from '../Utils/a11yUtils/a11yUtils';
import {
  HeaderWrapper,
  HeadingContainer,
  NoResultsFoundContainer,
  ResultWrapper,
  headerTextStyle,
  mixedResultCarouselStyle,
} from './ResultSection.style';

export const ResultSectionVirtual = ({ title }) => {
  const cdnUrl = config('CDN_BASE_URL');
  const phoneScreen = useMediaQuery(phoneOnly);
  const cardCssStyles = {
    'abyss-card-root': {
      height: '200px',
      width: phoneScreen ? '85%' : '95%',
      display: 'flex',
      borderColor: '#F3F3F3F3',
      borderRadius: '10px',
      flexDirection: 'column',
      '.abyss-card-section:first-child': {
        flexGrow: 1,
      },
      '&:active, &:focus, &:hover': {
        border: '2px solid $interactive2',
      },
    },
  };
  const [slidesPerView, setSlidesPerView] = useState(2);
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const virtualVisitProviders = useLagoon('virtual-visit-providers')();

  const { isExpandViewAllEnabled } = useSnackcardViewAllCount(
    virtualVisitProviders.length
  );
  const uiMessaging = useLagoon('ui-messaging')();
  const virtualVisitViewAll = find(uiMessaging, {
    key: ConstantsLagoon.VIRTUAL_VISIT.VIRTUAL_VISIT_VIEW_ALL,
  });

  useEffect(() => {
    let windowWidth = window.innerWidth;
    function handleResize() {
      windowWidth = window.innerWidth;
      setWinWidth(windowWidth);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    if (winWidth > 872) {
      if (winWidth > 872 && virtualVisitProviders?.length >= 3)
        setSlidesPerView(2);
      else if (winWidth < 1250 && virtualVisitProviders?.length === 2)
        setSlidesPerView(3);
      else if (winWidth < 1250 && virtualVisitProviders?.length > 2)
        setSlidesPerView(2);
      else if (winWidth > 1250 && virtualVisitProviders?.length >= 2)
        setSlidesPerView(3);
      else if (virtualVisitProviders?.length >= 1) setSlidesPerView(4);
    }
    if (winWidth < 872) setSlidesPerView(1);
    if (
      isExpandViewAllEnabled &&
      winWidth > 868 &&
      (virtualVisitProviders?.length === 1 ||
        virtualVisitProviders?.length === 2)
    )
      setSlidesPerView(2);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [winWidth, isExpandViewAllEnabled]);

  const providerSlides = virtualVisitProviders.map((provider) => (
    <Slide
      css={{
        'abyss-slide-container': {
          marginLeft: 0,
          background: 'transparent',
        },
      }}
    >
      <Slide.Container css={{ padding: 0 }}>
        <Card css={cardCssStyles}>
          <Card.Section
            style={{
              padding: '20px 15px',
            }}
          >
            <div
              style={{
                height: '50px',
                marginBottom: '10px',
              }}
            >
              <img
                alt={provider.title}
                src={`${cdnUrl}/${provider.providerIcon}`}
                style={{
                  height: 'auto',
                  maxHeight: '100%',
                  width: 'auto',
                  maxWidth: '50%',
                  backgroundImage: 'none',
                }}
              />
            </div>
            <div
              style={{
                height: '50px',
                marginBottom: '10px',
              }}
            >
              <Text>{provider.title}</Text>
            </div>
            <BottomWrapper
              css={{
                padding: '8px 12px',
              }}
              justify="left"
            >
              <div
                style={{
                  marginTop: '8px',
                  marginBottom: '8px',
                }}
              >
                <LinkWithTracking
                  analyticsInfo={{
                    location: 'body:virtual care visits',
                    destinationUrl: `${provider.ctaUrl}`,
                  }}
                  data-auto-testid={`${provider.title}`}
                  data-testid={`${provider.title}`}
                  isStandardAnchor
                  onClick={() => window.open(provider.ctaUrl)}
                  size="16px"
                >
                  {provider.ctaText}
                  <IconMaterial
                    css={{ marginLeft: '8px' }}
                    icon="open_in_new"
                  />
                </LinkWithTracking>
              </div>
            </BottomWrapper>
          </Card.Section>
        </Card>
      </Slide.Container>
    </Slide>
  ));

  const viewAllSlide =
    virtualVisitProviders.length > 2 ? (
      <Slide
        css={{
          'abyss-slide-container': {
            marginLeft: 0,
            background: 'transparent',
          },
        }}
        key="view-all-link"
      >
        <Slide.Container css={{ padding: 0 }}>
          <SnackCardViewAllLink
            handleViewAllOnClick={() => window.open(virtualVisitViewAll?.url)}
            hasPhysicalAddress={false}
            heading={virtualVisitViewAll?.message}
            isInNetworkBadge={false}
            isNetworkAlertIndicator={false}
            isOutOfCntyIndicatorEnabled={false}
            isVirtualCareOffered={false}
            isVirtualVisit={true}
            providerType={'Providers'}
          />
        </Slide.Container>
      </Slide>
    ) : null;

  const slides = [...providerSlides, viewAllSlide].filter(Boolean);
  const marginBottomCss = {
    marginBottom: phoneScreen ? '32px' : '40px',
    width: '100%',
  };

  return (
    <React.Fragment>
      {virtualVisitProviders?.length ? (
        <Layout.Stack css={marginBottomCss} grow space={0}>
          <HeaderWrapper
            css={{ gap: (isExpandViewAllEnabled && '8px 40px') || 0 }}
          >
            <ResultWrapper>
              <HeadingContainer>
                <Heading css={headerTextStyle} display="h6" offset={2}>
                  {title}
                </Heading>
              </HeadingContainer>
            </ResultWrapper>
          </HeaderWrapper>
          <Carousel
            css={mixedResultCarouselStyle(
              isExpandViewAllEnabled
                ? virtualVisitProviders?.length + 1
                : virtualVisitProviders?.length,
              winWidth,
              false,
              isExpandViewAllEnabled
            )}
            minimal
            nextSlideOnClick={() => makeAllSlidesAriaHidden()}
            noLoop
            prevSlideOnClick={() => makeAllSlidesAriaHidden()}
            slideIndexOnClick={() => makeAllSlidesAriaHidden()}
            slides={slides}
            slidesPerView={slidesPerView}
          />
        </Layout.Stack>
      ) : null}
      {!virtualVisitProviders ? (
        <Layout.Stack css={{ marginBottom: '$md' }} grow space={0}>
          <HeaderWrapper>
            <ResultWrapper>
              <Layout.Group>
                <Heading css={headerTextStyle} display="h6" offset={2}>
                  {title}
                </Heading>
              </Layout.Group>
            </ResultWrapper>
          </HeaderWrapper>
          <NoResultsFoundContainer>
            <Text>{'No Results Found'}</Text>
          </NoResultsFoundContainer>
        </Layout.Stack>
      ) : null}
    </React.Fragment>
  );
};
