/* eslint-disable no-console */
import { config } from '@abyss/web/tools/config';
import get from 'lodash/get';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import {
  Constants,
  CoverageTypesCodes,
  DEFAULT_SEARCH_RADIUS,
  FilterCollectionModel,
  OPTUM_CORRELATION_ID_HEADER,
  ProviderTypes,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getDistanceFilterOption, getFeatureFlag } from '../../common/Utils';
import { isUmrMember } from '../../common/Utils/memberUtils/memberUtils';
import { PortalContext } from '../../context/PortalContext';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { isMissingParameters } from '../../errors/helpers';
import {
  getUpdatedAoeCodesWithEAP,
  getUpdatedAoeCodesWithFilterOptions,
  isHavingMedicalAoeCodeByCoverageType,
  setBehavioralProgramId,
  setBehavioralProgramIdForEapFilter,
} from '../../frontends/ProviderSearch/routes/SearchResults/utils';
import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { CustomPreferredPolicy } from '../../models/Lagoon';
import { ProviderType } from '../../models/ProviderDetails';
import { ProviderSearchRequestParams } from '../../models/ProviderSearch';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useSearchStore } from '../../store/useSearchStore';
import { SearchState } from '../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import {
  getProviderType,
  getProviderResults as parseProviderResults,
  resolveIncludeSpecialityRollupCodes,
} from '../../utils/providerDetails.utils';
import {
  getCurrentMemberData,
  getLob,
  getLocale,
  getMemberHealthCoverageType,
  getOrgTypeCodes,
  getSortByValue,
  getSpecialityRollupCodes,
  isValidPlanVariationCode,
  parseProviderSearchFilters,
} from '../../utils/providerSearch.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageEffDateRange,
  getCoverageTypes,
  getCurrentMember,
  getDependentInfo,
  getIsCustomPreferredPolicy,
  getIsOxfordAndUSP,
  getMemberPlanYear,
  getNetworkIdsForPES,
  getOnlineRetailers,
  getPlanVariationCode,
  getRulesPackageKey,
  getSitusState,
  isPlanVariationCodeRequired,
} from '../../utils/user.utils';
import { useCategoryLLMFlag } from '../useCategoryLLMFlag';
import { useCoverageType } from '../useCoverageType';
import { useCustomQuery } from '../useCustomQuery';
import { useFeatureFlag } from '../useFeatureFlag';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import {
  getBehavioralFacilityPrograms,
  getFilteredBHFacilityProgramKeywordValue,
} from '../useProviderFilterCount/useProviderFilterCount';
import { useTypeaheadPseudoRollup } from '../useTypeaheadPseudoRollup';
import GET_PROVIDER_SEARCH_QUERY from './ProviderSearch.graphql';
import { useSmartChoice } from '../useSmartChoice';

export const memberCatRPK = (
  currentMember,
  uspTierOneFlag,
  uspToggleFlag,
  tierBenefits,
  providerType
) => {
  const membershipCategory = uspToggleFlag
    ? currentMember?.membershipCategory || ''
    : null;
  return {
    membershipCategory,
    rulesPackageKey: getRulesPackageKey(
      currentMember,
      tierBenefits,
      providerType,
      uspTierOneFlag
    ),
  };
};

const getPlanVariationCodeForSearchParams = (variables, planVariationCodeNew) =>
  variables?.planVariationCode || planVariationCodeNew;

let updatedAcceptingNewPatients: boolean | undefined = false;

export const getFirstNumber = (str: string) => str?.split(',')[0];

export const filterSearchResults = (searchResult) => {
  const providerSearchResult = get(searchResult, 'providerSearch', {});

  const providersData = providerSearchResult.providers;

  const practitionersFiltered = providersData?.filter(
    (data) => data?.providerType === ProviderType.PRACTITIONER
  );

  const facilitiesFiltered = providersData?.filter(
    (data) => data?.providerType === ProviderType.ORGANIZATION
  );

  const medicalGroup = providersData?.filter(
    (data) => data?.providerType === ProviderType.MEDICAL_GROUP
  );

  return {
    totalCountPractitioners: providerSearchResult.totalCountPractitioners,
    totalCountOrganizations: providerSearchResult.totalCountOrganizations,
    totalCountMedicalGroup: providerSearchResult.totalCountMedicalGroup,
    providers: practitionersFiltered || [],
    facilities: facilitiesFiltered || [],
    medicalGroup: medicalGroup || [],
    practitionerSearchRadius: providerSearchResult.practitionerSearchRadius,
    organizationSearchRadius: providerSearchResult.organizationSearchRadius,
    medicalGroupSearchRadius: providerSearchResult.medicalGroupSearchRadius,
    networkStatus: providerSearchResult.networkStatus,
    isTieredPlan: providerSearchResult.isTieredPlan,
  };
};

export const getProviderAoeCodes = (params) => {
  let aoeCodes = [];

  const providerQueryType = params.providerQueryTypes?.filter(
    (item) => ProviderTypes[item.providerType] === params.providerType
  )[0]?.queryType;

  if (
    params.searchType === Constants.SEARCH_TYPES.SPECIALTY &&
    providerQueryType === Constants.SEARCH_TYPES.AOE
  ) {
    aoeCodes = params.typeaheadAoeCodes;
  }

  return aoeCodes;
};
export const getBehavioralFacilityProgramsProviderSearch = (params) => {
  let bhfpcodes = '';

  const providerQueryType = params.providerQueryTypes?.filter(
    (item) => ProviderTypes[item.providerType] === params.providerType
  )[0]?.queryType;

  if (
    params.searchType === Constants.SEARCH_TYPES.SPECIALTY &&
    providerQueryType === Constants.SEARCH_TYPES.BHFP
  ) {
    bhfpcodes = params.bhProgramFacilityAgg;
  }

  return bhfpcodes;
};

const getIncludeSpecialityRollupCodes = (
  searchParams,
  includeSpecialityRollupCodesFromParams,
  typeaheadAoeCodes = []
) =>
  searchParams.behavioralFacilityPrograms === '' &&
  searchParams.searchType === Constants.SEARCH_TYPES.SPECIALTY &&
  typeaheadAoeCodes.length <= 0
    ? resolveIncludeSpecialityRollupCodes(
        includeSpecialityRollupCodesFromParams
      )
    : [];
export const getProviderIds = (providerIds) => providerIds || '';
export const getExcludeNatAncForNetworkIds = (excludeNatAncForNetworkIds) =>
  excludeNatAncForNetworkIds !== ''
    ? excludeNatAncForNetworkIds?.replace(/\s/g, '')?.split(',')
    : [];

export const useProviderSearch = (options) => {
  const [filteredResult, setFilteredResult] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [headers, setHeaders] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GET_PROVIDER_SEARCH_QUERY, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'providerSearch',
    onCalled: () => {
      setIsLoading(true);
    },
    /* istanbul ignore next */
    onCompleted: (result) => {
      const data = filterSearchResults(result?.data);
      const headers = {
        correlationId: result.headers[OPTUM_CORRELATION_ID_HEADER],
      };
      if (options?.onCompleted) {
        options.onCompleted({ ...data, headers });
      }
      setFilteredResult(data);
      setIsLoading(false);
      setHeaders(headers);
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const parsedResult = {
    ...queryResult,
    isLoading,
    data: filteredResult,
    headers,
  };

  return [parsedResult, queryAction];
};

export const useProviderResults = (customParams: any, options = {}) => {
  const coverageTypeFromStore = useCoverageType();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const coverageTypeFromParams = urlParams.get('coverageType');

  const [enableSearchResultsV2] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SEARCH_RESULTS_V2,
  ]);

  const [enableAoeMedical] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_AOE_MEDICAL,
  ]);

  const coverageType = enableSearchResultsV2
    ? coverageTypeFromParams || coverageTypeFromStore
    : coverageTypeFromStore;

  const { storeLob, chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      storeLob: state.lob,
      chipValue: state.chipValue,
    }))
  );
  const { portalData } = useContext(PortalContext);
  const { dependentSeqNbr, choosePCP } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
      choosePCP: state.pcpSearchState.choosePCP,
    }))
  );
  const { bhProgramFacilityAgg, preferredSpecialityCode } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      bhProgramFacilityAgg: state.typeaheadSearchStore.bhProgramFacilityAgg,
      preferredSpecialityCode:
        state.typeaheadSearchStore.preferredSpecialityCode,
    }))
  );
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();

  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  const productCode = medicalCoverage?.productCode;
  const marketType = medicalCoverage?.marketType;

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  );
  const useBHNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  );
  const useVisionNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  );
  const useDentalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  );

  const categoryLlmFlag = useCategoryLLMFlag();

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const isFutureTerminationDemo: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.FUTURE_TERMINATION_DEMO
  );

  const enableMockProviderIdsForPreEffective = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MOCK_PROVIDER_IDS_FOR_PRE_EFFECTIVE
  );
  const enableTierOneMockProviderIds = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER_ONE_MOCK_PROVIDER_IDS
  );

  const disablePCPIndAndANP = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_PCP_IND_AND_ANP
  );

  const eapBHProgramFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_EAP_FILTER_USING_BH_PROGRAM
  );

  const allConfigs = useLagoon('config')();
  const terminationProviderIdsDemo = allConfigs?.find(
    (row) => row.key === 'TERMINATION_PROVIDER_IDS_DEMO'
  )?.value;

  const futureStartDateProviderIds = allConfigs?.find(
    (row) => row.key === 'FUTURE_START_PROVIDERIDS'
  )?.value;
  const tierOneMockProviderIds = allConfigs?.find(
    (row) => row.key === 'TIER1_MOCK_PROVIDERIDS'
  )?.value;
  const acoMockProviderIds = allConfigs?.find(
    (row) => row.key === 'ACO_MOCK_PROVIDERIDS'
  )?.value;
  const premiumCareMockProviderIds = allConfigs?.find(
    (row) => row.key === 'PREMIUM_MOCK_PROVIDERIDS'
  )?.value;
  const mnrFutureMockProviderIds = allConfigs?.find(
    (row) => row.key === 'MNR_MOCK_PROVIDERIDS'
  )?.value;
  const minimumProvidersReturn = allConfigs?.find(
    (row) => row.key === 'MIN_PROVIDER_COUNT_OTHER'
  )?.value;
  const excludeNatAncForNetworkIds = allConfigs?.find(
    (row) => row.key === 'EXCLUDE_NAT_ANC_FOR_NETWORK_IDS'
  )?.value;

  const customPreferredPolicies: CustomPreferredPolicy[] = useLagoon(
    'custom-preferred-policies'
  )();
  const rollupCodeMapLagoonData = useLagoon(
    Constants.LAGOON_TABLE.ROLLUPCODE_MAPPING
  )();
  const tierBenefits = useLagoon('tier-benefit')();

  const rollupCodeMap = getRollupCodeMap(rollupCodeMapLagoonData);

  const {
    ddpCode,
    demographics,
    lineOfBusiness: memberLob,
    population,
    policyNumber: policyID,
  } = currentMember || {};
  const dateOfBirth = demographics?.dateOfBirth;
  const gender = demographics?.gender;

  const reciprocityId = getNetworkIdsForPES(
    currentMember,
    coverageType,
    featureFlags
  );
  const isCustomPreferredPolicy: boolean = getIsCustomPreferredPolicy(
    customPreferredPolicies,
    policyID,
    reciprocityId
  );

  const isSmartChoice = useSmartChoice();
  const isPreEffective = useCurrentMemberIsPreEffective();
  const currentMemberEffectiveDate = getCoverageEffDateRange(
    currentMember,
    coverageType
  ).startDate;
  const isPCPLandingPage =
    chipValue === Constants.CHIPS_CATEGORIES.PRIMARY_CARE;

  const coverages = getCoverageTypes(currentMember);
  const variables = {
    lob: getLob(memberLob, storeLob),
    population,
    coverages,
    situsState: getSitusState(currentMember, getLob(memberLob, storeLob)),
    planYear: getMemberPlanYear(isPreEffective, currentMemberEffectiveDate),
    memberEffectiveDate: isPreEffective ? currentMemberEffectiveDate : null,
    planVariationCode: getPlanVariationCode(currentMember, coverageType),
    preferredSpecialityCode,
    claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.claimSystemTypeCode,
    eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.eligibilitySystemTypeCode,
    policyID,
    reciprocityId: getNetworkIdsForPES(
      currentMember,
      customParams?.coverageType,
      featureFlags
    ),
    rollupCodeMap,
    snpType:
      coverageType == ReverseCoverageTypesCodes.MEDICAL
        ? medicalCoverage?.snpType
        : '',
    locale: getLocale(),
    latitude,
    longitude,
    stateCode,
    memberDemographics: {
      gender,
      dateOfBirth,
    },
    coverageType: customParams?.coverageType,
    isExtendedSearchEnabled: customParams?.isExtendedSearchEnabled,
    isFutureTerminationDemo,
    terminationProviderIdsDemo,
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    ddpCode,
    isCustomPreferredPolicy,
    cesProductCode: productCode,
    memberMarketType: marketType,
    enableMockProviderIdsForPreEffective,
    enableTierOneMockProviderIds: enableTierOneMockProviderIds || false,
    futureStartDateProviderIds: getProviderIds(futureStartDateProviderIds),
    tierOneMockProviderIds: getProviderIds(tierOneMockProviderIds),
    acoMockProviderIds: getProviderIds(acoMockProviderIds),
    premiumCareMockProviderIds: getProviderIds(premiumCareMockProviderIds),
    mnrFutureMockProviderIds: getProviderIds(mnrFutureMockProviderIds),
    minimumProvidersReturn: minimumProvidersReturn || undefined,
    excludeNatAncForNetworkIds: getExcludeNatAncForNetworkIds(
      excludeNatAncForNetworkIds
    ),
    pcpIndicator:
      disablePCPIndAndANP && isPCPLandingPage && !choosePCP
        ? null
        : customParams?.pcpIndicator,
  };
  //chip
  // to store all the search variables including default variables and selected filter params
  const [queryParams, setQueryParams] = useState({});

  const [providerResults, getProviders] = useProviderSearch(options);

  const [, setCurrentTypeaheadParamsPractitioner] = useSessionStorage<any>(
    `${Constants.STORAGE_KEYS.SESSION.MIXED_RESULTS_TYPE_AHEAD_PARAMS}_${ProviderTypes.PRACTITIONER}`,
    {}
  );
  const [, setCurrentTypeaheadParamsMedical] = useSessionStorage<any>(
    `${Constants.STORAGE_KEYS.SESSION.MIXED_RESULTS_TYPE_AHEAD_PARAMS}_${ProviderTypes.MEDICAL_GROUP}`,
    {}
  );
  const [, setCurrentTypeaheadParamsOrg] = useSessionStorage<any>(
    `${Constants.STORAGE_KEYS.SESSION.MIXED_RESULTS_TYPE_AHEAD_PARAMS}_${ProviderTypes.ORGANIZATION}`,
    {}
  );

  const getProviderResults = (params) => {
    const planVariationCodeNew = getPlanVariationCode(
      currentMember,
      params?.coverageType
    );
    const { membershipCategory, rulesPackageKey } = memberCatRPK(
      currentMember,
      uspTierOneFlag,
      uspToggleFlag,
      tierBenefits,
      params?.providerType
    );

    const coverageTypeFromParams =
      params?.coverageType || customParams?.coverageType;
    const virtualCare = params?.virtualCare || customParams?.virtualCare;
    const searchRadius = params?.searchRadius || customParams?.searchRadius;
    const pcpIndicator = params?.pcpIndicator || customParams?.pcpIndicator;
    const isExtendedSearchEnabled =
      params?.isExtendedSearchEnabled || customParams?.isExtendedSearchEnabled;
      const isPractitioner= params?.providerType?.toLowerCase() == ProviderTypes.PRACTITIONER;
      const sortByValue =  getSortByValue(isPractitioner && isSmartChoice);

    const planVariationCode = getPlanVariationCodeForSearchParams(
      variables,
      planVariationCodeNew
    );

    const searchParams = {
      ...variables,
      pcpIndicator:
        disablePCPIndAndANP && isPCPLandingPage && !choosePCP
          ? null
          : pcpIndicator,
      isExtendedSearchEnabled,
      coverageType: coverageTypeFromParams,
      reciprocityId: getNetworkIdsForPES(
        currentMember,
        coverageTypeFromParams,
        featureFlags
      ),
      ...params,
      planVariationCode,
      invokeLlmModel: categoryLlmFlag,
      membershipCategory,
      rulesPackageKey,
      sortBy: sortByValue,
      ...(isSmartChoice && isPractitioner && {
        recommendationPreferences: Constants.RECOMMENDATION_PREFERENCES,
        isProviderRecommendationsEnabled: sortByValue === Constants.RECOMMENDED_PROVIDERS_SEARCH_SORT_BY,
      }),
      searchRadius:
        coverageTypeFromParams ===
          ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] && virtualCare === true
          ? -1
          : searchRadius,
      behavioralFacilityPrograms: getBehavioralFacilityProgramsProviderSearch({
        searchType: params?.searchType,
        providerType: params.providerType,
        bhProgramFacilityAgg,
        providerQueryTypes: params.providerQueryTypes,
      }),
    };
    const typeaheadAoeCodes = getProviderAoeCodes(searchParams);
    let filteredTypeaheadAoeCodes = typeaheadAoeCodes;
    if (enableAoeMedical) {
      isHavingMedicalAoeCodeByCoverageType(searchParams, typeaheadAoeCodes);
      filteredTypeaheadAoeCodes = searchParams.aoeCodes;
    }
    const aoeCodes = getUpdatedAoeCodesWithEAP(
      eapBHProgramFlag,
      params.preSelectEapFilter,
      filteredTypeaheadAoeCodes,
      searchParams.conditionAoeCodes,
      params.providerType
    );

    const behavioralProgramId = setBehavioralProgramId(
      eapBHProgramFlag,
      params.preSelectEapFilter,
      params.providerType
    );

    const {
      includeSpecialityRollupCodes: includeSpecialityRollupCodesFromParams,
    } = searchParams;
    const includeSpecialityRollupCodes = getIncludeSpecialityRollupCodes(
      searchParams,
      includeSpecialityRollupCodesFromParams,
      typeaheadAoeCodes
    );

    [
      'conditionAoeCodes',
      'isConditionSearch',
      'typeaheadAoeCodes',
      'enableBHAoeTypeahead',
      'providerQueryTypes',
      'preSelectEapFilter',
      'acceptsEap',
    ].forEach((key) => delete searchParams[key]);

    const isUmrPopulation = !!isUmrMember(portalData?.portalName);
    const validPlanVariationCode = isValidPlanVariationCode(
      searchParams.eligibilitySystemTypeCode,
      searchParams.claimSystemTypeCode,
      searchParams.planVariationCode,
      searchParams.lob,
      searchParams.population,
      isUmrPopulation
    );

    if (JSON.stringify(queryParams) !== JSON.stringify(searchParams)) {
      setQueryParams(searchParams);
      if (!validPlanVariationCode) {
        throw new Error('Plan Variation Code is required');
      }
      const providerResultsVariables = {
        ...searchParams,
        includeSpecialityRollupCodes,
        aoeCodes,
        behavioralProgramId,
      };

      switch (params?.providerType) {
        case ProviderTypes.MEDICAL_GROUP:
          setCurrentTypeaheadParamsMedical(providerResultsVariables);
          break;
        case ProviderTypes.ORGANIZATION:
          setCurrentTypeaheadParamsOrg(providerResultsVariables);
          break;
        default:
          setCurrentTypeaheadParamsPractitioner(providerResultsVariables);
          break;
      }

      const planVariationCodeRequired = isPlanVariationCodeRequired(
        currentMember?.lineOfBusiness,
        currentMember?.population,
        portalData?.portalName
      );

      const requiredParams = {
        coverages,
        reciprocityId,
        policyID,
        latitude,
        longitude,
        coverageType,
        ...(planVariationCodeRequired ? { planVariationCode } : {}),
      };

      if (isMissingParameters('Provider Search', requiredParams)) {
        return;
      }

      getProviders({
        variables: {
          ...searchParams,
          includeSpecialityRollupCodes,
          aoeCodes,
          behavioralProgramId,
        },
      });
    }
  };

  return [providerResults, getProviderResults];
};

const getRollupCodeMap = (rollupCodeMapLagoonData: any) =>
  rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (item.source && item.destination) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});
const validateRadius = (providerSearchRadius, autoSearchRadius) =>
  providerSearchRadius && autoSearchRadius !== providerSearchRadius;

const validateSourceAndDestination = (source, destination) =>
  source && destination;

const getUpdatedFilterState = (
  includeOrgTypeCodes: any,
  excludeOrgTypeCodes: any,
  acceptingNewPatients: boolean | undefined,
  validPlanVariationCode: boolean,
  sortByValue: string
) => {
  const updatedParams: ProviderSearchRequestParams = {
    pageNumber: 1,
    pageSize: 10,
    includeOrgTypeCodes: getOrgTypeCodes(includeOrgTypeCodes),
    excludeOrgTypeCodes: getOrgTypeCodes(excludeOrgTypeCodes),
    searchRadius: DEFAULT_SEARCH_RADIUS,
    sortBy: sortByValue,
  };
  // reset filters
  const defaultFilters: FilterCollectionModel[] = [
    FilterCollectionModel.AUTO_SEARCH_RADIUS,
    FilterCollectionModel.DISTANCE,
  ];

  if (acceptingNewPatients) {
    updatedParams.acceptingNewPatients = true;
    defaultFilters.push(FilterCollectionModel.ACCEPTING_NEW_PATIENTS);
  }

  if (!validPlanVariationCode) {
    throw new Error('Plan Variation Code is required');
  }

  return { updatedParams, defaultFilters };
};

const canGetProviderResults = (
  providerType: string,
  searchParams: any,
  queryParams: any
) =>
  providerType && JSON.stringify(searchParams) !== JSON.stringify(queryParams);

const getRollupCodes = (
  bhFacilityPrograms,
  rollupCodes,
  typeaheadAoeCodes = []
) =>
  bhFacilityPrograms !== '' || typeaheadAoeCodes.length > 0
    ? []
    : [...new Set(rollupCodes)];

export const useFilterProviderResults = (params) => {
  const [urlParams] = useSearchParams();
  const { coverageType, storeLob, chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      storeLob: state.lob,
      chipValue: state.chipValue,
    }))
  );
  const { portalData } = useContext(PortalContext);
  const {
    pcpIndicator = false,
    dependentSeqNbr,
    choosePCP = false,
  } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      pcpIndicator: state.pcpSearchState.pcpIndicator,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
      choosePCP: state.pcpSearchState.choosePCP,
    }))
  );

  const [deeplinkSearchParams] = useSessionStorage<{
    includeSpecialityRollupCodes: string;
  }>(Constants.STORAGE_KEYS.SESSION.PSX_DEEPLINK_PARAMS, {
    includeSpecialityRollupCodes: '',
  });

  const isDeeplinkSearch =
    urlParams.get(Constants.SOURCE) === Constants.PSX_ROUTER;

  const {
    useMockData = false,
    search = '',
    providerType: stateProviderType = '',
    category,
    searchType,
    sectionType = '',
    specialtyCode,
    includeSpecialityRollupCodes,
    aoeCodes: typeaheadAoeCodes,
    acceptingNewPatients,
    keyword = '',
    isUserTextSearch,
    pseudoVerticals = '',
    suggestion,
    bhProgramFacilityAgg,
    providerQueryTypes,
    preferredSpecialityCode,
    searchFromTypeAhead,
    setTypeaheadSearchStore,
  } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      useMockData: state.typeaheadSearchStore.useMockData,
      search: state.typeaheadSearchStore.search,
      providerType: state.typeaheadSearchStore.providerType,
      category: state.typeaheadSearchStore.category,
      searchType: state.typeaheadSearchStore.searchType,
      sectionType: state.typeaheadSearchStore.sectionType,
      specialtyCode: state.typeaheadSearchStore.specialtyCode,
      includeSpecialityRollupCodes:
        state.typeaheadSearchStore.includeSpecialityRollupCodes,
      aoeCodes: state.typeaheadSearchStore.aoeCodes,
      acceptingNewPatients: state.typeaheadSearchStore.acceptingNewPatients,
      keyword: state.typeaheadSearchStore.keyword,
      isUserTextSearch: state.typeaheadSearchStore.isUserTextSearch,
      pseudoVerticals: state.typeaheadSearchStore.pseudoVerticals,
      preferredSpecialityCode:
        state.typeaheadSearchStore.preferredSpecialityCode,
      suggestion: state.typeaheadSearchStore.suggestion,
      bhProgramFacilityAgg: state.typeaheadSearchStore.bhProgramFacilityAgg,
      providerQueryTypes: state.typeaheadSearchStore.providerQueryTypes,
      searchFromTypeAhead: state.typeaheadSearchStore.searchFromTypeAhead,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const {
    includeOrgTypeCodes,
    excludeOrgTypeCodes,
    requestType = '',
    onlineRetailers,
    behavioralFacilityPrograms,
    setSearchStore,
  } = useSearchStore(
    useShallow((state: SearchState) => ({
      includeOrgTypeCodes: state.searchStore.includeOrgTypeCodes,
      excludeOrgTypeCodes: state.searchStore.excludeOrgTypeCodes,
      requestType: state.searchStore.requestType,
      onlineRetailers: state.searchStore.onlineRetailers,
      behavioralFacilityPrograms: state.searchStore.behavioralFacilityPrograms,
      setSearchStore: state.setSearchStore,
    }))
  );
  const [practitionerParams] = useSessionStorage<any>(
    `${Constants.STORAGE_KEYS.SESSION.MIXED_RESULTS_TYPE_AHEAD_PARAMS}_${ProviderTypes.PRACTITIONER}`,
    {}
  );
  const [medicalParams] = useSessionStorage<any>(
    `${Constants.STORAGE_KEYS.SESSION.MIXED_RESULTS_TYPE_AHEAD_PARAMS}_${ProviderTypes.MEDICAL_GROUP}`,
    {}
  );
  const [orgParams] = useSessionStorage<any>(
    `${Constants.STORAGE_KEYS.SESSION.MIXED_RESULTS_TYPE_AHEAD_PARAMS}_${ProviderTypes.ORGANIZATION}`,
    {}
  );
  const { longitude, latitude, stateCode } = useGeoLocationStorage();
  const providerType = getProviderType(stateProviderType, sectionType);
  const {
    pageNumber = 1,
    pageSize = 10,
    selectedFilters = {},
    shouldGetHGData,
    initialPage = false,
  } = params;

  const currentMember = getCurrentMemberData(dependentSeqNbr);
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  const productCode = medicalCoverage?.productCode;
  const marketType = medicalCoverage?.marketType;

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  );
  const useBHNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  );
  const useVisionNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  );
  const useDentalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  );

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const invokeLlmModel = useCategoryLLMFlag();

  const disablePCPIndAndANP = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_PCP_IND_AND_ANP
  );

  const eapBHProgramFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_EAP_FILTER_USING_BH_PROGRAM
  );

  const customPreferredPolicies: CustomPreferredPolicy[] = useLagoon(
    'custom-preferred-policies'
  )();
  const rollupCodeMapLagoonData = useLagoon(
    Constants.LAGOON_TABLE.ROLLUPCODE_MAPPING
  )();
  const tierBenefits = useLagoon('tier-benefit')();

  const rollupCodeMap = rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (validateSourceAndDestination(item.source, item.destination)) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});

  const rollupCodes = useMemo(() => {
    if (isDeeplinkSearch) {
      const includeSpecialityRollupCodesFromDeeplink =
        deeplinkSearchParams?.includeSpecialityRollupCodes;
      setTypeaheadSearchStore({
        includeSpecialityRollupCodes: includeSpecialityRollupCodesFromDeeplink,
        specialtyCode: '',
      });
      return includeSpecialityRollupCodesFromDeeplink.split(',');
    }
    const codes = getSpecialityRollupCodes(
      specialtyCode,
      includeSpecialityRollupCodes,
      category
    );
    return codes.split(',');
  }, [specialtyCode, includeSpecialityRollupCodes, category, isDeeplinkSearch]);

  const {
    ddpCode,
    demographics,
    lineOfBusiness: memberLob,
    population,
    policyNumber: policyID,
  } = currentMember;
  const dateOfBirth = demographics?.dateOfBirth;
  const gender = demographics?.gender;

  const reciprocityId = getNetworkIdsForPES(
    currentMember,
    coverageType,
    featureFlags
  );
  const isCustomPreferredPolicy: boolean = getIsCustomPreferredPolicy(
    customPreferredPolicies,
    policyID,
    reciprocityId
  );

  const { membershipCategory, rulesPackageKey } = memberCatRPK(
    currentMember,
    uspTierOneFlag,
    uspToggleFlag,
    tierBenefits,
    providerType
  );

  const allConfigs = useLagoon('config')();
  const terminationProviderIdsDemo = allConfigs?.find(
    (row) => row.key === 'TERMINATION_PROVIDER_IDS_DEMO'
  )?.value;
  const isFutureTerminationDemo: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.FUTURE_TERMINATION_DEMO
  );
  const enableMockProviderIdsForPreEffective = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MOCK_PROVIDER_IDS_FOR_PRE_EFFECTIVE
  );
  const enableTierOneMockProviderIds = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER_ONE_MOCK_PROVIDER_IDS
  );
  const futureStartDateProviderIds = allConfigs?.find(
    (row) => row.key === 'FUTURE_START_PROVIDERIDS'
  )?.value;
  const tierOneMockProviderIds = allConfigs?.find(
    (row) => row.key === 'TIER1_MOCK_PROVIDERIDS'
  )?.value;
  const acoMockProviderIds = allConfigs?.find(
    (row) => row.key === 'ACO_MOCK_PROVIDERIDS'
  )?.value;
  const premiumCareMockProviderIds = allConfigs?.find(
    (row) => row.key === 'PREMIUM_MOCK_PROVIDERIDS'
  )?.value;
  const mnrFutureMockProviderIds = allConfigs?.find(
    (row) => row.key === 'MNR_MOCK_PROVIDERIDS'
  )?.value;
  const minimumProvidersReturn = allConfigs?.find(
    (row) => row.key === 'MIN_PROVIDER_COUNT_OTHER'
  )?.value;
  const excludeNatAncForNetworkIds = allConfigs?.find(
    (row) => row.key === 'EXCLUDE_NAT_ANC_FOR_NETWORK_IDS'
  )?.value;

  const { isOXMember } = getIsOxfordAndUSP(
    currentMember,
    coverageType,
    featureFlags
  );

  const pseudoVerticalsDetails = useTypeaheadPseudoRollup(
    pseudoVerticals,
    suggestion,
    isOXMember
  );

  const isPseudoRollupSearch = pseudoVerticalsDetails?.isPseudoRollupSearch;
  const isPreEffective = useCurrentMemberIsPreEffective();
  const bhFacilityPrograms = getBehavioralFacilityProgramsProviderSearch({
    searchType,
    providerType,
    bhProgramFacilityAgg,
    providerQueryTypes,
  });

  const typeaheadAoeCodesForEachProvider = getProviderAoeCodes({
    typeaheadAoeCodes,
    providerQueryTypes,
    providerType,
    searchType,
  });
  const currentMemberEffectiveDate = getCoverageEffDateRange(
    currentMember,
    coverageType
  ).startDate;

  const isPCPLandingPage =
    chipValue === Constants.CHIPS_CATEGORIES.PRIMARY_CARE;

  const sectionTypeFromParams = urlParams?.get(Constants.SECTION_TYPE);

  let firstPageVariables;
  switch (providerType) {
    case ProviderTypes.ORGANIZATION:
      firstPageVariables = orgParams;
      break;
    case ProviderTypes.MEDICAL_GROUP:
      firstPageVariables = medicalParams;
      break;
    default:
      firstPageVariables = practitionerParams;
      break;
  }
  const isSmartChoice =  useSmartChoice();
  const sortByValue = getSortByValue(isSmartChoice);
  const coverages = getCoverageTypes(currentMember);
  const planVariationCode = getPlanVariationCode(currentMember, coverageType);
  const variables = {
    lob: getLob(memberLob, storeLob),
    population,
    membershipCategory,
    coverages,
    planYear: getMemberPlanYear(isPreEffective, currentMemberEffectiveDate),
    situsState: getSitusState(currentMember, getLob(memberLob, storeLob)),
    memberEffectiveDate: isPreEffective ? currentMemberEffectiveDate : null,
    planVariationCode,
    claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.claimSystemTypeCode,
    eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.eligibilitySystemTypeCode,
    policyID,
    preferredSpecialityCode,
    reciprocityId: getNetworkIdsForPES(
      currentMember,
      coverageType,
      featureFlags
    ),
    rulesPackageKey,
    rollupCodeMap,
    snpType:
      coverageType == ReverseCoverageTypesCodes.MEDICAL
        ? medicalCoverage?.snpType
        : '',
    locale: getLocale(),
    shouldGetHGData,
    latitude,
    longitude,
    stateCode,
    memberDemographics: {
      gender,
      dateOfBirth,
    },
    coverageType,
    providerType: getProviderType(
      providerType,
      sectionTypeFromParams || sectionType
    ),
    search,
    includeSpecialityRollupCodes: getRollupCodes(
      bhFacilityPrograms,
      rollupCodes,
      typeaheadAoeCodesForEachProvider
    ),
    requestType,
    searchType,
    pcpIndicator:
      disablePCPIndAndANP && isPCPLandingPage && !choosePCP
        ? null
        : pcpIndicator,
    isPCPLandingPage,
    useNewAgeAndGenderRollups: disablePCPIndAndANP,
    invokeLlmModel: isPseudoRollupSearch ? false : invokeLlmModel,
    keyword,
    onlineRetailers: getOnlineRetailers(onlineRetailers),
    isFutureTerminationDemo,
    terminationProviderIdsDemo,
    enableMockProviderIdsForPreEffective,
    enableTierOneMockProviderIds: enableTierOneMockProviderIds || false,
    futureStartDateProviderIds: getProviderIds(futureStartDateProviderIds),
    tierOneMockProviderIds: getProviderIds(tierOneMockProviderIds),
    acoMockProviderIds: getProviderIds(acoMockProviderIds),
    premiumCareMockProviderIds: getProviderIds(premiumCareMockProviderIds),
    mnrFutureMockProviderIds: getProviderIds(mnrFutureMockProviderIds),
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    ddpCode,
    isCustomPreferredPolicy,
    cesProductCode: productCode,
    memberMarketType: marketType,
    behavioralFacilityPrograms: searchFromTypeAhead
      ? bhFacilityPrograms
      : behavioralFacilityPrograms,
    isUserTextSearch,
    minimumProvidersReturn: minimumProvidersReturn || undefined,
    excludeNatAncForNetworkIds: getExcludeNatAncForNetworkIds(
      excludeNatAncForNetworkIds
    ),
    sortBy: sortByValue,
    ...(isSmartChoice && {
      recommendationPreferences: Constants.RECOMMENDATION_PREFERENCES,
      isProviderRecommendationsEnabled: sortByValue === Constants.RECOMMENDED_PROVIDERS_SEARCH_SORT_BY
    }),
  };
  //Filter count
  const {
    searchFilters,
    setSearchFilters,
    setDefaultSearchFilters,
    defaultSearchFilters,
  } = useContext(SearchFilterContext);
  const { AutoSearchRadius: autoSearchRadius } = searchFilters;
  const filterParams = parseProviderSearchFilters(selectedFilters);

  const behavioralFacilityProgramsValue = searchFromTypeAhead
    ? getFilteredBHFacilityProgramKeywordValue(bhFacilityPrograms, filterParams)
    : getBehavioralFacilityPrograms(filterParams, behavioralFacilityPrograms);
  const queryParams = {
    pageNumber,
    pageSize,
    includeOrgTypeCodes: getOrgTypeCodes(includeOrgTypeCodes),
    excludeOrgTypeCodes: getOrgTypeCodes(excludeOrgTypeCodes),
    // remove distance limit for Virtual Care filter for BH providers (US6465032),
    // backend logic will handle -1 for no distance limit search
    searchRadius: filterParams?.searchRadius,
    ...filterParams,
    behavioralFacilityPrograms: behavioralFacilityProgramsValue,
    ...(isSmartChoice && {
      recommendationPreferences: Constants.RECOMMENDATION_PREFERENCES,
      isProviderRecommendationsEnabled: filterParams?.sortBy === Constants.RECOMMENDED_PROVIDERS_SEARCH_SORT_BY,
    }),
  };

  if (!queryParams.searchRadius) {
    if (coverageType === 'B' && filterParams?.virtualCare === true) {
      queryParams.searchRadius = -1;
    } else {
      queryParams.searchRadius = autoSearchRadius;
    }
  }

  const [defaultVariables, setDefaultVariables] = useState(variables);
  const [searchParams, setSearchParams] = useState(filterParams);
  const [practitionersResult, getPractitionersResult] = useProviderSearch({});
  const [resetFilters, setResetFilters] = useState<Boolean>(false);
  const [resetFiltersVariables, setResetFiltersVariables] = useState({});

  const isUmrPopulation = !!isUmrMember(portalData?.portalName);
  const validPlanVariationCode = isValidPlanVariationCode(
    variables.eligibilitySystemTypeCode,
    variables.claimSystemTypeCode,
    variables.planVariationCode,
    variables.lob,
    variables.population,
    isUmrPopulation
  );

  const autoIncrementRadius = allConfigs?.find(
    (row) => row.key === 'AUTO_INCREMENT_SEARCH_RADIUS'
  )?.value;

  const aoeCodes = getUpdatedAoeCodesWithFilterOptions(
    typeaheadAoeCodesForEachProvider,
    filterParams.aoeCodes,
    eapBHProgramFlag
  );

  const behavioralProgramId = setBehavioralProgramIdForEapFilter(
    eapBHProgramFlag,
    selectedFilters.AreaOfExpertise
  );

  const planVariationCodeRequired = isPlanVariationCodeRequired(
    currentMember?.lineOfBusiness,
    currentMember?.population,
    portalData?.portalName
  );

  const requiredParams = {
    coverages,
    reciprocityId,
    policyID,
    latitude,
    longitude,
    coverageType,
    providerType,
    ...(planVariationCodeRequired ? { planVariationCode } : {}),
  };

  useEffect(() => {
    if (isMissingParameters('Provider Search', requiredParams)) {
      return;
    }

    updatedAcceptingNewPatients = acceptingNewPatients;
    updatedAcceptingNewPatients =
      disablePCPIndAndANP && isPCPLandingPage && !choosePCP
        ? undefined
        : acceptingNewPatients;
    const { updatedParams } = getUpdatedFilterState(
      includeOrgTypeCodes,
      excludeOrgTypeCodes,
      updatedAcceptingNewPatients,
      validPlanVariationCode,
      sortByValue
    );

    // auto increment search and reset filters on change of location, current member, etc
    if (
      JSON.stringify(defaultVariables) !== JSON.stringify(variables) &&
      !initialPage
    ) {
      const newVariables = {
        autoIncrementRadius: autoIncrementRadius
          ? autoIncrementRadius.split(',')
          : [],
        ...variables,
        ...updatedParams,
      };

      setResetFiltersVariables(newVariables);

      getPractitionersResult({
        variables: newVariables,
      });
      setSearchParams(updatedParams);
      setDefaultVariables(variables);
      setResetFilters(true);
    }
  }, [JSON.stringify(variables)]);

  useEffect(() => {
    if (
      canGetProviderResults(providerType, searchParams, queryParams) &&
      !initialPage
    ) {
      if (isMissingParameters('Provider Search', requiredParams)) {
        return;
      }

      setSearchParams(queryParams);
      getPractitionersResult({
        variables:
          resetFilters &&
          JSON?.stringify(defaultSearchFilters) ===
            JSON?.stringify(selectedFilters) &&
          pageNumber === 1
            ? resetFiltersVariables
            : initialPage && Object.keys(firstPageVariables).length !== 0
            ? firstPageVariables
            : {
              ...variables,
                ...queryParams,
                ...{ aoeCodes },
                ...{ behavioralProgramId },
              },
      });
    }
  }, [JSON.stringify(queryParams)]);

  const { results, totalResultsCount, providerSearchRadius, isLoading, error } =
    parseProviderResults(sectionType, practitionersResult, useMockData);

  useEffect(() => {
    if (validateRadius(providerSearchRadius, autoSearchRadius)) {
      setSearchFilters({
        ...searchFilters,
        AutoSearchRadius: providerSearchRadius,
        [FilterCollectionModel.DISTANCE]: getDistanceFilterOption(
          Number(providerSearchRadius)
        ),
      });
    }
    if (providerSearchRadius) {
      setDefaultSearchFilters(
        [
          FilterCollectionModel.AUTO_SEARCH_RADIUS,
          FilterCollectionModel.DISTANCE,
          ...(updatedAcceptingNewPatients
            ? [FilterCollectionModel.ACCEPTING_NEW_PATIENTS]
            : []),
        ],
        {
          autoSearchRadius: Number(providerSearchRadius),
        }
      );
    }
  }, [providerSearchRadius]);

  useEffect(() => {
    setSearchStore({ searchRadius: autoSearchRadius });
  }, [autoSearchRadius]);

  return {
    results,
    totalResultsCount,
    searchRadius: autoSearchRadius,
    isLoading,
    isTieredPlan: practitionersResult?.data?.isTieredPlan,
    error,
    headers: practitionersResult?.headers,
  };
};

export const useProviderSummaryResult = ({ providerId, isOHBS }) => {
  const { coverageType, storeLob } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      storeLob: state.lob,
    }))
  );
  const { portalData } = useContext(PortalContext);
  const currentMember = getCurrentMember();
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  const productCode = medicalCoverage?.productCode;
  const marketType = medicalCoverage?.marketType;
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const featureFlags: [{ key: string; active: boolean }] = useLagoon(
    Constants.LAGOON_TABLE.FEATURE_FLAGS
  )();
  const tierBenefits = useLagoon('tier-benefit')();

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const { membershipCategory, rulesPackageKey } = memberCatRPK(
    currentMember,
    uspTierOneFlag,
    uspToggleFlag,
    tierBenefits,
    ''
  );
  const isPreEffective = useCurrentMemberIsPreEffective();
  const currentMemberEffectiveDate = getCoverageEffDateRange(
    currentMember,
    coverageType
  ).startDate;

  const coverages = getCoverageTypes(currentMember);
  const planVariationCode = getPlanVariationCode(currentMember, coverageType);
  const providerType = '';
  const reciprocityId = isOHBS
    ? '100'
    : getNetworkIdsForPES(currentMember, coverageType, featureFlags);
  const policyID = currentMember?.policyNumber;

  const variables = {
    longitude,
    latitude,
    stateCode,
    providerId,
    providerType,
    lob: getLob(currentMember?.lineOfBusiness, storeLob),
    population: currentMember?.population,
    membershipCategory,
    coverages,
    situsState: getSitusState(
      currentMember,
      getLob(currentMember?.lineOfBusiness, storeLob)
    ),
    planYear: getMemberPlanYear(isPreEffective, currentMemberEffectiveDate),
    memberEffectiveDate: isPreEffective ? currentMemberEffectiveDate : null,
    planVariationCode,
    claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.claimSystemTypeCode,
    eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.eligibilitySystemTypeCode,
    policyID: currentMember?.policyNumber,
    reciprocityId,
    rulesPackageKey,
    snpType:
      coverageType == ReverseCoverageTypesCodes.MEDICAL
        ? medicalCoverage?.snpType
        : '',
    coverageType,
    locale: getLocale(),
    ddpCode: currentMember?.ddpCode,
    cesProductCode: productCode,
    memberMarketType: marketType
  };
  //Provider Search
  const isUmrPopulation = !!isUmrMember(portalData?.portalName);
  const [providerResult, getProviderResult] = useProviderSearch({});
  const validPlanVariationCode = isValidPlanVariationCode(
    variables.eligibilitySystemTypeCode,
    variables.claimSystemTypeCode,
    variables.planVariationCode,
    variables.lob,
    variables.population,
    isUmrPopulation
  );

  useEffect(() => {
    const planVariationCodeRequired = isPlanVariationCodeRequired(
      currentMember?.lineOfBusiness,
      currentMember?.population,
      portalData?.portalName
    );

    const requiredParams = {
      coverages,
      reciprocityId,
      policyID,
      latitude,
      longitude,
      coverageType,
      ...(planVariationCodeRequired ? { planVariationCode } : {}),
    };

    if (providerId) {
      if (!validPlanVariationCode) {
        throw new Error('Plan Variation Code is required');
      }

      if (isMissingParameters('Provider Search', requiredParams)) {
        return;
      }
      getProviderResult({
        variables,
      });
    }
  }, [providerId]);

  return providerResult;
};
