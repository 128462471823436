import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Markdown } from '@abyss/web/ui/Markdown';
import { find } from 'lodash';
import React from 'react';

import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useLagoon } from '../../hooks/useLagoon';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import { PremiumCareConstants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import {
  StyledMarkdown,
  disclaimerLinkStyles,
  disclaimerPDFLinkStyles,
} from './DisclaimersContainer.styled';

export const PremiumCareDisclaimer = ({
  showPremiumCareDisclaimer,
  isPDFView,
}) => {
  const locale = getLanguage().code;
  const lagoonData = useLagoon('ui-messaging')();
  const premiumCareDisclaimerContent = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.PREMIUM_CARE_DISCLAIMER,
  });
  const premiumCareData = PremiumCareConstants[locale];
  const navigationURL =
    (locale === 'es'
      ? premiumCareData?.popoverURL
      : premiumCareDisclaimerContent?.url ?? premiumCareData?.popoverURL) ?? '';
  const premiumDisclaimerContent =
    premiumCareDisclaimerContent?.message ??
    premiumCareData?.disclaimerContent ??
    '';
  const premiumDisclaimerLinkText = premiumCareData?.disclaimerLinkText ?? '';

  const suppressPremiumCareDisclaimer = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  if (showPremiumCareDisclaimer && !suppressPremiumCareDisclaimer) {
    return (
      <>
        {isPDFView ? (
          <Markdown
            data-auto-testid="premium-care-disclaimer-pdf-content"
            data-testid="premium-care-disclaimer-pdf-content"
          >
            {premiumDisclaimerContent}
          </Markdown>
        ) : (
          <StyledMarkdown
            data-auto-testid="premium-care-disclaimer-content"
            data-testid="premium-care-disclaimer-content"
          >
            {premiumDisclaimerContent}
          </StyledMarkdown>
        )}
        <LinkWithTracking
          analyticsInfo={{
            location: 'body:premium care disclaimer',
            type: 'download',
          }}
          css={isPDFView ? disclaimerPDFLinkStyles : disclaimerLinkStyles}
          data-auto-testid="premium-care-disclaimer-link-text"
          data-testid="premium-care-disclaimer-link-text"
          href={navigationURL}
          openNewWindow={!isPDFView}
        >
          {premiumDisclaimerLinkText}
          <IconMaterial icon="chevron_right" size="$xs" />
        </LinkWithTracking>
      </>
    );
  }

  return null;
};
