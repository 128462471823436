import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';

import translationsEnJson from '../../public/locale/en/translation.json';
import {
  AUTO_INCREMENT_SEARCH_RADIUS,
  DEFAULT_SEARCH_RADIUS,
  DEFAULT_SORT_BY,
  EAP_OPTION_LABEL,
  FilterCollectionModel,
  FilterKeysAndParamsMapping,
  ProviderTypes,
  ReverseCoverageTypesCodes,
  SORT_BY_RECOMMENDED_PROVIDERS,
  VirtualCareOptions,
  rollUpCodeMapping,
} from '../common/Constants';
import { ConstantsLagoon } from '../common/ConstantsLagoon';
import { getFeatureFlag } from '../common/Utils';
import {
  addDaysToDate,
  dateFormatUTC,
} from '../common/Utils/datesUtils/formatDate';
import { getLanguage } from '../frontends/ProviderSearch/context/Internationalization/helpers';
import { useCoverageType } from '../hooks/useCoverageType';
import { useLagoon } from '../hooks/useLagoon';
import { usePortalShowEAPfilter } from '../hooks/usePortalShowEAPfilter';
import { getProviderAoeCodes } from '../hooks/useProviderSearch/useProviderSearch';
import {
  FiltersData,
  LastModifiedFilter,
  Option,
  ProviderFiltersType,
  ProviderSearchFilters,
  ProviderSearchRequestParams,
} from '../models/ProviderSearch';
import { DateTime } from '../utils/DateTime';
import {
  getBehavioralFacilityProgramsFromLagoon,
  getIntersectionFromArrays,
} from './common';
import { portalShouldShowOnlineScheduling } from './portalConfig.utils';
import {
  getCurrentMember,
  getDependentInfo,
  isMemberEAPEligible,
} from './user.utils';

const specialtyCodes = require('../common/PSXHeader/specialtyCodes.json');

export const getValuesFromOptionsArray = (filter: Option[] = []) => {
  const values: any[] = [];
  filter?.forEach((option: Option) => {
    values.push(option.value);
  });
  return values;
};

export const getValuesAsKeyFromOptionsArray = (filter: Option[] = []) => {
  const values = {};
  filter?.forEach((option: Option) => {
    const key = FilterKeysAndParamsMapping[option.value] as string;
    values[key] = true;
  });
  return values;
};

const getArrayTypeFilterValues = (
  params: ProviderSearchFilters,
  pickFirstValue = false
) => {
  const filterParams = {};
  Object.keys(params).forEach((key) => {
    if (params[key]?.length) {
      const options = getValuesFromOptionsArray(params[key]);
      filterParams[FilterKeysAndParamsMapping[key]] = pickFirstValue
        ? options[0]
        : options;
    }
  });
  return filterParams;
};

export const getDateRangeFromFilterValuesArray = (
  params: ProviderSearchFilters
) => {
  const filterParams = {};
  let filterParamsDateRange = {};
  const startDate: any = new Date(
    DateTime.getCurrentDateTime().getUTCFullYear(),
    DateTime.getCurrentDateTime().getUTCMonth(),
    DateTime.getCurrentDateTime().getUTCDate()
  );

  // Converting from IST to UTC is taking a day back. So, adding a day to the start date.
  if (
    DateTime.getCurrentDateTime()
      .toTimeString()
      .indexOf('India Standard Time') !== -1
  ) {
    startDate.setDate(startDate.getDate() + 1);
  }

  const formattedDocAsapStartDate = dateFormatUTC(startDate, 'yyyy-MM-dd');
  Object.keys(params).forEach((key) => {
    if (params[key]?.length) {
      const options = getValuesFromOptionsArray(params[key]);
      options.sort((a, b) => a - b);
      const farthestDate = options[options.length - 1];
      const endDate = addDaysToDate(startDate, farthestDate - 1);
      filterParams[FilterKeysAndParamsMapping[key]] = dateFormatUTC(
        endDate,
        'yyyy-MM-dd'
      );
      filterParamsDateRange = {
        docAsapStDate: formattedDocAsapStartDate,
        ...filterParams,
      };
    }
  });
  return filterParamsDateRange;
};

const getObjectTypeFilterValues = (params: ProviderSearchFilters) => {
  const filterParams = {};
  Object.keys(params).forEach((key) => {
    if (params[key]?.value)
      filterParams[FilterKeysAndParamsMapping[key]] = params[key].value;
  });
  return filterParams;
};

const getBehavioralFacilityProgramsValue = (options: any[]): string => {
  const result = options
    .map((option: any) => {
      let value = [];

      if (!option || option.length === 0) {
        return value;
      }

      // Remove duplicates
      value = option
        .map((item: any) => item?.value)
        .toString()
        .split(',');
      value = [...new Set(value)];
      return value;
    })
    .filter((item: any) => item.length > 0);

  return result.length > 1
    ? getIntersectionFromArrays(result).join(',')
    : result.join(',');
};

export const getVirtualCareOptionsFilterValues = (
  selectedVirtualCareOption: VirtualCareOptions | undefined
) => {
  switch (selectedVirtualCareOption) {
    case VirtualCareOptions.IN_PERSON_ONLY:
      return {
        virtualOnlyLocationInd: false,
      };
    case VirtualCareOptions.VIRTUAL_VISIT_ONLY:
      return {
        searchRadius: undefined,
        virtualCare: true,
      };
    case VirtualCareOptions.BOTH:
      return {
        virtualCare: true,
        virtualOnlyLocationInd: false,
      };
    default:
      return null;
  }
};

export const parseProviderSearchFilters = (
  selectedFilters: ProviderSearchFilters
): ProviderSearchRequestParams => {
  const {
    AcoName,
    CulturalCompetencyCode,
    AgeRange,
    ExpressAccessProviderIndicator,
    TieredProvider,
    TieredProviders,
    PremiumCarePhysician,
    PlatinumProviderIndicator,
    VirtualCare,
    Distance,
    FacilityType,
    PreferredFacilities,
    Program,
    ProgramCategory,
    SpecialtyCode,
    AreaOfExpertise,
    PatientReviews,
    HealthSuppliesOffered,
    LanguageCode,
    Medical,
    HospitalAffiliations,
    EssentialCommunityProvider,
    AcceptingNewPatients,
    EveningAndWeekend,
    AccessibilityCode,
    GenderCode,
    PatientSafetyInfoAvailable,
    SortBy,
    EthnicityCode,
    RaceCode,
    BehavioralProgramIndicator,
    ServicesOffered,
    AccommodateOffered,
    UpcomingAvailability,
    BoardCertifiedSpecialtyIndicator,
    PreferredProviders,
    VirtualCareOptions,
  } = selectedFilters;
  const CulturalCompetencyCodeStr =
    CulturalCompetencyCode?.map((item) => item.value).join() ?? '';
  const behavioralFacilityPrograms = getBehavioralFacilityProgramsValue([
    AgeRange,
    Program,
    ProgramCategory,
  ]);

  const objectTypeFilterValues = getObjectTypeFilterValues({
    ExpressAccessProviderIndicator,
    TieredProvider,
    PremiumCarePhysician,
    PlatinumProviderIndicator,
    VirtualCare,
    SortBy,
    PreferredProviders,
  });

  const arrayTypeFilterValues = getArrayTypeFilterValues({
    AcoName,
    FacilityType,
    PreferredFacilities,
    SpecialtyCode,
    AreaOfExpertise,
    HealthSuppliesOffered,
    LanguageCode,
    AccessibilityCode,
    Medical,
    HospitalAffiliations,
    EthnicityCode,
    RaceCode,
    BehavioralProgramIndicator,
    ServicesOffered,
  });

  const arrayTypeFilterValuesFromFirstIndex = getArrayTypeFilterValues(
    {
      PatientReviews,
      GenderCode,
      Distance,
      VirtualCare,
      PremiumCarePhysician,
      BoardCertifiedSpecialtyIndicator,
    },
    true
  );
  const arrayTypeFilterDateRange = getDateRangeFromFilterValuesArray({
    UpcomingAvailability,
  });

  const acceptingNewPatientsSelectedValue = getValuesFromOptionsArray(
    AcceptingNewPatients
  )?.includes('Y')
    ? true
    : getValuesFromOptionsArray(AcceptingNewPatients)?.includes('N')
    ? false
    : null;

  const tieredProviders = getValuesAsKeyFromOptionsArray(TieredProviders);

  const selectedVirtualCareOption =
    getValuesFromOptionsArray(VirtualCareOptions)?.[0];
  const virtualCareOptionsFilterValues = getVirtualCareOptionsFilterValues(
    selectedVirtualCareOption
  );

  // @ts-ignore
  return {
    ...objectTypeFilterValues,
    ...arrayTypeFilterValues,
    ...(CulturalCompetencyCode?.length && {
      culturalCompetencyCode: CulturalCompetencyCodeStr,
    }),
    ...arrayTypeFilterValuesFromFirstIndex,
    ...arrayTypeFilterDateRange,
    ...tieredProviders,
    ...virtualCareOptionsFilterValues,
    ...(AccommodateOffered?.length && {
      accommodateOffered: getValuesFromOptionsArray(AccommodateOffered),
    }),
    ...(AcceptingNewPatients?.length && {
      acceptingNewPatients: acceptingNewPatientsSelectedValue,
    }),
    ...(EssentialCommunityProvider?.length && {
      essentialCommunityProvider: getValuesFromOptionsArray(
        EssentialCommunityProvider
      ).includes('Y'),
    }),
    ...(EveningAndWeekend?.length &&
      getValuesFromOptionsArray(EveningAndWeekend).includes('E') && {
        eveningAvailability: true,
      }),
    ...(EveningAndWeekend?.length &&
      getValuesFromOptionsArray(EveningAndWeekend).includes('W') && {
        weekendAvailability: true,
      }),
    ...(PatientSafetyInfoAvailable?.length &&
      getValuesFromOptionsArray(PatientSafetyInfoAvailable).includes('Y') && {
        patientSafetyInfoAvailable: true,
      }),
    behavioralFacilityPrograms,
  };
};

const getEveningAndWeekendFilter = (
  { EveningAvailability, WeekendAvailability }: FiltersData,
  translate,
  suppressVisionWeekendEveningsFilter
) => {
  if (suppressVisionWeekendEveningsFilter) {
    return [];
  }
  const eveningAvailability = getValuesFromOptionsArray(
    EveningAvailability?.options
  ).includes('Y')
    ? [
        {
          label: translate('FILTER_OPTIONS.EveningAvailability.Y'),
          value: 'E',
          dnt_label: translationsEnJson.FILTER_OPTIONS.EveningAvailability.Y,
        },
      ]
    : [];

  const weekendAvailability = getValuesFromOptionsArray(
    WeekendAvailability?.options
  ).includes('Y')
    ? [
        {
          label: translate('FILTER_OPTIONS.WeekendAvailability.Y'),
          value: 'W',
          dnt_label: translationsEnJson.FILTER_OPTIONS.WeekendAvailability.Y,
        },
      ]
    : [];

  return [...eveningAvailability, ...weekendAvailability];
};

const readOptionsFromFilterCollection = () => {
  const showOnlineScheduling = portalShouldShowOnlineScheduling();
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const upcomingAvailability: boolean =
    showOnlineScheduling &&
    getFeatureFlag(
      featureFlags,
      ConstantsLagoon.FEATURE_FLAGS.UPCOMING_AVAILABILITY
    );
  const filterResults: string[] = [
    FilterCollectionModel.AGE_RANGE,
    FilterCollectionModel.PROGRAM,
    FilterCollectionModel.PROGRAM_CATEGORY,
    FilterCollectionModel.DISTANCE,
    FilterCollectionModel.VIRTUAL_CARE,
    FilterCollectionModel.PATIENT_REVIEWS,
    FilterCollectionModel.TIERED_PROVIDERS,
    FilterCollectionModel.BOARD_CERTIFIED_SPECIALITY_INDICATOR,
    FilterCollectionModel.PREMIUM_CARE_PHYSICIAN,
    upcomingAvailability ? FilterCollectionModel.UPCOMING_AVAILABILITY : '',
  ];
  return filterResults;
};

const isVisionWeekendEveningsFilterEnabled = (coverageType) => {
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const visionWeekendEveningsFilterEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VISION_WEEKEND_EVENINGS_FILTER_ENABLED
  );

  return (
    coverageType === ReverseCoverageTypesCodes.VISION &&
    !visionWeekendEveningsFilterEnabled
  );
};

const getModifiedFilterOptions = (lastModifiedFilter) =>
  lastModifiedFilter?.options || [];

const getBehavioralFacilityProgramsOptions = (
  filter,
  filtersData,
  translate,
  selectedFilters: ProviderSearchFilters
) =>
  getBehavioralFacilityProgramsFromLagoon(filter.label)
    .map((item) => ({
      label: translate(item.name),
      dnt_label: item.name,
      value: item.programIds?.replaceAll(' ', ''),
      displayOrder: item.displayOrder,
      isDisabled: !filtersData?.BehavioralFacilityProgram?.options.some(
        (option) => {
          const commonFilterValues = findCommonFilterValues(
            selectedFilters?.ProgramCategory,
            selectedFilters?.AgeRange
          );
          if (
            commonFilterValues &&
            !isFilterSelected(commonFilterValues, option.value) &&
            filter.model == FilterCollectionModel.PROGRAM
          ) {
            return false;
          }

          return item.programIds.includes(option.value);
        }
      ),
    }))
    .sort((a, b) => a.displayOrder - b.displayOrder);

const findCommonFilterValues = (programCategoriesArray, ageRangeArray) => {
  if (!programCategoriesArray && !ageRangeArray) return null;

  if (!ageRangeArray) {
    return programCategoriesArray.flatMap((obj) =>
      obj.value.split(',').map((value) => value.trim())
    );
  }

  if (!programCategoriesArray) {
    return ageRangeArray.flatMap((obj) =>
      obj.value.split(',').map((value) => value.trim())
    );
  }

  const combinedProgramCategoryValues = programCategoriesArray.flatMap((obj) =>
    obj.value.split(',').map((value) => value.trim())
  );
  const combinedAgeRangeValues = ageRangeArray.flatMap((obj) =>
    obj.value.split(',').map((value) => value.trim())
  );

  return combinedProgramCategoryValues.filter((value) =>
    combinedAgeRangeValues.includes(value)
  );
};

const isFilterSelected = (commonFilterValues, optionValue): boolean => {
  if (commonFilterValues != null && commonFilterValues.length > 0) {
    return commonFilterValues.includes(optionValue);
  }

  return true;
};

const isBehavioralFacilityFilterModel = (filterModel: string): boolean =>
  [
    FilterCollectionModel.AGE_RANGE,
    FilterCollectionModel.PROGRAM,
    FilterCollectionModel.PROGRAM_CATEGORY,
  ].some((model) => model === filterModel);

const shouldApplySorting = (filter: any): boolean =>
  filter?.type !== 'radio' && !filter?.isSortingOptionsDisabled;

export const transformFiltersData = (
  translate,
  filtersCollection: ProviderFiltersType[],
  filtersData: FiltersData = {},
  lastModifiedFilter: LastModifiedFilter = {},
  selectedFilters: ProviderSearchFilters
) => {
  const coverageType = useCoverageType();
  const suppressVisionWeekendEveningsFilter =
    isVisionWeekendEveningsFilterEnabled(coverageType);

  const readLabelFromTranslations: string[] = [
    FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
    FilterCollectionModel.GENDER_CODE,
    FilterCollectionModel.ESSENTIAL_COMMUNITY_PROVIDER,
    FilterCollectionModel.BOARD_CERTIFIED_SPECIALITY_INDICATOR,
  ];

  const filters: ProviderFiltersType[] = [];
  filtersCollection.forEach((filter) => {
    let filterOptions: Option[] = [];
    if (!!filter.isDisabled) return;
    if (filter.model === lastModifiedFilter?.model) {
      filterOptions.push(...getModifiedFilterOptions(lastModifiedFilter));
    } else if (filter.model === FilterCollectionModel.EVENING_AND_WEEKEND) {
      const options = getEveningAndWeekendFilter(
        filtersData,
        translate,
        suppressVisionWeekendEveningsFilter
      );
      filterOptions.push(...options);
    } else if (isBehavioralFacilityFilterModel(filter.model)) {
      const options = getBehavioralFacilityProgramsOptions(
        filter,
        filtersData,
        translate,
        selectedFilters
      );
      filterOptions.push(...options);
    } else if (readOptionsFromFilterCollection().includes(filter.model)) {
      filterOptions.push(...filter.options);
    } else {
      if (filtersData[filter.model]?.options) {
        filtersData[filter.model]?.options?.forEach((option: Option) => {
          let label;
          if (readLabelFromTranslations.includes(filter.model)) {
            label = translate(
              `FILTER_OPTIONS.${filter.model}.${option?.value}`
            );
          } else {
            label = option?.label;
          }
          if (label)
            filterOptions.push({
              label,
              value: option?.value,
              dnt_label: option?.dnt_label,
            });
        });
      }
    }

    if (shouldApplySorting(filter)) {
      filterOptions = sortBy(filterOptions, 'label');
    }
    if (filterOptions.length)
      filters.push({ ...filter, options: [...filterOptions] });
  });
  return filters;
};

export const getLagoonConfigValue = (allConfigs, key) => {
  const config = allConfigs(key);
  return config ? config.value : '';
};

export const getDocAsapLagoonKeys = (key: string, portalName: any): string => {
  if (portalName) return `${key}_${portalName}`;
  return key;
};

export const getCurrentMemberData = (dependentSeqNbr) =>
  dependentSeqNbr ? getDependentInfo(dependentSeqNbr) : getCurrentMember();

export const getPlanYear = (member: any, coverageType: string) => {
  const coverageData =
    member?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.filter(
      (covType) => covType?.typeCode?.coverageTypeCode === coverageType
    );

  const startDate = coverageData?.[0]?.coverageEffDateRange?.startDate;
  const planYear = startDate?.split('-')?.[0];

  if (!planYear) {
    return null;
  }

  const parsedYear = parseInt(planYear, 10);

  return isNaN(parsedYear) ? null : parsedYear;
};

export const getLocale = () => getLanguage()?.code || 'en';

export const getLob = (
  memberLob: string | undefined,
  storeLob: string | undefined
) => memberLob || storeLob;

export const getOrgTypeCodes = (includeOrgTypeCodes) =>
  isArray(includeOrgTypeCodes)
    ? includeOrgTypeCodes.length
      ? includeOrgTypeCodes
      : []
    : includeOrgTypeCodes
    ? JSON.parse(includeOrgTypeCodes)
    : [];

export const getSearchRadius = (searchRadius) =>
  searchRadius ? +searchRadius : DEFAULT_SEARCH_RADIUS;

export const getSpecialityRollupCodes = (
  specialtyCode,
  includeSpecialityRollupCodes,
  category
) =>
  (specialtyCode ||
    includeSpecialityRollupCodes ||
    (typeof category === 'string'
      ? specialtyCodes[category?.toUpperCase()]
      : '')) ??
  '';

export const getAutoIncrementRadius = (autoIncrementRadius) =>
  autoIncrementRadius
    ? autoIncrementRadius.split(',')
    : AUTO_INCREMENT_SEARCH_RADIUS;

export const getRollUpCodeCategory = (code) => {
  const rollUpCode = code.substring(0, 2);
  if (code === '52-526-033') return [ProviderTypes.ORGANIZATION];
  return (
    rollUpCodeMapping[rollUpCode] || [
      ProviderTypes.PRACTITIONER,
      ProviderTypes.ORGANIZATION,
      ProviderTypes.MEDICAL_GROUP,
    ]
  );
};

export const getProviderTypeRollUpCodes = (
  selectedCategoryRollUpCodes,
  cleanUpProviderCalls
) => {
  const practitionerRollUpCodes = <string[]>[],
    organizationRollUpCodes = <string[]>[],
    mgRollUpCodes = <string[]>[];
  if (selectedCategoryRollUpCodes && cleanUpProviderCalls) {
    selectedCategoryRollUpCodes.forEach((code) => {
      const rollUpCodeCategories = getRollUpCodeCategory(code);
      rollUpCodeCategories.forEach((rollUpCodeCategory) => {
        switch (rollUpCodeCategory) {
          case ProviderTypes.PRACTITIONER:
            practitionerRollUpCodes.push(code);
            break;
          case ProviderTypes.ORGANIZATION:
            organizationRollUpCodes.push(code);
            break;
          case ProviderTypes.MEDICAL_GROUP:
            mgRollUpCodes.push(code);
            break;
          default:
            practitionerRollUpCodes.push(code);
            organizationRollUpCodes.push(code);
            mgRollUpCodes.push(code);
            break;
        }
      });
    });
  }
  return [practitionerRollUpCodes, organizationRollUpCodes, mgRollUpCodes];
};

export const formProviderTypeIncludeRollUpCode = (
  rollUpCodes,
  allRollUpCodes,
  cleanUpProviderCalls
) => {
  if (cleanUpProviderCalls) return rollUpCodes;
  else return allRollUpCodes;
};

export const getProviderSearchParams = (
  customParams,
  selectedCategoryPayload,
  searchType,
  choosePCP,
  cleanUpProviderCalls,
  providerQueryTypes
) => {
  const params = {
    ...customParams,
    searchType,
    pcpIndicator:
      customParams?.useNewAgeAndGenderRollups &&
      customParams?.isPCPLandingPage &&
      !choosePCP
        ? null
        : choosePCP || selectedCategoryPayload?.pcpIndicator,
    coverageType: choosePCP
      ? customParams?.coverageType
      : selectedCategoryPayload?.coverages?.[0],
    reciprocityId: choosePCP
      ? customParams?.reciprocityId
      : selectedCategoryPayload?.reciprocityId,
    invokeLlmModel: customParams.isUserTextSearch,
    providerName: !customParams.isUserTextSearch
      ? customParams.providerName
      : '',
    providerQueryTypes,
  };

  const selectedCategoryRollUpCodes =
    selectedCategoryPayload?.includeSpecialityRollupCodes || [];

  const [practitionerRollUpCodes, organizationRollUpCodes, mgRollUpCodes] =
    getProviderTypeRollUpCodes(
      selectedCategoryRollUpCodes,
      cleanUpProviderCalls
    );

  const allRollUpCodes = choosePCP
    ? customParams?.includeSpecialityRollupCodes
    : selectedCategoryPayload?.includeSpecialityRollupCodes;

  const practitionerRollUpCodesInPayload = formProviderTypeIncludeRollUpCode(
    practitionerRollUpCodes,
    allRollUpCodes,
    cleanUpProviderCalls
  );

  const practitionerParams = {
    ...params,
    typeaheadAoeCodes: getProviderAoeCodes({
      ...params,
      providerType: ProviderTypes.PRACTITIONER,
    }),
    providerType: ProviderTypes.PRACTITIONER,
    includeSpecialityRollupCodes: practitionerRollUpCodesInPayload,
  };

  const organizationRollUpCodesInPayload = formProviderTypeIncludeRollUpCode(
    organizationRollUpCodes,
    allRollUpCodes,
    cleanUpProviderCalls
  );

  const orgParams = {
    ...params,
    typeaheadAoeCodes: [],
    providerType: ProviderTypes.ORGANIZATION,
    excludeOrgTypeCodes: choosePCP
      ? customParams?.excludeOrgTypeCodes
      : selectedCategoryPayload?.excludeOrgTypeCodes,
    includeOrgTypeCodes: choosePCP
      ? customParams?.includeOrgTypeCodes
      : selectedCategoryPayload?.includeOrgTypeCodes,
    includeSpecialityRollupCodes: organizationRollUpCodesInPayload,
  };

  const medGrpRollUpCodesInPayload = formProviderTypeIncludeRollUpCode(
    mgRollUpCodes,
    allRollUpCodes,
    cleanUpProviderCalls
  );

  const medGrpParams = {
    ...params,
    typeaheadAoeCodes: getProviderAoeCodes({
      ...params,
      providerType: ProviderTypes.MEDICAL_GROUP,
    }),
    providerType: ProviderTypes.MEDICAL_GROUP,
    includeSpecialityRollupCodes: medGrpRollUpCodesInPayload,
  };
  return { practitionerParams, orgParams, medGrpParams };
};

export const getProviderFilterOptionsWithEAPCheck = (filtersData) => {
  const AOEFilters = filtersData[FilterCollectionModel.AREA_OF_EXPERTISE];
  const AOEOptions = AOEFilters?.['options'];
  if (AOEOptions) {
    AOEFilters['options'] = AOEOptions.filter(
      (option) => option.dnt_label !== EAP_OPTION_LABEL
    );
  }
  return filtersData;
};

export const getProviderFilterOptions = (providerFilters, featureFlags) => {
  const portalShowEAPfilter = usePortalShowEAPfilter();
  if (!providerFilters) return {};

  const enableEmployeeAssistanceProgramAcceptedFilter = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS
      .ENABLE_EMPLOYEE_ASSISTANCE_PROGRAM_ACCEPTED_FILTER
  );

  const isMemberNotEAPEligible = !isMemberEAPEligible() && !portalShowEAPfilter;

  if (
    isMemberNotEAPEligible ||
    !enableEmployeeAssistanceProgramAcceptedFilter
  ) {
    // If EAP feature flag is false or member is not eligible, remove EAP option from AOE filter
    providerFilters = getProviderFilterOptionsWithEAPCheck(providerFilters);
  }
  return providerFilters;
};

export const getMemberHealthCoverageType = (eligibility, coverageTypesCode) =>
  eligibility
    ?.flatMap((coverage) => coverage?.memberHealthCoverage?.coverageType)
    .find(
      (coverageType) =>
        coverageType?.typeCode?.coverageTypeCode ===
        ReverseCoverageTypesCodes[coverageTypesCode]
    );

export const isValidPlanVariationCode = (
  eligibilitySystemTypeCode: string,
  claimSystemTypeCode: string,
  planVariationCode: string,
  lob: string | undefined,
  population: string,
  isUmrPopulation: boolean
): boolean =>
  (eligibilitySystemTypeCode === 'CR' && claimSystemTypeCode === 'CR') ||
  (eligibilitySystemTypeCode === 'MR' && claimSystemTypeCode === '02') ||
  lob?.toUpperCase() === 'OBH' ||
  !!planVariationCode ||
  population === 'USP' ||
  isUmrPopulation;

export const getSortByValue = (isSmartChoice: boolean) => isSmartChoice ? SORT_BY_RECOMMENDED_PROVIDERS : DEFAULT_SORT_BY;
