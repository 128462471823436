import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ResultFilter } from '../../../../common/ContentFilters/ResultFilter';
import { Directions } from '../../../../models/RouteDirections';

type Props = {
  setOpenMapview: (a: boolean) => void;
  setMobileRouteView: (a: boolean) => void;
  setRouteEndCoords: (a: [number | null, number | null]) => void;
  setDirections: (a: Directions) => void;
  setSelectedId: Dispatch<SetStateAction<null>>;
  previousLocation?: string;
  setPreviousLocation?: (data: string) => void;
};

export const MobileMapFilter = ({
  setOpenMapview,
  setMobileRouteView,
  setRouteEndCoords,
  setDirections,
  setSelectedId,
  previousLocation,
  setPreviousLocation,
}: Props) => {
  const { t } = useTranslation();
  const handleListButton = () => {
    setDirections({
      userPlaceName: '',
      endPlaceName: '',
      routes: [],
    });
    setSelectedId(null);
    setRouteEndCoords([null, null]);
    setOpenMapview(false);
    setMobileRouteView(false);
  };
  return (
    <Layout.Group>
      <Button
        css={{
          'abyss-button-root': {
            backgroundColor: '$primary2',
            border: 'none',
            boxShadow: '0 2px rgba(25, 25, 26, 0.16)',
          },
        }}
        data-auto-testid="filter-buttons-list"
        data-testid="filter-buttons-list"
        onClick={handleListButton}
        size="42px"
        textColor="$neutralGray5"
        variant="ghost"
      >
        <IconMaterial color="$neutralGray5" icon="list" />
        <Text color="$neutralGray5">{t('CONTENT_FILTERS.LIST')}</Text>
      </Button>
      <ResultFilter
        color="$neutralGray5"
        icon="filter_list"
        name={t('CONTENT_FILTERS.FILTER')}
        previousLocation={previousLocation}
        setPreviousLocation={setPreviousLocation}
        size="42px"
        variant="ghost"
      />
    </Layout.Group>
  );
};
