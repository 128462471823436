import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { ProgressBar } from '@abyss/web/ui/ProgressBar';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../../hooks/useLagoon';
import { useSmartChoice } from '../../../hooks/useSmartChoice';
import { ProviderType } from '../../../models/ProviderDetails';
import { getUiMessageFromLagoon } from '../../../utils/common';
import {
  capitalizeEachWord,
  getNameAttrs,
  parseProviderName,
} from '../../../utils/providerNames.utils';
import {
  RecommendationCriteriaMatch,
  RecommendationScoreMatch,
  breakDownKeys,
  getChipTextColor,
  getChipbackgroundColor,
  getMatchDescription,
} from '../../../utils/smartChoice.utils';
import { Constants } from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { phoneOnly } from '../../ConstantsStyles';
import { ContentWrapper } from '../../ContentWrapper';
import { LinkContainer } from '../../LinkContainer/LinkContainer';
import { getFeatureFlag } from '../../Utils';
import {
  BreakDownContainer,
  BreakDownTitle,
  BreakDownValue,
  BreakDownValueContainer,
  PercentageBox,
  PercentageText,
  RightContainer,
  SmartChoiceCard,
  StyledDrawer,
  smartChoiceLinkStyle,
} from './SmartChoiceBreakDownL1.styled';

export interface Props {
  isOpen: boolean;
  handleCloseDrawer: () => void;
  recommendationDetails: object;
  providerDetails: {
    providerName: string;
    primaryDegrees: string[];
    specialties: string[];
  };
}

export const SmartChoiceBreakDownL1 = ({
  isOpen,
  handleCloseDrawer,
  onBreakDownClick,
  recommendationDetails,
  providerDetails,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const ENABLE_SMART_CHOICE_UPDATED_PROVIDER_INFO: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SMART_CHOICE_UPDATED_PROVIDER_INFO
  );
  const smartChoiceBreakdownText = getUiMessageFromLagoon(
    ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BREAKDOWN
  );
  const scoreCalculatedLabel = getUiMessageFromLagoon(
    ConstantsLagoon.UI_MESSAGING.SCORE_CALCULATED_LABEL
  );

  const nameAttrs = getNameAttrs(providerDetails);

  const recommendationScore = (
    recommendationDetails as { totalRecommendationScore?: number }
  )?.totalRecommendationScore;

  const isSmartChoice = useSmartChoice();
  const renderBreakDownData = (): JSX.Element[] => {
    const breakDownData: JSX.Element[] = [];
    Object.keys(breakDownKeys)
      .filter((item) => {
        if (
          item === 'verification' &&
          !ENABLE_SMART_CHOICE_UPDATED_PROVIDER_INFO
        ) {
          return false;
        }
        return true;
      })
      ?.map((key) => breakDownData.push(renderBreakDownCard(key)));
    return breakDownData;
  };

  const renderBreakDownCard = (key: string): JSX.Element => {
    let match =
      recommendationDetails?.[key]?.scoreDetails?.match ??
      RecommendationScoreMatch.Low;
    if (key === 'quality') {
      match =
        recommendationDetails?.[key]?.scoreDetails?.match ??
        RecommendationCriteriaMatch.None;
    }
    return (
      <Card
        css={{
          marginTop: '0px !important',
          marginBottom: '16px',
          padding: '0px !important',
          cursor: 'pointer',
          border: '0px',
          boxShadow: 'none',
        }}
        data-testid="smart-choice-card"
        key={key}
        onClick={() => onBreakDownClick(key)}
      >
        <BreakDownContainer>
          <Flex alignItems="center" justify="space-between">
            <BreakDownTitle color="$gray8">
              {t(breakDownKeys[key])}:
            </BreakDownTitle>
            <IconMaterial color="black" icon="chevron_right" />
          </Flex>
          <BreakDownValueContainer>
            <BreakDownValue
              css={{
                backgroundColor: getChipbackgroundColor(match, key),
                color: getChipTextColor(match, key),
              }}
            >
              {t(getMatchDescription(match, key))}
            </BreakDownValue>
          </BreakDownValueContainer>
          <ProgressBar
            barColor="$gray3"
            css={{ borderWidth: '0px' }}
            height={'6px'}
            percentage={
              recommendationDetails?.[key]?.scoreDetails?.percentage ?? 0
            }
            width={'94%'}
          />
        </BreakDownContainer>
      </Card>
    );
  };

  const renderSmartChoiceCard = () => (
    <SmartChoiceCard>
      <Flex
        alignItems={'center'}
        css={{
          flexWrap: 'nowrap',
        }}
      >
        <PercentageBox>
          <PercentageText color="$white">{recommendationScore}%</PercentageText>
        </PercentageBox>

        <RightContainer>
          <Text
            className="smart-choice-text"
            css={{
              'abyss-text-root': {
                fontSize: '20px',
                lineHeight: '24px',
              },
            }}
          >
            {parseProviderName(
              providerDetails?.providerName,
              ProviderType.PRACTITIONER,
              providerDetails?.primaryDegrees?.[0],
              nameAttrs
            )}
          </Text>

          <Text className="desc-text-l1" color="$gray7">
            {capitalizeEachWord(providerDetails?.specialties?.[0])}
          </Text>
        </RightContainer>
      </Flex>
    </SmartChoiceCard>
  );

  return (
    <React.Fragment>
      <StyledDrawer
        data-auto-testid="smart-choice-preferences-drawer"
        data-testid="smart-choice-preferences-drawer"
        isOpen={isOpen}
        onClose={handleCloseDrawer}
        size={572}
        title={
          <Heading
            color="$primary"
            css={{
              fontSize: '32.43px !important',
              lineHeight: '40px !important',
            }}
            data-testid="smart-choice-preferences-heading"
            offset={5}
          >
            {smartChoiceBreakdownText}
          </Heading>
        }
      >
        <ContentWrapper
          style={{
            padding: mobileScreen ? '0px' : '14px',
            paddingTop: '24px',
            paddingBottom: '40px',
            '& form': { marginLeft: '-16px', marginRight: '-16px' },
          }}
        >
          {isSmartChoice && (
            <Flex css={{ marginBottom: '10px' }} justify="flex-end">
              <LinkContainer
                before={
                  <IconSymbol
                    color="$primary1"
                    icon="settings"
                    size={18}
                    variant="outlined"
                  />
                }
                css={{ ...smartChoiceLinkStyle(mobileScreen) }}
                data-auto-testid="smart-choice-button"
                data-testid="smart-choice-button"
                icon="circle"
                iconSize="12"
                onClick={() => {}}
                size={14}
                text={!mobileScreen ? t('SMART_CHOICE.SMART_CHOICE') : null}
              />
            </Flex>
          )}
          {renderSmartChoiceCard()}
          <div
            style={{
              marginTop: mobileScreen ? '8px' : '32px',
              marginBottom: '8px',
            }}
          >
            <Text
              css={{
                'abyss-text-root': {
                  fontSize: '16px',
                  fontWeight: 500,
                  fontFamily: '$primary',
                  lineHeight: '20px',
                  color: '$gray7',
                  '@screen < $md': {
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '16px',
                  },
                },
              }}
            >
              {scoreCalculatedLabel}:
            </Text>
          </div>
          {renderBreakDownData()}
        </ContentWrapper>
      </StyledDrawer>
    </React.Fragment>
  );
};
