import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import React from 'react';

import { FacilityDetails } from '../../models/FacilityDetails';
import { ProviderDetails, ProviderType } from '../../models/ProviderDetails';
import { capitalizeFirstLetter } from '../Utils/capitalizeFirstLetter';
import { truncateLargeStrings } from '../Utils/truncateLargeStrings';
import {
  MobileViewHeadingContent,
  MobileViewHeadingInnerContainer,
  MobileViewHeadingNavigationWrap,
  PopoverProviderName,
  ProfilePicture,
  ProviderProfileInfo,
  ProviderSubTitle,
  ProviderTitle,
} from './MapViewLocationsWrap.style';

type Props = {
  providerDetails: ProviderDetails | FacilityDetails;
  mobileScreen: boolean;
  handleGoBack: (a: boolean) => void;
};

export const MobileViewHeadingNavigation = ({
  providerDetails,
  mobileScreen,
  handleGoBack,
}: Props) => {
  /* TODO This is going to be a Heading navigation to go back to Locations datils tab
          Design is supposed to have double check this...
          if this the experience they want we have to refactor more to get provider details,
          or pass the whole map view from locations tab to a separate route page
  */

  const { imageLocation, providerName, providerType, providerLocations } =
    providerDetails;

  return mobileScreen ? (
    <MobileViewHeadingNavigationWrap
      data-auto-testid="map-mobile-view-heading-navigation"
      data-testid="map-mobile-view-heading-navigation"
    >
      <MobileViewHeadingInnerContainer>
        <div>
          <Link
            data-auto-testid="back-to-locations-details"
            data-testid="back-to-locations-details"
            href="#"
            isStandardAnchor
            onClick={handleGoBack}
          >
            <IconMaterial
              color="$white"
              css={{
                width: '24px',
                height: '24px',
              }}
              icon="arrow_back"
              title="Back"
            />
          </Link>
        </div>
        <MobileViewHeadingContent>
          <ProfilePicture>
            <img alt="profile" src={imageLocation || ''} />
          </ProfilePicture>
          <ProviderProfileInfo>
            <PopoverProviderName
              aligin="start"
              content={providerName}
              position="top"
              positionOffset={-10}
            >
              <ProviderTitle>
                {truncateLargeStrings(providerName, 17)}
              </ProviderTitle>
            </PopoverProviderName>

            {providerLocations?.[0]?.speciality?.[0] &&
            providerType === ProviderType.PRACTITIONER ? (
              <ProviderSubTitle>
                {capitalizeFirstLetter(
                  providerLocations[0].speciality[0].toLowerCase()
                )}
              </ProviderSubTitle>
            ) : null}

            {providerDetails?.organizationType?.[0] &&
            providerType === ProviderType.ORGANIZATION ? (
              <ProviderSubTitle>
                {capitalizeFirstLetter(
                  providerDetails.organizationType[0].toLowerCase()
                )}
              </ProviderSubTitle>
            ) : null}
          </ProviderProfileInfo>
        </MobileViewHeadingContent>
      </MobileViewHeadingInnerContainer>
    </MobileViewHeadingNavigationWrap>
  ) : null;
};
