import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import React from 'react';

import {
  MatchStyledText,
  StyledFlexWithGapAndNoWrap,
} from '../../SmartChoiceBreakDown.styles';
import { StyledLinkLocations } from '../BenfitsBreakDown.styles';

interface MatchTextWithTierLocationsProps {
  text: string;
  StartIcon?: React.ReactNode;
  EndIcon?: React.ReactNode;
}
export const MatchTextWithTierLocations = ({
  text,
  StartIcon,
}: MatchTextWithTierLocationsProps) => (
  <StyledFlexWithGapAndNoWrap
    alignItems="flex-start"
    data-testid="tier1-with-location-match-and-tier1"
    direction="row"
  >
    <StyledFlexWithGapAndNoWrap>
      <IconSymbol color="#007000" icon="done" />
      {StartIcon && <React.Fragment>{StartIcon}</React.Fragment>}
    </StyledFlexWithGapAndNoWrap>

    <StyledFlexWithGapAndNoWrap alignItems="flex-start" direction="column">
      <MatchStyledText>{text}</MatchStyledText>
      <StyledLinkLocations
        data-testid="locations-link"
        href="/locations"
      >{`Locations > `}</StyledLinkLocations>
    </StyledFlexWithGapAndNoWrap>
  </StyledFlexWithGapAndNoWrap>
);
