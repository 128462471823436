import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Pagination } from '@abyss/web/ui/Pagination';

export const SidePaneCloseButtonWrap = styled('div', {
  position: 'absolute',
  '@screen < $sm': {
    display: 'none',
  },
});

export const SidePaneCloseButtonStyle = styled(Flex, {
  top: '$lg',
  position: 'absolute',
  cursor: 'pointer',
  zIndex: 1,
  height: '42px',
  width: '26.17px',
  backgroundColor: 'white',
  boxShadow: '0 2px 6px rgba(25, 25, 26, 0.16)',
  border: '1px solid $gray3',
  borderRadius: '0 4px 4px 0',
});

export const PopupStyle = styled({
  background: 'transparent !important',
});

export const MapDataPagination = styled(Pagination, {
  'abyss-pagination-root': {
    margin: '10px auto 20px',
    '@screen < $md': {
      visibility: 'hidden',
      display: 'inline-block',
    },
  },
});

export const ListWrapColumn = styled('div', {
  dynamic: ({
    cssProps: { isOpenCompareShare, enableMapViewEnhancements },
  }) => ({
    height: enableMapViewEnhancements
      ? isOpenCompareShare
        ? null
        : null
      : isOpenCompareShare
      ? 'calc(100vh - 220px)'
      : 'calc(100vh - 187px)',
  }),
  display: 'inline-block',
  boxShadow: '#xs 0px $xs rgba(0, 0, 0, 0.2)',
  backgroundColor: '$primary2',
  zIndex: 2,
  '@screen < $md': {
    position: 'fixed',
    width: '100%',
    background: 'transparent',
    bottom: '$lg',
    height: 'auto !important',
    '.side-btn': { display: 'none' },
  },
  flexShrink: 1,
});

export const ListWrap = styled('div', {
  display: 'flex',
  width: 432,
  paddingLeft: '5px',
  paddingTop: '5px',
  paddingRight: '$md',
  height: '100%',
  gap: '$md',
  flexWrap: 'nowrap',
  flexDirection: 'column',
  '@screen >= $sm': {
    overflowY: 'auto',
  },
  '.abyss-pagination-results-text': {
    marginLeft: 0,
    '@screen < $md': {
      display: 'none',
    },
  },
  '@screen < $sm': {
    width: '100%',
    background: 'transparent',
    display: 'flex',
    gap: '$md',
    padding: '0px',
    flexDirection: 'column',
    '.abyss-card-root': {
      display: 'inline-flex',
      width: '320px',
    },
  },
});

export const MapViewWrap = styled('div', {
  dynamic: ({
    cssProps: {
      urlCheck,
      enableMapViewEnhancements,
      choosePCP,
      enableMapFilterSortCTA,
    },
  }) => ({
    position: enableMapViewEnhancements && 'sticky',
    top:
      enableMapViewEnhancements &&
      (enableMapFilterSortCTA
        ? urlCheck
          ? '163px'
          : '123px'
        : choosePCP
        ? urlCheck
          ? '229px'
          : '189px'
        : urlCheck
        ? '180px'
        : '140px'),
    bottom: enableMapViewEnhancements && '0',
    height: enableMapViewEnhancements ? '100%' : 'calc(100vh - 187px)',
  }),
  width: '100%',
});

export const PaginationControl = styled('div', {
  display: 'flex',
  '.abyss-pagination-page-size-root': {
    marginRight: '10px',
  },
  '@screen < $md': {
    display: 'none',
  },
});

export const LoadingContainer = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
});

export const ZoomButton = styled(Flex, {
  background: '$primary2',
  width: '48px',
  height: '88px',
  borderRadius: '100px',
  gap: '$md',
  padding: '3px',
});

export const PagingationWrapStyled = styled('div', {
  display: 'flex',
  gap: '16px',
  position: 'sticky',
  paddingBottom: '16px',
});

export const EAPStyle = styled('div', {
  paddingBottom: '32px',
  '@media (max-width: 1268px)': {
    width: '98%',
  },
  '@screen < $xl': {
    width: '94%',
  },
});

export const MapviewWrapper = styled(Flex, {
  dynamic: ({
    cssProps: {
      enableMapViewEnhancements,
      isOpenCompareShare,
      openMapview,
      navigateToDirections,
    },
  }) => ({
    position: 'relative',
    height: enableMapViewEnhancements
      ? isOpenCompareShare
        ? null
        : null
      : isOpenCompareShare
      ? 'calc(100vh - 5px)'
      : 'calc(100vh - 20px)',
    display: openMapview || navigateToDirections ? 'flex' : 'none',
  }),
  flexWrap: 'nowrap',
  '@screen < $sm': { height: '100vh' },
  '.abyss-layout-stack': {
    alignItems: 'flex-start',
  },
});

export const EnhanceStackWrapper = styled(Layout.Stack, {
  dynamic: ({
    cssProps: {
      urlCheck,
      enableMapViewEnhancements,
      choosePCP,
      enableMapFilterSortCTA,
    },
  }) => ({
    maxWidth: enableMapViewEnhancements && '450px',
    width: enableMapViewEnhancements && 'auto',
    '.abyss-c-PJLV-ihNPJpp-css': {
      '&:first-child': {
        position: enableMapViewEnhancements && 'sticky',
        top:
          enableMapViewEnhancements &&
          (enableMapFilterSortCTA
            ? urlCheck
              ? '163px'
              : '123px'
            : choosePCP
            ? urlCheck
              ? '229px'
              : '189px'
            : urlCheck
            ? '180px'
            : '140px'),
        zIndex: enableMapViewEnhancements && '2',
        backgroundColor: enableMapViewEnhancements && 'white',
      },
    },
  }),
});

export const EnhancePaginationStyles = {
  position: 'sticky',
  bottom: '0px',
  zIndex: '1',
  backgroundColor: 'white',
};
