/* eslint-disable no-param-reassign */

import { useForm } from '@abyss/web/hooks/useForm';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Flex } from '@abyss/web/ui/Flex';
import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { ConstantsLagoon } from '../../../../../../../common/ConstantsLagoon';
import { useCoverageType } from '../../../../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../../../../hooks/useFeatureFlag';
import { StoreKeys } from '../../../../../../../hooks/useStore/state';
import { useStore } from '../../../../../../../hooks/useStore/useStore';
import {
  returnSuppressFlag,
  suppressCostEstimatesForPolicies,
} from '../../../../../../../utils/featureSuppress';
import { getCurrentMember } from '../../../../../../../utils/user.utils';
import {
  editCompareButtonsApplyCancelStyle,
  editCompareCheckboxStyle,
  editCompareFloatingSectionStyle,
} from '../../utility/compareDrawer.styled';
import {
  CompareCheckboxInfo,
  MAX_NUM_OF_ATTRIBUTES,
} from '../../utility/compareDrawerConstants';
import {
  getCheckboxLabel,
  getInitialCheckedCount,
  itemToShow,
} from '../../utility/editCompareDrawer.util';
import { getDefaultCheckedCompareAttributes } from '../../utility/getDefaultCheckedCompareAttributes';
import { editCompareButtonRules } from './EditCompareButtonRules';

type Props = {
  items: CompareCheckboxInfo[];
  setChangedTempItems: Function;
  isEditCompareOpen: boolean;
  setOpenEdit: (a: boolean) => void;
  nameOfButtonClickedLast: string;
  setNameOfButtonClickedLast: Function;
  deselectAllButtonRef: React.RefObject<HTMLButtonElement>;
  caretRef: React.RefObject<HTMLButtonElement>;
};

const DrawerCloseButtonStyled = styled(Button, {
  variants: {
    view: {
      mobile: {
        '&.abyss-button-root': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
});

export const EditCompareDrawer = ({
  items,
  setChangedTempItems,
  isEditCompareOpen,
  setOpenEdit,
  nameOfButtonClickedLast,
  setNameOfButtonClickedLast,
  deselectAllButtonRef,
  caretRef,
}: Props) => {
  const { t } = useTranslation();
  const [initialCheckCount, setInitialCount] = useState<number>(0);

  const itemsToShowInCheckboxes = useMemo(() => {
    const ItemsSorted = items.sort((a, b) =>
      t(a.label).localeCompare(t(b.label))
    );
    return cloneDeep(ItemsSorted);
  }, [JSON.stringify(items)]);

  const [checkBoxStateChanged, setCheckBoxStateChanged] = useState(false);
  const numChecked = useRef<number>(0);

  const [tierOneFilterCompareFeatureFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.TIER_ONE_FILTER_COMPARE,
  ]);
  const isTier1Plan = useStore(StoreKeys.TIER1_PLAN_FLAG);
  // If the feature_flag TIER_ONE_FILTER_COMPARE is ON, then suppress Tier1
  // identifier based on 'isTier1Plan' field from snackCard api response
  const showTierOnePlan = tierOneFilterCompareFeatureFlag ? isTier1Plan : true;

  const currentMember = getCurrentMember();
  const showTier2Badge = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER_TWO_PROVIDER,
  ]);

  const showTier2Plan = currentMember?.isIFPTieredPlan && showTier2Badge;

  const setNumChecked = (count) => {
    numChecked.current = count;
  };

  const handleAdobeLinkTrackEditComparision = (buttonName: string) => {
    adobeLinkTrackEvent({
      name: buttonName,
      location: `modal:edit comparison`,
      type: buttonName.includes('check') ? 'checkbox' : 'internal',
    });
  };

  const [costEnableFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED,
  ]);
  const coverageType = useCoverageType();

  const suppressCostEstimates = suppressCostEstimatesForPolicies(
    coverageType,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES_EMPIRE
  );
  const suppressPremiumCare: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const suppressPatientReviews: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const costOption = costEnableFlag && !suppressCostEstimates;

  const getApplyButtonState = (useCompareCheckboxes) =>
    !useCompareCheckboxes.some(
      (checkbox) => checkbox.name !== 'inNetwork' && checkbox.isChecked === true
    );

  useEffect(() => {
    setChangedTempItems(itemsToShowInCheckboxes);
  }, [checkBoxStateChanged]);

  const defaultAttributes = getDefaultCheckedCompareAttributes(
    coverageType,
    suppressPremiumCare,
    suppressPatientReviews
  );
  const form = useForm({
    defaultValues: { 'checkbox-form': defaultAttributes },
    values: {
      'checkbox-form': itemsToShowInCheckboxes
        .filter(
          (checkbox) =>
            itemToShow(
              checkbox.name,
              costOption,
              showTierOnePlan,
              showTier2Plan
            ) && checkbox.isChecked
        )
        .map((checkbox) => checkbox.name),
    },
  });

  const onSubmit = (data) => {
    if (isEditCompareOpen) {
      const changedTempItems = cloneDeep(itemsToShowInCheckboxes);
      const formData = data['checkbox-form'];
      changedTempItems.forEach((checkbox, index) => {
        const tempCheckBox = checkbox;
        tempCheckBox.isChecked = formData?.includes(checkbox.name) || false;
        changedTempItems[index] = tempCheckBox;
      });
      if (changedTempItems?.length > 0) setChangedTempItems(changedTempItems);
    }
  };
  const closeEditCompare = () => {
    handleAdobeLinkTrackEditComparision('close button');
    setNameOfButtonClickedLast('cancel');
    numChecked.current = initialCheckCount;
  };

  const cancelEditCompare = () => {
    handleAdobeLinkTrackEditComparision('cancel button');
    setNameOfButtonClickedLast('cancel');
    numChecked.current = initialCheckCount;

    if (caretRef?.current) {
      caretRef?.current.focus();
    }
  };

  useEffect(() => {
    editCompareButtonRules(
      items,
      setChangedTempItems,
      setOpenEdit,
      setNumChecked,
      nameOfButtonClickedLast,
      setNameOfButtonClickedLast,
      itemsToShowInCheckboxes,
      form,
      defaultAttributes
    );
  }, [nameOfButtonClickedLast]);

  const handleCheckBoxClick = (checkbox: CompareCheckboxInfo) => {
    handleAdobeLinkTrackEditComparision(
      `${checkbox.label}${checkbox.isChecked ? ':unchecked' : ':checked'}`
    );
    checkbox.isChecked = !checkbox.isChecked;
    setCheckBoxStateChanged(!checkBoxStateChanged);
    const newNumChecked = getInitialCheckedCount(
      costOption,
      showTierOnePlan,
      showTier2Plan,
      itemsToShowInCheckboxes
    );

    setNumChecked(newNumChecked);
  };

  useEffect(() => {
    const newNumChecked = getInitialCheckedCount(
      costOption,
      showTierOnePlan,
      showTier2Plan,
      itemsToShowInCheckboxes
    );

    setInitialCount(newNumChecked);
    setNumChecked(newNumChecked);
  }, [costOption, showTierOnePlan, showTier2Plan, itemsToShowInCheckboxes]);

  return isEditCompareOpen ? (
    <FloatingSection css={editCompareFloatingSectionStyle}>
      <FormProvider
        data-auto-testid="compare-providers-edit-compare-apply-edits-button"
        data-testid="compare-providers-edit-compare-apply-edits-button"
        onSubmit={onSubmit}
        state={form}
      >
        <Flex justify="space-between">
          <Layout.Group>
            <Heading color="$gray7" offset={5}>
              {t('Edit comparison')}
            </Heading>{' '}
            <Button
              data-auto-testid="compare-providers-edit-compare-deselect-all"
              data-testid="compare-providers-edit-compare-deselect-all"
              onClick={() => {
                handleAdobeLinkTrackEditComparision('deselect all button');
                setNameOfButtonClickedLast('deselect-all');
              }}
              ref={deselectAllButtonRef}
              variant="tertiary"
            >
              {t('Deselect all')}
            </Button>
            <Button
              css={{ paddingLeft: '0px' }}
              data-auto-testid="compare-providers-edit-compare-reset"
              data-testid="compare-providers-edit-compare-reset"
              onClick={() => {
                handleAdobeLinkTrackEditComparision('reset button');
                setNameOfButtonClickedLast('reset');
              }}
              variant="tertiary"
            >
              {t('Reset')}
            </Button>
          </Layout.Group>
          <Layout.Group>
            <DrawerCloseButtonStyled
              aria-label="Close"
              data-auto-testid="compare-providers-edit-compare-close-icon"
              data-testid="compare-providers-edit-compare-close-icon"
              onClick={closeEditCompare}
              variant="tertiary"
            >
              <IconMaterial
                data-auto-testid="compare-providers-edit-compare-close-icon"
                data-testid="compare-providers-edit-compare-close-icon"
                icon="close"
                onClick={closeEditCompare}
              />
            </DrawerCloseButtonStyled>
          </Layout.Group>
        </Flex>
        <Layout.Stack alignItems="left" space={16}>
          <CheckboxGroup display="row" model="checkbox-form">
            {itemsToShowInCheckboxes
              .filter((checkbox) =>
                itemToShow(
                  checkbox.name,
                  costOption,
                  showTierOnePlan,
                  showTier2Plan
                )
              )
              .map((checkbox) => (
                <Checkbox
                  css={editCompareCheckboxStyle}
                  data-auto-testid={`compare-providers-edit-compare-checkbox-${checkbox.name}`}
                  data-testid={`compare-providers-edit-compare-checkbox-${checkbox.name}`}
                  isChecked={checkbox.isChecked}
                  isDisabled={
                    numChecked.current >= MAX_NUM_OF_ATTRIBUTES &&
                    !checkbox?.isChecked
                  }
                  key={`CheckBoxKey-${checkbox.name}`}
                  label={getCheckboxLabel(t(checkbox.label))}
                  onChange={() => handleCheckBoxClick(checkbox)}
                  value={checkbox.name}
                />
              ))}
          </CheckboxGroup>
          <Layout.Group>
            <Button
              css={editCompareButtonsApplyCancelStyle}
              data-auto-testid="compare-providers-apply"
              data-testid="compare-providers-apply"
              isDisabled={getApplyButtonState(itemsToShowInCheckboxes)}
              onClick={() => {
                handleAdobeLinkTrackEditComparision('apply button');
                setNameOfButtonClickedLast('apply');

                if (caretRef?.current) {
                  caretRef?.current.focus();
                }
              }}
              onSubmit={onSubmit}
              type="submit"
            >
              {t('Apply')}
            </Button>
            <Button
              css={editCompareButtonsApplyCancelStyle}
              data-auto-testid="compare-providers-cancel"
              data-testid="compare-providers-cancel"
              onClick={cancelEditCompare}
              variant="outline"
            >
              {t('CANCEL')}
            </Button>
          </Layout.Group>
        </Layout.Stack>
      </FormProvider>
    </FloatingSection>
  ) : null;
};
