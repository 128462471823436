import { styled } from '@abyss/web/tools/styled';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Modal } from '@abyss/web/ui/Modal';
import find from 'lodash/find';
import React from 'react';

import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { useLagoon } from '../../hooks/useLagoon';

export const ModalContent = styled(Markdown, {
  fontSize: '14px',
  lineHeight: '18px',
  color: '#323334',
});

export const ModalContainer = styled(Modal, {
  '.abyss-modal-content-container': {
    width: 'auto',
    height: 'auto',
    maxWidth: '572px',
    maxHeight: '797px',
  },
  '.abyss-modal-content-title': {
    lineHeight: '40px',
    fontSize: '32.43px',
    fontWeight: '600',
    '@screen < $sm': {
      lineHeight: '36px',
      fontSize: '28.83px',
      fontWeight: '600',
    },
  },
});

export const CulturalCompetencyTraining = ({ isOpen, setIsOpen }) => {
  const data = useLagoon('ui-messaging')();
  const culturalCompetencyModalContent = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.CULTURAL_COMPETENCY_TOOLTIP_INFO_ICON,
  });

  return (
    <React.Fragment>
      <ModalContainer
        data-testid="cultural-competency-modal"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title={culturalCompetencyModalContent?.title}
      >
        <Modal.Section>
          <ModalContent>{culturalCompetencyModalContent?.message}</ModalContent>
        </Modal.Section>
      </ModalContainer>
    </React.Fragment>
  );
};
