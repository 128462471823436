import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useNotAvailableUnknownText } from '../../../../hooks/useNotAvailableUnknownText';
import { ProviderLocation } from '../../../../models/ProviderDetails';
import {
  eveningAndWeekendSubtext,
  getInNetworkStartDate,
  getPracticeLimitations,
  getServicesText,
  getYesNoText,
} from '../../../../utils/providerDetails.utils';
import { InfoProviderContent } from '../InfoProviderContent';
import { InfoProviderContentContainer } from '../ProviderLocationsDetails.style';
import { EmailAndWebsiteInfo } from './EmailAndWebsiteInfo';
import { ListItem } from './ListItem';

export type Props = {
  selectedLocation: ProviderLocation;
};

export const DentalCareAllOtherInfosContainer = ({
  selectedLocation,
}: Props) => {
  const { t } = useTranslation();

  const practiceLimitation = getPracticeLimitations(
    selectedLocation?.practiceLimitations?.age,
    selectedLocation?.practiceLimitations?.gender
  );
  const inNetworkStartDate = getInNetworkStartDate(
    selectedLocation?.network?.start
  );
  const { providerLocations: notAvailableOrUnknownText } =
    useNotAvailableUnknownText();

  const [isLocationSpecialtiesEnable] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SPECIALTIES_LOCATION_LEVEL,
  ]);

  return (
    <InfoProviderContentContainer data-testid="dental-care-all-other-infos">
      <EmailAndWebsiteInfo selectedLocation={selectedLocation} />
      <InfoProviderContent
        icon="date_range"
        infoSubtext={eveningAndWeekendSubtext(t, selectedLocation)}
        infoTitle={t('OTHER_CARE_DETAILS.EVENING_AND_WEEKEND_OPTIONS')}
      />
      <InfoProviderContent
        icon="accessibility"
        infoSubtext={selectedLocation.accessibility?.join(', ')}
        infoTitle={t('PROVIDER_LOCATIONS_DETAILS.ACCESSIBILITY')}
      />
      <InfoProviderContent
        icon="translate"
        infoSubtext={selectedLocation?.languagesSpokenByOffice?.join(', ')}
        infoTitle={t('OTHER_CARE_DETAILS.LANGUAGES_SPOKEN_BY_STAFF')}
        variant="outlined"
      />
      {isLocationSpecialtiesEnable &&
        selectedLocation.specialties?.length > 0 && (
          <InfoProviderContent
            icon="inventory"
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.SPECIALTIES')}
            variant="outlined"
          >
            <ul>
              {selectedLocation.specialties.map((specialty, index) => (
                <li key={index} style={{ color: '#323333' }}>
                  {specialty}
                </li>
              ))}
            </ul>
          </InfoProviderContent>
        )}
      <InfoProviderContent
        icon="translate"
        infoSubtext={selectedLocation?.languagesSpokenByInterpreters
          ?.sort((a, b) => a.localeCompare(b))
          .join(', ')}
        infoTitle={t(
          'PROVIDER_LOCATIONS_DETAILS.LANGUAGES_SPOKEN_BY_INTERPRETER'
        )}
      />
      <InfoProviderContent
        icon="task"
        infoSubtext={getServicesText(t, selectedLocation)}
        infoTitle={t('PROVIDER_LOCATIONS_DETAILS.SERVICES_PERFORMED')}
        variant="outlined"
      />
      <InfoProviderContent
        icon="pan_tool"
        infoSubtext={
          practiceLimitation && practiceLimitation?.trim().length > 0
            ? practiceLimitation
            : notAvailableOrUnknownText
        }
        infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PRACTICE_LIMITATIONS')}
      />
      <InfoProviderContent
        icon="people"
        infoTitle={t('OTHER_CARE_DETAILS.ACCOMMODATES')}
      >
        <ListItem
          text={t('OTHER_CARE_DETAILS.ADULT_SPECIAL_NEEDS')}
          value={getYesNoText(
            t,
            selectedLocation?.accommodates?.treatsAdultSpecialityNeedsPatients
          )}
        />
        <ListItem
          text={t('OTHER_CARE_DETAILS.CHILD_SPECIAL_NEEDS')}
          value={getYesNoText(
            t,
            selectedLocation?.accommodates
              ?.acceptingChildrenWithSpecialHealthCareNeeds
          )}
        />
        <ListItem
          text={t('OTHER_CARE_DETAILS.CHILD_UNDER_FIVE')}
          value={getYesNoText(
            t,
            selectedLocation?.accommodates?.treatsChildrenUnderFive
          )}
        />
        <ListItem
          text={t('OTHER_CARE_DETAILS.INTELLECTUALLY_DISABLED')}
          value={getYesNoText(
            t,
            selectedLocation?.accommodates?.treatsIntellectuallyDisabledPatients
          )}
        />
        <ListItem
          text={t('OTHER_CARE_DETAILS.WHEELCHAIR_BOUND')}
          value={getYesNoText(
            t,
            selectedLocation?.accommodates?.treatsWheelChairBoundPatients
          )}
        />
      </InfoProviderContent>
      <InfoProviderContent
        icon="medical_services"
        infoSubtext={getYesNoText(t, selectedLocation?.accommodates?.treatsTMJ)}
        infoTitle={t('OTHER_CARE_DETAILS.TREATS_TMJ')}
        variant="outlined"
      />
      <InfoProviderContent
        icon="event"
        infoSubtext={inNetworkStartDate}
        infoTitle={t('PROVIDER_LOCATIONS_DETAILS.IN_NETWORK')}
        variant="outlined"
      />
    </InfoProviderContentContainer>
  );
};
