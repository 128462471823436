import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { config } from '@abyss/web/tools/config';
import { Button } from '@abyss/web/ui/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { phoneOnly } from '../../ConstantsStyles';
import { ContentWrapper } from '../../ContentWrapper';
import {
  DescriptionTextOnboarding,
  FlexContainer,
  HeadingTextOnBoarding,
  StyledDrawer,
} from './SmartChoice.styled';

export const properties: any = {
  buttonStyles: {
    backgroundColor: '$primary2',
    border: '1px solid $gray4',
    padding: '$sm $lg $sm $md',
    height: '2.625em',
    fontWeight: '$semibold',
    borderRadius: '24px',
    color: '$gray8',
  },
  drawerSize: 492,
  drawerposition: 'right',
};

export interface Props {
  isOpen: boolean;
  handleCloseDrawer: () => void;
  handleOpenSetPreferencesModal: () => void;
}

export const ONBOARDING_SMART_CHOICE = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/smart-choice-onboarding.png`;

export const OnboardingForm = ({
  isOpen,
  handleCloseDrawer,
  handleOpenSetPreferencesModal,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);

  const ListItems = ({ heading, description, divider }) => (
    <>
      <FlexContainer>
        <IconSymbol color="$success1" icon="check" size="24px" />
        <HeadingTextOnBoarding>{heading}</HeadingTextOnBoarding>
        <DescriptionTextOnboarding>{description}</DescriptionTextOnboarding>
      </FlexContainer>
      {divider && <Divider />}
    </>
  );

  const onClickSetPreferences = () => {
    handleCloseDrawer();
    handleOpenSetPreferencesModal();
  };

  return (
    <React.Fragment>
      <StyledDrawer
        data-auto-testid="onboarding-smart-choice-drawer"
        data-testid="onboarding-smart-choice-drawer"
        isOpen={isOpen}
        onClose={handleCloseDrawer}
        position={properties.position}
        size={properties.drawerSize}
        title={
          <React.Fragment>
            <Flex css={{ marginBottom: '56px' }} justify="center">
              <img
                alt="smart-choice-onboarding"
                src={ONBOARDING_SMART_CHOICE}
                width={`${mobileScreen ? 138 : 172}`}
              />
            </Flex>
            <Heading
              color="$primary"
              css={{ fontSize: '32.45px !important' }}
              data-testid="onboarding-smart-choice-heading"
              offset={5}
            >
              {t('SMART_CHOICE.ONBOARDING.HEADING')}
            </Heading>
          </React.Fragment>
        }
      >
        <ContentWrapper style={{ padding: mobileScreen ? '0px' : '16px' }}>
          <Text
            css={{ fontSize: '18px' }}
            data-testid="smart-choice-sub-heading"
          >
            {t('SMART_CHOICE.ONBOARDING.DESCRIPTION')}
          </Text>
          <ListItems
            description={t('SMART_CHOICE.ONBOARDING.QUALITY_CARE_DESCRIPTION')}
            divider
            heading={t('SMART_CHOICE.ONBOARDING.QUALITY_CARE')}
          />
          <ListItems
            description={t('SMART_CHOICE.ONBOARDING.BENEFITS_DESCRIPTION')}
            divider
            heading={t('SMART_CHOICE.ONBOARDING.BENEFITS')}
          />
          <ListItems
            description={t('SMART_CHOICE.ONBOARDING.CONVENIENCE_DESCRIPTION')}
            divider
            heading={t('SMART_CHOICE.ONBOARDING.CONVENIENCE')}
          />
          <ListItems
            description={t('SMART_CHOICE.ONBOARDING.PERSONAL_FIT_DESCRIPTION')}
            divider={false}
            heading={t('SMART_CHOICE.ONBOARDING.PERSONAL_FIT')}
          />
          <Button
            css={{ width: '100%', margin: '32px 0px' }}
            data-testid="set-preferences-button"
            onClick={onClickSetPreferences}
          >
            {t('SMART_CHOICE.ONBOARDING.SET_PREFERENCES')}
          </Button>
        </ContentWrapper>
      </StyledDrawer>
    </React.Fragment>
  );
};
