import { styled } from '@abyss/web/tools/styled';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { hideScrollbar } from '../../../../common/ConstantsStyles';
import { DataCardContainer } from '../../../../common/DataCard/DataCardContainer';
import { OutsideCountyAlert } from '../../../../common/DataCard/OutsideCountyAlert';
import { NationalGroupProvidersVirtualDataCard } from '../../../../common/NationalGroupProvidersVirtualBiteCard/NationalGroupProvidersVirtualDataCard';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { CheckBoxDataCardStyle } from '../../../../common/ResultSection/ResultSection.style';
import { isOutOfCounty } from '../../../../common/SnackCardContainer/utils';
import { getFeatureFlag } from '../../../../common/Utils';
import { checkTerminationDate } from '../../../../common/Utils/terminateDateIndicatorUtils';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { Provider } from '../../../../models/Provider';
import { ProviderType } from '../../../../models/ProviderDetails';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { isProviderStartDateInFuture } from '../../../../utils/date.utils';
import { scrollWithRef } from './CompareDrawer/utility/scrollWithRef';

type Props = {
  headers: ResponseHeaders;
  sectionType: string;
  showCheckboxes: boolean;
  providerResults: any[];
  addSelectedProvider: (a: any) => void;
  updatePin: (a: any) => void;
  selectedCheckbox: any;
  setSelectedCheckbox: (a: any) => void;
  isMobileMap?: boolean;
  DataCardStyle?: Object;
  selectLocation?: (locationId, locationLng, locationLat) => void;
  map?: any;
  setRouteEndCoords?(coords: [number | null, number | null]): void;
  locationForAnalytics?: string;
  pageNumber?: number;
  parentRef?: any;
  selectedFilters?: string;
  searchTerm?: string;
  usedAt?: string;
  navigateToDirections?: boolean;
  setNavigateToDirections?: (a: boolean) => void;
  mobileRouteView?: boolean;
  fromMobileListView: boolean;
  isLoading: boolean;
  isMNR?: boolean;
  enableMapViewEnhancements?: boolean;
  isTieredPlan?: boolean;
};

export const DataCardListStyled = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  boxSizing: 'border-box',
  alignItems: 'end',
  '@screen < $sm': {
    marginTop: '4px',
  },
  gap: '16px',
  zIndex: '0',
  variants: {
    variant: {
      flexDirectionRow: {
        flexDirection: 'row',
        overflowX: 'auto',
        flexWrap: 'nowrap',
      },
    },
  },
});

export const DataCardList = ({
  headers,
  sectionType,
  showCheckboxes,
  providerResults,
  addSelectedProvider,
  updatePin,
  selectedCheckbox,
  setSelectedCheckbox,
  isMobileMap,
  selectLocation,
  map,
  setRouteEndCoords,
  DataCardStyle,
  locationForAnalytics,
  pageNumber,
  parentRef,
  selectedFilters,
  searchTerm,
  usedAt = '',
  navigateToDirections = false,
  setNavigateToDirections,
  mobileRouteView = false,
  fromMobileListView,
  isLoading,
  enableMapViewEnhancements,
  isMNR,
  isTieredPlan,
}: Props) => {
  const checkedCount = Object.keys(selectedCheckbox.checked).filter(
    (key) => selectedCheckbox.checked[key]
  ).length;

  // begin: a11y keyboard navigation
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number>(-1);
  const uiState = useStore(StoreKeys.UI_STATE);

  const { compareProvidersFlow, shareProvidersFlow } = useStore(
    StoreKeys.UI_STATE
  );
  const { indexOflastProviderSelected } = compareProvidersFlow;
  const setUIstate = useStore(StoreKeys.SET_UI_STATE);
  const featureFlags = useLagoon('feature-flags')();

  const displayTerminationDateEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_FUTURE_TERMINATION_DATE
  );

  useEffect(() => {
    if (indexOflastProviderSelected === -1 || lastSelectedIndex >= 0) {
      const newUIState = {
        ...uiState,
        compareProvidersFlow: {
          ...compareProvidersFlow,
          indexOflastProviderSelected: lastSelectedIndex + 1,
        },
        shareProvidersFlow: {
          ...shareProvidersFlow,
          indexOflastProviderSelected: lastSelectedIndex + 1,
        },
      };
      setUIstate(newUIState);
    }
  }, [lastSelectedIndex]);
  // end: a11y keyboard navigation

  const handleDirectionClick = (provider) => {
    if (selectLocation)
      selectLocation(
        provider.locationId,
        provider.longitude,
        provider.latitude
      );
  };

  const listRef = useRef<HTMLDivElement>(null);
  const scrollIntoView = (index: number) => {
    scrollWithRef(listRef, index);
    parentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  const [countySearchAlertFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_COUNTY_SEARCH_ALERT,
  ]);
  const { isCountySearch } = useContext(CountySearchContext);
  const { name, stateCode } = getGeoLocationFromStorage();

  const adobeLinkTrackEventCheckBox = (checkedState: any, index: number) => {
    adobeLinkTrackEvent({
      name: `provider${checkedState ? ':uncheck' : ':checked'}`,
      location: `body:${sectionType} results:page ${pageNumber}:position ${
        index + 1
      }`,
      type: 'checkbox',
    });
  };

  const displayOutOfCntyIndicator = (provider) => {
    const isOutOfCnty = isOutOfCounty(
      isCountySearch,
      name,
      stateCode,
      provider
    );

    return (
      countySearchAlertFlag &&
      isOutOfCnty && (
        <OutsideCountyAlert
          county={name?.split(',')[0]}
          isOutOfCounty={isOutOfCnty}
        />
      )
    );
  };
  const isNetworkAlertIndicator = providerResults.some((snackCardProvider) =>
    checkTerminationDate(
      displayTerminationDateEnabled,
      snackCardProvider?.networkEndDate
    )
  );
  const isInNetworkBadge = providerResults.some((snackCardProvider) =>
    isProviderStartDateInFuture(snackCardProvider?.networkStartDate)
  );

  const displayVirtualProvider = (provider: Provider) =>
    !provider.address &&
    !!provider?.virtualCareOffered?.length &&
    (provider.providerType === ProviderType.PRACTITIONER ||
      provider.providerType === ProviderType.PROVIDER_GROUP);

  const displayDataCard = (provider, index) =>
    displayVirtualProvider(provider) ? (
      <NationalGroupProvidersVirtualDataCard
        css={DataCardStyle}
        fromMobileListView={fromMobileListView}
        headers={headers}
        index={index}
        isLoading={isLoading}
        key={provider?.locationId}
        locationForAnalytics={locationForAnalytics}
        practitioner={provider}
        sectionType={sectionType}
      />
    ) : (
      <div
        data-auto-testid="data-card-container"
        data-testid="data-card-container"
        key={provider?.locationId}
      >
        <DataCardContainer
          css={DataCardStyle}
          data-auto-testid="data-card"
          data-testid="data-card"
          fromMobileListView={fromMobileListView}
          handleDirectionClick={() => handleDirectionClick(provider)}
          headers={headers}
          index={index}
          isInNetworkBadge={isInNetworkBadge}
          isLoading={isLoading}
          isMNR={isMNR}
          isNetworkAlertIndicator={isNetworkAlertIndicator}
          isTieredPlan={isTieredPlan}
          locationForAnalytics={locationForAnalytics}
          map={map}
          mobileRouteView={mobileRouteView}
          navigateToDirections={navigateToDirections}
          pageNumber={pageNumber}
          practitioner={provider}
          scrollIntoView={scrollIntoView}
          searchTerm={searchTerm}
          sectionType={sectionType}
          selectedFilters={selectedFilters}
          setNavigateToDirections={setNavigateToDirections}
          setRouteEndCoords={setRouteEndCoords}
          updatePin={updatePin}
          usedAt={usedAt}
        />
        {displayOutOfCntyIndicator(provider)}
      </div>
    );

  return (
    <DataCardListStyled
      css={{
        '@screen < $md': {
          display: enableMapViewEnhancements ? 'grid' : 'flex',
        },
        '@screen < $sm': { hideScrollbar },
      }}
      data-auto-testid="data-card-list-flex"
      data-testid="data-card-list-flex"
      ref={listRef}
      variant={isMobileMap ? 'flexDirectionRow' : undefined}
    >
      {providerResults.map((provider: any, index) =>
        showCheckboxes ? (
          <Checkbox
            css={{
              'abyss-checkbox-root': {
                '& > div': { alignItems: 'center' },
                paddingLeft: '$xs',
                width: '100%',
                '.abyss-checkbox-flex-container': {
                  '& > .abyss-layout-group': {
                    flexGrow: 1,
                    '& > div': {
                      flexGrow: 1,
                    },
                  },
                },
              },
              'abyss-checkbox-label': {
                padding: 0,
                marginLeft: '19px',
                width: '100%',
              },
            }}
            data-auto-testid="map-view-checkbox-group"
            data-testid="map-view-checkbox-group"
            isChecked={selectedCheckbox.checked[provider?.locationId] || false}
            isDisabled={
              (!selectedCheckbox.checked[provider?.locationId] &&
                checkedCount >= 5) ||
              !provider.latitude // Providers must have a location to be compared
            }
            key={provider?.locationId}
            label={
              <React.Fragment>
                <DataCardContainer
                  css={CheckBoxDataCardStyle}
                  fromMobileListView={fromMobileListView}
                  handleDirectionClick={() => handleDirectionClick(provider)}
                  headers={headers}
                  index={index}
                  isLoading={isLoading}
                  isMNR={isMNR}
                  key={provider?.locationId}
                  locationForAnalytics={locationForAnalytics}
                  pageNumber={pageNumber}
                  practitioner={provider}
                  scrollIntoView={scrollIntoView}
                  searchTerm={searchTerm}
                  sectionType={sectionType}
                  selectedFilters={selectedFilters}
                />
                {displayOutOfCntyIndicator(provider)}
              </React.Fragment>
            }
            onChange={() => {
              setSelectedCheckbox((prevState) => ({
                checked: {
                  ...prevState.checked,
                  [provider?.locationId]:
                    !prevState.checked[provider?.locationId],
                },
              }));
              addSelectedProvider(provider);
              adobeLinkTrackEventCheckBox(
                selectedCheckbox.checked[provider?.locationId],
                index
              );
              setLastSelectedIndex(index);
            }}
            size={18}
          />
        ) : (
          displayDataCard(provider, index)
        )
      )}
    </DataCardListStyled>
  );
};
