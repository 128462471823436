import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import debounce from 'lodash/debounce';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import {
  Constants,
  OPTUM_CORRELATION_ID_HEADER,
} from '../../../common/PSXHeader/SearchBar/../../Constants';
import { isCounty } from '../../../common/PSXHeader/SearchBar/../../SnackCardContainer/utils';
import { KeywordSearchDropdown } from '../../../common/PSXHeader/SearchBar//KeywordSearchDropdown';
import { LocationDropdown } from '../../../common/PSXHeader/SearchBar//LocationDropdown';
import { ChangeLocationForm } from '../../../common/PSXHeader/SearchBar//LocationDropdown/ChangeLocation';
import { ChangeLocationDrawer } from '../../../common/PSXHeader/SearchBar//LocationDropdown/LocationDropdown.styled';
import { LocationInput } from '../../../common/PSXHeader/SearchBar//LocationInput';
import {
  InputBox,
  PositioningBox,
} from '../../../common/PSXHeader/SearchBar//SearchBar.styled';
import { SearchPlacement } from '../../../common/PSXHeader/SearchBar/SearchPlacement';
import {
  getGeoLocationFromStorage,
  handleKeywordSuggestionKeyDownHelper,
  setGeoLocationToStorage,
  setTypeAheadResults,
} from '../../../common/PSXHeader/SearchBar/utils';
import { CountySearchContext } from '../../../context/CountySearchContext';
import { getLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useAutoCompleteQuery } from '../../../hooks/useAutoCompleteQuery';
import { useCoverageType } from '../../../hooks/useCoverageType';
import { useLagoon } from '../../../hooks/useLagoon';
import { useLocation } from '../../../hooks/useLocation';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCurrentMember,
  getDomainForAutoComplete,
} from '../../../utils/user.utils';

export const GuestSearchBar = () => {
  // TODO: update network
  const network =
    'bh_Employer%20Guest%3Bdt_PPO000000001%3Bvn_1%3Bmd_52%3Bmd_NatAncCommercial';
  const language = getLanguage()?.code || 'en';
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const currentMember = getCurrentMember();
  const coverageType = useCoverageType();
  const [, getLocation] = useLocation({});

  const { navigate } = useRouter();

  const [isFocusedOnKeywordSearchInput, setIsFocusedOnKeywordSearchInput] =
    useState(false);
  const [isFocusedOnLocationSearch, setIsFocusedOnLocationSearch] =
    useState(false);
  const [keywordSearchTerm, setKeywordSearchterm] = useState('');
  const { setIsCountySearch } = useContext(CountySearchContext);
  let searchText: string;
  const storedLocation = getGeoLocationFromStorage();
  const {
    id,
    name = '',
    latitude: geoLat,
    longitude: geoLong,
    zipCode,
    stateCode,
  } = storedLocation;
  const [locationInputVal, setLocationInputVal] = useState(name);
  const [headers, setHeaders] = useSessionStorage<any>(
    Constants.STORAGE_KEYS.SESSION.TYPE_AHEAD_HEADERS,
    {}
  );
  if (isCounty(id)) setIsCountySearch(true);
  const [results, setResults] = useState<any>('');
  const [combineRollUpCodes, setCombineRollUpCodes] = useState<any>('');
  const [aggregatedAoeCodes, setAggregatedAoeCodes] = useState<string[]>([]);
  const [bhProgramFacilityAgg, setBhProgramFacilityAgg] = useState<any>('');
  const [enter, setEnter] = useState(false);
  let typeaheadRequestData = {};

  const [locationSuggestions, setLocationSuggestions] = useState<object[]>();
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [isKeywordSearchLoading, setIsKeywordSearchLoading] = useState(false);
  const [searchButtonResults, setSearchButtonResults] = useSessionStorage<any>(
    Constants.STORAGE_KEYS.SESSION.TYPE_AHEAD_SEARCH_RESULTS,
    []
  );
  const [autoCompleteCallCompleted, setAutoCompleteCallCompleted] =
    useState<any>(false);

  const commonSearchesLagoon: () => any = useLagoon('common-searches');
  const commonSearchesData = commonSearchesLagoon();

  const [cursor, setCursor] = useState(-1);

  const isShowingKeywordSearchDropdown = isFocusedOnKeywordSearchInput;
  const isShowingLocationDropdown = isFocusedOnLocationSearch;

  const { claimSystemTypeCode, eligibilitySystemTypeCode } =
    getClaimOrEligibilitySystemTypeCode(currentMember, coverageType);

  const setKeywordSearchInputFocused = () => {
    setIsFocusedOnKeywordSearchInput(true);
    setIsFocusedOnLocationSearch(false);
  };

  const setKeywordSearchInputBlur = () => {
    setIsFocusedOnKeywordSearchInput(false);
  };

  const setLocationInputBlur = () => {
    setIsFocusedOnLocationSearch(false);
  };

  useEffect(() => {
    !isFocusedOnLocationSearch && checkForEmptyLocation();
  }, [isFocusedOnLocationSearch]);

  const handleKeywordSearchInputFocus = (value) => {
    setKeywordSearchInputFocused();
    setCursor(-1);
    setEnter(false);
    setResults([]);
    setAutoCompleteCallCompleted(false);
    handleKeywordSearchInputChange(value);
  };

  const handleCloseLocationDropdown = () => {
    setKeywordSearchInputBlur();
    setLocationInputBlur();
  };

  const handleCloseLocationDrawer = () => {
    setKeywordSearchInputBlur();
    setLocationInputBlur();
  };

  const setLocationInputFocused = () => {
    setIsFocusedOnLocationSearch(true);
    setIsFocusedOnKeywordSearchInput(false);
  };

  const handleLocationSearchInputFocus = () => {
    setLocationInputFocused();
    setCursor(-1);
  };

  const handleLocationSearchTextChange = (value: string) => {
    setCursor(-1);
    setIsFocusedOnLocationSearch(true);
    setLocationInputVal(value);
  };
  const [typeAheadData, GetSearchSuggestions] = useAutoCompleteQuery({
    onCompleted: (response) => {
      setIsKeywordSearchLoading(false);
      const providers =
        response?.data?.autoComplete?.practitioners_uhc?.provData;
      const facilities =
        response?.data?.autoComplete?.organizations_uhc?.orgData;
      const suggestions = providers.concat(facilities);
      setSearchButtonResults(suggestions);
      const parsedResults = setTypeAheadResults(
        suggestions,
        providers,
        facilities,
        network
      );
      const correlationId = response?.headers[OPTUM_CORRELATION_ID_HEADER];
      setHeaders({
        correlationId,
      });
      setResults(parsedResults);
      setAutoCompleteCallCompleted(true);
    },
    onError: () => {
      setResults(null);
    },
  });

  const handleKeywordSearchInputChange = useCallback(
    debounce(async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setKeywordSearchterm(value.trim());
      setEnter(false);
      searchText = value.trim();
      setCursor(-1);
      setResults([]);
      if (value.replace(/\s/g, '').length > 1 && /[A-Za-z0-9]/.test(value)) {
        typeaheadRequestData = {
          query: encodeURIComponent(value.trim()),
          network: network,
          latitude: geoLat,
          longitude: geoLong,
          lang: language,
          radius: 100,
          claimSystemTypeCode,
          eligibilitySystemTypeCode,
          domain: getDomainForAutoComplete(currentMember),
          useAutocompleteRollUpCodeNameSearch: true,
          useBHNewRollUpCodes: true,
          useBHVirtualCarePseudoRollupCodeSearch: true,
          useDentalNewRollUpCodes: true,
          useEnableBhFacilitiesProgramSearchFlag: true,
          useMedicalNewRollUpCodes: true,
          useOxfordPlnFlag: true,
          useVisionNewRollUpCodes: true,
        };
        GetSearchSuggestions({
          variables: typeaheadRequestData,
        });
      }
    }, 400),
    [geoLat, geoLong, currentMember]
  );

  const checkForEmptyLocation = (): void => {
    if (!locationInputVal) {
      const storedLocation = getGeoLocationFromStorage();
      const { name } = storedLocation;
      setLocationInputVal(name);
    }
  };

  const getTypeaheadData = () =>
    cursor === -1 ? searchButtonResults?.[0] : searchButtonResults?.[cursor];

  const handleKeywordSuggestionKeyDown = (ev: {
    key: string;
    preventDefault: () => void;
    target: { value: string };
  }) => {
    handleKeywordSuggestionKeyDownHelper(ev, null, false, true, {
      keywordSearchTerm,
      commonSearchesData,
      isKeywordSearchLoading,
      searchButtonResults,
      setEnter,
      cursor,
      results,
      combineRollUpCodes,
      aggregatedAoeCodes,
      bhProgramFacilityAgg,
      store: {},
      navigate,
      getTypeaheadData,
      headers,
      setCursor,
      convertTypeaheadProviderIdAndType: () => {},
      setIsFocusedOnKeywordSearchInput,
      searchInputOptionLocation: null,
      blurSearchInput,
      choosePCP: null,
      dependentSeqNbr: null,
      getCoverageType: () => {},
      enableUESSuggestionMatch: false,
      currentMember,
    });
  };

  const searchInputRef = useRef<HTMLInputElement>(null);
  const blurSearchInput = () => {
    searchInputRef?.current?.blur();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutsideInput = wrapperRef.current
        ? !wrapperRef.current.contains(event.target)
        : true;
      if (isOutsideInput) {
        setKeywordSearchInputBlur();
        setLocationInputBlur();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <React.Fragment>
      <PositioningBox
        css={{
          height: '40px',
        }}
        ref={wrapperRef}
      >
        <InputBox
          hasShadow={isShowingKeywordSearchDropdown}
          isSharpBottomRightCorner={isShowingLocationDropdown}
          mobileScreen={false}
        >
          <SearchPlacement
            choosePCPHeader={false}
            commonSearchesData={commonSearchesData}
            dentalCoverage={true}
            handleKeywordSearchInputChange={handleKeywordSearchInputChange}
            handleKeywordSearchInputFocus={handleKeywordSearchInputFocus}
            handleKeywordSuggestionKeyDown={handleKeywordSuggestionKeyDown}
            isFocusedOnKeywordSearchInput={isFocusedOnKeywordSearchInput}
            isFocusedOnLocationSearch={isFocusedOnLocationSearch}
            keywordSearchInputId={''}
            keywordSearchTerm={keywordSearchTerm}
            search={''}
            searchButtonResults={searchButtonResults}
            searchInputRef={searchInputRef}
            searchLabelText={'Search Label Text'}
            setIsKeywordSearchLoading={setIsKeywordSearchLoading}
            setResults={setResults}
            setSearchButtonResults={setSearchButtonResults}
            visionCoverage={true}
          />

          {isShowingKeywordSearchDropdown && (
            <KeywordSearchDropdown
              activeSuggestion={cursor}
              aggregatedAoeCodes={aggregatedAoeCodes}
              autoCompleteCallCompleted={autoCompleteCallCompleted}
              bhProgramFacilityAgg={''}
              blurKeywordSearchInput={blurSearchInput}
              checkForEmptyLocation={checkForEmptyLocation}
              combinedRollupCodes={combineRollUpCodes}
              dentalCoverage={''}
              enableUESSuggestionMatch={false}
              enter={enter}
              getCoverageType={[]}
              handleGeneralSearchClick={() => {}}
              headers={headers}
              hideDisabledTypeahead={false}
              isKeywordSearchLoading={isKeywordSearchLoading}
              isLoading={typeAheadData?.isLoading}
              keywordSearchTerm={keywordSearchTerm}
              medicalCoverage={''}
              memberDDP={currentMember?.ddpCode}
              setIsFocusedOnKeywordSearchInput={
                setIsFocusedOnKeywordSearchInput
              }
              typeAheadSuggestions={results}
              visionCoverage={''}
            />
          )}
        </InputBox>

        <LocationInput
          aggregatedAoeCodes={aggregatedAoeCodes}
          bhProgramFacilityAgg={bhProgramFacilityAgg}
          checkForEmptyLocation={checkForEmptyLocation}
          combinedRollupCodes={combineRollUpCodes}
          cursor={cursor}
          data-testid="location-search-input"
          enableTypeAheadWildCardSearch={false}
          enableUESSuggestionMatch={false}
          handleCloseLocationDrawer={handleCloseLocationDrawer}
          handleCloseLocationDropdown={handleCloseLocationDropdown}
          headers={headers}
          isFocusedOnKeywordSearchInput={isFocusedOnKeywordSearchInput}
          isFocusedOnLocationSearch={isFocusedOnLocationSearch}
          isOpenMobileLocation={false}
          isShowingLocationDrawer={false}
          isShowingLocationDropdown={isShowingLocationDropdown}
          locationValue={locationInputVal}
          memberLocation={
            storage.session.get(
              Constants.STORAGE_KEYS.SESSION.MEMBER_LOCATION
            ) ?? ''
          }
          onLocationSearchInputFocus={handleLocationSearchInputFocus}
          onLocationSearchInputTextChange={handleLocationSearchTextChange}
          placeHolderValue={name}
          searchTerm={keywordSearchTerm}
          setCursor={setCursor}
          setIsFocusedOnKeywordSearchInput={setIsFocusedOnKeywordSearchInput}
          setIsLocationLoading={setIsLocationLoading}
          setIsOpenMobileLocation={() => {}}
          setLocationInputVal={setLocationInputVal}
          setLocationSuggestions={setLocationSuggestions}
          specialityPreferenceTable={null}
          typeAheadSuggestions={results}
        />

        {isShowingLocationDropdown && (
          <LocationDropdown
            activeSuggestion={cursor}
            data-testid="location-search-bar-dropdown"
            isLocationLoading={isLocationLoading}
            locationInputVal={locationInputVal}
            locationSuggestions={locationSuggestions}
            onClose={handleCloseLocationDropdown}
            setLocationInputVal={setLocationInputVal}
            setSelectedLocation={(targetLocation) => {
              const {
                id: locationId,
                center = [],
                place_name: placeName,
                stateCode: targetStateCode,
                zipCode: targetZipCode,
              } = targetLocation;
              const [selectedLong, selectedLat] = center;
              if (isCounty(locationId)) setIsCountySearch(true);
              else setIsCountySearch(false);

              setGeoLocationToStorage({
                id: locationId,
                name: placeName,
                longitude: selectedLong,
                latitude: selectedLat,
                stateCode: targetStateCode,
                zipCode: targetZipCode,
              });
            }}
          />
        )}

        <ChangeLocationDrawer
          isOpen={false}
          onClose={handleCloseLocationDrawer}
          position="bottom"
          size="$lg"
          title={t('Change Location')}
        >
          <ChangeLocationForm
            data-testid="change-location-form"
            onLocationSelect={handleCloseLocationDrawer}
          />
        </ChangeLocationDrawer>
      </PositioningBox>
    </React.Fragment>
  );
};
