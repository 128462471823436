import { CareCategories, ProviderTypes } from '../../common/Constants';
import { useChipStore } from '../../store/useChipStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import { useShallow } from 'zustand/react/shallow';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { useLagoon } from '../useLagoon';
import { ChipState } from '../../store/useChipStore/chipStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';

export const useSmartChoice = (): boolean => {
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const enablePSXProviderRecommendations = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_PSX_PROVIDER_RECOMMENDATIONS
  );
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { chipValue } = chipStore;
  const isMedicalOrPrimaryCare = [
    CareCategories.ALL,
    CareCategories.MEDICAL_SPECIALISTS,
    CareCategories.PRIMARY_CARE,
  ].includes(chipValue as CareCategories);
  const {
    providerType = '',
  } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({

      providerType: state.typeaheadSearchStore.providerType,
    }))
  );
  const isSmartChoiceSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.SMART_CHOICE
  );
  return !isSmartChoiceSuppressed && enablePSXProviderRecommendations && isMedicalOrPrimaryCare && ((providerType && providerType === ProviderTypes.PRACTITIONER|| !providerType));
};
