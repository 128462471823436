import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { InNetworkBadgeWithDate } from '../../../common/InNetworkBadge/InNetworkBadge';
import { TerminationBadge } from '../../../common/TerminationBadge/TerminationBadge';
import { getFeatureFlag } from '../../../common/Utils';
import { useLagoon } from '../../../hooks/useLagoon';
import { FacilityLocationsResponse } from '../../../models/FacilityDetails';
import { ProviderLocation } from '../../../models/ProviderDetails';
import { isProviderStartDateInFuture } from '../../../utils/date.utils';
import { shortenZipCode } from '../../../utils/providerDetails.utils';
import { handleLinkAndModalTrack } from '../../Utils/adobeTrackUtils';
import {
  StyledAddressLine,
  StyledLocationBox,
  StyledLocationBoxContainer,
  StyledLocationsDropdownOption,
  StyledLocationsPopover,
} from './PopOver.style';

type Props = {
  locationsDetails: ProviderLocation[] | FacilityLocationsResponse[];
  locationsContentForPopOver: React.ReactNode | React.ReactFragment;
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
  selectedLocation: any | {};
  headingId?: string;
};

export const PopOver = ({
  locationsDetails,
  locationsContentForPopOver,
  isOpen,
  setIsOpen,
  selectedLocation,
  headingId,
}: Props) => {
  const { t } = useTranslation();
  const { address, distance } = selectedLocation;
  const postalCode = shortenZipCode(address?.postalCode);
  const featureFlags = useLagoon('feature-flags')();
  const displayInNetworkBadgeEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_IN_NETWORK_BADGE_FUTURE_START_DATE
  );

  const displayInNetworkBadge = isProviderStartDateInFuture(
    selectedLocation?.network?.start
  );

  useEffect(() => {
    if (isOpen) {
      const linkName: string = 'change location popover';
      const linkLocation: string = `body:locations`;
      const modalName: string = 'change location';
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpen]);

  return locationsDetails.length > 1 ? (
    <StyledLocationBoxContainer>
      <StyledLocationsPopover
        align="start"
        aria-describedby="changeLocation"
        content={locationsContentForPopOver}
        onOpenChange={() => {
          setIsOpen(!isOpen);
        }}
        open={isOpen}
        position="bottom"
        showClose
        title={
          <Text
            css={{
              fontWeight: '700',
              fontStyle: 'Normal',
              fontSize: '25.63px',
              marginTop: '24px',
              paddingTop: '24px',
              paddingBottom: '24px',
              marginLeft: '8px',
              marginRight: '40px',
            }}
            data-auto-testid="change-location-heading"
            data-testid="change-location-heading"
          >
            <span id={headingId}>
              {t('PROVIDER_DETAILS.LOCATIONS_TAB.CHANGE_LOCATION')}
            </span>
          </Text>
        }
        width={299}
      >
        <span id="changeLocation" style={{ display: 'none' }}>
          Change Location
        </span>
        <StyledLocationBox
          data-auto-testid="provider-locations-box"
          data-testid="provider-locations-box"
        >
          <StyledLocationsDropdownOption>
            <TerminationBadge
              endDate={selectedLocation?.network?.end}
              message={t('PCP_TERMINATION.MAY_LEAVE_THE_NETWORK_ON')}
            />
            {displayInNetworkBadge && displayInNetworkBadgeEnabled && (
              <InNetworkBadgeWithDate
                message={t('DISPLAY_IN_NETWORK_BADGE.IN_NETWORK_BADGE_PCP')}
                startDate={selectedLocation?.network?.start}
              />
            )}
            <StyledAddressLine>{address.line}</StyledAddressLine>
            {address.city}, {address.state} {postalCode} (
            {Number(distance).toFixed(1)} {t('PROVIDER_DETAILS.MILES')})
          </StyledLocationsDropdownOption>
          <IconMaterial
            color="black"
            data-auto-testid="provider-locations-dropdown"
            data-testid="provider-locations-dropdown"
            icon="keyboard_arrow_down"
          />
        </StyledLocationBox>
      </StyledLocationsPopover>
    </StyledLocationBoxContainer>
  ) : (
    <StyledLocationBox
      data-auto-testid="provider-locations-box"
      data-testid="provider-locations-box"
    >
      <StyledLocationsDropdownOption>
        <StyledAddressLine>{address.line}</StyledAddressLine>
        {address.city}, {address.state} {postalCode} (
        {Number(distance).toFixed(1)} {t('PROVIDER_DETAILS.MILES')})
      </StyledLocationsDropdownOption>
    </StyledLocationBox>
  );
};
