import { Constants, ReverseCoverageTypesCodes } from '../../common/Constants';

const { PROVIDER, PROVIDER_GROUPS, FACILITY } = Constants.RESULT_SECTION;
const BEHAVIORAL_HEALTH = ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];
const { MEDICAL, VISION, DENTAL } = ReverseCoverageTypesCodes;

const NO_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
  [DENTAL]: [],
};

export const ACO_CHIP_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
  [DENTAL]: [],
};

export const ACO_DRAWER_FILTERS = ACO_CHIP_FILTERS;

export const CULTURAL_COMPETENCE_CHIP_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [VISION]: [],
  [DENTAL]: [],
};

export const CULTURAL_COMPETENCE_DRAWER_FILTERS =
  CULTURAL_COMPETENCE_CHIP_FILTERS;

export const DISTANCE_CHIP_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [VISION]: [PROVIDER, FACILITY],
  [DENTAL]: [PROVIDER],
};

export const DISTANCE_DRAWER_FILTERS = DISTANCE_CHIP_FILTERS;

export const VIRTUAL_CARE_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [PROVIDER],
  [VISION]: [],
  [DENTAL]: [],
};

export const VIRTUAL_CARE_DRAWER_FILTERS = NO_FILTERS;

export const PATIENT_REVIEWS_CHIP_FILTERS = {
  [MEDICAL]: [PROVIDER],
  [BEHAVIORAL_HEALTH]: [PROVIDER],
  [VISION]: [PROVIDER],
  [DENTAL]: [PROVIDER],
};

export const PATIENT_REVIEWS_DRAWER_FILTERS = PATIENT_REVIEWS_CHIP_FILTERS;

export const SPECIALTY_CODE_CHIP_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
  [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
  [VISION]: [],
  [DENTAL]: [],
};

export const SPECIALTY_CODE_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
  [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
  [VISION]: [PROVIDER, FACILITY],
  [DENTAL]: [PROVIDER],
};

export const AREA_OF_EXPERTISE_CHIP_FILTERS = NO_FILTERS;

export const AREA_OF_EXPERTISE_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [VISION]: [],
  [DENTAL]: [],
};

export const FACILITY_TYPE_CHIP_FILTERS = {
  [MEDICAL]: [FACILITY],
  [VISION]: [],
};

export const FACILITY_TYPE_DRAWER_FILTERS = FACILITY_TYPE_CHIP_FILTERS;

export const PREFERRED_FACILITY_CHIP_FILTERS = {
  [MEDICAL]: [FACILITY],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
};

export const PREFERRED_FACILITY_DRAWER_FILTERS =
  PREFERRED_FACILITY_CHIP_FILTERS;

export const EVENING_AND_WEEKEND_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
};

export const EVENING_AND_WEEKEND_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [VISION]: [PROVIDER, FACILITY],
  [DENTAL]: [PROVIDER],
};

export const HEALTH_SUPPLIES_OFFERED_CHIP_FILTERS = {
  [MEDICAL]: [FACILITY],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
};

export const HEALTH_SUPPLIES_OFFERED_DRAWER_FILTERS =
  HEALTH_SUPPLIES_OFFERED_CHIP_FILTERS;

export const ACCESSIBILITY_CODE_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
};

export const ACCESSIBILITY_CODE_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [VISION]: [PROVIDER, FACILITY],
  [DENTAL]: [PROVIDER],
};

export const LANGUAGE_CODE_CHIP_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
  [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
  [VISION]: [PROVIDER, FACILITY],
  [DENTAL]: [PROVIDER],
};

export const LANGUAGE_CODE_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [VISION]: [PROVIDER, FACILITY],
  [DENTAL]: [PROVIDER],
};

export const RACE_CODE_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [],
};

export const RACE_CODE_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER],
  [BEHAVIORAL_HEALTH]: [PROVIDER],
};

export const GENDER_CODE_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
};

export const GENDER_CODE_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER],
  [BEHAVIORAL_HEALTH]: [PROVIDER],
  [VISION]: [PROVIDER],
  [DENTAL]: [PROVIDER],
};

export const ETHNICITY_CODE_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
};

export const ETHNICITY_CODE_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER],
  [BEHAVIORAL_HEALTH]: [PROVIDER],
  [VISION]: [],
};

export const MEDICAL_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [PROVIDER],
  [VISION]: [],
};

export const MEDICAL_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER],
  [BEHAVIORAL_HEALTH]: [PROVIDER],
  [VISION]: [],
};

export const HOSPITAL_AFFILIATIONS_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
};

export const HOSPITAL_AFFILIATIONS_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER],
  [BEHAVIORAL_HEALTH]: [PROVIDER],
  [VISION]: [],
};

export const ESSENTIAL_COMMUNITY_PROVIDER_CHIP_FILTERS = NO_FILTERS;

export const ESSENTIAL_COMMUNITY_PROVIDER_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
  [VISION]: [PROVIDER],
  [DENTAL]: [PROVIDER],
};

export const ACCEPTING_NEW_PATIENTS_CHIP_FILTERS = (isMnrOrIfp: boolean) => ({
  [MEDICAL]: isMnrOrIfp ? [PROVIDER, PROVIDER_GROUPS] : [],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
  [DENTAL]: [],
});

export const ACCEPTING_NEW_PATIENTS_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
  [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
  [VISION]: [PROVIDER],
  [DENTAL]: [PROVIDER],
};

export const BEHAVIORAL_PROGRAM_INDICATOR_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
};

export const BEHAVIORAL_PROGRAM_INDICATOR_DRAWER_FILTERS =
  BEHAVIORAL_PROGRAM_INDICATOR_CHIP_FILTERS;

export const SERVICES_OFFERED_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [PROVIDER, FACILITY],
  [DENTAL]: [PROVIDER],
};

export const SERVICES_OFFERED_DRAWER_FILTERS = SERVICES_OFFERED_CHIP_FILTERS;

export const UPCOMING_AVAILABILITY_CHIP_FILTERS = {
  [MEDICAL]: [PROVIDER],
  [BEHAVIORAL_HEALTH]: [PROVIDER],
  [VISION]: [],
};

export const UPCOMING_AVAILABILITY_DRAWER_FILTERS =
  UPCOMING_AVAILABILITY_CHIP_FILTERS;

export const AGE_RANGE_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [FACILITY],
  [VISION]: [],
  [DENTAL]: [],
};

export const AGE_RANGE_DRAWER_FILTERS = AGE_RANGE_CHIP_FILTERS;

export const PROGRAM_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [FACILITY],
  [VISION]: [],
  [DENTAL]: [],
};

export const PROGRAM_DRAWER_FILTERS = PROGRAM_CHIP_FILTERS;

export const PROGRAM_CATEGORY_CHIP_FILTERS = NO_FILTERS;

export const PROGRAM_CATEGORY_DRAWER_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [FACILITY],
  [VISION]: [],
  [DENTAL]: [],
};

export const BOARD_CERTIFIED_SPECIALITY_INDICATOR_CHIP_FILTERS = NO_FILTERS;

export const BOARD_CERTIFIED_SPECIALITY_INDICATOR_DRAWER_FILTERS = {
  [MEDICAL]: [PROVIDER],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
  [DENTAL]: [],
};

export const TIERED_PROVIDERS_CHIP_FILTERS = {
  [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
  [DENTAL]: [],
};

export const TIERED_PROVIDERS_DRAWER_FILTERS = TIERED_PROVIDERS_CHIP_FILTERS;

export const PREMIUM_CARE_PHYSICIAN_CHIP_FILTERS = {
  [MEDICAL]: [PROVIDER],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
  [DENTAL]: [],
};

export const PREMIUM_CARE_PHYSICIAN_DRAWER_FILTERS = NO_FILTERS;

export const ACCOMMODATE_OFFERED_CHIP_FILTERS = NO_FILTERS;

export const ACCOMMODATE_OFFERED_DRAWER_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [],
  [VISION]: [],
  [DENTAL]: [PROVIDER],
};

export const VIRTUAL_CARE_OPTIONS_CHIP_FILTERS = {
  [MEDICAL]: [],
  [BEHAVIORAL_HEALTH]: [PROVIDER],
  [VISION]: [],
  [DENTAL]: [],
};

export const VIRTUAL_CARE_OPTIONS_DRAWER_FILTERS =
  VIRTUAL_CARE_OPTIONS_CHIP_FILTERS;
