import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { phoneOnly } from '../../../common/ConstantsStyles';
import { ContentWrapper } from '../../../common/ContentWrapper';
import { getLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useSaveRecommendationPreferences } from '../../../hooks/useSaveRecommendationPreferencesStatus';
import { properties } from './OnboardingForm';
import {
  AccordionContainer,
  HeadingText,
  StyledDrawer,
  ToggleSwitchField,
} from './SmartChoice.styled';
import {
  countDifferences,
  countPreferences,
  formatPreferenceData,
  getLanguagesSpokenValues,
  getSelectedLanguage,
} from './utils';

export interface RecommendationProp {
  createdDate?: string;
  distance?: string;
  evngApptAvbl?: string;
  hasDismissedCoachMark?: string;
  hasSetPreferences?: string;
  lastUpdatedDate?: string;
  onlineApptSchdlAvbl?: string;
  providerBoardCertStatus?: string;
  providerGender?: string;
  uuid?: string;
  virtualApptAvbl?: string;
  wkndApptAvbl?: string;
  languagesSpoken?: string[];
  hasDefaultPreferences?: string;
  memberRandomizedKey?: string;
}
export interface Props {
  isOpen: boolean;
  handleCloseDrawer: () => void;
  preferencesData: RecommendationProp;
}

const distanceMiles = ['1', '5', '10', '25', '50', '100', '120'];

export const PreferencesForm = ({
  isOpen,
  handleCloseDrawer,
  preferencesData,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const [preferenceCount, setPreferenceCount] = React.useState(0);
  const [disabledSubmit, setDisabledSubmit] = React.useState(0);

  const { code: currentSelectedLanguage } = getLanguage();

  const [savePreferencesData, saveRecommendationPreferences] =
    useSaveRecommendationPreferences({
      handleSuccess: () => {
        handleCloseDrawer();
      },
    });

  const form = useForm({
    defaultValues: {
      distance: null,
      virtualApptAvbl: false,
      onlineApptSchdlAvbl: false,
      evngApptAvbl: false,
      wkndApptAvbl: false,
      // call_support: false,
      languagesSpoken: [],
      providerGender: null,
      providerBoardCertStatus: false,
    },
    values: formatPreferenceData(
      savePreferencesData?.data?.saveRecommendationPreferencesStatus ||
        preferencesData
    ),
  });

  const formValues = React.useMemo(() => form.getValues(), [form]);

  React.useEffect(() => {
    if (formValues) {
      const formFields = JSON.parse(JSON.stringify(form.getValues()));
      const preferencesDataFields = JSON.parse(
        JSON.stringify(
          formatPreferenceData(
            savePreferencesData?.data?.saveRecommendationPreferencesStatus ||
              preferencesData
          )
        )
      );

      const difference = countDifferences(formFields, preferencesDataFields);
      const enabledPreferences = countPreferences(formFields);
      setDisabledSubmit(difference);
      setPreferenceCount(enabledPreferences);
    }
  }, [formValues]);

  const LANGUAGES_SPOKEN = getLanguagesSpokenValues(currentSelectedLanguage);

  const onSubmit = () => {
    const values = form.getValues();
    const {
      distance,
      virtualApptAvbl,
      onlineApptSchdlAvbl,
      evngApptAvbl,
      wkndApptAvbl,
      languagesSpoken,
      providerGender,
      providerBoardCertStatus,
    } = values;

    const payload = {
      uuid: preferencesData?.uuid,
      hasSetPreferences: 'y',
      hasDismissedCoachMark: preferencesData?.hasDismissedCoachMark,
      hasDismissedCoachMarkWeb: 'y',
      distance,
      virtualApptAvbl: virtualApptAvbl ? 'y' : 'n',
      onlineApptSchdlAvbl: onlineApptSchdlAvbl ? 'y' : 'n',
      evngApptAvbl: evngApptAvbl ? 'y' : 'n',
      wkndApptAvbl: wkndApptAvbl ? 'y' : 'n',
      providerBoardCertStatus: providerBoardCertStatus ? 'y' : 'n',
      providerGender,
      languagesSpoken: languagesSpoken?.length
        ? languagesSpoken?.join(',')
        : '',
    };

    if (typeof saveRecommendationPreferences === 'function') {
      saveRecommendationPreferences({ variables: payload });
    }

    const formFields = JSON.parse(JSON.stringify(values));

    const enabledPreferences = countPreferences(formFields);
    setPreferenceCount(enabledPreferences);
  };

  const onClearHandler = () => {
    const formFields = JSON.parse(JSON.stringify(form.getValues()));

    const enabledPreferences = countPreferences(formFields);
    setPreferenceCount(enabledPreferences);

    form.reset();
  };

  return (
    <React.Fragment>
      <StyledDrawer
        data-auto-testid="smart-choice-preferences-drawer"
        data-testid="smart-choice-preferences-drawer"
        isOpen={isOpen}
        onClose={handleCloseDrawer}
        position={properties.position}
        size={properties.drawerSize}
        title={
          <Heading
            color="$primary"
            css={{ fontSize: '32.45px !important' }}
            data-testid="smart-choice-preferences-heading"
            offset={5}
          >
            {t('SMART_CHOICE.ONBOARDING.SET_PREFERENCES')}
          </Heading>
        }
      >
        <ContentWrapper
          style={{
            padding: mobileScreen ? '0px' : '16px',
            '& form': { marginLeft: '-16px', marginRight: '-16px' },
          }}
        >
          <Flex justify="space-between">
            <Text
              css={{ fontSize: '16px', fontWeight: 700, display: 'block' }}
              data-testid="set-preferences-sub-heading"
            >
              {`${preferenceCount} ${t(
                'SMART_CHOICE.PREFERENCES.Preferences_applied'
              )}`}
            </Text>
            <div data-testid="clear-all-preferences" onClick={onClearHandler}>
              <Text
                css={{
                  fontSize: '16px',
                  fontWeight: 700,
                  display: 'block',
                  color: '$interactive1',
                  cursor: 'pointer',
                }}
              >
                {t('SMART_CHOICE.PREFERENCES.clear_all')}
              </Text>
            </div>
          </Flex>
          <Text
            css={{ fontSize: '14px', fontWeight: 400, marginBottom: '16px' }}
            data-testid="set-preferences-sub-heading-desc"
          >
            {t('SMART_CHOICE.PREFERENCES.Preferences_applied_desc')}
          </Text>

          <Flex>
            <HeadingText>
              {t('SMART_CHOICE.PREFERENCES.Convenience')}
            </HeadingText>
            <Text
              css={{
                marginBottom: '16px',
                fontSize: '16px',
              }}
            >
              {t('SMART_CHOICE.PREFERENCES.Convenience_desc')}
            </Text>
          </Flex>

          <FormProvider
            data-auto-testid="set-preferences-form"
            data-testid="set-preferences-form"
            onSubmit={onSubmit}
            state={form}
          >
            <AccordionContainer isCollapsible>
              <Accordion.Item value="distance">
                <Accordion.Trigger>
                  <Flex>
                    <Accordion.Header
                      subText={
                        formValues?.distance
                          ? `${t('SMART_CHOICE.PREFERENCES.Within')} ${
                              formValues?.distance
                            } ${t('SMART_CHOICE.PREFERENCES.Miles')}`
                          : t('SMART_CHOICE.PREFERENCES.No_preferences_set')
                      }
                    >
                      {t('SMART_CHOICE.PREFERENCES.Distance_within')}
                    </Accordion.Header>
                  </Flex>
                </Accordion.Trigger>
                <Accordion.Content>
                  <RadioGroup label="" model="distance">
                    {distanceMiles?.map((item) => (
                      <RadioGroup.Radio
                        key={item}
                        label={`${item} ${
                          parseInt(item) > 1
                            ? t('SMART_CHOICE.PREFERENCES.Miles')
                            : t('SMART_CHOICE.PREFERENCES.Mile')
                        }`}
                        value={item}
                      />
                    ))}
                  </RadioGroup>
                </Accordion.Content>
              </Accordion.Item>
            </AccordionContainer>
            <Divider />
            <Flex>
              <ToggleSwitchField
                label={
                  t('SMART_CHOICE.PREFERENCES.Virtual_appointments') as string
                }
                model="virtualApptAvbl"
              />
              <Divider />
              <ToggleSwitchField
                label={
                  t(
                    'SMART_CHOICE.PREFERENCES.Online_appointment_scheduling'
                  ) as string
                }
                model="onlineApptSchdlAvbl"
              />
              <Divider />
              <ToggleSwitchField
                label={
                  t('SMART_CHOICE.PREFERENCES.Evening_appointments') as string
                }
                model="evngApptAvbl"
              />
              <Divider />
              <ToggleSwitchField
                label={
                  t('SMART_CHOICE.PREFERENCES.Weekend_appointments') as string
                }
                model="wkndApptAvbl"
              />
              {/* <Divider />
              <ToggleSwitchField
                label={t('SMART_CHOICE.PREFERENCES.call_support') as string}
                model="call_support"
              /> */}
              <Divider />
            </Flex>
            <Flex css={{ padding: '0px 16px' }}>
              <HeadingText>
                {t('SMART_CHOICE.ONBOARDING.PERSONAL_FIT')}
              </HeadingText>
              <Text
                css={{
                  marginBottom: '16px',
                  fontSize: '16px',
                }}
              >
                {t('SMART_CHOICE.PREFERENCES.personal_fit_desc')}
              </Text>
            </Flex>
            <AccordionContainer isCollapsible>
              <Accordion.Item value="language">
                <Accordion.Trigger>
                  <Accordion.Header
                    subText={
                      formValues?.languagesSpoken === ''
                        ? t('SMART_CHOICE.PREFERENCES.No_preferences_set')
                        : formValues?.languagesSpoken?.length
                        ? getSelectedLanguage(
                            formValues?.languagesSpoken,
                            currentSelectedLanguage
                          )?.join(', ')
                        : t('SMART_CHOICE.PREFERENCES.No_preferences_set')
                    }
                  >
                    {t('SMART_CHOICE.PREFERENCES.Languages_spoken')}
                  </Accordion.Header>
                </Accordion.Trigger>
                <Accordion.Content>
                  <CheckboxGroup label="" model="languagesSpoken">
                    {LANGUAGES_SPOKEN &&
                      LANGUAGES_SPOKEN?.map((item) => (
                        <Checkbox
                          key={item.value}
                          label={item.title}
                          value={item.value}
                        />
                      ))}
                  </CheckboxGroup>
                </Accordion.Content>
              </Accordion.Item>
            </AccordionContainer>
            <Divider />
            <AccordionContainer isCollapsible>
              <Accordion.Item value="gender">
                <Accordion.Trigger>
                  <Accordion.Header
                    subText={
                      formValues?.providerGender
                        ? formValues?.providerGender?.toLowerCase() === 'm'
                          ? 'Male'
                          : 'Female'
                        : t('SMART_CHOICE.PREFERENCES.No_preferences_set')
                    }
                  >
                    {t('SMART_CHOICE.PREFERENCES.Gender')}
                  </Accordion.Header>
                </Accordion.Trigger>
                <Accordion.Content>
                  <RadioGroup label="" model="providerGender">
                    <RadioGroup.Radio label="Male" value="M" />
                    <RadioGroup.Radio label="Female" value="F" />
                  </RadioGroup>
                </Accordion.Content>
              </Accordion.Item>
            </AccordionContainer>
            <Divider />
            <ToggleSwitchField
              label={t('SMART_CHOICE.PREFERENCES.Board_certified') as string}
              model="providerBoardCertStatus"
            />
            <Divider />
            <Flex css={{ margin: '32px 16px' }}>
              <Button
                css={{ width: '100%' }}
                data-testid="search-for-provider-button"
                isDisabled={disabledSubmit === 0}
                type="submit"
              >
                {preferencesData?.hasSetPreferences === 'y'
                  ? t('SMART_CHOICE.PREFERENCES.Update_provider_score')
                  : t('SMART_CHOICE.PREFERENCES.Search_provider')}
              </Button>
            </Flex>
          </FormProvider>
        </ContentWrapper>
      </StyledDrawer>
    </React.Fragment>
  );
};
