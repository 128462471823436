import { styled } from '@abyss/web/tools/styled';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';

export const StyledTitle = styled(Text, {
  fontFamily: '$primary',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '20px',
  letterSpacing: '0',
  color: '#002677 !important',
});

export const ExploreUnaitedHealthPremiumLink = styled(Link, {
  fontSize: '14px !important',
  fontWeight: '500 !important',
  lineHeight: '18px !important',
  letterSpacing: '0 !important',
  color: '#196ECF !important',
  verticalAlign: 'middle !important',
  '&::before': {
    borderBottom: 'none !important',
  },
});

export const StyledNotAMatchText = styled(Text, {
  fontFamily: '$primary',
  fontWeight: '700 !important',
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0',
  color: '#002677',
  marginBottom: '0 !important',
});
