import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { GuestDeeplinkGenerator } from 'parcels/src/frontends/GuestExperience/deeplink/GuestDeeplinkGenerator';
import { GuestExperience } from 'parcels/src/frontends/GuestExperience/GuestExperience';
import { ProviderSearchForPortal } from 'parcels/src/frontends/ProviderSearchForPortal/ProviderSearchForPortal';
import React from 'react';

import {
  Constants,
  PSX_LANDING_PAGE_PARAMS,
  PopulationType,
  RECIPROCITYID_NEVADA,
} from '../../parcels/src/common/Constants';
import {
  PortalData,
  PortalIdentifier,
  PortalName,
} from '../../parcels/src/common/Portal';
import { MockAuthPortalMemberInfo } from '../../parcels/src/common/Portal/MockAuthMember';
import {
  mockBehavioralGuestPortalMemberInfo,
  mockTier1PortalMemberInfo,
} from '../../parcels/src/common/Portal/mockBehavioralGuestPortalMemberInfo';
import { mockMedicalGuestPortalMemberInfo } from '../../parcels/src/common/Portal/mockMedicalGuestPortalMemberInfo';
import { mockPortalMemberInfo } from '../../parcels/src/common/Portal/mockPortalMemberInfo';
import { ProviderSearch } from '../../parcels/src/frontends/ProviderSearch';
import { DetailsDrawer } from './DetailsDrawer';
import { createThemeOverride, portalNameThemeMap } from './theme/theme';

export const browser = () => {
  const { getLocation } = useRouter();
  const { pathname = '' } = getLocation() || {};
  if (pathname === '/details-widget') {
    return <DetailsDrawer />;
  }
  if (pathname.startsWith('/laww')) {
    // Parse the current URL
    const urlParams = new URLSearchParams(window.location.search);

    // get theme
    const theme = urlParams.get('theme') || 'optum';

    // Get language, defaults to en-US
    const lang = urlParams.get('lang') || 'en-US';

    // Get the user tier, defaults to 2
    const userTier = urlParams.get('userTier') || '2';
    const eapOnlyClient = urlParams.get('eapOnlyClient') === 'true';
    const showEapFilter =
      eapOnlyClient || urlParams.get('showEapFilter') !== 'false';
    const lockEapFilter =
      eapOnlyClient || urlParams.get('lockEapFilter') === 'true';
    const preSelectEapFilter =
      eapOnlyClient || urlParams.get('preSelectEapFilter') === 'true';
    const showOnlineScheduling =
      urlParams.get('showOnlineScheduling') !== 'false';
    const portalShowCost = !(
      eapOnlyClient || urlParams.get('showCost') === 'false'
    );
    const showTreatmentCenter = !(
      eapOnlyClient || urlParams.get('showTreatmentCenter') === 'false'
    );
    const showMarketPlace = !(
      eapOnlyClient || urlParams.get('showMarketPlace') === 'false'
    );
    const memberInfo = () => {
      if (userTier === '0') return mockBehavioralGuestPortalMemberInfo;
      if (userTier === '1') return mockTier1PortalMemberInfo;
      return mockPortalMemberInfo;
    };
    const showVirtualVisits = urlParams.get('showVirtualVisits') !== 'false';

    const portalData: PortalData = {
      portalName: 'LAWW',
      portalConfig: {
        basePath: '/laww',
        headerChipsEnabled: false,
        theme,
        language: lang,
        homePage: PSX_LANDING_PAGE_PARAMS,
        signInUrl: '/api/auth/server-signin?callbackUrl=LANDING_PAGE',
        isGuestUser: userTier === '0',
        showCost: portalShowCost,
        showTreatmentCenter,
        showMarketPlace,
        includeBaseCss: true,
        userTier,
        showEapFilter,
        lockEapFilter,
        preSelectEapFilter,
        showVirtualVisits,
        showOnlineScheduling,
      },
      portalMemberInfo: memberInfo(),
      portalUrls: [],
    };

    return <ProviderSearchForPortal portal-data={JSON.stringify(portalData)} />;
  }

  if (
    pathname.startsWith('/umr') ||
    pathname.startsWith('/geha') ||
    pathname.startsWith('/umrNevada')
  ) {
    let themeOverride = createThemeOverride(portalNameThemeMap[PortalName.UMR]);
    let basePath = '/umr';
    let portalIdentifier = PortalIdentifier.STANDARD;
    let population = PopulationType.UM;
    if (pathname.startsWith('/geha')) {
      themeOverride = createThemeOverride(
        portalNameThemeMap[PortalIdentifier.GEHA]
      );
      basePath = '/geha';
      portalIdentifier = PortalIdentifier.GEHA;
    } else if (pathname.startsWith('/umrNevada')) {
      portalIdentifier = PortalIdentifier.NV;
      population = PopulationType.SIERRA;
      basePath = '/umrNevada';
      MockAuthPortalMemberInfo.memberInfo[0].eligibility[0].memberHealthCoverage.coverageType[0].reciprocityId =
        RECIPROCITYID_NEVADA;
      MockAuthPortalMemberInfo.memberInfo[0].reciprocityId =
        RECIPROCITYID_NEVADA;
      MockAuthPortalMemberInfo.memberInfo[0].eligibility[0].memberHealthCoverage.coverageType =
        MockAuthPortalMemberInfo.memberInfo[0].eligibility[0].memberHealthCoverage.coverageType.filter(
          (coverage) => coverage.typeCode.coverageTypeCode !== 'B'
        );
    }

    const {
      USE_PRE_PROD,
      USE_OBAPI,
      ENABLE_PCP,
      IS_PREPROD_USER,
      DEMO_MEMBER_INFO,
    } = Constants.STORAGE_KEYS.SESSION;

    const useObapiStore = storage.session.get(USE_OBAPI) || false;
    const useObapiPreProdStore = storage.session.get(USE_PRE_PROD) || false;
    const enablePcpStore = storage.session.get(ENABLE_PCP) || false;

    // Parse the current URL
    const urlParams = new URLSearchParams(window.location.search);

    // Get language, defaults to en-US
    const lang = urlParams.get('lang') || 'en-US';

    // Get enablePcp, defaults to false
    const urlPcpEnabled = urlParams.get('enablePcp');
    const isPcpEnabled = urlPcpEnabled
      ? urlParams.get('enablePcp') === 'true'
      : enablePcpStore;

    // Get greenDiamondPolicy state code
    const greenDiamondPolicy =
      urlParams.get('greenDiamondPolicy')?.toLowerCase() || '';

    const greenDiamondTestingPolicies = {
      wv: { policyId: '77700000', reciprocityId: '10169' },
    };
    const { firstName, lastName, dob, policyId, memberId, delsysCode } =
      storage.session.get(DEMO_MEMBER_INFO) || {};

    const greenDiamondPolicyInfo =
      greenDiamondTestingPolicies[greenDiamondPolicy];

    // Get obapi related query params
    const memberFirstName = urlParams.get('firstName') || firstName;
    const memberLastName = urlParams.get('lastName') || lastName;
    const memberDob = urlParams.get('dob') || dob;
    const memberPolicyId = urlParams.get('policyId') || policyId;
    const urlMemberId = urlParams.get('memberId') || memberId;
    const memberDelsysCode = urlParams.get('delsysCode') || delsysCode;
    const urlEnablePreProd = urlParams.get('enablePreProd');
    const urlUseOBAPI = urlParams.get('useOBAPI');
    const enablePreProd = urlEnablePreProd
      ? urlParams.get('enablePreProd') === 'true'
      : useObapiPreProdStore;
    const useOBAPI = urlUseOBAPI
      ? urlParams.get('useOBAPI') === 'true'
      : useObapiStore;

    storage.session.set(DEMO_MEMBER_INFO, {
      firstName: memberFirstName,
      lastName: memberLastName,
      policyId: memberPolicyId,
      memberId: urlMemberId,
      dob: memberDob,
      delsysCode: memberDelsysCode,
      usePreProd: enablePreProd,
    });

    const portalConfig = {
      basePath,
      headerChipsEnabled: true,
      homePage: '/',
      language: lang,
      theme: 'uhc',
      includeBaseCss: true,
      isGuestUser: false,
      themeOverride,
      useOBAPI,
    };

    storage.session.set(IS_PREPROD_USER, enablePreProd);
    storage.session.set(USE_PRE_PROD, enablePreProd);
    storage.session.set(USE_OBAPI, useOBAPI);
    storage.session.set(ENABLE_PCP, isPcpEnabled);
    let portalData: PortalData;
    let portalEligibilityData;
    if (!useOBAPI) {
      const portalMemberInfo = MockAuthPortalMemberInfo;
      portalMemberInfo.memberInfo[0].population = population;
      if (memberFirstName)
        portalMemberInfo.memberInfo[0].demographics.name.firstName =
          memberFirstName;
      if (memberLastName)
        portalMemberInfo.memberInfo[0].demographics.name.lastName =
          memberLastName;
      if (memberDob)
        portalMemberInfo.memberInfo[0].demographics.dateOfBirth = memberDob;
      if (memberDelsysCode) {
        portalMemberInfo.memberInfo[0].eligibility[0].memberHealthCoverage.coverageType[0].reciprocityId =
          memberDelsysCode;
        portalMemberInfo.memberInfo[0].reciprocityId = memberDelsysCode;
      }
      if (memberPolicyId)
        portalMemberInfo.memberInfo[0].policyNumber = memberPolicyId;
      portalMemberInfo.memberInfo[0].viewPcp = isPcpEnabled;
      portalMemberInfo.memberInfo[0].editPcp = isPcpEnabled;
      if (greenDiamondPolicyInfo) {
        portalMemberInfo.memberInfo[0].reciprocityId =
          greenDiamondPolicyInfo.reciprocityId;
        portalMemberInfo.memberInfo[0].policyNumber =
          greenDiamondPolicyInfo.policyId;
        portalMemberInfo.memberInfo[0].eligibility[0].memberHealthCoverage.coverageType[0].reciprocityId =
          greenDiamondPolicyInfo.reciprocityId;
      }

      portalData = {
        portalName: PortalName.UMR,
        portalIdentifier,
        portalConfig,
        portalMemberInfo,
      };
    } else {
      portalEligibilityData = {
        firstName: memberFirstName,
        lastName: memberLastName,
        dob: memberDob,
        memberId: urlMemberId,
        policyId: memberPolicyId,
        useObapiPreprod: enablePreProd,
        delsysCode: memberDelsysCode,
        enablePcp: isPcpEnabled,
      };

      portalData = {
        portalName: PortalName.UMR,
        portalIdentifier,
        portalEligibilityData,
        portalConfig,
      };
    }

    return <ProviderSearchForPortal portal-data={JSON.stringify(portalData)} />;
  }

  if (pathname.startsWith('/medical-guest')) {
    // Parse the current URL
    const urlParams = new URLSearchParams(window.location.search);
    const choosePcp = urlParams.get('choosePCP') || 'false';
    const chipValue = urlParams.get('chipValue');

    let homePath = '/';
    if (choosePcp === 'true') {
      homePath =
        '/results?chipValue=Primary%20Care&coverageType=M&search=Primary%20Care&searchType=speciality&providerType=&choosePCP=true&pcpIndicator=true';

      if (!chipValue) {
        window.location.href = `${window.location.protocol}//${window.location.host}/medical-guest${homePath}`;
      }
    }

    const portalData: PortalData = {
      portalName: 'medical-guest',
      portalConfig: {
        basePath: '/medical-guest',
        headerChipsEnabled: true,
        theme: 'uhc',
        language: 'en-US',
        homePage: homePath,
        isGuestUser: true,
        showCost: true,
        showTreatmentCenter: true,
        includeBaseCss: true,
        showVirtualVisits: true,
        showOnlineScheduling: true,
      },
      portalMemberInfo: mockMedicalGuestPortalMemberInfo,
      portalUrls: [],
    };

    return <ProviderSearchForPortal portal-data={JSON.stringify(portalData)} />;
  }

  if (pathname.startsWith('/guest')) {
    return <GuestExperience />;
  }

  if (pathname.startsWith('/generate-guest-deeplink')) {
    return <GuestDeeplinkGenerator />;
  }

  if (pathname.startsWith('/supportFinder')) {
    const portalData: PortalData = {
      portalName: 'SUPPORT_FINDER',
      portalConfig: {
        basePath: '/supportFinder',
        headerChipsEnabled: false,
        homePage: PSX_LANDING_PAGE_PARAMS,
        signInUrl: '/api/auth/server-signin?callbackUrl=LANDING_PAGE',
        includeBaseCss: true,
        userTier: '1',
        theme: 'optum',
        showEapFilter: true,
        lockEapFilter: true,
        preSelectEapFilter: true,
      },
      portalMemberInfo: mockTier1PortalMemberInfo,
      portalUrls: [],
    };

    return <ProviderSearchForPortal portal-data={JSON.stringify(portalData)} />;
  }

  if (pathname.startsWith('/find-care')) {
    const portalData: PortalData = {
      portalName: PortalName.ONE_PORTAL,
      portalConfig: {
        basePath: '/find-care',
        headerChipsEnabled: false,
        homePage: '/',
        includeBaseCss: true,
        theme: 'uhc',
        useOBAPI: true,
        usePlanSelector: true,
      },
    };

    return <ProviderSearchForPortal portal-data={JSON.stringify(portalData)} />;
  }

  return <ProviderSearch />;
};
