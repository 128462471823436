import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import React from 'react';

import {
  MatchStyledText,
  StyledFlexWithGapAndNoWrap,
} from '../../SmartChoiceBreakDown.styles';

interface MatchTextWithAvailabilityProps {
  text: string;
  availability: string;
}
export const MatchTextWithAvailability = ({
  text,
  availability,
}: MatchTextWithAvailabilityProps) => (
  <StyledFlexWithGapAndNoWrap alignItems="flex-start" direction="row">
    <IconSymbol color="#007000" icon="done" />
    <StyledFlexWithGapAndNoWrap alignItems="flex-start" direction="column">
      <MatchStyledText>{text}</MatchStyledText>
      <MatchStyledText>{availability}</MatchStyledText>
    </StyledFlexWithGapAndNoWrap>
  </StyledFlexWithGapAndNoWrap>
);
