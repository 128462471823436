import { styled } from '@abyss/web/tools/styled';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';

import { PSXIcon } from '../../../common/PSXIcon';

export const StyledText = styled(Text, {
  marginBottom: '0px !important',
  fontFamily: '$primary',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0',
  color: '#323334',
  display: 'flex',
});

export const HealthGradeDescriptionContainer = styled('div', {
  display: 'flex',
});

export const StyledLinkHealthGrade = styled(Link, {
  '&::before': {
    borderBottom: 'none !important',
  },
});

export const StyledIcon = styled(PSXIcon, {
  height: '24px !important',
  marginLeft: '-8px',
});
