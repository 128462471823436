import _ from "lodash";
import { t } from 'i18next';
import enLangugages from '@optum-fpc-psx-mobile-apps/mapping-files-translation/data/en/languageTranslations.json';
import esLangugages from '@optum-fpc-psx-mobile-apps/mapping-files-translation/data/es/languageTranslations.json';

export const getFormattedValues = (
  languagesValues
): Array<{ title: string; value: string }> =>
  Object.keys(languagesValues).reduce((acc: any, key: string) => {
    if (key !== 'zxx' && key !== 'zzz') {
      acc.push({ title: languagesValues[key], value: key });
    }
    return acc;
  }, []);

export const getLanguagesSpokenValues = (
  language: string
): Array<{ title: string; value: string }> => {
  if (language === 'es') {
    return getFormattedValues(esLangugages);
  }
  return getFormattedValues(enLangugages);
};

export const getSelectedLanguage = (keys: string[], language: string): string[] => {
  if (language === 'es') {
    return keys.map((key) => esLangugages[key]);
  }
  return keys.map((key) => enLangugages[key]);
};

export const deleteCommonKeys = (formFields) => {
  delete formFields.createdDate;
  delete formFields.lastUpdatedDate;
  delete formFields.memberRandomizedKey;
  delete formFields.uuid;
  delete formFields.hasSetPreferences;
  delete formFields.hasDismissedCoachMark;
  delete formFields.hasDismissedCoachMarkWeb;
  delete formFields.hasDefaultPreferences;

  return formFields;
};

export const countDifferences = (oldValues, newValues) => {
  const formattedOldValues = deleteCommonKeys(oldValues);
  const formattedNewValues = deleteCommonKeys(newValues);

  const oldKeys: any[] = Object.values(formattedOldValues);
  const newKeys: any[] = Object.values(formattedNewValues);

  let differenceCount = 0;

  _.forEach(oldKeys, (value: any[], key) => {
    if (value !== newKeys[key] && typeof value !== 'object') {
      differenceCount++;
    } else if (typeof value === 'object' && value?.length !== newKeys[key]?.length) {
      differenceCount ++;
    } else if (typeof value === 'object') {
      const difference = _.difference(value, newKeys[key]);
      if (difference.length > 0) {
        differenceCount++;
      }
    }
  });

  return differenceCount;
};

export const countPreferences = (preferences) => {
  const formattedPreferences = deleteCommonKeys(preferences);
  const values = Object.values(formattedPreferences);

  let count = 0;
  _.forEach(values, (value) => {
    if (value === true) {
      count++;
    } else if (typeof value === 'string' && value !== '') {
      count++;
    }
  });

  return count;
};

export const formatPreferenceData = (obj) => ({
  ...obj,
  virtualApptAvbl: obj?.virtualApptAvbl === 'y',
  onlineApptSchdlAvbl: obj?.onlineApptSchdlAvbl === 'y',
  evngApptAvbl: obj?.evngApptAvbl === 'y',
  wkndApptAvbl: obj?.wkndApptAvbl === 'y',
  providerBoardCertStatus: obj?.providerBoardCertStatus === 'y',
  languagesSpoken: obj?.languagesSpoken?.split(',') || [],
});

export const defaultToZero = (val: any): any =>
  val !== '' && val !== null ? val : '0';

export const removeLeadingZeroes = (str?: string): string =>
  str?.replace(/^0+/, '') ?? '';

export interface CustomPreferredPolicy {
  badgeLabel: string;
  description: string;
  icon: string;
  id: string;
  key: string;
  'lagoon.updateAt': string;
  'lagoon.updatedBy': string;
  policyId: string;
  popoverContent: string;
  productName: string;
  reciprocityId: string;
  byteCardLabel: string;
}

export const getIsCustomPreferredPolicy = (
  customPreferredPolicies: CustomPreferredPolicy[],
  policyId: string,
  reciprocityId: string
): boolean =>
  !(
    customPreferredPolicies?.find(
      (cpp) =>
        removeLeadingZeroes(cpp.policyId) === removeLeadingZeroes(policyId) &&
        cpp.reciprocityId === reciprocityId
    ) == null
  );

export const getCustomPreferredPolicyData = (
  customPreferredPolicies: CustomPreferredPolicy[],
  policyId: string,
  reciprocityId: string
): CustomPreferredPolicy | undefined =>
  customPreferredPolicies?.find(
    (cpp) =>
      removeLeadingZeroes(cpp.policyId) === removeLeadingZeroes(policyId) &&
      cpp.reciprocityId === reciprocityId
  );

export const useCustomPolicy = (
  selectedMember,
  tableFunction1
): {
  isCustomPreferredPolicy: boolean;
  customPreferredPolicyData: CustomPreferredPolicy | undefined;
} => {
  // const tableFunction1 = ;

  const data =
    tableFunction1 != null
      ? { ['CUSTOM_PREFERRED_POLICY']: tableFunction1() }
      : null;
  const res = data != null ? data['CUSTOM_PREFERRED_POLICY'] : [];

  const currentMember = selectedMember ?? {
    policyNumber: '',
    reciprocityId: '',
  };

  const isCustomPreferredPolicy: boolean = getIsCustomPreferredPolicy(
    res,
    currentMember?.policyNumber ?? '',
    currentMember?.reciprocityId ?? ''
  );

  const customPreferredPolicyData = getCustomPreferredPolicyData(
    res,
    currentMember?.policyNumber ?? '',
    currentMember?.reciprocityId ?? ''
  );

  return { isCustomPreferredPolicy, customPreferredPolicyData };
};

export const defaultStringToEmpty = (
  string: string | undefined | null
): string => string ?? '';

export const getAcoBenefitPlan = (
  cesProductCode?: string,
  memberMarketType?: string,
  membershipCategory?: string,
  population?: string,
  acoBenefitPlanCollection?: string
): boolean => {
  const isNotOxford = membershipCategory !== 'OXFORD';
  if (population === 'USP') {
    return isNotOxford;
  }
  const ACO_BENEFIT_COLLECTION =
    acoBenefitPlanCollection?.split(',')?.map((pair) => {
      const [marketType, productCode] = pair.split(':');
      return [marketType, productCode];
    }) ?? [];
  return ACO_BENEFIT_COLLECTION?.some(([marketType, productCode]) =>
    defaultStringToEmpty(cesProductCode).length > 0 &&
    defaultStringToEmpty(memberMarketType).length > 0
      ? productCode === cesProductCode && marketType === memberMarketType
      : false
  );
};

export const truncateString = (str: string = '', num: number): string => {
  if (num <= 0) {
    return '';
  }
  if (str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
};

export const distanceParser = (distance: string): string => {
  const number = parseFloat(distance);

  return isNaN(number)
    ? t('SMART_CHOICE.AUDIT_SCREEN.Number_of_miles', { number: '0' })
    : t('SMART_CHOICE.AUDIT_SCREEN.Number_of_miles', {
        number: number.toFixed(2).replace('.00', ''),
      });
};

export const getLanguageFormatted = (data: string) =>
  truncateString(
    data
      ?.split(',')
      ?.map((item) => enLangugages[item])
      .join(', ')
      ?.toString(),
    28
  );

export const formattedValue = (val: string | null | undefined): string =>
  val?.toLowerCase() === 'y'
    ? t('Yes')
    : val?.toLowerCase() === 'n'
    ? t('No')
    : t('N/A');

export const getLLMStatus = (llmStatus, t): String => {
  if (llmStatus?.toUpperCase() === 'NA') {
    return t('SMART_CHOICE.AUDIT_SCREEN.Not Applied');
  } else {
    return llmStatus === '1' ? t('Yes') : t('No');
  }
};

export const getFlagValue = (val: string | null | undefined) =>
  val === '' ? 'n' : val;
