import { getModuleOrDefault } from '@optum-fpc-psx-mobile-apps/mapping-files-translation';
import { TFunction } from 'i18next';
import { isEmpty } from 'lodash';
import find from 'lodash/find';

import translationsEnJson from '../../../../../public/locale/en/translation.json';
import {
  Constants,
  CoverageTypesCodes,
  PseudoRollupCodeTableKeys,
  ReverseCoverageTypesCodes,
  TypeaheadChipCategoriesMapping,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { getCategoryFromChipCategoriesCount } from '../../../../common/RecentActivity/helper';
import { getFeatureFlags } from '../../../../common/Utils/getFeatureFlag';
import { getTypeaheadPseudoRollup } from '../../../../hooks/useTypeaheadPseudoRollup/useTypeaheadPseudoRollup';
import { Option } from '../../../../models/ProviderSearch';
import { TypeaheadSearchState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import {
  getECPValue,
  getEmployeeAssistanceProgramValue,
  getVirtualCareValue,
} from '../../../../utils/providerDetails.utils';
import {
  getAutoIncrementRadius,
  getSearchRadius,
} from '../../../../utils/providerSearch.utils';
import { toCapitalCase } from '../../../../utils/string.utils';
import {
  getIsOxfordAndUSP,
  getNetworkIdsForPES,
} from '../../../../utils/user.utils';
import { GetCategoryCountProps } from './categoryCountTypes';
import {
  resolveCoverageType,
  resolveIncludeSpecialityRollupCodesAndUpdateStore,
} from './searchUtils';
import {
  compareAndReturn,
  getAOEOrSpecialityById,
  getAoeCodesForEAP,
  getDntFiltersFromQueryParams,
  getEapBHProgramId,
  getPreferredFacilityFilter,
} from './utils';

export const getCorrectChipCategory = (
  vertical: string,
  typeaheadPreferenceCodes: any[],
  specialityResults: any
) => {
  const sortedRollupCodesOfClickedSuggestion = vertical
    ?.split(',')
    ?.map((i) => i.trim())
    ?.sort((a, b) => a.localeCompare(b))
    ?.join(',');

  const sortedTypeaheadPreferenceCodes = typeaheadPreferenceCodes?.map(
    (codes) => ({
      ...codes,
      rollupCodes: codes?.rollupCodes
        ?.split(',')
        ?.map((i) => i.trim())
        ?.sort()
        ?.join(),
    })
  );

  const foundCategories =
    sortedTypeaheadPreferenceCodes?.filter(
      (item) => item.rollupCodes === sortedRollupCodesOfClickedSuggestion
    ) || [];

  const uniqueChips = new Set();
  foundCategories?.forEach((obj) => {
    uniqueChips.add(obj?.chipCategory);
  });
  const isDuplicateFound = uniqueChips.size > 1;

  const isThisChipEnabled = specialityResults?.some(
    (item) =>
      item.chipEnabled &&
      TypeaheadChipCategoriesMapping[item.chipCategory] ===
        foundCategories[0]?.chipCategory
  );

  if (foundCategories?.length > 0 && !isDuplicateFound && isThisChipEnabled) {
    return foundCategories[0]?.chipCategory;
  }
  return null;
};

export const hasResultsLoadedForParallelCategoryCount =
  (parallelCategoryCountResults: {
    isNameResultsLoaded: boolean;
    isSpecialityResultsLoaded: boolean;
    nameResults: any[];
    specialityResults: any[];
  }) =>
    parallelCategoryCountResults.isNameResultsLoaded &&
    parallelCategoryCountResults.isSpecialityResultsLoaded &&
    (parallelCategoryCountResults.nameResults.length > 0 ||
      parallelCategoryCountResults.specialityResults.length > 0);

export const getSpecialtyCode = (pesKeyword, searchCategory, specialtyCodes) =>
  pesKeyword ?? Array.isArray(searchCategory)
    ? undefined
    : specialtyCodes[searchCategory?.toUpperCase()];

export const getSelectedCoverageType = (value: string) =>
  find(Constants.MOCK_CARE_CATEGORIES, {
    value,
  })?.coverageType || CoverageTypesCodes.MEDICAL;

export const getPesSearchTerm = (
  bhProgramFacilityAgg,
  includeSpecialityRollupCodes,
  search
) =>
  bhProgramFacilityAgg && !includeSpecialityRollupCodes
    ? ''
    : compareAndReturn(includeSpecialityRollupCodes, search);

export const getGenderCode = (code: string): Option => {
  const genderValue: string = toCapitalCase(code);
  return {
    label: genderValue,
    value: genderValue,
    dnt_label: genderValue,
  };
};

export const getVirtualCareCode = (t: TFunction): Option => ({
  label: t('FILTER_OPTIONS.VirtualCare.Y'),
  dnt_label: translationsEnJson.FILTER_OPTIONS.VirtualCare.Y,
  value: true,
});

export const getECPValueCode = (t: TFunction): Option[] => [
  {
    label: t('FILTER_OPTIONS.EssentialCommunityProvider.Y'),
    dnt_label: translationsEnJson.FILTER_OPTIONS.EssentialCommunityProvider.Y,
    value: true,
  },
];

export const getConditionAoeCodes = (
  condition: string | undefined,
  deepLinkMapping: any,
  aoeTranslations: any
) => {
  if (condition && deepLinkMapping) {
    const conditionIds = getDntFiltersFromQueryParams(
      condition,
      deepLinkMapping
    );
    const areaOfExpertise = getAOEOrSpecialityById(
      conditionIds,
      aoeTranslations
    );
    const conditionAoeCodes = Object.keys(aoeTranslations).filter((key) =>
      conditionIds.includes(aoeTranslations[key])
    );
    return { areaOfExpertise, conditionAoeCodes };
  }
  return { areaOfExpertise: [], conditionAoeCodes: [] };
};

export const getSearchTitle = (
  search: string | null,
  searchType: string,
  userZip: string
) => {
  if (
    searchType === Constants.SEARCH_TYPES.LOCATION &&
    search !== Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS
  ) {
    return userZip;
  }
  return search || '';
};

export const setupAoeCodesForEAP = (
  eapSpecialitySearch: boolean,
  eapBHProgramFlag: boolean,
  typeaheadAoeCodes: string[]
) => {
  if (eapSpecialitySearch && !eapBHProgramFlag) {
    const aoeCodes = getAoeCodesForEAP() || [];
    const existingCodes = new Set(typeaheadAoeCodes);
    aoeCodes.forEach((code) => {
      if (!existingCodes.has(code)) {
        typeaheadAoeCodes.push(code);
        existingCodes.add(code);
      }
    });
  }
  return;
};

export const getFilterValue = (
  typeaheadSearchStore: TypeaheadSearchState,
  name: string
) => {
  const filterValueFromStore = typeaheadSearchStore[name];
  if (!filterValueFromStore) {
    return undefined;
  }
  return filterValueFromStore;
};

export const getPcpIndicators = (
  chipValue: string,
  disablePCPIndAndANP: boolean,
  choosePCP: boolean
) => {
  const isPCPLandingPage =
    chipValue === Constants.CHIPS_CATEGORIES.PRIMARY_CARE;
  const isPcpIndicator =
    disablePCPIndAndANP && isPCPLandingPage && !choosePCP
      ? null
      : choosePCP || isPCPLandingPage;

  return { isPCPLandingPage, isPcpIndicator };
};

export const getSearchType = (isSpecialtySearch: Boolean) =>
  isSpecialtySearch
    ? Constants.SEARCH_TYPES.SPECIALTY
    : Constants.SEARCH_TYPES.NAME;

export const getNewRollupcodesFromQueryParamsAndSpecialtyCodeFromQuery = ({
  specialtyCategory,
  deepLinkMapping,
  specialtyCodes,
  specialityCodeTranslation,
}) => {
  if (specialtyCategory && deepLinkMapping && specialtyCodes) {
    const specialtyCategoryIds = getDntFiltersFromQueryParams(
      specialtyCategory,
      deepLinkMapping
    );

    const specialtyCodeFromQuery = getAOEOrSpecialityById(
      specialtyCategoryIds,
      specialityCodeTranslation
    );

    const codes = [] as any;
    specialtyCodes.forEach((item) => {
      if (specialtyCategoryIds.includes(item.id)) {
        codes.push(item.codes);
      }
    });
    const newRollupcodesFromQueryParams = codes.flat().join();
    return { newRollupcodesFromQueryParams, specialtyCodeFromQuery };
  }
  return { newRollupcodesFromQueryParams: [], specialtyCodeFromQuery: [] };
};

export const getSearchResultsPageParams = ({
  typeAheadData = {},
  chipValue,
  choosePCP,
  pseudoRollupCodeTable,
  deepLinkMapping,
  specialtyCodes,
  isGlobalSearch,
  globalSeachParams,
  setTypeaheadSearchStore,
  typeaheadSearchStore,
  currentMember,
  onlineRetailers,
  featureFlags,
  portalData,
  isSpecialtySearch,
  providerType,
  searchRadius,
  autoIncrementRadius,
  dependentSeqNbr,
  coverageType,
  sortByValue,
  isSmartChoice,
  dependentInfo,
  invokeLlmModelFlag,
  t,
}: any) => {
  const uesTranslation = getModuleOrDefault('en', 'uesSpecialityTranslations');
  const aoeTranslations = getModuleOrDefault('en', 'aoeTranslations');
  const specialityCodeTranslation = getModuleOrDefault(
    'en',
    'sourceSystemSpecialityTranslations'
  );
  const {
    searchType = '',
    search = '',
    combinedIncludeSpecialityRollupCodes = '',
    pseudoVerticals = '',
    suggestion = '',
    searchTerm = '',
    keyword = '',
    coverageTypes = '',
    isMixedSuggestions,
    isUserTextSearch,
    bhProgramFacilityAgg,
    condition,
    aoeCodes: typeaheadAoeCodes = [],
    acceptsEap,
    userZip,
    specialtyCategory,
    category: searchCategory = '',
    pesKeyword,
    linkName,
    searchMethod,
    medicalSpeciality,
  } = typeAheadData;

  const [
    enableBHAoeTypeahead,
    eapBHProgramFlag,
    disablePCPIndAndANP,
    enableBHVirtualcare,
    oxfordPlnFlag,
  ] = getFeatureFlags(featureFlags, [
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_AOE_TYPEAHEAD,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_EAP_FILTER_USING_BH_PROGRAM,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_PCP_IND_AND_ANP,
    ConstantsLagoon.FEATURE_FLAGS.BEHAVIORAL_HEALTH_VIRTUAL_CARE,
    ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN,
  ]);

  const { isPCPLandingPage, isPcpIndicator } = getPcpIndicators(
    chipValue,
    disablePCPIndAndANP,
    choosePCP
  );
  const acceptingNewPatients = isPcpIndicator ? true : undefined;

  const { isOXMember } = getIsOxfordAndUSP(
    currentMember,
    coverageType,
    featureFlags
  );

  const pseudoVerticalsDetails = getTypeaheadPseudoRollup(
    pseudoRollupCodeTable,
    pseudoVerticals,
    suggestion,
    isOXMember,
    oxfordPlnFlag
  );

  const preferredFacilityFilter = getPreferredFacilityFilter(
    pseudoVerticals,
    pseudoVerticalsDetails
  );

  const { conditionAoeCodes } = getConditionAoeCodes(
    condition,
    deepLinkMapping,
    aoeTranslations
  );

  const searchTitle: string = getSearchTitle(search, searchType, userZip);
  const virtualCareValue = getVirtualCareValue(
    pseudoVerticalsDetails,
    PseudoRollupCodeTableKeys.FILTER_NAME
  );

  const ecpValue = getECPValue(
    pseudoVerticalsDetails,
    PseudoRollupCodeTableKeys.FILTER_NAME
  );

  const eapSpecialitySearch = getEmployeeAssistanceProgramValue(
    pseudoVerticalsDetails,
    PseudoRollupCodeTableKeys.FILTER_NAME
  );

  setupAoeCodesForEAP(eapSpecialitySearch, eapBHProgramFlag, typeaheadAoeCodes);

  const { newRollupcodesFromQueryParams, specialtyCodeFromQuery } =
    getNewRollupcodesFromQueryParamsAndSpecialtyCodeFromQuery({
      specialtyCategory,
      deepLinkMapping,
      specialtyCodes,
      specialityCodeTranslation,
    });

  // TODO: getSpecialtyCode is broken, to be fixed
  const specialtyCode: string = getSpecialtyCode(
    pesKeyword,
    searchCategory,
    specialtyCodes
  );

  const includeSpecialityRollupCodesProps =
    resolveIncludeSpecialityRollupCodesAndUpdateStore(
      isGlobalSearch,
      globalSeachParams,
      combinedIncludeSpecialityRollupCodes,
      setTypeaheadSearchStore,
      typeaheadSearchStore
    );

  const includeSpecialityRollupCodes =
    (newRollupcodesFromQueryParams.length
      ? newRollupcodesFromQueryParams
      : specialtyCode ?? includeSpecialityRollupCodesProps) || '';

  // Process pre-select EAP filter based on portal config or EAP pseudo code search or therapy drill down
  const preSelectEapFilter = !!(
    portalData?.portalConfig?.preSelectEapFilter ||
    acceptsEap ||
    eapSpecialitySearch
  );

  const preferredFacilities = preferredFacilityFilter?.length
    ? preferredFacilityFilter
    : undefined;

  const pseudoRollupCode = !!pseudoVerticalsDetails?.isPseudoRollupSearch;

  const gender = getFilterValue(typeAheadData, 'gender');
  const virtualCare =
    virtualCareValue && enableBHVirtualcare ? virtualCareValue : false;
  const commonParams = {
    isUserTextSearch,
    searchType,
    keyword,
    onlineRetailers,
    pseudoVerticalsDetails,
    preferredFacilities,
    isExtendedSearchEnabled: true,
    shouldGetHGData: true,
    coverageType,
    acceptingNewPatients,
    pcpIndicator: isPcpIndicator,
    isPCPLandingPage,
    useNewAgeAndGenderRollups: disablePCPIndAndANP,
    searchRadius: getSearchRadius(searchRadius),
    autoIncrementRadius: getAutoIncrementRadius(autoIncrementRadius),
    gender,
    virtualCare,
    isConditionSearch: condition && conditionAoeCodes?.length > 0,
    typeaheadAoeCodes,
    enableBHAoeTypeahead,
    preSelectEapFilter,
    acceptsEap,
    providerType,
    ...(conditionAoeCodes.length > 0 && { conditionAoeCodes }),
    ...(choosePCP && {
      reciprocityId: getNetworkIdsForPES(
        currentMember,
        coverageType,
        featureFlags
      ),
    }),
    ...(isEmpty(providerType) && { typeaheadSearch: searchTitle }),
    ...(dependentSeqNbr && {
      memberDemographics: {
        gender: dependentInfo?.demographics.gender,
        dateOfBirth: dependentInfo?.demographics.dateOfBirth,
      },
    }),
    ...(ecpValue && {
      essentialCommunityProvider: ecpValue,
    }),
  };

  const categoryCountRequestParams: GetCategoryCountProps = {
    ...commonParams,
    search,
    searchTerm,
    isMixedSuggestions,
    currentMember,
    featureFlags,
    pseudoVerticals,
    includeSpecialityRollupCodes,
    specialtySearch: isSpecialtySearch,
    uesTranslation,
    behavioralFacilityProgram: bhProgramFacilityAgg,
    pseudoRollupCode,
    coverageTypes: resolveCoverageType(coverageTypes),
  };

  const providerSearchParams = {
    ...commonParams,
    bhProgramFacilityAgg,
    search: searchTitle,
    includeSpecialityRollupCodes:
      !isUserTextSearch && includeSpecialityRollupCodes
        ? includeSpecialityRollupCodes
        : '',
  };

  const invokeLlmModel = pseudoVerticalsDetails?.isPseudoRollupSearch
    ? false
    : invokeLlmModelFlag;

  const genderCode = gender ? [getGenderCode(gender)] : [];
  const virtualCareCode = virtualCare ? getVirtualCareCode(t) : undefined;
  const essentialCommunityProviderCode = ecpValue
    ? getECPValueCode(t)
    : undefined;

  const otherParams = {
    searchType,
    preferredFacilities,
    search: searchTitle,
    includeSpecialityRollupCodes,
    acceptingNewPatients,
    isUserTextSearch,
    isMixedSuggestions,
    gender,
    virtualCare,
    acceptsEap,
    bhProgramFacilityAgg,
    specialtyCodeFromQuery,
    pseudoVerticals,
    condition,
    specialtyCategory,
    typeaheadAoeCodes,
    preSelectEapFilter,
    userZip,
    category: searchCategory,
    pesKeyword,
    combinedIncludeSpecialityRollupCodes,
    searchTerm,
    linkName,
    searchMethod,
    medicalSpeciality,
    specialtyCode,
    pseudoRollupCode,
    pseudoVerticalsDetails,
    coverageTypes: resolveCoverageType(coverageTypes),
    ...(ecpValue && {
      essentialCommunityProvider: ecpValue,
    }),
    sortBy: sortByValue,
    ...(isSmartChoice && {
      recommendationPreferences: Constants.RECOMMENDATION_PREFERENCES,
      isProviderRecommendationsEnabled: sortByValue === Constants.RECOMMENDED_PROVIDERS_SEARCH_SORT_BY,
    }),
    invokeLlmModel,
    genderCode,
    virtualCareCode,
    essentialCommunityProviderCode,
  };

  return {
    categoryCountRequestParams,
    providerSearchParams,
    otherParams,
  };
};

export const getRecentSearchVariables = ({
  baseParams,
  typeAheadData,
  categoriesCountResults,
  planYear,
  chipValue,
  storedLocation,
}) => {
  const { longitude, latitude, name: location } = storedLocation;

  const { otherParams } = getSearchResultsPageParams({
    ...baseParams,
    typeAheadData,
    chipValue,
  });

  const {
    search,
    searchType,
    pseudoVerticals,
    condition,
    specialtyCategory,
    typeaheadAoeCodes,
    gender,
    virtualCare,
    includeSpecialityRollupCodes,
    isUserTextSearch,
    isMixedSuggestions,
    acceptsEap,
    bhProgramFacilityAgg = '',
  } = otherParams;

  const coverageType = getSelectedCoverageType(chipValue);

  return {
    psxSearchTerm: search,
    searchType,
    pseudoVerticals,
    location,
    latitude,
    longitude,
    gender,
    virtualCare,
    pesSearchTerm: getPesSearchTerm(
      bhProgramFacilityAgg,
      includeSpecialityRollupCodes,
      search
    ),
    isUserTextSearch: compareAndReturn(isUserTextSearch, false),
    conditions: condition,
    specialtyCategories: specialtyCategory,
    aoeCodes: typeaheadAoeCodes,
    behavioralProgramId: acceptsEap ? [getEapBHProgramId()] : null,
    bhProgramFacilityAgg,
    category: getCategoryFromChipCategoriesCount(categoriesCountResults),
    coverageType:
      ReverseCoverageTypesCodes[coverageType] ||
      ReverseCoverageTypesCodes.MEDICAL,
    isMixedSearchSuggestion: compareAndReturn(isMixedSuggestions, false),
    planYear,
  };
};
