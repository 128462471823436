import find from 'lodash/find';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useCoverageType } from '../../hooks/useCoverageType';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useLagoon } from '../../hooks/useLagoon';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import {
  getCurrentMember,
  isDentalNetworkIdNotAvailable,
  isOHCNSMember,
} from '../../utils/user.utils';
import { Constants, LOB } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { CNSDisclaimersContainer } from './CNSDisclaimersContainer';
import { ENIDisclaimersContainer } from './ENIDisclaimersContainer';
import { MNRDisclaimersContainer } from './MNRDisclaimersContainer';

interface HGProps {
  showPremiumCareDisclaimer?: boolean;
  isVisionCare?: boolean;
  ratingSection?: boolean;
  isMNR?: boolean;
  isViewAllDisclaimer?: boolean;
  isLandingPage?: boolean;
  isVirtualVisit?: boolean;
}

export const DisclaimersContainer = ({
  showPremiumCareDisclaimer = false,
  isVisionCare = false,
  ratingSection = false,
  isViewAllDisclaimer = false,
  isLandingPage = false,
  isVirtualVisit = false,
}: HGProps) => {
  const { lineOfBusiness = '' } = getCurrentMember();
  const lagoonData = useLagoon(Constants.LAGOON_TABLE.UI_MESSAGING)();
  const OHCNSpolicyIds = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.STATE_SPECIFIC_DISCLAIMER,
  })?.policyIDs;

  const [showDentalChip] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.DENTAL_CHIP_ENABLED,
  ]);

  const isOHCNS = isOHCNSMember(OHCNSpolicyIds);

  const { chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
    }))
  );

  const { search, sectionType = '' } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      sectionType: state.typeaheadSearchStore.sectionType,
      search: state.typeaheadSearchStore.search,
    }))
  );

  const currentMember = getCurrentMember();
  const coverageType = useCoverageType();

  const isDentalProvidersNotAvailable = isDentalNetworkIdNotAvailable(
    chipValue,
    currentMember,
    coverageType,
    showDentalChip
  );

  if (isDentalProvidersNotAvailable) return null;

  switch (lineOfBusiness) {
    case LOB.MNR:
      return (
        <MNRDisclaimersContainer
          isLandingPage={isLandingPage}
          isViewAllDisclaimer={isViewAllDisclaimer}
          ratingSection={ratingSection}
          search={search ?? ''}
          sectionType={sectionType}
          selectedChipValue={chipValue}
        />
      );
    case LOB.CNS:
      return (
        <CNSDisclaimersContainer
          isOHCNS={isOHCNS}
          search={search}
          sectionType={sectionType}
        />
      );
    default:
      return (
        <ENIDisclaimersContainer
          isVirtualVisit={isVirtualVisit}
          isVisionCare={isVisionCare}
          ratingSection={ratingSection}
          search={search ?? ''}
          sectionType={sectionType}
          selectedChipValue={chipValue}
          showPremiumCareDisclaimer={showPremiumCareDisclaimer}
        />
      );
  }
};
