import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import {
  convertUrlParamsToObject,
  getOnlyDefinedValuesFromObject,
} from '../../common/Utils';
import {
  StoreKeys,
  TypeaheadSearchState,
  TypeaheadState,
} from './typeaheadStore';

const store = (set) => ({
  [StoreKeys.TYPEAHEAD_SEARCH_STORE]: {},
  [StoreKeys.SET_TYPEAHEAD_SEARCH_STORE]: (values: TypeaheadSearchState) =>
    set((state) => ({
      typeaheadSearchStore: { ...state.typeaheadSearchStore, ...values },
    })),
  [StoreKeys.CLEAR_TYPEAHEAD_SEARCH_STORE]: (defaultValues = {}) =>
    set({ typeaheadSearchStore: { ...defaultValues } }),
});

const typeAheadStore = persist(store, {
  name: 'typeAheadStore',
  storage: createJSONStorage(() => sessionStorage),
  merge: (
    persistedState: TypeaheadState,
    currentState: TypeaheadState
  ): TypeaheadState => {
    const urlParams = new URLSearchParams(window.location.search);
    const {
      search,
      category,
      searchType,
      pesKeyword,
      resultType,
      keyword,
      providerType,
      acceptingNewPatients,
      includeSpecialityRollupCodes,
      searchTerm,
      invokeLlmModel,
      specialtyCode,
      sectionType,
      medicalSpeciality,
      userZip,
      zipcode,
      virtualCare,
      gender,
      previous,
      isMixedSuggestions,
      searchFromTypeAhead,
      coverageTypes,
      specialtyCategory,
      condition,
      bhProgramFacilityAgg,
      initiateViewEstimate,
      carePathId,
      initiateDocasapSession,
      lob,
      suggestion,
      linkName,
      typeaheadLinkName,
      vertical,
      providerQueryTypes,
      acceptsEap,
    }: any = convertUrlParamsToObject(urlParams.entries());

    const combinedIncludeSpecialityRollupCodes = includeSpecialityRollupCodes;

    const values = getOnlyDefinedValuesFromObject({
      search,
      category,
      searchType,
      pesKeyword,
      resultType,
      keyword,
      providerType,
      acceptingNewPatients,
      includeSpecialityRollupCodes,
      searchTerm,
      invokeLlmModel,
      specialtyCode,
      sectionType,
      medicalSpeciality,
      userZip,
      zipcode,
      virtualCare,
      gender,
      previous,
      isMixedSuggestions,
      searchFromTypeAhead,
      coverageTypes,
      specialtyCategory,
      condition,
      bhProgramFacilityAgg,
      initiateViewEstimate,
      carePathId,
      initiateDocasapSession,
      lob,
      suggestion,
      linkName,
      typeaheadLinkName,
      combinedIncludeSpecialityRollupCodes,
      vertical,
      providerQueryTypes,
      acceptsEap,
    });

    return {
      ...currentState,
      ...persistedState,
      typeaheadSearchStore: {
        ...currentState?.typeaheadSearchStore,
        ...persistedState?.typeaheadSearchStore,
        ...values,
      },
    };
  },
}) as any;

export const useTypeaheadStore = create(devtools(typeAheadStore));
