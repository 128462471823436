export const SpecialtyOfferedByFacilityModal = {
  'abyss-modal-content-container': {
    width: '572px',
    '@screen < $sm': {
      width: '288px',
    },
  },
  'abyss-modal-content-title': {
    width: '540px',
    fontSize: '32.43px !important',
    fontWeight: '600 !important',
    lineHeight: '40px !important',
    '@screen < $sm': {
      width: '256px',
      fontSize: '28.83px !important',
      lineHeight: '36px !important',
    },
  },
};

export const specialtiesOffered = {
  fontWeight: '$bold',
  fontSize: '16px',
  lineHeight: '24px',
  color: '$gray8',
};

export const specialtiesOfferedTitle = {
  fontSize: '16px',
  fontWeight: '400px',
  lineHeight: '24px',
  color: '$gray8',
};
