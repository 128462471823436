import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Text } from '@abyss/web/ui/Text';

const LineOfBusinessNaming = {
  'E&I': {
    name: 'Employer and Individual',
    description:
      'Select from among the UHC family of health plans (does not include State Exhange, Medicare, or Medicaid plans)',
    icon: 'handshake',
  },
  'M&R': {
    name: 'Medicare',
    description:
      'Find Health Insurance Marketplace providers for individuals, families, and small businesses',
    icon: 'medicare_supplement',
  },
  'C&S': {
    name: 'Medicaid',
    description: 'Find UHC plans by state',
    icon: 'additional_benefits',
  },
  IFP: {
    name: 'State Exchange',
    description: 'Find UHC plans by county',
    icon: 'health_access',
  },
};

const LineOfBusinessStyledCard = styled(Card, {
  '&.abyss-card-root': {
    width: '195px',
    borderRadius: '8px',
    padding: '0px',
    marginTop: '0px !important',
  },
});

const LineOfBusinessCard = (lob: string, planSelector: boolean) => (
  <LineOfBusinessStyledCard>
    <Card.Section
      css={{
        'abyss-card-section': {
          padding: '0px',
        },
      }}
    >
      <Layout.Stack
        css={{
          'abyss-layout-stack': {
            marginLeft: '8px',
            marginRight: '8px',
            height: '253px',
            alignItems: 'start',
          },
        }}
      >
        <IconBrand
          icon={LineOfBusinessNaming[lob]?.icon}
          size="48px"
          css={{ paddingTop: '16px' }}
        />
        <Heading offset={1}>{LineOfBusinessNaming[lob]?.name}</Heading>
        <Text size={'sm'}>{LineOfBusinessNaming[lob]?.description}</Text>
      </Layout.Stack>
    </Card.Section>
    <Card.Section
      css={{
        'abyss-card-section': {
          padding: '0px',
        },
      }}
    >
      <Layout.Group
        css={{
          'abyss-layout-group': {
            marginLeft: '4px',
            height: '52px',
          },
        }}
      >
        <Link
          size={'sm'}
          href={`/select-location?lob=${encodeURIComponent(lob)}${
            planSelector ? `&planSelector=true` : ''
          }`}
        >
          Explore this plan &gt;
        </Link>
      </Layout.Group>
    </Card.Section>
  </LineOfBusinessStyledCard>
);

export const GuestLineOfBusinessPicker = () => {
  const { getSearchParams } = useRouter();
  const [searchParams] = getSearchParams();
  const isPlanSelectorFlow = searchParams?.get('planSelector') === 'true';

  return (
    <PageBody>
      <Heading textAlign="center">
        What type of plan do you want to explore?
      </Heading>

      <Flex
        direction="row"
        justify="center"
        alignItems="baseline"
        style={{ gap: '16px', marginTop: '21px' }}
      >
        {LineOfBusinessCard('E&I', isPlanSelectorFlow)}
        {LineOfBusinessCard('M&R', isPlanSelectorFlow)}
        {LineOfBusinessCard('C&S', isPlanSelectorFlow)}
        {LineOfBusinessCard('IFP', isPlanSelectorFlow)}
      </Flex>
    </PageBody>
  );
};
