import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotAvailableUnknownText } from '../../../hooks/useNotAvailableUnknownText';
import { FacilityLocationsResponse } from '../../../models/FacilityDetails';
import { ProviderLocation } from '../../../models/ProviderDetails';
import { infoIconButton, phoneOnly } from '../../ConstantsStyles';
import { popoverMobileStyle } from '../../DataCard/Miles.style';
import { handleLinkAndModalTrack } from '../../Utils/adobeTrackUtils';
import { MilesContent } from './ProviderLocationsDetails.style';

type Props = {
  selectedLocation?: ProviderLocation | FacilityLocationsResponse;
  title: string;
  content?: string;
  widths?: number;
  position?: string;
  align?: string;
  alignOffset?: number;
  locationForAnalytics?: string;
  ariaLabel: string;
};

export const PopOverWrapper = styled('div', {
  '.abyss-popover-trigger ~ div': {
    zIndex: '999 !important',
  },
});

export const Distance = ({
  selectedLocation,
  title,
  content = '',
  widths = 220,
  position = 'bottom',
  align = 'center',
  alignOffset = 0,
  locationForAnalytics,
  ariaLabel,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const mobileScreen = useMediaQuery(phoneOnly);

  useEffect(() => {
    if (isOpen) {
      const linkName: string = 'distance-popover';
      const linkLocation: string = `body:${
        locationForAnalytics ?? 'location detail distance'
      }`;
      const modalName: string = 'distance';
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpen]);

  const displayContent = () => (
    <Button
      css={infoIconButton}
      data-auto-testid="provider-location-detail-miles-Info"
      data-testid="provider-location-detail-miles-Info"
      onClick={() => setIsOpen(true)}
      variant="ghost"
    >
      <IconMaterial
        data-testid="provider-location-icon"
        icon="info"
        onClick={() => setIsOpen(true)}
        role="button"
        size={16}
        variant="outlined"
      />
    </Button>
  );

  const { providerLocations: notAvailableOrUnknownText } =
    useNotAvailableUnknownText();

  return (
    <React.Fragment>
      <Flex
        alignItems="center"
        data-auto-testid="preferred-provider-info-icon"
        data-testid="preferred-provider-info-icon"
      >
        {selectedLocation ? (
          <React.Fragment>
            <IconMaterial icon="directions" />
            {selectedLocation?.distance?.length ? (
              <MilesContent>
                {`${parseFloat(selectedLocation.distance).toFixed(1)} ${t(
                  'miles'
                )}`}
              </MilesContent>
            ) : (
              <MilesContent>{notAvailableOrUnknownText}</MilesContent>
            )}
          </React.Fragment>
        ) : null}

        {!mobileScreen ? (
          <PopOverWrapper>
            <Popover
              align={align}
              alignOffset={alignOffset}
              aria-label={ariaLabel}
              content={content}
              css={{
                'abyss-popover-trigger': {
                  display: 'flex',
                  alignItem: 'center',
                },
              }}
              data-auto-testid="provider-location-detail-miles-Info-popover"
              data-testid="provider-location-detail-miles-Info-popover"
              onOpenChange={() => {
                setIsOpen(!isOpen);
              }}
              position={position}
              tabIndex={0}
              title={<Text size="$sm">{title}</Text>}
              width={widths}
            >
              {displayContent()}
            </Popover>
          </PopOverWrapper>
        ) : (
          <React.Fragment>
            {displayContent()}
            <Drawer
              css={popoverMobileStyle}
              data-auto-testid="provider-location-detail-miles-Info-popover"
              data-testid="provider-location-detail-miles-Info-popover"
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              position="bottom"
              title={title}
              titleAlign="left"
            >
              <Drawer.Section>{content}</Drawer.Section>
            </Drawer>
          </React.Fragment>
        )}
      </Flex>
    </React.Fragment>
  );
};
