import { useForm } from '@abyss/web/hooks/useForm';
import { storage } from '@abyss/web/tools/storage';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Heading } from '@abyss/web/ui/Heading';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import React, { useContext, useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { PortalIdentifier } from '../../../common/Portal';
import { PortalContext } from '../../../context/PortalContext';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../hooks/useLagoon';
import { useOBAPI } from '../../../hooks/useOBAPI';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { modifyRulesPackageKey } from '../../../utils/user.utils';
import { Constants, LOB } from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import {
  dateFormatUTC,
  dateGroupByYearMonthDay,
} from '../../Utils/datesUtils/formatDate';
import {
  findLoggedInMember,
  isMnrMember,
} from '../../Utils/memberUtils/memberUtils';
import { MemberInfo, MemberProfile } from './memberProfile';

const { ACTIVE_MEMBER_INDEX, DEMO_MEMBER_INFO, LOGGED_IN_MEMBER_INDEX } =
  Constants.STORAGE_KEYS.SESSION;

const StyledTextInput = styled(TextInput, {
  '.abyss-text-input-label': {
    color: '$primary1',
  },
});

const StyledTextInputArea = styled(TextInputArea, {
  width: '100%',
  '.abyss-text-input-area-label': {
    color: '$primary1',
  },

  '&::placeholder': {
    color: '$gray5',
  },
});

type Props = {
  setMemberProfiles: (a: MemberInfo[]) => void;
  setMemberId?: (a: number) => void;
};

export const setProfileData = (
  result: { data: MemberProfile },
  setMemberProfiles: (a: MemberInfo[]) => void,
  setMemberState: (a: MemberInfo[]) => void,
  firstName: string,
  lastName: string,
  groupNumber: string,
  memberId: string,
  dob: string,
  id: string,
  userName: string,
  portalSource: string,
  lob: LOB,
  usePreProd: boolean,
  setMemberId: (x: string | number) => void
) => {
  if (result?.data?.memberInfo) {
    const loggedInMemberIndex = findLoggedInMember(result?.data?.memberInfo, {
      FirstName: firstName,
      LastName: lastName,
      DOB: dob,
      GroupNumber: groupNumber,
      MemberNumber: memberId,
      id,
      userName,
      portalSource,
    });
    setMemberState(result?.data?.memberInfo);
    storage.session.set(ACTIVE_MEMBER_INDEX, loggedInMemberIndex.toString());
    storage.session.set(LOGGED_IN_MEMBER_INDEX, loggedInMemberIndex);
    setMemberProfiles(
      result?.data?.memberInfo?.filter(
        (member) =>
          !!member.eligibility[0]?.memberHealthCoverage?.coverageType?.length
      )
    );
    storage.session.set(DEMO_MEMBER_INFO, {
      ...((lob === LOB.ENI || lob === LOB.CNS || lob === LOB.IFP) && {
        firstName,
        lastName,
        groupNumber,
        memberId,
        dob,
      }),
      ...(lob === LOB.MNR && {
        id,
        userName,
        portalSource,
      }),
      lob,
      usePreProd,
    });
    setMemberId(memberId);
  }
};

export const ObapiDemo = ({
  setMemberProfiles,
  setMemberId = () => {},
}: Props) => {
  const form = useForm();
  const suppressionLagoonData = useLagoon('feature-supression')();
  const includeLagoonData = useLagoon('feature-inclusion')();
  const tierBenefits = useLagoon('tier-benefit')();
  const setUIFeatureSuppressionState = useStore(
    StoreKeys.SET_UI_FEATURE_SUPPRESSION
  );
  const setUIFeatureInclusionState = useStore(
    StoreKeys.SET_UI_FEATURE_INCLUSION
  );

  const [
    requestReciprocityId,
    enableMnrCosmos,
    showIsPreEffectiveIndicator,
    extractProductCodeMarketTypeForUspNonOx,
    policyFilterToggle,
    enableCns,
    useNewReferralsIndicator,
    enableGroupNumberBasedRouting,
    enableMemberInfoCache,
  ] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.REQUEST_RECIPROCITY_ID,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MNR_COSMOS,
    ConstantsLagoon.FEATURE_FLAGS.SHOW_ISPREEFFECTIVE_INDICATOR,
    ConstantsLagoon.FEATURE_FLAGS
      .ENABLE_EXTRACT_PRODUCT_CODE_MARKET_TYPE_FOR_USP_NON_OXFORD,
    ConstantsLagoon.FEATURE_FLAGS.MEMBER_INFO_POLICY_FILTER,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CNS,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_NEW_REFERRALS_INDICATOR,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_GROUP_NUMBER_BASED_ROUTING,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MEMBER_INFO_CACHE,
  ]);

  const { USE_PRE_PROD, IS_PREPROD_USER, USE_OBAPI, ENABLE_PCP, MEMBER_ID } =
    Constants.STORAGE_KEYS.SESSION;

  const [enablePreProd, setEnablePreProd] = useState(
    storage.session.get(IS_PREPROD_USER, false)
  );
  const [useObapi, setUseObapi] = useState(
    storage.session.get(USE_OBAPI, false)
  );
  const [enablePcp, setEnablePcp] = useState(
    storage.session.get(ENABLE_PCP, false)
  );
  const [, setLob] = useSessionStorage<string | undefined>(
    Constants.STORAGE_KEYS.SESSION.LOB,
    ''
  );

  enablePreProd === null ? setEnablePreProd(() => false) : '';
  useObapi === null ? setUseObapi(() => false) : '';
  enablePcp === null ? setEnablePcp(() => false) : '';

  const { portalData } = useContext(PortalContext);

  useEffect(() => {
    storage.session.set(USE_PRE_PROD, enablePreProd);
  }, [enablePreProd]);

  const fpcPsxRedirection = useLagoon(
    Constants.LAGOON_TABLE.FPC_PSX_REDIRECTION
  )();

  const eligiblePolicyIds =
    fpcPsxRedirection[0]?.eligiblePolicyNumbers?.split(',') || [];

  const eligibleGroupIds = fpcPsxRedirection[0]?.groupNumber?.split(',') || [];

  const {
    firstName,
    lastName,
    groupNumber,
    memberId,
    dob,
    id,
    userName,
    portalSource,
    lob,
    delsysCode,
    bigNJsonString,
  } = form.getValues();

  const setMemberState = useStore(StoreKeys.SET_OBAPI_MEMBERS);
  const isUserMNRCosmosMember = enableMnrCosmos && isMnrMember(lob);

  const [, getUserProfile] = useOBAPI({
    onCompleted: (result: { data: MemberProfile }) => {
      const memberInfo: MemberInfo[] = modifyRulesPackageKey(
        result?.data?.memberInfo,
        tierBenefits
      );

      const {
        demographics,
        policyNumber,
        lineOfBusiness: memberLob,
      } = memberInfo?.[0] || {};
      const { dateOfBirth = '', name } = demographics;
      const { firstName, lastName } = name;
      const { memberId, id, userName, portalSource } = form.getValues();
      storage.cookie.set('LOB-Target', memberLob);

      const mnrMemberKey =
        result?.data?.memberInfo?.[0]?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.[0]?.coverageID.filter(
          (subcId) => subcId?.sourceSysCode === Constants.MEMBER_KEY
        );
      const mnrMemberId = mnrMemberKey?.[0]?.id;

      // setting lob in session storage when lob is changed from demoheader - to pick correct lagoon instance on LOWER ENVIRONMENTS. For higher environments, it will be picked from cookie LOB-Target which is set when user logs in via uhc.
      setLob(memberLob);
      storage.session.set(DEMO_MEMBER_INFO, {});
      setProfileData(
        { data: { memberInfo } },
        setMemberProfiles,
        setMemberState,
        firstName,
        lastName,
        policyNumber || groupNumber,
        isUserMNRCosmosMember ? mnrMemberId : memberId,
        dateGroupByYearMonthDay(dateOfBirth),
        id,
        userName,
        portalSource,
        memberLob,
        enablePreProd,
        setMemberId
      );

      setUIFeatureSuppressionState({
        suppressionLagoonData,
        memberPolicy: groupNumber,
      });
      setUIFeatureInclusionState({
        includeLagoonData,
        memberPolicy: groupNumber,
      });
    },
    onError: () => {},
  });

  const getBigN = () => {
    let bigNVars;
    if (bigNJsonString) {
      bigNVars = parseBigNJson();

      if (!bigNVars) {
        return undefined;
      }

      form.setValue('firstName', bigNVars.firstName);
      form.setValue('lastName', bigNVars.lastName);
      form.setValue('dob', dateFormatUTC(bigNVars.dob));
      form.setValue('groupNumber', bigNVars.groupNumber);
      form.setValue('memberId', bigNVars.memberId);
      form.setValue('id', bigNVars.id);
      form.setValue('userName', bigNVars.userName);
      form.setValue('portalSource', bigNVars.portalSource);
    } else {
      bigNVars = {
        ...((lob === LOB.ENI || lob === LOB.CNS || lob === LOB.IFP) && {
          firstName,
          lastName,
          groupNumber,
          memberId,
          dob: dateGroupByYearMonthDay(dob),
          delsysCode,
        }),
        ...(lob === LOB.MNR && {
          id,
          userName,
          portalSource,
        }),
      };
    }

    return bigNVars;
  };
  const handleUmrOnClick = () => {
    const bigNVars = getBigN();
    if (!bigNVars) {
      return;
    }

    let basePath = '/umr';
    if (portalData.portalIdentifier === PortalIdentifier.GEHA) {
      basePath = '/geha';
    } else if (portalData.portalIdentifier === PortalIdentifier.NV) {
      basePath = '/umrNevada';
    }
    window.location.href = `${basePath}?firstName=${bigNVars.firstName}&lastName=${bigNVars.lastName}&dob=${bigNVars.dob}&policyId=${bigNVars.groupNumber}&memberId=${bigNVars.memberId}&delsysCode=${bigNVars.delsysCode}&useOBAPI=${useObapi}&enablePreProd=${enablePreProd}&enablePcp=${enablePcp}`;
    storage.session.set(MEMBER_ID, memberId);
  };
  const handleObapiOnClick = () => {
    const bigNVars = getBigN();
    if (!bigNVars) {
      return;
    }

    getUserProfile({
      variables: {
        ...bigNVars,
        lob,
        requestReciprocityId,
        eligiblePolicyIds,
        eligibleGroupIds,
        enableGroupNumberBasedRouting,
        usePreProd: enablePreProd,
        enableMnrCosmos,
        showIsPreEffectiveIndicator,
        extractProductCodeMarketTypeForUspNonOx,
        policyFilterToggle,
        enableCns,
        useNewReferralsIndicator,
        enableMemberInfoCache,
      },
    });
    storage.session.set(IS_PREPROD_USER, enablePreProd);
    storage.session.set(MEMBER_ID, memberId);
  };

  const handleOnClick =
    portalData.portalName === 'umr' ? handleUmrOnClick : handleObapiOnClick;

  const parseBigNJson = () => {
    let bigNJson;
    try {
      bigNJson = JSON.parse(bigNJsonString);
    } catch (e) {
      alert('Invalid JSON string');
      return false;
    }

    if (lob === LOB.MNR) {
      return {
        id: bigNJson.id,
        userName: bigNJson.userName,
        portalSource: bigNJson.portalSource,
      };
    }

    return {
      firstName: bigNJson.firstName,
      lastName: bigNJson.lastName,
      dob: bigNJson.dob || bigNJson.dateOfBirth,
      groupNumber: bigNJson.groupNumber,
      memberId: bigNJson.memberId,
    };
  };

  const fillBigNJson = () => {
    const {
      firstName,
      lastName,
      groupNumber,
      memberId,
      dob,
      id,
      userName,
      portalSource,
      lob,
    } = storage.session.get(DEMO_MEMBER_INFO) || {};

    if (lob === LOB.MNR) {
      form.setValue(
        'bigNJsonString',
        `{
          "id": "${id}",
          "userName": "${userName}",
          "portalSource": "${portalSource}"
        }`
      );
    } else {
      form.setValue(
        'bigNJsonString',
        `{
          "firstName": "${firstName}",
          "lastName": "${lastName}",
          "dob": "${dob}",
          "groupNumber": "${groupNumber}",
          "memberId": "${memberId}"
}`
      );
    }
  };

  const headerName = enablePreProd ? 'OBAPI - Pre Prod Flag Enabled' : 'OBAPI';

  const jsonPlaceholder =
    lob === LOB.MNR
      ? `{
    "id": "",
    "userName": "",
    "portalSource": ""
}`
      : `{
    "firstName": "",
    "lastName": "",
    "dob": "",
    "groupNumber": "",
    "memberId": ""
}`;

  return (
    <React.Fragment>
      <Heading color="$primary1" offset={5}>
        {headerName}
      </Heading>
      <div style={{ width: '100%', padding: '6px 4px' }}>
        <ToggleSwitch
          css={{
            'abyss-toggle-switch-label': {
              color: '$primary1',
              fontWeight: 'bold',
            },
          }}
          data-testid="obapi-preprod-toggle"
          isChecked={enablePreProd}
          label="Pre Prod Toggle"
          onChange={(e: { target: { checked: any } }) =>
            setEnablePreProd(e.target.checked)
          }
        />
      </div>
      {portalData?.portalName === 'umr' ? (
        <div style={{ width: '100%', padding: '6px 4px' }}>
          <ToggleSwitch
            css={{
              'abyss-toggle-switch-label': {
                color: '$primary1',
                fontWeight: 'bold',
              },
            }}
            data-testid="use-obapi-toggle"
            isChecked={useObapi}
            label="Use OBAPI"
            onChange={(e: { target: { checked: any } }) =>
              setUseObapi(e.target.checked)
            }
          />
          <ToggleSwitch
            css={{
              'abyss-toggle-switch-label': {
                color: '$primary1',
                fontWeight: 'bold',
              },
            }}
            data-testid="enable-pcp-toggle"
            isChecked={enablePcp}
            label="Enable PCP"
            onChange={(e: { target: { checked: any } }) =>
              setEnablePcp(e.target.checked)
            }
          />
        </div>
      ) : null}
      {/* E&I */}
      {lob === LOB.ENI || lob === LOB.CNS || lob === LOB.IFP ? (
        <>
          <StyledTextInput
            data-auto-testid="obapi-firstName"
            data-testid="obapi-firstName"
            label="First Name"
            model="firstName"
            placeholder="First Name"
          />
          <StyledTextInput
            data-auto-testid="obapi-lastName"
            data-testid="obapi-lastName"
            label="Last Name"
            model="lastName"
            placeholder="Last Name"
          />
          <StyledTextInput
            data-auto-testid="obapi-dob"
            data-testid="obapi-dob"
            label="Date of birth (MM/DD/YYYY)"
            mask="##-##-####"
            model="dob"
            placeholder="Date of birth"
          />
          <StyledTextInput
            data-auto-testid="obapi-groupNumber"
            data-testid="obapi-groupNumber"
            label="Group Number"
            model="groupNumber"
            placeholder="Group Number"
          />
          <StyledTextInput
            data-auto-testid="obapi-memberId"
            data-testid="obapi-memberId"
            label="Member ID"
            model="memberId"
            placeholder="Member ID"
          />
        </>
      ) : null}
      {portalData?.portalName === 'umr' ? (
        <>
          <StyledTextInput
            data-auto-testid="obapi-delsyscode"
            data-testid="obapi-delsyscode"
            label="Delsys Code"
            model="delsysCode"
            placeholder="Delsys Code"
          />
        </>
      ) : null}
      {/* MNR */}
      {isUserMNRCosmosMember ? (
        <>
          <StyledTextInput
            data-auto-testid="obapi-id"
            data-testid="obapi-id"
            label="ID"
            model="id"
            placeholder="ID"
          />
          <StyledTextInput
            data-auto-testid="obapi-userName"
            data-testid="obapi-userName"
            label="User Name"
            model="userName"
            placeholder="User Name"
          />
          <StyledTextInput
            data-auto-testid="obapi-portalSource"
            data-testid="obapi-portalSource"
            label="Portal Source"
            model="portalSource"
            placeholder="Portal Source"
          />
        </>
      ) : null}
      {lob && !portalData?.portalName ? (
        <React.Fragment>
          <StyledTextInputArea
            data-testid="obapi-bign-json"
            label="or, BigN JSON"
            model="bigNJsonString"
            placeholder={jsonPlaceholder}
            rows={7}
          />
          <div style={{ width: '100%' }}>
            <Button
              css={{ marginTop: '$sm', marginBottom: '$md', display: 'block' }}
              data-testid="obapi-fill-bign-json"
              onClick={fillBigNJson}
              size="$sm"
            >
              Fill with current BigN
            </Button>
          </div>
        </React.Fragment>
      ) : null}
      <Button
        css={{ marginTop: '$sm', marginBottom: '$md' }}
        data-auto-testid="obapi-submit-button"
        data-testid="obapi-submit-button"
        onClick={handleOnClick}
        variant="outline"
      >
        Submit
      </Button>
    </React.Fragment>
  );
};
