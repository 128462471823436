export const ConstantsLagoon = {
  CONTENT_FILTERS: {
    INFO_MODAL_CONTENT: 'RESULT_SORT_INFO_MODAL_CONTENT',
    PREMIUM_CARE_PHYSICIAN_CONTENT: 'PREMIUM_CARE_PHYSICIAN_CONTENT',
    PREMIUM_CARE_POPOVER_URL: 'PREMIUM_CARE_POPOVER_URL',
    PREFERRED_FACILITY_CONTENT: 'PREFERRED_FACILITY_CONTENT',
    BEST_MATCH_MEDICAL_PROVIDER: 'BEST_MATCH_MEDICAL_PROVIDER',
    BEST_MATCH_MEDICAL_PROVIDER_GROUP: 'BEST_MATCH_MEDICAL_PROVIDER_GROUP',
    BEST_MATCH_MEDICAL_FACILITY: 'BEST_MATCH_MEDICAL_FACILITY',
    BEST_MATCH_BH_PROVIDER: 'BEST_MATCH_BH_PROVIDER',
    BEST_MATCH_BH_PROVIDER_GROUP: 'BEST_MATCH_BH_PROVIDER_GROUP',
    BEST_MATCH_BH_FACILITY: 'BEST_MATCH_BH_FACILITY',
    BEST_MATCH_DENTAL_PROVIDER: 'BEST_MATCH_DENTAL_PROVIDER',
    BEST_MATCH_POPOVER_CONTENT_VISION_PROVIDER:
      'BEST_MATCH_POPOVER_CONTENT_VISION_PROVIDER',
    BEST_MATCH_POPOVER_CONTENT_VISION_FACILITY:
      'BEST_MATCH_POPOVER_CONTENT_VISION_FACILITY',
    BEST_MATCH_POPOVER_CONTENT: 'BEST_MATCH_POPOVER_CONTENT',
    COST_TAB_IN_NETWORK_TEXT: 'COST_TAB_IN_NETWORK_TEXT',
    COST_TAB_COST_ESTIMATES_TEXT: 'COST_TAB_COST_ESTIMATES_TEXT',
    COST_TAB_COST_ESTIMATES_HEADING: 'COST_TAB_COST_ESTIMATES_HEADING',
    COST_TAB_COST_ESTIMATES_NOTIFICATION:
      'COST_TAB_COST_ESTIMATES_NOTIFICATION',
    COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_HEADING:
      'COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_HEADING',
    COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_TEXT:
      'COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_TEXT',
    COST_TAB_IN_NETWORK_HEADING: 'COST_TAB_IN_NETWORK_HEADING',
    CARE_TYPE_TITLE: 'CARE_TYPE_TITLE',
    OON_MESSAGE: 'OON_MESSAGE',
    SELECT_CARE_TYPE_TITLE: 'SELECT_CARE_TYPE_TITLE',
    PHYSICIAN_DIRECTORY_DIRECTORY_CONTENT:
      'PHYSICIAN_DIRECTORY_DIRECTORY_CONTENT',
    PHYSICIAN_DIRECTORY_LOCATION_CONTENT:
      'PHYSICIAN_DIRECTORY_LOCATION_CONTENT',
    PHYSICIAN_DIRECTORY_SEARCH_INPUT_LABEL:
      'PHYSICIAN_DIRECTORY_SEARCH_INPUT_LABEL',
    PHYSICIAN_DIRECTORY_SEARCH_ERROR: 'PHYSICIAN_DIRECTORY_SEARCH_ERROR',
    PHYSICIAN_DIRECTORY_SEARCH_INPUT_BY_NAME_LABEL:
      'PHYSICIAN_DIRECTORY_SEARCH_INPUT_BY_NAME_LABEL',
    PHYSICIAN_DIRECTORY_SEARCH_INPUT_BY_SPECIALITY_LABEL:
      'PHYSICIAN_DIRECTORY_SEARCH_INPUT_BY_SPECIALITY_LABEL',
    PHYSICIAN_DIRECTORY_SEARCH_INPUT_OPTIONS:
      'PHYSICIAN_DIRECTORY_SEARCH_INPUT_OPTIONS',
    PHYSICIAN_DIRECTORY_HOSPITAL_COPY_CONTENT:
      'PHYSICIAN_DIRECTORY_HOSPITAL_COPY_CONTENT',
    PHYSICIAN_DIRECTORY_PROVIDERGROUP_COPY_CONTENT:
      'PHYSICIAN_DIRECTORY_PROVIDERGROUP_COPY_CONTENT',
    PHYSICIAN_DIRECTORY_HOSPITAL_NO_PROVIDER:
      'PHYSICIAN_DIRECTORY_HOSPITAL_NO_PROVIDER',
    PHYSICIAN_DIRECTORY_PROVIDERGROUP_NO_PROVIDER:
      'PHYSICIAN_DIRECTORY_PROVIDERGROUP_NO_PROVIDER',
    NONTIER1_LOCATION_LABEL: 'NONTIER1_LOCATION_LABEL',
    ACCEPTS_MEDICAID_POPOVER_CONTENT: 'ACCEPTS_MEDICAID_POPOVER_CONTENT',
    PRE_EFFECTIVE_ONLINE_SCHEDULE_POPOVER_CONTENT:
      'PRE_EFFECTIVE_ONLINE_SCHEDULE_POPOVER_CONTENT',
    DETAILS_WIDGET_NO_DETAILS_TITLE: 'DETAILS_WIDGET_NO_DETAILS_TITLE',
    DETAILS_WIDGET_NO_DETAILS_SUB_TITLE: 'DETAILS_WIDGET_NO_DETAILS_SUB_TITLE',
    DETAILS_WIDGET_NO_DETAILS_INFO: 'DETAILS_WIDGET_NO_DETAILS_INFO',
    UPCOMING_AVAILABILITY_FILTER_DESCRIPTION:
      'UPCOMING_AVAILABILITY_FILTER_DESCRIPTION',
    BOARD_CERTIFIED_INFO_ICON: 'BOARD_CERTIFIED_INFO_ICON',
    BOARD_CERTIFIED_INFO_TEXT: 'BOARD_CERTIFIED_INFO_TEXT',
    CULTURAL_COMPETENCY_TOOLTIP_INFO_ICON:
      'CULTURAL_COMPETENCY_TOOLTIP_INFO_ICON',
    HCBS_TOOLTIP_INFO_ICON: 'HCBS_TOOLTIP_INFO_ICON',
  },
  FEATURE_FLAGS: {
    REMOVE_COOKIES: 'REMOVE_COOKIES',
    ENABLE_GROUP_NUMBER_BASED_ROUTING: 'ENABLE_GROUP_NUMBER_BASED_ROUTING',
    ENABLE_BROWSE_FOR_CARE: 'ENABLE_BROWSE_FOR_CARE',
    ENABLE_COMMON_PROVIDER_SEARCHES: 'ENABLE_COMMON_PROVIDER_SEARCHES',
    AUTOCOMPLETE_PARALLEL_SEARCHFLOW: 'AUTOCOMPLETE_PARALLEL_SEARCHFLOW',
    BEHAVIORAL_FACILITY_PROGRAMS_FILTER: 'BEHAVIORAL_FACILITY_PROGRAMS_FILTER',
    DEMO_FLAG: 'DEMO',
    REQUEST_RECIPROCITY_ID: 'REQUEST_RECIPROCITY_ID',
    EDUCATIONAL_CONTENT_ENABLED: 'EDUCATIONAL_CONTENT_ENABLED',
    COST_ENABLED: 'COST_ENABLED',
    BH_FACILITIES_PROGRAMIDS_SECTIONS: 'BH_FACILITIES_PROGRAMIDS_SECTIONS',
    COST_TAB_ENABLED: 'COST_TAB',
    ENABLE_CEX_GET_FULL_COST_ESTIMATE: 'ENABLE_CEX_GET_FULL_COST_ESTIMATE',
    ENABLE_PRE_PROD: 'ENABLE_PRE_PROD_ENV',
    PRINT_SHARE_DIRECTORY: 'PRINT_SHARE_DIRECTORY',
    NOTIFICATIONS: 'NOTIFICATIONS',
    PREEFFECTIVE_COST_TAB_DISABLED: 'PREEFFECTIVE_COST_TAB_DISABLED',
    SHARE_RECIPIENT_DISABLE: 'SHARE_RECIPIENT_DISABLE',
    THINGS_TO_KNOW_DECEMBER_RLS: 'THINGS_TO_KNOW_DECEMBER_RLS',
    ENABLE_SEARCH_LOCATION: 'ENABLE_SEARCH_LOCATION',
    AUTOCOMPLETE_CAPTURE_RESULTS: 'AUTOCOMPLETE_CAPTURE_RESULTS',
    UPCOMING_AVAILABILITY: 'UPCOMING_AVAILABILITY',
    ENABLE_COUNTY_SEARCH_ALERT: 'ENABLE_COUNTY_SEARCH_ALERT',
    SHOW_MAP_COLLAPSE: 'SHOW_MAP_COLLAPSE',
    REFERRAL_REQUIRED_BANNER: 'REFERRAL_REQUIRED_BANNER',
    VIRTUAL_CARE_INDICATOR_ENABLED: 'VIRTUAL_CARE_INDICATOR_ENABLED',
    CARE_CATEGORY_CHIPS_TO_TABS_ENABLED: 'CARE_CATEGORY_CHIPS_TO_TABS_ENABLED',
    VISION_CHIP_ENABLED: 'VISION_CHIP_ENABLED',
    DENTAL_CHIP_ENABLED: 'DENTAL_CHIP_ENABLED',
    USE_MEDICAL_NEW_ROLLUP_CODES: 'USE_MEDICAL_NEW_ROLLUP_CODES',
    USE_BH_NEW_ROLLUP_CODES: 'USE_BH_NEW_ROLLUP_CODES',
    USE_DENTAL_NEW_ROLLUP_CODES: 'USE_DENTAL_NEW_ROLLUP_CODES',
    USE_UES_ROLLUP_CODE_FOR_NAME_SEARCH: 'USE_UES_ROLLUP_CODE_FOR_NAME_SEARCH',
    USE_VISION_NEW_ROLLUP_CODES: 'USE_VISION_NEW_ROLLUP_CODES',
    ENABLE_TIERED2_PLAN_FILTER: 'ENABLE_TIERED2_PLAN_FILTER',
    DISABLE_ACO_FILTER: 'DISABLE_ACO_FILTER',
    DISABLE_PREFERRED_FACILITY_FILTER: 'DISABLE_PREFERRED_FACILITY_FILTER',
    ENABLE_VIRTUAL_CARE_BH_FILTER: 'ENABLE_VIRTUAL_CARE_BH_FILTER',
    VISION_WEEKEND_EVENINGS_FILTER_ENABLED:
      'VISION_WEEKEND_EVENINGS_FILTER_ENABLED',
    VISION_RECENT_VISIT: 'VISION_RECENT_VISIT',
    EAP_CODE: 'EAP_CODE',
    TIERED_BENEFITS_ENABLED: 'TIERED_BENEFITS_ENABLED',
    FEATURE_SUPPRESSION: 'FEATURE_SUPPRESSION',
    DENTAL_INTEGRATED_VIEW: 'DENTAL_INTEGRATED_VIEW',
    PREMIUM_CARE: 'PREMIUM_CARE',
    CUSTOM_MESSAGE_NOTIFICATION: 'CUSTOM_MESSAGE_NOTIFICATION',
    ENABLE_LLM: 'ENABLE_LLM',
    TIER_ONE_FILTER_COMPARE: 'TIER_ONE_FILTER_COMPARE',
    PATIENT_REVIEWS_DYNAMIC_HEADER: 'PATIENT_REVIEWS_DYNAMIC_HEADER',
    PREFERRED_FACILITY_IDENTIFICATION: 'PREFERRED_FACILITY_IDENTIFICATION',
    ENABLE_TIER_TWO_PROVIDER: 'ENABLE_TIER_TWO_PROVIDER',
    TRACK_BACK_BUTTON_ENABLED: 'TRACK_BACK_BUTTON_ENABLED',
    ENABLE_INCLUDE_SPEC_CODE_CHANGES: 'ENABLE_INCLUDE_SPEC_CODE_CHANGES',
    ENABLE_PCP_ALERT_BANNER: 'ENABLE_PCP_ALERT_BANNER',
    ENABLE_EXPRESS_ACCESS_PROVIDER_FILTER:
      'ENABLE_EXPRESS_ACCESS_PROVIDER_FILTER',
    ENABLE_TYPEAHEAD_WILDCARD_SEARCH: 'ENABLE_TYPEAHEAD_WILDCARD_SEARCH',
    ENABLE_EMPLOYEE_ASSISTANCE_PROGRAM_ACCEPTED_FILTER:
      'ENABLE_EMPLOYEE_ASSISTANCE_PROGRAM_ACCEPTED_FILTER',
    INCLUDE_PROVIDER_GROUP_NAME: 'INCLUDE_PROVIDER_GROUP_NAME',
    GET_PROVIDER_RATINGS: 'PROVIDER_RATINGS',
    GREEN_DIAMOND_IDENTIFICATION: 'GREEN_DIAMOND_IDENTIFICATION',
    GREEN_DIAMOND_PRINT_PDF_IDENTIFICATION:
      'GREEN_DIAMOND_PRINT_PDF_IDENTIFICATION',
    CLEANUP_PROVIDER_CALLS: 'CLEANUP_PROVIDER_CALLS',
    ENABLE_PLAN_SELECTION_V2: 'ENABLE_PLAN_SELECTION_V2',
    BEHAVIORAL_HEALTH_VIRTUAL_CARE: 'BEHAVIORAL_HEALTH_VIRTUAL_CARE',
    ENABLE_LIST_VIEW_MAP_ENHANCEMENTS: 'ENABLE_LIST_VIEW_MAP_ENHANCEMENTS',
    ENABLE_FILTER_AND_SORT_CTA_MAP_VIEW: 'ENABLE_FILTER_AND_SORT_CTA_MAP_VIEW',
    USP_TIER1_ENABLE: 'USP_TIER1_ENABLE',
    USP_ENABLE: 'USP_ENABLE',
    UNET_NONTIER1_LOCATION: 'UNET_NONTIER1_LOCATION',
    ENABLE_COST_ESTIMATES_NOTIFICATION: 'ENABLE_COST_ESTIMATES_NOTIFICATION',
    ENABLE_ONPREM_MAPBOX: 'ENABLE_ONPREM_MAPBOX',
    ENABLE_MNR_COSMOS: 'ENABLE_MNR_COSMOS',
    ENABLE_CNS: 'ENABLE_CNS',
    ENABLE_IFP: 'ENABLE_IFP',
    OXFORD_PLN: 'OXFORD_PLN',
    DISABLE_TIMESTAMP: 'DISABLE_TIMESTAMP',
    DISABLE_MEDICAL_TIMESTAMP: 'DISABLE_MEDICAL_TIMESTAMP',
    ENABLE_AVAILABILITY_SECTION: 'ENABLE_AVAILABILITY_SECTION',
    SHOW_ISPREEFFECTIVE_INDICATOR: 'SHOW_ISPREEFFECTIVE_INDICATOR',
    SHOW_ACCEPTS_MEDICAID: 'SHOW_ACCEPTS_MEDICAID',
    DISPLAY_FUTURE_TERMINATION_DATE: 'DISPLAY_FUTURE_TERMINATION_DATE',
    ENABLE_DISTANCE_FILTER_SLIDER: 'ENABLE_DISTANCE_FILTER_SLIDER',
    ENABLE_MAP_LOCATION_PIN_HOVER: 'ENABLE_MAP_LOCATION_PIN_HOVER',
    IS_SUPPRESS_PREEFFECTIVE: 'IS_SUPPRESS_PREEFFECTIVE',
    FUTURE_TERMINATION_DEMO: 'FUTURE_TERMINATION_DEMO',
    SHOW_NETWORK_AFFILIATION: 'SHOW_NETWORK_AFFILIATION',
    MEMBER_INFO_POLICY_FILTER: 'MEMBER_INFO_POLICY_FILTER',
    ENABLE_BH_FACILITIES_PROGRAM_SEARCH: 'ENABLE_BH_FACILITIES_PROGRAM_SEARCH',
    FEATURE_INCLUSION: 'FEATURE_INCLUSION',
    ENABLE_BH_AOE_TYPEAHEAD: 'ENABLE_BH_AOE_TYPEAHEAD',
    ENABLE_EAP_FILTER_USING_BH_PROGRAM: 'ENABLE_EAP_FILTER_USING_BH_PROGRAM',
    SHOW_DEPENDENT: 'SHOW_DEPENDENT',
    SHOW_FUTURE_PCP: 'SHOW_FUTURE_PCP',
    SHOW_HCBS_TYPEAHEAD_SUGGESTION: 'SHOW_HCBS_TYPEAHEAD_SUGGESTION',
    ENABLE_SCHEDULE_OPTION: 'ENABLE_SCHEDULE_OPTION',
    ENABLE_SCHEDULING_FOR_DEPENDENT: 'ENABLE_SCHEDULING_FOR_DEPENDENT',
    ONLINE_SCHEDULING: 'ONLINE_SCHEDULING',
    PSX_CTA_DISPLAY_LOGIC: 'PSX_CTA_DISPLAY_LOGIC',
    ENABLE_FIND_ELIGIBLE_PCP_BANNER: 'ENABLE_FIND_ELIGIBLE_PCP_BANNER',
    ENABLE_L2_CULTURAL_COMPETENCIES: 'ENABLE_L2_CULTURAL_COMPETENCIES',
    ENABLE_MOCK_PROVIDER_IDS_FOR_PRE_EFFECTIVE:
      'ENABLE_MOCK_PROVIDER_IDS_FOR_PRE_EFFECTIVE',
    ENABLE_TIER_ONE_MOCK_PROVIDER_IDS: 'ENABLE_TIER_ONE_MOCK_PROVIDER_IDS',
    ENABLE_EXPANDED_SEARCH_RADIUS_BANNER:
      'ENABLE_EXPANDED_SEARCH_RADIUS_BANNER',
    UPCOMING_AVAILABILITY_FOR_MEDICAL: 'UPCOMING_AVAILABILITY_FOR_MEDICAL',
    UPCOMING_AVAILABILITY_FOR_BH: 'UPCOMING_AVAILABILITY_FOR_BH',
    RACE_ETHNICITY: 'RACE_ETHNICITY',
    ENABLE_EXPANDED_SEARCH_RADIUS: 'ENABLE_EXPANDED_SEARCH_RADIUS',
    ENABLE_NAVIGATE_TO_CORRECT_CATEGORY: 'ENABLE_NAVIGATE_TO_CORRECT_CATEGORY',
    ENABLE_CULTURAL_COMPETENCY_TRAINING_SECTION:
      'ENABLE_CULTURAL_COMPETENCY_TRAINING_SECTION',
    ENABLE_CULTURAL_COMPETENCY_CODES_FILTER:
      'ENABLE_CULTURAL_COMPETENCY_CODES_FILTER',
    DISPLAY_IN_NETWORK_BADGE_FUTURE_START_DATE:
      'DISPLAY_IN_NETWORK_BADGE_FUTURE_START_DATE',
    ENABLE_MARKET_PLACE: 'ENABLE_MARKET_PLACE',
    ENABLE_EDIT_PCP_UPDATED_DESIGN: 'ENABLE_EDIT_PCP_UPDATED_DESIGN',
    DISABLE_LINK_WHEN_NO_DATA_MS: 'DISABLE_LINK_WHEN_NO_DATA_MS',
    ENABLE_TIMEMACHINE_TESTING: 'ENABLE_TIMEMACHINE_TESTING',
    ENABLE_TIMEZONE_OFFSET: 'ENABLE_TIMEZONE_OFFSET',
    ENABLE_MIXED_RESULT_PAGE_UPDATED_DESIGN:
      'ENABLE_MIXED_RESULT_PAGE_UPDATED_DESIGN',
    DISABLE_PCP_IND_AND_ANP: 'DISABLE_PCP_IND_AND_ANP',
    ENABLE_RETAIN_STORE_VALUES_IN_SESSION_STORAGE:
      'ENABLE_RETAIN_STORE_VALUES_IN_SESSION_STORAGE',
    ENABLE_REMOVE_SEARCH_PARAMS_IN_URL: 'ENABLE_REMOVE_SEARCH_PARAMS_IN_URL',
    ENABLE_TIMESTAMP_CONTENT: 'ENABLE_TIMESTAMP_CONTENT',
    ENABLE_FREQUENT_SERVICES_PROVIDED: 'ENABLE_FREQUENT_SERVICES_PROVIDED',
    ENABLE_PROVIDER_EXPERIENCE_SECTION: 'ENABLE_PROVIDER_EXPERIENCE_SECTION',
    ENABLE_UES_SUGGESTION_MATCH: 'ENABLE_UES_SUGGESTION_MATCH',
    SHOW_PCP_LAG_TIME_BANNER: 'SHOW_PCP_LAG_TIME_BANNER',
    SHOW_PCP_LAG_TIME_SUCCESS: 'SHOW_PCP_LAG_TIME_SUCCESS',
    ENABLE_SUGGEST_AN_EDIT_FORM: 'ENABLE_SUGGEST_AN_EDIT_FORM',
    ENABLE_BOARD_CERTIFIED_PROVIDERS: 'ENABLE_BOARD_CERTIFIED_PROVIDERS',
    LIMITED_ACCESS_TO_PROVIDER_BANNER_AND_SCHEDULE:
      'LIMITED_ACCESS_TO_PROVIDER_BANNER_AND_SCHEDULE',
    ENABLE_ALL_FACILITY_ACTIVE_CHECK: 'ENABLE_ALL_FACILITY_ACTIVE_CHECK',
    DISABLE_MEMBER_SELECTOR: 'DISABLE_MEMBER_SELECTOR',
    ENABLE_EXTRACT_PRODUCT_CODE_MARKET_TYPE_FOR_USP_NON_OXFORD:
      'ENABLE_EXTRACT_PRODUCT_CODE_MARKET_TYPE_FOR_USP_NON_OXFORD',
    ENABLE_RETRIVE_TYPEAHEAD_DATA: 'ENABLE_RETRIVE_TYPEAHEAD_DATA',
    ENABLE_RETRIVE_TYPEAHEAD_DATA_MAP_VIEW_PAGE:
      'ENABLE_RETRIVE_TYPEAHEAD_DATA_MAP_VIEW_PAGE',
    ENABLE_SEARCH_RESULTS_V2: 'ENABLE_SEARCH_RESULTS_V2',
    ENABLE_UPDATED_TIME_STAMP_CONTENT: 'ENABLE_UPDATED_TIME_STAMP_CONTENT',
    ENABLE_NOT_AVAILABLE_UNKNOWN_TEXT: 'ENABLE_NOT_AVAILABLE_UNKNOWN_TEXT',
    ENABLE_NEW_REFERRALS_INDICATOR: 'ENABLE_NEW_REFERRALS_INDICATOR',
    ENABLE_KELSEY_SEYBOLD_REFERRAL_BANNER:
      'ENABLE_KELSEY_SEYBOLD_REFERRAL_BANNER',
    ENABLE_LLM_TYPEAHEAD_GENERAL_SEARCH: 'ENABLE_LLM_TYPEAHEAD_GENERAL_SEARCH',
    ENABLE_CRISIS_SUPPORT_SECTION: 'ENABLE_CRISIS_SUPPORT_SECTION',
    ENABLE_NEW_FEEDBACK_FORM_DESIGN: 'ENABLE_NEW_FEEDBACK_FORM_DESIGN',
    ENABLE_BROWSE_ALL_FACILITIES_DRAWER: 'ENABLE_BROWSE_ALL_FACILITIES_DRAWER',
    ENABLE_ADVANCED_HOME_TIERING: 'ENABLE_ADVANCED_HOME_TIERING',
    ENABLE_REMOVE_DATE_FROM_PCP_CARD: 'ENABLE_REMOVE_DATE_FROM_PCP_CARD',
    ENABLE_CUSTOM_PREFFERED_PROVIDER_TOGGLE_FILTER:
      'ENABLE_CUSTOM_PREFFERED_PROVIDER_TOGGLE_FILTER',
    ENABLE_ACCOMMODATE_FILTER: 'ENABLE_ACCOMMODATES_FILTER',
    ENABLE_HCBS_FACILITY_LOCATION_TAB: 'ENABLE_HCBS_FACILITY_LOCATION_TAB',
    ENABLE_LAGOON_PCP_ERROR_MAP: 'ENABLE_LAGOON_PCP_ERROR_MAP',
    ENABLE_SCHEDULING_WITH_FACILITY_LIMITED_ACCESS_PROVIDERS:
      'ENABLE_SCHEDULING_WITH_FACILITY_LIMITED_ACCESS_PROVIDERS',
    DISABLE_ERROR_PAGE_CALL_CHAT: 'DISABLE_ERROR_PAGE_CALL_CHAT',
    DISABLE_MEDICAL_GROUP_FOR_PCP: 'DISABLE_MEDICAL_GROUP_FOR_PCP',
    ENABLE_THINGS_TO_CONSIDER_MODAL: 'ENABLE_THINGS_TO_CONSIDER_MODAL',
    ENABLE_PSX_PROVIDER_RECOMMENDATIONS: 'ENABLE_PSX_PROVIDER_RECOMMENDATIONS',
    SEDATION_DENTISTRY_SERVICES_PERFORMED:
      'SEDATION_DENTISTRY_SERVICES_PERFORMED',
    ENABLE_SPECIALTIES_LOCATION_LEVEL: 'ENABLE_SPECIALTIES_LOCATION_LEVEL',
    ENABLE_MEMBER_INFO_CACHE: 'ENABLE_MEMBER_INFO_CACHE',
    ENABLE_PLATINUM_PROVIDER_IDENTIFIER: 'ENABLE_PLATINUM_PROVIDER_IDENTIFIER',
    CONFIRM_PCP_LOCATION: 'CONFIRM_PCP_LOCATION',
    DISABLE_EAP_FROM_DETAILS_PAGE: 'DISABLE_EAP_FROM_DETAILS_PAGE',
    SHOW_PREMIUM_CARE_CHIP_FILTER: 'SHOW_PREMIUM_CARE_CHIP_FILTER',
    ENABLE_MULTIPLE_BADGE_DATA_CARD_VIEW:
      'ENABLE_MULTIPLE_BADGE_DATA_CARD_VIEW',
    ENABLE_AOE_MEDICAL: 'ENABLE_AOE_MEDICAL',
    ENABLE_TIER1_FROM_MEMBER_RPK: 'ENABLE_TIER1_FROM_MEMBER_RPK',
    ENABLE_SMART_CHOICE_UPDATED_PROVIDER_INFO:
      'ENABLE_SMART_CHOICE_UPDATED_PROVIDER_INFO',
    ENABLE_LLM_MEDICAL_SPECIALISTS: 'ENABLE_LLM_MEDICAL_SPECIALISTS',
    ENABLE_VIEW_MORE_SPECIALTIES_IN_FACILITY_LOCATION_TAB:
      'ENABLE_VIEW_MORE_SPECIALTIES_IN_FACILITY_LOCATION_TAB',
    ENABLE_BH_VIRTUAL_CARE_FILTER_V2: 'ENABLE_BH_VIRTUAL_CARE_FILTER_V2',
    ENABLE_COOKIE_VALIDATION_INTERVAL: 'ENABLE_COOKIE_VALIDATION_INTERVAL',
    DISABLE_MEMBER_SELECTION: 'DISABLE_MEMBER_SELECTION',
    ENABLE_VIRTUAL_VISIT: 'ENABLE_VIRTUAL_VISIT',
  },
  MEDICAL_SPECIALISTS: {
    HOW_TO_GET_REFERRAL_CARD: 'HOW_TO_GET_REFERRAL',
    TIER_ONE_BENEFITS_ONE: 'TIER_ONE_BENEFITS_ONE',
  },
  VIRTUAL_VISIT: {
    VIRTUAL_VISIT_COST: 'VIRTUAL_VISIT_COST',
    VIRTUAL_VISIT_LANDING_PAGE: 'VIRTUAL_VISIT_LANDING_PAGE',
    VIRTUAL_VISIT_LANDING_PAGE_LIST_CONTENT:
      'VIRTUAL_VISIT_LANDING_PAGE_LIST_CONTENT',
    VIRTUAL_VISIT_PROVIDERS: 'VIRTUAL_VISIT_PROVIDERS',
    VIRTUAL_VISIT_DISCLAIMER: 'VIRTUAL_VISIT_DISCLAIMER',
    VIRTUAL_VISIT_VIEW_ALL: 'VIRTUAL_VISIT_VIEW_ALL',
  },
  BEHAVIORAL_HEALTH: {
    TITLE_KEY: 'TITLE',
    CONTENT_KEY: 'CONTENT',
    TALK_TO_NURSE_CARD_INFO: 'TALK_TO_NURSE_CARD_INFO',
  },
  PRIMARY_CARE: {
    PRIMARY_CARE_PROVIDERS: 'PRIMARY_CARE_PROVIDERS',
    PRIMARY_CARE_LOCATIONS: 'PRIMARY_CARE_LOCATIONS',
    PRIMARY_CARE_CLINICS: 'PRIMARY_CARE_CLINICS',
    PRIMARY_CARE_MEDICAL_GROUPS: 'PRIMARY_CARE_MEDICAL_GROUPS',
    TIER_ONE_PROVIDER: 'TIER_ONE_PROVIDER',
    TIERED_BENEFITS: 'TIERED_BENEFITS',
    PCP_NOT_CHOSEN_ELIGIBILITY: 'PCP_NOT_CHOSEN_ELIGIBILITY',
    PCP_NOT_CHOSEN_ACCEPTANCE: 'PCP_NOT_CHOSEN_ACCEPTANCE',
    NO_PCP_MESSAGE: 'NO_PCP_MESSAGE',
    PCP_WILL_BE_ASSIGNED_SUBSCRIBER: 'PCP_WILL_BE_ASSIGNED_SUBSCRIBER',
    PCP_WILL_BE_ASSIGNED_DEPENDENT: 'PCP_WILL_BE_ASSIGNED_DEPENDENT',
    CHOOSE_PCP_BY: 'CHOOSE_PCP_BY',
    CHOOSE_PCP_SOON: 'CHOOSE_PCP_SOON',
    PCP_NOT_REQUIRED_INFO: 'PCP_NOT_REQUIRED_INFO',
    CHOOSE_PCP_BUTTON: 'CHOOSE_PCP_BUTTON',
  },
  SHARE_THIS_PROVIDER: {
    SHARE_THIS_PROVIDER_MESSAGE: 'SHARE_THIS_PROVIDER_MESSAGE',
  },
  VALID_EMAIL_ADDRESS: {
    VALID_EMAIL_ADDRESS_MESSAGE: 'VALID_EMAIL_ADDRESS_MESSAGE',
  },
  SEARCH_LABEL: 'SEARCH_LABEL',
  LOCATION_LABEL: 'LOCATION_LABEL',
  SUGGESTED_PROVIDERS: {
    SUGGESTED_PROVIDERS_MESSAGE: 'SUGGESTED_PROVIDERS_MESSAGE',
  },
  HEALTHGRADES: {
    MODAL_DISCLAIMER_SHORT: 'HEALTH_GRADES_DISCLAIMER_CONTENT',
    MODAL_DISCLAIMER_FULL: 'HEALTH_GRADES_DISCLAIMER_POPUP',
    DISCLAIMER_IN_PAGE: 'HEALTH_GRADES_IN_PAGE',
    PRINT_VIEW_CONTENT: 'HEALTH_GRADES_PRINT_VIEW_CONTENT',
    CNS_DISCLAIMER_FOR_NOT_INCLUSION_STATES:
      'CNS_DISCLAIMER_FOR_NOT_INCLUSION_STATES',
  },
  DENTAL: {
    TITLE_KEY: 'TITLE',
  },
  VISION: {
    TITLE_KEY: 'TITLE',
    VISION_PAGE_TALK_TO_NURSE_TOGGLE: 'VISION_PAGE_TALK_TO_NURSE_TOGGLE',
  },
  EDUCATIONAL_CONTENT_MODAL: {
    EDUCATIONAL_CONTENT_MODAL_TITLE: 'EDUCATIONAL_CONTENT_MODAL_TITLE',
    EDUCATIONAL_CONTENT_MODAL_DESCRIPTION:
      'EDUCATIONAL_CONTENT_MODAL_DESCRIPTION',
  },
  LANGUAGE_MODAL: {
    LANGUAGE_NOT_AVAILABLE: 'LANGUAGE_NOT_AVAILABLE',
    LANGUAGE_NOT_AVAILABLE_CONTENT: 'LANGUAGE_NOT_AVAILABLE_CONTENT',
  },
  COST_INDICATOR_CONTENT_MODAL: {
    COST_INDICATOR_POPOVER_CONTENT: 'COST_INDICATOR_POPOVER_CONTENT',
  },
  NATIONAL_ANCILLARY_NO_LOCATION_MODAL: 'NATIONAL_ANCILLARY_NO_LOCATION_MODAL',
  ONLINE_PROVIDERS_VISION_NO_LOCATION_MODAL:
    'ONLINE_PROVIDERS_VISION_NO_LOCATION_MODAL',
  MEMBER_SELECTION_CONFIRMATION_MODAL: {
    MEMBER_SELECTION_MODAL_BODY_CONTENT: 'MEMBER_SELECTION_MODAL_BODY_CONTENT',
    MEMBER_SELECTION_MODAL_BODY_HEADER: 'MEMBER_SELECTION_MODAL_BODY_HEADER',
    MEMBER_SELECTION_MODAL_PCP_CONTENT: 'MEMBER_SELECTION_MODAL_PCP_CONTENT',
    MEMBER_SELECTION_MODAL_CARE_SECTION_CONTENT:
      'MEMBER_SELECTION_MODAL_CARE_SECTION_CONTENT',
    MEMBER_SELECTION_MODAL_SCHEDULING_SECTION_CONTENT:
      'MEMBER_SELECTION_MODAL_SCHEDULING_SECTION_CONTENT',
  },
  NO_PROVIDERS_FOUND: 'NO_PROVIDERS_FOUND',
  FEATURE_SUPPRESSION_FLAGS: {
    PREMIUM_CARE: 'Premium Care',
    PATIENT_REVIEWS: 'Patient Reviews',
    PREFERRED_FACILITY: 'Preferred Facility',
    DDP: 'DDP',
    PLN: 'PLN',
    FSF: 'FSF',
    EAP: 'EAP',
    CUSTOM_PREFERRED_POLICY: 'Custom Preferred Policy',
    COST_ESTIMATES: 'Cost Estimates',
    COST_ESTIMATES_EMPIRE: 'Cost Estimates Empire',
    VIRTUAL_CARE_EMPIRE: 'Virtual Care Empire',
    SUPPRESS_DOC_ASAP: 'Suppress Doc ASAP',
    MARKET_PLACE: 'Market Place',
    VIRTUAL_CARE_UNET_RAILROAD: 'Virtual Care UNET Railroad',
    VIRTUAL_CARE_UNET: 'Virtual Care UNET',
    VIRTUAL_CARE_UNET_QUICK_CARE: 'Virtual Care UNET Quick Care',
    BEHAVIORAL_HEALTH: 'Behavioral Health',
    SMART_CHOICE: 'Smart Choice',
  },
  FEATURE_INCLUSION_FLAGS: {
    INCLUDE_DOC_ASAP: 'DocASAP',
    INCLUDE_CNS_OH: 'C&S Ohio',
  },
  EAP_AUTHORIZATION_CODE: 'EAP_AUTHORIZATION_CODE',
  PREFERRED_FACILITY: {
    PREFERRED_FACILITY_IDENTIFICATION: 'PREFERRED_FACILITY_IDENTIFICATION',
    PREFERRED_FACILITY_CONTENT: 'PREFERRED_FACILITY_CONTENT',
    DESIGNATED_DIAGNOSTIC_PROVIDER_CONTENT:
      'DESIGNATED_DIAGNOSTIC_PROVIDER_CONTENT',
    PREFERRED_LAB_NETWORK_CONTENT: 'PREFERRED_LAB_NETWORK_CONTENT',
    FREESTANDING_FACILITY_CONTENT: 'FREESTANDING_FACILITY_CONTENT',
    OXFORD_PREFERRED_LAB_CONTENT: 'OXFORD_PREFERRED_LAB_CONTENT',
  },
  MIXED_SEARCH_RESULT_PAGE: {
    SEARCH_SPECIALITY_RESULTS: 'SEARCH_SPECIALITY_RESULTS',
    SEARCH_NAME_RESULTS: 'SEARCH_NAME_RESULTS',
    SEARCH_SPECIALITY_RESULTS_SUBTITLE: 'SEARCH_SPECIALITY_RESULTS_SUBTITLE',
    SEARCH_NAME_RESULTS_SUBTITLE: 'SEARCH_NAME_RESULTS_SUBTITLE',
    SEARCH_INSTEAD_FOR_HEADING: 'SEARCH_INSTEAD_FOR_HEADING',
    SEARCH_RESULT_TITLE: 'SEARCH_RESULT_TITLE',
    SEARCH_PREFERRED_FACILITY_RESULTS_SUBTITLE:
      'SEARCH_PREFERRED_FACILITY_RESULTS_SUBTITLE',
  },
  PREFERRED_PROVIDER: {
    PREFERRED_PROVIDER_CONTENT: 'PREFERRED_PROVIDER_CONTENT',
  },
  TYPEAHEAD_PARAMS: {
    USER_TEXT_SEARCH_LABEL: 'USER_TEXT_SEARCH_LABEL',
  },
  LEAPFROG: {
    LEAPFROG_DISCLAIMER_CONTENT: 'LEAPFROG_DISCLAIMER_CONTENT',
  },
  CHOOSE_PCP_FLOW: {
    NEW_PATIENT_DISCLAIMER: 'NEW_PATIENT_DISCLAIMER',
  },
  VISION_DICTIONARY: {
    DISCLAIMER_CONTENT: 'VISION_DICTIONARY_DISCLAIMER_CONTENT',
    DISCLAIMER_ADDL_CONTENT: 'VISION_DICTIONARY_DISCLAIMER_ADDL_CONTENT',
  },
  MNR_DISCLAIMER: {
    DENTAL_MNR_DISCLAIMER: 'DENTAL_MNR_DISCLAIMER',
    MEDICAL_FACILITY_MNR_DISCLAIMER: 'MEDICAL_FACILITY_MNR_DISCLAIMER',
    VISION_MNR_DISCLAIMER: 'VISION_MNR_DISCLAIMER',
    HEALTH_GRADES_MNR_DISCLAIMER_CONTENT:
      'HEALTH_GRADES_MNR_DISCLAIMER_CONTENT',
    DIRECTORY_MNR_DISCLAIMER: 'DIRECTORY_MNR_DISCLAIMER',
  },
  THINGS_TO_CONSIDER: {
    THINGS_TO_CONSIDER_SECTION_ONE_TITLE:
      'THINGS_TO_CONSIDER_SECTION_ONE_TITLE',
    THINGS_TO_CONSIDER_SECTION_ONE_CONTENT:
      'THINGS_TO_CONSIDER_SECTION_ONE_CONTENT',
    THINGS_TO_CONSIDER_SECTION_TWO_TITLE:
      'THINGS_TO_CONSIDER_SECTION_TWO_TITLE',
    THINGS_TO_CONSIDER_SECTION_TWO_CONTENT:
      'THINGS_TO_CONSIDER_SECTION_TWO_CONTENT',
    THINGS_TO_CONSIDER_SECTION_THREE_CONTENT:
      'THINGS_TO_CONSIDER_SECTION_THREE_CONTENT',
    THINGS_TO_CONSIDER_SECTION_THREE_TITLE:
      'THINGS_TO_CONSIDER_SECTION_THREE_TITLE',
    THINGS_TO_CONSIDER_TITLE: 'THINGS_TO_CONSIDER_TITLE',
    THINGS_TO_CONSIDER_TITLE_CHOOSE_PCP: 'THINGS_TO_CONSIDER_TITLE_CHOOSE_PCP',
    THINGS_TO_CONSIDER_SUBTITLE: 'THINGS_TO_CONSIDER_SUBTITLE',
    THINGS_TO_CONSIDER_SUBTITLE_CHOOSE_PCP:
      'THINGS_TO_CONSIDER_SUBTITLE_CHOOSE_PCP',
  },
  PCP_CHANGE_MESSAGE: {
    PCP_MESSAGE_FOR_LAG_TIME: 'PCP_MESSAGE_FOR_LAG_TIME',
    PCP_SUCCESS_MESSAGE: 'PCP_SUCCESS_MESSAGE',
    PCP_CARD_MESSAGE_FOR_LAG_TIME: 'PCP_CARD_MESSAGE_FOR_LAG_TIME',
  },
  CHOOSE_A_PCP_POPOVER_CONTENT: {
    POPOVER_CONTENT_HEADING: 'POPOVER_CONTENT_HEADING',
    POPOVER_CONTENT_SUB_HEADING: 'POPOVER_CONTENT_SUB_HEADING',
    POPOVER_CONTENT_CONTENT_LIST_ONE: 'POPOVER_CONTENT_CONTENT_LIST_ONE',
    POPOVER_CONTENT_CONTENT_LIST_TWO: 'POPOVER_CONTENT_CONTENT_LIST_TWO',
    POPOVER_CONTENT_CONTENT_LIST_THREE: 'POPOVER_CONTENT_CONTENT_LIST_THREE',
    POPOVER_CONTENT_CHOOSE_PCP: 'POPOVER_CONTENT_CHOOSE_PCP',
  },
  UI_MESSAGING: {
    TIME_STAMP_LABEL: 'TIME_STAMP_LABEL',
    TIME_STAMP_CONTENT: 'TIME_STAMP_CONTENT',
    PRE_EFFECTIVE_NOTIFICATION: 'PRE_EFFECTIVE_NOTIFICATION',
    TABBED_SERVICES_FACILITIES_TITLE: 'TABBED_SERVICES_FACILITIES_TITLE',
    TABBED_SERVICES_FACILITIES_CONTENT: 'TABBED_SERVICES_FACILITIES_CONTENT',
    HELP_SECTION_TITLE: 'HELP_SECTION_TITLE',
    NETWORK_AFFILIATION_POPOVER_CONTENT: 'NETWORK_AFFILIATION_POPOVER_CONTENT',
    STATE_SPECIFIC_DISCLAIMER: 'STATE_SPECIFIC_DISCLAIMER',
    DENTAL_PROVIDER_NOT_AVAILABLE_CONTENT:
      'DENTAL_PROVIDER_NOT_AVAILABLE_CONTENT',
    PCP_ISPREEFFECTIVE_POPOVER_CONTENT: 'PCP_ISPREEFFECTIVE_POPOVER_CONTENT',
    PATIENT_CENTER_MEDICAL_HOMES_POPOVER_CONTENT:
      'PATIENT_CENTER_MEDICAL_HOMES_POPOVER_CONTENT',
    ESSENTIAL_COMMUNITY_PROVIDER_CONTENT:
      'ESSENTIAL_COMMUNITY_PROVIDER_CONTENT',
    VIEW_ID_CARD_HEADER: 'VIEW_ID_CARD_HEADER',
    VIEW_ID_CARD_CONTENT: 'VIEW_ID_CARD_CONTENT',
    VIEW_ID_CARD_BUTTON: 'VIEW_ID_CARD_BUTTON',
    PCP_NOT_AVAILABLE: 'PCP_NOT_AVAILABLE',
    KELSEY_SEYBOLD_CLINIC_NAME: 'KELSEY_SEYBOLD_CLINIC_NAME',
    KELSEY_SEYBOLD_NAME: 'KELSEY_SEYBOLD_NAME',
    KELSEY_SEYBOLD_PCP_TEXT: 'KELSEY_SEYBOLD_PCP_TEXT',
    EXPANDED_SEARCH_RADIUS_DESCRIPTION: 'EXPANDED_SEARCH_RADIUS_DESCRIPTION',
    UHC_DUAL_COMPLETE_PLAN_DISCLAIMER: 'UHC_DUAL_COMPLETE_PLAN_DISCLAIMER',
    UHC_CONNECTED_DISCLAIMER: 'UHC_CONNECTED_DISCLAIMER',
    FIND_CARE_DISCLAIMER: 'FIND_CARE_DISCLAIMER',
    TEXAS_HEALTH_STEPS_PROVIDER: 'TEXAS_HEALTH_STEPS_PROVIDER',
    IN_NETWORK_BANNER_FUTURE_START_DATE: 'IN_NETWORK_BANNER_FUTURE_START_DATE',
    ANTHEM_BLUE_CROSS_DISCLAIMER: 'ANTHEM_BLUE_CROSS_DISCLAIMER',
    ANTHEM_BLUE_CROSS_DISCLAIMER_POPUP: 'ANTHEM_BLUE_CROSS_DISCLAIMER_POPUP',
    NONE_AVAILABLE: 'NONE_AVAILABLE',
    NO_DATA_DISMISSIBLE_BANNER_HEADING: 'NO_DATA_DISMISSIBLE_BANNER_HEADING',
    NO_DATA_DISMISSIBLE_BANNER_DESCRIPTION:
      'NO_DATA_DISMISSIBLE_BANNER_DESCRIPTION',
    FREQUENT_SERVICES_TITLE_DESCRIPTION: 'FREQUENT_SERVICES_TITLE_DESCRIPTION',
    FREQUENT_SERVICES_DESCRIPTION_NO_DATA:
      'FREQUENT_SERVICES_DESCRIPTION_NO_DATA',
    TIER_2_POPOVER_CONTENT: 'TIER_2_POPOVER_CONTENT',
    MARKET_PLACE_HEADING: 'MARKET_PLACE_HEADING',
    MARKET_PLACE_DESCRIPTION: 'MARKET_PLACE_DECRIPTION',
    LIMITED_ACCESS_TO_PROVIDER_BANNER: 'LIMITED_ACCESS_TO_PROVIDER_BANNER',
    BOARD_CERTIFICATIONS_SECTION_DESCRIPTION:
      'BOARD_CERTIFICATIONS_SECTION_DESCRIPTION',
    NETWORK_FACILITY_DIRECTORY_BANNER: 'NETWORK_FACILITY_DIRECTORY_BANNER',
    PROVIDER_DIRECTORY_LABEL: 'PROVIDER_DIRECTORY_LABEL',
    NEW_DESIGN_FEEDBACK_FORM_BANNER: 'NEW_DESIGN_FEEDBACK_FORM_BANNER',
    ADVANCED_MEDICAL_HOME_INFO_ICON_BANNER_TIER_1:
      'ADVANCED_MEDICAL_HOME_INFO_ICON_BANNER_TIER_1',
    ADVANCED_MEDICAL_HOME_INFO_ICON_BANNER_TIER_2:
      'ADVANCED_MEDICAL_HOME_INFO_ICON_BANNER_TIER_2',
    ADVANCED_MEDICAL_HOME_INFO_ICON_BANNER_TIER_3:
      'ADVANCED_MEDICAL_HOME_INFO_ICON_BANNER_TIER_3',
    SCHEDULING_WITH_FACILITY_BANNER: 'SCHEDULING_WITH_FACILITY_BANNER',
    PLATINUM_RECOGNITION_POPOVER_CONTENT:
      'PLATINUM_RECOGNITION_POPOVER_CONTENT',
    PREMIUM_CARE_DISCLAIMER: 'PREMIUM_CARE_DISCLAIMER',
    SMART_CHOICE_BREAKDOWN_FAQ_TEXT: 'SMART_CHOICE_BREAKDOWN_FAQ_TEXT',
    SMART_CHOICE_BREAKDOWN_VIEW_LOCATIONS_TEXT:
      'SMART_CHOICE_BREAKDOWN_VIEW_LOCATIONS_TEXT',
    SMART_CHOICE_QUALITY_BREAKDOWN: {
      QUALITY_BREAKDOWN_TITLE: 'QUALITY_BREAKDOWN_TITLE',
      QUALITY_BREAKDOWN_ALL_CRITERIA_MET_TITLE_AND_DESCRIPTION:
        'QUALITY_BREAKDOWN_ALL_CRITERIA_MET_TITLE_AND_DESCRIPTION',
      QUALITY_BREAKDOWN_SOME_CRITERIA_MET_TITLE_AND_DESCRIPTION:
        'QUALITY_BREAKDOWN_SOME_CRITERIA_MET_TITLE_AND_DESCRIPTION',
      QUALITY_BREAKDOWN_NO_CRITERIA_NOT_MET_TITLE_AND_DESCRIPTION:
        'QUALITY_BREAKDOWN_NO_CRITERIA_NOT_MET_TITLE_AND_DESCRIPTION',
      QUALITY_BREAKDOWN_DESCRIPTION: 'QUALITY_BREAKDOWN_DESCRIPTION',
      QUALITY_BREAKDOWN_CREDENTIALED_PROVIDER:
        'QUALITY_BREAKDOWN_CREDENTIALED_PROVIDER',
      QUALITY_BREAKDOWN_PREMIUM_CARE_PHYSICIAN:
        'QUALITY_BREAKDOWN_PREMIUM_CARE_PHYSICIAN',
      QUALITY_BREAKDOWN_SERVICE_DATA_AVAILABLE:
        'QUALITY_BREAKDOWN_SERVICE_DATA_AVAILABLE',
      QUALITY_BREAKDOWN_NO_SERVICE_DATA_AVAILABLE:
        'QUALITY_BREAKDOWN_NO_SERVICE_DATA_AVAILABLE',
      QUALITY_BREAKDOWN_MATCH_WHAT_IS_PRIMARY_CARE_PHYSICIAN_Q_AND_A:
        'QUALITY_BREAKDOWN_MATCH_WHAT_IS_PRIMARY_CARE_PHYSICIAN_Q_AND_A',
      QUALITY_BREAKDOWN_NOTMATCH_PRIMARY_CARE_PHYSICIAN_TITLE_AND_DESCRIPTION:
        'QUALITY_BREAKDOWN_NOTMATCH_PRIMARY_CARE_PHYSICIAN_TITLE_AND_DESCRIPTION',
      QUALITY_BREAKDOWN_EXPLORE_UNITED_HEALTHCARE_PREMIUM:
        'QUALITY_BREAKDOWN_EXPLORE_UNITED_HEALTHCARE_PREMIUM',
    },
    SMART_CHOICE_BENEFITS_BREAKDOWN: {
      BENIFITS_BREAKDOWN_TITLE: 'BENIFITS_BREAKDOWN_TITLE',
      BENIFITS_BREAKDOWN_GOOD_MATCH_CONTENT:
        'BENIFITS_BREAKDOWN_GOOD_MATCH_CONTENT',
      BENIFITS_BREAKDOWN_DESCRIPTION: 'BENIFITS_BREAKDOWN_DESCRIPTION',
      BENIFITS_BREAKDOWN_IN_NETWORK: 'BENIFITS_BREAKDOWN_IN_NETWORK',
      BENIFITS_BREAKDOWN_PREFERRED_PROVIDER:
        'BENIFITS_BREAKDOWN_PREFERRED_PROVIDER',
      BENIFITS_BREAKDOWN_PROVIDER_TIER: 'BENIFITS_BREAKDOWN_PROVIDER_TIER',
      BENIFITS_BREAKDOWN_PROVIDER_TIER1_WITH_LOCATION:
        'BENIFITS_BREAKDOWN_PROVIDER_TIER1_WITH_LOCATION',
      BENIFITS_BREAKDOWN_PROVIDER_ISNOT_TIER1_WITH_LOCATION:
        'BENIFITS_BREAKDOWN_PROVIDER_ISNOT_TIER1_WITH_LOCATION',
      BENIFITS_BREAKDOWN_PROVIDER_PARTICIPATES_ACO_NAME:
        'BENIFITS_BREAKDOWN_PROVIDER_PARTICIPATES_ACO_NAME',
      BENIFITS_BREAKDOWN_PROVIDER_PRATICIPATES_ACO_NAME_AND_LOCATION:
        'BENIFITS_BREAKDOWN_PROVIDER_PRATICIPATES_ACO_NAME_AND_LOCATION',
      BENIFITS_BREAKDOWN_DOES_NOT_PARTICIPATES_ELIGIBLE_AT_ACO_NAME:
        'BENIFITS_BREAKDOWN_DOES_NOT_PARTICIPATES_ELIGIBLE_AT_ACO_NAME',
      BENIFITS_BREAKDOWN_PROVIDER_NOT_PARTICIPATES_ACO_NAME_AND_LOCATION:
        'BENIFITS_BREAKDOWN_PROVIDER_NOT_PARTICIPATES_ACO_NAME_AND_LOCATION',
      BENIFITS_BREAKDOWN_BELOW_AVARAGE_COST_FOR_OFFICE_VISIT:
        'BENIFITS_BREAKDOWN_BELOW_AVARAGE_COST_FOR_OFFICE_VISIT',
      BENIFITS_BREAKDOWN_AVERAGE_COST_FOR_OFFICE_VISIT:
        'BENIFITS_BREAKDOWN_AVERAGE_COST_FOR_OFFICE_VISIT',
      BENIFITS_BREAKDOWN_ABOVE_AVERAGE_COST_FOR_OFFICE_VISIT:
        'BENIFITS_BREAKDOWN_ABOVE_AVERAGE_COST_FOR_OFFICE_VISIT',
      BENIFITS_BREAKDOWN_BELOW_AVARAGE_TOTAL_COST_OF_CARE:
        'BENIFITS_BREAKDOWN_BELOW_AVARAGE_TOTAL_COST_OF_CARE',
      BENIFITS_BREAKDOWN_AVERAGE_TOTAL_COST_OF_CARE:
        'BENIFITS_BREAKDOWN_AVERAGE_TOTAL_COST_OF_CARE',
      BENIFITS_BREAKDOWN_ABOVE_AVERAGE_TOTAL_COST_OF_CARE:
        'BENIFITS_BREAKDOWN_ABOVE_AVERAGE_TOTAL_COST_OF_CARE',
    },
    SMART_CHOICE_CONVENIENCE_BREAKDOWN: {
      CONVENIENCE_BREAKDOWN_TITLE: 'CONVENIENCE_BREAKDOWN_TITLE',
      CONVENIENCE_BREAKDOWN_GOOD_MATCH_CONTENT:
        'CONVENIENCE_BREAKDOWN_GOOD_MATCH_CONTENT',
      CONVENIENCE_BREAKDOWN_DESCRIPTION: 'CONVENIENCE_BREAKDOWN_DESCRIPTION',
      CONVENIENCE_BREAKDOWN_AVALIABILITY_TITLE:
        'CONVENIENCE_BREAKDOWN_AVALIABILITY_TITLE',
      CONVENIENCE_BREAKDOWN_ACCEPCTING_NEW_PATIENTS:
        'CONVENIENCE_BREAKDOWN_ACCEPCTING_NEW_PATIENTS',
      CONVENIENCE_BREAKDOWN_APPOINTMENTS_ARE_AVAILABILE_WITH_IN:
        'CONVENIENCE_BREAKDOWN_APPOINTMENTS_ARE_AVAILABILE_WITH_IN',
      CONVENIENCE_BREAKDOWN_EVENING_APPONTMENTS_AVAILABILE:
        'CONVENIENCE_BREAKDOWN_EVENING_APPONTMENTS_AVAILABILE',
      CONVENIENCE_BREAKDOWN_EVENING_APPONTMENTS_UNAVAILABILE:
        'CONVENIENCE_BREAKDOWN_EVENING_APPONTMENTS_UNAVAILABILE',
      CONVENIENCE_BREAKDOWN_WEEKEND_APPONTMENTS_AVAILABILE:
        'CONVENIENCE_BREAKDOWN_WEEKEND_APPONTMENTS_AVAILABILE',
      CONVENIENCE_BREAKDOWN_WEEKEND_APPONTMENTS_UNAVAILABILE:
        'CONVENIENCE_BREAKDOWN_WEEKEND_APPONTMENTS_UNAVAILABILE',
      CONVENIENCE_BREAKDOWN_ONLINE_APOINTMENT_SCHEDULE_AVAILABLE_THOUGHT_MYUHC_COM:
        'CONVENIENCE_BREAKDOWN_ONLINE_APOINTMENT_SCHEDULE_AVAILABLE_THOUGHT_MYUHC_COM',
      CONVENIENCE_BREAKDOWN_ONLINE_APOINTMENT_SCHEDULE_UNAVAILABLE_THOUGHT_MYUHC_COM:
        'CONVENIENCE_BREAKDOWN_ONLINE_APOINTMENT_SCHEDULE_UNAVAILABLE_THOUGHT_MYUHC_COM',
      CONVENIENCE_BREAKDOWN_ACCESS_TO_CARE_TITLE:
        'CONVENIENCE_BREAKDOWN_ACCESS_TO_CARE_TITLE',
      CONVENIENCE_BREAKDOWN_WITH_NUMBER_OF_MILES_YOUR_LOCATION:
        'CONVENIENCE_BREAKDOWN_WITH_NUMBER_OF_MILES_YOUR_LOCATION',
      CONVENIENCE_BREAKDOWN_VIRTUAL_APOINTMENTS_AVAILABLE:
        'CONVENIENCE_BREAKDOWN_VIRTUAL_APOINTMENTS_AVAILABLE',
      CONVENIENCE_BREAKDOWN_VIRTUAL_APOINTMENTS_UNAVAILABLE:
        'CONVENIENCE_BREAKDOWN_VIRTUAL_APOINTMENTS_UNAVAILABLE',
    },
    SMART_CHOICE_PERSONAL_FIT_BREAKDOWN: {
      PERSONAL_FIT_BREAKDOWN_TITLE: 'PERSONAL_FIT_BREAKDOWN_TITLE',
      PERSONAL_FIT_BREAKDOWN_GOOD_MATCH_CONTENT:
        'PERSONAL_FIT_BREAKDOWN_GOOD_MATCH_CONTENT',
      PERSONAL_FIT_BREAKDOWN_DESCRIPTION: 'PERSONAL_FIT_BREAKDOWN_DESCRIPTION',
      PERSONAL_FIT_BREAKDOWN_COMPATABILITY_TITLE:
        'PERSONAL_FIT_BREAKDOWN_COMPATABILITY_TITLE',
      PERSONAL_FIT_BREAKDOWN_SPEAKS: 'PERSONAL_FIT_BREAKDOWN_SPEAKS',
      PERSONAL_FIT_BREAKDOWN_DOES_NOT_SPEAKS_LANGUAGE:
        'PERSONAL_FIT_BREAKDOWN_DOES_NOT_SPEAKS_LANGUAGE',
      PERSONAL_FIT_BREAKDOWN_LANGUAGE_UNKNOWN:
        'PERSONAL_FIT_BREAKDOWN_LANGUAGE_UNKNOWN',
      PERSONAL_FIT_BREAKDOWN_PROVIDER_IDENTIFIES_AS:
        'PERSONAL_FIT_BREAKDOWN_PROVIDER_IDENTIFIES_AS',
      PERSONAL_FIT_BREAKDOWN_GENDER_UNKNOWN:
        'PERSONAL_FIT_BREAKDOWN_GENDER_UNKNOWN',
      PERSONAL_FIT_BREAKDOWN_BOARD_CERTIFIED_IN:
        'PERSONAL_FIT_BREAKDOWN_BOARD_CERTIFIED_IN',
      PERSONAL_FIT_BREAKDOWN_NOT_BORD_CERTIFIED:
        'PERSONAL_FIT_BREAKDOWN_NOT_BORD_CERTIFIED',
      PERSONAL_FIT_BREAKDOWN_CONSUMER_RATING_INFO_UNAVAILABLE:
        'PERSONAL_FIT_BREAKDOWN_CONSUMER_RATING_INFO_UNAVAILABLE',
      PERSONAL_FIT_BREAKDOWN_PAITIENT_EXPERIENCE_TITLE:
        'PERSONAL_FIT_BREAKDOWN_PAITIENT_EXPERIENCE_TITLE',
      PERSONAL_FIT_BREAKDOWN_CONSIMER_RATING_STARS_ARE_PROVIDED_BY_HEALTHGRADES:
        'PERSONAL_FIT_BREAKDOWN_CONSIMER_RATING_STARS_ARE_PROVIDED_BY_HEALTHGRADES',
      PERSONAL_FIT_BREAKDOWN_CONSUMER_RATING_AND_STARS:
        'PERSONAL_FIT_BREAKDOWN_CONSUMER_RATING_AND_STARS',
      PERSONAL_FIT_BREAKDOWN_HEALTHGRADE_DESCRIPTION_AT_END:
        'PERSONAL_FIT_BREAKDOWN_HEALTHGRADE_DESCRIPTION_AT_END',
    },
    SMART_CHOICE_BREAKDOWN: 'SMART_CHOICE_BREAKDOWN',
    SCORE_CALCULATED_LABEL: 'SCORE_CALCULATED_LABEL',
  },
  QUICK_CARE: {
    VIRTUAL_CARE: 'VIRTUAL_CARE',
    VIRTUAL_CARE_RAILROAD: 'VIRTUAL_CARE_RAILROAD',
    VIRTUAL_CARE_KELSEY_SEYBOLD: 'VIRTUAL_CARE_KELSEY_SEYBOLD',
  },
  LANDING_INFO: {
    TIME_STAMP_CONTENT: 'TIME_STAMP_CONTENT',
  },
  FACILITY_LOCATIONS: {
    SPECIALTIES_OFFERED_BY_FACILITY: 'SPECIALTIES_OFFERED_BY_FACILITY',
  },
};
