import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import update from 'lodash/update';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useId,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { CountySearchContext } from '../../../../context/CountySearchContext';
import { useDeviceLocation } from '../../../../hooks/useDeviceLocation';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useLocation } from '../../../../hooks/useLocation';
import { Location } from '../../../../models/Location';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { useAnalyticsStore } from '../../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import { usePCPStore } from '../../../../store/usePCPStore';
import { PCPSearchStore } from '../../../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import { refactorPlaceName } from '../../../../utils/locationSearch.util';
import { getCurrentMember } from '../../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { mobileOnly } from '../../../ConstantsStyles';
import { isCounty } from '../../../SnackCardContainer/utils';
import {
  convertTypeaheadProviderIdAndType,
  getTypeAheadCategory,
  locationSearchImpressionTrackEvent,
} from '../../../Utils/adobeTrackUtils/adobeTrackUtils';
import { LocationSearchMobile } from '../../LocationSearchMobile/LocationSearchMobile';
import {
  IconContainer,
  InputDivider,
  LocationInputContainer,
  LocationInputRow,
  LocationSearchLabel,
  LocationSearchPiece,
  LocationSearchPieceContainer,
} from '../SearchBar.styled';
import {
  getGeoLocationFromStorage,
  handleCloseLocationOptionsList,
  handleSearchBarSearchButtonClickOrEnter,
  setGeoLocationToStorage,
} from '../utils';
import {
  ButtonContainer,
  Input,
  LayoutGroupStyle,
} from './LocationInput.styled';

interface IProps {
  isShowingLocationDropdown: boolean;
  onLocationSearchInputFocus: () => void;
  onLocationSearchInputTextChange: (newValue: string) => void;
  setLocationSuggestions: (value: object[]) => void;
  setIsLocationLoading: (value: boolean) => void;
  handleCloseLocationDrawer: () => void;
  isShowingLocationDrawer: boolean;
  handleCloseLocationDropdown: () => void;
  setCursor: any;
  cursor: number;
  memberLocation: string;
  searchTerm: string;
  locationValue: string;
  // eslint-disable-next-line react/no-unused-prop-types
  placeHolderValue: string;
  setLocationInputVal: (value: string) => void;
  setIsFocusedOnKeywordSearchInput: (val: boolean) => void;
  setIsOpenMobileLocation: (value: boolean) => void;
  isOpenMobileLocation: boolean;
  headers: ResponseHeaders;
  typeAheadSuggestions?: any;
  specialityPreferenceTable?: any;
  combinedRollupCodes: string;
  aggregatedAoeCodes?: string[];
  bhProgramFacilityAgg: string;
  isFocusedOnKeywordSearchInput: boolean;
  isFocusedOnLocationSearch: boolean;
  checkForEmptyLocation: () => void;
  enableUESSuggestionMatch: boolean;
  enableTypeAheadWildCardSearch?: boolean;
  id?: string;
}

export const LocationInput = ({
  isShowingLocationDropdown,
  onLocationSearchInputFocus,
  onLocationSearchInputTextChange,
  setLocationSuggestions,
  setIsLocationLoading,
  handleCloseLocationDrawer,
  headers,
  isShowingLocationDrawer,
  handleCloseLocationDropdown,
  cursor,
  setCursor,
  memberLocation,
  searchTerm,
  locationValue,
  setLocationInputVal,
  setIsFocusedOnKeywordSearchInput,
  isFocusedOnLocationSearch,
  isOpenMobileLocation,
  setIsOpenMobileLocation,
  typeAheadSuggestions,
  combinedRollupCodes,
  aggregatedAoeCodes = [],
  bhProgramFacilityAgg,
  checkForEmptyLocation,
  isFocusedOnKeywordSearchInput,
  specialityPreferenceTable,
  enableUESSuggestionMatch,
  enableTypeAheadWildCardSearch,
  id,
}: IProps) => {
  const { t } = useTranslation();
  const locationSearchInputId = useId();
  const currentMember = getCurrentMember();
  const geoLocation = useGeoLocationStorage();
  const { name: locationName } = geoLocation;
  const { navigate } = useRouter();
  const config = useLagoon('config')();

  const [enableRetriveTypeAheadDataFlag, enableSearchResultsV2] =
    useFeatureFlag([
      ConstantsLagoon.FEATURE_FLAGS.ENABLE_RETRIVE_TYPEAHEAD_DATA,
      ConstantsLagoon.FEATURE_FLAGS.ENABLE_SEARCH_RESULTS_V2,
    ]);

  const enableRetriveTypeAheadData =
    enableRetriveTypeAheadDataFlag && enableSearchResultsV2;

  useEffect(() => {
    if (!isShowingLocationDropdown) {
      setLocationInputVal(locationName);
    }
  }, [isShowingLocationDropdown]);

  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setCoverageType, setChipValue, chipValue } = chipStore;

  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const { setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );
  const { setIsCountySearch } = useContext(CountySearchContext);
  const [, setDeviceLocation] = useState<string>(
    getGeoLocationFromStorage() ?? ''
  );
  const [locationSearchResults, getLocation] = useLocation({
    onCompleted: (result: {
      data: {
        location: {
          features: [
            { place_name: string; id: string; center: [string, string] }
          ];
        };
      };
    }) => {
      setIsLocationLoading(false);
      const resultSuggestions = cloneDeep(result?.data?.location?.features);
      resultSuggestions?.map((loc) =>
        update(loc, 'place_name', () => refactorPlaceName(loc.place_name))
      );
      /** Stub in one more item in array for keyboard accessibility and navigation purposes */
      resultSuggestions.push({
        center: ['0', '0'],
        id: `${resultSuggestions.length}`,
        place_name: 'Find my Location',
      });

      setLocationSuggestions(resultSuggestions);
    },
    onError: () => {
      setIsLocationLoading(false);
      setLocationSuggestions([]);
    },
  });

  const [, getInitialLocation] = useLocation({
    onCompleted: (result) => {
      const targetLocation: Location =
        result?.data?.location?.features?.[0] || {};
      const {
        id,
        center = [],
        place_name: placeName = '',
        stateCode = '',
        zipCode = '',
      } = targetLocation;
      const [long, lat] = center || [];
      // set geo coords
      if (isCounty(id)) setIsCountySearch(true);
      else setIsCountySearch(false);

      setGeoLocationToStorage({
        id,
        name: placeName,
        longitude: long,
        latitude: lat,
        stateCode,
        zipCode,
      });
    },
  });

  const { getDeviceLocation } = useDeviceLocation(
    setDeviceLocation,
    setIsOpenMobileLocation
  );
  const mobileScreen = useMediaQuery(mobileOnly);

  useEffect(() => {
    if (isEmpty(geoLocation)) {
      const getCurrentLocation = async () => {
        await getDeviceLocation();
        if (isEmpty(geoLocation)) {
          getInitialLocation({
            variables: {
              address: memberLocation,
              countySearchEnabled: true,
            },
          });
        }
      };
      getCurrentLocation();
    }
  }, []);

  useEffect(() => {
    setLocationInputVal(locationName);
  }, [locationName]);

  useEffect(() => {
    checkForEmptyLocation();
  }, [chipValue]);

  const handleLocationChange = async (
    loc: string,
    additionalCountriesCode: string
  ) => {
    const { data } = await getLocation({
      variables: {
        address: encodeURIComponent(loc),
        countySearchEnabled: true,
        additionalCountriesCode,
      },
    });

    if (data) {
      locationSearchImpressionTrackEvent(loc, data.location?.features?.length);
    }
  };

  const handleLocationSearchTextChange = useCallback(
    debounce(async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      onLocationSearchInputTextChange(value);
      const countryCodes =
        config?.find(
          (item) => item.key === 'ADDITIONAL_COUNTRIES_FOR_LOCATION_SEARCH'
        )?.value ?? '';
      if (value?.trim().length) {
        setIsLocationLoading(true);
        await handleLocationChange(value, countryCodes ?? '');
      }
    }, 1900),
    []
  );

  const handleLocationSearchKeyDown = (ev: {
    key: string;
    preventDefault: () => void;
  }) => {
    const minCursor = -1;
    const maxCursor = locationSearchResults?.data?.location?.features?.length;

    if (['ArrowUp', 'ArrowDown'].some((key) => key === ev.key)) {
      ev.preventDefault();
    }

    if (ev.key === 'Enter') {
      ev.preventDefault();
      if (cursor > minCursor && cursor < maxCursor) {
        const analyticsLinkLocationOnEnter: string = 'search location enter';
        const searchInputOptionLocation = 'search-input-option-location-list';
        const selectedLocation =
          locationSearchResults?.data?.location?.features?.[cursor];

        adobeLinkTrackEvent({
          name: analyticsLinkLocationOnEnter,
          location: `body:${searchInputOptionLocation}`,
          type: 'internal',
        });

        const [selectedLong, selectedLat] = selectedLocation.center || [];
        const placeName = selectedLocation.place_name;
        setGeoLocationToStorage({
          name: placeName,
          longitude: selectedLong,
          latitude: selectedLat,
          stateCode: selectedLocation.stateCode,
          zipCode: selectedLocation.zipCode || '',
        });

        handleCloseLocationOptionsList(
          isShowingLocationDrawer,
          isShowingLocationDropdown,
          setCursor,
          handleCloseLocationDrawer,
          handleCloseLocationDropdown
        );
      } else if (cursor === maxCursor) {
        getDeviceLocation();
        handleCloseLocationOptionsList(
          isShowingLocationDrawer,
          isShowingLocationDropdown,
          setCursor,
          handleCloseLocationDrawer,
          handleCloseLocationDropdown
        );
      }
    } else if (ev.key === 'ArrowUp' && cursor > minCursor) {
      setCursor((previous) => previous - 1);
    } else if (ev.key === 'ArrowDown' && cursor < maxCursor) {
      setCursor((previous) => previous + 1);
    }
  };

  const clickSearchButton = (userSearchTerm: any, currentMember: any) => {
    const analyticsLinkName = 'search button';
    const typeaheadLinkName = 'typeahead search';
    checkForEmptyLocation();
    handleSearchBarSearchButtonClickOrEnter(
      {
        enableRetriveTypeAheadData,
        enableUESSuggestionMatch,
        typeAheadSuggestions: typeAheadSuggestions || [],
        navigate,
        headers,
        analyticsLinkName,
        typeaheadLinkName,
        currentMember,
        userSearchTerm,
        combinedRollupCodes,
        aggregatedAoeCodes,
        bhProgramFacilityAgg,
        setIsFocusedOnKeywordSearchInput,
        convertTypeaheadProviderIdAndType,
        getTypeAheadCategory,
        store: {
          chipStore,
          setTypeaheadSearchStore,
          setAnalyticsState,
          setPCPSearchState,
          setChipValue,
          setCoverageType,
          setDetailsStore,
        },
      },
      specialityPreferenceTable
    );
  };
  return !mobileScreen ? (
    <LocationInputContainer>
      <LocationInputRow>
        <InputDivider />
        <LocationSearchPieceContainer
          data-testid="location-search-container"
          isShowingLocationDropdown={isShowingLocationDropdown}
        >
          <LocationSearchLabel
            data-testid="location-search-label"
            htmlFor={locationSearchInputId}
          >
            {t('Location')}
          </LocationSearchLabel>
          <LocationSearchPiece>
            <IconContainer>
              <IconMaterial color={'$icColor'} icon="location_on" size={24} />
            </IconContainer>
            <Input
              aria-activedescendant={
                isShowingLocationDropdown
                  ? 'location-search-result-suggestions'
                  : ''
              }
              aria-autocomplete="list"
              aria-describedby={id}
              aria-expanded={isShowingLocationDropdown}
              aria-haspopup="listbox"
              aria-owns="suggested-locations-list"
              autoComplete="off"
              data-testid="location-search-input"
              id={locationSearchInputId}
              onChange={(e) => {
                setLocationInputVal(e.target.value);
                handleLocationSearchTextChange(e);
              }}
              onFocus={onLocationSearchInputFocus}
              onKeyDown={handleLocationSearchKeyDown}
              role="combobox"
              type="text"
              value={locationValue}
            />
          </LocationSearchPiece>
        </LocationSearchPieceContainer>

        {enableTypeAheadWildCardSearch && !mobileScreen ? (
          <Layout.Group css={LayoutGroupStyle}>
            <ButtonContainer
              data-testid="search-button"
              disabled={
                (!isFocusedOnKeywordSearchInput &&
                  !isFocusedOnLocationSearch) ||
                searchTerm?.length <= 1
              }
              onClick={() => {
                clickSearchButton(searchTerm, currentMember);
              }}
            >
              {t('Search')}
            </ButtonContainer>
          </Layout.Group>
        ) : null}
      </LocationInputRow>
    </LocationInputContainer>
  ) : (
    <Drawer
      css={{
        'abyss-modal-close-button': {
          marginTop: '$md',
          marginRight: '8px',
        },
        'abyss-modal-header-container': {
          marginLeft: '$sm',
        },
        'abyss-modal-content-container': {
          borderRadius: '20px 20px 0px 0px',
          height: '784px !important',
        },
      }}
      isOpen={isOpenMobileLocation}
      onClose={() => setIsOpenMobileLocation(false)}
      position="bottom"
      size="$lg"
      title={
        <Heading color="$gray8" offset={4}>
          {!locationName ? t('My search location') : t('Location Change')}
        </Heading>
      }
    >
      <LocationSearchMobile
        findUserLocation={getDeviceLocation}
        location={locationName}
        setIsOpen={setIsOpenMobileLocation}
        setLocation={(targetLocation) => {
          const [selectedLong, selectedLat] = targetLocation.center || [];
          const placeName = targetLocation.place_name;
          if (isCounty(targetLocation.id)) setIsCountySearch(true);
          else setIsCountySearch(false);
          setGeoLocationToStorage({
            id: targetLocation.id,
            name: placeName,
            longitude: selectedLong,
            latitude: selectedLat,
            stateCode: targetLocation.stateCode,
            zipCode: targetLocation.zipCode || '',
          });
        }}
      />
    </Drawer>
  );
};
