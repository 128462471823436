import { styled } from '@abyss/web/tools/styled';
import { Link } from '@abyss/web/ui/Link';

export const StyledLinkLocations = styled(Link, {
  fontFamily: '$primary',
  fontSize: '14px !important',
  fontWeight: '400 !important',
  lineHeight: '20px !important',
  '&::before': {
    borderBottom: 'none !important',
  },
});
