export const carouselStyle = {
  'abyss-carousel-root': {
    '@screen >= $sm': {
      width: '100%',
      marginTop: '16px',
    },
    '@screen < $sm': {
      width: '100%',
      marginTop: '12px',
    },
  },
  'abyss-carousel-swiper-container': {
    display: 'flex',
    willChange: 'transform',
    zIndex: 0,
  },
  'abyss-carousel-slide-container': {
    position: 'relative',
    width: '588px',
    gap: '24px',
    height: 'auto',
    '@screen < $sm': {
      width: '310px',
      gap: '12px',
    },
  },
  'abyss-carousel-minimal-controls': {
    marginTop: '16px',
    padding: '0px',
    height: '20px',
    marginBottom: '12px',
    '@screen < $sm': {
      marginBottom: 0,
    },
    'button.abyss-carousel-minimal-pagination-container': {
      padding: ' 0 4px 0 4px',
      display: 'block',
      width: 'auto',
    },
  },
  'abyss-carousel-minimal-nav-icon-right': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-nav-container-right': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },
  'abyss-carousel-minimal-nav-container-left': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },

  'abyss-carousel-minimal-nav-icon-left': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-pagination-container': {
    padding: '0 8px 0 8px',
  },
};

export const GridColStyle = {
  padding: '12px 12px 0 12px',
  '@screen < $sm': {
    padding: '0px',
  },
};
