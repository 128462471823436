import { PortalIdentifier, PortalName } from 'parcels/src/common/Portal';

export interface PortalTheme {
  primaryColor: string;
  secondaryColor: string;
}

type PortalNameTheme = {
  [key in string]: PortalTheme;
};

export const portalNameThemeMap: PortalNameTheme = {
  [PortalName.UMR]: {
    primaryColor: '#002677',
    secondaryColor: '#196ECF',
  },
  [PortalName.UMR_PORTAL]: {
    primaryColor: '#002677',
    secondaryColor: '#196ECF',
  },
  [PortalIdentifier.GEHA]: {
    primaryColor: '#1C0950',
    secondaryColor: '#500778',
  },
  [PortalName.STUDENT_RESOURCE]: {
    primaryColor: '#002677',
    secondaryColor: '#196ECF',
  },
  [PortalName.ALL_SAVERS]: {
    primaryColor: '#002677',
    secondaryColor: '#196ECF',
  },
  [PortalName.UHONE]: {
    primaryColor: '#002677',
    secondaryColor: '#196ECF',
  },
};

// These overrides are copied from container app so we can test the white labeling standalone.
// The owner is really container app because both PSX and CEX should be using the same theme overrides.
// So if there's any update required, update here first to test standalone then update the one in container app.
// https://github.com/uhc-tech-provider/search-container/blob/main/products/web/src/style/theme.tsx
export const createThemeOverride = ({
  primaryColor,
  secondaryColor,
}: PortalTheme): object => {
  const primary1 = primaryColor ?? '#002677';
  const interactive1 = secondaryColor ?? '#196ECF';

  return {
    theme: {
      colors: {
        // primary
        primary1,
        footerBackground: '$primary1',
        carouselContentPrimary: '$primary1',
        carouselButtonDefault: '$primary1',
        loadingSpinnerPrimary1: '$primary1',
        avatarContentAquaBorderColor: '$primary1',
        avatarContentBlueBorderColor: '$primary1',
        navMenuBackgroundDefault: '$primary1',
        navMenuTextHover: '$primary1',
        'core-color-brand-100': '$primary1',
        'semantic-color-surface-interactive-standards-rest-default-primary':
          '$primary1',
        'semantic-color-surface-interactive-standards-active-selected-tertiary':
          '$primary1',
        'semantic-color-surface-interactive-controls-rest-selected-pickers':
          '$primary1',
        'semantic-color-surface-interactive-controls-active-selected-choice':
          '$primary1',
        'semantic-color-surface-interactive-headerElement-rest-default':
          '$primary1',
        'semantic-color-surface-container-primary': '$primary1',
        'semantic-color-surface-container-header-topLevelNav': '$primary1',
        'semantic-color-border-interactive-controls-active-selected':
          '$primary1',
        'semantic-color-border-content-primary': '$primary1',
        'semantic-color-icon-interactive-rest-primary': '$primary1',
        'semantic-color-icon-interactive-active-tertiary': '$primary1',
        'semantic-color-icon-content-primary': '$primary1',
        'semantic-color-text-interactive-active-primary': '$primary1',
        'semantic-color-text-label-cta-primary': '$primary1',
        'semantic-color-text-content-primary': '$primary1',
        'accordionV2-color-text-trigger': '$primary1',
        'accordionV2-color-fill-chevron-default': '$primary1',
        'accordionV2-color-fill-headerIcon': '$primary1',
        'breadcrumbsV2-color-text-link-active': '$primary1',
        'button-color-text-root-tertiary-default': '$primary1',
        'buttonV2-color-text-root-secondary': '$primary1',
        'buttonV2-color-text-root-tertiary-active': '$primary1',
        'buttonV2-color-background-root-primary-default': '$primary1',
        'buttonV2-color-border-root-secondary': '$primary1',
        'buttonV2-color-fill-svg-secondary-default': '$primary1',
        'buttonV2-color-fill-svg-tertiary-active': '$primary1',
        'footerV2-color-background-root-default': '$primary1',
        'formInputV2-color-background-root-inputType-checkbox-checked-active':
          '$primary1',
        'linkV2-color-text-active': '$primary1',
        'navMenu-color-background-link-inverted-hover': '$primary1',
        'navMenu-color-background-menuTrigger-inverted-hover': '$primary1',
        'navMenu-color-background-root-default': '$primary1',
        'navMenu-color-background-scrollButton-default-default': '$primary1',
        'navMenu-color-background-scrollButton-inverted-hover': '$primary1',
        'navMenu-color-fill-columnTitleExternalIcon': '$primary1',
        'navMenu-color-fill-menuTriggerIcon-default-open-default': '$primary1',
        'navMenu-color-fill-menuTriggerIcon-default-open-hover': '$primary1',
        'navMenu-color-fill-menuTriggerIcon-inverted-closed': '$primary1',
        'navMenu-color-fill-scrollButtonIcon-default-default': '$primary1',
        'navMenu-color-fill-scrollButtonIcon-inverted-default': '$primary1',
        'navMenu-color-text-columnTitle-default': '$primary1',
        'navMenu-color-text-link-inverted-default': '$primary1',
        'navMenu-color-text-menuTrigger-default-hover': '$primary1',
        'navMenu-color-text-menuTrigger-inverted-default': '$primary1',
        'navMenuV2-color-background-default-default': '$primary1',
        'navMenuV2-color-background-link-inverted-hover': '$primary1',
        'navMenuV2-color-background-menuTrigger-inverted-hover': '$primary1',
        'navMenuV2-color-background-root-default': '$primary1',
        'navMenuV2-color-background-scrollButton-default-default': '$primary1',
        'navMenuV2-color-background-scrollButton-inverted-hover': '$primary1',
        'navMenuV2-color-fill-columnTitleExternalIcon': '$primary1',
        'navMenuV2-color-fill-menuTriggerIcon-default-open-default':
          '$primary1',
        'navMenuV2-color-fill-menuTriggerIcon-default-open-hover': '$primary1',
        'navMenuV2-color-fill-menuTriggerIcon-inverted-closed': '$primary1',
        'navMenuV2-color-fill-scrollButtonIcon-default-default': '$primary1',
        'navMenuV2-color-fill-scrollButtonIcon-inverted-default': '$primary1',
        'navMenuV2-color-text-columnTitle-default': '$primary1',
        'navMenuV2-color-text-link-default-hover': '$primary1',
        'navMenuV2-color-text-link-inverted-default': '$primary1',
        'navMenuV2-color-text-menuTrigger-default-hover': '$primary1',
        'navMenuV2-color-text-menuTrigger-inverted-default': '$primary1',
        'pageBodyIntro-color-text-titleText': '$primary1',
        'pageFooter-color-background-root': '$primary1',
        // interactive
        interactive1,
        info1: '$interactive1',
        accent1: '$interactive1',
        toggleTabsSelected: '$interactive1',
        carouselContentSecondary: '$interactive1',
        'core-color-info1': '$interactive1',
        'core-color-brand-60': '$interactive1',
        'core-color-blue-80': '$interactive1',
        'semantic-color-surface-interactive-standards-rest-selected-tertiary':
          '$interactive1',
        'semantic-color-surface-interactive-controls-rest-selected-choice':
          '$interactive1',
        'semantic-color-surface-interactive-headerElement-rest-selected':
          '$interactive1',
        'semantic-color-surface-container-status-info-saturated':
          '$interactive1',
        'semantic-color-border-interactive-controls-rest-selected':
          '$interactive1',
        'semantic-color-border-interactive-stateAffordance-selected':
          '$interactive1',
        'semantic-color-border-interactive-forms-hover': '$interactive1',
        'semantic-color-border-status-info': '$interactive1',
        'semantic-color-icon-interactive-rest-tertiary': '$interactive1',
        'semantic-color-icon-status-info': '$interactive1',
        'semantic-color-text-interactive-rest-primary': '$interactive1',
        'semantic-color-text-status-info': '$interactive1',
        'alert-color-border-root-info': '$interactive1',
        'alert-color-border-actionButton-info': '$interactive1',
        'alert-color-icon-alertIcon-info': '$interactive1',
        'badge-color-text-container-info': '$interactive1',
        'badge-color-border-container-info': '$interactive1',
        'badgeV2-color-text-container-info': '$interactive1',
        'badgeV2-color-border-container-info': '$interactive1',
        'breadcrumbsV2-color-text-link-default': '$interactive1',
        'breadcrumbsV2-color-icon-mobile': '$interactive1',
        'buttonV2-color-text-root-tertiary-default': '$interactive1',
        'buttonV2-color-fill-svg-tertiary-default': '$interactive1',
        'checkboxV2-color-border-checked-true-rest': '$interactive1',
        'formInputV2-color-background-root-inputType-checkbox-checked-rest':
          '$interactive1',
        'formInputV2-color-border-root-default-hover': '$interactive1',
        'formInputV2-color-text-root-caret-default': '$interactive1',
        'formInputLabelV2-color-text-helperIcon': '$interactive1',
        'link-color-text-default': '$interactive1',
        'linkV2-color-text-default': '$interactive1',
        'navMenu-color-fill-itemLinkExternalIcon-default': '$interactive1',
        'navMenu-color-fill-routerHeaderIcon': '$interactive1',
        'navMenu-color-focus-root': '$interactive1',
        'navMenu-color-focus-drawerMenuItem': '$interactive1',
        'navMenu-color-text-drawerMenuItem': '$interactive1',
        'navMenu-color-text-itemLinkTitle-default': '$interactive1',
        'navMenuV2-color-fill-itemLinkExternalIcon-default': '$interactive1',
        'navMenuV2-color-fill-routerHeaderIcon': '$interactive1',
        'navMenuV2-color-focus-root': '$interactive1',
        'navMenuV2-color-focus-drawerMenuItem': '$interactive1',
        'navMenuV2-color-text-drawerMenuItem': '$interactive1',
        'navMenuV2-color-text-itemLinkTitle-default': '$interactive1',
        'pageBodyIntro-color-border-link-active': '$interactive1',
        'pageBodyIntro-color-text-profileHeadingText': '$interactive1',
        'selectInput-color-border-input-open': '$interactive1',
        'selectInput-color-border-optionListContainer-open': '$interactive1',
        'selectInput-color-fill-icon-default': '$interactive1',
        'selectInput-color-fill-icon-hover': '$interactive1',
        'stepIndicator-color-background-stepBar-active': '$interactive1',
        'stepIndicator-color-fill-scrollButton': '$interactive1',
        'stepIndicator-color-outline-stepIcon': '$interactive1',
        'tabs-color-border-activeLine': '$interactive1',
        'tabs-color-focusRing-tab': '$interactive1',
        'tabs-color-text-tab-default': '$interactive1',
        'toast-color-background-container-info': '$interactive1',
      },
    },
  };
};
