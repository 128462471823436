import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { landingDrawerIcon, phoneOnly } from '../ConstantsStyles';
import { ResponsiveText } from './DataCard.style';

const PhoneTag = styled('a', {
  display: 'inline',
  alignItems: 'center',
  textDecoration: 'none',
  '&:hover,&:active': {
    textDecoration: 'none',
  },
});

export const phoneTextStyles = {
  fontSize: '14px !important',
  lineHeight: '18px',
  fontWeight: '500',
  padding: '0 4px !important',
  cursor: 'pointer',
  textDecoration: 'underline',
  '@screen < $sm': {
    fontSize: '16px !important',
  },
};

export const ScheduleWithFacility = ({
  scheduleText,
  primaryPhone,
  providerId,
}) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const [isOpen, setIsOpen] = useState(false);
  const uiMessaging = useLagoon('ui-messaging')();
  const schedulingWithFacilityBanner = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.SCHEDULING_WITH_FACILITY_BANNER,
  });

  const bannerContent = (
    <React.Fragment>
      {schedulingWithFacilityBanner?.message}
      <PhoneTag
        data-auto-testid={`phone-schedule-with-facility-${providerId}`}
        data-testid={`phone-schedule-with-facility-${providerId}`}
        href={`tel:${primaryPhone}`}
        onClick={(e: SyntheticEvent) => e.stopPropagation()}
      >
        <ResponsiveText
          color="$interactive1"
          css={phoneTextStyles}
          data-auto-testid={`data-card-phone-number-text-${providerId}`}
          data-testid={`data-card-phone-number-text-${providerId}`}
          fontWeight="$bold"
        >
          {primaryPhone}
        </ResponsiveText>
      </PhoneTag>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!mobileScreen ? (
        <Popover
          aria-label={`${t('INFORMATION_ABOUT')} ${
            schedulingWithFacilityBanner?.title
          }`}
          content={bannerContent}
          css={{
            'abyss-popover-content': {
              zIndex: '9',
              width: '220px',
              height: 'auto',
            },
            'abyss-popover-trigger': {
              marginTop: '3px',
            },
          }}
          data-auto-testid={`data-card-provider-schedule-with-facility-popover-${providerId}`}
          data-testid={`data-card-provider-schedule-with-facility-popover-${providerId}`}
          onOpenChange={() => {
            setIsOpen(!isOpen);
          }}
          open={isOpen}
          title={schedulingWithFacilityBanner?.title}
        >
          <div style={{ paddingLeft: '24px' }}>
            <IconMaterial
              css={{ marginRight: '4px', marginBottom: '-4px' }}
              data-auto-testid="data-card-provider-scheduling-popover"
              data-testid="data-card-provider-scheduling-popover"
              icon="info"
              onClick={() => setIsOpen(!isOpen)}
              size={18}
              variant="outlined"
            />
            {scheduleText}
          </div>
        </Popover>
      ) : (
        <React.Fragment>
          <Drawer
            css={{
              ...landingDrawerIcon,
              'abyss-modal-content-container': {
                ...landingDrawerIcon['abyss-modal-content-container'],
                maxWidth: '100%',
                width: '100%',
              },
            }}
            data-auto-testid={`data-card-provider-schedule-with-facility-popover-${providerId}`}
            data-testid={`data-card-provider-schedule-with-facility-popover-${providerId}`}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            position="bottom"
            title={schedulingWithFacilityBanner?.title}
            titleAlign="left"
          >
            {bannerContent}
          </Drawer>
          <Layout.Group css={{ paddingLeft: '18px' }}>
            <IconMaterial
              css={{ marginLeft: '-4px', marginRight: '-4px' }}
              data-auto-testid="data-card-provider-scheduling-popover"
              data-testid="data-card-provider-scheduling-popover"
              icon="info"
              onClick={() => setIsOpen(true)}
              size={16}
              variant="outlined"
            />
            <Layout.Group>
              <div
                css={{ marginBottom: '4px' }}
                data-auto-testid="data-card-schedule-with-facility-text"
                data-testid="data-card-schedule-with-facility-text"
                onClick={() => setIsOpen(true)}
              >
                <Text>{scheduleText}</Text>
              </div>
            </Layout.Group>
          </Layout.Group>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
