import { styled } from '@abyss/web/tools/styled';
import { ReactNode } from 'react';

export const InfoWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingBottom: '8px',
});

export const MapViewWrap = styled('div', {
  '.abyss-card-root': {
    height: '562',
    width: '100%',
    border: 'none',
    padding: '16px, 24px, 16px, 24px',
    boxShadow: 'unset',
    '.map-container': {
      height: '298px',
      width: '100%',
    },
  },
  '.mapboxgl-ctrl-bottom-left': {
    display: 'none',
  },
  '.mapboxgl-ctrl-bottom-right': {
    display: 'none',
  },
});

export const MapContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

export const CantChangeLocationSpan = styled('span', {
  display: 'flex',
  flexWrap: 'nowrap',
  marginRight: '4px',
  width: '233px',
  fontWeight: '$bold',
  fontSize: '$md',
  lineHeight: '20px',
  color: '$interactive1',
  '@screen < $sm': {
    fontSize: '14px',
    lineHeight: '18px',
    width: '180px',
  },
});

export const CantChangeLocationContainer = styled('div', {
  display: 'flex',
  flexWrap: 'nowrap',
  marginTop: '4px',
});

export const Heading = styled('div', {
  fontWeight: '$bold',
  color: '$primary1',
  fontSize: '20.25px',
  lineHeight: '24px',
  marginBottom: 'initial',
});
export type ContentWrapperProps = {
  children: ReactNode;
};

export const ContentWrapper = ({ children }: ContentWrapperProps) => (
  <Wrapper>{children}</Wrapper>
);

export const Wrapper = styled('div', {
  maxWidth: '1200px',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '16px 24px 16px 24px',
  '@screen < $sm': {
    padding: '16px',
  },
});

export const StyledLocationContainer = styled('div', {
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '8px',
  minWidth: '407px',
  minHeight: '63px',
  border: '1px solid  #CBCCCD',
  borderRadius: '4px',
  color: '$primary1',
  gap: '8px',
  '@screen < $sm': {
    minWidth: '100%',
    padding: '8px',
  },
  '>div': {
    '@screen < $sm': {
      width: '85%',
    },
  },
});

export const StyledLocationsDropdownOption = styled('div', {
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '20px',
  color: '$providerDetailsDrawerLocationsDropdownOptionColor',
  textAlign: 'left',
  alignItem: 'center',
});

export const MapDirectionsContainer = styled('div', {
  outline: '1px solid #E5E5E6',
  boxShadow: '0px 2px 6px rgba(25, 25, 26, 0.16)',
});

export const PopoverContentContainer = styled('div', {
  width: '300px',
  fontSize: '16px',
  color: '#424242',
  lineHeight: '20px ',
  fontWeight: '500',
});
export const DrawerContentContainer = styled('div', {
  fontSize: '16px',
  color: '#424242',
  lineHeight: '20px ',
  fontWeight: '500',
});

export const locationStyle = {
  display: 'block',
  alignItems: 'baseline',
  'abyss-flex-root': {
    gap: '16px',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    '@screen < $sm': {
      gap: '0px',
    },
  },
};

export const PopoverContentStyle = {
  'abyss-popover-content': {
    width: '343px',
    height: '145px',
    borderRadius: '4px',
    padding: '20px 16px 20px 16px',
  },
  'abyss-popover-close-button': {
    top: '0px',
    right: '4px',
  },
  'abyss-popover-title': {
    fontSize: '16px !important',
    fontWeight: '$bold !important',
    lineHeight: '18px !important',
  },
};

export const locationDrawerIcon = {
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0 0',
    height: 'auto !important',
    padding: '20px 16px 20px 16px',
  },
  'abyss-modal-body': {
    color: '$gray8',
    whiteSpace: 'pre-line',
    fontSize: '16px',
    fontWeight: '500',
  },
  'abyss-modal-close-button': { margin: '8px 10px 0 0' },
  'abyss-modal-content-title': {
    paddingTop: '0px',
    fontFamily: '$primary !important',
    fontSize: '16px !important',
    color: '#323334 !important',
    lineHeight: '18px !important',
    fontWeight: '700 !important',
  },
  'abyss-modal-header-container': {
    padding: '0px 0px 8px 0px',
  },
};

export const LocationContainer = styled('div', {
  borderStyle: 'solid',
  borderColor: '$cardBorder',
  borderWidth: '1px 0px 1px 0px ',
  margin: '8px 0px 0px',
});
