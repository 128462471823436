

export enum breakDownKeys {
  'quality' = 'Quality',
  'benefits' = 'Benefits',
  'convenience' = 'Convenience',
  'personalFit' = 'Personal fit',
  'verification' = 'Updated provider info',
}
export const RecommendationCriteriaMatch = {
  None: 'None',
  All: 'All',
  Some: 'Some',
};

export const RecommendationScoreMatch = {
  Excellent: 'Excellent',
  Good: 'Good',
  Low: 'Low',
};

const criteriaMatchDescriptions = {
  [RecommendationCriteriaMatch.None]: 'No Criteria Met',
  [RecommendationCriteriaMatch.All]: 'All Criteria Met',
  [RecommendationCriteriaMatch.Some]: 'Some Criteria Met',
};

const criteriaViewMatchStyle = {
  [RecommendationCriteriaMatch.None]: {
    backgroundColor: '$warning2',
  },
  [RecommendationCriteriaMatch.Some]: {
    backgroundColor: '#F3F3F3',
  },
  [RecommendationCriteriaMatch.All]: {
    backgroundColor: '#F0F9ED',
  },
};

const matchDescriptions = {
  [RecommendationScoreMatch.Excellent]: 'Excellent match',
  [RecommendationScoreMatch.Good]: 'Good match',
  [RecommendationScoreMatch.Low]: 'Low match',
};

const viewMatchStyle = {
  [RecommendationScoreMatch.Low]: {
    backgroundColor: '$warning2',
  },
  [RecommendationScoreMatch.Good]: {
    backgroundColor: '#F3F3F3',
  },
  [RecommendationScoreMatch.Excellent]: {
    backgroundColor: '#F0F9ED',
  },
};

const textMatchStyle = {
  [RecommendationScoreMatch.Low]: {
    color: '#C24E14',
  },
  [RecommendationScoreMatch.Good]: {
    color: '$gray7',
  },
  [RecommendationScoreMatch.Excellent]: {
    color: '$success1',
  },
};

const criteriaTextMatchStyle = {
  [RecommendationCriteriaMatch.None]: {
    color: '#C24E14',
  },
  [RecommendationCriteriaMatch.Some]: {
    color: '$gray7',
  },
  [RecommendationCriteriaMatch.All]: {
    color: '$success1',
  },
};



export const getMatchDescription = (match: string, key: string): string => {
  if (key === 'quality') {
    return  (criteriaMatchDescriptions[match] ??
        criteriaMatchDescriptions[RecommendationCriteriaMatch.None])
  }
  return (matchDescriptions[match] ??
    matchDescriptions[RecommendationScoreMatch.Low])
};

export const getChipbackgroundColor = (match: string, key: string): string => {
  if (key === 'quality') {
    return (
      criteriaViewMatchStyle[match]?.backgroundColor ??
      criteriaViewMatchStyle[RecommendationCriteriaMatch.None].backgroundColor
    );
  }
  return (
    viewMatchStyle[match]?.backgroundColor ??
    viewMatchStyle[RecommendationScoreMatch.Low].backgroundColor
  );
};

export const getChipTextColor = (match: string, key: string): string => {
  if (key === 'quality') {
    return (
      criteriaTextMatchStyle[match]?.color ??
      criteriaTextMatchStyle[RecommendationCriteriaMatch.None].color
    );
  }
  return (
    textMatchStyle[match]?.color ??
    textMatchStyle[RecommendationScoreMatch.Low].color
  );
};