import { t } from 'i18next';
import { find } from 'lodash';
import React from 'react';

import { useLagoon } from '../../hooks/useLagoon';
import {
  AcoProvider,
  ProviderDetails,
  ProviderLocation,
  RecommendationDetails,
} from '../../models/ProviderDetails';
import { capitalizeAllFirstCharacters } from '../../utils/providerNames.utils';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { BenifitsBreakDown } from './BenefitsBreakDown/BenfitsBreakDown';
import { ConvenienceBreakDown } from './ConvenienceBreakDown/ConvenienceBreakDown';
import { PersonalFitBreakDown } from './PersonalFitBreakDown/PersonalFitBreakDown';
import { QualityBreakDown } from './QualityBreakDown/QualityBreakDown';
import { StyledDrawer } from './SmartChoiceBreakDown.styles';

interface BreakdownDrawerProps {
  drawerType: string;
  isOpen: boolean;
  providerDetails: ProviderDetails;
  locationKey: string | undefined;
  recommendationDetails: RecommendationDetails;
  setIsOpen: (isOpen: boolean) => void;
}
export const BreakdownDrawer = ({
  drawerType,
  isOpen,
  providerDetails,
  locationKey,
  recommendationDetails,
  setIsOpen,
}: BreakdownDrawerProps) => {
  const providerGender = providerDetails?.gender;
  const providerSpeaks = providerDetails?.languagesSpoken?.join(',');
  const providerBoardCertifications =
    providerDetails?.boardCertifications?.join(',');
  const selectedLocation: ProviderLocation =
    providerDetails?.providerLocations?.find(
      (x) => x.locationKey === locationKey
    ) as ProviderLocation;
  const selectedLocatoionCity = `${selectedLocation?.address?.line}, ${selectedLocation?.address?.city}, ${selectedLocation?.address?.state}`;
  const getAcoText = (acoProviders: AcoProvider[] = []) =>
    acoProviders
      .filter((aco) => aco?.acoNetworkDescription)
      .map((aco) => capitalizeAllFirstCharacters(aco.acoNetworkDescription))
      .join(', ') || t('PROVIDER_LOCATIONS_DETAILS.NOT_APPLICABLE');

  const { quality, benefits, convenience, personalFit } =
    recommendationDetails || {};
  const lagoonData = useLagoon('ui-messaging')();

  const quality_breakdown = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
      .QUALITY_BREAKDOWN_TITLE,
  });
  const convenience_breakdown = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_CONVENIENCE_BREAKDOWN
      .CONVENIENCE_BREAKDOWN_TITLE,
  });
  const benefits_breakdown = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BENEFITS_BREAKDOWN
      .BENIFITS_BREAKDOWN_TITLE,
  });
  const personal_fit_breakdown = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_PERSONAL_FIT_BREAKDOWN
      .PERSONAL_FIT_BREAKDOWN_TITLE,
  });

  return (
    <React.Fragment>
      <StyledDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size={572}
        title={(() => {
          switch (drawerType) {
            case 'quality':
              return quality_breakdown?.message;
            case 'benefits':
              return benefits_breakdown?.message;
            case 'convenience':
              return convenience_breakdown?.message;
            case 'personalFit':
              return personal_fit_breakdown?.message;
            default:
              return '';
          }
        })()}
      >
        {drawerType === 'quality' && <QualityBreakDown quality={quality} />}
        {drawerType === 'benefits' && (
          <BenifitsBreakDown
            acoName={getAcoText(selectedLocation?.acoProvider)}
            benefits={benefits}
            selectedLocatoionCity={selectedLocatoionCity || ''}
          />
        )}
        {drawerType === 'convenience' && (
          <ConvenienceBreakDown convenience={convenience} />
        )}
        {drawerType === 'personalFit' && (
          <PersonalFitBreakDown
            personalFit={personalFit}
            providerBoardCertifications={providerBoardCertifications}
            providerGender={providerGender}
            providerSpeaks={providerSpeaks}
          />
        )}
      </StyledDrawer>
    </React.Fragment>
  );
};
