import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { find } from 'lodash';
import React from 'react';

import { Quality } from '../../../../../api/src/models/ProviderRecommendationDetails';
import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { useLagoon } from '../../../hooks/useLagoon';
import { MatchText } from '../MatchText';
import {
  BreakdownContainer,
  ContainerWithTopandBottonBorder,
  CriteriaDescription,
  CriteriaPointsContainer,
  CriteriaTitle,
  DescriptionContainer,
  DrawerContentWithBackgroundgray,
  FAQContainer,
  FAQLink,
  StyledDescription,
} from '../SmartChoiceBreakDown.styles';
// import { NotAMatchNoServiceDataTextText } from './Components/NotAMatchNoServiceDataText';
import { NotAMatchPremiumCarePhysicianText } from './Components/NotAMatchPremiumCarePhysicianText';
import {
  ExploreUnaitedHealthPremiumLink,
  StyledTitle,
} from './QualityBreakDown.styles';

export interface QualityBreakDownProps {
  quality: Quality;
}

export const QualityBreakDown = ({ quality }: QualityBreakDownProps) => {
  const lagoonData = useLagoon('ui-messaging')();

  const all_criteria_met = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
      .QUALITY_BREAKDOWN_ALL_CRITERIA_MET_TITLE_AND_DESCRIPTION,
  });
  const some_criteria_met = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
      .QUALITY_BREAKDOWN_SOME_CRITERIA_MET_TITLE_AND_DESCRIPTION,
  });
  const no_criteria_met = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
      .QUALITY_BREAKDOWN_NO_CRITERIA_NOT_MET_TITLE_AND_DESCRIPTION,
  });
  const description = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
      .QUALITY_BREAKDOWN_DESCRIPTION,
  });
  const credentialed_provider = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
      .QUALITY_BREAKDOWN_CREDENTIALED_PROVIDER,
  });
  const premium_care_physician = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
      .QUALITY_BREAKDOWN_PREMIUM_CARE_PHYSICIAN,
  });
  // const service_data_available = find(lagoonData, {
  //   key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
  //     .QUALITY_BREAKDOWN_SERVICE_DATA_AVAILABLE,
  // });
  // const no_service_data_available = find(lagoonData, {
  //   key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
  //     .QUALITY_BREAKDOWN_NO_SERVICE_DATA_AVAILABLE,
  // });
  const what_is_premium_care_physician = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
      .QUALITY_BREAKDOWN_MATCH_WHAT_IS_PRIMARY_CARE_PHYSICIAN_Q_AND_A,
  });
  const not_match_premium_care_physician = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
      .QUALITY_BREAKDOWN_NOTMATCH_PRIMARY_CARE_PHYSICIAN_TITLE_AND_DESCRIPTION,
  });
  const explore_unaited_health_premium = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_QUALITY_BREAKDOWN
      .QUALITY_BREAKDOWN_EXPLORE_UNITED_HEALTHCARE_PREMIUM,
  });
  const FAQs = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.SMART_CHOICE_BREAKDOWN_FAQ_TEXT,
  });

  return (
    <BreakdownContainer>
      <DrawerContentWithBackgroundgray direction="column">
        {quality?.credentialed === 'Y' &&
          quality?.premiumDesignation === 'Y' && (
            <React.Fragment>
              <CriteriaTitle data-testid="all-criteria-met">
                {all_criteria_met?.title}
              </CriteriaTitle>
              <CriteriaDescription data-testid="all-criteria-met-description">
                {all_criteria_met?.message}
              </CriteriaDescription>
            </React.Fragment>
          )}
        {((quality?.credentialed === 'Y' &&
          quality?.premiumDesignation === 'N') ||
          (quality?.credentialed === 'Y' &&
            quality?.premiumDesignation === '') ||
          (quality?.credentialed === 'N' &&
            quality?.premiumDesignation === 'Y') ||
          (quality?.credentialed === 'N' &&
            quality?.premiumDesignation === '')) && (
          <React.Fragment>
            <CriteriaTitle data-testid="some-criteria-met">
              {some_criteria_met?.title}
            </CriteriaTitle>
            <CriteriaDescription data-testid="some-criteria-met-description">
              {some_criteria_met?.message}
            </CriteriaDescription>
          </React.Fragment>
        )}
        {quality?.credentialed === 'N' &&
          quality?.premiumDesignation === 'N' && (
            <React.Fragment>
              <CriteriaTitle data-testid="no-criteria-met">
                {no_criteria_met?.title}
              </CriteriaTitle>
              <CriteriaDescription data-testid="no-criteria-met-description">
                {no_criteria_met?.message}
              </CriteriaDescription>
            </React.Fragment>
          )}
      </DrawerContentWithBackgroundgray>
      <DescriptionContainer>
        <StyledDescription data-testid="quality-brakdown-description">
          {description?.message}
        </StyledDescription>
      </DescriptionContainer>
      <CriteriaPointsContainer direction="column">
        {quality?.credentialed === 'Y' && (
          <MatchText text={credentialed_provider?.message} />
        )}
        {quality?.premiumDesignation === 'Y' && (
          <MatchText
            StartIcon={
              <React.Fragment>
                <IconSymbol color="#002677" icon="favorite" />
                <IconSymbol color="#002677" icon="favorite" />
              </React.Fragment>
            }
            text={premium_care_physician?.message}
          />
        )}
        {(quality?.premiumDesignation === 'N' ||
          quality?.premiumDesignation === '') && (
          <NotAMatchPremiumCarePhysicianText
            text={premium_care_physician?.message}
          />
        )}

        {/*  currently backend work is going on LLM
      <MatchText EndIcon={<IconSymbol icon="info" variant="outlined" color="#196ECF" />} text={service_data_available?.message} />
      <NotAMatchNoServiceDataTextText
        EndIcon={<IconSymbol icon="info" variant="outlined" color="#196ECF" />}
        text={no_service_data_available?.message}
      /> */}
      </CriteriaPointsContainer>
      <ContainerWithTopandBottonBorder
        data-testid="quality-breakdown-top-and-bottom-border"
        direction="column"
      >
        {quality?.credentialed === 'Y' &&
          quality?.premiumDesignation === 'Y' && (
            <React.Fragment>
              <StyledTitle data-testid="what-is-premium-care-physician">
                {what_is_premium_care_physician?.title}
              </StyledTitle>
              <StyledDescription data-testid="what-is-premium-care-physician-description">
                {what_is_premium_care_physician?.message}
              </StyledDescription>
            </React.Fragment>
          )}

        {((quality?.credentialed === 'Y' &&
          quality?.premiumDesignation === 'N') ||
          (quality?.credentialed === 'Y' &&
            quality?.premiumDesignation === '') ||
          (quality?.credentialed === 'N' &&
            quality?.premiumDesignation === 'Y') ||
          (quality?.credentialed === 'N' &&
            quality?.premiumDesignation === '')) && (
          <React.Fragment>
            <StyledTitle data-testid="not-match-premium-care-physician">
              {not_match_premium_care_physician?.title}
            </StyledTitle>
            <StyledDescription data-testid="not-match-premium-care-physician-description">
              {not_match_premium_care_physician?.message}
            </StyledDescription>
          </React.Fragment>
        )}

        <ExploreUnaitedHealthPremiumLink
          data-auto-testid="explore-unaited-health-premium-link"
          data-testid="explore-unaited-health-premium-link"
          href="https://www.uhc.com/"
        >
          {explore_unaited_health_premium?.message}
        </ExploreUnaitedHealthPremiumLink>
      </ContainerWithTopandBottonBorder>
      <FAQContainer>
        <FAQLink
          data-auto-testid="faq-link"
          data-testid="faq-link"
          href="https://www.uhc.com/"
        >
          {FAQs?.message}
        </FAQLink>
      </FAQContainer>
    </BreakdownContainer>
  );
};
