import { useShallow } from 'zustand/react/shallow';

import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';

export const useCoverageType = () => {
  const { coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
    }))
  );

  return coverageType;
};