import { useContext } from 'react';

import { Constants } from '../../common/Constants';
import { setGeoLocationToStorage } from '../../common/PSXHeader/SearchBar/utils';
import { isCounty } from '../../common/SnackCardContainer/utils';
import { CountySearchContext } from '../../context/CountySearchContext';
import { Location } from '../../models/Location';
import { useLocation } from '../useLocation';
import { useUserLocation } from '../useUserLocation';

export const useDeviceLocation = (
  setDeviceLocation: (location: string) => void,
  setIsLoading?: (loading: boolean) => void
) => {
  const { setIsCountySearch } = useContext(CountySearchContext);

  const [, getLocation] = useLocation({
    onCompleted: (result) => {
      const targetLocation: Location =
        result?.data?.location?.features?.[0] || {};
      const {
        id,
        center = [],
        place_name: placeName,
        stateCode,
        zipCode,
      } = targetLocation;
      const [long, lat] = center;
      // set geo coords
      if (isCounty(id)) setIsCountySearch(true);
      else setIsCountySearch(false);
      setGeoLocationToStorage({
        id,
        name: placeName,
        longitude: long,
        latitude: lat,
        stateCode,
        zipCode,
      });
      if (setIsLoading) {
        setIsLoading(false);
      }
    },
  });
  const [, getUserLocation] = useUserLocation({
    onCompleted: (result: {
      data: { userLocation: { features: { place_name: string }[] } };
    }) => {
      const { ADDRESS_CITY_STATE_ZIPCODE } = Constants.DEVICE_LOCATION;
      const devicePlaceName = result?.data?.userLocation?.features?.[
        ADDRESS_CITY_STATE_ZIPCODE
      ]?.place_name?.slice(0, -15);
      getLocation({
        variables: {
          address: encodeURIComponent(devicePlaceName),
          countySearchEnabled: true,
        },
      });
      setDeviceLocation(devicePlaceName);
    },
  });

  const handleFindLocation = async (longitude: string, latitude: string) => {
    await getUserLocation({
      variables: {
        longitude,
        latitude,
      },
    });
  };

  const getDeviceLocation = () => {
    const successCallback = (position: {
      coords: { latitude: number; longitude: number };
    }) => {
      const { latitude, longitude } = position.coords;
      handleFindLocation(String(longitude), String(latitude));
    };

    navigator.geolocation.getCurrentPosition(successCallback, () => {
      if (setIsLoading) {
        setIsLoading(false);
      }
    });
  };
  return { getDeviceLocation };
};
