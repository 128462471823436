import { styled } from '@abyss/web/tools/styled';
import { PageSize } from '@abyss/web/ui/Pagination';

export const StyledPageSize = styled(PageSize, {
  '.abyss-pagination-page-size-label': {
    fontWeight: '$semibold',
  },
  '.abyss-pagination-page-size-select-input': {
    maxHeight: '100%',
  },
});
