import { config } from '@abyss/web/tools/config';
import { useShallow } from 'zustand/react/shallow';

import {
  Constants,
  MemberCoverageTypeToUICoverageTypeConversion,
  OPTUM_CORRELATION_ID_HEADER,
} from '../../common/Constants';
import { FeatureFlags } from '../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { setTypeAheadResults } from '../../common/PSXHeader/SearchBar/utils';
import { getIntValue } from '../../common/Utils/getIntValue';
import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { usePCPStore } from '../../store/usePCPStore';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import { getSuppressFacilityFlags } from '../../utils/providerDetails.utils';
import { getLob } from '../../utils/providerSearch.utils';
import { excludeDisabledResults } from '../../utils/typeahead.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageTypes,
  getCurrentMember,
  getDependentInfo,
  getDomainForAutoComplete,
  getMemberCoverage,
  getMemberShipCategory,
  getSitusState,
  isCNSMember,
  isDsnpMember,
  isMNRMember,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useFeatureFlag } from '../useFeatureFlag';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import { getExcludeNatAncForNetworkIds } from '../useProviderSearch/useProviderSearch';
import { useTypeaheadPseudoRollupTable } from '../useTypeaheadPseudoRollup';
import GET_TYPEAHEAD_DETAILS from './AutoComplete.graphql';

enum UesSuggestions {
  EAP = 'EAP',
}

enum PseudoRollupCodeSearchDescription {
  FSF = 'Freestanding facility',
  PLN = 'preferred lab network (PLN)',
  OX_PLN = 'Oxford preferred lab network',
  DDP_RAD = 'Designated Diagnostic Provider: Imaging',
  DDP_LAB = 'Designated Diagnostic Provider: Lab',
}

export const useAutoCompleteQuery = (options) =>
  useCustomQuery(GET_TYPEAHEAD_DETAILS, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    clearCache: [
      {
        key: 'AutoComplete',
        variables: [
          'query',
          'network',
          'latitude',
          'longitude',
          'lang',
          'limit',
          'limitSpl',
          'limitOrg',
          'limitPrn',
          'radius',
        ],
      },
    ],
    accessor: 'autoComplete',
  });

export const useAutoCompleteResults = (options) => {
  const { dependentSeqNbr } = usePCPStore(
    useShallow((state: any) => ({
      setPCPSearchState: state.setPCPSearchState,
      choosePCP: state.pcpSearchState.choosePCP,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();

  const { lineOfBusiness: memberLob } = currentMember;
  const isCNS = isCNSMember(memberLob);
  const isMNR = isMNRMember(memberLob);
  const isDSNP = isDsnpMember(currentMember);
  const isMNRAndDSNP = isMNR && isDSNP;
  const shouldIncludeHCBSSuggestion = isCNS || isMNRAndDSNP;
  const memberCoverages = getCoverageTypes(currentMember);
  const convertedMemberCoverages = memberCoverages?.map(
    (cov) => MemberCoverageTypeToUICoverageTypeConversion[cov]
  );

  const [
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    useAutocompleteRollUpCodeNameSearch,
    useBHVirtualCarePseudoRollupCodeSearch,
    useOxfordPlnFlag,
    useEnableBhFacilitiesProgramSearchFlag,
    includeAoeOnlySuggestions,
    hideDisabledTypeahead,
    includeHCBSSuggestion,
    enableAoeMedical
  ] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES,
    ConstantsLagoon.FEATURE_FLAGS.USE_UES_ROLLUP_CODE_FOR_NAME_SEARCH,
    ConstantsLagoon.FEATURE_FLAGS.BEHAVIORAL_HEALTH_VIRTUAL_CARE,
    ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_FACILITIES_PROGRAM_SEARCH,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_AOE_TYPEAHEAD,
    FeatureFlags.FEATURE_FLAG_KEY.HIDE_DISABLED_TYPEAHEAD,
    ConstantsLagoon.FEATURE_FLAGS.SHOW_HCBS_TYPEAHEAD_SUGGESTION,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_AOE_MEDICAL,
  ]);

  const includeHCBSTypeAheadSuggestion =
    includeHCBSSuggestion && shouldIncludeHCBSSuggestion;

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const allConfigss = useLagoon('config')();
  const excludeNatAncForNetworkIds = allConfigss?.find(
    (row) => row.key === 'EXCLUDE_NAT_ANC_FOR_NETWORK_IDS'
  )?.value;

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { coverageType } = chipStore;

  const storedLocation = useGeoLocationStorage();
  const { longitude, latitude } = storedLocation;
  const network = getMemberCoverage(currentMember, featureFlags);
  const language = getLanguage()?.code || 'en';
  const typeaheadParams = useLagoon(Constants.LAGOON_TABLE.TYPEAHEAD_PARAMS)();
  const membershipCategory = getMemberShipCategory(currentMember, featureFlags);

  const limitSpl = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_SPECIALTIES,
    Constants.TYPEAHEAD_PARAMS.LIMIT_SPECIALTIES_DEFAULT
  );
  const limitOrg = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_ORG,
    Constants.TYPEAHEAD_PARAMS.LIMIT_ORG_DEFAULT
  );
  const limitPrn = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_PRACTITIONER,
    Constants.TYPEAHEAD_PARAMS.LIMIT_PRACTITIONER_DEFAULT
  );
  const radius = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.RADIUS,
    Constants.TYPEAHEAD_PARAMS.RADIUS_DEFAULT
  );

  const { claimSystemTypeCode, eligibilitySystemTypeCode } =
    getClaimOrEligibilitySystemTypeCode(currentMember, coverageType);
  const pseudoRollupCodeTable = useTypeaheadPseudoRollupTable();

  const {
    suppressPreferredFacilityPLN,
    suppressPreferredFacilityDDP,
    suppressPreferredFacilityFSF,
  } = getSuppressFacilityFlags();

  const suppressEAPKeyword = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.EAP
  );

  const pseudoRollupCodeMap = new Map(
    pseudoRollupCodeTable.map(({ dntDescription, pseudoRollupCode }) => [
      dntDescription,
      pseudoRollupCode,
    ])
  );

  const keywordSuppressionFlags = [
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.PLN
      ),
      value: suppressPreferredFacilityPLN,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.OX_PLN
      ),
      value: suppressPreferredFacilityPLN,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.FSF
      ),
      value: suppressPreferredFacilityFSF,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.DDP_LAB
      ),
      value: suppressPreferredFacilityDDP,
    },
    {
      pseudoVertical: pseudoRollupCodeMap.get(
        PseudoRollupCodeSearchDescription.DDP_RAD
      ),
      value: suppressPreferredFacilityDDP,
    },
    {
      suggestion: UesSuggestions.EAP,
      value: suppressEAPKeyword,
    },
  ];

  const variables = {
    network,
    latitude,
    longitude,
    lang: language,
    limitSpl,
    limitOrg,
    limitPrn,
    radius,
    membershipCategory,
    claimSystemTypeCode,
    eligibilitySystemTypeCode,
    pseudoRollupCodeTable,
    keywordSuppressionFlags,
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    useAutocompleteRollUpCodeNameSearch,
    useBHVirtualCarePseudoRollupCodeSearch,
    useOxfordPlnFlag,
    useEnableBhFacilitiesProgramSearchFlag,
    includeAoeOnlySuggestions,
    includeHCBSTypeAheadSuggestion,
    excludeNatAncForNetworkIds: getExcludeNatAncForNetworkIds(
      excludeNatAncForNetworkIds
    ),
    domain: getDomainForAutoComplete(currentMember),
    situsState: getSitusState(currentMember, getLob(memberLob, chipStore?.lob)),
    enableAoeMedical
  };

  const [autoCompleteResults, GetSearchSuggestions] = useAutoCompleteQuery({
    onCompleted: (autoCompleteResults: any) => {
      const requestData = JSON.parse(autoCompleteResults?.config?.data);
      const correlationId =
        autoCompleteResults?.headers[OPTUM_CORRELATION_ID_HEADER];
      const suggestions =
        autoCompleteResults?.data?.autoComplete?.lang_provider?.langProvider;
      const combinedRollupCodes =
        autoCompleteResults?.data?.autoComplete?.lang_provider
          ?.specialityRollUpCodes;
      const aggregatedAoeCodes =
        autoCompleteResults?.data?.autoComplete?.lang_provider?.aoeCodes;
      const bhProgramFacilityAgg =
        autoCompleteResults?.data?.autoComplete?.lang_provider
          ?.bhProgramFacilityAgg;
      const providers =
        autoCompleteResults?.data?.autoComplete?.practitioners_uhc?.provData;
      const facilities =
        autoCompleteResults?.data?.autoComplete?.organizations_uhc?.orgData;
      const filterData = autoCompleteResults?.data?.autoComplete;
      let suggestionResults;
      if (hideDisabledTypeahead) {
        suggestionResults = excludeDisabledResults(
          convertedMemberCoverages,
          suggestions
        );
      } else {
        suggestionResults = suggestions;
      }

      const typeAheadSuggestions = setTypeAheadResults(
        suggestionResults,
        providers,
        facilities,
        network
      );

      options.onCompleted({
        suggestions,
        typeAheadSuggestions,
        combinedRollupCodes,
        aggregatedAoeCodes,
        bhProgramFacilityAgg,
        requestData,
        correlationId,
        providers,
        facilities,
        filterData,
      });
    },
    onError: () => {},
  });

  const getAutoCompleteResults = ({ query }) => {
    GetSearchSuggestions({
      variables: { ...variables, query },
    });
  };

  return [autoCompleteResults, getAutoCompleteResults];
};
