import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { find } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { useLagoon } from '../../hooks/useLagoon';
import { usePrimaryCare } from '../../hooks/usePrimaryCare';
import { ProviderType } from '../../models/ProviderDetails';
import { getShowEditPCP } from '../../utils/primaryCare.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { EditPCPMenu } from '../PrimaryCareProvider/EditPCPMenu/EditPCPMenu';
import { getFormattedDate } from '../Utils/datesUtils/formatDate';
import {
  LayoutContainer,
  YourPCPContainer,
  YourPCPMessageDetailsContainer,
} from './DetailsPageHeader.styled';

export type Props = {
  providerPCPId: string;
  endDate: string;
  displayFutureTerminationDate?: boolean;
  locationTotalCount?: number;
  providerType: string;
  isCurrentPCP: boolean;
  memberPcpName: boolean;
};

export const ProviderDetailBanner = ({
  memberPcpName,
  providerPCPId,
  endDate,
  displayFutureTerminationDate,
  locationTotalCount,
  isCurrentPCP,
  providerType,
}: Props) => {
  const { t } = useTranslation();
  const currentMember = getCurrentMember();
  const lob = currentMember?.lineOfBusiness;
  const isIfpMember = currentMember?.isIFPMember || false;
  const uiMessaging = useLagoon(Constants.LAGOON_TABLE.UI_MESSAGING)();

  const kelseySeyboldClinicName = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.KELSEY_SEYBOLD_CLINIC_NAME,
  });
  const isPreEffective = useCurrentMemberIsPreEffective();

  const { data: pcpData } = usePrimaryCare();
  const { primaryCare } = pcpData;
  const lockInIndicator = primaryCare?.[0]?.lockInIndicator;
  const { isKelseySeyboldPlan } = currentMember;

  const isPcpKelseySeyboldClinic =
    memberPcpName === kelseySeyboldClinicName?.message;

  const isKelseySeyboldClinic =
    isIfpMember && isKelseySeyboldPlan && isPcpKelseySeyboldClinic;

  const showEditPCP =
    getShowEditPCP(currentMember.editPcp, lockInIndicator) &&
    !isKelseySeyboldClinic;

  const YourPCPMessage = () => {
    if (
      !isCurrentPCP ||
      getFormattedDate(endDate) ||
      displayFutureTerminationDate
    ) {
      return null;
    }

    return (
      <React.Fragment>
        <YourPCPContainer css={!showEditPCP ? { width: '250px' } : {}}>
          <LayoutContainer css={{ alignItems: 'flex-start' }}>
            <YourPCPMessageDetailsContainer>
              <IconMaterial color="$primary1" icon="check" size="$sm" />
              <Text
                css={{
                  'abyss-text-root': {
                    fontSize: '12.64px',
                    fontWeight: '700',
                    fontStyle: 'normal',
                    lineHeight: '16px',
                    color: '$primary1 ! important',
                  },
                }}
                data-testid="pcp-banner-content"
              >
                {t('PCP_BANNER.CONTENT')}
              </Text>
            </YourPCPMessageDetailsContainer>
            {showEditPCP ? (
              <EditPCPMenu
                badge
                dependentSeqNbr={currentMember?.demographics?.dependentSeqNbr}
                isPreEffective={isPreEffective}
                lineOfBusiness={lob}
                locationForAnalytics="Edit PCP"
                providerID={providerPCPId}
                providerType={
                  providerType === ProviderType.PROVIDER_GROUP
                    ? ProviderType.MEDICAL_GROUP
                    : providerType
                }
                showPcpLocation={locationTotalCount}
              />
            ) : null}
          </LayoutContainer>
        </YourPCPContainer>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <YourPCPMessage />
    </React.Fragment>
  );
};
