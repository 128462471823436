import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PortalContext } from '../../../context/PortalContext';
import { mobileOnly } from '../../ConstantsStyles';
import {
  A11yHiddenSpan,
  IconContainer,
  Input,
  KeywordSearchInputContainer,
  KeywordSearchLabel,
  KeywordSearchStyledDiv,
  SearchPlacementStyle,
} from './SearchBar.styled';

type Props = {
  keywordSearchInputId: string;
  searchLabelText: any;
  choosePCPHeader?: boolean;
  isFocusedOnKeywordSearchInput: any;
  isFocusedOnLocationSearch: any;
  search?: string | null;
  setResults: any;
  setSearchButtonResults: any;
  handleKeywordSearchInputChange: any;
  handleKeywordSearchInputFocus: any;
  handleKeywordSuggestionKeyDown: any;
  searchInputRef: any;
  keywordSearchTerm: any;
  searchButtonResults: any;
  commonSearchesData: any;
  dentalCoverage: any;
  visionCoverage: any;
};
export const SearchPlacement = ({
  keywordSearchInputId,
  searchLabelText,
  choosePCPHeader,
  isFocusedOnKeywordSearchInput,
  isFocusedOnLocationSearch,
  search,
  setResults,
  setSearchButtonResults,
  handleKeywordSearchInputChange,
  handleKeywordSearchInputFocus,
  handleKeywordSuggestionKeyDown,
  searchInputRef,
  keywordSearchTerm,
  searchButtonResults,
  commonSearchesData,
  dentalCoverage,
  visionCoverage,
}: Props) => {
  const mobileScreen = useMediaQuery(mobileOnly);
  const { portalData } = useContext(PortalContext);
  const [searchInput, setSearchInput] = useState(search || '');
  useEffect(() => {
    setSearchInput(search || '');
  }, [search]);

  useEffect(() => {
    const shouldResetSearchInput =
      !isFocusedOnKeywordSearchInput && !isFocusedOnLocationSearch;
    if (shouldResetSearchInput) {
      setSearchInput(search || '');
    }
  }, [isFocusedOnKeywordSearchInput, isFocusedOnLocationSearch]);

  const { t } = useTranslation();
  const themeOverride = portalData?.portalConfig;
  const icColor = themeOverride
    ? '$searchIconColor'
    : choosePCPHeader && mobileScreen && '$interactive1';

  return (
    <SearchPlacementStyle>
      <KeywordSearchInputContainer>
        <KeywordSearchLabel
          data-testid="provider-search-label"
          htmlFor={keywordSearchInputId}
        >
          {searchLabelText?.message ?? ''}
        </KeywordSearchLabel>
        <KeywordSearchStyledDiv>
          <IconContainer>
            <IconMaterial
              color={icColor}
              icon="search"
              size={mobileScreen ? 20 : 24}
            />
          </IconContainer>

          <Input
            aria-activedescendant={
              isFocusedOnKeywordSearchInput
                ? 'primary-search-bar-result-suggestion'
                : ''
            }
            aria-autocomplete="list"
            aria-describedby="initInstr"
            aria-expanded={isFocusedOnKeywordSearchInput}
            aria-haspopup="listbox"
            autoComplete="off"
            data-testid="primary-search-input"
            defaultValue={search}
            id={keywordSearchInputId}
            key={search}
            onChange={(e) => {
              const forbiddenCharsRegex = /[<>?&/"]/;
              if (forbiddenCharsRegex.test(e.target.value)) {
                return;
              }
              setSearchInput(e.target.value);
              if (
                e.target.value?.trim().length <= 1 ||
                !/[A-Za-z0-9]/.test(e.target.value?.trim())
              ) {
                setResults([]);
                setSearchButtonResults([]);
              }

              handleKeywordSearchInputChange(e);
            }}
            onFocus={handleKeywordSearchInputFocus}
            onKeyDown={handleKeywordSuggestionKeyDown}
            placeholder={t('SEARCH_PLACEHOLDER')}
            ref={searchInputRef}
            role="combobox"
            type="text"
            value={searchInput}
          />
          <A11yHiddenSpan aria-atomic="true" aria-live="polite">
            {`${
              keywordSearchTerm?.trim().length > 1
                ? searchButtonResults.length + 2
                : commonSearchesData.length +
                  (dentalCoverage ? 1 : 0) +
                  (visionCoverage ? 1 : 0)
            } ${t('results available')}`}
          </A11yHiddenSpan>
        </KeywordSearchStyledDiv>
      </KeywordSearchInputContainer>
    </SearchPlacementStyle>
  );
};
